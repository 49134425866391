import React from "react";
import { connect } from 'react-redux';
import { postSerieComic, getEditoriales } from "../../redux/actions";
import Swal from "sweetalert2";
import Loader from "../loader"

import '../add.css';
import tokens from "../../variables";
import { auth } from "../../firebase";
import BackDash from "../../components/backDash/backDash";

class AddSerieComic extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            hasError: false,
            error: "",
            serie: {},
            loading: true,
            checked: false,
            isLogged: false,
            editoriales: []
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);
    }

    reset = ()=>{
        this.setState({
            hasError: false,
            error: "",
        });
    }

    controlInfo = ()=>{
        let nombre = document.getElementById("input-name");
        let guion = document.getElementById("input-guion");
        let dibujo = document.getElementById("input-dibujo");
        let color = document.getElementById("input-color");
        let tintas = document.getElementById("input-tintas");
        let editorial = document.getElementById("input-editorial");
        let amount = document.getElementById("input-amount");
        let state = document.getElementById("input-state");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        nombre.style = "border-color: none";
        guion.style = "border-color: none";
        dibujo.style = "border-color: none";
        color.style = "border-color: none";
        tintas.style = "border-color: none";
        editorial.style = "border-color: none";
        amount.style = "border-color: none";
        state.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(nombre.value === "" || !nombre.value){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${vacio}`,
                checked: true
            });
        }

        if(guion.value === "" || !guion.value){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${vacio}`,
                checked: true
            });
        }

        if(dibujo.value === "" || !dibujo.value){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${vacio}`,
                checked: true
            });
        }

        if(color.value === "" || !color.value){
            color.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Color" ${vacio}`,
                checked: true
            });
        }

        if(tintas.value === "" || !tintas.value){
            tintas.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tintas" ${vacio}`,
                checked: true
            });
        }

        if(editorial.value === "" || !editorial.value){
            editorial.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Editorial" ${vacio}`,
                checked: true
            });
        }

        if(amount.value === "" || !amount.value){
            amount.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tomos" ${vacio}`,
                checked: true
            });
        }

        if(state.value === "" || !state.value){
            state.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Estado" ${vacio}`,
                checked: true
            });
        }

        //CONTROLAR ESPACIOS
        if(nombre.value[0] === " "){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${espacio}`,
                checked: true
            })
        }

        if(guion.value[0] === " "){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${espacio}`,
                checked: true
            })
        }

        if(dibujo.value[0] === " "){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${espacio}`,
                checked: true
            })
        }

        if(color.value[0] === " "){
            color.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Color" ${espacio}`,
                checked: true
            })
        }

        if(tintas.value[0] === " "){
            tintas.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tintas" ${espacio}`,
                checked: true
            })
        }

        return this.setState({
            checked: true
        })

    }

    onSubmit = (e)=>{
        e.preventDefault();
        e.target.disabled = true;

        const serie_info = {
            nombre: document.getElementById("input-name").value,
            autores: {
                guion: document.getElementById("input-guion").value,
                dibujo: document.getElementById("input-dibujo").value,
                color: document.getElementById("input-color").value,
                tintas: document.getElementById("input-tintas").value,
                otros: document.getElementById("input-others").value
            },
            editorial: document.getElementById("input-editorial").value,
            editorial_origen: document.getElementById("input-origen").value,
            vol_amount: document.getElementById("input-amount").value,
            state: document.getElementById("input-state").value,
            sinopsis: document.getElementById("sinopsis").value
        }

        this.setState({
            serie: serie_info
        }, ()=>{
            this.controlInfo();
        })
    }
    
    componentDidUpdate(prevProps, prevState){

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                if(auth.currentUser.uid === tokens.admin){
                    this.setState({
                        isLogged: true,
                        loading: true
                    }, ()=>{
                        this.props.getEditoriales()
                    })
                }else{
                    window.location.href = '/';
                }
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }else if(!auth.currentUser && !this.state.isLogged){
                window.location.href = '/';
            }
        })

        if(prevProps.state.post_state !== this.props.state.post_state){

            if(this.props.state.post_state !== "success"){
                if(this.props.state.post_state.error){
                    Swal.fire({
                        title: this.props.state.post_state.error.detail,
                        text: this.props.state.post_state.error.code,
                        icon: "error"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: this.props.state.post_state,
                        icon: "warning",
                        button: "Cerrar"
                    }).then(()=>{
                        window.location.reload()
                    })
                }
            }else{

                if(document.getElementById("input-state")){
                    document.getElementById("input-name").value = "";
                    document.getElementById("input-guion").value = "";
                    document.getElementById("input-dibujo").value = "";
                    document.getElementById("input-color").value = "";
                    document.getElementById("input-tintas").value = "";
                    document.getElementById("input-others").value = "";
                    document.getElementById("input-editorial").value = "";
                    document.getElementById("input-amount").value = "";
                    document.getElementById("input-state").value = "";
                    document.getElementById("input-origen").value = "";
                }

                Swal.fire({
                    title: "Agregada!",
                    text: `${this.state.serie.nombre} agregada exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                });

                this.setState({
                    serie: {}
                }, this.reset())                

                document.getElementById("post_button").disabled = false;
            }
        }

        if(prevState.checked !== this.state.checked){
            if(this.state.checked){
                if(this.state.hasError){
                    Swal.fire({
                            title: "Error!",
                            text: this.state.error,
                            icon: "error",
                            button: "Volver"
                        })
                        .then(()=>{
                            window.location.reload()
                        })
        
                        document.getElementById("post_button").disabled = false;
                        this.setState({
                            checked: false
                        })
        
                }else if(this.state.serie){  
                    this.props.postSerieComic(this.state.serie);
                    this.setState({
                        checked: false
                    })
                }
            }
        }

        if(prevProps.state.editoriales !== this.props.state.editoriales){
            this.setState({
                editoriales: this.props.state.editoriales
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    render(){
        if(this.state.loading || !this.state.isLogged){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir serie (cómic)</h1>
                    <span className="form-container" id="comic-width">
                        <span id="comic-form">
                            <span className="adds-container">
                                <span className="input-add">
                                    <p className="p-input">Nombre</p>
                                    <input className="input-" id="input-name" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Editorial</p>
                                    <select className="input-" id="input-editorial">
                                        {
                                            this.props.state.editoriales.map((editorial)=>{
                                                return(
                                                    <option key={editorial.nombre}>
                                                        {editorial.nombre}
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Editorial de origen</p>
                                    <input className="input-" id="input-origen" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Tomos en total</p>
                                    <input className="input-" id="input-amount" type="number" min={1} />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Estado (país de origen)</p>
                                    <select className="input-" id="input-state">
                                        <option>
                                            Abierta
                                        </option>
                                        <option>
                                            Cerrada
                                        </option>
                                    </select>
                                </span>
                            </span>
                            <span className="adds-container">
                                <span className="input-add">
                                    <p className="p-input">Guión</p>
                                    <input className="input-" id="input-guion" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Dibujo</p>
                                    <input className="input-" id="input-dibujo" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Color</p>
                                    <input className="input-" id="input-color" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Tintas</p>
                                    <input className="input-" id="input-tintas" type="text" autoComplete="false" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Otros autores</p>
                                    <input className="input-" id="input-others" type="text" autoComplete="false" />
                                </span>
                            </span>
                        </span>
                        <span className="input-add">
                            <p className="p-input">Sinopsis</p>
                            <textarea className="input-" id="sinopsis" />
                        </span>
                        <br />
                        <button className="add-button" onClick={this.onSubmit} id="post_button">Crear</button>
                    </span>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postSerieComic,
    getEditoriales
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(AddSerieComic);