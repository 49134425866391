import React from "react";
import { connect } from 'react-redux';
import { postSerie, getEditoriales } from "../../redux/actions";
import Swal from "sweetalert2";
import Loader from "../loader"
import BackDash from '../../components/backDash/backDash';

import '../add.css';
import tokens from "../../variables";
import { auth } from "../../firebase";

class AddSerie extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            hasError: false,
            error: "",
            serie: {},
            loading: true,
            isLogged: false,
            editoriales: []
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    reset = ()=>{
        this.setState({
            hasError: false,
            error: "",
        });
    }

    componentDidMount(){
        
        setTimeout(()=>{
            this.setState({
                loading: false
            })
        }, 1000)
    }

    controlInfo = ()=>{
        let nombre = document.getElementById("input-name");
        let guion = document.getElementById("input-guion");
        let dibujo = document.getElementById("input-dibujo");
        let editorial = document.getElementById("input-editorial");
        let amount = document.getElementById("input-amount");
        let state = document.getElementById("input-state");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        nombre.style = "border-color: none";
        guion.style = "border-color: none";
        dibujo.style = "border-color: none";
        editorial.style = "border-color: none";
        amount.style = "border-color: none";
        state.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(nombre.value === "" || !nombre.value){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${vacio}`
            });
        }

        if(guion.value === "" || !guion.value){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${vacio}`
            });
        }

        if(dibujo.value === "" || !dibujo.value){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${vacio}`
            });
        }

        if(editorial.value === "" || !editorial.value){
            editorial.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Editorial" ${vacio}`
            });
        }

        if(amount.value === "" || !amount.value){
            amount.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tomos" ${vacio}`
            });
        }

        if(state.value === "" || !state.value){
            state.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Estado" ${vacio}`
            });
        }

        //CONTROLAR ESPACIOS
        if(nombre.value[0] === " "){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${espacio}`
            })
        }

        if(guion.value[0] === " "){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${espacio}`
            })
        }

        if(dibujo.value[0] === " "){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${espacio}`
            })
        }

    }

    onSubmit = (e)=>{
        e.preventDefault();
        e.target.disabled = true;

        const serie_info = {
            nombre: document.getElementById("input-name").value,
            guion: document.getElementById("input-guion").value,
            dibujo: document.getElementById("input-dibujo").value,
            editorial: document.getElementById("input-editorial").value,
            vol_amount: document.getElementById("input-amount").value,
            state: document.getElementById("input-state").value,
            sinopsis: document.getElementById("sinopsis").value
        }

        this.setState({
            serie: serie_info
        }, ()=>{
            this.controlInfo();
        })

        setTimeout(() => {
            if(this.state.hasError){
                Swal.fire({
                        title: "Error!",
                        text: this.state.error,
                        icon: "error",
                        button: "Volver"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
    
                    document.getElementById("post_button").disabled = false;
    
            }else if(this.state.serie){  
                this.props.postSerie(this.state.serie);
            }
        }, 250);
    }
    
    componentDidUpdate(prevProps){

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                if(auth.currentUser.uid === tokens.admin){
                    this.setState({
                        isLogged: true,
                        loading: true
                    }, ()=>{
                        this.props.getEditoriales()
                    })
                }else{
                    window.location.href = '/';
                }
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }else if(!auth.currentUser && !this.state.isLogged){
                window.location.href = '/';
            }
        })

        if(prevProps.state.editoriales !== this.props.state.editoriales){
            this.setState({
                editoriales: this.props.state.editoriales
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        if(prevProps.state.post_state !== this.props.state.post_state){

            if(this.props.state.post_state !== "success"){
                if(this.props.state.post_state.error){
                    Swal.fire({
                        title: this.props.state.post_state.error.detail,
                        text: this.props.state.post_state.error.code,
                        icon: "error"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: this.props.state.post_state,
                        icon: "warning",
                        button: "Cerrar"
                    }).then(()=>{
                        window.location.reload()
                    })
                }
            }else{
                Swal.fire({
                    title: "Agregada!",
                    text: `${this.state.serie.nombre} agregada exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                })
                .then(()=>{
                    window.location.reload()
                })
            }
        }
    }

    render(){
        if(this.state.loading || !this.state.isLogged){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir serie (manga)</h1>
                    <span className="form-container">
                        <span className="input-add">
                            <p className="p-input">Nombre</p>
                            <input className="input-" id="input-name" type="text" autoComplete="false" />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Guión</p>
                            <input className="input-" id="input-guion" type="text" autoComplete="false" />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Dibujo</p>
                            <input className="input-" id="input-dibujo" type="text" autoComplete="false" />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Sinopsis</p>
                            <textarea className="input-" id="sinopsis" />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Editorial</p>
                            <select className="input-" id="input-editorial">
                                {
                                    this.state.editoriales.map((editorial)=>{
                                        return(
                                            <option key={editorial.nombre}>
                                                {editorial.nombre}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </span>
                        <span className="input-add">
                            <p className="p-input">Tomos en total</p>
                            <input className="input-" id="input-amount" type="number" min={1} />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Estado (país de origen)</p>
                            <select className="input-" id="input-state">
                                <option>
                                    Abierta
                                </option>
                                <option>
                                    Cerrada
                                </option>
                            </select>
                        </span>
                        <br />
                        <button className="add-button" onClick={this.onSubmit} id="post_button">Crear</button>
                    </span>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postSerie,
    getEditoriales
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(AddSerie);