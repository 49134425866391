import React from "react";
import { connect } from "react-redux";
import { getManga, addToCart, addtofavs, subscribe } from "../../redux/actions";
import Swal from 'sweetalert2';
import { auth } from "../../firebase";

import '../vol.css'

import Loader from "../loader";
import SameSerie from "../../components/sameSerie/sameSerie";
import SameAuthors from "../../components/sameAuthors/sameAuthors";


class Manga extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            manga: {},
            url: "",
            firstUpdate: false,
            n: 0,
            get: false
        }
    }

    componentDidMount(){
        
        let url = window.location.href;
        url = url.split("/")[5];
        const id = url.split('&')[0]
        const n = url.split('&')[1]
        const special = url.split('&')[2]

        console.log(special)
        
        this.setState({
            url: {
                id: id,
                n: n,
                special: special
            }
        }, ()=>{
            if(!this.state.get){
                this.setState({
                    get: true
                }, ()=>{
                    this.props.getManga(this.state.url);
                })
            }
        })

    }
    
    componentDidUpdate(prevProps){
        if(this.state.manga !== prevProps.state.manga){
            this.setState({
                manga: this.props.state.manga
            }, ()=>{
                setTimeout(()=>{
                    this.setState({
                        loading: false
                    })
                }, 500)
            })
        }

        if(prevProps.state.status !== this.props.state.status){
            if(this.props.state.status === "success"){
                document.getElementById("shop").disabled = false;
                Swal.fire({
                    icon: "success",
                    title: "Añadido al carrito!"
                })
                .then(()=>{
                    window.location.reload();
                })
            }
        }

        if(prevProps.state.subscribe !== this.props.state.subscribe){
            if(this.props.state.subscribe === "success"){
                Swal.fire({
                    icon: "success",
                    title: `Susripto correctamente!`
                })
                .then(()=>{
                    window.location.reload();
                })
            }else{
                Swal.fire({
                    icon: "warning",
                    title: this.props.state.subscribe
                })
                this.props.state.subscribe = ""
            }
        }

        if(prevProps.state.post_state !== this.props.state.post_state){
            if(this.props.state.post_state === "success"){
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "success",
                    title: "Añadido al favoritos!"
                })
                this.props.state.post_state = ""
            }else{
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "error",
                    title: "Este tomo ya está en favoritos!"
                })
                this.props.state.post_state = ""
            }
        }
    }

    getAutores = () => {
        if(this.state.manga.guion === this.state.manga.dibujo){
            return `${this.state.manga.guion}`
        }else{
            return `${this.state.manga.guion}, ${this.state.manga.dibujo}`
        }
    }

    getState = () => {
        if(this.state.manga.completa){
            return `Estado: Completa`
        }else{
            return `Estado: En curso`
        }
    }

    buy = (e) => {
        e.preventDefault();
        let url = `/itstimetopay?cart=false&item=${this.state.manga.id}`

        window.location.hash = url;
    }

    cart = (e) => {
        e.preventDefault();
        if(auth.currentUser){
            const data = {
                id: auth.currentUser.uid,
                itemId: this.state.manga.id
            }

            this.props.addToCart(data);
            e.target.disabled = true;
        }else{

            const prevStorage = JSON.parse(localStorage.getItem('cart'));

            if(prevStorage.items){
                const cart = {
                    items: [...prevStorage.items, this.state.manga.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }else{
                const cart = {
                    items: [this.state.manga.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }

            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }
    }

    favs = (e) => {
        e.preventDefault();
        if(auth.currentUser){
            e.target.disabled = true;
            this.props.addtofavs({
                user_id: auth.currentUser.uid,
                id: this.state.manga.id
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "Necesitas estar registrado para usar los favoritos!"
            })
        }
    }

    getPrice = () => {
        if(this.state.manga.descuento){
            const precio = this.state.manga.precio
            const descuento = this.state.manga.descuento
            const oldPrecio = Math.floor(100 * precio / (100 - descuento))
            return(
                <span className="precio-container">
                    <h3 className="old-precio">
                        {`$${oldPrecio}`}
                    </h3>
                    <h1 className="vol-price">
                        {`$${precio}`}
                    </h1>
                </span>
            )
        }else{
            return(
                <h1 className="vol-price">
                    {`$${this.state.manga.precio}`}
                </h1>
            )
        }
    }

    isInStock = (e) => {
        if(this.state.manga.stock > 0){
            return(
                <span className={`vol-options-container${e}`}>
                    {this.getPrice()}
                    <button className="vol-buy-button" onClick={this.buy}>
                        <p id="buy" className="vol-p">
                            Comprar
                        </p>
                    </button>
                    <button className="vol-cart-button" onClick={this.cart}>
                        <p className="vol-p">
                            Agregar al carrito
                        </p>
                    </button>
                    <button className="vol-favs-button" onClick={this.favs}>
                        <p className="vol-p">
                            Agregar a favoritos
                        </p>
                    </button>
                </span>
            )
        }else{
            return(
                <span className={`vol-options-container${e}`}>
                    {this.getPrice()}
                    <span className="vol-buy-button" id="stock">
                        <p id="buy" className="vol-p">
                            SIN STOCK
                        </p>
                    </span>
                    <button className="vol-favs-button" onClick={this.favs}>
                        <p className="vol-p">
                            Agregar a favoritos
                        </p>
                    </button>
                </span>
            )
        }
    }

    suscribe = (e) => {
        if(auth.currentUser){
            this.props.subscribe({
                id: auth.currentUser.uid,
                serie: this.state.manga.serie
            })
            e.target.disabled = true
        }else{
            Swal.fire({
                icon: "error",
                title: "Debes estar registrado para suscribirte!"
            })
        }
    }

    getTitle = () => {
        if(parseInt(this.state.manga.vol_amount) === 1 && parseInt(this.state.manga.numero) === 1){
            if(this.state.manga.special){
                return `${this.state.manga.serie} - ${this.state.manga.comment}`
            }else{
                return `${this.state.manga.serie}`
            }
        }else{
            if(this.state.manga.special){
                return `${this.state.manga.serie} #${this.state.manga.numero} - ${this.state.manga.comment}`
            }else{
                return `${this.state.manga.serie} #${this.state.manga.numero}`
            }
        }
    }

    render(){
        if(this.state.loading || !this.state.manga){
            return <Loader />
        }else{
            return(
                <div className="vol-main-container">
                    <span className="this-container">
                        <span className="vol-info-container">
                            <span className="vol-img-container">
                                <img className="vol-img" alt={this.state.manga.serie} src={this.state.manga.imgURL} />
                            </span>
                            <span className="vol-info">
                                <h3 className="vol-title">
                                    {this.getTitle()}
                                </h3>
                                <span className="vol-details">
                                    <p className="vol-detail">
                                        {`Autor/es: ${this.getAutores()}`}
                                    </p>
                                    <p className="vol-detail">
                                        Editorial: {this.state.manga.editorial}
                                    </p>
                                    <p className="vol-detail">
                                        {this.getState()}
                                    </p>
                                    {this.isInStock("-p")}
                                    <button className="suscribe-button" onClick={this.suscribe}>
                                        <p className="suscribe">
                                            Suscribirse a {this.state.manga.serie}
                                        </p>
                                    </button>
                                    <p className="suscribe-info">
                                        Al suscribirse a esta serie, se le enviarán notificaciones cada vez que salga un tomo nuevo u otro vuelva a estar en stock.
                                    </p>
                                </span>
                            </span>
                        </span>
                        {this.isInStock("")}
                    </span>
                    <span className="sinposis-container">
                        <h1 className="sinopsis">
                            SINOPSIS
                        </h1>
                        {this.state.manga.sinopsis}
                    </span>
                    <span className="vol-related">
                        <span className="vol-same">
                            <h1 className="same-title">
                                Más tomos de {this.state.manga.serie}
                            </h1>
                            <SameSerie type="manga" serie={this.state.manga.serie} />
                        </span>
                        <span className="vol-same">
                            <h1 className="same-title">
                                Más tomos de {this.getAutores()}
                            </h1>
                            <SameAuthors type="manga" guion={this.state.manga.guion} dibujo={this.state.manga.dibujo} />
                        </span>
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getManga,
    addToCart,
    addtofavs,
    subscribe
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Manga);