import React from "react";
import "./bestSellers.css";
import { getLibros } from "../../../redux/actions";
import { connect } from 'react-redux';
import Card from "../../../components/card/card";
import "../../loader.css";

class BestSellers extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            n: 0
        }
    }

    componentDidMount(){
        this.props.getLibros();
        if(window.screen.width < 850){
            if(window.screen.width < 450){
                this.setState({
                    n: 2
                })
            }else{
                this.setState({
                    n: 3
                })
            }
        }else{
            this.setState({
                n: 5
            })
        }
    }

    sortLibros = (n)=>{
        let libros = this.props.state.libros;
        let filtered = [];

        let libro_stock = [];

        for(let i = 0; i < libros.length; i++){
            if(libros[i].stock > 0){
                libro_stock.push(libros[i])
            }
        }

        libro_stock.sort((a, b)=>{
            return b.ventas - a.ventas
        })

        for(let i = 0; i < libro_stock.length; i++){
            if(i < this.state.n){
                filtered.push(libro_stock[i]);
            }
        }
    
        return filtered;

    }

    gridLibros = ()=>{
        if(this.props.state.libros[0]){
            let libros = this.sortLibros(1);

            return(
                <div className="grid-mangas">
                    <div className="grid-mangas-a">
                        {
                            libros.map((manga)=>{
                                return(
                                    <Card isTop={true} vol={manga} key={`${manga.serie}${manga.numero}`}/>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }else{
            return(
                <div className="loader" />
            )
        }
    }

    render(){
        return(
            <div className="bs-main-container">
                <div className="bs-title-container">
                    <h1 className="bs-title">
                        LOS MÁS VENDIDOS
                    </h1>
                </div>
                {this.gridLibros()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getLibros
}
const conexion = connect(mapStateToProps, mapDispatchToProps);
export default  conexion(BestSellers);