const tokens = {
    admin: /*"DbX8EkfUIme6Nxj52Ix4vMuHELv2",*/ "r3XUxJFTvvb1Xe5VUNfNlJ5zHNu2",
    firebase: {
        apiKey: "AIzaSyAzk728tA-M01AqAMfVYh_VMBgxOm-ELDU",
        authDomain: "proyecto-comiqueria.firebaseapp.com",
        databaseURL: "https://proyecto-comiqueria-default-rtdb.firebaseio.com",
        projectId: "proyecto-comiqueria",
        storageBucket: "proyecto-comiqueria.appspot.com",
        messagingSenderId: "476957430410",
        appId: "1:476957430410:web:619244a683e08d0bc99373"
    },
    //server: "http://localhost:8000/", //Mi PC
    //server: "https://66.97.37.159:443/",
    server: "https://api-bookhall.com:443/",
    //server: "192.168.0.247:3001"
}

export default tokens;