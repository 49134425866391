const localidades = [
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4394729739475,
      "centroide_lon": -64.8316610855999,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098270000",
      "localidad_censal_id": "14098270",
      "localidad_censal_nombre": "Suco",
      "municipio_id": "142588",
      "municipio_nombre": "Suco",
      "nombre": "SUCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.407625810512,
      "centroide_lon": -67.0961546311905,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010008",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "SALICAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.6132917290185,
      "centroide_lon": -58.3887517365082,
      "departamento_id": null,
      "departamento_nombre": null,
      "id": "02000010000",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CIUDAD DE BUENOS AIRES",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1774801030509,
      "centroide_lon": -62.7578962604015,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007010000",
      "localidad_censal_id": "06007010",
      "localidad_censal_nombre": "Carhué",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "CARHUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4486186469627,
      "centroide_lon": -63.117609405389,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007020000",
      "localidad_censal_id": "06007020",
      "localidad_censal_nombre": "Colonia San Miguel Arcángel",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "COLONIA SAN MIGUEL ARCANGEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3173292631958,
      "centroide_lon": -63.2331690622102,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007030000",
      "localidad_censal_id": "06007030",
      "localidad_censal_nombre": "Delfín Huergo",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "DELFIN HUERGO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -37.3563345638716,
      "centroide_lon": -62.4387637810214,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007040000",
      "localidad_censal_id": "06007040",
      "localidad_censal_nombre": "Espartillar",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "ESPARTILLAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4544378016288,
      "centroide_lon": -63.2565950033782,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007050000",
      "localidad_censal_id": "06007050",
      "localidad_censal_nombre": "Esteban Agustín Gascón",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "ESTEBAN AGUSTIN GASCON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6613400852941,
      "centroide_lon": -63.3661281675236,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007060000",
      "localidad_censal_id": "06007060",
      "localidad_censal_nombre": "La Pala",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "LA PALA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7999468137731,
      "centroide_lon": -63.3385151506224,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007070000",
      "localidad_censal_id": "06007070",
      "localidad_censal_nombre": "Maza",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "MAZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1583546113813,
      "centroide_lon": -63.2442194943605,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007080000",
      "localidad_censal_id": "06007080",
      "localidad_censal_nombre": "Rivera",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "RIVERA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4600992847959,
      "centroide_lon": -63.2405658599852,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007100000",
      "localidad_censal_id": "06007100",
      "localidad_censal_nombre": "Villa Margarita",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "VILLA MARGARITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9884309445004,
      "centroide_lon": -63.133808887439,
      "departamento_id": "06007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "06007110000",
      "localidad_censal_id": "06007110",
      "localidad_censal_nombre": "Yutuyaco",
      "municipio_id": "060007",
      "municipio_nombre": "Adolfo Alsina",
      "nombre": "YUTUYACO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0333995087596,
      "centroide_lon": -60.1003341740637,
      "departamento_id": "06014",
      "departamento_nombre": "Adolfo Gonzales Chaves",
      "id": "06014010000",
      "localidad_censal_id": "06014010",
      "localidad_censal_nombre": "Adolfo Gonzales Chaves",
      "municipio_id": "060014",
      "municipio_nombre": "Adolfo Gonzales Chaves",
      "nombre": "ADOLFO GONZALES CHAVES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9635021758562,
      "centroide_lon": -60.415659383962,
      "departamento_id": "06014",
      "departamento_nombre": "Adolfo Gonzales Chaves",
      "id": "06014020000",
      "localidad_censal_id": "06014020",
      "localidad_censal_nombre": "De La Garma",
      "municipio_id": "060014",
      "municipio_nombre": "Adolfo Gonzales Chaves",
      "nombre": "DE LA GARMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8233871716044,
      "centroide_lon": -60.484639770555,
      "departamento_id": "06014",
      "departamento_nombre": "Adolfo Gonzales Chaves",
      "id": "06014030000",
      "localidad_censal_id": "06014030",
      "localidad_censal_nombre": "Juan E. Barra",
      "municipio_id": "060014",
      "municipio_nombre": "Adolfo Gonzales Chaves",
      "nombre": "JUAN E. BARRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1766861391835,
      "centroide_lon": -60.1708487303777,
      "departamento_id": "06014",
      "departamento_nombre": "Adolfo Gonzales Chaves",
      "id": "06014040000",
      "localidad_censal_id": "06014040",
      "localidad_censal_nombre": "Vásquez",
      "municipio_id": "060014",
      "municipio_nombre": "Adolfo Gonzales Chaves",
      "nombre": "VASQUEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0330734347841,
      "centroide_lon": -60.2806197287099,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021010000",
      "localidad_censal_id": "06021010",
      "localidad_censal_nombre": "Alberti",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "ALBERTI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8681189984321,
      "centroide_lon": -60.3939708823404,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021020000",
      "localidad_censal_id": "06021020",
      "localidad_censal_nombre": "Coronel Seguí",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "CORONEL SEGUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -35.068013673391,
      "centroide_lon": -60.4025971632697,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021030000",
      "localidad_censal_id": "06021030",
      "localidad_censal_nombre": "Mechita",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "MECHITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1243819752343,
      "centroide_lon": -60.2200612615259,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021040000",
      "localidad_censal_id": "06021040",
      "localidad_censal_nombre": "Pla",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "PLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1096178332808,
      "centroide_lon": -60.070551324606,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021050000",
      "localidad_censal_id": "06021050",
      "localidad_censal_nombre": "Villa Grisolía",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "VILLA GRISOLIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8881255447686,
      "centroide_lon": -60.3469385614883,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021060000",
      "localidad_censal_id": "06021060",
      "localidad_censal_nombre": "Villa María",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "VILLA MARIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8435329449862,
      "centroide_lon": -60.3048498633642,
      "departamento_id": "06021",
      "departamento_nombre": "Alberti",
      "id": "06021070000",
      "localidad_censal_id": "06021070",
      "localidad_censal_nombre": "Villa Ortiz",
      "municipio_id": "060021",
      "municipio_nombre": "Alberti",
      "nombre": "VILLA ORTIZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8015580737331,
      "centroide_lon": -58.3914677191541,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010001",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "ADROGUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8315415891783,
      "centroide_lon": -58.3986874871405,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010002",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "BURZACO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8044759080477,
      "centroide_lon": -58.3447825531042,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010003",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "CLAYPOLE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8210751643113,
      "centroide_lon": -58.3447457989957,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010004",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "DON ORIONE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8860114180413,
      "centroide_lon": -58.3818301842512,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010005",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "GLEW",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7851442510713,
      "centroide_lon": -58.3681475603787,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010006",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "JOSE MARMOL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8609450255718,
      "centroide_lon": -58.3891476922426,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010007",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "LONGCHAMPS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8189583169642,
      "centroide_lon": -58.4235610606239,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010008",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "MALVINAS ARGENTINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8556895005541,
      "centroide_lon": -58.361646542791,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010009",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "MINISTRO RIVADAVIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7911367513318,
      "centroide_lon": -58.350598184222,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010010",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "RAFAEL CALZADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7866743578473,
      "centroide_lon": -58.3219363521574,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010011",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "SAN FRANCISCO SOLANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7673771924196,
      "centroide_lon": -58.3432771404358,
      "departamento_id": "06028",
      "departamento_nombre": "Almirante Brown",
      "id": "06028010012",
      "localidad_censal_id": "06028010",
      "localidad_censal_nombre": "Almirante Brown",
      "municipio_id": "060028",
      "municipio_nombre": "Almirante Brown",
      "nombre": "SAN JOSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6750056478488,
      "centroide_lon": -58.3105634248142,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010001",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "AREA CINTURON ECOLOGICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6626508059016,
      "centroide_lon": -58.3656990232718,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010002",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "AVELLANEDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.66910587351,
      "centroide_lon": -58.3574544412345,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010003",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "CRUCESITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6491266928534,
      "centroide_lon": -58.3411774769136,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010004",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "DOCK SUD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6847637540553,
      "centroide_lon": -58.3726025503412,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010005",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "GERLI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6685497926645,
      "centroide_lon": -58.3886029123689,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010006",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "PIÑEYRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6843716640855,
      "centroide_lon": -58.348101313379,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010007",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "SARANDI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6986315148924,
      "centroide_lon": -58.3363602646664,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010008",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "VILLA DOMINICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7061588356333,
      "centroide_lon": -58.3218349208501,
      "departamento_id": "06035",
      "departamento_nombre": "Avellaneda",
      "id": "06035010009",
      "localidad_censal_id": "06035010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "060035",
      "municipio_nombre": "Avellaneda",
      "nombre": "WILDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1536695670417,
      "centroide_lon": -58.4895476662462,
      "departamento_id": "06042",
      "departamento_nombre": "Ayacucho",
      "id": "06042010000",
      "localidad_censal_id": "06042010",
      "localidad_censal_nombre": "Ayacucho",
      "municipio_id": "060042",
      "municipio_nombre": "Ayacucho",
      "nombre": "AYACUCHO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2286256057071,
      "centroide_lon": -58.7603890048806,
      "departamento_id": "06042",
      "departamento_nombre": "Ayacucho",
      "id": "06042020000",
      "localidad_censal_id": "06042020",
      "localidad_censal_nombre": "La Constancia",
      "municipio_id": "060042",
      "municipio_nombre": "Ayacucho",
      "nombre": "LA CONSTANCIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8444984439739,
      "centroide_lon": -58.5071421689017,
      "departamento_id": "06042",
      "departamento_nombre": "Ayacucho",
      "id": "06042030000",
      "localidad_censal_id": "06042030",
      "localidad_censal_nombre": "Solanet",
      "municipio_id": "060042",
      "municipio_nombre": "Ayacucho",
      "nombre": "SOLANET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5637513540211,
      "centroide_lon": -58.5333344196536,
      "departamento_id": "06042",
      "departamento_nombre": "Ayacucho",
      "id": "06042040000",
      "localidad_censal_id": "06042040",
      "localidad_censal_nombre": "Udaquiola",
      "municipio_id": "060042",
      "municipio_nombre": "Ayacucho",
      "nombre": "UDAQUIOLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5312544192466,
      "centroide_lon": -59.9200651844348,
      "departamento_id": "06049",
      "departamento_nombre": "Azul",
      "id": "06049010000",
      "localidad_censal_id": "06049010",
      "localidad_censal_nombre": "Ariel",
      "municipio_id": "060049",
      "municipio_nombre": "Azul",
      "nombre": "ARIEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7795144970313,
      "centroide_lon": -59.8586331707414,
      "departamento_id": "06049",
      "departamento_nombre": "Azul",
      "id": "06049020000",
      "localidad_censal_id": "06049020",
      "localidad_censal_nombre": "Azul",
      "municipio_id": "060049",
      "municipio_nombre": "Azul",
      "nombre": "AZUL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3805626332947,
      "centroide_lon": -59.5030645507265,
      "departamento_id": "06049",
      "departamento_nombre": "Azul",
      "id": "06049030000",
      "localidad_censal_id": "06049030",
      "localidad_censal_nombre": "Cacharí",
      "municipio_id": "060049",
      "municipio_nombre": "Azul",
      "nombre": "CACHARI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3150113247344,
      "centroide_lon": -59.9853181679139,
      "departamento_id": "06049",
      "departamento_nombre": "Azul",
      "id": "06049040000",
      "localidad_censal_id": "06049040",
      "localidad_censal_nombre": "Chillar",
      "municipio_id": "060049",
      "municipio_nombre": "Azul",
      "nombre": "CHILLAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2020524773603,
      "centroide_lon": -60.1652139181813,
      "departamento_id": "06049",
      "departamento_nombre": "Azul",
      "id": "06049050000",
      "localidad_censal_id": "06049050",
      "localidad_censal_nombre": "16 de Julio",
      "municipio_id": "060049",
      "municipio_nombre": "Azul",
      "nombre": "16 DE JULIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7114037284555,
      "centroide_lon": -62.2569089501864,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056010001",
      "localidad_censal_id": "06056010",
      "localidad_censal_nombre": "Bahía Blanca",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "BAHIA BLANCA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7471079502848,
      "centroide_lon": -62.1891166907456,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056010002",
      "localidad_censal_id": "06056010",
      "localidad_censal_nombre": "Bahía Blanca",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "GRÜNBEIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7794670416666,
      "centroide_lon": -62.262951248824,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056010003",
      "localidad_censal_id": "06056010",
      "localidad_censal_nombre": "Bahía Blanca",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "INGENIERO WHITE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.6472605094596,
      "centroide_lon": -62.3200223419024,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056010004",
      "localidad_censal_id": "06056010",
      "localidad_censal_nombre": "Bahía Blanca",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "VILLA BORDEAU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7765069529222,
      "centroide_lon": -62.1851833537179,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056010005",
      "localidad_censal_id": "06056010",
      "localidad_censal_nombre": "Bahía Blanca",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "VILLA ESPORA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.4838572081944,
      "centroide_lon": -61.8926015349487,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056020000",
      "localidad_censal_id": "06056020",
      "localidad_censal_nombre": "Cabildo",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "CABILDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7136524854986,
      "centroide_lon": -62.3924221653991,
      "departamento_id": "06056",
      "departamento_nombre": "Bahía Blanca",
      "id": "06056030000",
      "localidad_censal_id": "06056030",
      "localidad_censal_nombre": "General Daniel Cerri",
      "municipio_id": "060056",
      "municipio_nombre": "Bahía Blanca",
      "nombre": "GENERAL DANIEL CERRI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8482779294345,
      "centroide_lon": -58.2551665841248,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063010000",
      "localidad_censal_id": "06063010",
      "localidad_censal_nombre": "Balcarce",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "BALCARCE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9412057603,
      "centroide_lon": -58.3225920150442,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063020000",
      "localidad_censal_id": "06063020",
      "localidad_censal_nombre": "Los Pinos",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "LOS PINOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6254980210026,
      "centroide_lon": -58.7461862359423,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063030000",
      "localidad_censal_id": "06063030",
      "localidad_censal_nombre": "Napaleofú",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "NAPALEOFU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5426353712017,
      "centroide_lon": -58.3407185507274,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063040000",
      "localidad_censal_id": "06063040",
      "localidad_censal_nombre": "Ramos Otero",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "RAMOS OTERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0122838564587,
      "centroide_lon": -58.355377761682,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063050000",
      "localidad_censal_id": "06063050",
      "localidad_censal_nombre": "San Agustín",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "SAN AGUSTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8596285282151,
      "centroide_lon": -57.9806008736401,
      "departamento_id": "06063",
      "departamento_nombre": "Balcarce",
      "id": "06063060000",
      "localidad_censal_id": "06063060",
      "localidad_censal_nombre": "Villa Laguna La Brava",
      "municipio_id": "060063",
      "municipio_nombre": "Balcarce",
      "nombre": "VILLA LAGUNA LA BRAVA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8128781547156,
      "centroide_lon": -59.5043068852807,
      "departamento_id": "06070",
      "departamento_nombre": "Baradero",
      "id": "06070010000",
      "localidad_censal_id": "06070010",
      "localidad_censal_nombre": "Baradero",
      "municipio_id": "060070",
      "municipio_nombre": "Baradero",
      "nombre": "BARADERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9810489198561,
      "centroide_lon": -59.6715601606315,
      "departamento_id": "06070",
      "departamento_nombre": "Baradero",
      "id": "06070020000",
      "localidad_censal_id": "06070020",
      "localidad_censal_nombre": "Irineo Portela",
      "municipio_id": "060070",
      "municipio_nombre": "Baradero",
      "nombre": "IRINEO PORTELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.062154134736,
      "centroide_lon": -59.5585420642846,
      "departamento_id": "06070",
      "departamento_nombre": "Baradero",
      "id": "06070030000",
      "localidad_censal_id": "06070030",
      "localidad_censal_nombre": "Santa Coloma",
      "municipio_id": "060070",
      "municipio_nombre": "Baradero",
      "nombre": "SANTA COLOMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9096038045591,
      "centroide_lon": -59.3882017132282,
      "departamento_id": "06070",
      "departamento_nombre": "Baradero",
      "id": "06070040000",
      "localidad_censal_id": "06070040",
      "localidad_censal_nombre": "Villa Alsina",
      "municipio_id": "060070",
      "municipio_nombre": "Baradero",
      "nombre": "VILLA ALSINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.064591121211,
      "centroide_lon": -60.1025564443537,
      "departamento_id": "06077",
      "departamento_nombre": "Arrecifes",
      "id": "06077010000",
      "localidad_censal_id": "06077010",
      "localidad_censal_nombre": "Arrecifes",
      "municipio_id": "060077",
      "municipio_nombre": "Arrecifes",
      "nombre": "ARRECIFES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0328240475348,
      "centroide_lon": -60.1562270065746,
      "departamento_id": "06077",
      "departamento_nombre": "Arrecifes",
      "id": "06077020000",
      "localidad_censal_id": "06077020",
      "localidad_censal_nombre": "Todd",
      "municipio_id": "060077",
      "municipio_nombre": "Arrecifes",
      "nombre": "TODD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9922765156087,
      "centroide_lon": -60.2263211205477,
      "departamento_id": "06077",
      "departamento_nombre": "Arrecifes",
      "id": "06077030000",
      "localidad_censal_id": "06077030",
      "localidad_censal_nombre": "Viña",
      "municipio_id": "060077",
      "municipio_nombre": "Arrecifes",
      "nombre": "VI¥A",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6424111302365,
      "centroide_lon": -59.3889380312927,
      "departamento_id": "06084",
      "departamento_nombre": "Benito Juárez",
      "id": "06084010000",
      "localidad_censal_id": "06084010",
      "localidad_censal_nombre": "Barker",
      "municipio_id": "060084",
      "municipio_nombre": "Benito Juárez",
      "nombre": "BARKER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6766410210105,
      "centroide_lon": -59.8057677109444,
      "departamento_id": "06084",
      "departamento_nombre": "Benito Juárez",
      "id": "06084020000",
      "localidad_censal_id": "06084020",
      "localidad_censal_nombre": "Benito Juárez",
      "municipio_id": "060084",
      "municipio_nombre": "Benito Juárez",
      "nombre": "BENITO JUAREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5545120854221,
      "centroide_lon": -59.6278461536299,
      "departamento_id": "06084",
      "departamento_nombre": "Benito Juárez",
      "id": "06084030000",
      "localidad_censal_id": "06084030",
      "localidad_censal_nombre": "López",
      "municipio_id": "060084",
      "municipio_nombre": "Benito Juárez",
      "nombre": "LOPEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3683686253208,
      "centroide_lon": -59.7630391604187,
      "departamento_id": "06084",
      "departamento_nombre": "Benito Juárez",
      "id": "06084040000",
      "localidad_censal_id": "06084040",
      "localidad_censal_nombre": "Tedín Uriburu",
      "municipio_id": "060084",
      "municipio_nombre": "Benito Juárez",
      "nombre": "TEDIN URIBURU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6704778893557,
      "centroide_lon": -59.4003533386514,
      "departamento_id": "06084",
      "departamento_nombre": "Benito Juárez",
      "id": "06084050000",
      "localidad_censal_id": "06084050",
      "localidad_censal_nombre": "Villa Cacique",
      "municipio_id": "060084",
      "municipio_nombre": "Benito Juárez",
      "nombre": "VILLA CACIQUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7597493264937,
      "centroide_lon": -58.2019645234278,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010001",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "BERAZATEGUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7804201858899,
      "centroide_lon": -58.2415010261207,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010002",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "BERAZATEGUI OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8021192214631,
      "centroide_lon": -58.2188212494897,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010003",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "CARLOS TOMAS SOURIGUES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9067557072114,
      "centroide_lon": -58.1966995021239,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010004",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "EL PATO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7897594857633,
      "centroide_lon": -58.1439692638183,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010005",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "GUILLERMO ENRIQUE HUDSON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8348420760566,
      "centroide_lon": -58.1819061125702,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010006",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "JUAN MARIA GUTIERREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8471945391094,
      "centroide_lon": -58.1392765205344,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010007",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "PEREYRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7674201820877,
      "centroide_lon": -58.1652686003148,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010008",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "PLATANOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7941468501172,
      "centroide_lon": -58.1989815634425,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010009",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "RANELAGH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.776063416904,
      "centroide_lon": -58.2002532983623,
      "departamento_id": "06091",
      "departamento_nombre": "Berazategui",
      "id": "06091010010",
      "localidad_censal_id": "06091010",
      "localidad_censal_nombre": "Berazategui",
      "municipio_id": "060091",
      "municipio_nombre": "Berazategui",
      "nombre": "VILLA ESPAÑA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8752589900973,
      "centroide_lon": -57.8550856662876,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010001",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "BARRIO BANCO PROVINCIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9293102150655,
      "centroide_lon": -57.8853552505795,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010002",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "BARRIO EL CARMEN (ESTE)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9027528834433,
      "centroide_lon": -57.9270884333304,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010003",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "BARRIO UNIVERSITARIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8746353679645,
      "centroide_lon": -57.8947472316799,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010004",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "BERISSO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9190209219847,
      "centroide_lon": -57.7909954962894,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010005",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "LOS TALAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9053981881099,
      "centroide_lon": -57.9180815078165,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010006",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA ARGÜELLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8802085383888,
      "centroide_lon": -57.8663036732616,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010007",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA DOLORES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8829519743895,
      "centroide_lon": -57.8614892695719,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010008",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA INDEPENDENCIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8957079362353,
      "centroide_lon": -57.9108816644737,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010009",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA NUEVA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.891043310448,
      "centroide_lon": -57.8963773978494,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010010",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA PORTEÑA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9308766123633,
      "centroide_lon": -57.8468799885622,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010011",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA PROGRESO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8838130805,
      "centroide_lon": -57.87701595642,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010012",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA SAN CARLOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9221646298322,
      "centroide_lon": -57.8009664840662,
      "departamento_id": "06098",
      "departamento_nombre": "Berisso",
      "id": "06098010013",
      "localidad_censal_id": "06098010",
      "localidad_censal_nombre": "Berisso",
      "municipio_id": "060098",
      "municipio_nombre": "Berisso",
      "nombre": "VILLA ZULA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0010429767568,
      "centroide_lon": -60.8534495072374,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105010000",
      "localidad_censal_id": "06105010",
      "localidad_censal_nombre": "Hale",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "HALE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3498770527922,
      "centroide_lon": -61.2552591224518,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105020000",
      "localidad_censal_id": "06105020",
      "localidad_censal_nombre": "Juan F. Ibarra",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "JUAN F. IBARRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5053183545527,
      "centroide_lon": -61.0243287245345,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105040000",
      "localidad_censal_id": "06105040",
      "localidad_censal_nombre": "Paula",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "PAULA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5109170215402,
      "centroide_lon": -61.5545516558692,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105050000",
      "localidad_censal_id": "06105050",
      "localidad_censal_nombre": "Pirovano",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "PIROVANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2295602208798,
      "centroide_lon": -61.1131898679983,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105060000",
      "localidad_censal_id": "06105060",
      "localidad_censal_nombre": "San Carlos de Bolívar",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "SAN CARLOS DE BOLIVAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4329292363695,
      "centroide_lon": -61.4191160192421,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105070000",
      "localidad_censal_id": "06105070",
      "localidad_censal_nombre": "Urdampilleta",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "URDAMPILLETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6025793254646,
      "centroide_lon": -61.3618625494725,
      "departamento_id": "06105",
      "departamento_nombre": "Bolívar",
      "id": "06105080000",
      "localidad_censal_id": "06105080",
      "localidad_censal_nombre": "Villa Lynch Pueyrredón",
      "municipio_id": "060105",
      "municipio_nombre": "Bolívar",
      "nombre": "VILLA LYNCH PUEYRREDON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2267581272748,
      "centroide_lon": -60.4168952837295,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112005000",
      "localidad_censal_id": "06112005",
      "localidad_censal_nombre": "Asamblea",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "ASAMBLEA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.118942299763,
      "centroide_lon": -60.4879147568209,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112010000",
      "localidad_censal_id": "06112010",
      "localidad_censal_nombre": "Bragado",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "BRAGADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3233100374631,
      "centroide_lon": -60.5217314911689,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112020000",
      "localidad_censal_id": "06112020",
      "localidad_censal_nombre": "Comodoro Py",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "COMODORO PY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9067979091956,
      "centroide_lon": -60.7597838238054,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112030000",
      "localidad_censal_id": "06112030",
      "localidad_censal_nombre": "General O'Brien",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "GENERAL O'BRIEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7718230311122,
      "centroide_lon": -60.6916912010278,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112040000",
      "localidad_censal_id": "06112040",
      "localidad_censal_nombre": "Irala",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "IRALA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0797272650156,
      "centroide_lon": -60.5928546665185,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112050000",
      "localidad_censal_id": "06112050",
      "localidad_censal_nombre": "La Limpia",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "LA LIMPIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0615246745825,
      "centroide_lon": -60.7060741424473,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112060000",
      "localidad_censal_id": "06112060",
      "localidad_censal_nombre": "Juan F. Salaberry",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "MAXIMO FERNANDEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -35.0699378566803,
      "centroide_lon": -60.4084937925362,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112070000",
      "localidad_censal_id": "06112070",
      "localidad_censal_nombre": "Mechita",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "MECHITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2375277927366,
      "centroide_lon": -60.6115341696178,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112080000",
      "localidad_censal_id": "06112080",
      "localidad_censal_nombre": "Olascoaga",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "OLASCOAGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9098734445057,
      "centroide_lon": -60.5381681629003,
      "departamento_id": "06112",
      "departamento_nombre": "Bragado",
      "id": "06112090000",
      "localidad_censal_id": "06112090",
      "localidad_censal_nombre": "Warnes",
      "municipio_id": "060112",
      "municipio_nombre": "Bragado",
      "nombre": "WARNES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3615828022139,
      "centroide_lon": -58.1504868159157,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119010000",
      "localidad_censal_id": "06119010",
      "localidad_censal_nombre": "Altamirano",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "ALTAMIRANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3145370547125,
      "centroide_lon": -58.0484420796345,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119015000",
      "localidad_censal_id": "06119015",
      "localidad_censal_nombre": "Barrio El Mirador",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "BARRIO EL MIRADOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0335777783556,
      "centroide_lon": -58.1935382681817,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119020000",
      "localidad_censal_id": "06119020",
      "localidad_censal_nombre": "Barrio Las Golondrinas",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "BARRIO LAS GOLONDRINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1050389949725,
      "centroide_lon": -58.1835007859607,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119030000",
      "localidad_censal_id": "06119030",
      "localidad_censal_nombre": "Barrio Los Bosquecitos",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "BARRIO LOS BOSQUECITOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1030329461442,
      "centroide_lon": -58.2730405375653,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119040000",
      "localidad_censal_id": "06119040",
      "localidad_censal_nombre": "Barrio Parque Las Acacias",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "BARRIO PARQUE LAS ACACIAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1149772603157,
      "centroide_lon": -58.0953896541907,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119045000",
      "localidad_censal_id": "06119045",
      "localidad_censal_nombre": "Campos de Roca",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "CAMPOS DE ROCA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1690983002919,
      "centroide_lon": -58.2373529446643,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119050000",
      "localidad_censal_id": "06119050",
      "localidad_censal_nombre": "Coronel Brandsen",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "CORONEL BRANDSEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2139978115731,
      "centroide_lon": -58.2433030920635,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119055000",
      "localidad_censal_id": "06119055",
      "localidad_censal_nombre": "Club de Campo Las Malvinas",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "CLUB DE CAMPO LAS MALVINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0693655332834,
      "centroide_lon": -58.1656899882306,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119060000",
      "localidad_censal_id": "06119060",
      "localidad_censal_nombre": "Gómez",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "GOMEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2802043920968,
      "centroide_lon": -58.1996649431989,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119070000",
      "localidad_censal_id": "06119070",
      "localidad_censal_nombre": "Jeppener",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "JEPPENER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1842584334678,
      "centroide_lon": -57.9479623248001,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119080000",
      "localidad_censal_id": "06119080",
      "localidad_censal_nombre": "Oliden",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "OLIDEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1499383981993,
      "centroide_lon": -58.0504528667351,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119085000",
      "localidad_censal_id": "06119085",
      "localidad_censal_nombre": "Posada de los Lagos",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "POSADA DE LOS LAGOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2206242719966,
      "centroide_lon": -58.2805297949826,
      "departamento_id": "06119",
      "departamento_nombre": "Brandsen",
      "id": "06119090000",
      "localidad_censal_id": "06119090",
      "localidad_censal_nombre": "Samborombón",
      "municipio_id": "060119",
      "municipio_nombre": "Brandsen",
      "nombre": "SAMBOROMBON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.3160243688815,
      "centroide_lon": -58.9784574815339,
      "departamento_id": "06126",
      "departamento_nombre": "Campana",
      "id": "06126010000",
      "localidad_censal_id": "06126010",
      "localidad_censal_nombre": "Los Cardales",
      "municipio_id": "060126",
      "municipio_nombre": "Campana",
      "nombre": "ALTO LOS CARDALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2530406367633,
      "centroide_lon": -58.9584400368589,
      "departamento_id": "06126",
      "departamento_nombre": "Campana",
      "id": "06126020000",
      "localidad_censal_id": "06126020",
      "localidad_censal_nombre": "Barrio Los Pioneros (Barrio Tavella)",
      "municipio_id": "060126",
      "municipio_nombre": "Campana",
      "nombre": "BARRIO LOS PIONEROS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1639618118269,
      "centroide_lon": -58.9588741035355,
      "departamento_id": "06126",
      "departamento_nombre": "Campana",
      "id": "06126030000",
      "localidad_censal_id": "06126030",
      "localidad_censal_nombre": "Campana",
      "municipio_id": "060126",
      "municipio_nombre": "Campana",
      "nombre": "CAMPANA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.2934153226239,
      "centroide_lon": -58.921199044246,
      "departamento_id": "06126",
      "departamento_nombre": "Campana",
      "id": "06126035000",
      "localidad_censal_id": "06126035",
      "localidad_censal_nombre": "Chacras del Río Luján",
      "municipio_id": "060126",
      "municipio_nombre": "Campana",
      "nombre": "CHACRAS DEL RIO LUJAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.2816770641806,
      "centroide_lon": -58.8911906524052,
      "departamento_id": "06126",
      "departamento_nombre": "Campana",
      "id": "06126040000",
      "localidad_censal_id": "06126040",
      "localidad_censal_nombre": "Río Luján",
      "municipio_id": "060126",
      "municipio_nombre": "Campana",
      "nombre": "LOMAS DEL RIO LUJAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9793768508134,
      "centroide_lon": -58.6749430768989,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134010000",
      "localidad_censal_id": "06134010",
      "localidad_censal_nombre": "Alejandro Petión",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "ALEJANDRO PETION",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0742486164225,
      "centroide_lon": -58.8632420603475,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134020000",
      "localidad_censal_id": "06134020",
      "localidad_censal_nombre": "Barrio El Taladro",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "BARRIO EL TALADRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0527140350539,
      "centroide_lon": -58.7583856965375,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134030000",
      "localidad_censal_id": "06134030",
      "localidad_censal_nombre": "Cañuelas",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "CA¥UELAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3003271130454,
      "centroide_lon": -58.5943053657455,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134040000",
      "localidad_censal_id": "06134040",
      "localidad_censal_nombre": "Gobernador Udaondo",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "GOBERNADOR UDAONDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9022941561353,
      "centroide_lon": -58.6479010170021,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134050001",
      "localidad_censal_id": "06134050",
      "localidad_censal_nombre": "Máximo Paz",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "BARRIO BELGRANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9364563092157,
      "centroide_lon": -58.6143957995956,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134050002",
      "localidad_censal_id": "06134050",
      "localidad_censal_nombre": "Máximo Paz",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "MAXIMO PAZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9611839371811,
      "centroide_lon": -58.7308918275978,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134060000",
      "localidad_censal_id": "06134060",
      "localidad_censal_nombre": "Santa Rosa",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "SANTA ROSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1227364407356,
      "centroide_lon": -58.8904871463421,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134070000",
      "localidad_censal_id": "06134070",
      "localidad_censal_nombre": "Uribelarrea",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "URIBELARREA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9653526702416,
      "centroide_lon": -58.6504979594296,
      "departamento_id": "06134",
      "departamento_nombre": "Cañuelas",
      "id": "06134080000",
      "localidad_censal_id": "06134080",
      "localidad_censal_nombre": "Vicente Casares",
      "municipio_id": "060134",
      "municipio_nombre": "Cañuelas",
      "nombre": "VICENTE CASARES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1723674255219,
      "centroide_lon": -59.789349139091,
      "departamento_id": "06140",
      "departamento_nombre": "Capitán Sarmiento",
      "id": "06140010000",
      "localidad_censal_id": "06140010",
      "localidad_censal_nombre": "Capitán Sarmiento",
      "municipio_id": "060140",
      "municipio_nombre": "Capitán Sarmiento",
      "nombre": "CAPITAN SARMIENTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1285572110784,
      "centroide_lon": -59.9240058312679,
      "departamento_id": "06140",
      "departamento_nombre": "Capitán Sarmiento",
      "id": "06140020000",
      "localidad_censal_id": "06140020",
      "localidad_censal_nombre": "La Luisa",
      "municipio_id": "060140",
      "municipio_nombre": "Capitán Sarmiento",
      "nombre": "LA LUISA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9189705554631,
      "centroide_lon": -61.5312630222769,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147010000",
      "localidad_censal_id": "06147010",
      "localidad_censal_nombre": "Bellocq",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "BELLOCQ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7727531397053,
      "centroide_lon": -61.3353879836968,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147020000",
      "localidad_censal_id": "06147020",
      "localidad_censal_nombre": "Cadret",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "CADRET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.623543502046,
      "centroide_lon": -61.3653159121918,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147030000",
      "localidad_censal_id": "06147030",
      "localidad_censal_nombre": "Carlos Casares",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "CARLOS CASARES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5249595511909,
      "centroide_lon": -61.4378670824782,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147040000",
      "localidad_censal_id": "06147040",
      "localidad_censal_nombre": "Colonia Mauricio",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "COLONIA MAURICIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9277092575349,
      "centroide_lon": -61.262290153739,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147050000",
      "localidad_censal_id": "06147050",
      "localidad_censal_nombre": "Hortensia",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "HORTENSIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7003907357414,
      "centroide_lon": -61.1702865161871,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147060000",
      "localidad_censal_id": "06147060",
      "localidad_censal_nombre": "La Sofía",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "LA SOFIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.583017649873,
      "centroide_lon": -61.5244457086778,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147070000",
      "localidad_censal_id": "06147070",
      "localidad_censal_nombre": "Mauricio Hirsch",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "MAURICIO HIRSCH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4774725641963,
      "centroide_lon": -61.4930734927677,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147080000",
      "localidad_censal_id": "06147080",
      "localidad_censal_nombre": "Moctezuma",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "MOCTEZUMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8831262095535,
      "centroide_lon": -61.1594280439031,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147090000",
      "localidad_censal_id": "06147090",
      "localidad_censal_nombre": "Ordoqui",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "ORDOQUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6740915009624,
      "centroide_lon": -61.5066664301675,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147095000",
      "localidad_censal_id": "06147095",
      "localidad_censal_nombre": "Santo Tomás",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "SANTO TOMAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4946290260766,
      "centroide_lon": -61.5937811081594,
      "departamento_id": "06147",
      "departamento_nombre": "Carlos Casares",
      "id": "06147100000",
      "localidad_censal_id": "06147100",
      "localidad_censal_nombre": "Smith",
      "municipio_id": "060147",
      "municipio_nombre": "Carlos Casares",
      "nombre": "SMITH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3925733302196,
      "centroide_lon": -62.4193023144118,
      "departamento_id": "06154",
      "departamento_nombre": "Carlos Tejedor",
      "id": "06154010000",
      "localidad_censal_id": "06154010",
      "localidad_censal_nombre": "Carlos Tejedor",
      "municipio_id": "060154",
      "municipio_nombre": "Carlos Tejedor",
      "nombre": "CARLOS TEJEDOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4388157700903,
      "centroide_lon": -62.7252382146864,
      "departamento_id": "06154",
      "departamento_nombre": "Carlos Tejedor",
      "id": "06154020000",
      "localidad_censal_id": "06154020",
      "localidad_censal_nombre": "Colonia Seré",
      "municipio_id": "060154",
      "municipio_nombre": "Carlos Tejedor",
      "nombre": "COLONIA SERE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6403503028113,
      "centroide_lon": -62.1924372294866,
      "departamento_id": "06154",
      "departamento_nombre": "Carlos Tejedor",
      "id": "06154030000",
      "localidad_censal_id": "06154030",
      "localidad_censal_nombre": "Curarú",
      "municipio_id": "060154",
      "municipio_nombre": "Carlos Tejedor",
      "nombre": "CURARU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3477615165229,
      "centroide_lon": -62.2246532791856,
      "departamento_id": "06154",
      "departamento_nombre": "Carlos Tejedor",
      "id": "06154040000",
      "localidad_censal_id": "06154040",
      "localidad_censal_nombre": "Timote",
      "municipio_id": "060154",
      "municipio_nombre": "Carlos Tejedor",
      "nombre": "TIMOTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1979815144911,
      "centroide_lon": -62.7730814064669,
      "departamento_id": "06154",
      "departamento_nombre": "Carlos Tejedor",
      "id": "06154050000",
      "localidad_censal_id": "06154050",
      "localidad_censal_nombre": "Tres Algarrobos",
      "municipio_id": "060154",
      "municipio_nombre": "Carlos Tejedor",
      "nombre": "TRES ALGARROBOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3776987251735,
      "centroide_lon": -59.8229019801033,
      "departamento_id": "06161",
      "departamento_nombre": "Carmen de Areco",
      "id": "06161010000",
      "localidad_censal_id": "06161010",
      "localidad_censal_nombre": "Carmen de Areco",
      "municipio_id": "060161",
      "municipio_nombre": "Carmen de Areco",
      "nombre": "CARMEN DE ARECO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4951191416005,
      "centroide_lon": -59.8029737383366,
      "departamento_id": "06161",
      "departamento_nombre": "Carmen de Areco",
      "id": "06161020000",
      "localidad_censal_id": "06161020",
      "localidad_censal_nombre": "Pueblo Gouin",
      "municipio_id": "060161",
      "municipio_nombre": "Carmen de Areco",
      "nombre": "PUEBLO GOUIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4664778301466,
      "centroide_lon": -60.0008697422009,
      "departamento_id": "06161",
      "departamento_nombre": "Carmen de Areco",
      "id": "06161030000",
      "localidad_censal_id": "06161030",
      "localidad_censal_nombre": "Tres Sargentos",
      "municipio_id": "060161",
      "municipio_nombre": "Carmen de Areco",
      "nombre": "TRES SARGENTOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.091694119436,
      "centroide_lon": -57.8071801619355,
      "departamento_id": "06168",
      "departamento_nombre": "Castelli",
      "id": "06168010000",
      "localidad_censal_id": "06168010",
      "localidad_censal_nombre": "Castelli",
      "municipio_id": "060168",
      "municipio_nombre": "Castelli",
      "nombre": "CASTELLI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0570621940227,
      "centroide_lon": -57.8235422591576,
      "departamento_id": "06168",
      "departamento_nombre": "Castelli",
      "id": "06168020000",
      "localidad_censal_id": "06168020",
      "localidad_censal_nombre": "Centro Guerrero",
      "municipio_id": "060168",
      "municipio_nombre": "Castelli",
      "nombre": "CENTRO GUERRERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9760530599951,
      "centroide_lon": -57.4487354447874,
      "departamento_id": "06168",
      "departamento_nombre": "Castelli",
      "id": "06168030000",
      "localidad_censal_id": "06168030",
      "localidad_censal_nombre": "Cerro de la Gloria",
      "municipio_id": "060168",
      "municipio_nombre": "Castelli",
      "nombre": "CERRO DE LA GLORIA (CANAL 15)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8978633606345,
      "centroide_lon": -61.099560506382,
      "departamento_id": "06175",
      "departamento_nombre": "Colón",
      "id": "06175010000",
      "localidad_censal_id": "06175010",
      "localidad_censal_nombre": "Colón",
      "municipio_id": "060175",
      "municipio_nombre": "Colón",
      "nombre": "COLON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.9147838019682,
      "centroide_lon": -60.9438081845998,
      "departamento_id": "06175",
      "departamento_nombre": "Colón",
      "id": "06175020000",
      "localidad_censal_id": "06175020",
      "localidad_censal_nombre": "Villa Manuel Pomar",
      "municipio_id": "060175",
      "municipio_nombre": "Colón",
      "nombre": "EL ARBOLITO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6518963027432,
      "centroide_lon": -60.8922239880462,
      "departamento_id": "06175",
      "departamento_nombre": "Colón",
      "id": "06175030000",
      "localidad_censal_id": "06175030",
      "localidad_censal_nombre": "Pearson",
      "municipio_id": "060175",
      "municipio_nombre": "Colón",
      "nombre": "PEARSON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0523353092344,
      "centroide_lon": -61.2019040914739,
      "departamento_id": "06175",
      "departamento_nombre": "Colón",
      "id": "06175040000",
      "localidad_censal_id": "06175040",
      "localidad_censal_nombre": "Sarasa",
      "municipio_id": "060175",
      "municipio_nombre": "Colón",
      "nombre": "SARASA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7648159927502,
      "centroide_lon": -61.9184051093309,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182010000",
      "localidad_censal_id": "06182010",
      "localidad_censal_nombre": "Bajo Hondo",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "BAJO HONDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9961963948629,
      "centroide_lon": -61.5471990087107,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182020000",
      "localidad_censal_id": "06182020",
      "localidad_censal_nombre": "Balneario Pehuen Co",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "BALNEARIO PEHUEN CO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7839402799391,
      "centroide_lon": -62.1195194756714,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182025000",
      "localidad_censal_id": "06182025",
      "localidad_censal_nombre": "Pago Chico",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "PAGO CHICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -38.8813527346955,
      "centroide_lon": -62.0749536088688,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182030000",
      "localidad_censal_id": "06182030",
      "localidad_censal_nombre": "Punta Alta",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "PUNTA ALTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8823547797498,
      "centroide_lon": -62.0626706347168,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182030001",
      "localidad_censal_id": "06182030",
      "localidad_censal_nombre": "Punta Alta",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "PUNTA ALTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8532242630709,
      "centroide_lon": -62.1164752039925,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182030002",
      "localidad_censal_id": "06182030",
      "localidad_censal_nombre": "Punta Alta",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "VILLA DEL MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8069001411051,
      "centroide_lon": -62.09498539538,
      "departamento_id": "06182",
      "departamento_nombre": "Coronel de Marina Leonardo Rosales",
      "id": "06182050000",
      "localidad_censal_id": "06182050",
      "localidad_censal_nombre": "Villa General Arias",
      "municipio_id": "060182",
      "municipio_nombre": "Coronel de Marina Leonardo Rosales",
      "nombre": "VILLA GENERAL ARIAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6204949120776,
      "centroide_lon": -60.8809603426407,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189010000",
      "localidad_censal_id": "06189010",
      "localidad_censal_nombre": "Aparicio",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "APARICIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9224906889726,
      "centroide_lon": -60.5329774471942,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189020000",
      "localidad_censal_id": "06189020",
      "localidad_censal_nombre": "Marisol",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "BALNEARIO MARISOL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7166239148323,
      "centroide_lon": -61.2884948879698,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189030000",
      "localidad_censal_id": "06189030",
      "localidad_censal_nombre": "Coronel Dorrego",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "CORONEL DORREGO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6757752787604,
      "centroide_lon": -61.0884421797928,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189040000",
      "localidad_censal_id": "06189040",
      "localidad_censal_nombre": "El Perdido",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "EL PERDIDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7966781095909,
      "centroide_lon": -61.0688772134763,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189050000",
      "localidad_censal_id": "06189050",
      "localidad_censal_nombre": "Faro",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "FARO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5543426342658,
      "centroide_lon": -60.6954938748393,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189060000",
      "localidad_censal_id": "06189060",
      "localidad_censal_nombre": "Irene",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "IRENE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7388491145692,
      "centroide_lon": -60.6092238150829,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189070000",
      "localidad_censal_id": "06189070",
      "localidad_censal_nombre": "Oriente",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "ORIENTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6534164106777,
      "centroide_lon": -60.8608557685895,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189075000",
      "localidad_censal_id": "06189075",
      "localidad_censal_nombre": "Paraje La Ruta",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "LA RUTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7415550885108,
      "centroide_lon": -61.5377200112301,
      "departamento_id": "06189",
      "departamento_nombre": "Coronel Dorrego",
      "id": "06189080000",
      "localidad_censal_id": "06189080",
      "localidad_censal_nombre": "San Román",
      "municipio_id": "060189",
      "municipio_nombre": "Coronel Dorrego",
      "nombre": "SAN ROMAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9865210556901,
      "centroide_lon": -61.3540715661069,
      "departamento_id": "06196",
      "departamento_nombre": "Coronel Pringles",
      "id": "06196010000",
      "localidad_censal_id": "06196010",
      "localidad_censal_nombre": "Coronel Pringles",
      "municipio_id": "060196",
      "municipio_nombre": "Coronel Pringles",
      "nombre": "CORONEL PRINGLES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3235636030787,
      "centroide_lon": -61.4450578127822,
      "departamento_id": "06196",
      "departamento_nombre": "Coronel Pringles",
      "id": "06196020000",
      "localidad_censal_id": "06196020",
      "localidad_censal_nombre": "El Divisorio",
      "municipio_id": "060196",
      "municipio_nombre": "Coronel Pringles",
      "nombre": "EL DIVISORIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2154094275446,
      "centroide_lon": -61.3145847771451,
      "departamento_id": "06196",
      "departamento_nombre": "Coronel Pringles",
      "id": "06196030000",
      "localidad_censal_id": "06196030",
      "localidad_censal_nombre": "El Pensamiento",
      "municipio_id": "060196",
      "municipio_nombre": "Coronel Pringles",
      "nombre": "EL PENSAMIENTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3293770967784,
      "centroide_lon": -60.8866340266383,
      "departamento_id": "06196",
      "departamento_nombre": "Coronel Pringles",
      "id": "06196040000",
      "localidad_censal_id": "06196040",
      "localidad_censal_nombre": "Indio Rico",
      "municipio_id": "060196",
      "municipio_nombre": "Coronel Pringles",
      "nombre": "INDIO RICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.44591903663,
      "centroide_lon": -61.566096860456,
      "departamento_id": "06196",
      "departamento_nombre": "Coronel Pringles",
      "id": "06196050000",
      "localidad_censal_id": "06196050",
      "localidad_censal_nombre": "Lartigau",
      "municipio_id": "060196",
      "municipio_nombre": "Coronel Pringles",
      "nombre": "LARTIGAU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2899615825436,
      "centroide_lon": -62.2971682457111,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203010000",
      "localidad_censal_id": "06203010",
      "localidad_censal_nombre": "Cascada",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "CASCADAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4596224938744,
      "centroide_lon": -61.9317530113989,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203020000",
      "localidad_censal_id": "06203020",
      "localidad_censal_nombre": "Coronel Suárez",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "CORONEL SUAREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4843018699441,
      "centroide_lon": -62.10361959864,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203030000",
      "localidad_censal_id": "06203030",
      "localidad_censal_nombre": "Curamalal",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "CURA MALAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6772293087783,
      "centroide_lon": -61.7052608546557,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203040000",
      "localidad_censal_id": "06203040",
      "localidad_censal_nombre": "D'Orbigny",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "D'ORBIGNY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0622288209544,
      "centroide_lon": -61.9297529087938,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203050000",
      "localidad_censal_id": "06203050",
      "localidad_censal_nombre": "Huanguelén",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "HUANGUELEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2234351404125,
      "centroide_lon": -62.1602259170472,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203060000",
      "localidad_censal_id": "06203060",
      "localidad_censal_nombre": "Pasman",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "PASMAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5077480260109,
      "centroide_lon": -61.9211473085303,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203070000",
      "localidad_censal_id": "06203070",
      "localidad_censal_nombre": "San José",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "SAN JOSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5565856774998,
      "centroide_lon": -61.872634363442,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203080000",
      "localidad_censal_id": "06203080",
      "localidad_censal_nombre": "Santa María",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "SANTA MARIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4891036510029,
      "centroide_lon": -61.9252761219778,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203090000",
      "localidad_censal_id": "06203090",
      "localidad_censal_nombre": "Santa Trinidad",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "SANTA TRINIDAD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.1345025284944,
      "centroide_lon": -61.7885553500206,
      "departamento_id": "06203",
      "departamento_nombre": "Coronel Suárez",
      "id": "06203100000",
      "localidad_censal_id": "06203100",
      "localidad_censal_nombre": "Villa La Arcadia",
      "municipio_id": "060203",
      "municipio_nombre": "Coronel Suárez",
      "nombre": "VILLA LA ARCADIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6131247014799,
      "centroide_lon": -59.9005194580559,
      "departamento_id": "06210",
      "departamento_nombre": "Chacabuco",
      "id": "06210010000",
      "localidad_censal_id": "06210010",
      "localidad_censal_nombre": "Castilla",
      "municipio_id": "060210",
      "municipio_nombre": "Chacabuco",
      "nombre": "CASTILLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6429843999409,
      "centroide_lon": -60.4701843504346,
      "departamento_id": "06210",
      "departamento_nombre": "Chacabuco",
      "id": "06210020000",
      "localidad_censal_id": "06210020",
      "localidad_censal_nombre": "Chacabuco",
      "municipio_id": "060210",
      "municipio_nombre": "Chacabuco",
      "nombre": "CHACABUCO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4575064000002,
      "centroide_lon": -60.1801845132051,
      "departamento_id": "06210",
      "departamento_nombre": "Chacabuco",
      "id": "06210030000",
      "localidad_censal_id": "06210030",
      "localidad_censal_nombre": "Los Angeles",
      "municipio_id": "060210",
      "municipio_nombre": "Chacabuco",
      "nombre": "LOS ANGELES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5854707868254,
      "centroide_lon": -60.6986325840689,
      "departamento_id": "06210",
      "departamento_nombre": "Chacabuco",
      "id": "06210040000",
      "localidad_censal_id": "06210040",
      "localidad_censal_nombre": "O'Higgins",
      "municipio_id": "060210",
      "municipio_nombre": "Chacabuco",
      "nombre": "O'HIGGINS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6086374337309,
      "centroide_lon": -60.0677106761007,
      "departamento_id": "06210",
      "departamento_nombre": "Chacabuco",
      "id": "06210050000",
      "localidad_censal_id": "06210050",
      "localidad_censal_nombre": "Rawson",
      "municipio_id": "060210",
      "municipio_nombre": "Chacabuco",
      "nombre": "RAWSON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5739889229165,
      "centroide_lon": -58.0680436475186,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217003000",
      "localidad_censal_id": "06217003",
      "localidad_censal_nombre": "Barrio Lomas Altas",
      "municipio_id": "060218",
      "municipio_nombre": "Chascomús",
      "nombre": "BARRIO LOMAS ALTAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.5743009068199,
      "centroide_lon": -58.0005532563664,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217010001",
      "localidad_censal_id": "06217010",
      "localidad_censal_nombre": "Chascomús",
      "municipio_id": "060218",
      "municipio_nombre": "Chascomús",
      "nombre": "CHASCOMUS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.5466815668936,
      "centroide_lon": -58.0013258753826,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217010003",
      "localidad_censal_id": "06217010",
      "localidad_censal_nombre": "Chascomús",
      "municipio_id": "060218",
      "municipio_nombre": "Chascomús",
      "nombre": "BARRIO SAN CAYETANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5404169288645,
      "centroide_lon": -58.1349194201315,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217015000",
      "localidad_censal_id": "06217015",
      "localidad_censal_nombre": "Laguna Vitel",
      "municipio_id": "060218",
      "municipio_nombre": "Chascomús",
      "nombre": "LAGUNA VITEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8748248734085,
      "centroide_lon": -57.8964226760932,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217020000",
      "localidad_censal_id": "06217020",
      "localidad_censal_nombre": "Manuel J. Cobo",
      "municipio_id": "060466",
      "municipio_nombre": "Lezama",
      "nombre": "MANUEL J. COBO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6282696386352,
      "centroide_lon": -58.0145524400059,
      "departamento_id": "06217",
      "departamento_nombre": "Chascomús",
      "id": "06217030000",
      "localidad_censal_id": "06217030",
      "localidad_censal_nombre": "Villa Parque Girado",
      "municipio_id": "060218",
      "municipio_nombre": "Chascomús",
      "nombre": "VILLA PARQUE GIRADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.9660329017519,
      "centroide_lon": -60.1236385563996,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224005000",
      "localidad_censal_id": "06224005",
      "localidad_censal_nombre": "Benitez",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "BENITEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8980163772726,
      "centroide_lon": -60.0188841030321,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224010000",
      "localidad_censal_id": "06224010",
      "localidad_censal_nombre": "Chivilcoy",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "CHIVILCOY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.746060108407,
      "centroide_lon": -60.0392314192743,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224020000",
      "localidad_censal_id": "06224020",
      "localidad_censal_nombre": "Emilio Ayarza",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "EMILIO AYARZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.839127302807,
      "centroide_lon": -59.8646671984292,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224030000",
      "localidad_censal_id": "06224030",
      "localidad_censal_nombre": "Gorostiaga",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "GOROSTIAGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9739189538858,
      "centroide_lon": -59.8639302070489,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224040000",
      "localidad_censal_id": "06224040",
      "localidad_censal_nombre": "La Rica",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "LA RICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0926582854313,
      "centroide_lon": -59.7745302971612,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224050000",
      "localidad_censal_id": "06224050",
      "localidad_censal_nombre": "Moquehuá",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "MOQUEHUA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0858455213162,
      "centroide_lon": -59.9236682591744,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224060000",
      "localidad_censal_id": "06224060",
      "localidad_censal_nombre": "Ramón Biaus",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "RAMON BIAUS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9443783815263,
      "centroide_lon": -59.7018597369347,
      "departamento_id": "06224",
      "departamento_nombre": "Chivilcoy",
      "id": "06224070000",
      "localidad_censal_id": "06224070",
      "localidad_censal_nombre": "San Sebastián",
      "municipio_id": "060224",
      "municipio_nombre": "Chivilcoy",
      "nombre": "SAN SEBASTIAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5640579804658,
      "centroide_lon": -62.1324009778233,
      "departamento_id": "06231",
      "departamento_nombre": "Daireaux",
      "id": "06231010000",
      "localidad_censal_id": "06231010",
      "localidad_censal_nombre": "Andant",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "ANDANT",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8827708539235,
      "centroide_lon": -61.4878179060517,
      "departamento_id": "06231",
      "departamento_nombre": "Daireaux",
      "id": "06231020000",
      "localidad_censal_id": "06231020",
      "localidad_censal_nombre": "Arboledas",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "ARBOLEDAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6001749213746,
      "centroide_lon": -61.7450132654369,
      "departamento_id": "06231",
      "departamento_nombre": "Daireaux",
      "id": "06231030000",
      "localidad_censal_id": "06231030",
      "localidad_censal_nombre": "Daireaux",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "DAIREAUX",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6749256386405,
      "centroide_lon": -61.9291952273289,
      "departamento_id": "06231",
      "departamento_nombre": "Daireaux",
      "id": "06231040000",
      "localidad_censal_id": "06231040",
      "localidad_censal_nombre": "La Larga",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "LA LARGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.307117163487,
      "centroide_lon": -62.2003365899,
      "departamento_id": "06231",
      "departamento_nombre": "Daireaux",
      "id": "06231060000",
      "localidad_censal_id": "06231060",
      "localidad_censal_nombre": "Salazar",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "SALAZAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3161215739926,
      "centroide_lon": -57.6752657333964,
      "departamento_id": "06238",
      "departamento_nombre": "Dolores",
      "id": "06238010000",
      "localidad_censal_id": "06238010",
      "localidad_censal_nombre": "Dolores",
      "municipio_id": "060238",
      "municipio_nombre": "Dolores",
      "nombre": "DOLORES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2067284269712,
      "centroide_lon": -57.7413707296745,
      "departamento_id": "06238",
      "departamento_nombre": "Dolores",
      "id": "06238020000",
      "localidad_censal_id": "06238020",
      "localidad_censal_nombre": "Sevigne",
      "municipio_id": "060238",
      "municipio_nombre": "Dolores",
      "nombre": "SEVIGNE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8758485548313,
      "centroide_lon": -57.9336298993983,
      "departamento_id": "06245",
      "departamento_nombre": "Ensenada",
      "id": "06245010001",
      "localidad_censal_id": "06245010",
      "localidad_censal_nombre": "Ensenada",
      "municipio_id": "060245",
      "municipio_nombre": "Ensenada",
      "nombre": "DIQUE Nº 1",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8679770208454,
      "centroide_lon": -57.9214379246659,
      "departamento_id": "06245",
      "departamento_nombre": "Ensenada",
      "id": "06245010002",
      "localidad_censal_id": "06245010",
      "localidad_censal_nombre": "Ensenada",
      "municipio_id": "060245",
      "municipio_nombre": "Ensenada",
      "nombre": "ENSENADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8370461990756,
      "centroide_lon": -57.8999620408998,
      "departamento_id": "06245",
      "departamento_nombre": "Ensenada",
      "id": "06245010003",
      "localidad_censal_id": "06245010",
      "localidad_censal_nombre": "Ensenada",
      "municipio_id": "060245",
      "municipio_nombre": "Ensenada",
      "nombre": "ISLA SANTIAGO (OESTE)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8346689394776,
      "centroide_lon": -58.0010481870988,
      "departamento_id": "06245",
      "departamento_nombre": "Ensenada",
      "id": "06245010004",
      "localidad_censal_id": "06245010",
      "localidad_censal_nombre": "Ensenada",
      "municipio_id": "060245",
      "municipio_nombre": "Ensenada",
      "nombre": "PUNTA LARA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.884600148052,
      "centroide_lon": -57.9511290255284,
      "departamento_id": "06245",
      "departamento_nombre": "Ensenada",
      "id": "06245010005",
      "localidad_censal_id": "06245010",
      "localidad_censal_nombre": "Ensenada",
      "municipio_id": "060245",
      "municipio_nombre": "Ensenada",
      "nombre": "VILLA CATELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3516704130246,
      "centroide_lon": -58.7832526742638,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010001",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "BELEN DE ESCOBAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3034904902207,
      "centroide_lon": -58.7811274560113,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010002",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "EL CAZADOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.428785229178,
      "centroide_lon": -58.7361732596546,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010003",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "GARIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3790006332322,
      "centroide_lon": -58.7303342412363,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010004",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "INGENIERO MASCHWITZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3285234890863,
      "centroide_lon": -58.8506997728362,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010005",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "LOMA VERDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3688779666182,
      "centroide_lon": -58.8402291878847,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010006",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "MATHEU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3964888336318,
      "centroide_lon": -58.7629458829717,
      "departamento_id": "06252",
      "departamento_nombre": "Escobar",
      "id": "06252010007",
      "localidad_censal_id": "06252010",
      "localidad_censal_nombre": "Escobar",
      "municipio_id": "060252",
      "municipio_nombre": "Escobar",
      "nombre": "MAQUINISTA F. SAVIO ESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8849791351051,
      "centroide_lon": -58.4778099372334,
      "departamento_id": "06260",
      "departamento_nombre": "Esteban Echeverría",
      "id": "06260010001",
      "localidad_censal_id": "06260010",
      "localidad_censal_nombre": "Esteban Echeverría",
      "municipio_id": "060260",
      "municipio_nombre": "Esteban Echeverría",
      "nombre": "CANNING",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8304505041023,
      "centroide_lon": -58.494869419897,
      "departamento_id": "06260",
      "departamento_nombre": "Esteban Echeverría",
      "id": "06260010002",
      "localidad_censal_id": "06260010",
      "localidad_censal_nombre": "Esteban Echeverría",
      "municipio_id": "060260",
      "municipio_nombre": "Esteban Echeverría",
      "nombre": "EL JAGÜEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.800196435999,
      "centroide_lon": -58.4530964052639,
      "departamento_id": "06260",
      "departamento_nombre": "Esteban Echeverría",
      "id": "06260010003",
      "localidad_censal_id": "06260010",
      "localidad_censal_nombre": "Esteban Echeverría",
      "municipio_id": "060260",
      "municipio_nombre": "Esteban Echeverría",
      "nombre": "LUIS GUILLON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8191368630736,
      "centroide_lon": -58.4726947603508,
      "departamento_id": "06260",
      "departamento_nombre": "Esteban Echeverría",
      "id": "06260010004",
      "localidad_censal_id": "06260010",
      "localidad_censal_nombre": "Esteban Echeverría",
      "municipio_id": "060260",
      "municipio_nombre": "Esteban Echeverría",
      "nombre": "MONTE GRANDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7565075768115,
      "centroide_lon": -58.4902923509723,
      "departamento_id": "06260",
      "departamento_nombre": "Esteban Echeverría",
      "id": "06260010005",
      "localidad_censal_id": "06260010",
      "localidad_censal_nombre": "Esteban Echeverría",
      "municipio_id": "060260",
      "municipio_nombre": "Esteban Echeverría",
      "nombre": "9 DE ABRIL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3363786908945,
      "centroide_lon": -59.1094149717708,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266010000",
      "localidad_censal_id": "06266010",
      "localidad_censal_nombre": "Arroyo de la Cruz",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "ARROYO DE LA CRUZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2912108668035,
      "centroide_lon": -59.1015232724441,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266020000",
      "localidad_censal_id": "06266020",
      "localidad_censal_nombre": "Capilla del Señor",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "CAPILLA DEL SE¥OR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.288973975279,
      "centroide_lon": -59.222781222713,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266030000",
      "localidad_censal_id": "06266030",
      "localidad_censal_nombre": "Diego Gaynor",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "DIEGO GAYNOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.3300736993853,
      "centroide_lon": -58.9885651588167,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266040000",
      "localidad_censal_id": "06266040",
      "localidad_censal_nombre": "Los Cardales",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "LOS CARDALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3273003551547,
      "centroide_lon": -59.0759905460338,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266050000",
      "localidad_censal_id": "06266050",
      "localidad_censal_nombre": "Parada Orlando",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "PARADA ORLANDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4149477393736,
      "centroide_lon": -59.086281182388,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266060001",
      "localidad_censal_id": "06266060",
      "localidad_censal_nombre": "Parada Robles - Pavón",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "EL REMANSO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3736266959435,
      "centroide_lon": -59.139354021597,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266060002",
      "localidad_censal_id": "06266060",
      "localidad_censal_nombre": "Parada Robles - Pavón",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "PARADA ROBLES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3581118951493,
      "centroide_lon": -59.0310010709452,
      "departamento_id": "06266",
      "departamento_nombre": "Exaltación de la Cruz",
      "id": "06266060003",
      "localidad_censal_id": "06266060",
      "localidad_censal_nombre": "Parada Robles - Pavón",
      "municipio_id": "060266",
      "municipio_nombre": "Exaltación de la Cruz",
      "nombre": "PAVON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8128216283518,
      "centroide_lon": -58.5521650062885,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010001",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "AEROPUERTO INTERNACIONAL EZEIZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8962283957338,
      "centroide_lon": -58.5087006002723,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010002",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "CANNING",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9338489869515,
      "centroide_lon": -58.5780017334776,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010003",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "CARLOS SPEGAZZINI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8536100103068,
      "centroide_lon": -58.5195521653909,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010004",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "JOSE MARIA EZEIZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8503859677023,
      "centroide_lon": -58.5558695462288,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010005",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "LA UNION",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8851527149847,
      "centroide_lon": -58.5700335845498,
      "departamento_id": "06270",
      "departamento_nombre": "José M. Ezeiza",
      "id": "06270010006",
      "localidad_censal_id": "06270010",
      "localidad_censal_nombre": "Ezeiza",
      "municipio_id": "060270",
      "municipio_nombre": "Ezeiza",
      "nombre": "TRISTAN SUAREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.827580702831,
      "centroide_lon": -58.2229120039487,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010001",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "BOSQUES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.812285887223,
      "centroide_lon": -58.2426532633495,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010002",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "ESTANISLAO SEVERO ZEBALLOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7960544308506,
      "centroide_lon": -58.2748588953342,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010003",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "FLORENCIO VARELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8152397261388,
      "centroide_lon": -58.3082334245906,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010004",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "GOBERNADOR JULIO A. COSTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8648075260046,
      "centroide_lon": -58.2110437322304,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010005",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "INGENIERO JUAN ALLAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8790424725562,
      "centroide_lon": -58.2898092496787,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010006",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "VILLA BROWN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8627929258513,
      "centroide_lon": -58.2553232274346,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010007",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "VILLA SAN LUIS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8380276482598,
      "centroide_lon": -58.2888899071764,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010008",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "VILLA SANTA ROSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8303193176345,
      "centroide_lon": -58.2635239901185,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010009",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "VILLA VATTEONE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9564048574127,
      "centroide_lon": -58.2787454299742,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010010",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "EL TROPEZON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9437330109546,
      "centroide_lon": -58.2632550914348,
      "departamento_id": "06274",
      "departamento_nombre": "Florencio Varela",
      "id": "06274010011",
      "localidad_censal_id": "06274010",
      "localidad_censal_nombre": "Florencio Varela",
      "municipio_id": "060274",
      "municipio_nombre": "Florencio Varela",
      "nombre": "LA CAPILLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6356616332748,
      "centroide_lon": -62.4786821321875,
      "departamento_id": "06277",
      "departamento_nombre": "Florentino Ameghino",
      "id": "06277010000",
      "localidad_censal_id": "06277010",
      "localidad_censal_nombre": "Blaquier",
      "municipio_id": "060277",
      "municipio_nombre": "Florentino Ameghino",
      "nombre": "BLAQUIER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.846677699169,
      "centroide_lon": -62.4671575805384,
      "departamento_id": "06277",
      "departamento_nombre": "Florentino Ameghino",
      "id": "06277020000",
      "localidad_censal_id": "06277020",
      "localidad_censal_nombre": "Florentino Ameghino",
      "municipio_id": "060277",
      "municipio_nombre": "Florentino Ameghino",
      "nombre": "FLORENTINO AMEGHINO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9522292183733,
      "centroide_lon": -62.2174229886622,
      "departamento_id": "06277",
      "departamento_nombre": "Florentino Ameghino",
      "id": "06277030000",
      "localidad_censal_id": "06277030",
      "localidad_censal_nombre": "Porvenir",
      "municipio_id": "060277",
      "municipio_nombre": "Florentino Ameghino",
      "nombre": "PORVENIR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.4348806367321,
      "centroide_lon": -58.216863731472,
      "departamento_id": "06280",
      "departamento_nombre": "General Alvarado",
      "id": "06280005000",
      "localidad_censal_id": "06280005",
      "localidad_censal_nombre": "Centinela del Mar",
      "municipio_id": "060280",
      "municipio_nombre": "General Alvarado",
      "nombre": "CENTINELA DEL MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1119335118514,
      "centroide_lon": -57.8415353121543,
      "departamento_id": "06280",
      "departamento_nombre": "General Alvarado",
      "id": "06280010000",
      "localidad_censal_id": "06280010",
      "localidad_censal_nombre": "Comandante Nicanor Otamendi",
      "municipio_id": "060280",
      "municipio_nombre": "General Alvarado",
      "nombre": "COMANDANTE NICANOR OTAMENDI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3446881533109,
      "centroide_lon": -57.9920393065731,
      "departamento_id": "06280",
      "departamento_nombre": "General Alvarado",
      "id": "06280020000",
      "localidad_censal_id": "06280020",
      "localidad_censal_nombre": "Mar del Sur",
      "municipio_id": "060280",
      "municipio_nombre": "General Alvarado",
      "nombre": "MAR DEL SUR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1486545138318,
      "centroide_lon": -58.2230121065801,
      "departamento_id": "06280",
      "departamento_nombre": "General Alvarado",
      "id": "06280030000",
      "localidad_censal_id": "06280030",
      "localidad_censal_nombre": "Mechongué",
      "municipio_id": "060280",
      "municipio_nombre": "General Alvarado",
      "nombre": "MECHONGUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.2707429318083,
      "centroide_lon": -57.8404714577536,
      "departamento_id": "06280",
      "departamento_nombre": "General Alvarado",
      "id": "06280040000",
      "localidad_censal_id": "06280040",
      "localidad_censal_nombre": "Miramar",
      "municipio_id": "060280",
      "municipio_nombre": "General Alvarado",
      "nombre": "MIRAMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0229384341367,
      "centroide_lon": -60.0147935726886,
      "departamento_id": "06287",
      "departamento_nombre": "General Alvear",
      "id": "06287010000",
      "localidad_censal_id": "06287010",
      "localidad_censal_nombre": "General Alvear",
      "municipio_id": "060287",
      "municipio_nombre": "General Alvear",
      "nombre": "GENERAL ALVEAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2101542565966,
      "centroide_lon": -61.3548577979495,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294010000",
      "localidad_censal_id": "06294010",
      "localidad_censal_nombre": "Arribeños",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "ARRIBE¥OS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2368758979793,
      "centroide_lon": -61.103613881133,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294020000",
      "localidad_censal_id": "06294020",
      "localidad_censal_nombre": "Ascensión",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "ASCENSION",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2698455922697,
      "centroide_lon": -61.2926718130401,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294030000",
      "localidad_censal_id": "06294030",
      "localidad_censal_nombre": "Estación Arenales",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "ESTACION ARENALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1250383813873,
      "centroide_lon": -61.132654886464,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294040000",
      "localidad_censal_id": "06294040",
      "localidad_censal_nombre": "Ferré",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "FERRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3044679711077,
      "centroide_lon": -61.3056277788216,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294050000",
      "localidad_censal_id": "06294050",
      "localidad_censal_nombre": "General Arenales",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "GENERAL ARENALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2608740726731,
      "centroide_lon": -60.9685988224111,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294060000",
      "localidad_censal_id": "06294060",
      "localidad_censal_nombre": "La Angelita",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "LA ANGELITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1068549430168,
      "centroide_lon": -61.1317020714357,
      "departamento_id": "06294",
      "departamento_nombre": "General Arenales",
      "id": "06294070000",
      "localidad_censal_id": "06294070",
      "localidad_censal_nombre": "La Trinidad",
      "municipio_id": "060294",
      "municipio_nombre": "General Arenales",
      "nombre": "LA TRINIDAD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7694577358026,
      "centroide_lon": -58.4944615102033,
      "departamento_id": "06301",
      "departamento_nombre": "General Belgrano",
      "id": "06301010000",
      "localidad_censal_id": "06301010",
      "localidad_censal_nombre": "General Belgrano",
      "municipio_id": "060301",
      "municipio_nombre": "General Belgrano",
      "nombre": "GENERAL BELGRANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6733729629116,
      "centroide_lon": -58.9582999568816,
      "departamento_id": "06301",
      "departamento_nombre": "General Belgrano",
      "id": "06301020000",
      "localidad_censal_id": "06301020",
      "localidad_censal_nombre": "Gorchs",
      "municipio_id": "060301",
      "municipio_nombre": "General Belgrano",
      "nombre": "GORCHS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6431651777017,
      "centroide_lon": -57.7905019037041,
      "departamento_id": "06308",
      "departamento_nombre": "General Guido",
      "id": "06308010000",
      "localidad_censal_id": "06308010",
      "localidad_censal_nombre": "General Guido",
      "municipio_id": "060308",
      "municipio_nombre": "General Guido",
      "nombre": "GENERAL GUIDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9489668508723,
      "centroide_lon": -58.1035671819428,
      "departamento_id": "06308",
      "departamento_nombre": "General Guido",
      "id": "06308020000",
      "localidad_censal_id": "06308020",
      "localidad_censal_nombre": "Labardén",
      "municipio_id": "060308",
      "municipio_nombre": "General Guido",
      "nombre": "LABARDEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.0062217702401,
      "centroide_lon": -57.1417255856769,
      "departamento_id": "06315",
      "departamento_nombre": "General Juan Madariaga",
      "id": "06315010001",
      "localidad_censal_id": "06315010",
      "localidad_censal_nombre": "General Juan Madariaga",
      "municipio_id": "060315",
      "municipio_nombre": "General Juan Madariaga",
      "nombre": "BARRIO KENNEDY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.9967730012079,
      "centroide_lon": -57.1374769071298,
      "departamento_id": "06315",
      "departamento_nombre": "General Juan Madariaga",
      "id": "06315010002",
      "localidad_censal_id": "06315010",
      "localidad_censal_nombre": "General Juan Madariaga",
      "municipio_id": "060315",
      "municipio_nombre": "General Juan Madariaga",
      "nombre": "GENERAL JUAN MADARIAGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2503992475804,
      "centroide_lon": -61.2595794125711,
      "departamento_id": "06322",
      "departamento_nombre": "General La Madrid",
      "id": "06322010000",
      "localidad_censal_id": "06322010",
      "localidad_censal_nombre": "General La Madrid",
      "municipio_id": "060322",
      "municipio_nombre": "General la Madrid",
      "nombre": "GENERAL LA MADRID",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3607690235166,
      "centroide_lon": -61.5348487870647,
      "departamento_id": "06322",
      "departamento_nombre": "General La Madrid",
      "id": "06322020000",
      "localidad_censal_id": "06322020",
      "localidad_censal_nombre": "La Colina",
      "municipio_id": "060322",
      "municipio_nombre": "General la Madrid",
      "nombre": "LA COLINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1980813542863,
      "centroide_lon": -61.1220424031557,
      "departamento_id": "06322",
      "departamento_nombre": "General La Madrid",
      "id": "06322030000",
      "localidad_censal_id": "06322030",
      "localidad_censal_nombre": "Las Martinetas",
      "municipio_id": "060322",
      "municipio_nombre": "General la Madrid",
      "nombre": "LAS MARTINETAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.53362138441,
      "centroide_lon": -61.2865750087495,
      "departamento_id": "06322",
      "departamento_nombre": "General La Madrid",
      "id": "06322040000",
      "localidad_censal_id": "06322040",
      "localidad_censal_nombre": "Líbano",
      "municipio_id": "060322",
      "municipio_nombre": "General la Madrid",
      "nombre": "LIBANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.731769903374,
      "centroide_lon": -61.3230957170244,
      "departamento_id": "06322",
      "departamento_nombre": "General La Madrid",
      "id": "06322050000",
      "localidad_censal_id": "06322050",
      "localidad_censal_nombre": "Pontaut",
      "municipio_id": "060322",
      "municipio_nombre": "General la Madrid",
      "nombre": "PONTAUT",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8937763345299,
      "centroide_lon": -58.9172215319327,
      "departamento_id": "06329",
      "departamento_nombre": "General Las Heras",
      "id": "06329010000",
      "localidad_censal_id": "06329010",
      "localidad_censal_nombre": "General Hornos",
      "municipio_id": "060329",
      "municipio_nombre": "General las Heras",
      "nombre": "GENERAL HORNOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9267739074255,
      "centroide_lon": -58.9453407851291,
      "departamento_id": "06329",
      "departamento_nombre": "General Las Heras",
      "id": "06329020000",
      "localidad_censal_id": "06329020",
      "localidad_censal_nombre": "General Las Heras",
      "municipio_id": "060329",
      "municipio_nombre": "General las Heras",
      "nombre": "GENERAL LAS HERAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7829908276155,
      "centroide_lon": -59.1095647693247,
      "departamento_id": "06329",
      "departamento_nombre": "General Las Heras",
      "id": "06329030000",
      "localidad_censal_id": "06329030",
      "localidad_censal_nombre": "La Choza",
      "municipio_id": "060329",
      "municipio_nombre": "General las Heras",
      "nombre": "LA CHOZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7941193667757,
      "centroide_lon": -59.0279756935744,
      "departamento_id": "06329",
      "departamento_nombre": "General Las Heras",
      "id": "06329050000",
      "localidad_censal_id": "06329050",
      "localidad_censal_nombre": "Plomer",
      "municipio_id": "060329",
      "municipio_nombre": "General las Heras",
      "nombre": "PLOMER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8300241949658,
      "centroide_lon": -58.9422589402025,
      "departamento_id": "06329",
      "departamento_nombre": "General Las Heras",
      "id": "06329060000",
      "localidad_censal_id": "06329060",
      "localidad_censal_nombre": "Villars",
      "municipio_id": "060329",
      "municipio_nombre": "General las Heras",
      "nombre": "VILLARS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4080851872455,
      "centroide_lon": -56.9433553335054,
      "departamento_id": "06336",
      "departamento_nombre": "General Lavalle",
      "id": "06336020000",
      "localidad_censal_id": "06336020",
      "localidad_censal_nombre": "General Lavalle",
      "municipio_id": "060336",
      "municipio_nombre": "General Lavalle",
      "nombre": "GENERAL LAVALLE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7089537726649,
      "centroide_lon": -56.7598433617574,
      "departamento_id": "06336",
      "departamento_nombre": "General Lavalle",
      "id": "06336030000",
      "localidad_censal_id": "06336030",
      "localidad_censal_nombre": "Pavón",
      "municipio_id": "060336",
      "municipio_nombre": "General Lavalle",
      "nombre": "PAVON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6936377582584,
      "centroide_lon": -58.447313612508,
      "departamento_id": "06343",
      "departamento_nombre": "General Paz",
      "id": "06343010000",
      "localidad_censal_id": "06343010",
      "localidad_censal_nombre": "Barrio Río Salado",
      "municipio_id": "060343",
      "municipio_nombre": "General Paz",
      "nombre": "BARRIO RIO SALADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2747490125694,
      "centroide_lon": -58.4041701894263,
      "departamento_id": "06343",
      "departamento_nombre": "General Paz",
      "id": "06343020000",
      "localidad_censal_id": "06343020",
      "localidad_censal_nombre": "Loma Verde",
      "municipio_id": "060343",
      "municipio_nombre": "General Paz",
      "nombre": "LOMA VERDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5173520233652,
      "centroide_lon": -58.3184247148653,
      "departamento_id": "06343",
      "departamento_nombre": "General Paz",
      "id": "06343030000",
      "localidad_censal_id": "06343030",
      "localidad_censal_nombre": "Ranchos",
      "municipio_id": "060343",
      "municipio_nombre": "General Paz",
      "nombre": "RANCHOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6776271050909,
      "centroide_lon": -58.4350923167562,
      "departamento_id": "06343",
      "departamento_nombre": "General Paz",
      "id": "06343040000",
      "localidad_censal_id": "06343040",
      "localidad_censal_nombre": "Villanueva",
      "municipio_id": "060343",
      "municipio_nombre": "General Paz",
      "nombre": "VILLANUEVA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4162137301573,
      "centroide_lon": -61.9280141749282,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351010000",
      "localidad_censal_id": "06351010",
      "localidad_censal_nombre": "Colonia San Ricardo",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "COLONIA SAN RICARDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.764155990724,
      "centroide_lon": -61.8900674920122,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351020000",
      "localidad_censal_id": "06351020",
      "localidad_censal_nombre": "General Pinto",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "GENERAL PINTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.576111833277,
      "centroide_lon": -62.0518306894073,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351030000",
      "localidad_censal_id": "06351030",
      "localidad_censal_nombre": "Germania",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "GERMANIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5994995308259,
      "centroide_lon": -61.9164989081536,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351035000",
      "localidad_censal_id": "06351035",
      "localidad_censal_nombre": "Gunther",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "GUNTHER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7913160212074,
      "centroide_lon": -62.2011090686366,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351040000",
      "localidad_censal_id": "06351040",
      "localidad_censal_nombre": "Villa Francia",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "VILLA FRANCIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5798302278832,
      "centroide_lon": -62.1710489234118,
      "departamento_id": "06351",
      "departamento_nombre": "General Pinto",
      "id": "06351050000",
      "localidad_censal_id": "06351050",
      "localidad_censal_nombre": "Villa Roth",
      "municipio_id": "060351",
      "municipio_nombre": "General Pinto",
      "nombre": "VILLA ROTH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0297229162982,
      "centroide_lon": -57.7995485049706,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357020000",
      "localidad_censal_id": "06357020",
      "localidad_censal_nombre": "Barrio El Boquerón",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO EL BOQUERON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9068268303117,
      "centroide_lon": -57.7858860640391,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357050000",
      "localidad_censal_id": "06357050",
      "localidad_censal_nombre": "Barrio La Gloria",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO LA GLORIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9317157840639,
      "centroide_lon": -57.683162032898,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357060000",
      "localidad_censal_id": "06357060",
      "localidad_censal_nombre": "Barrio Santa Paula",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO SANTA PAULA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0086211557001,
      "centroide_lon": -57.7085500402678,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357070000",
      "localidad_censal_id": "06357070",
      "localidad_censal_nombre": "Batán",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BATAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1757779275595,
      "centroide_lon": -57.6513390003209,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357080000",
      "localidad_censal_id": "06357080",
      "localidad_censal_nombre": "Chapadmalal",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "CHAPADMALAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.2369312519788,
      "centroide_lon": -57.763418077922,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357090000",
      "localidad_censal_id": "06357090",
      "localidad_censal_nombre": "El Marquesado",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "EL MARQUESADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.036727731373,
      "centroide_lon": -57.7129897398616,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357100000",
      "localidad_censal_id": "06357100",
      "localidad_censal_nombre": "Estación Chapadmalal",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "ESTACION CHAPADMALAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.9130763908367,
      "centroide_lon": -57.552463851894,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357110001",
      "localidad_censal_id": "06357110",
      "localidad_censal_nombre": "Mar del Plata",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "CAMET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.888665236683,
      "centroide_lon": -57.6000121501773,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357110002",
      "localidad_censal_id": "06357110",
      "localidad_censal_nombre": "Mar del Plata",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "ESTACION CAMET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.0120914785136,
      "centroide_lon": -57.6064947153173,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357110003",
      "localidad_censal_id": "06357110",
      "localidad_censal_nombre": "Mar del Plata",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "MAR DEL PLATA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.0833774287933,
      "centroide_lon": -57.5859350936487,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357110004",
      "localidad_censal_id": "06357110",
      "localidad_censal_nombre": "Mar del Plata",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "PUNTA MOGOTES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8324145231571,
      "centroide_lon": -57.6311155465986,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357110005",
      "localidad_censal_id": "06357110",
      "localidad_censal_nombre": "Mar del Plata",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO EL CASAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -37.954166706805,
      "centroide_lon": -57.7715971022228,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357120000",
      "localidad_censal_id": "06357120",
      "localidad_censal_nombre": "Sierra de los Padres",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "SIERRA DE LOS PADRES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8931024789245,
      "centroide_lon": -57.8261645643114,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357120001",
      "localidad_censal_id": "06357120",
      "localidad_censal_nombre": "Sierra de los Padres",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO COLINAS VERDES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.9050810135269,
      "centroide_lon": -57.7391824086455,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357120002",
      "localidad_censal_id": "06357120",
      "localidad_censal_nombre": "Sierra de los Padres",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "BARRIO EL COYUNCO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.9504850690528,
      "centroide_lon": -57.7774696627876,
      "departamento_id": "06357",
      "departamento_nombre": "General Pueyrredón",
      "id": "06357120004",
      "localidad_censal_id": "06357120",
      "localidad_censal_nombre": "Sierra de los Padres",
      "municipio_id": "060357",
      "municipio_nombre": "General Pueyrredón",
      "nombre": "SIERRA DE LOS PADRES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -34.6079239021063,
      "centroide_lon": -58.950282039134,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030000",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "GENERAL RODRIGUEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5497197440161,
      "centroide_lon": -58.9467785534585,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030001",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "BARRIO MORABO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6764462958288,
      "centroide_lon": -58.9704188625706,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030002",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "BARRIO RUTA 24 KM. 10",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5601388485008,
      "centroide_lon": -58.9182614196703,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030003",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "C.C. BOSQUE REAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6278603432415,
      "centroide_lon": -58.9532533538878,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030004",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "GENERAL RODRGUEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5673433766504,
      "centroide_lon": -58.5843112459936,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010001",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "BARRIO PARQUE GENERAL SAN MARTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5751071711249,
      "centroide_lon": -58.5745828938046,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010002",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "BILLINGHURST",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5796986848297,
      "centroide_lon": -58.5420144816587,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010003",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "CIUDAD DEL LIBERTADOR GENERAL SAN MARTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5416520781805,
      "centroide_lon": -58.5959093634496,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010004",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "CIUDAD JARDIN EL LIBERTADOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5904132636755,
      "centroide_lon": -58.5530430923958,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010005",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA AYACUCHO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5422846822324,
      "centroide_lon": -58.5583177179822,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010006",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA BALLESTER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5905899097986,
      "centroide_lon": -58.5396606594168,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010007",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA BERNARDO MONTEAGUDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5796744853293,
      "centroide_lon": -58.523036970803,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010008",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA CHACABUCO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5595169115142,
      "centroide_lon": -58.5772769308184,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010009",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA CORONEL JOSE M. ZAPIOLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5470506011052,
      "centroide_lon": -58.5694391894093,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010010",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GENERAL ANTONIO J. DE SUCRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.51253320696,
      "centroide_lon": -58.5801478288493,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010011",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GENERAL EUGENIO NECOCHEA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.553116323608,
      "centroide_lon": -58.5669976603922,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010012",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GENERAL JOSE TOMAS GUIDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5612567076697,
      "centroide_lon": -58.5553936643491,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010013",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GENERAL JUAN G. LAS HERAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5433989252454,
      "centroide_lon": -58.5755928423118,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010014",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GODOY CRUZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5507646909435,
      "centroide_lon": -58.5284854572959,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010015",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GRANADEROS DE SAN MARTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5383846467918,
      "centroide_lon": -58.5491721010066,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010016",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA GREGORIA MATORRAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5253711653351,
      "centroide_lon": -58.5802550661988,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010017",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA JOSE LEON SUAREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5474839124216,
      "centroide_lon": -58.5750972559389,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010018",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA JUAN MARTIN DE PUEYRREDON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5846960052521,
      "centroide_lon": -58.5623334028073,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010019",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA LIBERTAD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.590146441043,
      "centroide_lon": -58.5234377223288,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010020",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA LYNCH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5682131696117,
      "centroide_lon": -58.5245451491949,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010021",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA MAIPU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5646397095177,
      "centroide_lon": -58.5922548425316,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010022",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA MARIA IRENE DE LOS REMEDIOS DE ESCALADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5551356608044,
      "centroide_lon": -58.5383771255925,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010023",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA MARQUES ALEJANDRO MARIA DE AGUADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5939487574993,
      "centroide_lon": -58.5321739553925,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010024",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA PARQUE PRESIDENTE FIGUEROA ALCORTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5618730613851,
      "centroide_lon": -58.5336624494541,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010025",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA PARQUE SAN LORENZO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5659932905453,
      "centroide_lon": -58.5448069225448,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010026",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA SAN ANDRES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5716953020123,
      "centroide_lon": -58.5491073994839,
      "departamento_id": "06371",
      "departamento_nombre": "Ciudad Libertador San Martín",
      "id": "06371010027",
      "localidad_censal_id": "06371010",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "060371",
      "municipio_nombre": "General San Martín",
      "nombre": "VILLA YAPEYU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7472686783062,
      "centroide_lon": -60.9889526585307,
      "departamento_id": "06385",
      "departamento_nombre": "General Viamonte",
      "id": "06385010000",
      "localidad_censal_id": "06385010",
      "localidad_censal_nombre": "Baigorrita",
      "municipio_id": "060385",
      "municipio_nombre": "General Viamonte",
      "nombre": "BAIGORRITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9437720137007,
      "centroide_lon": -61.1588643543904,
      "departamento_id": "06385",
      "departamento_nombre": "General Viamonte",
      "id": "06385020000",
      "localidad_censal_id": "06385020",
      "localidad_censal_nombre": "La Delfina",
      "municipio_id": "060385",
      "municipio_nombre": "General Viamonte",
      "nombre": "LA DELFINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0010365651503,
      "centroide_lon": -61.0381497866181,
      "departamento_id": "06385",
      "departamento_nombre": "General Viamonte",
      "id": "06385030000",
      "localidad_censal_id": "06385030",
      "localidad_censal_nombre": "Los Toldos",
      "municipio_id": "060385",
      "municipio_nombre": "General Viamonte",
      "nombre": "LOS TOLDOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0319914044113,
      "centroide_lon": -60.8647685857183,
      "departamento_id": "06385",
      "departamento_nombre": "General Viamonte",
      "id": "06385040000",
      "localidad_censal_id": "06385040",
      "localidad_censal_nombre": "San Emilio",
      "municipio_id": "060385",
      "municipio_nombre": "General Viamonte",
      "nombre": "SAN EMILIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8949208760254,
      "centroide_lon": -61.0044538243489,
      "departamento_id": "06385",
      "departamento_nombre": "General Viamonte",
      "id": "06385050000",
      "localidad_censal_id": "06385050",
      "localidad_censal_nombre": "Zavalía",
      "municipio_id": "060385",
      "municipio_nombre": "General Viamonte",
      "nombre": "ZAVALIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0124666610174,
      "centroide_lon": -63.3741563457186,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392010000",
      "localidad_censal_id": "06392010",
      "localidad_censal_nombre": "Banderaló",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "BANDERALO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4155054560621,
      "centroide_lon": -62.9618553806295,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392020000",
      "localidad_censal_id": "06392020",
      "localidad_censal_nombre": "Cañada Seca",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "CA¥ADA SECA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.672994561958,
      "centroide_lon": -63.3724454840572,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392030000",
      "localidad_censal_id": "06392030",
      "localidad_censal_nombre": "Coronel Charlone",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "CORONEL CHARLONE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7798328261907,
      "centroide_lon": -63.1960964862342,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392040000",
      "localidad_censal_id": "06392040",
      "localidad_censal_nombre": "Emilio V. Bunge",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "EMILIO V. BUNGE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0338419886306,
      "centroide_lon": -63.0146637107531,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392050000",
      "localidad_censal_id": "06392050",
      "localidad_censal_nombre": "General Villegas",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "GENERAL VILLEGAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0490892294374,
      "centroide_lon": -63.1218876844046,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392060000",
      "localidad_censal_id": "06392060",
      "localidad_censal_nombre": "Massey",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "MASSEY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5804516676438,
      "centroide_lon": -62.3516395240412,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392070000",
      "localidad_censal_id": "06392070",
      "localidad_censal_nombre": "Pichincha",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "PICHINCHA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.771104540922,
      "centroide_lon": -62.9846965760316,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392080000",
      "localidad_censal_id": "06392080",
      "localidad_censal_nombre": "Piedritas",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "PIEDRITAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6921180067125,
      "centroide_lon": -62.6957932681386,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392090000",
      "localidad_censal_id": "06392090",
      "localidad_censal_nombre": "Santa Eleodora",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "SANTA ELEODORA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.548335663977,
      "centroide_lon": -63.1732728724567,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392100000",
      "localidad_censal_id": "06392100",
      "localidad_censal_nombre": "Santa Regina",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "SANTA REGINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4607916334895,
      "centroide_lon": -62.649344456796,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392110000",
      "localidad_censal_id": "06392110",
      "localidad_censal_nombre": "Villa Saboya",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "VILLA SABOYA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2858298200472,
      "centroide_lon": -63.3682055476167,
      "departamento_id": "06392",
      "departamento_nombre": "General Villegas",
      "id": "06392120000",
      "localidad_censal_id": "06392120",
      "localidad_censal_nombre": "Villa Sauze",
      "municipio_id": "060392",
      "municipio_nombre": "General Villegas",
      "nombre": "VILLA SAUZE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0864662845331,
      "centroide_lon": -62.5391482291111,
      "departamento_id": "06399",
      "departamento_nombre": "Guaminí",
      "id": "06399010000",
      "localidad_censal_id": "06399010",
      "localidad_censal_nombre": "Arroyo Venado",
      "municipio_id": "060399",
      "municipio_nombre": "Guaminí",
      "nombre": "ARROYO VENADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7580953396186,
      "centroide_lon": -62.5017754084549,
      "departamento_id": "06399",
      "departamento_nombre": "Guaminí",
      "id": "06399020000",
      "localidad_censal_id": "06399020",
      "localidad_censal_nombre": "Casbas",
      "municipio_id": "060399",
      "municipio_nombre": "Guaminí",
      "nombre": "CASBAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5604378772819,
      "centroide_lon": -62.5981120377825,
      "departamento_id": "06399",
      "departamento_nombre": "Guaminí",
      "id": "06399030000",
      "localidad_censal_id": "06399030",
      "localidad_censal_nombre": "Garré",
      "municipio_id": "060399",
      "municipio_nombre": "Guaminí",
      "nombre": "GARRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0120881308673,
      "centroide_lon": -62.4166857296556,
      "departamento_id": "06399",
      "departamento_nombre": "Guaminí",
      "id": "06399040000",
      "localidad_censal_id": "06399040",
      "localidad_censal_nombre": "Guaminí",
      "municipio_id": "060399",
      "municipio_nombre": "Guaminí",
      "nombre": "GUAMINI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.80905243808,
      "centroide_lon": -62.2451480754422,
      "departamento_id": "06399",
      "departamento_nombre": "Guaminí",
      "id": "06399050000",
      "localidad_censal_id": "06399050",
      "localidad_censal_nombre": "Laguna Alsina",
      "municipio_id": "060399",
      "municipio_nombre": "Guaminí",
      "nombre": "LAGUNA ALSINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2994630370091,
      "centroide_lon": -61.7178401805261,
      "departamento_id": "06406",
      "departamento_nombre": "Hipólito Yrigoyen",
      "id": "06406010000",
      "localidad_censal_id": "06406010",
      "localidad_censal_nombre": "Henderson",
      "municipio_id": "060406",
      "municipio_nombre": "Hipólito Yrigoyen",
      "nombre": "HENDERSON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0883254041477,
      "centroide_lon": -61.4112500767203,
      "departamento_id": "06406",
      "departamento_nombre": "Hipólito Yrigoyen",
      "id": "06406020000",
      "localidad_censal_id": "06406020",
      "localidad_censal_nombre": "Herrera Vegas",
      "municipio_id": "060406",
      "municipio_nombre": "Hipólito Yrigoyen",
      "nombre": "HERRERA VEGAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5930931356257,
      "centroide_lon": -58.6357209509749,
      "departamento_id": "06408",
      "departamento_nombre": "Hurlingham",
      "id": "06408010001",
      "localidad_censal_id": "06408010",
      "localidad_censal_nombre": "Hurlingham",
      "municipio_id": "060408",
      "municipio_nombre": "Hurlingham",
      "nombre": "HURLINGHAM",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6123944448227,
      "centroide_lon": -58.6538103691925,
      "departamento_id": "06408",
      "departamento_nombre": "Hurlingham",
      "id": "06408010002",
      "localidad_censal_id": "06408010",
      "localidad_censal_nombre": "Hurlingham",
      "municipio_id": "060408",
      "municipio_nombre": "Hurlingham",
      "nombre": "VILLA SANTOS TESEI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5815622830288,
      "centroide_lon": -58.6583005365543,
      "departamento_id": "06408",
      "departamento_nombre": "Hurlingham",
      "id": "06408010003",
      "localidad_censal_id": "06408010",
      "localidad_censal_nombre": "Hurlingham",
      "municipio_id": "060408",
      "municipio_nombre": "Hurlingham",
      "nombre": "WILLIAM C. MORRIS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6440551161203,
      "centroide_lon": -58.688489332955,
      "departamento_id": "06410",
      "departamento_nombre": "Ituzaingó",
      "id": "06410010001",
      "localidad_censal_id": "06410010",
      "localidad_censal_nombre": "Ituzaingó",
      "municipio_id": "060410",
      "municipio_nombre": "Ituzaingó",
      "nombre": "ITUZAINGO CENTRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6699430265699,
      "centroide_lon": -58.6733044157835,
      "departamento_id": "06410",
      "departamento_nombre": "Ituzaingó",
      "id": "06410010002",
      "localidad_censal_id": "06410010",
      "localidad_censal_nombre": "Ituzaingó",
      "municipio_id": "060410",
      "municipio_nombre": "Ituzaingó",
      "nombre": "ITUZAINGO SUR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6168695808944,
      "centroide_lon": -58.693287500009,
      "departamento_id": "06410",
      "departamento_nombre": "Ituzaingó",
      "id": "06410010003",
      "localidad_censal_id": "06410010",
      "localidad_censal_nombre": "Ituzaingó",
      "municipio_id": "060410",
      "municipio_nombre": "Ituzaingó",
      "nombre": "VILLA GOBERNADOR UDAONDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.480495794913,
      "centroide_lon": -58.8042588983994,
      "departamento_id": "06412",
      "departamento_nombre": "José C. Paz",
      "id": "06412010001",
      "localidad_censal_id": "06412010",
      "localidad_censal_nombre": "José C. Paz",
      "municipio_id": "060412",
      "municipio_nombre": "José C. Paz",
      "nombre": "DEL VISO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5211611965464,
      "centroide_lon": -58.770881112578,
      "departamento_id": "06412",
      "departamento_nombre": "José C. Paz",
      "id": "06412010002",
      "localidad_censal_id": "06412010",
      "localidad_censal_nombre": "José C. Paz",
      "municipio_id": "060412",
      "municipio_nombre": "José C. Paz",
      "nombre": "JOSE C. PAZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4918483987206,
      "centroide_lon": -58.7863780316191,
      "departamento_id": "06412",
      "departamento_nombre": "José C. Paz",
      "id": "06412010003",
      "localidad_censal_id": "06412010",
      "localidad_censal_nombre": "José C. Paz",
      "municipio_id": "060412",
      "municipio_nombre": "José C. Paz",
      "nombre": "TORTUGUITAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.508084406003,
      "centroide_lon": -60.8648956546314,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413010000",
      "localidad_censal_id": "06413010",
      "localidad_censal_nombre": "Agustín Roca",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "AGUSTIN ROCA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4607068571169,
      "centroide_lon": -61.067072329925,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413020000",
      "localidad_censal_id": "06413020",
      "localidad_censal_nombre": "Agustina",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "AGUSTINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6606435780718,
      "centroide_lon": -61.0183645929666,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413030000",
      "localidad_censal_id": "06413030",
      "localidad_censal_nombre": "Balneario Laguna de Gómez",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "BALNEARIO LAGUNA DE GOMEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3467322372427,
      "centroide_lon": -61.1310781728463,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413040000",
      "localidad_censal_id": "06413040",
      "localidad_censal_nombre": "Fortín Tiburcio",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "FORTIN TIBURCIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5838316271397,
      "centroide_lon": -60.9472651715327,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413050000",
      "localidad_censal_id": "06413050",
      "localidad_censal_nombre": "Junín",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "JUNIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6564145153102,
      "centroide_lon": -60.8466681579607,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413055000",
      "localidad_censal_id": "06413055",
      "localidad_censal_nombre": "Paraje La Agraria",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "LA AGRARIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7245238530675,
      "centroide_lon": -61.1553171474145,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413060000",
      "localidad_censal_id": "06413060",
      "localidad_censal_nombre": "Laplacette",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "LAPLACETTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7608898130707,
      "centroide_lon": -60.8419122487126,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413070000",
      "localidad_censal_id": "06413070",
      "localidad_censal_nombre": "Morse",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "MORSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5752591285936,
      "centroide_lon": -61.0747308195452,
      "departamento_id": "06413",
      "departamento_nombre": "Junín",
      "id": "06413080000",
      "localidad_censal_id": "06413080",
      "localidad_censal_nombre": "Saforcada",
      "municipio_id": "060413",
      "municipio_nombre": "Junín",
      "nombre": "SAFORCADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4883976285615,
      "centroide_lon": -56.7004352579533,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420010000",
      "localidad_censal_id": "06420010",
      "localidad_censal_nombre": "Las Toninas",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "LAS TONINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.6552111489067,
      "centroide_lon": -56.6949520678334,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420020001",
      "localidad_censal_id": "06420020",
      "localidad_censal_nombre": "Mar de Ajó - San Bernardo",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "AGUAS VERDES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.6627491358367,
      "centroide_lon": -56.6817136067571,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420020002",
      "localidad_censal_id": "06420020",
      "localidad_censal_nombre": "Mar de Ajó - San Bernardo",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "LUCILA DEL MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.7468693750233,
      "centroide_lon": -56.6852641972249,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420020003",
      "localidad_censal_id": "06420020",
      "localidad_censal_nombre": "Mar de Ajó - San Bernardo",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "MAR DE AJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.7065977594674,
      "centroide_lon": -56.6904987226994,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420020004",
      "localidad_censal_id": "06420020",
      "localidad_censal_nombre": "Mar de Ajó - San Bernardo",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "MAR DE AJO NORTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.6874110140403,
      "centroide_lon": -56.6841400639982,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420020005",
      "localidad_censal_id": "06420020",
      "localidad_censal_nombre": "Mar de Ajó - San Bernardo",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "SAN BERNARDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3532388310754,
      "centroide_lon": -56.723944139417,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420030000",
      "localidad_censal_id": "06420030",
      "localidad_censal_nombre": "San Clemente del Tuyú",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "SAN CLEMENTE DEL TUYU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.5861168542894,
      "centroide_lon": -56.6990062189009,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420040001",
      "localidad_censal_id": "06420040",
      "localidad_censal_nombre": "Santa Teresita - Mar del Tuyú",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "MAR DEL TUYU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.5409111808415,
      "centroide_lon": -56.705867443875,
      "departamento_id": "06420",
      "departamento_nombre": "La Costa",
      "id": "06420040002",
      "localidad_censal_id": "06420040",
      "localidad_censal_nombre": "Santa Teresita - Mar del Tuyú",
      "municipio_id": "060420",
      "municipio_nombre": "La Costa",
      "nombre": "SANTA TERESITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7141728890679,
      "centroide_lon": -58.5089043765674,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010001",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "ALDO BONZI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7245358500775,
      "centroide_lon": -58.5370063768546,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010002",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "CIUDAD EVITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7724652485211,
      "centroide_lon": -58.6404861634561,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010003",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "GONZALEZ CATAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7468379962284,
      "centroide_lon": -58.5925328858169,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010004",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "GREGORIO DE LAFERRERE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7184291570686,
      "centroide_lon": -58.577751528605,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010005",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "ISIDRO CASANOVA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6870536689657,
      "centroide_lon": -58.5256123986254,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010006",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "LA TABLADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6656949723622,
      "centroide_lon": -58.5331553311677,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010007",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "LOMAS DEL MIRADOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7132054583486,
      "centroide_lon": -58.6258365121679,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010008",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "RAFAEL CASTILLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6523081823718,
      "centroide_lon": -58.5592114732944,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010009",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "RAMOS MEJIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6881604248706,
      "centroide_lon": -58.5626785715398,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010010",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "SAN JUSTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7108317770604,
      "centroide_lon": -58.495008488146,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010011",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "TAPIALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7855336508384,
      "centroide_lon": -58.7171850019077,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010012",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "20 DE JUNIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6981156880684,
      "centroide_lon": -58.4877575961419,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010013",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "VILLA EDUARDO MADERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6730866446433,
      "centroide_lon": -58.5938792359541,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010014",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "VILLA LUZURIAGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8475213469043,
      "centroide_lon": -58.686067635918,
      "departamento_id": "06427",
      "departamento_nombre": "La Matanza",
      "id": "06427010015",
      "localidad_censal_id": "06427010",
      "localidad_censal_nombre": "La Matanza",
      "municipio_id": "060427",
      "municipio_nombre": "La Matanza",
      "nombre": "VIRREY DEL PINO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6909571502715,
      "centroide_lon": -58.3838628492162,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010001",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "GERLI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7108609216651,
      "centroide_lon": -58.3725047463731,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010002",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "LANUS ESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6957815314992,
      "centroide_lon": -58.416253274019,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010003",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "LANUS OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7302098940207,
      "centroide_lon": -58.3561741850066,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010004",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "MONTE CHINGOLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.723808815713,
      "centroide_lon": -58.397669711018,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010005",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "REMEDIOS ESCALADA DE SAN MARTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.672035526127,
      "centroide_lon": -58.4134851675076,
      "departamento_id": "06434",
      "departamento_nombre": "Lanús",
      "id": "06434010006",
      "localidad_censal_id": "06434010",
      "localidad_censal_nombre": "Lanús",
      "municipio_id": "060434",
      "municipio_nombre": "Lanús",
      "nombre": "VALENTIN ALSINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0794688053886,
      "centroide_lon": -58.1393275732941,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441010000",
      "localidad_censal_id": "06441010",
      "localidad_censal_nombre": "Country Club El Rodeo",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "COUNTRY CLUB EL RODEO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0345600341406,
      "centroide_lon": -57.8440249586726,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441020000",
      "localidad_censal_id": "06441020",
      "localidad_censal_nombre": "Ignacio Correas",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "IGNACIO CORREAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.0103681960184,
      "centroide_lon": -58.1151848585184,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030001",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "ABASTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.0261580911328,
      "centroide_lon": -58.0959060056615,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030002",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "ANGEL ETCHEVERRY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.0300556104917,
      "centroide_lon": -57.8865250010855,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030003",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "ARANA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8907397135389,
      "centroide_lon": -58.132711650613,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030004",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "ARTURO SEGUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9707544223488,
      "centroide_lon": -57.8108950283044,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030005",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "BARRIO EL CARMEN (OESTE)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9359594527479,
      "centroide_lon": -57.9837630521994,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030006",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "BARRIO GAMBIER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9374880163466,
      "centroide_lon": -58.0093954169858,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030007",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "BARRIO LAS MALVINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9257210987549,
      "centroide_lon": -58.0393403036748,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030008",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "BARRIO LAS QUINTAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8879382744371,
      "centroide_lon": -58.0582328038691,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030009",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "CITY BELL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9476175562489,
      "centroide_lon": -57.9957432212034,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030010",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "EL RETIRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9017817335757,
      "centroide_lon": -58.039185928571,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030011",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "JOAQUIN GORINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8985896635727,
      "centroide_lon": -58.0143972026636,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030012",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "JOSE HERNANDEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9455092438623,
      "centroide_lon": -58.0362904746611,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030013",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "JOSE MELCHOR ROMERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9235917717359,
      "centroide_lon": -58.0019478733944,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030014",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "LA CUMBRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.920863104693,
      "centroide_lon": -57.9540560062469,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030015",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "LA PLATA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9976086009747,
      "centroide_lon": -58.043377425691,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030016",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "LISANDRO OLMOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.973715513981,
      "centroide_lon": -57.9850539062724,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030017",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "LOS HORNOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8819511933447,
      "centroide_lon": -58.0189915342036,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030018",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "MANUEL B. GONNET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8910544941568,
      "centroide_lon": -57.9913781921596,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030019",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "RINGUELET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9710900774179,
      "centroide_lon": -57.9524351288235,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030020",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "RUFINO DE ELIZALDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9007699307169,
      "centroide_lon": -57.9821293495972,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030021",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "TOLOSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8790055541958,
      "centroide_lon": -58.081733864461,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030022",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "TRANSRADIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.8776205187118,
      "centroide_lon": -58.0907139194439,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030023",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "VILLA ELISA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9406437624757,
      "centroide_lon": -57.9214984675578,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030024",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "VILLA ELVIRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9986517196678,
      "centroide_lon": -57.8404966243711,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030025",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "VILLA GARIBALDI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9634490535296,
      "centroide_lon": -57.9025001308298,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030026",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "VILLA MONTORO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9830288461923,
      "centroide_lon": -57.8674653074052,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441030027",
      "localidad_censal_id": "06441030",
      "localidad_censal_nombre": "La Plata",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "VILLA PARQUE SICARDI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9520385659845,
      "centroide_lon": -57.8409385980167,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441040000",
      "localidad_censal_id": "06441040",
      "localidad_censal_nombre": "Lomas de Copello",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "LOMAS DE COPELLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.9437333541431,
      "centroide_lon": -58.1732652389706,
      "departamento_id": "06441",
      "departamento_nombre": "La Plata",
      "id": "06441050000",
      "localidad_censal_id": "06441050",
      "localidad_censal_nombre": "Ruta Sol",
      "municipio_id": "060441",
      "municipio_nombre": "La Plata",
      "nombre": "BARRIO RUTA SOL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5467976434903,
      "centroide_lon": -60.7970535965801,
      "departamento_id": "06448",
      "departamento_nombre": "Laprida",
      "id": "06448010000",
      "localidad_censal_id": "06448010",
      "localidad_censal_nombre": "Laprida",
      "municipio_id": "060448",
      "municipio_nombre": "Laprida",
      "nombre": "LAPRIDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3263227,
      "centroide_lon": -60.9098409,
      "departamento_id": "06448",
      "departamento_nombre": "Laprida",
      "id": "06448020000",
      "localidad_censal_id": "06448020",
      "localidad_censal_nombre": "Pueblo Nuevo",
      "municipio_id": "060448",
      "municipio_nombre": "Laprida",
      "nombre": "PUEBLO NUEVO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2298626892886,
      "centroide_lon": -60.9621658450441,
      "departamento_id": "06448",
      "departamento_nombre": "Laprida",
      "id": "06448030000",
      "localidad_censal_id": "06448030",
      "localidad_censal_nombre": "Pueblo San Jorge",
      "municipio_id": "060448",
      "municipio_nombre": "Laprida",
      "nombre": "PUEBLO SAN JORGE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9414440334215,
      "centroide_lon": -59.0693547287839,
      "departamento_id": "06455",
      "departamento_nombre": "Las Flores",
      "id": "06455010000",
      "localidad_censal_id": "06455010",
      "localidad_censal_nombre": "Coronel Boerr",
      "municipio_id": "060455",
      "municipio_nombre": "Las Flores",
      "nombre": "CORONEL BOERR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8815483511482,
      "centroide_lon": -59.4063369903389,
      "departamento_id": "06455",
      "departamento_nombre": "Las Flores",
      "id": "06455020000",
      "localidad_censal_id": "06455020",
      "localidad_censal_nombre": "El Trigo",
      "municipio_id": "060455",
      "municipio_nombre": "Las Flores",
      "nombre": "EL TRIGO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0154969145348,
      "centroide_lon": -59.1004659218434,
      "departamento_id": "06455",
      "departamento_nombre": "Las Flores",
      "id": "06455030000",
      "localidad_censal_id": "06455030",
      "localidad_censal_nombre": "Las Flores",
      "municipio_id": "060455",
      "municipio_nombre": "Las Flores",
      "nombre": "LAS FLORES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2437580460508,
      "centroide_lon": -59.3662916338311,
      "departamento_id": "06455",
      "departamento_nombre": "Las Flores",
      "id": "06455040000",
      "localidad_censal_id": "06455040",
      "localidad_censal_nombre": "Pardo",
      "municipio_id": "060455",
      "municipio_nombre": "Las Flores",
      "nombre": "PARDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4418003349,
      "centroide_lon": -61.8448787838164,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462010000",
      "localidad_censal_id": "06462010",
      "localidad_censal_nombre": "Alberdi Viejo",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "ALBERDI VIEJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6528608747523,
      "centroide_lon": -61.5829085058874,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462020000",
      "localidad_censal_id": "06462020",
      "localidad_censal_nombre": "El Dorado",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "EL DORADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3430420274499,
      "centroide_lon": -61.5156655267073,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462030000",
      "localidad_censal_id": "06462030",
      "localidad_censal_nombre": "Fortín Acha",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "FORTIN ACHA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4387919326349,
      "centroide_lon": -61.8121893821736,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462040000",
      "localidad_censal_id": "06462040",
      "localidad_censal_nombre": "Juan Bautista Alberdi",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "JUAN BAUTISTA ALBERDI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5217246234597,
      "centroide_lon": -61.391182899484,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462050000",
      "localidad_censal_id": "06462050",
      "localidad_censal_nombre": "Leandro N. Alem",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "LEANDRO N. ALEM",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4973984349932,
      "centroide_lon": -61.5453447631479,
      "departamento_id": "06462",
      "departamento_nombre": "Leandro N. Alem",
      "id": "06462060000",
      "localidad_censal_id": "06462060",
      "localidad_censal_nombre": "Vedia",
      "municipio_id": "060462",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "VEDIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9846043002155,
      "centroide_lon": -61.7729116988591,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469010000",
      "localidad_censal_id": "06469010",
      "localidad_censal_nombre": "Arenaza",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "ARENAZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8710511337121,
      "centroide_lon": -61.2895451446375,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469020000",
      "localidad_censal_id": "06469020",
      "localidad_censal_nombre": "Bayauca",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "BAYAUCA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6966047108596,
      "centroide_lon": -61.3250128810539,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469030000",
      "localidad_censal_id": "06469030",
      "localidad_censal_nombre": "Bermúdez",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "BERMUDEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3913662871435,
      "centroide_lon": -61.9949102331933,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469040000",
      "localidad_censal_id": "06469040",
      "localidad_censal_nombre": "Carlos Salas",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "CARLOS SALAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3321817215807,
      "centroide_lon": -61.6140851684372,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469050000",
      "localidad_censal_id": "06469050",
      "localidad_censal_nombre": "Coronel Martínez de Hoz",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "CORONEL MARTINEZ DE HOZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0882210775847,
      "centroide_lon": -61.5163333232822,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469060000",
      "localidad_censal_id": "06469060",
      "localidad_censal_nombre": "El Triunfo",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "EL TRIUNFO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3649873001768,
      "centroide_lon": -61.8055485519284,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469070000",
      "localidad_censal_id": "06469070",
      "localidad_censal_nombre": "Las Toscas",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "LAS TOSCAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.869042222818,
      "centroide_lon": -61.5291649669285,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469080000",
      "localidad_censal_id": "06469080",
      "localidad_censal_nombre": "Lincoln",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "LINCOLN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1426386337075,
      "centroide_lon": -62.2439028944602,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469090000",
      "localidad_censal_id": "06469090",
      "localidad_censal_nombre": "Pasteur",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "PASTEUR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1440875676884,
      "centroide_lon": -61.9707859541264,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469100000",
      "localidad_censal_id": "06469100",
      "localidad_censal_nombre": "Roberts",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "ROBERTS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.6758379792473,
      "centroide_lon": -61.4657087059048,
      "departamento_id": "06469",
      "departamento_nombre": "Lincoln",
      "id": "06469110000",
      "localidad_censal_id": "06469110",
      "localidad_censal_nombre": "Triunvirato",
      "municipio_id": "060469",
      "municipio_nombre": "Lincoln",
      "nombre": "TRIUNVIRATO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5463782386967,
      "centroide_lon": -58.557322112625,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476010000",
      "localidad_censal_id": "06476010",
      "localidad_censal_nombre": "Arenas Verdes",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "ARENAS VERDES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9097927118145,
      "centroide_lon": -58.9120965943062,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476020000",
      "localidad_censal_id": "06476020",
      "localidad_censal_nombre": "Licenciado Matienzo",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "LICENCIADO MATIENZO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.165273387122,
      "centroide_lon": -58.7822303314999,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476030000",
      "localidad_censal_id": "06476030",
      "localidad_censal_nombre": "Lobería",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "LOBERIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3962306181995,
      "centroide_lon": -58.670580006548,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476040000",
      "localidad_censal_id": "06476040",
      "localidad_censal_nombre": "Pieres",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "PIERES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7892829007834,
      "centroide_lon": -58.8486476710495,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476050000",
      "localidad_censal_id": "06476050",
      "localidad_censal_nombre": "San Manuel",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "SAN MANUEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2010112434137,
      "centroide_lon": -58.7373143964601,
      "departamento_id": "06476",
      "departamento_nombre": "Lobería",
      "id": "06476060000",
      "localidad_censal_id": "06476060",
      "localidad_censal_nombre": "Tamangueyú",
      "municipio_id": "060476",
      "municipio_nombre": "Lobería",
      "nombre": "TAMANGUEYU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2033088915799,
      "centroide_lon": -59.3449562581802,
      "departamento_id": "06483",
      "departamento_nombre": "Lobos",
      "id": "06483010000",
      "localidad_censal_id": "06483010",
      "localidad_censal_nombre": "Antonio Carboni",
      "municipio_id": "060483",
      "municipio_nombre": "Lobos",
      "nombre": "ANTONIO CARBONI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.243272141682,
      "centroide_lon": -59.4860397931491,
      "departamento_id": "06483",
      "departamento_nombre": "Lobos",
      "id": "06483020000",
      "localidad_censal_id": "06483020",
      "localidad_censal_nombre": "Elvira",
      "municipio_id": "060483",
      "municipio_nombre": "Lobos",
      "nombre": "ELVIRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2747624905943,
      "centroide_lon": -59.1339060011927,
      "departamento_id": "06483",
      "departamento_nombre": "Lobos",
      "id": "06483030000",
      "localidad_censal_id": "06483030",
      "localidad_censal_nombre": "Laguna de Lobos",
      "municipio_id": "060483",
      "municipio_nombre": "Lobos",
      "nombre": "LAGUNA DE LOBOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1858677983922,
      "centroide_lon": -59.0957115706922,
      "departamento_id": "06483",
      "departamento_nombre": "Lobos",
      "id": "06483040000",
      "localidad_censal_id": "06483040",
      "localidad_censal_nombre": "Lobos",
      "municipio_id": "060483",
      "municipio_nombre": "Lobos",
      "nombre": "LOBOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3028071422894,
      "centroide_lon": -59.1696841440203,
      "departamento_id": "06483",
      "departamento_nombre": "Lobos",
      "id": "06483050000",
      "localidad_censal_id": "06483050",
      "localidad_censal_nombre": "Salvador María",
      "municipio_id": "060483",
      "municipio_nombre": "Lobos",
      "nombre": "SALVADOR MARIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7379027155314,
      "centroide_lon": -58.440675368556,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010001",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "BANFIELD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7946182344111,
      "centroide_lon": -58.4320908524909,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010002",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "LLAVALLOL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7627676410061,
      "centroide_lon": -58.4267903899666,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010003",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "LOMAS DE ZAMORA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.767486625425,
      "centroide_lon": -58.3780461337408,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010004",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "TEMPERLEY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.789560716095,
      "centroide_lon": -58.4041056486523,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010005",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "TURDERA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7289932955803,
      "centroide_lon": -58.4283436419093,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010006",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "VILLA CENTENARIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7067104032516,
      "centroide_lon": -58.4451304500011,
      "departamento_id": "06490",
      "departamento_nombre": "Lomas de Zamora",
      "id": "06490010007",
      "localidad_censal_id": "06490010",
      "localidad_censal_nombre": "Lomas de Zamora",
      "municipio_id": "060490",
      "municipio_nombre": "Lomas de Zamora",
      "nombre": "VILLA FIORITO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4862819813638,
      "centroide_lon": -59.2180861330698,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497020000",
      "localidad_censal_id": "06497020",
      "localidad_censal_nombre": "Carlos Keen",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "CARLOS KEEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5760126891975,
      "centroide_lon": -59.0214105976263,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497025000",
      "localidad_censal_id": "06497025",
      "localidad_censal_nombre": "Club de Campo Los Puentes",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "CLUB DE CAMPO LOS PUENTES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -34.5706550661632,
      "centroide_lon": -59.109540176033,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060000",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "LUJAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5607457195455,
      "centroide_lon": -59.1700837097016,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060001",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "BARRIO LAS CASUARINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5590633814317,
      "centroide_lon": -59.2044266157478,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060002",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "CORTINES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5806997364467,
      "centroide_lon": -59.0582308683017,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060003",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "LEZICA Y TORREZURI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5646845021423,
      "centroide_lon": -59.1320945387741,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060004",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "LUJAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.570714539607,
      "centroide_lon": -59.1826103383073,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060005",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "VILLA FLANDRIA NORTE (PUEBLO NUEVO)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5993969541802,
      "centroide_lon": -59.1757636437956,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060006",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "VILLA FLANDRIA SUR (EST. JAUREGUI)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4902656696955,
      "centroide_lon": -59.1324863072423,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060007",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "COUNTRY CLUB LAS PRADERAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4890162386569,
      "centroide_lon": -59.0617361279716,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497060008",
      "localidad_censal_id": "06497060",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "OPEN DOOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6265041710141,
      "centroide_lon": -59.2533805145177,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497070000",
      "localidad_censal_id": "06497070",
      "localidad_censal_nombre": "Olivera",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "OLIVERA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4318215080317,
      "centroide_lon": -59.1287739002816,
      "departamento_id": "06497",
      "departamento_nombre": "Luján",
      "id": "06497090000",
      "localidad_censal_id": "06497090",
      "localidad_censal_nombre": "Torres",
      "municipio_id": "060497",
      "municipio_nombre": "Luján",
      "nombre": "TORRES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0240363457416,
      "centroide_lon": -57.5340253600961,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505010000",
      "localidad_censal_id": "06505010",
      "localidad_censal_nombre": "Atalaya",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "ATALAYA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0816834306862,
      "centroide_lon": -57.7469762592144,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505020000",
      "localidad_censal_id": "06505020",
      "localidad_censal_nombre": "General Mansilla",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "GENERAL MANSILLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.99655185849,
      "centroide_lon": -57.7036642458572,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505030000",
      "localidad_censal_id": "06505030",
      "localidad_censal_nombre": "Los Naranjos",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "LOS NARANJOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0806853032506,
      "centroide_lon": -57.5172585839912,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505040000",
      "localidad_censal_id": "06505040",
      "localidad_censal_nombre": "Magdalena",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "MAGDALENA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1799267057869,
      "centroide_lon": -57.651986019808,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505050000",
      "localidad_censal_id": "06505050",
      "localidad_censal_nombre": "Roberto J. Payró",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "ROBERTO J. PAYRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.269568396303,
      "centroide_lon": -57.5757117447539,
      "departamento_id": "06505",
      "departamento_nombre": "Magdalena",
      "id": "06505060000",
      "localidad_censal_id": "06505060",
      "localidad_censal_nombre": "Vieytes",
      "municipio_id": "060505",
      "municipio_nombre": "Magdalena",
      "nombre": "VIEYTES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0860540989,
      "centroide_lon": -57.8285752273883,
      "departamento_id": "06511",
      "departamento_nombre": "Maipú",
      "id": "06511010000",
      "localidad_censal_id": "06511010",
      "localidad_censal_nombre": "Las Armas",
      "municipio_id": "060511",
      "municipio_nombre": "Maipú",
      "nombre": "LAS ARMAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8648514645038,
      "centroide_lon": -57.8829166198472,
      "departamento_id": "06511",
      "departamento_nombre": "Maipú",
      "id": "06511020000",
      "localidad_censal_id": "06511020",
      "localidad_censal_nombre": "Maipú",
      "municipio_id": "060511",
      "municipio_nombre": "Maipú",
      "nombre": "MAIPU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7137597811284,
      "centroide_lon": -57.5860126519926,
      "departamento_id": "06511",
      "departamento_nombre": "Maipú",
      "id": "06511030000",
      "localidad_censal_id": "06511030",
      "localidad_censal_nombre": "Santo Domingo",
      "municipio_id": "060511",
      "municipio_nombre": "Maipú",
      "nombre": "SANTO DOMINGO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4544550576034,
      "centroide_lon": -58.7062417407487,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010001",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "AREA DE PROMOCION EL TRIANGULO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4869935800875,
      "centroide_lon": -58.7286750953502,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010002",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "GRAND BOURG",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5004325972609,
      "centroide_lon": -58.6618050648717,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010003",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "INGENIERO ADOLFO SOURDEAUX",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4790933125467,
      "centroide_lon": -58.6989741628196,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010004",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "INGENIERO PABLO NOGUES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5078746726212,
      "centroide_lon": -58.7013439131882,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010005",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "LOS POLVORINES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4746661471823,
      "centroide_lon": -58.7513344097631,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010007",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "TORTUGUITAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5061507591135,
      "centroide_lon": -58.6793063506777,
      "departamento_id": "06515",
      "departamento_nombre": "Malvinas Argentinas",
      "id": "06515010008",
      "localidad_censal_id": "06515010",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "060515",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "VILLA DE MAYO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.452214188593,
      "centroide_lon": -57.7299500229319,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518010000",
      "localidad_censal_id": "06518010",
      "localidad_censal_nombre": "Coronel Vidal",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "CORONEL VIDAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2778606745914,
      "centroide_lon": -57.7740148170475,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518020000",
      "localidad_censal_id": "06518020",
      "localidad_censal_nombre": "General Pirán",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "GENERAL PIRAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.774854850113,
      "centroide_lon": -57.6351210747907,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518030000",
      "localidad_censal_id": "06518030",
      "localidad_censal_nombre": "La Armonía",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "LA ARMONIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7462581727647,
      "centroide_lon": -57.4274479339348,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518040000",
      "localidad_censal_id": "06518040",
      "localidad_censal_nombre": "Mar Chiquita",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "MAR CHIQUITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.7697388275396,
      "centroide_lon": -57.450820092514,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518050001",
      "localidad_censal_id": "06518050",
      "localidad_censal_nombre": "Mar de Cobo",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "LA BALIZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.7834384978567,
      "centroide_lon": -57.4647863685704,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518050002",
      "localidad_censal_id": "06518050",
      "localidad_censal_nombre": "Mar de Cobo",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "LA CALETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.7768000427976,
      "centroide_lon": -57.455658983424,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518050003",
      "localidad_censal_id": "06518050",
      "localidad_censal_nombre": "Mar de Cobo",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "MAR DE COBO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8505519250944,
      "centroide_lon": -57.5134595632837,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060001",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "ATLANTIDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8224325083139,
      "centroide_lon": -57.4974692762018,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060002",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "CAMET NORTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.857770246553,
      "centroide_lon": -57.5154242437478,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060003",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "FRENTE MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8730952780707,
      "centroide_lon": -57.5218898754931,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060004",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "PLAYA DORADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.834833606977,
      "centroide_lon": -57.5079487213859,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060005",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "SANTA CLARA DEL MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.8649003626945,
      "centroide_lon": -57.5169016362882,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518060006",
      "localidad_censal_id": "06518060",
      "localidad_censal_nombre": "Santa Clara del Mar",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "SANTA ELENA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6627994476991,
      "centroide_lon": -57.6670967441538,
      "departamento_id": "06518",
      "departamento_nombre": "Mar Chiquita",
      "id": "06518070000",
      "localidad_censal_id": "06518070",
      "localidad_censal_nombre": "Vivoratá",
      "municipio_id": "060518",
      "municipio_nombre": "Mar Chiquita",
      "nombre": "VIVORATA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.953080822173,
      "centroide_lon": -58.78292863718,
      "departamento_id": "06525",
      "departamento_nombre": "Marcos Paz",
      "id": "06525010000",
      "localidad_censal_id": "06525010",
      "localidad_censal_nombre": "Barrio Santa Rosa",
      "municipio_id": "060525",
      "municipio_nombre": "Marcos Paz",
      "nombre": "BARRIO SANTA ROSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.854330073065,
      "centroide_lon": -58.7844436414942,
      "departamento_id": "06525",
      "departamento_nombre": "Marcos Paz",
      "id": "06525020001",
      "localidad_censal_id": "06525020",
      "localidad_censal_nombre": "Marcos Paz",
      "municipio_id": "060525",
      "municipio_nombre": "Marcos Paz",
      "nombre": "BARRIOS LISANDRO DE LA TORRE Y SANTA MARTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7965436888808,
      "centroide_lon": -58.8778211205753,
      "departamento_id": "06525",
      "departamento_nombre": "Marcos Paz",
      "id": "06525020002",
      "localidad_censal_id": "06525020",
      "localidad_censal_nombre": "Marcos Paz",
      "municipio_id": "060525",
      "municipio_nombre": "Marcos Paz",
      "nombre": "MARCOS PAZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6029546709308,
      "centroide_lon": -59.288213732192,
      "departamento_id": "06532",
      "departamento_nombre": "Mercedes",
      "id": "06532005000",
      "localidad_censal_id": "06532005",
      "localidad_censal_nombre": "Goldney",
      "municipio_id": "060532",
      "municipio_nombre": "Mercedes",
      "nombre": "GOLDNEY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.652429281715,
      "centroide_lon": -59.3523186300108,
      "departamento_id": "06532",
      "departamento_nombre": "Mercedes",
      "id": "06532010000",
      "localidad_censal_id": "06532010",
      "localidad_censal_nombre": "Gowland",
      "municipio_id": "060532",
      "municipio_nombre": "Mercedes",
      "nombre": "GOWLAND",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6521715354886,
      "centroide_lon": -59.4295981722689,
      "departamento_id": "06532",
      "departamento_nombre": "Mercedes",
      "id": "06532020000",
      "localidad_censal_id": "06532020",
      "localidad_censal_nombre": "Mercedes",
      "municipio_id": "060532",
      "municipio_nombre": "Mercedes",
      "nombre": "MERCEDES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.698955554941,
      "centroide_lon": -59.3194428313109,
      "departamento_id": "06532",
      "departamento_nombre": "Mercedes",
      "id": "06532030000",
      "localidad_censal_id": "06532030",
      "localidad_censal_nombre": "Jorge Born",
      "municipio_id": "060532",
      "municipio_nombre": "Mercedes",
      "nombre": "TOMAS JOFRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7085422423529,
      "centroide_lon": -58.6795867252566,
      "departamento_id": "06539",
      "departamento_nombre": "Merlo",
      "id": "06539010001",
      "localidad_censal_id": "06539010",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "060539",
      "municipio_nombre": "Merlo",
      "nombre": "LIBERTAD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7154808073462,
      "centroide_lon": -58.7976740413845,
      "departamento_id": "06539",
      "departamento_nombre": "Merlo",
      "id": "06539010002",
      "localidad_censal_id": "06539010",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "060539",
      "municipio_nombre": "Merlo",
      "nombre": "MARIANO ACOSTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6823277014068,
      "centroide_lon": -58.7436432079669,
      "departamento_id": "06539",
      "departamento_nombre": "Merlo",
      "id": "06539010003",
      "localidad_censal_id": "06539010",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "060539",
      "municipio_nombre": "Merlo",
      "nombre": "MERLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7467959489481,
      "centroide_lon": -58.7060431536772,
      "departamento_id": "06539",
      "departamento_nombre": "Merlo",
      "id": "06539010004",
      "localidad_censal_id": "06539010",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "060539",
      "municipio_nombre": "Merlo",
      "nombre": "PONTEVEDRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6698581359268,
      "centroide_lon": -58.697930436646,
      "departamento_id": "06539",
      "departamento_nombre": "Merlo",
      "id": "06539010005",
      "localidad_censal_id": "06539010",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "060539",
      "municipio_nombre": "Merlo",
      "nombre": "SAN ANTONIO DE PADUA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2825062106661,
      "centroide_lon": -58.8042619084922,
      "departamento_id": "06547",
      "departamento_nombre": "Monte",
      "id": "06547010000",
      "localidad_censal_id": "06547010",
      "localidad_censal_nombre": "Abbott",
      "municipio_id": "060547",
      "municipio_nombre": "Monte",
      "nombre": "ABBOTT",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4391643240479,
      "centroide_lon": -58.8094598355672,
      "departamento_id": "06547",
      "departamento_nombre": "Monte",
      "id": "06547020000",
      "localidad_censal_id": "06547020",
      "localidad_censal_nombre": "San Miguel del Monte",
      "municipio_id": "060547",
      "municipio_nombre": "Monte",
      "nombre": "SAN MIGUEL DEL MONTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5447734044526,
      "centroide_lon": -58.8855911917932,
      "departamento_id": "06547",
      "departamento_nombre": "Monte",
      "id": "06547030000",
      "localidad_censal_id": "06547030",
      "localidad_censal_nombre": "Zenón Videla Dorna",
      "municipio_id": "060547",
      "municipio_nombre": "Monte",
      "nombre": "ZENON VIDELA DORNA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.995357691335,
      "centroide_lon": -61.2152015930805,
      "departamento_id": "06553",
      "departamento_nombre": "Monte Hermoso",
      "id": "06553010000",
      "localidad_censal_id": "06553010",
      "localidad_censal_nombre": "Balneario Sauce Grande",
      "municipio_id": "060553",
      "municipio_nombre": "Monte Hermoso",
      "nombre": "BALNEARIO SAUCE GRANDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9815065384254,
      "centroide_lon": -61.3005457486605,
      "departamento_id": "06553",
      "departamento_nombre": "Monte Hermoso",
      "id": "06553020000",
      "localidad_censal_id": "06553020",
      "localidad_censal_nombre": "Monte Hermoso",
      "municipio_id": "060553",
      "municipio_nombre": "Monte Hermoso",
      "nombre": "MONTE HERMOSO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5586595722532,
      "centroide_lon": -58.8163010886698,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010001",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "CUARTEL V",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6073137077479,
      "centroide_lon": -58.859942785434,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010002",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "FRANCISCO ALVAREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6452391234366,
      "centroide_lon": -58.8380252692761,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010003",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "LA REJA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.637632238148,
      "centroide_lon": -58.7921362740593,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010004",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "MORENO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6353876829627,
      "centroide_lon": -58.7503180890016,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010005",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "PASO DEL REY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5941439978594,
      "centroide_lon": -58.7557346559769,
      "departamento_id": "06560",
      "departamento_nombre": "Moreno",
      "id": "06560010006",
      "localidad_censal_id": "06560010",
      "localidad_censal_nombre": "Moreno",
      "municipio_id": "060560",
      "municipio_nombre": "Moreno",
      "nombre": "TRUJUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6644131034283,
      "centroide_lon": -58.6456427123911,
      "departamento_id": "06568",
      "departamento_nombre": "Morón",
      "id": "06568010001",
      "localidad_censal_id": "06568010",
      "localidad_censal_nombre": "Morón",
      "municipio_id": "060568",
      "municipio_nombre": "Morón",
      "nombre": "CASTELAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.617707172718,
      "centroide_lon": -58.601533043415,
      "departamento_id": "06568",
      "departamento_nombre": "Morón",
      "id": "06568010002",
      "localidad_censal_id": "06568010",
      "localidad_censal_nombre": "Morón",
      "municipio_id": "060568",
      "municipio_nombre": "Morón",
      "nombre": "EL PALOMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6440661183216,
      "centroide_lon": -58.5978176171897,
      "departamento_id": "06568",
      "departamento_nombre": "Morón",
      "id": "06568010003",
      "localidad_censal_id": "06568010",
      "localidad_censal_nombre": "Morón",
      "municipio_id": "060568",
      "municipio_nombre": "Morón",
      "nombre": "HAEDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6652227151167,
      "centroide_lon": -58.6190557007973,
      "departamento_id": "06568",
      "departamento_nombre": "Morón",
      "id": "06568010004",
      "localidad_censal_id": "06568010",
      "localidad_censal_nombre": "Morón",
      "municipio_id": "060568",
      "municipio_nombre": "Morón",
      "nombre": "MORON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6346596523214,
      "centroide_lon": -58.5715757135423,
      "departamento_id": "06568",
      "departamento_nombre": "Morón",
      "id": "06568010005",
      "localidad_censal_id": "06568010",
      "localidad_censal_nombre": "Morón",
      "municipio_id": "060568",
      "municipio_nombre": "Morón",
      "nombre": "VILLA SARMIENTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9232643360691,
      "centroide_lon": -59.5422519447044,
      "departamento_id": "06574",
      "departamento_nombre": "Navarro",
      "id": "06574010000",
      "localidad_censal_id": "06574010",
      "localidad_censal_nombre": "José Juan Almeyra",
      "municipio_id": "060574",
      "municipio_nombre": "Navarro",
      "nombre": "JOSE JUAN ALMEYRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0539263781559,
      "centroide_lon": -59.5131011825281,
      "departamento_id": "06574",
      "departamento_nombre": "Navarro",
      "id": "06574020000",
      "localidad_censal_id": "06574020",
      "localidad_censal_nombre": "Las Marianas",
      "municipio_id": "060574",
      "municipio_nombre": "Navarro",
      "nombre": "LAS MARIANAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0036066018879,
      "centroide_lon": -59.2774163163898,
      "departamento_id": "06574",
      "departamento_nombre": "Navarro",
      "id": "06574030000",
      "localidad_censal_id": "06574030",
      "localidad_censal_nombre": "Navarro",
      "municipio_id": "060574",
      "municipio_nombre": "Navarro",
      "nombre": "NAVARRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0782490606759,
      "centroide_lon": -59.651945539681,
      "departamento_id": "06574",
      "departamento_nombre": "Navarro",
      "id": "06574040000",
      "localidad_censal_id": "06574040",
      "localidad_censal_nombre": "Villa Moll",
      "municipio_id": "060574",
      "municipio_nombre": "Navarro",
      "nombre": "VILLA MOLL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8918269607856,
      "centroide_lon": -59.2866977775565,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581010000",
      "localidad_censal_id": "06581010",
      "localidad_censal_nombre": "Claraz",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "CLARAZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5580125335608,
      "centroide_lon": -59.3364566671065,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581025000",
      "localidad_censal_id": "06581025",
      "localidad_censal_nombre": "Energía",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "ENERGIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0091787783017,
      "centroide_lon": -59.2634988347821,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581030000",
      "localidad_censal_id": "06581030",
      "localidad_censal_nombre": "Juan N. Fernández",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "JUAN N. FERNANDEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.5711702285697,
      "centroide_lon": -58.7702419838751,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581040001",
      "localidad_censal_id": "06581040",
      "localidad_censal_nombre": "Necochea - Quequén",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "NECOCHEA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.5375051784084,
      "centroide_lon": -58.6952024694735,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581040002",
      "localidad_censal_id": "06581040",
      "localidad_censal_nombre": "Necochea - Quequén",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "QUEQUEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.5589732600706,
      "centroide_lon": -58.6585544269181,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581040003",
      "localidad_censal_id": "06581040",
      "localidad_censal_nombre": "Necochea - Quequén",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "COSTA BONITA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2846361959919,
      "centroide_lon": -59.202754253612,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581050000",
      "localidad_censal_id": "06581050",
      "localidad_censal_nombre": "Nicanor Olivera",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "NICANOR OLIVERA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.4503897333336,
      "centroide_lon": -59.3311154416146,
      "departamento_id": "06581",
      "departamento_nombre": "Necochea",
      "id": "06581060000",
      "localidad_censal_id": "06581060",
      "localidad_censal_nombre": "Ramón Santamarina",
      "municipio_id": "060581",
      "municipio_nombre": "Necochea",
      "nombre": "RAMON SANTAMARINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2929819536873,
      "centroide_lon": -61.4072542128,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588010000",
      "localidad_censal_id": "06588010",
      "localidad_censal_nombre": "Alfredo Demarchi",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "ALFREDO DEMARCHI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.239499381663,
      "centroide_lon": -60.8251358750153,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588020000",
      "localidad_censal_id": "06588020",
      "localidad_censal_nombre": "Carlos María Naón",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "CARLOS MARIA NAON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.607730780146,
      "centroide_lon": -60.9182929927645,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588030000",
      "localidad_censal_id": "06588030",
      "localidad_censal_nombre": "12 de Octubre",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "12 DE OCTUBRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6511928511123,
      "centroide_lon": -60.7098962799094,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588040000",
      "localidad_censal_id": "06588040",
      "localidad_censal_nombre": "Dudignac",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "DUDIGNAC",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4079467934432,
      "centroide_lon": -61.2105668204871,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588050000",
      "localidad_censal_id": "06588050",
      "localidad_censal_nombre": "La Aurora",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "LA AURORA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5201414507378,
      "centroide_lon": -60.9989487976537,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588060000",
      "localidad_censal_id": "06588060",
      "localidad_censal_nombre": "Manuel B. Gonnet",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "MANUEL B. GONNET",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3462662593318,
      "centroide_lon": -60.7453446826815,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588070000",
      "localidad_censal_id": "06588070",
      "localidad_censal_nombre": "Marcelino Ugarte",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "MARCELINO UGARTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5563880098445,
      "centroide_lon": -60.5605940553161,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588080000",
      "localidad_censal_id": "06588080",
      "localidad_censal_nombre": "Morea",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "MOREA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5357392707567,
      "centroide_lon": -60.7928143632315,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588090000",
      "localidad_censal_id": "06588090",
      "localidad_censal_nombre": "Norumbega",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "NORUMBEGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4447435053964,
      "centroide_lon": -60.8843433362719,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588100000",
      "localidad_censal_id": "06588100",
      "localidad_censal_nombre": "9 de Julio",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "9 DE JULIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4395526972374,
      "centroide_lon": -60.7174384712323,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588110000",
      "localidad_censal_id": "06588110",
      "localidad_censal_nombre": "Patricios",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "PATRICIOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4989239490928,
      "centroide_lon": -60.8647207106461,
      "departamento_id": "06588",
      "departamento_nombre": "9 de Julio",
      "id": "06588120000",
      "localidad_censal_id": "06588120",
      "localidad_censal_nombre": "Villa Fournier",
      "municipio_id": "060588",
      "municipio_nombre": "9 de Julio",
      "nombre": "VILLA GENERAL FOURNIER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5328887082639,
      "centroide_lon": -60.8824549132602,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595010000",
      "localidad_censal_id": "06595010",
      "localidad_censal_nombre": "Blancagrande",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "BLANCAGRANDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8641370257955,
      "centroide_lon": -60.0816424704,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595030000",
      "localidad_censal_id": "06595030",
      "localidad_censal_nombre": "Colonia Nievas",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "COLONIA NIEVAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9495659791886,
      "centroide_lon": -60.1108954083182,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595040000",
      "localidad_censal_id": "06595040",
      "localidad_censal_nombre": "Colonia San Miguel",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "COLONIA SAN MIGUEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4122732396335,
      "centroide_lon": -60.6730694647,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595050000",
      "localidad_censal_id": "06595050",
      "localidad_censal_nombre": "Espigas",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "ESPIGAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -36.8805120719062,
      "centroide_lon": -60.1771940245473,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595060000",
      "localidad_censal_id": "06595060",
      "localidad_censal_nombre": "Hinojo",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "HINOJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.8800694499223,
      "centroide_lon": -60.1780552970611,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595060001",
      "localidad_censal_id": "06595060",
      "localidad_censal_nombre": "Hinojo",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "COLONIA HINOJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.8758995951211,
      "centroide_lon": -60.1473196906487,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595060002",
      "localidad_censal_id": "06595060",
      "localidad_censal_nombre": "Hinojo",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "HINOJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8920935788859,
      "centroide_lon": -60.3180046559577,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595070000",
      "localidad_censal_id": "06595070",
      "localidad_censal_nombre": "Olavarría",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "OLAVARRIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6515016172821,
      "centroide_lon": -61.0845040269815,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595080000",
      "localidad_censal_id": "06595080",
      "localidad_censal_nombre": "Recalde",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "RECALDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1289653693521,
      "centroide_lon": -60.4099303899854,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595090000",
      "localidad_censal_id": "06595090",
      "localidad_censal_nombre": "Santa Luisa",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "SANTA LUISA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.843238222524,
      "centroide_lon": -60.2234271381196,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595100000",
      "localidad_censal_id": "06595100",
      "localidad_censal_nombre": "Sierra Chica",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "SIERRA CHICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.9225238148372,
      "centroide_lon": -60.2157925414657,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595110001",
      "localidad_censal_id": "06595110",
      "localidad_censal_nombre": "Sierras Bayas",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "SIERRAS BAYAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -36.9436605372421,
      "centroide_lon": -60.1578271825148,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595110002",
      "localidad_censal_id": "06595110",
      "localidad_censal_nombre": "Sierras Bayas",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "VILLA ARRIETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9802714637124,
      "centroide_lon": -60.2790828437139,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595120000",
      "localidad_censal_id": "06595120",
      "localidad_censal_nombre": "Villa Alfredo Fortabat",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "VILLA ALFREDO FORTABAT",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9903632120511,
      "centroide_lon": -60.3108081181748,
      "departamento_id": "06595",
      "departamento_nombre": "Olavarría",
      "id": "06595130000",
      "localidad_censal_id": "06595130",
      "localidad_censal_nombre": "Villa La Serranía",
      "municipio_id": "060595",
      "municipio_nombre": "Olavarría",
      "nombre": "VILLA LA SERRANIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.5602246137406,
      "centroide_lon": -62.2380643077459,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602010000",
      "localidad_censal_id": "06602010",
      "localidad_censal_nombre": "Bahía San Blas",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "BAHIA SAN BLAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.6522000086693,
      "centroide_lon": -62.7575972084464,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602020000",
      "localidad_censal_id": "06602020",
      "localidad_censal_nombre": "Cardenal Cagliero",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "CARDENAL CAGLIERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -40.797298324401,
      "centroide_lon": -62.984754269904,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602030000",
      "localidad_censal_id": "06602030",
      "localidad_censal_nombre": "Carmen de Patagones",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "CARMEN DE PATAGONES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.4363643935514,
      "centroide_lon": -62.5449343513868,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602040000",
      "localidad_censal_id": "06602040",
      "localidad_censal_nombre": "José B. Casas",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "JOSE B. CASAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.5993801239416,
      "centroide_lon": -62.6510470779021,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602050000",
      "localidad_censal_id": "06602050",
      "localidad_censal_nombre": "Juan A. Pradere",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "JUAN A. PRADERE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.1854869993736,
      "centroide_lon": -62.6205163469862,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602060000",
      "localidad_censal_id": "06602060",
      "localidad_censal_nombre": "Stroeder",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "STROEDER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.9151387867695,
      "centroide_lon": -62.6188702678852,
      "departamento_id": "06602",
      "departamento_nombre": "Patagones",
      "id": "06602070000",
      "localidad_censal_id": "06602070",
      "localidad_censal_nombre": "Villalonga",
      "municipio_id": "060602",
      "municipio_nombre": "Patagones",
      "nombre": "VILLALONGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.913692884071,
      "centroide_lon": -62.2240579143551,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609010000",
      "localidad_censal_id": "06609010",
      "localidad_censal_nombre": "Capitán Castro",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "CAPITAN CASTRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7327373193414,
      "centroide_lon": -61.7439272931387,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609020000",
      "localidad_censal_id": "06609020",
      "localidad_censal_nombre": "San Esteban",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "CHICLANA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8483065194137,
      "centroide_lon": -62.0693918423117,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609030000",
      "localidad_censal_id": "06609030",
      "localidad_censal_nombre": "Francisco Madero",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "FRANCISCO MADERO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7184196474894,
      "centroide_lon": -62.1142777365421,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609035000",
      "localidad_censal_id": "06609035",
      "localidad_censal_nombre": "Inocencio Sosa",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "INOCENCIO SOSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8524740557321,
      "centroide_lon": -62.2962123802194,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609040000",
      "localidad_censal_id": "06609040",
      "localidad_censal_nombre": "Juan José Paso",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "JUAN JOSE PASO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0846707903374,
      "centroide_lon": -61.7254747841859,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609050000",
      "localidad_censal_id": "06609050",
      "localidad_censal_nombre": "Magdala",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "MAGDALA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2301776476091,
      "centroide_lon": -62.0069944091925,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609060000",
      "localidad_censal_id": "06609060",
      "localidad_censal_nombre": "Mones Cazón",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "MONES CAZON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9199117438484,
      "centroide_lon": -61.8133995164977,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609070000",
      "localidad_censal_id": "06609070",
      "localidad_censal_nombre": "Nueva Plata",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "NUEVA PLATA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8123030689596,
      "centroide_lon": -61.8988207561037,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609080000",
      "localidad_censal_id": "06609080",
      "localidad_censal_nombre": "Pehuajó",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "PEHUAJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7137365347985,
      "centroide_lon": -61.6477830181101,
      "departamento_id": "06609",
      "departamento_nombre": "Pehuajó",
      "id": "06609090000",
      "localidad_censal_id": "06609090",
      "localidad_censal_nombre": "San Bernardo",
      "municipio_id": "060609",
      "municipio_nombre": "Pehuajó",
      "nombre": "SAN BERNARDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2065730139324,
      "centroide_lon": -63.0761554731554,
      "departamento_id": "06616",
      "departamento_nombre": "Pellegrini",
      "id": "06616010000",
      "localidad_censal_id": "06616010",
      "localidad_censal_nombre": "Bocayuva",
      "municipio_id": "060616",
      "municipio_nombre": "Pellegrini",
      "nombre": "BOCAYUVA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3416806655424,
      "centroide_lon": -63.2611533171506,
      "departamento_id": "06616",
      "departamento_nombre": "Pellegrini",
      "id": "06616020000",
      "localidad_censal_id": "06616020",
      "localidad_censal_nombre": "De Bary",
      "municipio_id": "060616",
      "municipio_nombre": "Pellegrini",
      "nombre": "DE BARY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2697352282386,
      "centroide_lon": -63.1652861097017,
      "departamento_id": "06616",
      "departamento_nombre": "Pellegrini",
      "id": "06616030000",
      "localidad_censal_id": "06616030",
      "localidad_censal_nombre": "Pellegrini",
      "municipio_id": "060616",
      "municipio_nombre": "Pellegrini",
      "nombre": "PELLEGRINI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.755665348987,
      "centroide_lon": -60.4408373865386,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623010000",
      "localidad_censal_id": "06623010",
      "localidad_censal_nombre": "Acevedo",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "ACEVEDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9138392371386,
      "centroide_lon": -60.4628650932084,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623020000",
      "localidad_censal_id": "06623020",
      "localidad_censal_nombre": "Fontezuela",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "FONTEZUELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6745849638169,
      "centroide_lon": -60.4006865938611,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623030000",
      "localidad_censal_id": "06623030",
      "localidad_censal_nombre": "Guerrico",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "GUERRICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8322767381075,
      "centroide_lon": -60.4864895356948,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623040000",
      "localidad_censal_id": "06623040",
      "localidad_censal_nombre": "Juan A. de la Peña",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "JUAN A. DE LA PE¥A",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9263378592898,
      "centroide_lon": -60.3829034809436,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623050000",
      "localidad_censal_id": "06623050",
      "localidad_censal_nombre": "Juan Anchorena",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "JUAN ANCHORENA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7347851403163,
      "centroide_lon": -60.1701773086428,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623060000",
      "localidad_censal_id": "06623060",
      "localidad_censal_nombre": "La Violeta",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "LA VIOLETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7647232673599,
      "centroide_lon": -60.6492429333859,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623070000",
      "localidad_censal_id": "06623070",
      "localidad_censal_nombre": "Manuel Ocampo",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "MANUEL OCAMPO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7090270411103,
      "centroide_lon": -60.5842599045876,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623080000",
      "localidad_censal_id": "06623080",
      "localidad_censal_nombre": "Mariano Benítez",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "MARIANO BENITEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9149979004605,
      "centroide_lon": -60.8383550657724,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623090000",
      "localidad_censal_id": "06623090",
      "localidad_censal_nombre": "Mariano H. Alfonzo",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "MARIANO H. ALFONZO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8949900563191,
      "centroide_lon": -60.5716400794952,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623100000",
      "localidad_censal_id": "06623100",
      "localidad_censal_nombre": "Pergamino",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "PERGAMINO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9947211174052,
      "centroide_lon": -60.7316480040989,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623110000",
      "localidad_censal_id": "06623110",
      "localidad_censal_nombre": "Pinzón",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "PINZON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0303722475767,
      "centroide_lon": -60.5042327001336,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623120000",
      "localidad_censal_id": "06623120",
      "localidad_censal_nombre": "Rancagua",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "RANCAGUA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6647037443081,
      "centroide_lon": -60.7084372635626,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623130000",
      "localidad_censal_id": "06623130",
      "localidad_censal_nombre": "Villa Angélica",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "VILLA ANGELICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.0906695795004,
      "centroide_lon": -60.4162716627136,
      "departamento_id": "06623",
      "departamento_nombre": "Pergamino",
      "id": "06623140000",
      "localidad_censal_id": "06623140",
      "localidad_censal_nombre": "Villa San José",
      "municipio_id": "060623",
      "municipio_nombre": "Pergamino",
      "nombre": "VILLA SAN JOSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3181419923689,
      "centroide_lon": -58.5525025522873,
      "departamento_id": "06630",
      "departamento_nombre": "Pila",
      "id": "06630010000",
      "localidad_censal_id": "06630010",
      "localidad_censal_nombre": "Casalins",
      "municipio_id": "060630",
      "municipio_nombre": "Pila",
      "nombre": "CASALINS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0014746137723,
      "centroide_lon": -58.1427778873885,
      "departamento_id": "06630",
      "departamento_nombre": "Pila",
      "id": "06630020000",
      "localidad_censal_id": "06630020",
      "localidad_censal_nombre": "Pila",
      "municipio_id": "060630",
      "municipio_nombre": "Pila",
      "nombre": "PILA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4494009456502,
      "centroide_lon": -58.8009119728306,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040001",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "DEL VISO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4072575836787,
      "centroide_lon": -58.974691291144,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040002",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "FATIMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4448149403448,
      "centroide_lon": -58.8393730861805,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040003",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "LA LONJA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4250028270043,
      "centroide_lon": -58.7950003996087,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040004",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "LOS CACHORROS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.452630932571,
      "centroide_lon": -59.0136606624887,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040005",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "MANZANARES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5287308766458,
      "centroide_lon": -58.8775995091498,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040006",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "MANZONE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.410617254736,
      "centroide_lon": -58.7821701089413,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040007",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "MAQUINISTA F. SAVIO (OESTE)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4663318396358,
      "centroide_lon": -58.9227197887079,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040008",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "PILAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4928616935261,
      "centroide_lon": -58.8416985218723,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040009",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "PRESIDENTE DERQUI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4369652784326,
      "centroide_lon": -58.7669941785147,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040010",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "ROBERTO DE VICENZO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4366914158447,
      "centroide_lon": -58.7573470164393,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040011",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "SANTA TERESA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4578061717121,
      "centroide_lon": -58.76209072062,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040012",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "TORTUGUITAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4858983446269,
      "centroide_lon": -58.8768335703823,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040013",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "VILLA ASTOLFI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4077327133185,
      "centroide_lon": -58.8539345779841,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040014",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "VILLA ROSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3657182841794,
      "centroide_lon": -58.893696088678,
      "departamento_id": "06638",
      "departamento_nombre": "Pilar",
      "id": "06638040015",
      "localidad_censal_id": "06638040",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "060638",
      "municipio_nombre": "Pilar",
      "nombre": "ZELAYA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.1641593745618,
      "centroide_lon": -56.9027914378095,
      "departamento_id": "06644",
      "departamento_nombre": "Pinamar",
      "id": "06644010001",
      "localidad_censal_id": "06644010",
      "localidad_censal_nombre": "Pinamar",
      "municipio_id": "060644",
      "municipio_nombre": "Pinamar",
      "nombre": "CARILO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.1300477581888,
      "centroide_lon": -56.8833984697828,
      "departamento_id": "06644",
      "departamento_nombre": "Pinamar",
      "id": "06644010002",
      "localidad_censal_id": "06644010",
      "localidad_censal_nombre": "Pinamar",
      "municipio_id": "060644",
      "municipio_nombre": "Pinamar",
      "nombre": "OSTENDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.0777448860202,
      "centroide_lon": -56.8437227435213,
      "departamento_id": "06644",
      "departamento_nombre": "Pinamar",
      "id": "06644010003",
      "localidad_censal_id": "06644010",
      "localidad_censal_nombre": "Pinamar",
      "municipio_id": "060644",
      "municipio_nombre": "Pinamar",
      "nombre": "PINAMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.1445557771803,
      "centroide_lon": -56.890291998877,
      "departamento_id": "06644",
      "departamento_nombre": "Pinamar",
      "id": "06644010004",
      "localidad_censal_id": "06644010",
      "localidad_censal_nombre": "Pinamar",
      "municipio_id": "060644",
      "municipio_nombre": "Pinamar",
      "nombre": "VALERIA DEL MAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.886197581853,
      "centroide_lon": -58.41733483734,
      "departamento_id": "06648",
      "departamento_nombre": "Presidente Perón",
      "id": "06648010001",
      "localidad_censal_id": "06648010",
      "localidad_censal_nombre": "Presidente Perón",
      "municipio_id": "060648",
      "municipio_nombre": "Presidente Perón",
      "nombre": "BARRIO AMERICA UNIDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.9230592108536,
      "centroide_lon": -58.3650272662643,
      "departamento_id": "06648",
      "departamento_nombre": "Presidente Perón",
      "id": "06648010002",
      "localidad_censal_id": "06648010",
      "localidad_censal_nombre": "Presidente Perón",
      "municipio_id": "060648",
      "municipio_nombre": "Presidente Perón",
      "nombre": "GUERNICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7017920523646,
      "centroide_lon": -62.9006764415683,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651010000",
      "localidad_censal_id": "06651010",
      "localidad_censal_nombre": "Azopardo",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "AZOPARDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8028181597411,
      "centroide_lon": -63.0425144041162,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651020000",
      "localidad_censal_id": "06651020",
      "localidad_censal_nombre": "Bordenave",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "BORDENAVE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6857562622736,
      "centroide_lon": -63.1595663902141,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651030000",
      "localidad_censal_id": "06651030",
      "localidad_censal_nombre": "Darregueira",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "DARREGUEIRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9086924428976,
      "centroide_lon": -62.9360284730803,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651040000",
      "localidad_censal_id": "06651040",
      "localidad_censal_nombre": "17 de Agosto",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "17 DE AGOSTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1080950084855,
      "centroide_lon": -62.9129041375994,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651050000",
      "localidad_censal_id": "06651050",
      "localidad_censal_nombre": "Estela",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "ESTELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.005992834739,
      "centroide_lon": -62.8186936682637,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651060000",
      "localidad_censal_id": "06651060",
      "localidad_censal_nombre": "Felipe Solá",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "FELIPE SOLA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1171190117962,
      "centroide_lon": -62.7254849373447,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651070000",
      "localidad_censal_id": "06651070",
      "localidad_censal_nombre": "López Lecube",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "LOPEZ LECUBE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.542613502113,
      "centroide_lon": -62.7652275731166,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651080000",
      "localidad_censal_id": "06651080",
      "localidad_censal_nombre": "Puán",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "PUAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2995183581309,
      "centroide_lon": -62.9821877089624,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651090000",
      "localidad_censal_id": "06651090",
      "localidad_censal_nombre": "San Germán",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "SAN GERMAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3905464925651,
      "centroide_lon": -62.8051325654187,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651100000",
      "localidad_censal_id": "06651100",
      "localidad_censal_nombre": "Villa Castelar",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "VILLA CASTELAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1693097057753,
      "centroide_lon": -63.2320511809102,
      "departamento_id": "06651",
      "departamento_nombre": "Puán",
      "id": "06651110000",
      "localidad_censal_id": "06651110",
      "localidad_censal_nombre": "Villa Iris",
      "municipio_id": "060651",
      "municipio_nombre": "Puán",
      "nombre": "VILLA IRIS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3227800092544,
      "centroide_lon": -57.4490768504218,
      "departamento_id": "06655",
      "departamento_nombre": "Punta Indio",
      "id": "06655010000",
      "localidad_censal_id": "06655010",
      "localidad_censal_nombre": "Alvarez Jonte",
      "municipio_id": "060655",
      "municipio_nombre": "Punta Indio",
      "nombre": "ALVAREZ JONTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.53126706917,
      "centroide_lon": -57.3285887617048,
      "departamento_id": "06655",
      "departamento_nombre": "Punta Indio",
      "id": "06655030000",
      "localidad_censal_id": "06655030",
      "localidad_censal_nombre": "Pipinas",
      "municipio_id": "060655",
      "municipio_nombre": "Punta Indio",
      "nombre": "PIPINAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2808352898089,
      "centroide_lon": -57.2360072391524,
      "departamento_id": "06655",
      "departamento_nombre": "Punta Indio",
      "id": "06655040000",
      "localidad_censal_id": "06655040",
      "localidad_censal_nombre": "Punta Indio",
      "municipio_id": "060655",
      "municipio_nombre": "Punta Indio",
      "nombre": "PUNTA INDIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3881552142501,
      "centroide_lon": -57.3371601623743,
      "departamento_id": "06655",
      "departamento_nombre": "Punta Indio",
      "id": "06655050000",
      "localidad_censal_id": "06655050",
      "localidad_censal_nombre": "Verónica",
      "municipio_id": "060655",
      "municipio_nombre": "Punta Indio",
      "nombre": "VERONICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.700378406023,
      "centroide_lon": -58.2766427817003,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010001",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "BERNAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7269644246033,
      "centroide_lon": -58.3182795621594,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010002",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "BERNAL OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7032130159372,
      "centroide_lon": -58.2984533178872,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010003",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "DON BOSCO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7435644425127,
      "centroide_lon": -58.2281408361207,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010004",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "EZPELETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7634442077448,
      "centroide_lon": -58.2638314351646,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010005",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "EZPELETA OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7241344748224,
      "centroide_lon": -58.2492205541766,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010006",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "QUILMES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7477989892391,
      "centroide_lon": -58.2999440151876,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010007",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "QUILMES OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7777730726874,
      "centroide_lon": -58.3101561253692,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010008",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "SAN FRANCISCO SOLANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.7688841339243,
      "centroide_lon": -58.2957092489115,
      "departamento_id": "06658",
      "departamento_nombre": "Quilmes",
      "id": "06658010009",
      "localidad_censal_id": "06658010",
      "localidad_censal_nombre": "Quilmes",
      "municipio_id": "060658",
      "municipio_nombre": "Quilmes",
      "nombre": "VILLA LA FLORIDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5679152654445,
      "centroide_lon": -59.9791123483107,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665010000",
      "localidad_censal_id": "06665010",
      "localidad_censal_nombre": "El Paraíso",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "EL PARAISO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6366100825527,
      "centroide_lon": -59.9895563465816,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665020000",
      "localidad_censal_id": "06665020",
      "localidad_censal_nombre": "Las Bahamas",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "LAS BAHAMAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7674497804832,
      "centroide_lon": -60.0927343667154,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665030000",
      "localidad_censal_id": "06665030",
      "localidad_censal_nombre": "Pérez Millán",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "PEREZ MILLAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4877021169888,
      "centroide_lon": -60.0072209741607,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665040000",
      "localidad_censal_id": "06665040",
      "localidad_censal_nombre": "Ramallo",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "RAMALLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4352672179447,
      "centroide_lon": -60.1451316522293,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665050000",
      "localidad_censal_id": "06665050",
      "localidad_censal_nombre": "Villa General Savio",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "VILLA GENERAL SAVIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.502293213764,
      "centroide_lon": -60.0650138035308,
      "departamento_id": "06665",
      "departamento_nombre": "Ramallo",
      "id": "06665060000",
      "localidad_censal_id": "06665060",
      "localidad_censal_nombre": "Villa Ramallo",
      "municipio_id": "060665",
      "municipio_nombre": "Ramallo",
      "nombre": "VILLA RAMALLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7755035485732,
      "centroide_lon": -59.0871114029954,
      "departamento_id": "06672",
      "departamento_nombre": "Rauch",
      "id": "06672010000",
      "localidad_censal_id": "06672010",
      "localidad_censal_nombre": "Rauch",
      "municipio_id": "060672",
      "municipio_nombre": "Rauch",
      "nombre": "RAUCH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4903412429492,
      "centroide_lon": -62.9763759840765,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679010000",
      "localidad_censal_id": "06679010",
      "localidad_censal_nombre": "América",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "AMERICA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7048848499519,
      "centroide_lon": -63.0230332752501,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679020000",
      "localidad_censal_id": "06679020",
      "localidad_censal_nombre": "Fortín Olavarría",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "FORTIN OLAVARRIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5576529256075,
      "centroide_lon": -63.3815800402317,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679030000",
      "localidad_censal_id": "06679030",
      "localidad_censal_nombre": "González Moreno",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "GONZALEZ MORENO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8703867348478,
      "centroide_lon": -63.3742010687724,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679040000",
      "localidad_censal_id": "06679040",
      "localidad_censal_nombre": "Mira Pampa",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "MIRA PAMPA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8466320155134,
      "centroide_lon": -63.2898118317476,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679050000",
      "localidad_censal_id": "06679050",
      "localidad_censal_nombre": "Roosevelt",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "ROOSEVELT",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5118022821456,
      "centroide_lon": -63.1882154861262,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679060000",
      "localidad_censal_id": "06679060",
      "localidad_censal_nombre": "San Mauricio",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "SAN MAURICIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2750295400617,
      "centroide_lon": -63.2135436741919,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679070000",
      "localidad_censal_id": "06679070",
      "localidad_censal_nombre": "Sansinena",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "SANSINENA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7656416636683,
      "centroide_lon": -63.1386100973601,
      "departamento_id": "06679",
      "departamento_nombre": "Rivadavia",
      "id": "06679080000",
      "localidad_censal_id": "06679080",
      "localidad_censal_nombre": "Sundblad",
      "municipio_id": "060679",
      "municipio_nombre": "Rivadavia",
      "nombre": "SUNDBLAD",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1544387870912,
      "centroide_lon": -61.0128711169321,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686010000",
      "localidad_censal_id": "06686010",
      "localidad_censal_nombre": "La Beba",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "LA BEBA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0381002651925,
      "centroide_lon": -60.8685264174218,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686020000",
      "localidad_censal_id": "06686020",
      "localidad_censal_nombre": "Las Carabelas",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "LAS CARABELAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3735696107802,
      "centroide_lon": -60.6523710265627,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686030000",
      "localidad_censal_id": "06686030",
      "localidad_censal_nombre": "Los Indios",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "LOS INDIOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3588132488617,
      "centroide_lon": -60.7845693256007,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686040000",
      "localidad_censal_id": "06686040",
      "localidad_censal_nombre": "Rafael Obligado",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "RAFAEL OBLIGADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0876515179763,
      "centroide_lon": -60.6672564006968,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686050000",
      "localidad_censal_id": "06686050",
      "localidad_censal_nombre": "Roberto Cano",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "ROBERTO CANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -34.1961600446015,
      "centroide_lon": -60.7332636467146,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686060000",
      "localidad_censal_id": "06686060",
      "localidad_censal_nombre": "Rojas",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "ROJAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.1932374145653,
      "centroide_lon": -60.6820264999894,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686060001",
      "localidad_censal_id": "06686060",
      "localidad_censal_nombre": "Rojas",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "BARRIO LAS MARGARITAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.1872292263523,
      "centroide_lon": -60.7179411923956,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686060002",
      "localidad_censal_id": "06686060",
      "localidad_censal_nombre": "Rojas",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "ROJAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.1707175989305,
      "centroide_lon": -60.7438336816558,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686060003",
      "localidad_censal_id": "06686060",
      "localidad_censal_nombre": "Rojas",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "VILLA PARQUE CECIR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2689448512236,
      "centroide_lon": -60.8717773420981,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686070000",
      "localidad_censal_id": "06686070",
      "localidad_censal_nombre": "Sol de Mayo",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "ESTACION SOL DE MAYO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.9159508805463,
      "centroide_lon": -60.9379380238883,
      "departamento_id": "06686",
      "departamento_nombre": "Rojas",
      "id": "06686080000",
      "localidad_censal_id": "06686080",
      "localidad_censal_nombre": "Villa Manuel Pomar",
      "municipio_id": "060686",
      "municipio_nombre": "Rojas",
      "nombre": "VILLA MANUEL POMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4854690457283,
      "centroide_lon": -59.1017155978911,
      "departamento_id": "06693",
      "departamento_nombre": "Roque Pérez",
      "id": "06693010000",
      "localidad_censal_id": "06693010",
      "localidad_censal_nombre": "Carlos Beguerie",
      "municipio_id": "060693",
      "municipio_nombre": "Roque Pérez",
      "nombre": "CARLOS BEGUERIE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4016375824204,
      "centroide_lon": -59.3346857845207,
      "departamento_id": "06693",
      "departamento_nombre": "Roque Pérez",
      "id": "06693020000",
      "localidad_censal_id": "06693020",
      "localidad_censal_nombre": "Roque Pérez",
      "municipio_id": "060693",
      "municipio_nombre": "Roque Pérez",
      "nombre": "ROQUE PEREZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.512917973477,
      "centroide_lon": -62.3116837747426,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700010000",
      "localidad_censal_id": "06700010",
      "localidad_censal_nombre": "Arroyo Corto",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "ARROYO CORTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9769152091143,
      "centroide_lon": -62.3326095560459,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700020000",
      "localidad_censal_id": "06700020",
      "localidad_censal_nombre": "Colonia San Martín",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "COLONIA SAN MARTIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9428223013646,
      "centroide_lon": -62.284864272698,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700030000",
      "localidad_censal_id": "06700030",
      "localidad_censal_nombre": "Dufaur",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "DUFAUR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -37.3604343942367,
      "centroide_lon": -62.4297386825705,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700040000",
      "localidad_censal_id": "06700040",
      "localidad_censal_nombre": "Espartillar",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "ESPARTILLAR (E)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7192518502325,
      "centroide_lon": -62.6071296330023,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700050000",
      "localidad_censal_id": "06700050",
      "localidad_censal_nombre": "Goyena",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "GOYENA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0361082716296,
      "centroide_lon": -62.4704140736323,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700055000",
      "localidad_censal_id": "06700055",
      "localidad_censal_nombre": "Las Encadenadas",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "LAS ENCADENADAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6063896033626,
      "centroide_lon": -62.4057728759143,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700060000",
      "localidad_censal_id": "06700060",
      "localidad_censal_nombre": "Pigüé",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "PIGUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7636402199499,
      "centroide_lon": -62.3506328041255,
      "departamento_id": "06700",
      "departamento_nombre": "Saavedra",
      "id": "06700070000",
      "localidad_censal_id": "06700070",
      "localidad_censal_nombre": "Saavedra",
      "municipio_id": "060700",
      "municipio_nombre": "Saavedra",
      "nombre": "SAAVEDRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6391609182127,
      "centroide_lon": -59.6292383440582,
      "departamento_id": "06707",
      "departamento_nombre": "Saladillo",
      "id": "06707010000",
      "localidad_censal_id": "06707010",
      "localidad_censal_nombre": "Álvarez de Toledo",
      "municipio_id": "060707",
      "municipio_nombre": "Saladillo",
      "nombre": "ALVAREZ DE TOLEDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.576803202395,
      "centroide_lon": -59.6645037628687,
      "departamento_id": "06707",
      "departamento_nombre": "Saladillo",
      "id": "06707030000",
      "localidad_censal_id": "06707030",
      "localidad_censal_nombre": "Cazón",
      "municipio_id": "060707",
      "municipio_nombre": "Saladillo",
      "nombre": "CAZON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5127172285926,
      "centroide_lon": -59.5158202035334,
      "departamento_id": "06707",
      "departamento_nombre": "Saladillo",
      "id": "06707040000",
      "localidad_censal_id": "06707040",
      "localidad_censal_nombre": "Del Carril",
      "municipio_id": "060707",
      "municipio_nombre": "Saladillo",
      "nombre": "DEL CARRIL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.593907231155,
      "centroide_lon": -59.5079734849201,
      "departamento_id": "06707",
      "departamento_nombre": "Saladillo",
      "id": "06707050000",
      "localidad_censal_id": "06707050",
      "localidad_censal_nombre": "Polvaredas",
      "municipio_id": "060707",
      "municipio_nombre": "Saladillo",
      "nombre": "POLVAREDAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6404298805989,
      "centroide_lon": -59.7790589845354,
      "departamento_id": "06707",
      "departamento_nombre": "Saladillo",
      "id": "06707060000",
      "localidad_censal_id": "06707060",
      "localidad_censal_nombre": "Saladillo",
      "municipio_id": "060707",
      "municipio_nombre": "Saladillo",
      "nombre": "SALADILLO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -34.1024332833136,
      "centroide_lon": -60.4061551794069,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714010000",
      "localidad_censal_id": "06714010",
      "localidad_censal_nombre": "Arroyo Dulce",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "ARROYO DULCE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3987726587976,
      "centroide_lon": -60.2605725409335,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714020000",
      "localidad_censal_id": "06714020",
      "localidad_censal_nombre": "Berdier",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "BERDIER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3380744009945,
      "centroide_lon": -60.0992736191527,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714030000",
      "localidad_censal_id": "06714030",
      "localidad_censal_nombre": "Gahan",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "GAHAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3994163305262,
      "centroide_lon": -60.5436825925267,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714040000",
      "localidad_censal_id": "06714040",
      "localidad_censal_nombre": "Inés Indart",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "INES INDART",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2687422037239,
      "centroide_lon": -60.3853645216588,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714050000",
      "localidad_censal_id": "06714050",
      "localidad_censal_nombre": "La Invencible",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "LA INVENCIBLE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2921598652878,
      "centroide_lon": -60.2546244496406,
      "departamento_id": "06714",
      "departamento_nombre": "Salto",
      "id": "06714060000",
      "localidad_censal_id": "06714060",
      "localidad_censal_nombre": "Salto",
      "municipio_id": "060714",
      "municipio_nombre": "Salto",
      "nombre": "SALTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5689908284906,
      "centroide_lon": -63.0876229573465,
      "departamento_id": "06721",
      "departamento_nombre": "Salliqueló",
      "id": "06721010000",
      "localidad_censal_id": "06721010",
      "localidad_censal_nombre": "Quenumá",
      "municipio_id": "060721",
      "municipio_nombre": "Salliqueló",
      "nombre": "QUENUMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7511653813281,
      "centroide_lon": -62.9599573443115,
      "departamento_id": "06721",
      "departamento_nombre": "Salliqueló",
      "id": "06721020000",
      "localidad_censal_id": "06721020",
      "localidad_censal_nombre": "Salliqueló",
      "municipio_id": "060721",
      "municipio_nombre": "Salliqueló",
      "nombre": "SALLIQUELO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3637910907784,
      "centroide_lon": -59.3745991110996,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728010000",
      "localidad_censal_id": "06728010",
      "localidad_censal_nombre": "Azcuénaga",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "AZCUENAGA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4460870678,
      "centroide_lon": -59.3622553297801,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728020000",
      "localidad_censal_id": "06728020",
      "localidad_censal_nombre": "Cucullú",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "CULULLU",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6103908719397,
      "centroide_lon": -59.6301795182439,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728030000",
      "localidad_censal_id": "06728030",
      "localidad_censal_nombre": "Franklin",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "FRANKLIN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4459080814748,
      "centroide_lon": -59.4451673563949,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728040000",
      "localidad_censal_id": "06728040",
      "localidad_censal_nombre": "San Andrés de Giles",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "SAN ANDRES DE GILES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2989712949513,
      "centroide_lon": -59.3250547278241,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728050000",
      "localidad_censal_id": "06728050",
      "localidad_censal_nombre": "Solís",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "SOLIS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5066814044811,
      "centroide_lon": -59.3372661316763,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728060000",
      "localidad_censal_id": "06728060",
      "localidad_censal_nombre": "Villa Espil",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "VILLA ESPIL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4352021828488,
      "centroide_lon": -59.260395880426,
      "departamento_id": "06728",
      "departamento_nombre": "San Andrés de Giles",
      "id": "06728070000",
      "localidad_censal_id": "06728070",
      "localidad_censal_nombre": "Villa Ruiz",
      "municipio_id": "060728",
      "municipio_nombre": "San Andrés de Giles",
      "nombre": "VILLA RUIZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2071973030208,
      "centroide_lon": -59.6357055338083,
      "departamento_id": "06735",
      "departamento_nombre": "San Antonio de Areco",
      "id": "06735010000",
      "localidad_censal_id": "06735010",
      "localidad_censal_nombre": "Duggan",
      "municipio_id": "060735",
      "municipio_nombre": "San Antonio de Areco",
      "nombre": "DUGGAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2503763113877,
      "centroide_lon": -59.4708634797005,
      "departamento_id": "06735",
      "departamento_nombre": "San Antonio de Areco",
      "id": "06735020000",
      "localidad_censal_id": "06735020",
      "localidad_censal_nombre": "San Antonio de Areco",
      "municipio_id": "060735",
      "municipio_nombre": "San Antonio de Areco",
      "nombre": "SAN ANTONIO DE ARECO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1237740718192,
      "centroide_lon": -59.4313725724126,
      "departamento_id": "06735",
      "departamento_nombre": "San Antonio de Areco",
      "id": "06735030000",
      "localidad_censal_id": "06735030",
      "localidad_censal_nombre": "Villa Lía",
      "municipio_id": "060735",
      "municipio_nombre": "San Antonio de Areco",
      "nombre": "VILLA LIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7481815016452,
      "centroide_lon": -59.429204589717,
      "departamento_id": "06742",
      "departamento_nombre": "San Cayetano",
      "id": "06742010000",
      "localidad_censal_id": "06742010",
      "localidad_censal_nombre": "Balneario San Cayetano",
      "municipio_id": "060742",
      "municipio_nombre": "San Cayetano",
      "nombre": "BALNEARIO SAN CAYETANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3598244506044,
      "centroide_lon": -59.7935349347064,
      "departamento_id": "06742",
      "departamento_nombre": "San Cayetano",
      "id": "06742020000",
      "localidad_censal_id": "06742020",
      "localidad_censal_nombre": "Ochandío",
      "municipio_id": "060742",
      "municipio_nombre": "San Cayetano",
      "nombre": "OCHANDIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.346970946847,
      "centroide_lon": -59.6063826058006,
      "departamento_id": "06742",
      "departamento_nombre": "San Cayetano",
      "id": "06742030000",
      "localidad_censal_id": "06742030",
      "localidad_censal_nombre": "San Cayetano",
      "municipio_id": "060742",
      "municipio_nombre": "San Cayetano",
      "nombre": "SAN CAYETANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4473601745411,
      "centroide_lon": -58.5690015916776,
      "departamento_id": "06749",
      "departamento_nombre": "San Fernando",
      "id": "06749010001",
      "localidad_censal_id": "06749010",
      "localidad_censal_nombre": "San Fernando",
      "municipio_id": "060749",
      "municipio_nombre": "San Fernando",
      "nombre": "SAN FERNANDO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4619820050413,
      "centroide_lon": -58.5595970877081,
      "departamento_id": "06749",
      "departamento_nombre": "San Fernando",
      "id": "06749010002",
      "localidad_censal_id": "06749010",
      "localidad_censal_nombre": "San Fernando",
      "municipio_id": "060749",
      "municipio_nombre": "San Fernando",
      "nombre": "VICTORIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4636240092728,
      "centroide_lon": -58.5761941633194,
      "departamento_id": "06749",
      "departamento_nombre": "San Fernando",
      "id": "06749010003",
      "localidad_censal_id": "06749010",
      "localidad_censal_nombre": "San Fernando",
      "municipio_id": "060749",
      "municipio_nombre": "San Fernando",
      "nombre": "VIRREYES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4794018120083,
      "centroide_lon": -58.5074043996961,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010001",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "ACASSUSO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4674159462571,
      "centroide_lon": -58.5400343502635,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010002",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "BECCAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4983312888498,
      "centroide_lon": -58.5700137209729,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010003",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "BOULOGNE SUR MER",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4944230772436,
      "centroide_lon": -58.5105732755943,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010004",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "MARTINEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4770412162141,
      "centroide_lon": -58.52719519996,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010005",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "SAN ISIDRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5151197673508,
      "centroide_lon": -58.5457579220177,
      "departamento_id": "06756",
      "departamento_nombre": "San Isidro",
      "id": "06756010006",
      "localidad_censal_id": "06756010",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "060756",
      "municipio_nombre": "San Isidro",
      "nombre": "VILLA ADELINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5751401519414,
      "centroide_lon": -58.6960409885544,
      "departamento_id": "06760",
      "departamento_nombre": "San Miguel",
      "id": "06760010001",
      "localidad_censal_id": "06760010",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "060760",
      "municipio_nombre": "San Miguel",
      "nombre": "BELLA VISTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5346818189868,
      "centroide_lon": -58.6513860167431,
      "departamento_id": "06760",
      "departamento_nombre": "San Miguel",
      "id": "06760010002",
      "localidad_censal_id": "06760010",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "060760",
      "municipio_nombre": "San Miguel",
      "nombre": "CAMPO DE MAYO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5557351629874,
      "centroide_lon": -58.7080227572424,
      "departamento_id": "06760",
      "departamento_nombre": "San Miguel",
      "id": "06760010003",
      "localidad_censal_id": "06760010",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "060760",
      "municipio_nombre": "San Miguel",
      "nombre": "MUÑIZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5536228677773,
      "centroide_lon": -58.7347875195953,
      "departamento_id": "06760",
      "departamento_nombre": "San Miguel",
      "id": "06760010004",
      "localidad_censal_id": "06760010",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "060760",
      "municipio_nombre": "San Miguel",
      "nombre": "SAN MIGUEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5962124822607,
      "centroide_lon": -60.3541264450858,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763010000",
      "localidad_censal_id": "06763010",
      "localidad_censal_nombre": "Conesa",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "CONESA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5232449410318,
      "centroide_lon": -60.3174454958625,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763020000",
      "localidad_censal_id": "06763020",
      "localidad_censal_nombre": "Erezcano",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "EREZCANO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4758683523604,
      "centroide_lon": -60.2874270636731,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763030000",
      "localidad_censal_id": "06763030",
      "localidad_censal_nombre": "General Rojo",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "GENERAL ROJO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3514852802661,
      "centroide_lon": -60.3139573529074,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763040001",
      "localidad_censal_id": "06763040",
      "localidad_censal_nombre": "La Emilia",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "LA EMILIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3599587304228,
      "centroide_lon": -60.3000892882903,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763040002",
      "localidad_censal_id": "06763040",
      "localidad_censal_nombre": "La Emilia",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "VILLA CAMPI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3708479748,
      "centroide_lon": -60.2954116362263,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763040003",
      "localidad_censal_id": "06763040",
      "localidad_censal_nombre": "La Emilia",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "VILLA CANTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3630498059477,
      "centroide_lon": -60.2953990898749,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763040004",
      "localidad_censal_id": "06763040",
      "localidad_censal_nombre": "La Emilia",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "VILLA RICCIO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.4010837029564,
      "centroide_lon": -60.2846972878391,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763050001",
      "localidad_censal_id": "06763050",
      "localidad_censal_nombre": "San Nicolás de los Arroyos",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "CAMPOS SALLES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3487082868277,
      "centroide_lon": -60.231333927581,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763050002",
      "localidad_censal_id": "06763050",
      "localidad_censal_nombre": "San Nicolás de los Arroyos",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "SAN NICOLAS DE LOS ARROYOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.422326147095,
      "centroide_lon": -60.2605874904382,
      "departamento_id": "06763",
      "departamento_nombre": "San Nicolás",
      "id": "06763060000",
      "localidad_censal_id": "06763060",
      "localidad_censal_nombre": "Villa Esperanza",
      "municipio_id": "060763",
      "municipio_nombre": "San Nicolás",
      "nombre": "VILLA ESPERANZA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6605787290919,
      "centroide_lon": -59.8663408291494,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770010000",
      "localidad_censal_id": "06770010",
      "localidad_censal_nombre": "Gobernador Castro",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "GOBERNADOR CASTRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9314631330704,
      "centroide_lon": -59.7482330053997,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770015000",
      "localidad_censal_id": "06770015",
      "localidad_censal_nombre": "Ingeniero Moneta",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "INGENIERO MONETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5965558617631,
      "centroide_lon": -59.8199792397796,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770020000",
      "localidad_censal_id": "06770020",
      "localidad_censal_nombre": "Obligado",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "OBLIGADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9050057189811,
      "centroide_lon": -59.8187532271261,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770030000",
      "localidad_censal_id": "06770030",
      "localidad_censal_nombre": "Pueblo Doyle",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "PUEBLO DOYLE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.769589927701,
      "centroide_lon": -59.6383708591324,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770040000",
      "localidad_censal_id": "06770040",
      "localidad_censal_nombre": "Río Tala",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "RIO TALA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6791252253366,
      "centroide_lon": -59.6668951027895,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770050000",
      "localidad_censal_id": "06770050",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "SAN PEDRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8794559527188,
      "centroide_lon": -59.8753284459435,
      "departamento_id": "06770",
      "departamento_nombre": "San Pedro",
      "id": "06770060000",
      "localidad_censal_id": "06770060",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "060770",
      "municipio_nombre": "San Pedro",
      "nombre": "SANTA LUCIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.0135125285778,
      "centroide_lon": -58.3865346894091,
      "departamento_id": "06778",
      "departamento_nombre": "San Vicente",
      "id": "06778020001",
      "localidad_censal_id": "06778020",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "060778",
      "municipio_nombre": "San Vicente",
      "nombre": "ALEJANDRO KORN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.010404143813,
      "centroide_lon": -58.4699705982043,
      "departamento_id": "06778",
      "departamento_nombre": "San Vicente",
      "id": "06778020002",
      "localidad_censal_id": "06778020",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "060778",
      "municipio_nombre": "San Vicente",
      "nombre": "SAN VICENTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -35.048692550243,
      "centroide_lon": -58.3145745868708,
      "departamento_id": "06778",
      "departamento_nombre": "San Vicente",
      "id": "06778020003",
      "localidad_censal_id": "06778020",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "060778",
      "municipio_nombre": "San Vicente",
      "nombre": "DOMSELAAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6100370680434,
      "centroide_lon": -59.7504234207746,
      "departamento_id": "06784",
      "departamento_nombre": "Suipacha",
      "id": "06784010000",
      "localidad_censal_id": "06784010",
      "localidad_censal_nombre": "General Rivas",
      "municipio_id": "060784",
      "municipio_nombre": "Suipacha",
      "nombre": "GENERAL RIVAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7712617839883,
      "centroide_lon": -59.6879181821818,
      "departamento_id": "06784",
      "departamento_nombre": "Suipacha",
      "id": "06784020000",
      "localidad_censal_id": "06784020",
      "localidad_censal_nombre": "Suipacha",
      "municipio_id": "060784",
      "municipio_nombre": "Suipacha",
      "nombre": "SUIPACHA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1293125663244,
      "centroide_lon": -59.105380206456,
      "departamento_id": "06791",
      "departamento_nombre": "Tandil",
      "id": "06791010000",
      "localidad_censal_id": "06791010",
      "localidad_censal_nombre": "De la Canal",
      "municipio_id": "060791",
      "municipio_nombre": "Tandil",
      "nombre": "DE LA CANAL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2823323375205,
      "centroide_lon": -59.3630004748452,
      "departamento_id": "06791",
      "departamento_nombre": "Tandil",
      "id": "06791030000",
      "localidad_censal_id": "06791030",
      "localidad_censal_nombre": "Gardey",
      "municipio_id": "060791",
      "municipio_nombre": "Tandil",
      "nombre": "GARDEY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4029073074266,
      "centroide_lon": -59.5093354449092,
      "departamento_id": "06791",
      "departamento_nombre": "Tandil",
      "id": "06791040000",
      "localidad_censal_id": "06791040",
      "localidad_censal_nombre": "María Ignacia",
      "municipio_id": "060791",
      "municipio_nombre": "Tandil",
      "nombre": "MARIA IGNACIA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3238849060878,
      "centroide_lon": -59.1310691770429,
      "departamento_id": "06791",
      "departamento_nombre": "Tandil",
      "id": "06791050000",
      "localidad_censal_id": "06791050",
      "localidad_censal_nombre": "Tandil",
      "municipio_id": "060791",
      "municipio_nombre": "Tandil",
      "nombre": "TANDIL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5774103919485,
      "centroide_lon": -60.1711185359919,
      "departamento_id": "06798",
      "departamento_nombre": "Tapalqué",
      "id": "06798010000",
      "localidad_censal_id": "06798010",
      "localidad_censal_nombre": "Crotto",
      "municipio_id": "060798",
      "municipio_nombre": "Tapalqué",
      "nombre": "CROTTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3573669882462,
      "centroide_lon": -60.0247443487218,
      "departamento_id": "06798",
      "departamento_nombre": "Tapalqué",
      "id": "06798020000",
      "localidad_censal_id": "06798020",
      "localidad_censal_nombre": "Tapalqué",
      "municipio_id": "060798",
      "municipio_nombre": "Tapalqué",
      "nombre": "TAPALQUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1215309084723,
      "centroide_lon": -59.6527146246682,
      "departamento_id": "06798",
      "departamento_nombre": "Tapalqué",
      "id": "06798030000",
      "localidad_censal_id": "06798030",
      "localidad_censal_nombre": "Velloso",
      "municipio_id": "060798",
      "municipio_nombre": "Tapalqué",
      "nombre": "VELLOSO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4104347356392,
      "centroide_lon": -58.6887919689512,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010001",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "BENAVIDEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.3717750147952,
      "centroide_lon": -58.6917925096496,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010002",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "DIQUE LUJAN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4869370480479,
      "centroide_lon": -58.6159410299812,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010003",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "DON TORCUATO ESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5035354582885,
      "centroide_lon": -58.6275286549289,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010004",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "DON TORCUATO OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4713213368481,
      "centroide_lon": -58.6570301927479,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010005",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "EL TALAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4523088119909,
      "centroide_lon": -58.6448936162046,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010006",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "GENERAL PACHECO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4425672031974,
      "centroide_lon": -58.6174364127772,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010007",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "LOS TRONCOS DEL TALAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4563339674448,
      "centroide_lon": -58.6806670320976,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010008",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "RICARDO ROJAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4097532043357,
      "centroide_lon": -58.6304827023614,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010009",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "RINCON DE MILBERG",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4266925110065,
      "centroide_lon": -58.5779717541318,
      "departamento_id": "06805",
      "departamento_nombre": "Tigre",
      "id": "06805010010",
      "localidad_censal_id": "06805010",
      "localidad_censal_nombre": "Tigre",
      "municipio_id": "060805",
      "municipio_nombre": "Tigre",
      "nombre": "TIGRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5208587437818,
      "centroide_lon": -57.3249371025508,
      "departamento_id": "06812",
      "departamento_nombre": "Tordillo",
      "id": "06812010000",
      "localidad_censal_id": "06812010",
      "localidad_censal_nombre": "General Conesa",
      "municipio_id": "060812",
      "municipio_nombre": "Tordillo",
      "nombre": "GENERAL CONESA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3352258409739,
      "centroide_lon": -62.6424099899733,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819010000",
      "localidad_censal_id": "06819010",
      "localidad_censal_nombre": "Chasicó",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "CHASICO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2028131038038,
      "centroide_lon": -61.7678925154653,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819020000",
      "localidad_censal_id": "06819020",
      "localidad_censal_nombre": "Saldungaray",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "SALDUNGARAY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.1366716139365,
      "centroide_lon": -61.7956860399239,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819030000",
      "localidad_censal_id": "06819030",
      "localidad_censal_nombre": "Sierra de la Ventana",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "SIERRA DE LA VENTANA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0989983131157,
      "centroide_lon": -62.2218091270044,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819040000",
      "localidad_censal_id": "06819040",
      "localidad_censal_nombre": "Tornquist",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "TORNQUIST",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2867749310339,
      "centroide_lon": -62.2073117423093,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819050000",
      "localidad_censal_id": "06819050",
      "localidad_censal_nombre": "Tres Picos",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "TRES PICOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0568266332177,
      "centroide_lon": -62.0782618310425,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819060000",
      "localidad_censal_id": "06819060",
      "localidad_censal_nombre": "La Gruta",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "VILLA SERRANA LA GRUTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0795765714478,
      "centroide_lon": -61.9311383483155,
      "departamento_id": "06819",
      "departamento_nombre": "Tornquist",
      "id": "06819070000",
      "localidad_censal_id": "06819070",
      "localidad_censal_nombre": "Villa Ventana",
      "municipio_id": "060819",
      "municipio_nombre": "Tornquist",
      "nombre": "VILLA VENTANA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8580311359835,
      "centroide_lon": -62.5126328995628,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826010000",
      "localidad_censal_id": "06826010",
      "localidad_censal_nombre": "Berutti",
      "municipio_id": "060826",
      "municipio_nombre": "Trenque Lauquen",
      "nombre": "BERUTTI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.367451804132,
      "centroide_lon": -62.3569660936558,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826020000",
      "localidad_censal_id": "06826020",
      "localidad_censal_nombre": "Girodias",
      "municipio_id": "060231",
      "municipio_nombre": "Daireaux",
      "nombre": "GIRODIAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1964811594442,
      "centroide_lon": -62.2245641400875,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826030000",
      "localidad_censal_id": "06826030",
      "localidad_censal_nombre": "La Carreta",
      "municipio_id": "060826",
      "municipio_nombre": "Trenque Lauquen",
      "nombre": "LA CARRETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2779720057322,
      "centroide_lon": -62.5453088654651,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826040000",
      "localidad_censal_id": "06826040",
      "localidad_censal_nombre": "30 de Agosto",
      "municipio_id": "060826",
      "municipio_nombre": "Trenque Lauquen",
      "nombre": "30 DE AGOSTO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9746951559846,
      "centroide_lon": -62.7323099655579,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826050000",
      "localidad_censal_id": "06826050",
      "localidad_censal_nombre": "Trenque Lauquen",
      "municipio_id": "060826",
      "municipio_nombre": "Trenque Lauquen",
      "nombre": "TRENQUE LAUQUEN",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4603092402658,
      "centroide_lon": -62.4871229040303,
      "departamento_id": "06826",
      "departamento_nombre": "Trenque Lauquen",
      "id": "06826060000",
      "localidad_censal_id": "06826060",
      "localidad_censal_nombre": "Trongé",
      "municipio_id": "060826",
      "municipio_nombre": "Trenque Lauquen",
      "nombre": "TRONGE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8079580951783,
      "centroide_lon": -59.7382989446997,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833010000",
      "localidad_censal_id": "06833010",
      "localidad_censal_nombre": "Balneario Orense",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "BALNEARIO ORENSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7973744243016,
      "centroide_lon": -60.107623035402,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833020001",
      "localidad_censal_id": "06833020",
      "localidad_censal_nombre": "Claromecó",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "CLAROMECO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8586780962346,
      "centroide_lon": -60.0873319312173,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833020002",
      "localidad_censal_id": "06833020",
      "localidad_censal_nombre": "Claromecó",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "DUNAMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7227403438549,
      "centroide_lon": -60.4519655195991,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833030000",
      "localidad_censal_id": "06833030",
      "localidad_censal_nombre": "Copetonas",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "COPETONAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7081778337076,
      "centroide_lon": -60.2417246463467,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833040000",
      "localidad_censal_id": "06833040",
      "localidad_censal_nombre": "Lin Calel",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "LIN CALEL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.4926790060303,
      "centroide_lon": -60.4684232796744,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833050000",
      "localidad_censal_id": "06833050",
      "localidad_censal_nombre": "Micaela Cascallares",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "MICAELA CASCALLARES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6855169253543,
      "centroide_lon": -59.7764287092023,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833060000",
      "localidad_censal_id": "06833060",
      "localidad_censal_nombre": "Orense",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "ORENSE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8970805514765,
      "centroide_lon": -60.343409791669,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833070000",
      "localidad_censal_id": "06833070",
      "localidad_censal_nombre": "Reta",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "RETA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6931705173547,
      "centroide_lon": -60.0141786926695,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833080000",
      "localidad_censal_id": "06833080",
      "localidad_censal_nombre": "San Francisco de Bellocq",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "SAN FRANCISCO DE BELLOCQ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3177365227324,
      "centroide_lon": -60.0258421214843,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833090000",
      "localidad_censal_id": "06833090",
      "localidad_censal_nombre": "San Mayol",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "SAN MAYOL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3771785795114,
      "centroide_lon": -60.2755588939396,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833100000",
      "localidad_censal_id": "06833100",
      "localidad_censal_nombre": "Tres Arroyos",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "TRES ARROYOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3125817693893,
      "centroide_lon": -60.2324995777758,
      "departamento_id": "06833",
      "departamento_nombre": "Tres Arroyos",
      "id": "06833110000",
      "localidad_censal_id": "06833110",
      "localidad_censal_nombre": "Villa Rodríguez",
      "municipio_id": "060833",
      "municipio_nombre": "Tres Arroyos",
      "nombre": "VILLA RODRIGUEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.608872950993,
      "centroide_lon": -58.5636644572416,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010001",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "CASEROS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5589664800215,
      "centroide_lon": -58.6228016204112,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010002",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "CHURRUCA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5961197253917,
      "centroide_lon": -58.5965998962097,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010003",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "CIUDAD JARDIN LOMAS DEL PALOMAR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6326965166188,
      "centroide_lon": -58.5424067827037,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010004",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "CIUDADELA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5564986200599,
      "centroide_lon": -58.622337837699,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010005",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "EL LIBERTADOR",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6171528691842,
      "centroide_lon": -58.5342186326877,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010006",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "JOSE INGENIEROS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5684066633166,
      "centroide_lon": -58.603081885803,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010007",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "LOMA HERMOSA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5852038063892,
      "centroide_lon": -58.5918755543704,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010008",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "MARTIN CORONADO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.566314345456,
      "centroide_lon": -58.6183600679169,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010009",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "11 DE SEPTIEMBRE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5758246152249,
      "centroide_lon": -58.6202497472316,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010010",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "PABLO PODESTA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5705752444729,
      "centroide_lon": -58.620118067161,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010011",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "REMEDIOS DE ESCALADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5992886088748,
      "centroide_lon": -58.5320796402641,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010012",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "SAENZ PEÑA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5991533496562,
      "centroide_lon": -58.5478835120962,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010013",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "SANTOS LUGARES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5822981982887,
      "centroide_lon": -58.5802932642206,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010014",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "VILLA BOSCH (EST. JUAN MARIA BOSCH)",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6083090753398,
      "centroide_lon": -58.5319718083201,
      "departamento_id": "06840",
      "departamento_nombre": "Tres de Febrero",
      "id": "06840010015",
      "localidad_censal_id": "06840010",
      "localidad_censal_nombre": "Tres de Febrero",
      "municipio_id": "060840",
      "municipio_nombre": "Tres de Febrero",
      "nombre": "VILLA RAFFO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.6102541131892,
      "centroide_lon": -62.9109612139671,
      "departamento_id": "06847",
      "departamento_nombre": "Tres Lomas",
      "id": "06847010000",
      "localidad_censal_id": "06847010",
      "localidad_censal_nombre": "Ingeniero Thompson",
      "municipio_id": "060847",
      "municipio_nombre": "Tres Lomas",
      "nombre": "INGENIERO THOMPSON",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.458109497037,
      "centroide_lon": -62.8621355091327,
      "departamento_id": "06847",
      "departamento_nombre": "Tres Lomas",
      "id": "06847020000",
      "localidad_censal_id": "06847020",
      "localidad_censal_nombre": "Tres Lomas",
      "municipio_id": "060847",
      "municipio_nombre": "Tres Lomas",
      "nombre": "TRES LOMAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7392169542026,
      "centroide_lon": -60.5590634621789,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854010000",
      "localidad_censal_id": "06854010",
      "localidad_censal_nombre": "Agustín Mosconi",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "AGUSTIN MOSCONI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8973453887603,
      "centroide_lon": -60.7316089512944,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854020000",
      "localidad_censal_id": "06854020",
      "localidad_censal_nombre": "Del Valle",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "DEL VALLE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2702340519789,
      "centroide_lon": -59.5575034078873,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854030000",
      "localidad_censal_id": "06854030",
      "localidad_censal_nombre": "Ernestina",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "ERNESTINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1643973648093,
      "centroide_lon": -60.0813577815069,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854040000",
      "localidad_censal_id": "06854040",
      "localidad_censal_nombre": "Gobernador Ugarte",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "GOBERNADOR UGARTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4838272164734,
      "centroide_lon": -59.9880647932831,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854050000",
      "localidad_censal_id": "06854050",
      "localidad_censal_nombre": "Lucas Monteverde",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "LUCAS MONTEVERDE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2727788695321,
      "centroide_lon": -59.7719327250509,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854060000",
      "localidad_censal_id": "06854060",
      "localidad_censal_nombre": "Norberto de la Riestra",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "NORBERTO DE LA RIESTRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2666085074294,
      "centroide_lon": -59.6306574695384,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854070000",
      "localidad_censal_id": "06854070",
      "localidad_censal_nombre": "Pedernales",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "PEDERNALES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7785028686085,
      "centroide_lon": -60.3546454811901,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854080000",
      "localidad_censal_id": "06854080",
      "localidad_censal_nombre": "San Enrique",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "SAN ENRIQUE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6488128268597,
      "centroide_lon": -60.4672591673156,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854090000",
      "localidad_censal_id": "06854090",
      "localidad_censal_nombre": "Valdés",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "VALDES",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4339385892588,
      "centroide_lon": -60.1731209454056,
      "departamento_id": "06854",
      "departamento_nombre": "25 de Mayo",
      "id": "06854100000",
      "localidad_censal_id": "06854100",
      "localidad_censal_nombre": "25 de Mayo",
      "municipio_id": "060854",
      "municipio_nombre": "25 de Mayo",
      "nombre": "25 DE MAYO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5259149691463,
      "centroide_lon": -58.5357639427634,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010001",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "CARAPACHAY",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5327432636609,
      "centroide_lon": -58.4910979599198,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010002",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "FLORIDA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5394223366496,
      "centroide_lon": -58.5148137943065,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010003",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "FLORIDA OESTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.4985433329471,
      "centroide_lon": -58.486582489457,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010004",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "LA LUCILA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5272623078248,
      "centroide_lon": -58.5255060588714,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010005",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "MUNRO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.512161433984,
      "centroide_lon": -58.4985444232342,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010006",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "OLIVOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.525536745172,
      "centroide_lon": -58.4736495025234,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010007",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "VICENTE LOPEZ",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5287596487564,
      "centroide_lon": -58.5460242623901,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010008",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "VILLA ADELINA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5525979527695,
      "centroide_lon": -58.5091432644227,
      "departamento_id": "06861",
      "departamento_nombre": "Vicente López",
      "id": "06861010009",
      "localidad_censal_id": "06861010",
      "localidad_censal_nombre": "Vicente López",
      "municipio_id": "060861",
      "municipio_nombre": "Vicente López",
      "nombre": "VILLA MARTELLI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.3366731576801,
      "centroide_lon": -57.032501468863,
      "departamento_id": "06868",
      "departamento_nombre": "Villa Gesell",
      "id": "06868010001",
      "localidad_censal_id": "06868010",
      "localidad_censal_nombre": "Mar Azul",
      "municipio_id": "060868",
      "municipio_nombre": "Villa Gesell",
      "nombre": "MAR AZUL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -37.3281312355181,
      "centroide_lon": -57.0399070097774,
      "departamento_id": "06868",
      "departamento_nombre": "Villa Gesell",
      "id": "06868010002",
      "localidad_censal_id": "06868010",
      "localidad_censal_nombre": "Mar Azul",
      "municipio_id": "060868",
      "municipio_nombre": "Villa Gesell",
      "nombre": "MAR DE LAS PAMPAS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2464704698501,
      "centroide_lon": -56.9707267624406,
      "departamento_id": "06868",
      "departamento_nombre": "Villa Gesell",
      "id": "06868020000",
      "localidad_censal_id": "06868020",
      "localidad_censal_nombre": "Villa Gesell",
      "municipio_id": "060868",
      "municipio_nombre": "Villa Gesell",
      "nombre": "VILLA GESELL",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7686560837266,
      "centroide_lon": -62.6025276771033,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875010000",
      "localidad_censal_id": "06875010",
      "localidad_censal_nombre": "Argerich",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "ARGERICH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.3983952518232,
      "centroide_lon": -62.5711721088672,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875020000",
      "localidad_censal_id": "06875020",
      "localidad_censal_nombre": "Colonia San Adolfo",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "COLONIA SAN ADOLFO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8468445915571,
      "centroide_lon": -62.738741885831,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875025000",
      "localidad_censal_id": "06875025",
      "localidad_censal_nombre": "Country Los Medanos",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "COUNTRY LOS MEDANOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.3758602632663,
      "centroide_lon": -62.6477900877351,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875030000",
      "localidad_censal_id": "06875030",
      "localidad_censal_nombre": "Hilario Ascasubi",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "HILARIO ASCASUBI",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8942604188728,
      "centroide_lon": -63.1361581918897,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875040000",
      "localidad_censal_id": "06875040",
      "localidad_censal_nombre": "Juan Cousté",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "JUAN COUSTE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2590946793617,
      "centroide_lon": -62.6157889589915,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875050000",
      "localidad_censal_id": "06875050",
      "localidad_censal_nombre": "Mayor Buratovich",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "MAYOR BURATOVICH",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8293145512181,
      "centroide_lon": -62.6943321574343,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875060002",
      "localidad_censal_id": "06875060",
      "localidad_censal_nombre": "Médanos",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "MEDANOS",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.5007767274615,
      "centroide_lon": -62.6874317159849,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875070000",
      "localidad_censal_id": "06875070",
      "localidad_censal_nombre": "Pedro Luro",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "PEDRO LURO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0580586668564,
      "centroide_lon": -62.5697137402023,
      "departamento_id": "06875",
      "departamento_nombre": "Villarino",
      "id": "06875080000",
      "localidad_censal_id": "06875080",
      "localidad_censal_nombre": "Teniente Origone",
      "municipio_id": "060875",
      "municipio_nombre": "Villarino",
      "nombre": "TENIENTE ORIGONE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1275885141615,
      "centroide_lon": -59.0834596008166,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882020000",
      "localidad_censal_id": "06882020",
      "localidad_censal_nombre": "Country Club El Casco",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "COUNTRY CLUB EL CASCO",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.160933188334,
      "centroide_lon": -59.1125399836881,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882030000",
      "localidad_censal_id": "06882030",
      "localidad_censal_nombre": "Escalada",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "ESCALADA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0436335939636,
      "centroide_lon": -59.1973339595539,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882040000",
      "localidad_censal_id": "06882040",
      "localidad_censal_nombre": "Lima",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "LIMA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -34.0998630672257,
      "centroide_lon": -59.024557255152,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882050000",
      "localidad_censal_id": "06882050",
      "localidad_censal_nombre": "Zárate",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "ZARATE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.1263843372848,
      "centroide_lon": -59.020156759664,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882050001",
      "localidad_censal_id": "06882050",
      "localidad_censal_nombre": "Zárate",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "BARRIO SAAVEDRA",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.1115306481926,
      "centroide_lon": -59.0483426856883,
      "departamento_id": "06882",
      "departamento_nombre": "Zárate",
      "id": "06882050002",
      "localidad_censal_id": "06882050",
      "localidad_censal_nombre": "Zárate",
      "municipio_id": "060882",
      "municipio_nombre": "Zárate",
      "nombre": "ZARATE",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9072844349135,
      "centroide_lon": -65.8265242094263,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007010000",
      "localidad_censal_id": "10007010",
      "localidad_censal_nombre": "Chuchucaruana",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "CHUCHUCARUANA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.056472497625,
      "centroide_lon": -65.8260844153652,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007020000",
      "localidad_censal_id": "10007020",
      "localidad_censal_nombre": "Colpes",
      "municipio_id": "100014",
      "municipio_nombre": "La Puerta",
      "nombre": "COLPES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9038743420396,
      "centroide_lon": -65.8884829262542,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007030000",
      "localidad_censal_id": "10007030",
      "localidad_censal_nombre": "El Bolsón",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "EL BOLSON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2141516424861,
      "centroide_lon": -65.8738600634115,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007040000",
      "localidad_censal_id": "10007040",
      "localidad_censal_nombre": "El Rodeo",
      "municipio_id": "100007",
      "municipio_nombre": "El Rodeo",
      "nombre": "EL RODEO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0988458342305,
      "centroide_lon": -65.8147464336478,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007050000",
      "localidad_censal_id": "10007050",
      "localidad_censal_nombre": "Huaycama",
      "municipio_id": "100014",
      "municipio_nombre": "La Puerta",
      "nombre": "HUAYCAMA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1764379052117,
      "centroide_lon": -65.7863037835082,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007060000",
      "localidad_censal_id": "10007060",
      "localidad_censal_nombre": "La Puerta",
      "municipio_id": "100014",
      "municipio_nombre": "La Puerta",
      "nombre": "LA PUERTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6464168267027,
      "centroide_lon": -65.9533446470458,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007070000",
      "localidad_censal_id": "10007070",
      "localidad_censal_nombre": "Las Chacritas",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "LAS CHACRITAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1038836314671,
      "centroide_lon": -65.8996513641899,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007080000",
      "localidad_censal_id": "10007080",
      "localidad_censal_nombre": "Las Juntas",
      "municipio_id": "100021",
      "municipio_nombre": "Las Juntas",
      "nombre": "LAS JUNTAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9582295650792,
      "centroide_lon": -65.820685723313,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007090000",
      "localidad_censal_id": "10007090",
      "localidad_censal_nombre": "Los Castillos",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "LOS CASTILLOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.94999754917,
      "centroide_lon": -65.875829814164,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007100000",
      "localidad_censal_id": "10007100",
      "localidad_censal_nombre": "Los Talas",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "LOS TALAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9279942328057,
      "centroide_lon": -65.8822302327591,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007110000",
      "localidad_censal_id": "10007110",
      "localidad_censal_nombre": "Los Varela",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "LOS VARELA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8142168571341,
      "centroide_lon": -65.8670660017181,
      "departamento_id": "10007",
      "departamento_nombre": "Ambato",
      "id": "10007120000",
      "localidad_censal_id": "10007120",
      "localidad_censal_nombre": "Singuil",
      "municipio_id": "100028",
      "municipio_nombre": "Los Varela",
      "nombre": "SINGUIL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.809829766706,
      "centroide_lon": -65.5021019996524,
      "departamento_id": "10014",
      "departamento_nombre": "Ancasti",
      "id": "10014010000",
      "localidad_censal_id": "10014010",
      "localidad_censal_nombre": "Ancasti",
      "municipio_id": "100035",
      "municipio_nombre": "Ancasti",
      "nombre": "ANCASTI",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7545936474404,
      "centroide_lon": -65.5495102803337,
      "departamento_id": "10014",
      "departamento_nombre": "Ancasti",
      "id": "10014020000",
      "localidad_censal_id": "10014020",
      "localidad_censal_nombre": "Anquincila",
      "municipio_id": "100035",
      "municipio_nombre": "Ancasti",
      "nombre": "ANQUINCILA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7207622901373,
      "centroide_lon": -65.4106917370235,
      "departamento_id": "10014",
      "departamento_nombre": "Ancasti",
      "id": "10014030000",
      "localidad_censal_id": "10014030",
      "localidad_censal_nombre": "La Candelaria",
      "municipio_id": "100035",
      "municipio_nombre": "Ancasti",
      "nombre": "LA CANDELARIA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0295107636719,
      "centroide_lon": -65.5499567687055,
      "departamento_id": "10014",
      "departamento_nombre": "Ancasti",
      "id": "10014040000",
      "localidad_censal_id": "10014040",
      "localidad_censal_nombre": "La Majada",
      "municipio_id": "100035",
      "municipio_nombre": "Ancasti",
      "nombre": "LA MAJADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5275895579827,
      "centroide_lon": -66.5156761677031,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021010000",
      "localidad_censal_id": "10021010",
      "localidad_censal_nombre": "Amanao",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "AMANAO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5732225954972,
      "centroide_lon": -66.3235466394645,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021020000",
      "localidad_censal_id": "10021020",
      "localidad_censal_nombre": "Andalgalá",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "ANDALGALA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5368955481985,
      "centroide_lon": -66.3350568927423,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021030000",
      "localidad_censal_id": "10021030",
      "localidad_censal_nombre": "Chaquiago",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "CHAQUIAGO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5210749343604,
      "centroide_lon": -66.4034742805443,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021040000",
      "localidad_censal_id": "10021040",
      "localidad_censal_nombre": "Choya",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "CHOYA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.3391922396844,
      "centroide_lon": -66.0241892155379,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021050001",
      "localidad_censal_id": "10021050",
      "localidad_censal_nombre": "El Alamito",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "BUENA VISTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.333537875981,
      "centroide_lon": -66.022985174122,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021050002",
      "localidad_censal_id": "10021050",
      "localidad_censal_nombre": "El Alamito",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "EL ALAMITO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4743798884533,
      "centroide_lon": -66.0266884349829,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021060001",
      "localidad_censal_id": "10021060",
      "localidad_censal_nombre": "El Lindero",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "ACONQUIJA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4511959792163,
      "centroide_lon": -66.0199688270559,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021060002",
      "localidad_censal_id": "10021060",
      "localidad_censal_nombre": "El Lindero",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "ALTO DE LAS JUNTAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4743798884533,
      "centroide_lon": -66.0266884349829,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021060003",
      "localidad_censal_id": "10021060",
      "localidad_censal_nombre": "El Lindero",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "EL LINDERO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4511959792163,
      "centroide_lon": -66.0199688270559,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021060004",
      "localidad_censal_id": "10021060",
      "localidad_censal_nombre": "El Lindero",
      "municipio_id": "100042",
      "municipio_nombre": "Aconquija",
      "nombre": "LA MESADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5162322380357,
      "centroide_lon": -66.3434280762552,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021070000",
      "localidad_censal_id": "10021070",
      "localidad_censal_nombre": "El Potrero",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "EL POTRERO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.534089032104,
      "centroide_lon": -66.3117750484602,
      "departamento_id": "10021",
      "departamento_nombre": "Andalgalá",
      "id": "10021080000",
      "localidad_censal_id": "10021080",
      "localidad_censal_nombre": "La Aguada",
      "municipio_id": "100049",
      "municipio_nombre": "Andalgalá",
      "nombre": "LA AGUADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0632957365737,
      "centroide_lon": -67.4116706391736,
      "departamento_id": "10028",
      "departamento_nombre": "Antofagasta de la Sierra",
      "id": "10028010000",
      "localidad_censal_id": "10028010",
      "localidad_censal_nombre": "Antofagasta de la Sierra",
      "municipio_id": "100056",
      "municipio_nombre": "Antofagasta de la Sierra",
      "nombre": "ANTOFAGASTA DE LA SIERRA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.445326397371,
      "centroide_lon": -67.6583954684648,
      "departamento_id": "10028",
      "departamento_nombre": "Antofagasta de la Sierra",
      "id": "10028020000",
      "localidad_censal_id": "10028020",
      "localidad_censal_nombre": "Antofalla",
      "municipio_id": "100056",
      "municipio_nombre": "Antofagasta de la Sierra",
      "nombre": "ANTOFALLA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4377795455164,
      "centroide_lon": -67.2590953594946,
      "departamento_id": "10028",
      "departamento_nombre": "Antofagasta de la Sierra",
      "id": "10028030000",
      "localidad_censal_id": "10028030",
      "localidad_censal_nombre": "El Peñón",
      "municipio_id": "100056",
      "municipio_nombre": "Antofagasta de la Sierra",
      "nombre": "EL PEÑON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7592665755338,
      "centroide_lon": -67.3912671398198,
      "departamento_id": "10028",
      "departamento_nombre": "Antofagasta de la Sierra",
      "id": "10028040000",
      "localidad_censal_id": "10028040",
      "localidad_censal_nombre": "Los Nacimientos",
      "municipio_id": "100056",
      "municipio_nombre": "Antofagasta de la Sierra",
      "nombre": "LOS NACIMIENTOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9366725902001,
      "centroide_lon": -66.7473736733687,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035010000",
      "localidad_censal_id": "10035010",
      "localidad_censal_nombre": "Barranca Larga",
      "municipio_id": "100119",
      "municipio_nombre": "Villa Vil",
      "nombre": "BARRANCA LARGA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6337582271366,
      "centroide_lon": -67.0181224346383,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035020000",
      "localidad_censal_id": "10035020",
      "localidad_censal_nombre": "Belén",
      "municipio_id": "100063",
      "municipio_nombre": "Belén",
      "nombre": "BELEN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4800253698881,
      "centroide_lon": -67.1034365555174,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035030000",
      "localidad_censal_id": "10035030",
      "localidad_censal_nombre": "Cóndor Huasi",
      "municipio_id": "100091",
      "municipio_nombre": "Pozo de Piedra",
      "nombre": "CONDOR HUASI",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1458266170631,
      "centroide_lon": -66.9418772140389,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035040000",
      "localidad_censal_id": "10035040",
      "localidad_censal_nombre": "Corral Quemado",
      "municipio_id": "100070",
      "municipio_nombre": "Corral Quemado",
      "nombre": "CORRAL QUEMADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2352160693093,
      "centroide_lon": -67.0644302349354,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035050000",
      "localidad_censal_id": "10035050",
      "localidad_censal_nombre": "El Durazno",
      "municipio_id": "100098",
      "municipio_nombre": "Puerta de Corral Quemado",
      "nombre": "EL DURAZNO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.297991160481,
      "centroide_lon": -66.6526535881206,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035060000",
      "localidad_censal_id": "10035060",
      "localidad_censal_nombre": "Farallón Negro",
      "municipio_id": "100077",
      "municipio_nombre": "Hualfín",
      "nombre": "FARALLON NEGRO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2251089488391,
      "centroide_lon": -66.8257494663378,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035070000",
      "localidad_censal_id": "10035070",
      "localidad_censal_nombre": "Hualfín",
      "municipio_id": "100077",
      "municipio_nombre": "Hualfín",
      "nombre": "HUALFIN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2232502935304,
      "centroide_lon": -67.0189560597067,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035080000",
      "localidad_censal_id": "10035080",
      "localidad_censal_nombre": "Jacipunco",
      "municipio_id": "100098",
      "municipio_nombre": "Puerta de Corral Quemado",
      "nombre": "JACIPUNCO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6687999477098,
      "centroide_lon": -66.9831854181017,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035090000",
      "localidad_censal_id": "10035090",
      "localidad_censal_nombre": "La Puntilla",
      "municipio_id": "100063",
      "municipio_nombre": "Belén",
      "nombre": "LA PUNTILLA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5255267279244,
      "centroide_lon": -67.1230327103174,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035100000",
      "localidad_censal_id": "10035100",
      "localidad_censal_nombre": "Las Juntas",
      "municipio_id": "100091",
      "municipio_nombre": "Pozo de Piedra",
      "nombre": "LAS JUNTAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7091441699136,
      "centroide_lon": -67.1521288574758,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035110000",
      "localidad_censal_id": "10035110",
      "localidad_censal_nombre": "Londres",
      "municipio_id": "100084",
      "municipio_nombre": "Londres",
      "nombre": "LONDRES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1276313861506,
      "centroide_lon": -66.7125336324121,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035120000",
      "localidad_censal_id": "10035120",
      "localidad_censal_nombre": "Los Nacimientos",
      "municipio_id": "100077",
      "municipio_nombre": "Hualfín",
      "nombre": "LOS NACIMIENTOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2142919344438,
      "centroide_lon": -66.9263178640934,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035130000",
      "localidad_censal_id": "10035130",
      "localidad_censal_nombre": "Puerta de Corral Quemado",
      "municipio_id": "100098",
      "municipio_nombre": "Puerta de Corral Quemado",
      "nombre": "PUERTA DE CORRAL QUEMADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5397412070942,
      "centroide_lon": -67.0153310519752,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035140000",
      "localidad_censal_id": "10035140",
      "localidad_censal_nombre": "Puerta de San José",
      "municipio_id": "100105",
      "municipio_nombre": "Puerta de San José",
      "nombre": "PUERTA DE SAN JOSE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0710806751114,
      "centroide_lon": -66.8307696967369,
      "departamento_id": "10035",
      "departamento_nombre": "Belén",
      "id": "10035150000",
      "localidad_censal_id": "10035150",
      "localidad_censal_nombre": "Villa Vil",
      "municipio_id": "100119",
      "municipio_nombre": "Villa Vil",
      "nombre": "VILLA VIL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0269775235925,
      "centroide_lon": -65.9719315369666,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042010000",
      "localidad_censal_id": "10042010",
      "localidad_censal_nombre": "Adolfo E. Carranza",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "ADOLFO E. CARRANZA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5589484234071,
      "centroide_lon": -65.5807240749041,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042020000",
      "localidad_censal_id": "10042020",
      "localidad_censal_nombre": "Balde de la Punta",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "BALDE DE LA PUNTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7807106449765,
      "centroide_lon": -66.0386455444458,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042030000",
      "localidad_censal_id": "10042030",
      "localidad_censal_nombre": "Capayán",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "CAPAYAN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8475652676601,
      "centroide_lon": -66.2413567840968,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042040000",
      "localidad_censal_id": "10042040",
      "localidad_censal_nombre": "Chumbicha",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "CHUMBICHA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6659572317315,
      "centroide_lon": -65.8752053335172,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042050000",
      "localidad_censal_id": "10042050",
      "localidad_censal_nombre": "Colonia del Valle",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "COLONIA DEL VALLE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5859218596167,
      "centroide_lon": -65.8384330489801,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042060000",
      "localidad_censal_id": "10042060",
      "localidad_censal_nombre": "Colonia Nueva Coneta",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "COLONIA NUEVA CONETA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7027878127673,
      "centroide_lon": -66.0684404425234,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042070000",
      "localidad_censal_id": "10042070",
      "localidad_censal_nombre": "Concepción",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "CONCEPCION",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5824940471212,
      "centroide_lon": -65.8832899522771,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042080000",
      "localidad_censal_id": "10042080",
      "localidad_censal_nombre": "Coneta",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "CONETA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6492910442287,
      "centroide_lon": -65.8184083876597,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042090000",
      "localidad_censal_id": "10042090",
      "localidad_censal_nombre": "El Bañado",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "EL BAÑADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7326533523263,
      "centroide_lon": -65.9692671337206,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042100000",
      "localidad_censal_id": "10042100",
      "localidad_censal_nombre": "Huillapima",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "HUILLAPIMA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -28.4757282890296,
      "centroide_lon": -65.9584714095267,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042110000",
      "localidad_censal_id": "10042110",
      "localidad_censal_nombre": "Los Angeles",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "LOS ANGELES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4130524181894,
      "centroide_lon": -65.9974963114274,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042110001",
      "localidad_censal_id": "10042110",
      "localidad_censal_nombre": "Los Angeles",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "LOS ANGELES NORTE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5096259939006,
      "centroide_lon": -66.0134501995358,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042110002",
      "localidad_censal_id": "10042110",
      "localidad_censal_nombre": "Los Angeles",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "LOS ANGELES SUR",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.61156437771,
      "centroide_lon": -65.9045510608673,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042120000",
      "localidad_censal_id": "10042120",
      "localidad_censal_nombre": "Miraflores",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "MIRAFLORES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2514238184412,
      "centroide_lon": -65.7966971627176,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042130000",
      "localidad_censal_id": "10042130",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "SAN MARTIN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7185211619112,
      "centroide_lon": -66.0406031753627,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042140000",
      "localidad_censal_id": "10042140",
      "localidad_censal_nombre": "San Pablo",
      "municipio_id": "100133",
      "municipio_nombre": "Huillapima",
      "nombre": "SAN PABLO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7717177596284,
      "centroide_lon": -66.1240600986815,
      "departamento_id": "10042",
      "departamento_nombre": "Capayán",
      "id": "10042150000",
      "localidad_censal_id": "10042150",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "100126",
      "municipio_nombre": "Capayán",
      "nombre": "SAN PEDRO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5416893432334,
      "centroide_lon": -65.8026632730873,
      "departamento_id": "10049",
      "departamento_nombre": "Capital",
      "id": "10049020000",
      "localidad_censal_id": "10049020",
      "localidad_censal_nombre": "El Pantanillo",
      "municipio_id": "100140",
      "municipio_nombre": "San Fernando del Valle de Catamarca",
      "nombre": "EL PANTANILLO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -28.4846581947085,
      "centroide_lon": -65.7867892937631,
      "departamento_id": "10049",
      "departamento_nombre": "Capital",
      "id": "10049030000",
      "localidad_censal_id": "10049030",
      "localidad_censal_nombre": "San Fernando del Valle de Catamarca",
      "municipio_id": "100140",
      "municipio_nombre": "San Fernando del Valle de Catamarca",
      "nombre": "SAN FERNANDO DEL VALLE DE CATAMARCA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3027477561138,
      "centroide_lon": -65.3693767192491,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056010000",
      "localidad_censal_id": "10056010",
      "localidad_censal_nombre": "El Alto",
      "municipio_id": "100147",
      "municipio_nombre": "El Alto",
      "nombre": "EL ALTO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3441266642037,
      "centroide_lon": -65.4127476684157,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056020000",
      "localidad_censal_id": "10056020",
      "localidad_censal_nombre": "Guayamba",
      "municipio_id": "100147",
      "municipio_nombre": "El Alto",
      "nombre": "GUAYAMBA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5973244077887,
      "centroide_lon": -65.4116602658918,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056030000",
      "localidad_censal_id": "10056030",
      "localidad_censal_nombre": "Infanzón",
      "municipio_id": "100147",
      "municipio_nombre": "El Alto",
      "nombre": "INFANZON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5108290239161,
      "centroide_lon": -65.3308772218931,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056040000",
      "localidad_censal_id": "10056040",
      "localidad_censal_nombre": "Los Corrales",
      "municipio_id": "100147",
      "municipio_nombre": "El Alto",
      "nombre": "LOS CORRALES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -28.4159041977159,
      "centroide_lon": -65.1086409589416,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056050000",
      "localidad_censal_id": "10056050",
      "localidad_censal_nombre": "Tapso",
      "municipio_id": "100154",
      "municipio_nombre": "Tapso",
      "nombre": "TAPSO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5021133153073,
      "centroide_lon": -65.4385489995235,
      "departamento_id": "10056",
      "departamento_nombre": "El Alto",
      "id": "10056060000",
      "localidad_censal_id": "10056060",
      "localidad_censal_nombre": "Vilismán",
      "municipio_id": "100147",
      "municipio_nombre": "El Alto",
      "nombre": "VILISMAN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3616776397898,
      "centroide_lon": -65.7282690165025,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063010000",
      "localidad_censal_id": "10063010",
      "localidad_censal_nombre": "Collagasta",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "COLLAGASTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3087173740113,
      "centroide_lon": -65.7222098439933,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063020000",
      "localidad_censal_id": "10063020",
      "localidad_censal_nombre": "Pomancillo Este",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "POMANCILLO ESTE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3178432457188,
      "centroide_lon": -65.7423447192215,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063030000",
      "localidad_censal_id": "10063030",
      "localidad_censal_nombre": "Pomancillo Oeste",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "POMANCILLO OESTE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4127884506849,
      "centroide_lon": -65.7175369854892,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040001",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "EL HUECO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.3495576813874,
      "centroide_lon": -65.7091195877043,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040002",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "LA CARRERA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4198487342191,
      "centroide_lon": -65.6988377960377,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040003",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "LA FALDA DE SAN ANTONIO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.3734473479931,
      "centroide_lon": -65.7081292515981,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040004",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "LA TERCENA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4237766788262,
      "centroide_lon": -65.7062134320732,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040005",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "SAN ANTONIO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.3887321458271,
      "centroide_lon": -65.7062701247999,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063040006",
      "localidad_censal_id": "10063040",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "SAN JOSE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2754665210832,
      "centroide_lon": -65.733431477509,
      "departamento_id": "10063",
      "departamento_nombre": "Fray Mamerto Esquiú",
      "id": "10063050000",
      "localidad_censal_id": "10063050",
      "localidad_censal_nombre": "Villa Las Pirquitas",
      "municipio_id": "100161",
      "municipio_nombre": "Fray Mamerto Esquiú",
      "nombre": "VILLA LAS PIRQUITAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.649289010725,
      "centroide_lon": -65.5171151597056,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070010000",
      "localidad_censal_id": "10070010",
      "localidad_censal_nombre": "Casa de Piedra",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "CASA DE PIEDRA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1066140404485,
      "centroide_lon": -65.339695207396,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070020000",
      "localidad_censal_id": "10070020",
      "localidad_censal_nombre": "El Aybal",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "EL AYBAL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1832354089391,
      "centroide_lon": -65.4161459216901,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070030000",
      "localidad_censal_id": "10070030",
      "localidad_censal_nombre": "El Bañado",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "EL BAÑADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.193718415728,
      "centroide_lon": -65.4233403523442,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070040000",
      "localidad_censal_id": "10070040",
      "localidad_censal_nombre": "El Divisadero",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "EL DIVISADERO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9534214435134,
      "centroide_lon": -65.3926406151323,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070050000",
      "localidad_censal_id": "10070050",
      "localidad_censal_nombre": "El Quimilo",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "EL QUIMILO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3790661944991,
      "centroide_lon": -65.2898287859815,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070060000",
      "localidad_censal_id": "10070060",
      "localidad_censal_nombre": "Esquiú",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "ESQUIU",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9308886124085,
      "centroide_lon": -65.2902158081112,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070070000",
      "localidad_censal_id": "10070070",
      "localidad_censal_nombre": "Icaño",
      "municipio_id": "100168",
      "municipio_nombre": "Icaño",
      "nombre": "ICAÑO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2804387843954,
      "centroide_lon": -65.4766321943102,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070080000",
      "localidad_censal_id": "10070080",
      "localidad_censal_nombre": "La Dorada",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "LA DORADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5496731497758,
      "centroide_lon": -65.4504857993817,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070090000",
      "localidad_censal_id": "10070090",
      "localidad_censal_nombre": "La Guardia",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "LA GUARDIA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7644628061471,
      "centroide_lon": -65.1120210167305,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070100000",
      "localidad_censal_id": "10070100",
      "localidad_censal_nombre": "Las Esquinas",
      "municipio_id": "100168",
      "municipio_nombre": "Icaño",
      "nombre": "LAS ESQUINAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6402396947153,
      "centroide_lon": -64.9870716926629,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070110000",
      "localidad_censal_id": "10070110",
      "localidad_censal_nombre": "Las Palmitas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS PALMITAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7883056058069,
      "centroide_lon": -65.1007302335708,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070120000",
      "localidad_censal_id": "10070120",
      "localidad_censal_nombre": "Quirós",
      "municipio_id": "100168",
      "municipio_nombre": "Icaño",
      "nombre": "QUIROS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1582844511715,
      "centroide_lon": -65.374945475853,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070130000",
      "localidad_censal_id": "10070130",
      "localidad_censal_nombre": "Ramblones",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "RAMBLONES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2768611237227,
      "centroide_lon": -65.0565641552447,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070140000",
      "localidad_censal_id": "10070140",
      "localidad_censal_nombre": "Recreo",
      "municipio_id": "100175",
      "municipio_nombre": "Recreo",
      "nombre": "RECREO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9331053161662,
      "centroide_lon": -65.0949655869377,
      "departamento_id": "10070",
      "departamento_nombre": "La Paz",
      "id": "10070150000",
      "localidad_censal_id": "10070150",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "100168",
      "municipio_nombre": "Icaño",
      "nombre": "SAN ANTONIO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2680363798746,
      "centroide_lon": -65.6462108146466,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077010000",
      "localidad_censal_id": "10077010",
      "localidad_censal_nombre": "Amadores",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "AMADORES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9877541032226,
      "centroide_lon": -65.688606939529,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077020000",
      "localidad_censal_id": "10077020",
      "localidad_censal_nombre": "El Rosario",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "EL ROSARIO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3920524907261,
      "centroide_lon": -65.6282649899935,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077030000",
      "localidad_censal_id": "10077030",
      "localidad_censal_nombre": "La Bajada",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "LA BAJADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9352828616507,
      "centroide_lon": -65.699367489363,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077040000",
      "localidad_censal_id": "10077040",
      "localidad_censal_nombre": "La Higuera",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "LA HIGUERA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1537649054231,
      "centroide_lon": -65.6696062414473,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077050000",
      "localidad_censal_id": "10077050",
      "localidad_censal_nombre": "La Merced",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "LA MERCED",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.044445782746,
      "centroide_lon": -65.6079383578395,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077060000",
      "localidad_censal_id": "10077060",
      "localidad_censal_nombre": "La Viña",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "LA VIÑA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.826913357423,
      "centroide_lon": -65.7401079267206,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077070000",
      "localidad_censal_id": "10077070",
      "localidad_censal_nombre": "Las Lajas",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "LAS LAJAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.19124607288,
      "centroide_lon": -65.670675013192,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077080000",
      "localidad_censal_id": "10077080",
      "localidad_censal_nombre": "Monte Potrero",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "MONTE POTRERO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3360934480518,
      "centroide_lon": -65.6274299147885,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077090000",
      "localidad_censal_id": "10077090",
      "localidad_censal_nombre": "Palo Labrado",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "PALO LABRADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0073083693979,
      "centroide_lon": -65.726850392729,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077100000",
      "localidad_censal_id": "10077100",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "SAN ANTONIO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.87835755933,
      "centroide_lon": -65.7190758944755,
      "departamento_id": "10077",
      "departamento_nombre": "Paclín",
      "id": "10077110000",
      "localidad_censal_id": "10077110",
      "localidad_censal_nombre": "Villa de Balcozna",
      "municipio_id": "100182",
      "municipio_nombre": "Paclín",
      "nombre": "VILLA DE BALCOZNA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3006337911705,
      "centroide_lon": -66.1659791753183,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084010000",
      "localidad_censal_id": "10084010",
      "localidad_censal_nombre": "Apoyaco",
      "municipio_id": "100185",
      "municipio_nombre": "Mutquín",
      "nombre": "APOYACO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3432877302321,
      "centroide_lon": -66.1222559102194,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084020000",
      "localidad_censal_id": "10084020",
      "localidad_censal_nombre": "Colana",
      "municipio_id": "100185",
      "municipio_nombre": "Mutquín",
      "nombre": "COLANA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0606503909839,
      "centroide_lon": -66.2065039006705,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084030000",
      "localidad_censal_id": "10084030",
      "localidad_censal_nombre": "Colpes",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "COLPES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3773148491306,
      "centroide_lon": -66.3017092655099,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084040000",
      "localidad_censal_id": "10084040",
      "localidad_censal_nombre": "El Pajonal",
      "municipio_id": "100189",
      "municipio_nombre": "Pomán",
      "nombre": "EL PAJONAL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0731019653889,
      "centroide_lon": -66.1435569766782,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084050000",
      "localidad_censal_id": "10084050",
      "localidad_censal_nombre": "Joyango",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "JOYANGO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3178455729042,
      "centroide_lon": -66.1420642866296,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084060000",
      "localidad_censal_id": "10084060",
      "localidad_censal_nombre": "Mutquin",
      "municipio_id": "100185",
      "municipio_nombre": "Mutquín",
      "nombre": "MUTQUIN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3927801149204,
      "centroide_lon": -66.2220823999264,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084070000",
      "localidad_censal_id": "10084070",
      "localidad_censal_nombre": "Pomán",
      "municipio_id": "100189",
      "municipio_nombre": "Pomán",
      "nombre": "POMAN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2022631098292,
      "centroide_lon": -66.148799132888,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084080000",
      "localidad_censal_id": "10084080",
      "localidad_censal_nombre": "Rincón",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "RINCON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1297413013666,
      "centroide_lon": -66.2019565174868,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084090000",
      "localidad_censal_id": "10084090",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "SAN MIGUEL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.173337080009,
      "centroide_lon": -66.2145148644181,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084100000",
      "localidad_censal_id": "10084100",
      "localidad_censal_nombre": "Saujil",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "SAUJIL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2623558875079,
      "centroide_lon": -66.2209299175292,
      "departamento_id": "10084",
      "departamento_nombre": "Pomán",
      "id": "10084110000",
      "localidad_censal_id": "10084110",
      "localidad_censal_nombre": "Siján",
      "municipio_id": "100196",
      "municipio_nombre": "Saujil",
      "nombre": "SIJAN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8479427341705,
      "centroide_lon": -66.0244068592756,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091010000",
      "localidad_censal_id": "10091010",
      "localidad_censal_nombre": "Andalhualá",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "ANDALHUALA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6859290047178,
      "centroide_lon": -65.970794986953,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091020000",
      "localidad_censal_id": "10091020",
      "localidad_censal_nombre": "Caspichango",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "CASPICHANGO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7588342868363,
      "centroide_lon": -66.0493067195716,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091030001",
      "localidad_censal_id": "10091030",
      "localidad_censal_nombre": "Chañar Punco",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "CHAÑAR PUNCO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7265981975072,
      "centroide_lon": -66.0436979128184,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091030002",
      "localidad_censal_id": "10091030",
      "localidad_censal_nombre": "Chañar Punco",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "LAMPACITO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7588342868363,
      "centroide_lon": -66.0493067195716,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091030003",
      "localidad_censal_id": "10091030",
      "localidad_censal_nombre": "Chañar Punco",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "MEDANITOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3955476915097,
      "centroide_lon": -66.2641930164042,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091040000",
      "localidad_censal_id": "10091040",
      "localidad_censal_nombre": "El Cajón",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "EL CAJON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9077168676094,
      "centroide_lon": -66.020423153794,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091050000",
      "localidad_censal_id": "10091050",
      "localidad_censal_nombre": "El Desmonte",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "EL DESMONTE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6307679478768,
      "centroide_lon": -66.0140418967533,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091060000",
      "localidad_censal_id": "10091060",
      "localidad_censal_nombre": "El Puesto",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "EL PUESTO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8101195558413,
      "centroide_lon": -66.0595221384282,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091070001",
      "localidad_censal_id": "10091070",
      "localidad_censal_nombre": "Famatanca",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "FAMATANCA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8101195558413,
      "centroide_lon": -66.0595221384282,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091070002",
      "localidad_censal_id": "10091070",
      "localidad_censal_nombre": "Famatanca",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "SAN JOSE BANDA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6304336405934,
      "centroide_lon": -66.0644373734469,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091080000",
      "localidad_censal_id": "10091080",
      "localidad_censal_nombre": "Fuerte Quemado",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "FUERTE QUEMADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5189083514476,
      "centroide_lon": -66.3686109740362,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091090000",
      "localidad_censal_id": "10091090",
      "localidad_censal_nombre": "La Hoyada",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "LA HOYADA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.7574990827117,
      "centroide_lon": -66.0331064865258,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091100000",
      "localidad_censal_id": "10091100",
      "localidad_censal_nombre": "La Loma",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "LA LOMA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.6988604981977,
      "centroide_lon": -66.0387334407325,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091110001",
      "localidad_censal_id": "10091110",
      "localidad_censal_nombre": "Las Mojarras",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "EL CERRITO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.6988604981977,
      "centroide_lon": -66.0387334407325,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091110002",
      "localidad_censal_id": "10091110",
      "localidad_censal_nombre": "Las Mojarras",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "LAS MOJARRAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.7361383729613,
      "centroide_lon": -66.0217845814201,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091120000",
      "localidad_censal_id": "10091120",
      "localidad_censal_nombre": "Loro Huasi",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "LORO HUASI",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9300943985158,
      "centroide_lon": -66.1487294264287,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091130000",
      "localidad_censal_id": "10091130",
      "localidad_censal_nombre": "Punta de Balasto",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "PUNTA DE BALASTO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8274550484771,
      "centroide_lon": -66.0506274507575,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091140001",
      "localidad_censal_id": "10091140",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "CASA DE PIEDRA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8274550484771,
      "centroide_lon": -66.0506274507575,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091140002",
      "localidad_censal_id": "10091140",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "LA PUNTILLA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8274550484771,
      "centroide_lon": -66.0506274507575,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091140003",
      "localidad_censal_id": "10091140",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "PALO SECO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7745985033315,
      "centroide_lon": -66.0362576892132,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091140004",
      "localidad_censal_id": "10091140",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "SAN JOSE NORTE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7919331343022,
      "centroide_lon": -66.0378776334838,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091140005",
      "localidad_censal_id": "10091140",
      "localidad_censal_nombre": "San José",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "SAN JOSE VILLA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6891167120231,
      "centroide_lon": -66.018884441311,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091150000",
      "localidad_censal_id": "10091150",
      "localidad_censal_nombre": "Santa María",
      "municipio_id": "100210",
      "municipio_nombre": "Santa María",
      "nombre": "SANTA MARIA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8289862929732,
      "centroide_lon": -66.0179166770413,
      "departamento_id": "10091",
      "departamento_nombre": "Santa María",
      "id": "10091160000",
      "localidad_censal_id": "10091160",
      "localidad_censal_nombre": "Yapes",
      "municipio_id": "100203",
      "municipio_nombre": "San José",
      "nombre": "YAPES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1771812692249,
      "centroide_lon": -65.4915924205872,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098010000",
      "localidad_censal_id": "10098010",
      "localidad_censal_nombre": "Alijilán",
      "municipio_id": "100213",
      "municipio_nombre": "Los Altos",
      "nombre": "ALIJILAN",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1034003361447,
      "centroide_lon": -65.3076846046355,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098020000",
      "localidad_censal_id": "10098020",
      "localidad_censal_nombre": "Bañado de Ovanta",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "BAÑADO DE OVANTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2098696025062,
      "centroide_lon": -65.2230167049514,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098030000",
      "localidad_censal_id": "10098030",
      "localidad_censal_nombre": "Las Cañas",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "LAS CAÑAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -28.1946338127178,
      "centroide_lon": -65.1137806266473,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098040000",
      "localidad_censal_id": "10098040",
      "localidad_censal_nombre": "Lavalle",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "LAVALLE",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0488590493489,
      "centroide_lon": -65.4973640431533,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098050000",
      "localidad_censal_id": "10098050",
      "localidad_censal_nombre": "Los Altos",
      "municipio_id": "100213",
      "municipio_nombre": "Los Altos",
      "nombre": "LOS ALTOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1469656455994,
      "centroide_lon": -65.5073235747446,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098060000",
      "localidad_censal_id": "10098060",
      "localidad_censal_nombre": "Manantiales",
      "municipio_id": "100213",
      "municipio_nombre": "Los Altos",
      "nombre": "MANANTIALES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.9608485883022,
      "centroide_lon": -65.1680076958397,
      "departamento_id": "10098",
      "departamento_nombre": "Santa Rosa",
      "id": "10098070000",
      "localidad_censal_id": "10098070",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "SAN PEDRO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9001528461069,
      "centroide_lon": -67.6142838741591,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105010000",
      "localidad_censal_id": "10105010",
      "localidad_censal_nombre": "Anillaco",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "ANILLACO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.231445564254,
      "centroide_lon": -67.6080549409746,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105020000",
      "localidad_censal_id": "10105020",
      "localidad_censal_nombre": "Antinaco",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "ANTINACO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0687850626962,
      "centroide_lon": -67.50770242056,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105030000",
      "localidad_censal_id": "10105030",
      "localidad_censal_nombre": "Banda de Lucero",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "BANDA DE LUCERO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2432405441597,
      "centroide_lon": -67.144273179127,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105040000",
      "localidad_censal_id": "10105040",
      "localidad_censal_nombre": "Cerro Negro",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "CERRO NEGRO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.1243914662106,
      "centroide_lon": -67.4981013989976,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105050001",
      "localidad_censal_id": "10105050",
      "localidad_censal_nombre": "Copacabana",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "COPACABANA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.1243914662106,
      "centroide_lon": -67.4981013989976,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105050002",
      "localidad_censal_id": "10105050",
      "localidad_censal_nombre": "Copacabana",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "LA PUNTILLA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2979798704965,
      "centroide_lon": -67.1670904449903,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105060000",
      "localidad_censal_id": "10105060",
      "localidad_censal_nombre": "Cordobita",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "CORDOBITA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.294959923009,
      "centroide_lon": -67.702902490024,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105070000",
      "localidad_censal_id": "10105070",
      "localidad_censal_nombre": "Costa de Reyes",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "COSTA DE REYES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2888183254668,
      "centroide_lon": -67.1234522983543,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105080000",
      "localidad_censal_id": "10105080",
      "localidad_censal_nombre": "El Pueblito",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "EL PUEBLITO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9267621470675,
      "centroide_lon": -67.6304002049274,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105090000",
      "localidad_censal_id": "10105090",
      "localidad_censal_nombre": "El Puesto",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "EL PUESTO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3120206339513,
      "centroide_lon": -67.250879321266,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105100000",
      "localidad_censal_id": "10105100",
      "localidad_censal_nombre": "El Salado",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "EL SALADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.69316213413,
      "centroide_lon": -67.608574901701,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105110001",
      "localidad_censal_id": "10105110",
      "localidad_censal_nombre": "Fiambalá",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "FIAMBALA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.6442427673373,
      "centroide_lon": -67.6139458012753,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105110002",
      "localidad_censal_id": "10105110",
      "localidad_censal_nombre": "Fiambalá",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "LA RAMADITA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.6354340349652,
      "centroide_lon": -67.6168389660358,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105110003",
      "localidad_censal_id": "10105110",
      "localidad_censal_nombre": "Fiambalá",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "PAMPA BLANCA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2769854029709,
      "centroide_lon": -67.1074444099184,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105120000",
      "localidad_censal_id": "10105120",
      "localidad_censal_nombre": "Los Balverdis",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "LOS BALVERDIS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5239130706008,
      "centroide_lon": -67.5891477326484,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105130000",
      "localidad_censal_id": "10105130",
      "localidad_censal_nombre": "Medanitos",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "MEDANITOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3395852802531,
      "centroide_lon": -67.7592295432626,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105140000",
      "localidad_censal_id": "10105140",
      "localidad_censal_nombre": "Palo Blanco",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "PALO BLANCO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2106668219369,
      "centroide_lon": -67.7317928557182,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105150000",
      "localidad_censal_id": "10105150",
      "localidad_censal_nombre": "Punta del Agua",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "PUNTA DEL AGUA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5632127581426,
      "centroide_lon": -67.6355130611556,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105160000",
      "localidad_censal_id": "10105160",
      "localidad_censal_nombre": "Saujil",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "SAUJIL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3270122428844,
      "centroide_lon": -67.4747701838904,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105170000",
      "localidad_censal_id": "10105170",
      "localidad_censal_nombre": "Tatón",
      "municipio_id": "100224",
      "municipio_nombre": "Fiambalá",
      "nombre": "TATON",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0637510698675,
      "centroide_lon": -67.5802695760226,
      "departamento_id": "10105",
      "departamento_nombre": "Tinogasta",
      "id": "10105180000",
      "localidad_censal_id": "10105180",
      "localidad_censal_nombre": "Tinogasta",
      "municipio_id": "100231",
      "municipio_nombre": "Tinogasta",
      "nombre": "TINOGASTA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4818680419511,
      "centroide_lon": -65.6351326652418,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112010000",
      "localidad_censal_id": "10112010",
      "localidad_censal_nombre": "El Portezuelo",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "EL PORTEZUELO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5334483576648,
      "centroide_lon": -65.6821279512165,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112020000",
      "localidad_censal_id": "10112020",
      "localidad_censal_nombre": "Huaycama",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "HUAYCAMA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6468889609952,
      "centroide_lon": -65.7889935499028,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112030000",
      "localidad_censal_id": "10112030",
      "localidad_censal_nombre": "Las Tejas",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "LAS TEJAS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4594352492075,
      "centroide_lon": -65.7113717209433,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040001",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "EL BAÑADO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4333815878035,
      "centroide_lon": -65.7202973350365,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040002",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "POLCOS",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4689789857859,
      "centroide_lon": -65.7195047318519,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040003",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "POZO DEL MISTOL",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4583109666735,
      "centroide_lon": -65.726757673457,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040004",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "SAN ISIDRO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.447802287271,
      "centroide_lon": -65.7055328995515,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040005",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "SANTA ROSA",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4746455518307,
      "centroide_lon": -65.7433480027028,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040006",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "SUMALAO",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4434739220039,
      "centroide_lon": -65.7215360640125,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112040007",
      "localidad_censal_id": "10112040",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "VILLA DOLORES",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4928946549812,
      "centroide_lon": -65.6744465587279,
      "departamento_id": "10112",
      "departamento_nombre": "Valle Viejo",
      "id": "10112050000",
      "localidad_censal_id": "10112050",
      "localidad_censal_nombre": "Santa Cruz",
      "municipio_id": "100238",
      "municipio_nombre": "Valle Viejo",
      "nombre": "SANTA CRUZ",
      "provincia_id": "10",
      "provincia_nombre": "Catamarca"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1759165218621,
      "centroide_lon": -64.5765483175138,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007010000",
      "localidad_censal_id": "14007010",
      "localidad_censal_nombre": "Amboy",
      "municipio_id": "142007",
      "municipio_nombre": "Amboy",
      "nombre": "AMBOY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.26205146004,
      "centroide_lon": -64.5937253212731,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007020000",
      "localidad_censal_id": "14007020",
      "localidad_censal_nombre": "Arroyo San Antonio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARROYO SAN ANTONIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3679991604313,
      "centroide_lon": -64.6425223427633,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007030000",
      "localidad_censal_id": "14007030",
      "localidad_censal_nombre": "Cañada del Sauce",
      "municipio_id": "142021",
      "municipio_nombre": "Cañada del Sauce",
      "nombre": "CAÑADA DEL SAUCE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.942027416529,
      "centroide_lon": -64.6165406145348,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007040000",
      "localidad_censal_id": "14007040",
      "localidad_censal_nombre": "Capilla Vieja",
      "municipio_id": "140049",
      "municipio_nombre": "Villa General Belgrano",
      "nombre": "CAPILLA VIEJA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.149759071844,
      "centroide_lon": -64.5015914694579,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007050000",
      "localidad_censal_id": "14007050",
      "localidad_censal_nombre": "El Corcovado - El Torreón",
      "municipio_id": "140063",
      "municipio_nombre": "Villa Rumipal",
      "nombre": "EL CORCOVADO - EL TORREON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1695656120552,
      "centroide_lon": -64.7747710529989,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007055000",
      "localidad_censal_id": "14007055",
      "localidad_censal_nombre": "El Durazno",
      "municipio_id": "140070",
      "municipio_nombre": "Villa Yacanto",
      "nombre": "EL DURAZNO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2065076875762,
      "centroide_lon": -64.4006226825819,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007060000",
      "localidad_censal_id": "14007060",
      "localidad_censal_nombre": "Embalse",
      "municipio_id": "140007",
      "municipio_nombre": "Embalse",
      "nombre": "EMBALSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3034055718452,
      "centroide_lon": -64.4831089740345,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007070000",
      "localidad_censal_id": "14007070",
      "localidad_censal_nombre": "La Cruz",
      "municipio_id": "140014",
      "municipio_nombre": "La Cruz",
      "nombre": "LA CRUZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8969643877578,
      "centroide_lon": -64.7751589433589,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007080000",
      "localidad_censal_id": "14007080",
      "localidad_censal_nombre": "La Cumbrecita",
      "municipio_id": "143015",
      "municipio_nombre": "La Cumbrecita",
      "nombre": "LA CUMBRECITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0954063583092,
      "centroide_lon": -64.3310535546374,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007090000",
      "localidad_censal_id": "14007090",
      "localidad_censal_nombre": "Las Bajadas",
      "municipio_id": "142028",
      "municipio_nombre": "Las Bajadas",
      "nombre": "LAS BAJADAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3893612457104,
      "centroide_lon": -64.5185923418535,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007100000",
      "localidad_censal_id": "14007100",
      "localidad_censal_nombre": "Las Caleras",
      "municipio_id": "142035",
      "municipio_nombre": "Las Caleras",
      "nombre": "LAS CALERAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3211277679392,
      "centroide_lon": -64.2810080327514,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007110000",
      "localidad_censal_id": "14007110",
      "localidad_censal_nombre": "Los Cóndores",
      "municipio_id": "140021",
      "municipio_nombre": "Los Cóndores",
      "nombre": "LOS CONDORES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.8568223164309,
      "centroide_lon": -64.3779521586489,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007120000",
      "localidad_censal_id": "14007120",
      "localidad_censal_nombre": "Los Molinos",
      "municipio_id": "142042",
      "municipio_nombre": "Los Molinos",
      "nombre": "LOS MOLINOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.8582901636165,
      "centroide_lon": -64.3750577906576,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007120001",
      "localidad_censal_id": "14007120",
      "localidad_censal_nombre": "Los Molinos",
      "municipio_id": "142042",
      "municipio_nombre": "Los Molinos",
      "nombre": "LOS MOLINOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.8582901636165,
      "centroide_lon": -64.3750577906576,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007120002",
      "localidad_censal_id": "14007120",
      "localidad_censal_nombre": "Los Molinos",
      "municipio_id": "142042",
      "municipio_nombre": "Los Molinos",
      "nombre": "VILLA SAN MIGUEL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.919856077953,
      "centroide_lon": -64.5755037073336,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007130000",
      "localidad_censal_id": "14007130",
      "localidad_censal_nombre": "Los Reartes",
      "municipio_id": "142049",
      "municipio_nombre": "Los Reartes",
      "nombre": "LOS REARTES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2989441032587,
      "centroide_lon": -64.7254379234594,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007140000",
      "localidad_censal_id": "14007140",
      "localidad_censal_nombre": "Lutti",
      "municipio_id": "142056",
      "municipio_nombre": "Lutti",
      "nombre": "LUTTI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0237443074993,
      "centroide_lon": -64.4641636844138,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007160000",
      "localidad_censal_id": "14007160",
      "localidad_censal_nombre": "Parque Calmayo",
      "municipio_id": "142014",
      "municipio_nombre": "Calmayo",
      "nombre": "PARQUE CALMAYO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5265595269976,
      "centroide_lon": -64.5869530802431,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007170000",
      "localidad_censal_id": "14007170",
      "localidad_censal_nombre": "Río de los Sauces",
      "municipio_id": "140028",
      "municipio_nombre": "Río de los Sauces",
      "nombre": "RIO DE LOS SAUCES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9763155149128,
      "centroide_lon": -64.3733067164283,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007180000",
      "localidad_censal_id": "14007180",
      "localidad_censal_nombre": "San Agustín",
      "municipio_id": "140056",
      "municipio_nombre": "San Agustín",
      "nombre": "SAN AGUSTIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1667856733263,
      "centroide_lon": -64.516397206098,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007190000",
      "localidad_censal_id": "14007190",
      "localidad_censal_nombre": "San Ignacio (Loteo San Javier)",
      "municipio_id": "142063",
      "municipio_nombre": "San Ignacio",
      "nombre": "SAN IGNACIO (LOTEO SAN JAVIER)",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -32.0700578177618,
      "centroide_lon": -64.537633731877,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007210000",
      "localidad_censal_id": "14007210",
      "localidad_censal_nombre": "Santa Rosa de Calamuchita",
      "municipio_id": "140035",
      "municipio_nombre": "Santa Rosa de Calamuchita",
      "nombre": "SANTA ROSA DE CALAMUCHITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0665951444652,
      "centroide_lon": -64.5791018557419,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007210001",
      "localidad_censal_id": "14007210",
      "localidad_censal_nombre": "Santa Rosa de Calamuchita",
      "municipio_id": "140035",
      "municipio_nombre": "Santa Rosa de Calamuchita",
      "nombre": "SANTA MONICA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0731573746398,
      "centroide_lon": -64.5447323761644,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007210002",
      "localidad_censal_id": "14007210",
      "localidad_censal_nombre": "Santa Rosa de Calamuchita",
      "municipio_id": "140035",
      "municipio_nombre": "Santa Rosa de Calamuchita",
      "nombre": "SANTA ROSA DE CALAMUCHITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0994512671733,
      "centroide_lon": -64.5337591760097,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007210003",
      "localidad_censal_id": "14007210",
      "localidad_censal_nombre": "Santa Rosa de Calamuchita",
      "municipio_id": "140035",
      "municipio_nombre": "Santa Rosa de Calamuchita",
      "nombre": "SAN IGNACIO (LOTEO VELEZ CRESPO)",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1650425977053,
      "centroide_lon": -64.3784225873001,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007220000",
      "localidad_censal_id": "14007220",
      "localidad_censal_nombre": "Segunda Usina",
      "municipio_id": "142070",
      "municipio_nombre": "Segunda Usina",
      "nombre": "SEGUNDA USINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8262216703829,
      "centroide_lon": -64.5197802668561,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007230000",
      "localidad_censal_id": "14007230",
      "localidad_censal_nombre": "Solar de los Molinos",
      "municipio_id": "142084",
      "municipio_nombre": "Villa Ciudad Parque los Reartes",
      "nombre": "SOLAR DE LOS MOLINOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9532611007669,
      "centroide_lon": -64.8132541353652,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007240000",
      "localidad_censal_id": "14007240",
      "localidad_censal_nombre": "Villa Alpina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ALPINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1863928332037,
      "centroide_lon": -64.570452981186,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007250000",
      "localidad_censal_id": "14007250",
      "localidad_censal_nombre": "Villa Amancay",
      "municipio_id": "142077",
      "municipio_nombre": "Villa Amancay",
      "nombre": "VILLA AMANCAY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9052382568315,
      "centroide_lon": -64.7428823583405,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007260000",
      "localidad_censal_id": "14007260",
      "localidad_censal_nombre": "Villa Berna",
      "municipio_id": "143015",
      "municipio_nombre": "La Cumbrecita",
      "nombre": "VILLA BERNA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.9118704947274,
      "centroide_lon": -64.5279613493097,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007270000",
      "localidad_censal_id": "14007270",
      "localidad_censal_nombre": "Villa Ciudad Parque Los Reartes (1a. Sección)",
      "municipio_id": "142084",
      "municipio_nombre": "Villa Ciudad Parque los Reartes",
      "nombre": "VILLA CIUDAD PARQUE LOS REARTES (1a.SECC",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9091576117526,
      "centroide_lon": -64.5456887371347,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007270001",
      "localidad_censal_id": "14007270",
      "localidad_censal_nombre": "Villa Ciudad Parque Los Reartes (1a. Sección)",
      "municipio_id": "142084",
      "municipio_nombre": "Villa Ciudad Parque los Reartes",
      "nombre": "VILLA CIUDAD PARQUE LOS REARTES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9091576117526,
      "centroide_lon": -64.5456887371347,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007270002",
      "localidad_censal_id": "14007270",
      "localidad_censal_nombre": "Villa Ciudad Parque Los Reartes (1a. Sección)",
      "municipio_id": "142084",
      "municipio_nombre": "Villa Ciudad Parque los Reartes",
      "nombre": "VILLA CIUDAD PARQUES LOS REARTES (1° SECCION)",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9091576117526,
      "centroide_lon": -64.5456887371347,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007270003",
      "localidad_censal_id": "14007270",
      "localidad_censal_nombre": "Villa Ciudad Parque Los Reartes (1a. Sección)",
      "municipio_id": "142084",
      "municipio_nombre": "Villa Ciudad Parque los Reartes",
      "nombre": "VILLA CIUDAD PARQUE LOS REARTES (3° SECCION)",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.1696837164743,
      "centroide_lon": -64.4567952849232,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007290000",
      "localidad_censal_id": "14007290",
      "localidad_censal_nombre": "Villa del Dique",
      "municipio_id": "140042",
      "municipio_nombre": "Villa del Dique",
      "nombre": "VILLA DEL DIQUE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2542673612565,
      "centroide_lon": -64.5850039184628,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007300000",
      "localidad_censal_id": "14007300",
      "localidad_censal_nombre": "Villa El Tala",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA EL TALA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9808236449718,
      "centroide_lon": -64.5606191505919,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007310000",
      "localidad_censal_id": "14007310",
      "localidad_censal_nombre": "Villa General Belgrano",
      "municipio_id": "140049",
      "municipio_nombre": "Villa General Belgrano",
      "nombre": "VILLA GENERAL BELGRANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2667329359208,
      "centroide_lon": -64.5204928765463,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007320000",
      "localidad_censal_id": "14007320",
      "localidad_censal_nombre": "Villa La Rivera",
      "municipio_id": "142091",
      "municipio_nombre": "Villa Quillinzo",
      "nombre": "VILLA LA RIVERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2384365356542,
      "centroide_lon": -64.5210498971139,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007330000",
      "localidad_censal_id": "14007330",
      "localidad_censal_nombre": "Villa Quillinzo",
      "municipio_id": "142091",
      "municipio_nombre": "Villa Quillinzo",
      "nombre": "VILLA QUILLINZO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.1893813417324,
      "centroide_lon": -64.4792063354191,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007340000",
      "localidad_censal_id": "14007340",
      "localidad_censal_nombre": "Villa Rumipal",
      "municipio_id": "140063",
      "municipio_nombre": "Villa Rumipal",
      "nombre": "VILLA RUMIPAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1037825310006,
      "centroide_lon": -64.7541028295247,
      "departamento_id": "14007",
      "departamento_nombre": "Calamuchita",
      "id": "14007360000",
      "localidad_censal_id": "14007360",
      "localidad_censal_nombre": "Villa Yacanto",
      "municipio_id": "140070",
      "municipio_nombre": "Villa Yacanto",
      "nombre": "VILLA YACANTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.4138166206931,
      "centroide_lon": -64.1833384346292,
      "departamento_id": "14014",
      "departamento_nombre": "Capital",
      "id": "14014010000",
      "localidad_censal_id": "14014010",
      "localidad_censal_nombre": "Córdoba",
      "municipio_id": "140077",
      "municipio_nombre": "Córdoba",
      "nombre": "CORDOBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3686406343815,
      "centroide_lon": -64.0603031964104,
      "departamento_id": "14014",
      "departamento_nombre": "Capital",
      "id": "14014010001",
      "localidad_censal_id": "14014010",
      "localidad_censal_nombre": "Córdoba",
      "municipio_id": "140077",
      "municipio_nombre": "Córdoba",
      "nombre": "JARDIN ARENALES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3961494977945,
      "centroide_lon": -64.0609143527134,
      "departamento_id": "14014",
      "departamento_nombre": "Capital",
      "id": "14014010002",
      "localidad_censal_id": "14014010",
      "localidad_censal_nombre": "Córdoba",
      "municipio_id": "140077",
      "municipio_nombre": "Córdoba",
      "nombre": "LA FLORESTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4177432635316,
      "centroide_lon": -64.189601351685,
      "departamento_id": "14014",
      "departamento_nombre": "Capital",
      "id": "14014010003",
      "localidad_censal_id": "14014010",
      "localidad_censal_nombre": "Córdoba",
      "municipio_id": "140077",
      "municipio_nombre": "Córdoba",
      "nombre": "CORDOBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.058228127494,
      "centroide_lon": -64.2955793576311,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021010000",
      "localidad_censal_id": "14021010",
      "localidad_censal_nombre": "Agua de Oro",
      "municipio_id": "140084",
      "municipio_nombre": "Agua de Oro",
      "nombre": "AGUA DE ORO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9594645513035,
      "centroide_lon": -64.2754165610863,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021020000",
      "localidad_censal_id": "14021020",
      "localidad_censal_nombre": "Ascochinga",
      "municipio_id": "140126",
      "municipio_nombre": "La Granja",
      "nombre": "ASCOCHINGA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.1980230004722,
      "centroide_lon": -64.2887924252949,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021025000",
      "localidad_censal_id": "14021025",
      "localidad_censal_nombre": "Barrio Nuevo Río Ceballos",
      "municipio_id": "140147",
      "municipio_nombre": "Río Ceballos",
      "nombre": "BARRIO NUEVO RIO CEBALLOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0864140054075,
      "centroide_lon": -64.3145341004157,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021030000",
      "localidad_censal_id": "14021030",
      "localidad_censal_nombre": "Canteras El Sauce",
      "municipio_id": "142105",
      "municipio_nombre": "El Manzano",
      "nombre": "CANTERAS EL SAUCE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3429017095306,
      "centroide_lon": -64.3992718801553,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021040000",
      "localidad_censal_id": "14021040",
      "localidad_censal_nombre": "Casa Bamba",
      "municipio_id": "140119",
      "municipio_nombre": "La Calera",
      "nombre": "CASA BAMBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0172322466264,
      "centroide_lon": -64.066728610829,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021050000",
      "localidad_censal_id": "14021050",
      "localidad_censal_nombre": "Colonia Caroya",
      "municipio_id": "140091",
      "municipio_nombre": "Colonia Caroya",
      "nombre": "COLONIA CAROYA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2357859283773,
      "centroide_lon": -64.0672541009396,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021060000",
      "localidad_censal_id": "14021060",
      "localidad_censal_nombre": "Colonia Tirolesa",
      "municipio_id": "140098",
      "municipio_nombre": "Colonia Tirolesa",
      "nombre": "COLONIA TIROLESA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0277849308523,
      "centroide_lon": -64.019045653731,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021070000",
      "localidad_censal_id": "14021070",
      "localidad_censal_nombre": "Colonia Vicente Agüero",
      "municipio_id": "142098",
      "municipio_nombre": "Colonia Vicente Agüero",
      "nombre": "COLONIA VICENTE AGUERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4435294401245,
      "centroide_lon": -63.9963793951592,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021075000",
      "localidad_censal_id": "14021075",
      "localidad_censal_nombre": "Villa Corazón de María",
      "municipio_id": "140133",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "VILLA CORAZON DE MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9890353478112,
      "centroide_lon": -64.3778448899044,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021080000",
      "localidad_censal_id": "14021080",
      "localidad_censal_nombre": "Corral Quemado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CORRAL QUEMADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.3015080091569,
      "centroide_lon": -64.2382229714889,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021090000",
      "localidad_censal_id": "14021090",
      "localidad_censal_nombre": "Country San Isidro - Country Chacras de la Villa",
      "municipio_id": "140175",
      "municipio_nombre": "Villa Allende",
      "nombre": "COUNTRY CHACRAS DE LA VILLA - COUNTRY SA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2982310728982,
      "centroide_lon": -64.2371416516445,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021090001",
      "localidad_censal_id": "14021090",
      "localidad_censal_nombre": "Country San Isidro - Country Chacras de la Villa",
      "municipio_id": "140175",
      "municipio_nombre": "Villa Allende",
      "nombre": "COUNTRY CHACRAS DE LA VILLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3055336354525,
      "centroide_lon": -64.2480169288216,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021090002",
      "localidad_censal_id": "14021090",
      "localidad_censal_nombre": "Country San Isidro - Country Chacras de la Villa",
      "municipio_id": "140175",
      "municipio_nombre": "Villa Allende",
      "nombre": "COUNTRY SAN ISIDRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0817617139175,
      "centroide_lon": -64.2998324164847,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021110000",
      "localidad_censal_id": "14021110",
      "localidad_censal_nombre": "El Manzano",
      "municipio_id": "142105",
      "municipio_nombre": "El Manzano",
      "nombre": "EL MANZANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2735550500587,
      "centroide_lon": -64.0150545730464,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021120000",
      "localidad_censal_id": "14021120",
      "localidad_censal_nombre": "Estación Colonia Tirolesa",
      "municipio_id": "140098",
      "municipio_nombre": "Colonia Tirolesa",
      "nombre": "ESTACION COLONIA TIROLESA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1341189126982,
      "centroide_lon": -64.1410056588479,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021130000",
      "localidad_censal_id": "14021130",
      "localidad_censal_nombre": "General Paz",
      "municipio_id": "142112",
      "municipio_nombre": "Estación General Paz",
      "nombre": "GENERAL PAZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -30.9811937384049,
      "centroide_lon": -64.095771286873,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021140000",
      "localidad_censal_id": "14021140",
      "localidad_censal_nombre": "Jesús María",
      "municipio_id": "140112",
      "municipio_nombre": "Jesús María",
      "nombre": "JESUS MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.3441541784042,
      "centroide_lon": -64.3368093736089,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021150000",
      "localidad_censal_id": "14021150",
      "localidad_censal_nombre": "La Calera",
      "municipio_id": "140119",
      "municipio_nombre": "La Calera",
      "nombre": "LA CALERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3321162961153,
      "centroide_lon": -64.3336598560636,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021150001",
      "localidad_censal_id": "14021150",
      "localidad_censal_nombre": "La Calera",
      "municipio_id": "140119",
      "municipio_nombre": "La Calera",
      "nombre": "DUMESNIL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3430963762885,
      "centroide_lon": -64.3302275470709,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021150002",
      "localidad_censal_id": "14021150",
      "localidad_censal_nombre": "La Calera",
      "municipio_id": "140119",
      "municipio_nombre": "La Calera",
      "nombre": "LA CALERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3589143636745,
      "centroide_lon": -64.3650932467319,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021150003",
      "localidad_censal_id": "14021150",
      "localidad_censal_nombre": "La Calera",
      "municipio_id": "140119",
      "municipio_nombre": "La Calera",
      "nombre": "EL DIQUECITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0054374122172,
      "centroide_lon": -64.2616783510445,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021160000",
      "localidad_censal_id": "14021160",
      "localidad_censal_nombre": "La Granja",
      "municipio_id": "140126",
      "municipio_nombre": "La Granja",
      "nombre": "LA GRANJA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3050726647191,
      "centroide_lon": -64.2625844071662,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021165000",
      "localidad_censal_id": "14021165",
      "localidad_censal_nombre": "La Morada",
      "municipio_id": "140175",
      "municipio_nombre": "Villa Allende",
      "nombre": "LA MORADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1419300602666,
      "centroide_lon": -64.0402188172603,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021170000",
      "localidad_censal_id": "14021170",
      "localidad_censal_nombre": "La Puerta",
      "municipio_id": "140098",
      "municipio_nombre": "Colonia Tirolesa",
      "nombre": "LA PUERTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.240896079449,
      "centroide_lon": -64.2618872007094,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021175000",
      "localidad_censal_id": "14021175",
      "localidad_censal_nombre": "Las Corzuelas",
      "municipio_id": "140168",
      "municipio_nombre": "Unquillo",
      "nombre": "LAS CORZUELAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0167070305726,
      "centroide_lon": -64.225388080483,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021180000",
      "localidad_censal_id": "14021180",
      "localidad_censal_nombre": "Los Molles",
      "municipio_id": "140126",
      "municipio_nombre": "La Granja",
      "nombre": "LOS MOLLES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3677025435362,
      "centroide_lon": -64.0503196448495,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021190000",
      "localidad_censal_id": "14021190",
      "localidad_censal_nombre": "Malvinas Argentinas",
      "municipio_id": "140133",
      "municipio_nombre": "Malvinas Argentinas",
      "nombre": "MALVINAS ARGENTINAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2634679794013,
      "centroide_lon": -64.3038336466706,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021200000",
      "localidad_censal_id": "14021200",
      "localidad_censal_nombre": "Mendiolaza",
      "municipio_id": "140140",
      "municipio_nombre": "Mendiolaza",
      "nombre": "MENDIOLAZA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3501106609981,
      "centroide_lon": -63.9996450992295,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021210000",
      "localidad_censal_id": "14021210",
      "localidad_censal_nombre": "Mi Granja",
      "municipio_id": "142119",
      "municipio_nombre": "Mi Granja",
      "nombre": "MI GRANJA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2146356684786,
      "centroide_lon": -64.2768259906649,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021220000",
      "localidad_censal_id": "14021220",
      "localidad_censal_nombre": "Pajas Blancas",
      "municipio_id": "140147",
      "municipio_nombre": "Río Ceballos",
      "nombre": "PAJAS BLANCAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.1748535571499,
      "centroide_lon": -64.3096761754849,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021230000",
      "localidad_censal_id": "14021230",
      "localidad_censal_nombre": "Río Ceballos",
      "municipio_id": "140147",
      "municipio_nombre": "Río Ceballos",
      "nombre": "RIO CEBALLOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3142696814132,
      "centroide_lon": -64.3129043036137,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021240000",
      "localidad_censal_id": "14021240",
      "localidad_censal_nombre": "Saldán",
      "municipio_id": "140154",
      "municipio_nombre": "Saldán",
      "nombre": "SALDAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.1388344121698,
      "centroide_lon": -64.2906610433299,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021250000",
      "localidad_censal_id": "14021250",
      "localidad_censal_nombre": "Salsipuedes",
      "municipio_id": "140161",
      "municipio_nombre": "Salsipuedes",
      "nombre": "SALSIPUEDES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.1065564116392,
      "centroide_lon": -64.2971945109864,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021250001",
      "localidad_censal_id": "14021250",
      "localidad_censal_nombre": "Salsipuedes",
      "municipio_id": "140161",
      "municipio_nombre": "Salsipuedes",
      "nombre": "EL PUEBLITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.1349269310989,
      "centroide_lon": -64.2873331074367,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021250002",
      "localidad_censal_id": "14021250",
      "localidad_censal_nombre": "Salsipuedes",
      "municipio_id": "140161",
      "municipio_nombre": "Salsipuedes",
      "nombre": "SALSIPUEDES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2593058004875,
      "centroide_lon": -64.0753189644633,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021260000",
      "localidad_censal_id": "14021260",
      "localidad_censal_nombre": "Santa Elena",
      "municipio_id": "140098",
      "municipio_nombre": "Colonia Tirolesa",
      "nombre": "SANTA ELENA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1238790204144,
      "centroide_lon": -63.8923777817115,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021270000",
      "localidad_censal_id": "14021270",
      "localidad_censal_nombre": "Tinoco",
      "municipio_id": "142728",
      "municipio_nombre": "Tinoco",
      "nombre": "TINOCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2319725034056,
      "centroide_lon": -64.3177425598673,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021280000",
      "localidad_censal_id": "14021280",
      "localidad_censal_nombre": "Unquillo",
      "municipio_id": "140168",
      "municipio_nombre": "Unquillo",
      "nombre": "UNQUILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2922067561376,
      "centroide_lon": -64.2950077746202,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021290000",
      "localidad_censal_id": "14021290",
      "localidad_censal_nombre": "Villa Allende",
      "municipio_id": "140175",
      "municipio_nombre": "Villa Allende",
      "nombre": "VILLA ALLENDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0702139869894,
      "centroide_lon": -64.3197333591217,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021300000",
      "localidad_censal_id": "14021300",
      "localidad_censal_nombre": "Villa Cerro Azul",
      "municipio_id": "142126",
      "municipio_nombre": "Villa Cerro Azul",
      "nombre": "VILLA CERRO AZUL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.3101307241869,
      "centroide_lon": -64.1805270805742,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310000",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140077",
      "municipio_nombre": "Córdoba",
      "nombre": "VILLA EL FACHINAL - PARQUE NORTE - GUIÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3067152892491,
      "centroide_lon": -64.1703253616993,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310001",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "GUIÑAZU NORTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3000754366056,
      "centroide_lon": -64.1767428650772,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310002",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "PARQUE NORTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.306907347736,
      "centroide_lon": -64.1785314598263,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310004",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "1 DE AGOSTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3075672143524,
      "centroide_lon": -64.1814827461648,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310005",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "ALMIRANTE BROWN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2957196524063,
      "centroide_lon": -64.1768685276575,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310006",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "CIUDAD DE LOS NIÑOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.306907347736,
      "centroide_lon": -64.1785314598263,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021310007",
      "localidad_censal_id": "14021310",
      "localidad_censal_nombre": "Parque Norte - Ciudad de los Niños - Villa Pastora - Almirante Brown - Guiñazú N",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "VILLA PASTORA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.2742571242585,
      "centroide_lon": -64.1641142793009,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021320000",
      "localidad_censal_id": "14021320",
      "localidad_censal_nombre": "Villa Los Llanos - Juárez Celman",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "VILLA LOS LLANOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2570227263756,
      "centroide_lon": -64.1651661967333,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021320001",
      "localidad_censal_id": "14021320",
      "localidad_censal_nombre": "Villa Los Llanos - Juárez Celman",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "JUAREZ CELMAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2757010937435,
      "centroide_lon": -64.1626835394284,
      "departamento_id": "14021",
      "departamento_nombre": "Colón",
      "id": "14021320002",
      "localidad_censal_id": "14021320",
      "localidad_censal_nombre": "Villa Los Llanos - Juárez Celman",
      "municipio_id": "140105",
      "municipio_nombre": "Estación Juárez Celman",
      "nombre": "VILLA LOS LLANOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5409658147192,
      "centroide_lon": -65.0384387721916,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028010000",
      "localidad_censal_id": "14028010",
      "localidad_censal_nombre": "Alto de los Quebrachos",
      "municipio_id": "142133",
      "municipio_nombre": "Alto de los Quebrachos",
      "nombre": "ALTO DE LOS QUEBRACHOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8044357869451,
      "centroide_lon": -65.0436620185917,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028020000",
      "localidad_censal_id": "14028020",
      "localidad_censal_nombre": "Bañado de Soto",
      "municipio_id": "142140",
      "municipio_nombre": "Bañado de Soto",
      "nombre": "BAÑADO DE SOTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8666932853441,
      "centroide_lon": -64.6829010647536,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028030000",
      "localidad_censal_id": "14028030",
      "localidad_censal_nombre": "Canteras Quilpo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CANTERAS QUILPO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0662466251544,
      "centroide_lon": -64.9425321672363,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028040000",
      "localidad_censal_id": "14028040",
      "localidad_censal_nombre": "Cruz de Caña",
      "municipio_id": "142147",
      "municipio_nombre": "Cruz de Caña",
      "nombre": "CRUZ DE CAÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7218592173102,
      "centroide_lon": -64.8077377312137,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028050000",
      "localidad_censal_id": "14028050",
      "localidad_censal_nombre": "Cruz del Eje",
      "municipio_id": "140182",
      "municipio_nombre": "Cruz del Eje",
      "nombre": "CRUZ DEL EJE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6722100581192,
      "centroide_lon": -64.8699070292303,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028060000",
      "localidad_censal_id": "14028060",
      "localidad_censal_nombre": "El Brete",
      "municipio_id": "142154",
      "municipio_nombre": "El Brete",
      "nombre": "EL BRETE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7397207125925,
      "centroide_lon": -64.6484924752741,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028070000",
      "localidad_censal_id": "14028070",
      "localidad_censal_nombre": "El Rincón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL RINCON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4794131800612,
      "centroide_lon": -65.0597627738017,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028080000",
      "localidad_censal_id": "14028080",
      "localidad_censal_nombre": "Guanaco Muerto",
      "municipio_id": "142161",
      "municipio_nombre": "Guanaco Muerto",
      "nombre": "GUANACO MUERTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7628592235779,
      "centroide_lon": -64.6459839334075,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028090000",
      "localidad_censal_id": "14028090",
      "localidad_censal_nombre": "La Banda",
      "municipio_id": "140189",
      "municipio_nombre": "San Marcos Sierras",
      "nombre": "LA BANDA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4417646532675,
      "centroide_lon": -65.4238386532105,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028100000",
      "localidad_censal_id": "14028100",
      "localidad_censal_nombre": "La Batea",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA BATEA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0139548417914,
      "centroide_lon": -65.1020313666951,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028110000",
      "localidad_censal_id": "14028110",
      "localidad_censal_nombre": "La Higuera",
      "municipio_id": "142175",
      "municipio_nombre": "La Higuera",
      "nombre": "LA HIGUERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9332479488458,
      "centroide_lon": -64.7189758691612,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028120000",
      "localidad_censal_id": "14028120",
      "localidad_censal_nombre": "Las Cañadas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS CAÑADAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6895900139602,
      "centroide_lon": -64.8508831281017,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028130000",
      "localidad_censal_id": "14028130",
      "localidad_censal_nombre": "Las Playas",
      "municipio_id": "142189",
      "municipio_nombre": "Las Playas",
      "nombre": "LAS PLAYAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5724823864554,
      "centroide_lon": -64.9410778222467,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028140000",
      "localidad_censal_id": "14028140",
      "localidad_censal_nombre": "Los Chañaritos",
      "municipio_id": "142819",
      "municipio_nombre": "Los Chañaritos",
      "nombre": "LOS CHAÑARITOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6233104657952,
      "centroide_lon": -64.9515406139404,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028150000",
      "localidad_censal_id": "14028150",
      "localidad_censal_nombre": "Media Naranja",
      "municipio_id": "142203",
      "municipio_nombre": "Media Naranja",
      "nombre": "MEDIA NARANJA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7686835768001,
      "centroide_lon": -65.1864700189142,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028160000",
      "localidad_censal_id": "14028160",
      "localidad_censal_nombre": "Paso Viejo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PASO VIEJO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7803420622583,
      "centroide_lon": -64.6468515131076,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028170000",
      "localidad_censal_id": "14028170",
      "localidad_censal_nombre": "San Marcos Sierra",
      "municipio_id": "140189",
      "municipio_nombre": "San Marcos Sierras",
      "nombre": "SAN MARCOS SIERRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6392026300348,
      "centroide_lon": -65.3813009621884,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028180000",
      "localidad_censal_id": "14028180",
      "localidad_censal_nombre": "Serrezuela",
      "municipio_id": "140196",
      "municipio_nombre": "Serrezuela",
      "nombre": "SERREZUELA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7485284753417,
      "centroide_lon": -65.2375410689391,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028190000",
      "localidad_censal_id": "14028190",
      "localidad_censal_nombre": "Tuclame",
      "municipio_id": "142217",
      "municipio_nombre": "Tuclame",
      "nombre": "TUCLAME",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.852977256889,
      "centroide_lon": -64.9924997261449,
      "departamento_id": "14028",
      "departamento_nombre": "Cruz del Eje",
      "id": "14028200000",
      "localidad_censal_id": "14028200",
      "localidad_censal_nombre": "Villa de Soto",
      "municipio_id": "140203",
      "municipio_nombre": "Villa de Soto",
      "nombre": "VILLA DE SOTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3760847098309,
      "centroide_lon": -64.4945399243505,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035010000",
      "localidad_censal_id": "14035010",
      "localidad_censal_nombre": "Del Campillo",
      "municipio_id": "140217",
      "municipio_nombre": "Del Campillo",
      "nombre": "DEL CAMPILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4964110409998,
      "centroide_lon": -64.8094621973323,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035020000",
      "localidad_censal_id": "14035020",
      "localidad_censal_nombre": "Estación Lecueder",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION LECUEDER",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7223142041085,
      "centroide_lon": -63.508013061936,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035030000",
      "localidad_censal_id": "14035030",
      "localidad_censal_nombre": "Hipólito Bouchard",
      "municipio_id": "140210",
      "municipio_nombre": "Buchardo",
      "nombre": "HIPOLITO BOUCHARD",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8397251237332,
      "centroide_lon": -64.3724876795015,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035040000",
      "localidad_censal_id": "14035040",
      "localidad_censal_nombre": "Huinca Renancó",
      "municipio_id": "140224",
      "municipio_nombre": "Huinca Renancó",
      "nombre": "HUINCA RENANCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7911820710534,
      "centroide_lon": -63.781208388865,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035050000",
      "localidad_censal_id": "14035050",
      "localidad_censal_nombre": "Italó",
      "municipio_id": "140231",
      "municipio_nombre": "Italó",
      "nombre": "ITALO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4803867338234,
      "centroide_lon": -64.1695033049234,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035060000",
      "localidad_censal_id": "14035060",
      "localidad_censal_nombre": "Mattaldi",
      "municipio_id": "140245",
      "municipio_nombre": "Mattaldi",
      "nombre": "MATTALDI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4382295005707,
      "centroide_lon": -64.3383707319069,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035070000",
      "localidad_censal_id": "14035070",
      "localidad_censal_nombre": "Nicolás Bruzzone",
      "municipio_id": "142224",
      "municipio_nombre": "Nicolás Bruzzone",
      "nombre": "NICOLAS BRUZZONE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7703021224105,
      "centroide_lon": -63.67032307176,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035080000",
      "localidad_censal_id": "14035080",
      "localidad_censal_nombre": "Onagoity",
      "municipio_id": "142231",
      "municipio_nombre": "Onagoity",
      "nombre": "ONAGOITY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8379291437488,
      "centroide_lon": -63.9155804696192,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035090000",
      "localidad_censal_id": "14035090",
      "localidad_censal_nombre": "Pincén",
      "municipio_id": "142238",
      "municipio_nombre": "Pincen",
      "nombre": "PINCEN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8436937843761,
      "centroide_lon": -64.0987740916452,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035100000",
      "localidad_censal_id": "14035100",
      "localidad_censal_nombre": "Ranqueles",
      "municipio_id": "142245",
      "municipio_nombre": "Ranqueles",
      "nombre": "RANQUELES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.514736598064,
      "centroide_lon": -63.941510935076,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035110000",
      "localidad_censal_id": "14035110",
      "localidad_censal_nombre": "Santa Magdalena",
      "municipio_id": "140238",
      "municipio_nombre": "Jovita",
      "nombre": "SANTA MAGDALENA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8371817717375,
      "centroide_lon": -64.5833571665395,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035120000",
      "localidad_censal_id": "14035120",
      "localidad_censal_nombre": "Villa Huidobro",
      "municipio_id": "140252",
      "municipio_nombre": "Villa Huidobro",
      "nombre": "VILLA HUIDOBRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1216195744231,
      "centroide_lon": -64.7240499364674,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035130000",
      "localidad_censal_id": "14035130",
      "localidad_censal_nombre": "Villa Sarmiento",
      "municipio_id": "141120",
      "municipio_nombre": "Villa Sarmiento (S. A.)",
      "nombre": "VILLA SARMIENTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3408530354994,
      "centroide_lon": -64.917844943457,
      "departamento_id": "14035",
      "departamento_nombre": "General Roca",
      "id": "14035140000",
      "localidad_censal_id": "14035140",
      "localidad_censal_nombre": "Villa Valeria",
      "municipio_id": "140259",
      "municipio_nombre": "Villa Valeria",
      "nombre": "VILLA VALERIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2019665986816,
      "centroide_lon": -63.1627861860009,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042010000",
      "localidad_censal_id": "14042010",
      "localidad_censal_nombre": "Arroyo Algodón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARROYO ALGODON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4889824965456,
      "centroide_lon": -63.4014794487713,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042020000",
      "localidad_censal_id": "14042020",
      "localidad_censal_nombre": "Arroyo Cabral",
      "municipio_id": "140273",
      "municipio_nombre": "Arroyo Cabral",
      "nombre": "ARROYO CABRAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6613825801545,
      "centroide_lon": -63.2447973807791,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042030000",
      "localidad_censal_id": "14042030",
      "localidad_censal_nombre": "Ausonia",
      "municipio_id": "140280",
      "municipio_nombre": "Ausonia",
      "nombre": "AUSONIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0772598274988,
      "centroide_lon": -63.275435286184,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042040000",
      "localidad_censal_id": "14042040",
      "localidad_censal_nombre": "Chazón",
      "municipio_id": "140287",
      "municipio_nombre": "Chazón",
      "nombre": "CHAZON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9405157092885,
      "centroide_lon": -63.2472565147,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042050000",
      "localidad_censal_id": "14042050",
      "localidad_censal_nombre": "Etruria",
      "municipio_id": "140294",
      "municipio_nombre": "Etruria",
      "nombre": "ETRURIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8014149822474,
      "centroide_lon": -63.2440045658431,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042060000",
      "localidad_censal_id": "14042060",
      "localidad_censal_nombre": "La Laguna",
      "municipio_id": "140301",
      "municipio_nombre": "La Laguna",
      "nombre": "LA LAGUNA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6155884790714,
      "centroide_lon": -63.4092352057166,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042070000",
      "localidad_censal_id": "14042070",
      "localidad_censal_nombre": "La Palestina",
      "municipio_id": "140308",
      "municipio_nombre": "La Palestina",
      "nombre": "LA PALESTINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.099694860079,
      "centroide_lon": -63.0325289648087,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042080000",
      "localidad_censal_id": "14042080",
      "localidad_censal_nombre": "La Playosa",
      "municipio_id": "140315",
      "municipio_nombre": "La Playosa",
      "nombre": "LA PLAYOSA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3029687109385,
      "centroide_lon": -63.2328555816575,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042090000",
      "localidad_censal_id": "14042090",
      "localidad_censal_nombre": "Las Mojarras",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS MOJARRAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5401979602741,
      "centroide_lon": -63.4762536378135,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042100000",
      "localidad_censal_id": "14042100",
      "localidad_censal_nombre": "Luca",
      "municipio_id": "140322",
      "municipio_nombre": "Luca",
      "nombre": "LUCA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7470044961114,
      "centroide_lon": -63.3411254262064,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042110000",
      "localidad_censal_id": "14042110",
      "localidad_censal_nombre": "Pasco",
      "municipio_id": "140329",
      "municipio_nombre": "Pasco",
      "nombre": "PASCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.527560043626,
      "centroide_lon": -63.2479616144958,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042120000",
      "localidad_censal_id": "14042120",
      "localidad_censal_nombre": "Sanabria",
      "municipio_id": "140280",
      "municipio_nombre": "Ausonia",
      "nombre": "SANABRIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2506338091324,
      "centroide_lon": -62.931650118919,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042130000",
      "localidad_censal_id": "14042130",
      "localidad_censal_nombre": "Silvio Pellico",
      "municipio_id": "140336",
      "municipio_nombre": "Silvio Pellico",
      "nombre": "SILVIO PELLICO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6919271380136,
      "centroide_lon": -63.4353044375428,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042140000",
      "localidad_censal_id": "14042140",
      "localidad_censal_nombre": "Ticino",
      "municipio_id": "140343",
      "municipio_nombre": "Ticino",
      "nombre": "TICINO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2863739057236,
      "centroide_lon": -63.3540179546817,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042150000",
      "localidad_censal_id": "14042150",
      "localidad_censal_nombre": "Tío Pujio",
      "municipio_id": "140350",
      "municipio_nombre": "Tío Pujio",
      "nombre": "TIO PUJIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4342175966013,
      "centroide_lon": -63.1844586471364,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042160000",
      "localidad_censal_id": "14042160",
      "localidad_censal_nombre": "Villa Albertina",
      "municipio_id": "140357",
      "municipio_nombre": "Villa María",
      "nombre": "VILLA ALBERTINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.4120836110321,
      "centroide_lon": -63.2499951238659,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042170000",
      "localidad_censal_id": "14042170",
      "localidad_censal_nombre": "Villa María",
      "municipio_id": "140357",
      "municipio_nombre": "Villa María",
      "nombre": "VILLA MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.4288251783807,
      "centroide_lon": -63.249359249877,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042180000",
      "localidad_censal_id": "14042180",
      "localidad_censal_nombre": "Villa Nueva",
      "municipio_id": "140364",
      "municipio_nombre": "Villa Nueva",
      "nombre": "VILLA NUEVA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4343319670367,
      "centroide_lon": -63.2875591644345,
      "departamento_id": "14042",
      "departamento_nombre": "General San Martín",
      "id": "14042190000",
      "localidad_censal_id": "14042190",
      "localidad_censal_nombre": "Villa Oeste",
      "municipio_id": "140364",
      "municipio_nombre": "Villa Nueva",
      "nombre": "VILLA OESTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5946762022718,
      "centroide_lon": -64.2078393373412,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049010000",
      "localidad_censal_id": "14049010",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "142259",
      "municipio_nombre": "Avellaneda",
      "nombre": "AVELLANEDA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7759183214925,
      "centroide_lon": -64.2182971976615,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049020000",
      "localidad_censal_id": "14049020",
      "localidad_censal_nombre": "Cañada de Río Pinto",
      "municipio_id": "142266",
      "municipio_nombre": "Cañada de Río Pinto",
      "nombre": "CAÑADA DE RIO PINTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4687274606224,
      "centroide_lon": -64.6712287665168,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049030000",
      "localidad_censal_id": "14049030",
      "localidad_censal_nombre": "Chuña",
      "municipio_id": "142273",
      "municipio_nombre": "Chuña",
      "nombre": "CHUÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6113486219317,
      "centroide_lon": -64.5555688574614,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049040000",
      "localidad_censal_id": "14049040",
      "localidad_censal_nombre": "Copacabana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COPACABANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4229409365773,
      "centroide_lon": -64.3516697664768,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049050000",
      "localidad_censal_id": "14049050",
      "localidad_censal_nombre": "Deán Funes",
      "municipio_id": "140371",
      "municipio_nombre": "Deán Funes",
      "nombre": "DEAN FUNES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6016118281727,
      "centroide_lon": -64.9074033309966,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049060000",
      "localidad_censal_id": "14049060",
      "localidad_censal_nombre": "Esquina del Alambre",
      "municipio_id": "142294",
      "municipio_nombre": "Olivares de San Nicolás",
      "nombre": "ESQUINA DEL ALAMBRE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5154516695053,
      "centroide_lon": -64.2664449882016,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049080000",
      "localidad_censal_id": "14049080",
      "localidad_censal_nombre": "Los Pozos",
      "municipio_id": "142287",
      "municipio_nombre": "Los Pozos",
      "nombre": "LOS POZOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6020503748658,
      "centroide_lon": -64.8433653744314,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049090000",
      "localidad_censal_id": "14049090",
      "localidad_censal_nombre": "Olivares de San Nicolás",
      "municipio_id": "142294",
      "municipio_nombre": "Olivares de San Nicolás",
      "nombre": "OLIVARES DE SAN NICOLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2159552214688,
      "centroide_lon": -64.5029777045333,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049100000",
      "localidad_censal_id": "14049100",
      "localidad_censal_nombre": "Quilino",
      "municipio_id": "140378",
      "municipio_nombre": "Quilino",
      "nombre": "QUILINO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4541583004868,
      "centroide_lon": -64.4475688737827,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049110000",
      "localidad_censal_id": "14049110",
      "localidad_censal_nombre": "San Pedro de Toyos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN PEDRO DE TOYOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6569511707803,
      "centroide_lon": -64.18195908099,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049120000",
      "localidad_censal_id": "14049120",
      "localidad_censal_nombre": "Villa Gutiérrez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA GUTIERREZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2101157475483,
      "centroide_lon": -64.4775674155262,
      "departamento_id": "14049",
      "departamento_nombre": "Ischilín",
      "id": "14049130000",
      "localidad_censal_id": "14049130",
      "localidad_censal_nombre": "Villa Quilino",
      "municipio_id": "140378",
      "municipio_nombre": "Quilino",
      "nombre": "VILLA QUILINO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3564514451723,
      "centroide_lon": -63.7205503922441,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056010000",
      "localidad_censal_id": "14056010",
      "localidad_censal_nombre": "Alejandro Roca",
      "municipio_id": "140385",
      "municipio_nombre": "Alejandro Roca",
      "nombre": "ALEJANDRO ROCA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6332962011646,
      "centroide_lon": -63.2259060913548,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056020000",
      "localidad_censal_id": "14056020",
      "localidad_censal_nombre": "Assunta",
      "municipio_id": "142308",
      "municipio_nombre": "Assunta",
      "nombre": "ASSUNTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0274173144801,
      "centroide_lon": -63.6719277875696,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056030000",
      "localidad_censal_id": "14056030",
      "localidad_censal_nombre": "Bengolea",
      "municipio_id": "140392",
      "municipio_nombre": "Bengolea",
      "nombre": "BENGOLEA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9153142058652,
      "centroide_lon": -64.0256580552867,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056040000",
      "localidad_censal_id": "14056040",
      "localidad_censal_nombre": "Carnerillo",
      "municipio_id": "140399",
      "municipio_nombre": "Carnerillo",
      "nombre": "CARNERILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0241677315378,
      "centroide_lon": -64.0458262411649,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056050000",
      "localidad_censal_id": "14056050",
      "localidad_censal_nombre": "Charras",
      "municipio_id": "140406",
      "municipio_nombre": "Charras",
      "nombre": "CHARRAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6644787319553,
      "centroide_lon": -63.5392368036406,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056060000",
      "localidad_censal_id": "14056060",
      "localidad_censal_nombre": "El Rastreador",
      "municipio_id": "142315",
      "municipio_nombre": "El Rastreador",
      "nombre": "EL RASTREADOR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8118910840567,
      "centroide_lon": -63.8736965709556,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056070000",
      "localidad_censal_id": "14056070",
      "localidad_censal_nombre": "General Cabrera",
      "municipio_id": "140413",
      "municipio_nombre": "General Cabrera",
      "nombre": "GENERAL CABRERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7549291159098,
      "centroide_lon": -63.7850383518255,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056080000",
      "localidad_censal_id": "14056080",
      "localidad_censal_nombre": "General Deheza",
      "municipio_id": "140420",
      "municipio_nombre": "General Deheza",
      "nombre": "GENERAL DEHEZA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6655227252595,
      "centroide_lon": -63.6372618363763,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056090000",
      "localidad_censal_id": "14056090",
      "localidad_censal_nombre": "Huanchillas",
      "municipio_id": "140427",
      "municipio_nombre": "Huanchilla",
      "nombre": "HUANCHILLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4201679372505,
      "centroide_lon": -63.2969802619056,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056100000",
      "localidad_censal_id": "14056100",
      "localidad_censal_nombre": "La Carlota",
      "municipio_id": "140434",
      "municipio_nombre": "La Carlota",
      "nombre": "LA CARLOTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.400060095449,
      "centroide_lon": -63.4716515559202,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056110000",
      "localidad_censal_id": "14056110",
      "localidad_censal_nombre": "Los Cisnes",
      "municipio_id": "140441",
      "municipio_nombre": "Los Cisnes",
      "nombre": "LOS CISNES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0434821211836,
      "centroide_lon": -63.9085957407616,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056120000",
      "localidad_censal_id": "14056120",
      "localidad_censal_nombre": "Olaeta",
      "municipio_id": "140448",
      "municipio_nombre": "Olaeta",
      "nombre": "OLAETA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7600575092431,
      "centroide_lon": -63.4898589767857,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056130000",
      "localidad_censal_id": "14056130",
      "localidad_censal_nombre": "Pacheco de Melo",
      "municipio_id": "142322",
      "municipio_nombre": "Pacheco de Melo",
      "nombre": "PACHECO DE MELO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1704554487519,
      "centroide_lon": -64.0478670082552,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056140000",
      "localidad_censal_id": "14056140",
      "localidad_censal_nombre": "Paso del Durazno",
      "municipio_id": "142329",
      "municipio_nombre": "Paso del Durazno",
      "nombre": "PASO DEL DURAZNO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1767529632621,
      "centroide_lon": -63.2820358699256,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056150000",
      "localidad_censal_id": "14056150",
      "localidad_censal_nombre": "Santa Eufemia",
      "municipio_id": "140462",
      "municipio_nombre": "Santa Eufemia",
      "nombre": "SANTA EUFEMIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0334392795817,
      "centroide_lon": -63.5082571588932,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056160000",
      "localidad_censal_id": "14056160",
      "localidad_censal_nombre": "Ucacha",
      "municipio_id": "140469",
      "municipio_nombre": "Ucacha",
      "nombre": "UCACHA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2014556304775,
      "centroide_lon": -63.8622207209792,
      "departamento_id": "14056",
      "departamento_nombre": "Juárez Celman",
      "id": "14056170000",
      "localidad_censal_id": "14056170",
      "localidad_censal_nombre": "Villa Reducción",
      "municipio_id": "140455",
      "municipio_nombre": "Reducción",
      "nombre": "VILLA REDUCCION",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6076125942499,
      "centroide_lon": -62.6261405718946,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063010000",
      "localidad_censal_id": "14063010",
      "localidad_censal_nombre": "Alejo Ledesma",
      "municipio_id": "140476",
      "municipio_nombre": "Alejo Ledesma",
      "nombre": "ALEJO LEDESMA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6411632781096,
      "centroide_lon": -62.402789011452,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063020000",
      "localidad_censal_id": "14063020",
      "localidad_censal_nombre": "Arias",
      "municipio_id": "140483",
      "municipio_nombre": "Arias",
      "nombre": "ARIAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.123740825534,
      "centroide_lon": -62.0965954602144,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063030000",
      "localidad_censal_id": "14063030",
      "localidad_censal_nombre": "Camilo Aldao",
      "municipio_id": "140490",
      "municipio_nombre": "Camilo Aldao",
      "nombre": "CAMILO ALDAO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.247202805036,
      "centroide_lon": -62.269738358968,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063040000",
      "localidad_censal_id": "14063040",
      "localidad_censal_nombre": "Capitán General Bernardo O'Higgins",
      "municipio_id": "140497",
      "municipio_nombre": "Capitán General B. O'Higgins",
      "nombre": "CAPITAN GENERAL BERNARDO O`HIGGINS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.478334898944,
      "centroide_lon": -62.3393464555145,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063050000",
      "localidad_censal_id": "14063050",
      "localidad_censal_nombre": "Cavanagh",
      "municipio_id": "140504",
      "municipio_nombre": "Cavanagh",
      "nombre": "CAVANAGH",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2585411549016,
      "centroide_lon": -62.6081628269792,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063060000",
      "localidad_censal_id": "14063060",
      "localidad_censal_nombre": "Colonia Barge",
      "municipio_id": "142336",
      "municipio_nombre": "Colonia Barge",
      "nombre": "COLONIA BARGE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3119180942558,
      "centroide_lon": -62.1807987939012,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063070000",
      "localidad_censal_id": "14063070",
      "localidad_censal_nombre": "Colonia Italiana",
      "municipio_id": "140511",
      "municipio_nombre": "Colonia Italiana",
      "nombre": "COLONIA ITALIANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8883679292315,
      "centroide_lon": -61.9347825911775,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063080000",
      "localidad_censal_id": "14063080",
      "localidad_censal_nombre": "Colonia Veinticinco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA VEINTICINCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2816219895104,
      "centroide_lon": -62.1854508942409,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063090000",
      "localidad_censal_id": "14063090",
      "localidad_censal_nombre": "Corral de Bustos",
      "municipio_id": "140518",
      "municipio_nombre": "Corral de Bustos",
      "nombre": "CORRAL DE BUSTOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0069947278727,
      "centroide_lon": -61.8109726038419,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063100000",
      "localidad_censal_id": "14063100",
      "localidad_censal_nombre": "Cruz Alta",
      "municipio_id": "140525",
      "municipio_nombre": "Cruz Alta",
      "nombre": "CRUZ ALTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1220171765357,
      "centroide_lon": -62.3037212558104,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063110000",
      "localidad_censal_id": "14063110",
      "localidad_censal_nombre": "General Baldissera",
      "municipio_id": "140532",
      "municipio_nombre": "General Baldissera",
      "nombre": "GENERAL BALDISSERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7305115296671,
      "centroide_lon": -61.917138712509,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063120000",
      "localidad_censal_id": "14063120",
      "localidad_censal_nombre": "General Roca",
      "municipio_id": "140539",
      "municipio_nombre": "General Roca",
      "nombre": "GENERAL ROCA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4617411292085,
      "centroide_lon": -62.4391434502619,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063130000",
      "localidad_censal_id": "14063130",
      "localidad_censal_nombre": "Guatimozín",
      "municipio_id": "140546",
      "municipio_nombre": "Guatimozín",
      "nombre": "GUATIMOZIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9438405235327,
      "centroide_lon": -62.2305487525417,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063140000",
      "localidad_censal_id": "14063140",
      "localidad_censal_nombre": "Inriville",
      "municipio_id": "140553",
      "municipio_nombre": "Inriville",
      "nombre": "INRIVILLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2401071501959,
      "centroide_lon": -62.404426547367,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063150000",
      "localidad_censal_id": "14063150",
      "localidad_censal_nombre": "Isla Verde",
      "municipio_id": "140560",
      "municipio_nombre": "Isla Verde",
      "nombre": "ISLA VERDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.658391411409,
      "centroide_lon": -62.299914625293,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063160000",
      "localidad_censal_id": "14063160",
      "localidad_censal_nombre": "Leones",
      "municipio_id": "140567",
      "municipio_nombre": "Leones",
      "nombre": "LEONES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9843826304567,
      "centroide_lon": -62.0237039883189,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063170000",
      "localidad_censal_id": "14063170",
      "localidad_censal_nombre": "Los Surgentes",
      "municipio_id": "140574",
      "municipio_nombre": "Los Surgentes",
      "nombre": "LOS SURGENTES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6913099811557,
      "centroide_lon": -62.1057946726991,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063180000",
      "localidad_censal_id": "14063180",
      "localidad_censal_nombre": "Marcos Juárez",
      "municipio_id": "140581",
      "municipio_nombre": "Marcos Juárez",
      "nombre": "MARCOS JUAREZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9175280341379,
      "centroide_lon": -62.4576576002351,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063190000",
      "localidad_censal_id": "14063190",
      "localidad_censal_nombre": "Monte Buey",
      "municipio_id": "140588",
      "municipio_nombre": "Monte Buey",
      "nombre": "MONTE BUEY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.406411636801,
      "centroide_lon": -62.1029831790036,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063210000",
      "localidad_censal_id": "14063210",
      "localidad_censal_nombre": "Saira",
      "municipio_id": "140595",
      "municipio_nombre": "Saira",
      "nombre": "SAIRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9331604883654,
      "centroide_lon": -62.3428774336317,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063220000",
      "localidad_censal_id": "14063220",
      "localidad_censal_nombre": "Saladillo",
      "municipio_id": "142343",
      "municipio_nombre": "Saladillo",
      "nombre": "SALADILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7487775757302,
      "centroide_lon": -62.3306744044274,
      "departamento_id": "14063",
      "departamento_nombre": "Marcos Juárez",
      "id": "14063230000",
      "localidad_censal_id": "14063230",
      "localidad_censal_nombre": "Villa Elisa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ELISA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0381142705456,
      "centroide_lon": -65.2775888503175,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070010000",
      "localidad_censal_id": "14070010",
      "localidad_censal_nombre": "Ciénaga del Coro",
      "municipio_id": "142357",
      "municipio_nombre": "Ciénaga del Coro",
      "nombre": "CIENAGA DEL CORO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8017974058313,
      "centroide_lon": -65.6440521440399,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070020000",
      "localidad_censal_id": "14070020",
      "localidad_censal_nombre": "El Chacho",
      "municipio_id": "142364",
      "municipio_nombre": "El Chacho",
      "nombre": "EL CHACHO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1242101609074,
      "centroide_lon": -65.2269002056273,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070030000",
      "localidad_censal_id": "14070030",
      "localidad_censal_nombre": "Estancia de Guadalupe",
      "municipio_id": "142371",
      "municipio_nombre": "Estancia de Guadalupe",
      "nombre": "ESTANCIA DE GUADALUPE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0975357099584,
      "centroide_lon": -65.3281875063158,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070040000",
      "localidad_censal_id": "14070040",
      "localidad_censal_nombre": "Guasapampa",
      "municipio_id": "142378",
      "municipio_nombre": "Guasapampa",
      "nombre": "GUASAPAMPA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0350257440514,
      "centroide_lon": -65.3453122247049,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070050000",
      "localidad_censal_id": "14070050",
      "localidad_censal_nombre": "La Playa",
      "municipio_id": "142385",
      "municipio_nombre": "La Playa",
      "nombre": "LA PLAYA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.175590415787,
      "centroide_lon": -65.1017054267171,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070060000",
      "localidad_censal_id": "14070060",
      "localidad_censal_nombre": "San Carlos Minas",
      "municipio_id": "140602",
      "municipio_nombre": "San Carlos Minas",
      "nombre": "SAN CARLOS MINAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2496924733149,
      "centroide_lon": -65.1672000423356,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070070000",
      "localidad_censal_id": "14070070",
      "localidad_censal_nombre": "Talaini",
      "municipio_id": "142392",
      "municipio_nombre": "Talaini",
      "nombre": "TALAINI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9499425576932,
      "centroide_lon": -65.3099277411768,
      "departamento_id": "14070",
      "departamento_nombre": "Minas",
      "id": "14070080000",
      "localidad_censal_id": "14070080",
      "localidad_censal_nombre": "Tosno",
      "municipio_id": "142399",
      "municipio_nombre": "Tosno",
      "nombre": "TOSNO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4172820195639,
      "centroide_lon": -65.4453421341012,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077010000",
      "localidad_censal_id": "14077010",
      "localidad_censal_nombre": "Chancani",
      "municipio_id": "142406",
      "municipio_nombre": "Chancaní",
      "nombre": "CHANCANI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3800076236386,
      "centroide_lon": -65.2899757237495,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077020000",
      "localidad_censal_id": "14077020",
      "localidad_censal_nombre": "Las Palmas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS PALMAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3754115021879,
      "centroide_lon": -65.0386011461094,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077030000",
      "localidad_censal_id": "14077030",
      "localidad_censal_nombre": "Los Talares",
      "municipio_id": "142434",
      "municipio_nombre": "Tala Cañada",
      "nombre": "LOS TALARES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3151616749444,
      "centroide_lon": -65.0910260595287,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077040000",
      "localidad_censal_id": "14077040",
      "localidad_censal_nombre": "Salsacate",
      "municipio_id": "140609",
      "municipio_nombre": "Salsacate",
      "nombre": "SALSACATE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3409519996631,
      "centroide_lon": -64.9131949666644,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077050000",
      "localidad_censal_id": "14077050",
      "localidad_censal_nombre": "San Gerónimo",
      "municipio_id": "142427",
      "municipio_nombre": "San Gerónimo",
      "nombre": "SAN GERONIMO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3570289681593,
      "centroide_lon": -64.9764519289305,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077060000",
      "localidad_censal_id": "14077060",
      "localidad_censal_nombre": "Tala Cañada",
      "municipio_id": "142434",
      "municipio_nombre": "Tala Cañada",
      "nombre": "TALA CAÑADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3454000529726,
      "centroide_lon": -65.0801168738369,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077070000",
      "localidad_censal_id": "14077070",
      "localidad_censal_nombre": "Taninga",
      "municipio_id": "140609",
      "municipio_nombre": "Salsacate",
      "nombre": "TANINGA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4880894840309,
      "centroide_lon": -65.2830353856188,
      "departamento_id": "14077",
      "departamento_nombre": "Pocho",
      "id": "14077080000",
      "localidad_censal_id": "14077080",
      "localidad_censal_nombre": "Villa de Pocho",
      "municipio_id": "142441",
      "municipio_nombre": "Villa de Pocho",
      "nombre": "VILLA DE POCHO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0098324798955,
      "centroide_lon": -63.9225367502507,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084010000",
      "localidad_censal_id": "14084010",
      "localidad_censal_nombre": "General Levalle",
      "municipio_id": "140616",
      "municipio_nombre": "General Levalle",
      "nombre": "GENERAL LEVALLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9518611160055,
      "centroide_lon": -62.9744894136507,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084020000",
      "localidad_censal_id": "14084020",
      "localidad_censal_nombre": "La Cesira",
      "municipio_id": "140623",
      "municipio_nombre": "La Cesira",
      "nombre": "LA CESIRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1236122063832,
      "centroide_lon": -63.388646306069,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084030000",
      "localidad_censal_id": "14084030",
      "localidad_censal_nombre": "Laboulaye",
      "municipio_id": "140630",
      "municipio_nombre": "Laboulaye",
      "nombre": "LABOULAYE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2040788458157,
      "centroide_lon": -62.9768889543714,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084040000",
      "localidad_censal_id": "14084040",
      "localidad_censal_nombre": "Leguizamón",
      "municipio_id": "142448",
      "municipio_nombre": "Leguizamón",
      "nombre": "LEGUIZAMON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3483191246018,
      "centroide_lon": -63.4471714447308,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084050000",
      "localidad_censal_id": "14084050",
      "localidad_censal_nombre": "Melo",
      "municipio_id": "140637",
      "municipio_nombre": "Melo",
      "nombre": "MELO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0511211181075,
      "centroide_lon": -63.7321532881296,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084060000",
      "localidad_censal_id": "14084060",
      "localidad_censal_nombre": "Río Bamba",
      "municipio_id": "142455",
      "municipio_nombre": "Río Bamba",
      "nombre": "RIO BAMBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.171790725852,
      "centroide_lon": -63.1534798469172,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084070000",
      "localidad_censal_id": "14084070",
      "localidad_censal_nombre": "Rosales",
      "municipio_id": "140644",
      "municipio_nombre": "Rosales",
      "nombre": "ROSALES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5106395562101,
      "centroide_lon": -63.7132746964365,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084080000",
      "localidad_censal_id": "14084080",
      "localidad_censal_nombre": "San Joaquín",
      "municipio_id": "142462",
      "municipio_nombre": "San Joaquín",
      "nombre": "SAN JOAQUIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.4699818959404,
      "centroide_lon": -63.5375595280644,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084090000",
      "localidad_censal_id": "14084090",
      "localidad_censal_nombre": "Serrano",
      "municipio_id": "140651",
      "municipio_nombre": "Serrano",
      "nombre": "SERRANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.297937327836,
      "centroide_lon": -63.2685397142969,
      "departamento_id": "14084",
      "departamento_nombre": "Presidente Roque Sáenz Peña",
      "id": "14084100000",
      "localidad_censal_id": "14084100",
      "localidad_censal_nombre": "Villa Rossi",
      "municipio_id": "140658",
      "municipio_nombre": "Villa Rossi",
      "nombre": "VILLA ROSSI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8375267087623,
      "centroide_lon": -64.5367004570805,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091010000",
      "localidad_censal_id": "14091010",
      "localidad_censal_nombre": "Barrio Santa Isabel",
      "municipio_id": "140672",
      "municipio_nombre": "Capilla del Monte",
      "nombre": "BARRIO SANTA ISABEL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.3123081567618,
      "centroide_lon": -64.4629696370028,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091020000",
      "localidad_censal_id": "14091020",
      "localidad_censal_nombre": "Bialet Massé",
      "municipio_id": "140665",
      "municipio_nombre": "Bialet Massé",
      "nombre": "BIALET MASSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3214285741587,
      "centroide_lon": -64.4818703245544,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091020001",
      "localidad_censal_id": "14091020",
      "localidad_censal_nombre": "Bialet Massé",
      "municipio_id": "140665",
      "municipio_nombre": "Bialet Massé",
      "nombre": "BIALET MASSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3266874438793,
      "centroide_lon": -64.4544689502096,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091020002",
      "localidad_censal_id": "14091020",
      "localidad_censal_nombre": "Bialet Massé",
      "municipio_id": "140665",
      "municipio_nombre": "Bialet Massé",
      "nombre": "SAN ROQUE DEL LAGO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3931049014855,
      "centroide_lon": -64.5635652642982,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091030000",
      "localidad_censal_id": "14091030",
      "localidad_censal_nombre": "Cabalango",
      "municipio_id": "142469",
      "municipio_nombre": "Cabalango",
      "nombre": "CABALANGO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8563966713395,
      "centroide_lon": -64.526268881497,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091040000",
      "localidad_censal_id": "14091040",
      "localidad_censal_nombre": "Capilla del Monte",
      "municipio_id": "140672",
      "municipio_nombre": "Capilla del Monte",
      "nombre": "CAPILLA DEL MONTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.1697129911083,
      "centroide_lon": -64.4776214321437,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091050000",
      "localidad_censal_id": "14091050",
      "localidad_censal_nombre": "Casa Grande",
      "municipio_id": "142476",
      "municipio_nombre": "Casa Grande",
      "nombre": "CASA GRANDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7720869099195,
      "centroide_lon": -64.5441595530858,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091060000",
      "localidad_censal_id": "14091060",
      "localidad_censal_nombre": "Charbonier",
      "municipio_id": "142483",
      "municipio_nombre": "Charbonier",
      "nombre": "CHARBONIER",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.240571224569,
      "centroide_lon": -64.4655312574209,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091070000",
      "localidad_censal_id": "14091070",
      "localidad_censal_nombre": "Cosquín",
      "municipio_id": "140679",
      "municipio_nombre": "Cosquín",
      "nombre": "COSQUIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4787781890782,
      "centroide_lon": -64.5773466914484,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091080000",
      "localidad_censal_id": "14091080",
      "localidad_censal_nombre": "Cuesta Blanca",
      "municipio_id": "142490",
      "municipio_nombre": "Cuesta Blanca",
      "nombre": "CUESTA BLANCA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3842597063571,
      "centroide_lon": -64.517330819559,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091090000",
      "localidad_censal_id": "14091090",
      "localidad_censal_nombre": "Estancia Vieja",
      "municipio_id": "142497",
      "municipio_nombre": "Estancia Vieja",
      "nombre": "ESTANCIA VIEJA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0600927199176,
      "centroide_lon": -64.484304266589,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091100000",
      "localidad_censal_id": "14091100",
      "localidad_censal_nombre": "Huerta Grande",
      "municipio_id": "140686",
      "municipio_nombre": "Huerta Grande",
      "nombre": "HUERTA GRANDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -30.9792461523826,
      "centroide_lon": -64.4909087135044,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091110000",
      "localidad_censal_id": "14091110",
      "localidad_censal_nombre": "La Cumbre",
      "municipio_id": "140693",
      "municipio_nombre": "La Cumbre",
      "nombre": "LA CUMBRE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0919649906581,
      "centroide_lon": -64.4864417754562,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091120000",
      "localidad_censal_id": "14091120",
      "localidad_censal_nombre": "La Falda",
      "municipio_id": "140700",
      "municipio_nombre": "La Falda",
      "nombre": "LA FALDA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5182256602189,
      "centroide_lon": -64.5345928843013,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091130000",
      "localidad_censal_id": "14091130",
      "localidad_censal_nombre": "Las Jarillas",
      "municipio_id": "142511",
      "municipio_nombre": "San Antonio de Arredondo",
      "nombre": "LAS JARILLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -30.9269774865861,
      "centroide_lon": -64.5026123921004,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091140000",
      "localidad_censal_id": "14091140",
      "localidad_censal_nombre": "Los Cocos",
      "municipio_id": "140707",
      "municipio_nombre": "Los Cocos",
      "nombre": "LOS COCOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2976747453985,
      "centroide_lon": -64.5749813483232,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091150000",
      "localidad_censal_id": "14091150",
      "localidad_censal_nombre": "Mallín",
      "municipio_id": "140721",
      "municipio_nombre": "Santa María de Punilla",
      "nombre": "MALLIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4668646402709,
      "centroide_lon": -64.5427634762868,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091160000",
      "localidad_censal_id": "14091160",
      "localidad_censal_nombre": "Mayu Sumaj",
      "municipio_id": "142504",
      "municipio_nombre": "Mayu Sumaj",
      "nombre": "MAYU SUMAJ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7949898871398,
      "centroide_lon": -64.5210978856737,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091170000",
      "localidad_censal_id": "14091170",
      "localidad_censal_nombre": "Quebrada de Luna",
      "municipio_id": "140679",
      "municipio_nombre": "Cosquín",
      "nombre": "QUEBRADA DE LUNA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4782264688487,
      "centroide_lon": -64.5289350434399,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091180000",
      "localidad_censal_id": "14091180",
      "localidad_censal_nombre": "San Antonio de Arredondo",
      "municipio_id": "142511",
      "municipio_nombre": "San Antonio de Arredondo",
      "nombre": "SAN ANTONIO DE ARREDONDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -30.9199784063317,
      "centroide_lon": -64.5360036651851,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091190000",
      "localidad_censal_id": "14091190",
      "localidad_censal_nombre": "San Esteban",
      "municipio_id": "140714",
      "municipio_nombre": "San Esteban",
      "nombre": "SAN ESTEBAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3496904364626,
      "centroide_lon": -64.4599293921174,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091200000",
      "localidad_censal_id": "14091200",
      "localidad_censal_nombre": "San Roque",
      "municipio_id": "142518",
      "municipio_nombre": "San Roque",
      "nombre": "SAN ROQUE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2703319860361,
      "centroide_lon": -64.4653357678432,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091210000",
      "localidad_censal_id": "14091210",
      "localidad_censal_nombre": "Santa María de Punilla",
      "municipio_id": "140721",
      "municipio_nombre": "Santa María de Punilla",
      "nombre": "SANTA MARIA DE PUNILLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.472446933222,
      "centroide_lon": -64.5651507693433,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091220000",
      "localidad_censal_id": "14091220",
      "localidad_censal_nombre": "Tala Huasi",
      "municipio_id": "142525",
      "municipio_nombre": "Tala Huasi",
      "nombre": "TALA HUASI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3549072437345,
      "centroide_lon": -64.5909558305817,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091230000",
      "localidad_censal_id": "14091230",
      "localidad_censal_nombre": "Tanti",
      "municipio_id": "140728",
      "municipio_nombre": "Tanti",
      "nombre": "TANTI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.1172888298585,
      "centroide_lon": -64.484252831532,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091240000",
      "localidad_censal_id": "14091240",
      "localidad_censal_nombre": "Valle Hermoso",
      "municipio_id": "140735",
      "municipio_nombre": "Valle Hermoso",
      "nombre": "VALLE HERMOSO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4182380208919,
      "centroide_lon": -64.4933448063141,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091250000",
      "localidad_censal_id": "14091250",
      "localidad_censal_nombre": "Villa Carlos Paz",
      "municipio_id": "140742",
      "municipio_nombre": "Villa Carlos Paz",
      "nombre": "VILLA CARLOS PAZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3864690388309,
      "centroide_lon": -64.6003659348122,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091260000",
      "localidad_censal_id": "14091260",
      "localidad_censal_nombre": "Villa Flor Serrana",
      "municipio_id": "140728",
      "municipio_nombre": "Tanti",
      "nombre": "VILLA FLOR SERRANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.0362773938945,
      "centroide_lon": -64.4928899121054,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091270000",
      "localidad_censal_id": "14091270",
      "localidad_censal_nombre": "Villa Giardino",
      "municipio_id": "140749",
      "municipio_nombre": "Villa Giardino",
      "nombre": "VILLA GIARDINO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3740257221359,
      "centroide_lon": -64.4831122938584,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091280000",
      "localidad_censal_id": "14091280",
      "localidad_censal_nombre": "Villa Lago Azul",
      "municipio_id": "142539",
      "municipio_nombre": "Villa Santa Cruz del Lago",
      "nombre": "VILLA LAGO AZUL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.3457170881093,
      "centroide_lon": -64.4802571053108,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091290000",
      "localidad_censal_id": "14091290",
      "localidad_censal_nombre": "Villa Parque Siquimán",
      "municipio_id": "142532",
      "municipio_nombre": "Villa Parque Siquiman",
      "nombre": "VILLA PARQUE SIQUIMAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4791065590312,
      "centroide_lon": -64.562747406585,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091300000",
      "localidad_censal_id": "14091300",
      "localidad_censal_nombre": "Villa Río Icho Cruz",
      "municipio_id": "142546",
      "municipio_nombre": "Villa Río Icho Cruz",
      "nombre": "VILLA RIO ICHO CRUZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.279612799499,
      "centroide_lon": -64.5653436907589,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091310000",
      "localidad_censal_id": "14091310",
      "localidad_censal_nombre": "Villa San José",
      "municipio_id": "140721",
      "municipio_nombre": "Santa María de Punilla",
      "nombre": "VILLA SAN JOSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.369322851246,
      "centroide_lon": -64.5205370832482,
      "departamento_id": "14091",
      "departamento_nombre": "Punilla",
      "id": "14091320000",
      "localidad_censal_id": "14091320",
      "localidad_censal_nombre": "Villa Santa Cruz del Lago",
      "municipio_id": "142539",
      "municipio_nombre": "Villa Santa Cruz del Lago",
      "nombre": "VILLA SANTA CRUZ DEL LAGO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1770175916926,
      "centroide_lon": -64.9944684969757,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098010000",
      "localidad_censal_id": "14098010",
      "localidad_censal_nombre": "Achiras",
      "municipio_id": "140756",
      "municipio_nombre": "Achiras",
      "nombre": "ACHIRAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.631620222358,
      "centroide_lon": -64.0208372956378,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098020000",
      "localidad_censal_id": "14098020",
      "localidad_censal_nombre": "Adelia María",
      "municipio_id": "140763",
      "municipio_nombre": "Adelia María",
      "nombre": "ADELIA MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.757065291399,
      "centroide_lon": -64.3378599293347,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098030000",
      "localidad_censal_id": "14098030",
      "localidad_censal_nombre": "Alcira Gigena",
      "municipio_id": "140770",
      "municipio_nombre": "Alcira",
      "nombre": "ALCIRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6904036665231,
      "centroide_lon": -64.721171054893,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098040000",
      "localidad_censal_id": "14098040",
      "localidad_censal_nombre": "Alpa Corral",
      "municipio_id": "140777",
      "municipio_nombre": "Alpa Corral",
      "nombre": "ALPA CORRAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4528669215341,
      "centroide_lon": -64.3854404675021,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098050000",
      "localidad_censal_id": "14098050",
      "localidad_censal_nombre": "Berrotarán",
      "municipio_id": "140784",
      "municipio_nombre": "Berrotarán",
      "nombre": "BERROTARAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5032818212674,
      "centroide_lon": -64.6755735163666,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098060000",
      "localidad_censal_id": "14098060",
      "localidad_censal_nombre": "Bulnes",
      "municipio_id": "140791",
      "municipio_nombre": "Bulnes",
      "nombre": "BULNES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5563361347485,
      "centroide_lon": -65.0052699166312,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098070000",
      "localidad_censal_id": "14098070",
      "localidad_censal_nombre": "Chaján",
      "municipio_id": "140798",
      "municipio_nombre": "Chaján",
      "nombre": "CHAJAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0089546768665,
      "centroide_lon": -64.1712493406755,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098080000",
      "localidad_censal_id": "14098080",
      "localidad_censal_nombre": "Chucul",
      "municipio_id": "142553",
      "municipio_nombre": "Chucul",
      "nombre": "CHUCUL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8495478948519,
      "centroide_lon": -64.3598591374051,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098090000",
      "localidad_censal_id": "14098090",
      "localidad_censal_nombre": "Coronel Baigorria",
      "municipio_id": "140805",
      "municipio_nombre": "Coronel Baigorria",
      "nombre": "CORONEL BAIGORRIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6230868735031,
      "centroide_lon": -64.5971033510391,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098100000",
      "localidad_censal_id": "14098100",
      "localidad_censal_nombre": "Coronel Moldes",
      "municipio_id": "140812",
      "municipio_nombre": "Coronel Moldes",
      "nombre": "CORONEL MOLDES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5720684109952,
      "centroide_lon": -64.3929518423935,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098110000",
      "localidad_censal_id": "14098110",
      "localidad_censal_nombre": "Elena",
      "municipio_id": "140819",
      "municipio_nombre": "Elena",
      "nombre": "ELENA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1868433713045,
      "centroide_lon": -64.7251129655857,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098120000",
      "localidad_censal_id": "14098120",
      "localidad_censal_nombre": "La Carolina",
      "municipio_id": "142560",
      "municipio_nombre": "La Carolina \"El Potosi\"",
      "nombre": "LA CAROLINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.97859138783,
      "centroide_lon": -64.0842989461185,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098130000",
      "localidad_censal_id": "14098130",
      "localidad_censal_nombre": "La Cautiva",
      "municipio_id": "140826",
      "municipio_nombre": "La Cautiva",
      "nombre": "LA CAUTIVA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2082959166527,
      "centroide_lon": -64.2584448995097,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098140000",
      "localidad_censal_id": "14098140",
      "localidad_censal_nombre": "La Gilda",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA GILDA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2822486969948,
      "centroide_lon": -63.9758763865889,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098150000",
      "localidad_censal_id": "14098150",
      "localidad_censal_nombre": "Las Acequias",
      "municipio_id": "140833",
      "municipio_nombre": "Las Acequias",
      "nombre": "LAS ACEQUIAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8924933762193,
      "centroide_lon": -64.8435915248656,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098160000",
      "localidad_censal_id": "14098160",
      "localidad_censal_nombre": "Las Albahacas",
      "municipio_id": "142567",
      "municipio_nombre": "Las Albahacas",
      "nombre": "LAS ALBAHACAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0892422210207,
      "centroide_lon": -64.2868227686533,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098170000",
      "localidad_censal_id": "14098170",
      "localidad_censal_nombre": "Las Higueras",
      "municipio_id": "140840",
      "municipio_nombre": "Las Higueras",
      "nombre": "LAS HIGUERAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5348511864127,
      "centroide_lon": -64.1052291964295,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098180000",
      "localidad_censal_id": "14098180",
      "localidad_censal_nombre": "Las Peñas",
      "municipio_id": "142574",
      "municipio_nombre": "Las Peñas Sud",
      "nombre": "LAS PEÑAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2839463852241,
      "centroide_lon": -64.5781462257606,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098190000",
      "localidad_censal_id": "14098190",
      "localidad_censal_nombre": "Las Vertientes",
      "municipio_id": "140847",
      "municipio_nombre": "Las Vertientes",
      "nombre": "LAS VERTIENTES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4893410861497,
      "centroide_lon": -64.4320008051115,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098200000",
      "localidad_censal_id": "14098200",
      "localidad_censal_nombre": "Malena",
      "municipio_id": "142581",
      "municipio_nombre": "Malena",
      "nombre": "MALENA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.636887956776,
      "centroide_lon": -63.8900068799972,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098210000",
      "localidad_censal_id": "14098210",
      "localidad_censal_nombre": "Monte de los Gauchos",
      "municipio_id": "140854",
      "municipio_nombre": "Monte de los Gauchos",
      "nombre": "MONTE DE LOS GAUCHOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.170068435327,
      "centroide_lon": -64.049630767807,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098220000",
      "localidad_censal_id": "14098220",
      "localidad_censal_nombre": "Paso del Durazno",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PASO DEL DURAZNO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1242631220064,
      "centroide_lon": -64.3487377033754,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098230000",
      "localidad_censal_id": "14098230",
      "localidad_censal_nombre": "Río Cuarto",
      "municipio_id": "140861",
      "municipio_nombre": "Río Cuarto",
      "nombre": "RIO CUARTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3845382952352,
      "centroide_lon": -64.7219849539761,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098240000",
      "localidad_censal_id": "14098240",
      "localidad_censal_nombre": "Sampacho",
      "municipio_id": "140868",
      "municipio_nombre": "Sampacho",
      "nombre": "SAMPACHO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4978542515606,
      "centroide_lon": -64.3151828240087,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098250000",
      "localidad_censal_id": "14098250",
      "localidad_censal_nombre": "San Basilio",
      "municipio_id": "140875",
      "municipio_nombre": "San Basilio",
      "nombre": "SAN BASILIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2059384521037,
      "centroide_lon": -64.4348164483493,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098260000",
      "localidad_censal_id": "14098260",
      "localidad_censal_nombre": "Santa Catalina Holmberg",
      "municipio_id": "140882",
      "municipio_nombre": "Santa Catalina",
      "nombre": "SANTA CATALINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8188248224493,
      "centroide_lon": -64.4569327410668,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098280000",
      "localidad_censal_id": "14098280",
      "localidad_censal_nombre": "Tosquitas",
      "municipio_id": "140889",
      "municipio_nombre": "Tosquita",
      "nombre": "TOSQUITAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.917235746816,
      "centroide_lon": -64.3902222715013,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098290000",
      "localidad_censal_id": "14098290",
      "localidad_censal_nombre": "Vicuña Mackenna",
      "municipio_id": "140896",
      "municipio_nombre": "Vicuña Mackenna",
      "nombre": "VICUÑA MACKENNA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.893726660666,
      "centroide_lon": -64.8675140784316,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098300000",
      "localidad_censal_id": "14098300",
      "localidad_censal_nombre": "Villa El Chacay",
      "municipio_id": "142595",
      "municipio_nombre": "Villa el Chacay",
      "nombre": "VILLA EL CHACAY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6651066386532,
      "centroide_lon": -64.741501810378,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098310000",
      "localidad_censal_id": "14098310",
      "localidad_censal_nombre": "Villa Santa Eugenia",
      "municipio_id": "140777",
      "municipio_nombre": "Alpa Corral",
      "nombre": "VILLA SANTA EUGENIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8733676407196,
      "centroide_lon": -64.6886182213475,
      "departamento_id": "14098",
      "departamento_nombre": "Río Cuarto",
      "id": "14098320000",
      "localidad_censal_id": "14098320",
      "localidad_censal_nombre": "Washington",
      "municipio_id": "142602",
      "municipio_nombre": "Washington",
      "nombre": "WASHINGTON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9035316228673,
      "centroide_lon": -63.7055651181425,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105010000",
      "localidad_censal_id": "14105010",
      "localidad_censal_nombre": "Atahona",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ATAHONA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4289242714312,
      "centroide_lon": -63.5870264507761,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105020000",
      "localidad_censal_id": "14105020",
      "localidad_censal_nombre": "Cañada de Machado",
      "municipio_id": "142616",
      "municipio_nombre": "Cañada de Machado",
      "nombre": "CAÑADA DE MACHADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4302065756542,
      "centroide_lon": -63.8321496909141,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105030000",
      "localidad_censal_id": "14105030",
      "localidad_censal_nombre": "Capilla de los Remedios",
      "municipio_id": "142623",
      "municipio_nombre": "Capilla de los Remedios",
      "nombre": "CAPILLA DE LOS REMEDIOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7545707267715,
      "centroide_lon": -63.5682265940856,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105040000",
      "localidad_censal_id": "14105040",
      "localidad_censal_nombre": "Chalacea",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHALACEA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1735851610445,
      "centroide_lon": -63.3375921895433,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105050000",
      "localidad_censal_id": "14105050",
      "localidad_censal_nombre": "Colonia Las Cuatro Esquinas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA LAS CUATRO ESQUINAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0286404171341,
      "centroide_lon": -63.3402795519709,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105060000",
      "localidad_censal_id": "14105060",
      "localidad_censal_nombre": "Diego de Rojas",
      "municipio_id": "142651",
      "municipio_nombre": "Diego de Rojas",
      "nombre": "DIEGO DE ROJAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1161396833417,
      "centroide_lon": -63.6017410478522,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105070000",
      "localidad_censal_id": "14105070",
      "localidad_censal_nombre": "El Alcalde",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL ALCALDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.017971601362,
      "centroide_lon": -63.6051650588702,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105080000",
      "localidad_censal_id": "14105080",
      "localidad_censal_nombre": "El Crispín",
      "municipio_id": "142658",
      "municipio_nombre": "El Crispín",
      "nombre": "EL CRISPIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0772911263196,
      "centroide_lon": -63.7935627786301,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105090000",
      "localidad_censal_id": "14105090",
      "localidad_censal_nombre": "Esquina",
      "municipio_id": "142665",
      "municipio_nombre": "Esquina",
      "nombre": "ESQUINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3701809412977,
      "centroide_lon": -63.5326025508152,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105100000",
      "localidad_censal_id": "14105100",
      "localidad_censal_nombre": "Kilómetro 658",
      "municipio_id": "142672",
      "municipio_nombre": "Kilómetro 658",
      "nombre": "KILOMETRO 658",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8925359716793,
      "centroide_lon": -63.0010662681495,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105110000",
      "localidad_censal_id": "14105110",
      "localidad_censal_nombre": "La Para",
      "municipio_id": "140903",
      "municipio_nombre": "La Para",
      "nombre": "LA PARA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5653596004713,
      "centroide_lon": -63.5163053686029,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105120000",
      "localidad_censal_id": "14105120",
      "localidad_censal_nombre": "La Posta",
      "municipio_id": "142679",
      "municipio_nombre": "La Posta",
      "nombre": "LA POSTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8926051154777,
      "centroide_lon": -63.2532712015227,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105130000",
      "localidad_censal_id": "14105130",
      "localidad_censal_nombre": "La Puerta",
      "municipio_id": "140910",
      "municipio_nombre": "La Puerta",
      "nombre": "LA PUERTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0622588456023,
      "centroide_lon": -63.1501281830965,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105140000",
      "localidad_censal_id": "14105140",
      "localidad_censal_nombre": "La Quinta",
      "municipio_id": "142686",
      "municipio_nombre": "La Quinta",
      "nombre": "LA QUINTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0882555128663,
      "centroide_lon": -63.2418444621471,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105150000",
      "localidad_censal_id": "14105150",
      "localidad_censal_nombre": "Las Gramillas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS GRAMILLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7594681012857,
      "centroide_lon": -63.2035027286926,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105160000",
      "localidad_censal_id": "14105160",
      "localidad_censal_nombre": "Las Saladas",
      "municipio_id": "142700",
      "municipio_nombre": "Las Saladas",
      "nombre": "LAS SALADAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9074419738178,
      "centroide_lon": -63.532118614378,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105170000",
      "localidad_censal_id": "14105170",
      "localidad_censal_nombre": "Maquinista Gallini",
      "municipio_id": "142707",
      "municipio_nombre": "Maquinista Gallini",
      "nombre": "MAQUINISTA GALLINI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9826026339688,
      "centroide_lon": -63.6017850424963,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105180000",
      "localidad_censal_id": "14105180",
      "localidad_censal_nombre": "Monte del Rosario",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MONTE DEL ROSARIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3438538707604,
      "centroide_lon": -63.9457735584091,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105190000",
      "localidad_censal_id": "14105190",
      "localidad_censal_nombre": "Montecristo",
      "municipio_id": "140917",
      "municipio_nombre": "Monte Cristo",
      "nombre": "MONTECRISTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7811924488335,
      "centroide_lon": -63.4130494343907,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105200000",
      "localidad_censal_id": "14105200",
      "localidad_censal_nombre": "Obispo Trejo",
      "municipio_id": "140924",
      "municipio_nombre": "Obispo Trejo",
      "nombre": "OBISPO TREJO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2995844445842,
      "centroide_lon": -63.7591050718367,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105210000",
      "localidad_censal_id": "14105210",
      "localidad_censal_nombre": "Piquillín",
      "municipio_id": "140931",
      "municipio_nombre": "Piquillín",
      "nombre": "PIQUILLIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9780514540426,
      "centroide_lon": -63.2596192028417,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105220000",
      "localidad_censal_id": "14105220",
      "localidad_censal_nombre": "Plaza de Mercedes",
      "municipio_id": "142714",
      "municipio_nombre": "Plaza de Mercedes",
      "nombre": "PLAZA DE MERCEDES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1685485898925,
      "centroide_lon": -63.6075820921855,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105230000",
      "localidad_censal_id": "14105230",
      "localidad_censal_nombre": "Pueblo Comechingones",
      "municipio_id": "142644",
      "municipio_nombre": "Comechingones",
      "nombre": "PUEBLO COMECHINGONES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3291838493004,
      "centroide_lon": -63.621866727412,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105240000",
      "localidad_censal_id": "14105240",
      "localidad_censal_nombre": "Río Primero",
      "municipio_id": "140938",
      "municipio_nombre": "Río Primero",
      "nombre": "RIO PRIMERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2897282303946,
      "centroide_lon": -63.445471703469,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105250000",
      "localidad_censal_id": "14105250",
      "localidad_censal_nombre": "Sagrada Familia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAGRADA FAMILIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1514772066717,
      "centroide_lon": -63.4015912555355,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105260000",
      "localidad_censal_id": "14105260",
      "localidad_censal_nombre": "Santa Rosa de Río Primero",
      "municipio_id": "140945",
      "municipio_nombre": "Santa Rosa de Río Primero",
      "nombre": "SANTA ROSA DE RIO PRIMERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8940088267342,
      "centroide_lon": -63.1159181990178,
      "departamento_id": "14105",
      "departamento_nombre": "Río Primero",
      "id": "14105270000",
      "localidad_censal_id": "14105270",
      "localidad_censal_nombre": "Villa Fontana",
      "municipio_id": "140952",
      "municipio_nombre": "Villa Fontana",
      "nombre": "VILLA FONTANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0960599330651,
      "centroide_lon": -63.9287731727735,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112010000",
      "localidad_censal_id": "14112010",
      "localidad_censal_nombre": "Cerro Colorado",
      "municipio_id": "142735",
      "municipio_nombre": "Cerro Colorado",
      "nombre": "CERRO COLORADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0139606979963,
      "centroide_lon": -63.8503752152119,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112020000",
      "localidad_censal_id": "14112020",
      "localidad_censal_nombre": "Chañar Viejo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHAÑAR VIEJO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9247226351086,
      "centroide_lon": -63.5889152129175,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112030000",
      "localidad_censal_id": "14112030",
      "localidad_censal_nombre": "Eufrasio Loza",
      "municipio_id": "142749",
      "municipio_nombre": "Eufrasio Loza",
      "nombre": "EUFRASIO LOZA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7227092489555,
      "centroide_lon": -63.5149951918634,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112040000",
      "localidad_censal_id": "14112040",
      "localidad_censal_nombre": "Gütemberg",
      "municipio_id": "142756",
      "municipio_nombre": "Gütemberg",
      "nombre": "GUTEMBERG",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1846537889428,
      "centroide_lon": -62.8301426342643,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112050000",
      "localidad_censal_id": "14112050",
      "localidad_censal_nombre": "La Rinconada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA RINCONADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.802434071812,
      "centroide_lon": -63.6273622842684,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112060000",
      "localidad_censal_id": "14112060",
      "localidad_censal_nombre": "Los Hoyos",
      "municipio_id": "142770",
      "municipio_nombre": "Los Hoyos",
      "nombre": "LOS HOYOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2390823848323,
      "centroide_lon": -63.4916854103812,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112070000",
      "localidad_censal_id": "14112070",
      "localidad_censal_nombre": "Puesto de Castro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUESTO DE CASTRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0742593288829,
      "centroide_lon": -63.8249818238142,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112080000",
      "localidad_censal_id": "14112080",
      "localidad_censal_nombre": "Rayo Cortado",
      "municipio_id": "142784",
      "municipio_nombre": "Rayo Cortado",
      "nombre": "RAYO CORTADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.698228417088,
      "centroide_lon": -63.5610809474438,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112090000",
      "localidad_censal_id": "14112090",
      "localidad_censal_nombre": "San Pedro de Gütemberg",
      "municipio_id": "142756",
      "municipio_nombre": "Gütemberg",
      "nombre": "SAN PEDRO DE GUTEMBERG",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1174756551011,
      "centroide_lon": -63.8438054710488,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112100000",
      "localidad_censal_id": "14112100",
      "localidad_censal_nombre": "Santa Elena",
      "municipio_id": "142791",
      "municipio_nombre": "Santa Elena",
      "nombre": "SANTA ELENA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1614462636479,
      "centroide_lon": -63.5934503863753,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112110000",
      "localidad_censal_id": "14112110",
      "localidad_censal_nombre": "Sebastián Elcano",
      "municipio_id": "140959",
      "municipio_nombre": "Sebastián Elcano",
      "nombre": "SEBASTIAN ELCANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8162963199276,
      "centroide_lon": -63.3548391354924,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112120000",
      "localidad_censal_id": "14112120",
      "localidad_censal_nombre": "Villa Candelaria",
      "municipio_id": "142798",
      "municipio_nombre": "Villa Candelaria Norte",
      "nombre": "VILLA CANDELARIA NORTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9043583402484,
      "centroide_lon": -63.722318985926,
      "departamento_id": "14112",
      "departamento_nombre": "Río Seco",
      "id": "14112130000",
      "localidad_censal_id": "14112130",
      "localidad_censal_nombre": "Villa de María",
      "municipio_id": "140966",
      "municipio_nombre": "Villa de María",
      "nombre": "VILLA DE MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6679359912419,
      "centroide_lon": -63.2006870937546,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119010000",
      "localidad_censal_id": "14119010",
      "localidad_censal_nombre": "Calchín",
      "municipio_id": "140973",
      "municipio_nombre": "Calchín",
      "nombre": "CALCHIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8633868408967,
      "centroide_lon": -63.278168375262,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119020000",
      "localidad_censal_id": "14119020",
      "localidad_censal_nombre": "Calchín Oeste",
      "municipio_id": "140980",
      "municipio_nombre": "Calchín Oeste",
      "nombre": "CALCHIN OESTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5081678166164,
      "centroide_lon": -63.3421354529106,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119030000",
      "localidad_censal_id": "14119030",
      "localidad_censal_nombre": "Capilla del Carmen",
      "municipio_id": "140987",
      "municipio_nombre": "Capilla del Carmen",
      "nombre": "CAPILLA DEL CARMEN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8716468802323,
      "centroide_lon": -63.1165074420857,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119040000",
      "localidad_censal_id": "14119040",
      "localidad_censal_nombre": "Carrilobo",
      "municipio_id": "140994",
      "municipio_nombre": "Carrilobo",
      "nombre": "CARRILOBO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.964293093898,
      "centroide_lon": -63.3834011052136,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119050000",
      "localidad_censal_id": "14119050",
      "localidad_censal_nombre": "Colazo",
      "municipio_id": "141001",
      "municipio_nombre": "Colazo",
      "nombre": "COLAZO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9175044736895,
      "centroide_lon": -63.5047159461534,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119060000",
      "localidad_censal_id": "14119060",
      "localidad_censal_nombre": "Colonia Videla",
      "municipio_id": "142805",
      "municipio_nombre": "Colonia Videla",
      "nombre": "COLONIA VIDELA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6470984739179,
      "centroide_lon": -63.7598955842163,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119070000",
      "localidad_censal_id": "14119070",
      "localidad_censal_nombre": "Costasacate",
      "municipio_id": "141008",
      "municipio_nombre": "Costasacate",
      "nombre": "COSTASACATE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7965929244084,
      "centroide_lon": -63.6511207667299,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119080000",
      "localidad_censal_id": "14119080",
      "localidad_censal_nombre": "Impira",
      "municipio_id": "142812",
      "municipio_nombre": "Impira",
      "nombre": "IMPIRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7783987221778,
      "centroide_lon": -63.8027369015332,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119090000",
      "localidad_censal_id": "14119090",
      "localidad_censal_nombre": "Laguna Larga",
      "municipio_id": "141015",
      "municipio_nombre": "Laguna Larga",
      "nombre": "LAGUNA LARGA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8313972998615,
      "centroide_lon": -63.4506364545889,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119100000",
      "localidad_censal_id": "14119100",
      "localidad_censal_nombre": "Las Junturas",
      "municipio_id": "141022",
      "municipio_nombre": "Las Junturas",
      "nombre": "LAS JUNTURAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4021377541587,
      "centroide_lon": -63.3321030427196,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119110000",
      "localidad_censal_id": "14119110",
      "localidad_censal_nombre": "Los Chañaritos",
      "municipio_id": "142196",
      "municipio_nombre": "Los Chañaritos (C.D. Eje)",
      "nombre": "LOS CHAÑARITOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6468736294787,
      "centroide_lon": -63.344798299585,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119120000",
      "localidad_censal_id": "14119120",
      "localidad_censal_nombre": "Luque",
      "municipio_id": "141029",
      "municipio_nombre": "Luque",
      "nombre": "LUQUE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8440585633394,
      "centroide_lon": -63.7460746070431,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119130000",
      "localidad_censal_id": "14119130",
      "localidad_censal_nombre": "Manfredi",
      "municipio_id": "141036",
      "municipio_nombre": "Manfredi",
      "nombre": "MANFREDI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7144720415401,
      "centroide_lon": -63.5118521462999,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119140000",
      "localidad_censal_id": "14119140",
      "localidad_censal_nombre": "Matorrales",
      "municipio_id": "141043",
      "municipio_nombre": "Matorrales",
      "nombre": "MATORRALES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.912835805369,
      "centroide_lon": -63.6836820317482,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119150000",
      "localidad_censal_id": "14119150",
      "localidad_censal_nombre": "Oncativo",
      "municipio_id": "141050",
      "municipio_nombre": "Oncativo",
      "nombre": "ONCATIVO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.6804516863719,
      "centroide_lon": -63.8814979795567,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119160000",
      "localidad_censal_id": "14119160",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "141057",
      "municipio_nombre": "Pilar",
      "nombre": "PILAR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0194389864602,
      "centroide_lon": -62.9199182599484,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119170000",
      "localidad_censal_id": "14119170",
      "localidad_censal_nombre": "Pozo del Molle",
      "municipio_id": "141064",
      "municipio_nombre": "Pozo del Molle",
      "nombre": "POZO DEL MOLLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.595385474428,
      "centroide_lon": -63.6155414282074,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119180000",
      "localidad_censal_id": "14119180",
      "localidad_censal_nombre": "Rincón",
      "municipio_id": "142826",
      "municipio_nombre": "Rincón",
      "nombre": "RINCON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.650153972407,
      "centroide_lon": -63.9118630162229,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119190000",
      "localidad_censal_id": "14119190",
      "localidad_censal_nombre": "Río Segundo",
      "municipio_id": "141071",
      "municipio_nombre": "Río Segundo",
      "nombre": "RIO SEGUNDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3871253007234,
      "centroide_lon": -63.4182078006181,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119200000",
      "localidad_censal_id": "14119200",
      "localidad_censal_nombre": "Santiago Temple",
      "municipio_id": "141078",
      "municipio_nombre": "Santiago Temple",
      "nombre": "SANTIAGO TEMPLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5540113344661,
      "centroide_lon": -63.5341489014562,
      "departamento_id": "14119",
      "departamento_nombre": "Río Segundo",
      "id": "14119210000",
      "localidad_censal_id": "14119210",
      "localidad_censal_nombre": "Villa del Rosario",
      "municipio_id": "141085",
      "municipio_nombre": "Villa del Rosario",
      "nombre": "VILLA DEL ROSARIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.489683021508,
      "centroide_lon": -65.0566540377037,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126010000",
      "localidad_censal_id": "14126010",
      "localidad_censal_nombre": "Ambul",
      "municipio_id": "142833",
      "municipio_nombre": "Ambul",
      "nombre": "AMBUL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7500816583302,
      "centroide_lon": -65.004300188015,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126020000",
      "localidad_censal_id": "14126020",
      "localidad_censal_nombre": "Arroyo Los Patos",
      "municipio_id": "142840",
      "municipio_nombre": "Arroyo los Patos",
      "nombre": "ARROYO LOS PATOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.845848320458,
      "centroide_lon": -64.9560921756071,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126030000",
      "localidad_censal_id": "14126030",
      "localidad_censal_nombre": "El Huayco",
      "municipio_id": "142847",
      "municipio_nombre": "Las Calles",
      "nombre": "EL HUAYCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.668668399569,
      "centroide_lon": -65.37239422356,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126040000",
      "localidad_censal_id": "14126040",
      "localidad_censal_nombre": "La Cortadera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA CORTADERA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8206855464004,
      "centroide_lon": -64.9717608825205,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126050000",
      "localidad_censal_id": "14126050",
      "localidad_censal_nombre": "Las Calles",
      "municipio_id": "142847",
      "municipio_nombre": "Las Calles",
      "nombre": "LAS CALLES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6633718662216,
      "centroide_lon": -65.3190950145101,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126060000",
      "localidad_censal_id": "14126060",
      "localidad_censal_nombre": "Las Oscuras",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS OSCURAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8544494669466,
      "centroide_lon": -64.9870970596913,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126070000",
      "localidad_censal_id": "14126070",
      "localidad_censal_nombre": "Las Rabonas",
      "municipio_id": "142854",
      "municipio_nombre": "Las Rabonas",
      "nombre": "LAS RABONAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9322689128265,
      "centroide_lon": -65.2330653421552,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126080000",
      "localidad_censal_id": "14126080",
      "localidad_censal_nombre": "Los Callejones",
      "municipio_id": "141106",
      "municipio_nombre": "San Pedro",
      "nombre": "LOS CALLEJONES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7267843574619,
      "centroide_lon": -65.0055332639059,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126090000",
      "localidad_censal_id": "14126090",
      "localidad_censal_nombre": "Mina Clavero",
      "municipio_id": "141092",
      "municipio_nombre": "Mina Clavero",
      "nombre": "MINA CLAVERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4273241562001,
      "centroide_lon": -65.0756894821312,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126100000",
      "localidad_censal_id": "14126100",
      "localidad_censal_nombre": "Mussi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MUSSI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.795570031823,
      "centroide_lon": -65.0034036260171,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126110000",
      "localidad_censal_id": "14126110",
      "localidad_censal_nombre": "Nono",
      "municipio_id": "141099",
      "municipio_nombre": "Nono",
      "nombre": "NONO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.626892878253,
      "centroide_lon": -65.058665894714,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126120000",
      "localidad_censal_id": "14126120",
      "localidad_censal_nombre": "Panaholma",
      "municipio_id": "142861",
      "municipio_nombre": "Panaholma",
      "nombre": "PANAHOLMA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.832529380563,
      "centroide_lon": -64.9856729137672,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126130000",
      "localidad_censal_id": "14126130",
      "localidad_censal_nombre": "San Huberto",
      "municipio_id": "142847",
      "municipio_nombre": "Las Calles",
      "nombre": "SAN HUBERTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6822276756771,
      "centroide_lon": -65.0255685400222,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126140000",
      "localidad_censal_id": "14126140",
      "localidad_censal_nombre": "San Lorenzo",
      "municipio_id": "142868",
      "municipio_nombre": "San Lorenzo",
      "nombre": "SAN LORENZO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8969863219692,
      "centroide_lon": -65.5246625976026,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126150000",
      "localidad_censal_id": "14126150",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN MARTIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.9368244076607,
      "centroide_lon": -65.2192402223011,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126160000",
      "localidad_censal_id": "14126160",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "141106",
      "municipio_nombre": "San Pedro",
      "nombre": "SAN PEDRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8549846523947,
      "centroide_lon": -65.4304499825783,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126170000",
      "localidad_censal_id": "14126170",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "142875",
      "municipio_nombre": "San Vicente",
      "nombre": "SAN VICENTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9143833881487,
      "centroide_lon": -65.1687928322254,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126180000",
      "localidad_censal_id": "14126180",
      "localidad_censal_nombre": "Sauce Arriba",
      "municipio_id": "142882",
      "municipio_nombre": "Sauce Arriba",
      "nombre": "SAUCE ARRIBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6303406921333,
      "centroide_lon": -65.1483381584252,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126190000",
      "localidad_censal_id": "14126190",
      "localidad_censal_nombre": "Tasna",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TASNA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7063690825109,
      "centroide_lon": -65.0191109777329,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126200000",
      "localidad_censal_id": "14126200",
      "localidad_censal_nombre": "Villa Cura Brochero",
      "municipio_id": "141113",
      "municipio_nombre": "Villa Cura Brochero",
      "nombre": "VILLA CURA BROCHERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.9366572471396,
      "centroide_lon": -65.191796702404,
      "departamento_id": "14126",
      "departamento_nombre": "San Alberto",
      "id": "14126210000",
      "localidad_censal_id": "14126210",
      "localidad_censal_nombre": "Villa Sarmiento",
      "municipio_id": "142252",
      "municipio_nombre": "Villa Sarmiento (G.R.)",
      "nombre": "VILLA SARMIENTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0788609958395,
      "centroide_lon": -65.2312586416728,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133010000",
      "localidad_censal_id": "14133010",
      "localidad_censal_nombre": "Conlara",
      "municipio_id": "142889",
      "municipio_nombre": "Conlara",
      "nombre": "CONLARA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2779029026256,
      "centroide_lon": -65.0273777504727,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133030000",
      "localidad_censal_id": "14133030",
      "localidad_censal_nombre": "Cruz Caña",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "CRUZ CAÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8716867538421,
      "centroide_lon": -64.992749639447,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133040000",
      "localidad_censal_id": "14133040",
      "localidad_censal_nombre": "Dos Arroyos",
      "municipio_id": "142917",
      "municipio_nombre": "Los Hornillos",
      "nombre": "DOS ARROYOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8862653496977,
      "centroide_lon": -64.990651569992,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133050000",
      "localidad_censal_id": "14133050",
      "localidad_censal_nombre": "El Pantanillo",
      "municipio_id": "142917",
      "municipio_nombre": "Los Hornillos",
      "nombre": "EL PANTANILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2172752387548,
      "centroide_lon": -65.0511157108409,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133060000",
      "localidad_censal_id": "14133060",
      "localidad_censal_nombre": "La Paz",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "LA PAZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0660031804969,
      "centroide_lon": -65.0309086009182,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133070000",
      "localidad_censal_id": "14133070",
      "localidad_censal_nombre": "La Población",
      "municipio_id": "142896",
      "municipio_nombre": "La Población",
      "nombre": "LA POBLACION",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2996521283095,
      "centroide_lon": -65.0361512187387,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133080000",
      "localidad_censal_id": "14133080",
      "localidad_censal_nombre": "La Ramada",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "LA RAMADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1066758248056,
      "centroide_lon": -65.063758957899,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133090000",
      "localidad_censal_id": "14133090",
      "localidad_censal_nombre": "La Travesía",
      "municipio_id": "142924",
      "municipio_nombre": "Luyaba",
      "nombre": "LA TRAVESIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2418447787663,
      "centroide_lon": -65.0340964238205,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133095000",
      "localidad_censal_id": "14133095",
      "localidad_censal_nombre": "Las Chacras",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "LAS CHACRAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.9522279195169,
      "centroide_lon": -65.1014219744873,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133100000",
      "localidad_censal_id": "14133100",
      "localidad_censal_nombre": "Las Tapias",
      "municipio_id": "142903",
      "municipio_nombre": "Las Tapias",
      "nombre": "LAS TAPIAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2189295558758,
      "centroide_lon": -65.0285126897579,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133105000",
      "localidad_censal_id": "14133105",
      "localidad_censal_nombre": "Loma Bola",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "LOMA BOLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9737899960039,
      "centroide_lon": -65.4376442516279,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133110000",
      "localidad_censal_id": "14133110",
      "localidad_censal_nombre": "Los Cerrillos",
      "municipio_id": "142910",
      "municipio_nombre": "Los Cerrillos",
      "nombre": "LOS CERRILLOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9017353099654,
      "centroide_lon": -64.9900890034519,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133120000",
      "localidad_censal_id": "14133120",
      "localidad_censal_nombre": "Los Hornillos",
      "municipio_id": "142917",
      "municipio_nombre": "Los Hornillos",
      "nombre": "LOS HORNILLOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9620954005051,
      "centroide_lon": -65.0383138664304,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133130000",
      "localidad_censal_id": "14133130",
      "localidad_censal_nombre": "Los Molles",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "LOS MOLLES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1483422464594,
      "centroide_lon": -65.056618698571,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133150000",
      "localidad_censal_id": "14133150",
      "localidad_censal_nombre": "Luyaba",
      "municipio_id": "142924",
      "municipio_nombre": "Luyaba",
      "nombre": "LUYABA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2570957550243,
      "centroide_lon": -65.0309211927709,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133155000",
      "localidad_censal_id": "14133155",
      "localidad_censal_nombre": "Quebracho Ladeado",
      "municipio_id": "141127",
      "municipio_nombre": "La Paz",
      "nombre": "QUEBRACHO LADEADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9074953420069,
      "centroide_lon": -65.0344580207268,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133160000",
      "localidad_censal_id": "14133160",
      "localidad_censal_nombre": "Quebrada de los Pozos",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "QUEBRADA DE LOS POZOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0266518585481,
      "centroide_lon": -65.027552227227,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133170000",
      "localidad_censal_id": "14133170",
      "localidad_censal_nombre": "San Javier y Yacanto",
      "municipio_id": "141134",
      "municipio_nombre": "San Javier y Yacanto",
      "nombre": "SAN JAVIER Y YACANTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9573259005881,
      "centroide_lon": -65.3152083482463,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133180000",
      "localidad_censal_id": "14133180",
      "localidad_censal_nombre": "San José",
      "municipio_id": "141141",
      "municipio_nombre": "San José",
      "nombre": "SAN JOSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.9484733205417,
      "centroide_lon": -65.0550181621034,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190000",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "VILLA DE LAS ROSAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9620894324618,
      "centroide_lon": -65.0677903537373,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190001",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "ALTO RESBALOSO - EL BARRIAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.951097322144,
      "centroide_lon": -65.0721507920682,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190002",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "EL PUEBLITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.951097322144,
      "centroide_lon": -65.0721507920682,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190003",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "EL VALLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9402016355042,
      "centroide_lon": -65.0211563854361,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190004",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "LAS CHACRAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.9521742409328,
      "centroide_lon": -65.053914111613,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133190005",
      "localidad_censal_id": "14133190",
      "localidad_censal_nombre": "Villa de las Rosas",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "VILLA DE LAS ROSAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.9440434116077,
      "centroide_lon": -65.1961395608591,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133200000",
      "localidad_censal_id": "14133200",
      "localidad_censal_nombre": "Villa Dolores",
      "municipio_id": "141155",
      "municipio_nombre": "Villa Dolores",
      "nombre": "VILLA DOLORES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8727711509831,
      "centroide_lon": -65.0372746344972,
      "departamento_id": "14133",
      "departamento_nombre": "San Javier",
      "id": "14133210000",
      "localidad_censal_id": "14133210",
      "localidad_censal_nombre": "Villa La Viña",
      "municipio_id": "141148",
      "municipio_nombre": "Villa de las Rosas",
      "nombre": "VILLA LA VIÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9387491680744,
      "centroide_lon": -62.4657945680095,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140010000",
      "localidad_censal_id": "14140010",
      "localidad_censal_nombre": "Alicia",
      "municipio_id": "141162",
      "municipio_nombre": "Alicia",
      "nombre": "ALICIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9563507625723,
      "centroide_lon": -62.3378881220879,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140020000",
      "localidad_censal_id": "14140020",
      "localidad_censal_nombre": "Altos de Chipión",
      "municipio_id": "141169",
      "municipio_nombre": "Altos de Chipión",
      "nombre": "ALTOS DE CHIPION",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4193908735633,
      "centroide_lon": -63.0503444103203,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140030000",
      "localidad_censal_id": "14140030",
      "localidad_censal_nombre": "Arroyito",
      "municipio_id": "141176",
      "municipio_nombre": "Arroyito",
      "nombre": "ARROYITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0107075696975,
      "centroide_lon": -62.6671044005114,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140040000",
      "localidad_censal_id": "14140040",
      "localidad_censal_nombre": "Balnearia",
      "municipio_id": "141183",
      "municipio_nombre": "Balnearia",
      "nombre": "BALNEARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8665250177702,
      "centroide_lon": -62.0356522438995,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140050000",
      "localidad_censal_id": "14140050",
      "localidad_censal_nombre": "Brinkmann",
      "municipio_id": "141190",
      "municipio_nombre": "Brinkmann",
      "nombre": "BRINCKMANN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1438629464364,
      "centroide_lon": -62.2135862922774,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140060000",
      "localidad_censal_id": "14140060",
      "localidad_censal_nombre": "Colonia Anita",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA ANITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5273759974763,
      "centroide_lon": -62.114218229406,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140070000",
      "localidad_censal_id": "14140070",
      "localidad_censal_nombre": "Colonia 10 de Julio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA 10 DE JULIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2454066292387,
      "centroide_lon": -62.9256664887207,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140080000",
      "localidad_censal_id": "14140080",
      "localidad_censal_nombre": "Colonia Las Pichanas",
      "municipio_id": "142945",
      "municipio_nombre": "Colonia las Pichanas",
      "nombre": "COLONIA LAS PICHANAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2485066335845,
      "centroide_lon": -62.3639645596989,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140090000",
      "localidad_censal_id": "14140090",
      "localidad_censal_nombre": "Colonia Marina",
      "municipio_id": "141197",
      "municipio_nombre": "Colonia Marina",
      "nombre": "COLONIA MARINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6304966252155,
      "centroide_lon": -62.3663613505205,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140100000",
      "localidad_censal_id": "14140100",
      "localidad_censal_nombre": "Colonia Prosperidad",
      "municipio_id": "141204",
      "municipio_nombre": "Colonia Prosperidad",
      "nombre": "COLONIA PROSPERIDAD",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.528322298444,
      "centroide_lon": -62.7244933856141,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140110000",
      "localidad_censal_id": "14140110",
      "localidad_censal_nombre": "Colonia San Bartolomé",
      "municipio_id": "141211",
      "municipio_nombre": "Colonia San Bartolomé",
      "nombre": "COLONIA SAN BARTOLOME",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7836021597954,
      "centroide_lon": -61.9175643185455,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140120000",
      "localidad_censal_id": "14140120",
      "localidad_censal_nombre": "Colonia San Pedro",
      "municipio_id": "142952",
      "municipio_nombre": "Colonia San Pedro",
      "nombre": "COLONIA SAN PEDRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6057747904501,
      "centroide_lon": -62.4278882105043,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140130000",
      "localidad_censal_id": "14140130",
      "localidad_censal_nombre": "Colonia Santa María",
      "municipio_id": "141204",
      "municipio_nombre": "Colonia Prosperidad",
      "nombre": "COLONIA SANTA MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0529548297979,
      "centroide_lon": -62.235437860737,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140140000",
      "localidad_censal_id": "14140140",
      "localidad_censal_nombre": "Colonia Valtelina",
      "municipio_id": "142959",
      "municipio_nombre": "Colonia Valtelina",
      "nombre": "COLONIA VALTELINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8416242298498,
      "centroide_lon": -61.9558386203663,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140150000",
      "localidad_censal_id": "14140150",
      "localidad_censal_nombre": "Colonia Vignaud",
      "municipio_id": "141218",
      "municipio_nombre": "Colonia Vignaud",
      "nombre": "COLONIA VIGNAUD",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.403980918137,
      "centroide_lon": -62.3061393858824,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140160000",
      "localidad_censal_id": "14140160",
      "localidad_censal_nombre": "Devoto",
      "municipio_id": "141225",
      "municipio_nombre": "Devoto",
      "nombre": "DEVOTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7412330671687,
      "centroide_lon": -62.8933443705701,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140170000",
      "localidad_censal_id": "14140170",
      "localidad_censal_nombre": "El Arañado",
      "municipio_id": "141232",
      "municipio_nombre": "El Arañado",
      "nombre": "EL ARAÑADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9672502352148,
      "centroide_lon": -62.3026285942112,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140180000",
      "localidad_censal_id": "14140180",
      "localidad_censal_nombre": "El Fortín",
      "municipio_id": "141239",
      "municipio_nombre": "El Fortín",
      "nombre": "EL FORTIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4039349300198,
      "centroide_lon": -62.9733657676524,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140190000",
      "localidad_censal_id": "14140190",
      "localidad_censal_nombre": "El Fuertecito",
      "municipio_id": "141176",
      "municipio_nombre": "Arroyito",
      "nombre": "EL FUERTECITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3845486215618,
      "centroide_lon": -62.8284737646901,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140200000",
      "localidad_censal_id": "14140200",
      "localidad_censal_nombre": "El Tío",
      "municipio_id": "141246",
      "municipio_nombre": "El Tío",
      "nombre": "EL TIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3047488843392,
      "centroide_lon": -62.1333023360613,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140210000",
      "localidad_censal_id": "14140210",
      "localidad_censal_nombre": "Estación Luxardo",
      "municipio_id": "142966",
      "municipio_nombre": "Plaza Luxardo",
      "nombre": "ESTACION LUXARDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2071433242951,
      "centroide_lon": -62.1099182033011,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140215000",
      "localidad_censal_id": "14140215",
      "localidad_censal_nombre": "Colonia Iturraspe",
      "municipio_id": "142938",
      "municipio_nombre": "Colonia Iturraspe",
      "nombre": "COLONIA ITURRASPE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1647319320813,
      "centroide_lon": -62.0976333908888,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140220000",
      "localidad_censal_id": "14140220",
      "localidad_censal_nombre": "Freyre",
      "municipio_id": "141253",
      "municipio_nombre": "Freyre",
      "nombre": "FREYRE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.406437919327,
      "centroide_lon": -62.6343581010891,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140230000",
      "localidad_censal_id": "14140230",
      "localidad_censal_nombre": "La Francia",
      "municipio_id": "141260",
      "municipio_nombre": "La Francia",
      "nombre": "LA FRANCIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9070545771133,
      "centroide_lon": -62.2154361817056,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140240000",
      "localidad_censal_id": "14140240",
      "localidad_censal_nombre": "La Paquita",
      "municipio_id": "141267",
      "municipio_nombre": "La Paquita",
      "nombre": "LA PAQUITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2352452196552,
      "centroide_lon": -63.0599042713799,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140250000",
      "localidad_censal_id": "14140250",
      "localidad_censal_nombre": "La Tordilla",
      "municipio_id": "141274",
      "municipio_nombre": "La Tordilla",
      "nombre": "LA TORDILLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.801292158779,
      "centroide_lon": -62.6171047243489,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140260000",
      "localidad_censal_id": "14140260",
      "localidad_censal_nombre": "Las Varas",
      "municipio_id": "141281",
      "municipio_nombre": "Las Varas",
      "nombre": "LAS VARAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8731135581313,
      "centroide_lon": -62.7187948274612,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140270000",
      "localidad_censal_id": "14140270",
      "localidad_censal_nombre": "Las Varillas",
      "municipio_id": "141288",
      "municipio_nombre": "Las Varillas",
      "nombre": "LAS VARILLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9945226321227,
      "centroide_lon": -62.8258401081139,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140280000",
      "localidad_censal_id": "14140280",
      "localidad_censal_nombre": "Marull",
      "municipio_id": "141295",
      "municipio_nombre": "Marull",
      "nombre": "MARULL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9145163516027,
      "centroide_lon": -62.6749183796469,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140290000",
      "localidad_censal_id": "14140290",
      "localidad_censal_nombre": "Miramar",
      "municipio_id": "141302",
      "municipio_nombre": "Miramar",
      "nombre": "MIRAMAR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7107058083835,
      "centroide_lon": -62.0044437379159,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140300000",
      "localidad_censal_id": "14140300",
      "localidad_censal_nombre": "Morteros",
      "municipio_id": "141309",
      "municipio_nombre": "Morteros",
      "nombre": "MORTEROS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.301747140591,
      "centroide_lon": -62.2291672872465,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140310000",
      "localidad_censal_id": "14140310",
      "localidad_censal_nombre": "Plaza Luxardo",
      "municipio_id": "142966",
      "municipio_nombre": "Plaza Luxardo",
      "nombre": "PLAZA LUXARDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3698057980989,
      "centroide_lon": -62.0980386090508,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140320000",
      "localidad_censal_id": "14140320",
      "localidad_censal_nombre": "Plaza San Francisco",
      "municipio_id": "141337",
      "municipio_nombre": "San Francisco",
      "nombre": "PLAZA SAN FRANCISCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0139211475279,
      "centroide_lon": -62.0633288002735,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140330000",
      "localidad_censal_id": "14140330",
      "localidad_censal_nombre": "Porteña",
      "municipio_id": "141316",
      "municipio_nombre": "Porteña",
      "nombre": "PORTEÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5496022220548,
      "centroide_lon": -62.2257312576183,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140340000",
      "localidad_censal_id": "14140340",
      "localidad_censal_nombre": "Quebracho Herrado",
      "municipio_id": "141323",
      "municipio_nombre": "Quebracho Herrado",
      "nombre": "QUEBRACHO HERRADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6633035739729,
      "centroide_lon": -63.0453212093259,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140350000",
      "localidad_censal_id": "14140350",
      "localidad_censal_nombre": "Sacanta",
      "municipio_id": "141330",
      "municipio_nombre": "Sacanta",
      "nombre": "SACANTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4276088869788,
      "centroide_lon": -62.0866336146078,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140360000",
      "localidad_censal_id": "14140360",
      "localidad_censal_nombre": "San Francisco",
      "municipio_id": "141337",
      "municipio_nombre": "San Francisco",
      "nombre": "SAN FRANCISCO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7029319509565,
      "centroide_lon": -62.4840180855351,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140370000",
      "localidad_censal_id": "14140370",
      "localidad_censal_nombre": "Saturnino María Laspiur",
      "municipio_id": "141344",
      "municipio_nombre": "Saturnino María Laspiur",
      "nombre": "SATURNINO MARIA LASPIUR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9239726451413,
      "centroide_lon": -61.9737374276269,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140380000",
      "localidad_censal_id": "14140380",
      "localidad_censal_nombre": "Seeber",
      "municipio_id": "141351",
      "municipio_nombre": "Seeber",
      "nombre": "SEEBER",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1090401829574,
      "centroide_lon": -62.9876506224993,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140390000",
      "localidad_censal_id": "14140390",
      "localidad_censal_nombre": "Toro Pujio",
      "municipio_id": "142973",
      "municipio_nombre": "Toro Pujio",
      "nombre": "TORO PUJIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4251109532295,
      "centroide_lon": -63.1954038579264,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140400000",
      "localidad_censal_id": "14140400",
      "localidad_censal_nombre": "Tránsito",
      "municipio_id": "141358",
      "municipio_nombre": "Tránsito",
      "nombre": "TRANSITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3224689015774,
      "centroide_lon": -62.8144745758859,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140420000",
      "localidad_censal_id": "14140420",
      "localidad_censal_nombre": "Villa Concepción del Tío",
      "municipio_id": "141365",
      "municipio_nombre": "Villa Concepción del Tío",
      "nombre": "VILLA CONCEPCION DEL TIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4474037687006,
      "centroide_lon": -63.1940139744669,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140430000",
      "localidad_censal_id": "14140430",
      "localidad_censal_nombre": "Villa del Tránsito",
      "municipio_id": "141358",
      "municipio_nombre": "Tránsito",
      "nombre": "VILLA DEL TRANSITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6335474004986,
      "centroide_lon": -62.896766816574,
      "departamento_id": "14140",
      "departamento_nombre": "San Justo",
      "id": "14140440000",
      "localidad_censal_id": "14140440",
      "localidad_censal_nombre": "Villa San Esteban",
      "municipio_id": "142980",
      "municipio_nombre": "Villa San Esteban",
      "nombre": "VILLA SAN ESTEBAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.6576798267691,
      "centroide_lon": -64.4288214061936,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147010000",
      "localidad_censal_id": "14147010",
      "localidad_censal_nombre": "Alta Gracia",
      "municipio_id": "141372",
      "municipio_nombre": "Alta Gracia",
      "nombre": "ALTA GRACIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7231378713901,
      "centroide_lon": -64.4144863418681,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147020000",
      "localidad_censal_id": "14147020",
      "localidad_censal_nombre": "Anisacate",
      "municipio_id": "142987",
      "municipio_nombre": "Anisacate",
      "nombre": "ANISACATE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.4426508165936,
      "centroide_lon": -64.3178623084579,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147030000",
      "localidad_censal_id": "14147030",
      "localidad_censal_nombre": "Barrio Gilbert (1º de Mayo) - Tejas Tres",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "BARRIO GILBERT",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4406013293568,
      "centroide_lon": -64.3177468950882,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147030001",
      "localidad_censal_id": "14147030",
      "localidad_censal_nombre": "Barrio Gilbert (1º de Mayo) - Tejas Tres",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "BARRIO GILBERT (1° DE MAYO)",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4661292884087,
      "centroide_lon": -64.3670949660514,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147030002",
      "localidad_censal_id": "14147030",
      "localidad_censal_nombre": "Barrio Gilbert (1º de Mayo) - Tejas Tres",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "TEJAS TRES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.557666956067,
      "centroide_lon": -64.194465844878,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147050000",
      "localidad_censal_id": "14147050",
      "localidad_censal_nombre": "Bouwer",
      "municipio_id": "142994",
      "municipio_nombre": "Bouwer",
      "nombre": "BOUWER",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5854354142154,
      "centroide_lon": -64.3439770074277,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147055000",
      "localidad_censal_id": "14147055",
      "localidad_censal_nombre": "Campos del Virrey",
      "municipio_id": "143106",
      "municipio_nombre": "Villa Parque Santa Ana",
      "nombre": "CAMPOS DEL VIRREY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5292561486201,
      "centroide_lon": -64.1688666057858,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147060000",
      "localidad_censal_id": "14147060",
      "localidad_censal_nombre": "Caseros Centro",
      "municipio_id": "142994",
      "municipio_nombre": "Bouwer",
      "nombre": "CASEROS CENTRO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4637911559576,
      "centroide_lon": -64.4124514041777,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147065000",
      "localidad_censal_id": "14147065",
      "localidad_censal_nombre": "Causana",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "CAUSANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7247289371172,
      "centroide_lon": -64.3939272186065,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147070000",
      "localidad_censal_id": "14147070",
      "localidad_censal_nombre": "Costa Azul",
      "municipio_id": "142987",
      "municipio_nombre": "Anisacate",
      "nombre": "COSTA AZUL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8170515932731,
      "centroide_lon": -64.2889894059973,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147080000",
      "localidad_censal_id": "14147080",
      "localidad_censal_nombre": "Despeñaderos",
      "municipio_id": "141379",
      "municipio_nombre": "Despeñaderos",
      "nombre": "DESPEÑADEROS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.751852051978,
      "centroide_lon": -64.3627643049016,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147090000",
      "localidad_censal_id": "14147090",
      "localidad_censal_nombre": "Dique Chico",
      "municipio_id": "143001",
      "municipio_nombre": "Dique Chico",
      "nombre": "DIQUE CHICO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.6456181955204,
      "centroide_lon": -64.481617153406,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147095000",
      "localidad_censal_id": "14147095",
      "localidad_censal_nombre": "El Potrerillo",
      "municipio_id": "141372",
      "municipio_nombre": "Alta Gracia",
      "nombre": "EL POTRERILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5345237877237,
      "centroide_lon": -64.4559553621678,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147100000",
      "localidad_censal_id": "14147100",
      "localidad_censal_nombre": "Falda del Cañete",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "FALDA DEL CAÑETE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5854632680747,
      "centroide_lon": -64.4548814536225,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147110000",
      "localidad_censal_id": "14147110",
      "localidad_censal_nombre": "Falda del Carmen",
      "municipio_id": "143008",
      "municipio_nombre": "Falda del Carmen",
      "nombre": "FALDA DEL CARMEN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.8034930461757,
      "centroide_lon": -64.418856525597,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147115000",
      "localidad_censal_id": "14147115",
      "localidad_censal_nombre": "José de la Quintana",
      "municipio_id": "143113",
      "municipio_nombre": "Villa San Isidro",
      "nombre": "JOSE DE LA QUINTANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8374172963205,
      "centroide_lon": -64.4336411746815,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147120000",
      "localidad_censal_id": "14147120",
      "localidad_censal_nombre": "La Boca del Río",
      "municipio_id": "143113",
      "municipio_nombre": "Villa San Isidro",
      "nombre": "LA BOCA DEL RIO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5285694623422,
      "centroide_lon": -64.0712556356294,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147130000",
      "localidad_censal_id": "14147130",
      "localidad_censal_nombre": "La Carbonada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA CARBONADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7194206060414,
      "centroide_lon": -64.4779150030479,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147150000",
      "localidad_censal_id": "14147150",
      "localidad_censal_nombre": "La Paisanita",
      "municipio_id": "143022",
      "municipio_nombre": "La Paisanita",
      "nombre": "LA PAISANITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4425579240341,
      "centroide_lon": -64.3484859265831,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147160000",
      "localidad_censal_id": "14147160",
      "localidad_censal_nombre": "La Perla",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "LA PERLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7574675409558,
      "centroide_lon": -64.4486902777249,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147170000",
      "localidad_censal_id": "14147170",
      "localidad_censal_nombre": "La Rancherita y Las Cascadas",
      "municipio_id": "143113",
      "municipio_nombre": "Villa San Isidro",
      "nombre": "LA RANCHERITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7331340814564,
      "centroide_lon": -64.4564154524522,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147180000",
      "localidad_censal_id": "14147180",
      "localidad_censal_nombre": "La Serranita",
      "municipio_id": "143036",
      "municipio_nombre": "La Serranita",
      "nombre": "LA SERRANITA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.5262509877113,
      "centroide_lon": -64.2847584167114,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147190000",
      "localidad_censal_id": "14147190",
      "localidad_censal_nombre": "Los Cedros",
      "municipio_id": "143043",
      "municipio_nombre": "Los Cedros",
      "nombre": "LOS CEDROS - LAS QUINTAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5502925188794,
      "centroide_lon": -64.4334256167366,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147190001",
      "localidad_censal_id": "14147190",
      "localidad_censal_nombre": "Los Cedros",
      "municipio_id": "143008",
      "municipio_nombre": "Falda del Carmen",
      "nombre": "BARRIO LAS QUINTAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5571765800385,
      "centroide_lon": -64.4028321480993,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147190002",
      "localidad_censal_id": "14147190",
      "localidad_censal_nombre": "Los Cedros",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS CEDROS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6484545317081,
      "centroide_lon": -64.0899796115028,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147200000",
      "localidad_censal_id": "14147200",
      "localidad_censal_nombre": "Lozada",
      "municipio_id": "141386",
      "municipio_nombre": "Lozada",
      "nombre": "LOZADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4648051043666,
      "centroide_lon": -64.3584261973277,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147210000",
      "localidad_censal_id": "14147210",
      "localidad_censal_nombre": "Malagueño",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "MALAGUEÑO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4572735024583,
      "centroide_lon": -64.4057106148379,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147217000",
      "localidad_censal_id": "14147217",
      "localidad_censal_nombre": "Milenica",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "MILENICA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9115749813288,
      "centroide_lon": -64.2399918461068,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147220000",
      "localidad_censal_id": "14147220",
      "localidad_censal_nombre": "Monte Ralo",
      "municipio_id": "141400",
      "municipio_nombre": "Monte Ralo",
      "nombre": "MONTE RALO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7824159665775,
      "centroide_lon": -64.5424068778316,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147230000",
      "localidad_censal_id": "14147230",
      "localidad_censal_nombre": "Potrero de Garay",
      "municipio_id": "143050",
      "municipio_nombre": "Potrero de Garay",
      "nombre": "POTRERO DE GARAY",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6465373949134,
      "centroide_lon": -64.2584787728338,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147240000",
      "localidad_censal_id": "14147240",
      "localidad_censal_nombre": "Rafael García",
      "municipio_id": "143057",
      "municipio_nombre": "Rafael García",
      "nombre": "RAFAEL GARCIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7166546515515,
      "centroide_lon": -64.6265869046185,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147250000",
      "localidad_censal_id": "14147250",
      "localidad_censal_nombre": "San Clemente",
      "municipio_id": "143064",
      "municipio_nombre": "San Clemente",
      "nombre": "SAN CLEMENTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.435151387072,
      "centroide_lon": -64.4492154097951,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147260000",
      "localidad_censal_id": "14147260",
      "localidad_censal_nombre": "San Nicolás - Tierra Alta",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "SAN NICOLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4364876977282,
      "centroide_lon": -64.4538955732489,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147260001",
      "localidad_censal_id": "14147260",
      "localidad_censal_nombre": "San Nicolás - Tierra Alta",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "SAN NICOLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4723029527477,
      "centroide_lon": -64.4707945856239,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147260002",
      "localidad_censal_id": "14147260",
      "localidad_censal_nombre": "San Nicolás - Tierra Alta",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "TIERRA ALTA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5300866657118,
      "centroide_lon": -64.1457832241514,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147270000",
      "localidad_censal_id": "14147270",
      "localidad_censal_nombre": "Socavones",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SOCAVONES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5560967583723,
      "centroide_lon": -64.0080543306962,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147280000",
      "localidad_censal_id": "14147280",
      "localidad_censal_nombre": "Toledo",
      "municipio_id": "141407",
      "municipio_nombre": "Toledo",
      "nombre": "TOLEDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.608213303048,
      "centroide_lon": -64.4403684727464,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147290000",
      "localidad_censal_id": "14147290",
      "localidad_censal_nombre": "Valle Alegre",
      "municipio_id": "143008",
      "municipio_nombre": "Falda del Carmen",
      "nombre": "VALLE ALEGRE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7314776926852,
      "centroide_lon": -64.4129622125593,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147300000",
      "localidad_censal_id": "14147300",
      "localidad_censal_nombre": "Valle de Anisacate",
      "municipio_id": "143071",
      "municipio_nombre": "Valle de Anisacate",
      "nombre": "VALLE DE ANISACATE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -31.7944839463173,
      "centroide_lon": -64.5122651994167,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147310000",
      "localidad_censal_id": "14147310",
      "localidad_censal_nombre": "Villa Ciudad de América",
      "municipio_id": "143078",
      "municipio_nombre": "Villa Ciudad de América",
      "nombre": "VILLA CIUDAD DE AMERICA (LOTEO DIEGO DE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.7772193262534,
      "centroide_lon": -64.532077974826,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147310001",
      "localidad_censal_id": "14147310",
      "localidad_censal_nombre": "Villa Ciudad de América",
      "municipio_id": "143078",
      "municipio_nombre": "Villa Ciudad de América",
      "nombre": "BARRIO VILLA DEL PARQUE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.7932195162073,
      "centroide_lon": -64.5139255175446,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147310002",
      "localidad_censal_id": "14147310",
      "localidad_censal_nombre": "Villa Ciudad de América",
      "municipio_id": "143078",
      "municipio_nombre": "Villa Ciudad de América",
      "nombre": "VILLA CIUDAD DE AMERICA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.6179397578028,
      "centroide_lon": -64.3924925248834,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147320000",
      "localidad_censal_id": "14147320",
      "localidad_censal_nombre": "Villa del Prado",
      "municipio_id": "143085",
      "municipio_nombre": "Villa del Prado",
      "nombre": "VILLA DEL PRADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6174543235156,
      "centroide_lon": -64.391062873902,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147320001",
      "localidad_censal_id": "14147320",
      "localidad_censal_nombre": "Villa del Prado",
      "municipio_id": "143085",
      "municipio_nombre": "Villa del Prado",
      "nombre": "VILLA DEL PRADO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6430306930417,
      "centroide_lon": -64.3453826897825,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147320002",
      "localidad_censal_id": "14147320",
      "localidad_censal_nombre": "Villa del Prado",
      "municipio_id": "141372",
      "municipio_nombre": "Alta Gracia",
      "nombre": "LA DONOSA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7287879568356,
      "centroide_lon": -64.4324174982752,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147330000",
      "localidad_censal_id": "14147330",
      "localidad_censal_nombre": "Villa La Bolsa",
      "municipio_id": "143092",
      "municipio_nombre": "Villa la Bolsa",
      "nombre": "VILLA LA BOLSA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7359598057037,
      "centroide_lon": -64.4389279396961,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147340000",
      "localidad_censal_id": "14147340",
      "localidad_censal_nombre": "Villa Los Aromos",
      "municipio_id": "143099",
      "municipio_nombre": "Villa los Aromos",
      "nombre": "VILLA LOS AROMOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -31.5893075812486,
      "centroide_lon": -64.3536920462417,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147350000",
      "localidad_censal_id": "14147350",
      "localidad_censal_nombre": "Villa Parque Santa Ana",
      "municipio_id": "143106",
      "municipio_nombre": "Villa Parque Santa Ana",
      "nombre": "VILLA PARQUE SANTA ANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5725913753684,
      "centroide_lon": -64.3341910295503,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147350001",
      "localidad_censal_id": "14147350",
      "localidad_censal_nombre": "Villa Parque Santa Ana",
      "municipio_id": "143106",
      "municipio_nombre": "Villa Parque Santa Ana",
      "nombre": "MI VALLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5917319016207,
      "centroide_lon": -64.3503003378206,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147350002",
      "localidad_censal_id": "14147350",
      "localidad_censal_nombre": "Villa Parque Santa Ana",
      "municipio_id": "143106",
      "municipio_nombre": "Villa Parque Santa Ana",
      "nombre": "VILLA PARQUE SANTA ANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.8208264119355,
      "centroide_lon": -64.3935275639835,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147360000",
      "localidad_censal_id": "14147360",
      "localidad_censal_nombre": "Villa San Isidro",
      "municipio_id": "143113",
      "municipio_nombre": "Villa San Isidro",
      "nombre": "VILLA SAN ISIDRO - JOSE DE LA QUINTANA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4579361723038,
      "centroide_lon": -64.4217153267522,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147370000",
      "localidad_censal_id": "14147370",
      "localidad_censal_nombre": "Villa Sierras De Oro",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "VILLA SIERRAS DE ORO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4470577243833,
      "centroide_lon": -64.3660748415952,
      "departamento_id": "14147",
      "departamento_nombre": "Santa María",
      "id": "14147380000",
      "localidad_censal_id": "14147380",
      "localidad_censal_nombre": "Yocsina",
      "municipio_id": "141393",
      "municipio_nombre": "Malagueño",
      "nombre": "YOCSINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0682250779624,
      "centroide_lon": -64.052875898278,
      "departamento_id": "14154",
      "departamento_nombre": "Sobremonte",
      "id": "14154010000",
      "localidad_censal_id": "14154010",
      "localidad_censal_nombre": "Caminiaga",
      "municipio_id": "143120",
      "municipio_nombre": "Caminiaga",
      "nombre": "CAMINIAGA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9138372593106,
      "centroide_lon": -64.1299817960277,
      "departamento_id": "14154",
      "departamento_nombre": "Sobremonte",
      "id": "14154030000",
      "localidad_censal_id": "14154030",
      "localidad_censal_nombre": "Chuña Huasi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHUÑA HUASI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5775456904692,
      "centroide_lon": -64.1070996245776,
      "departamento_id": "14154",
      "departamento_nombre": "Sobremonte",
      "id": "14154040000",
      "localidad_censal_id": "14154040",
      "localidad_censal_nombre": "Pozo Nuevo",
      "municipio_id": "143134",
      "municipio_nombre": "Pozo Nuevo",
      "nombre": "POZO NUEVO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7882609409722,
      "centroide_lon": -63.9430703616826,
      "departamento_id": "14154",
      "departamento_nombre": "Sobremonte",
      "id": "14154050000",
      "localidad_censal_id": "14154050",
      "localidad_censal_nombre": "San Francisco del Chañar",
      "municipio_id": "141414",
      "municipio_nombre": "San Francisco del Chañar",
      "nombre": "SAN FRANCISCO DEL CHAÑAR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1934604986786,
      "centroide_lon": -64.2543871859511,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161010000",
      "localidad_censal_id": "14161010",
      "localidad_censal_nombre": "Almafuerte",
      "municipio_id": "141421",
      "municipio_nombre": "Almafuerte",
      "nombre": "ALMAFUERTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.033345652856,
      "centroide_lon": -63.8628846933814,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161020000",
      "localidad_censal_id": "14161020",
      "localidad_censal_nombre": "Colonia Almada",
      "municipio_id": "141428",
      "municipio_nombre": "Colonia Almada",
      "nombre": "COLONIA ALMADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0252572445237,
      "centroide_lon": -64.1931588929625,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161030000",
      "localidad_censal_id": "14161030",
      "localidad_censal_nombre": "Corralito",
      "municipio_id": "141435",
      "municipio_nombre": "Corralito",
      "nombre": "CORRALITO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6108732524704,
      "centroide_lon": -63.5798837158318,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161040000",
      "localidad_censal_id": "14161040",
      "localidad_censal_nombre": "Dalmacio Vélez",
      "municipio_id": "141442",
      "municipio_nombre": "Dalmacio Vélez",
      "nombre": "DALMACIO VELEZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3234295842043,
      "centroide_lon": -63.8704257430147,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161050000",
      "localidad_censal_id": "14161050",
      "localidad_censal_nombre": "General Fotheringham",
      "municipio_id": "143141",
      "municipio_nombre": "General Fotheringham",
      "nombre": "GENERAL FOTHERINGHAM",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4276148348373,
      "centroide_lon": -63.7330119093142,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161060000",
      "localidad_censal_id": "14161060",
      "localidad_censal_nombre": "Hernando",
      "municipio_id": "141449",
      "municipio_nombre": "Hernando",
      "nombre": "HERNANDO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1613056379775,
      "centroide_lon": -63.4651919500633,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161070000",
      "localidad_censal_id": "14161070",
      "localidad_censal_nombre": "James Craik",
      "municipio_id": "141456",
      "municipio_nombre": "James Craik",
      "nombre": "JAMES CRAIK",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5100979867632,
      "centroide_lon": -63.9290229356551,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161080000",
      "localidad_censal_id": "14161080",
      "localidad_censal_nombre": "Las Isletillas",
      "municipio_id": "143148",
      "municipio_nombre": "Las Isletillas",
      "nombre": "LAS ISLETILLAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6981884459632,
      "centroide_lon": -63.7083103176214,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161090000",
      "localidad_censal_id": "14161090",
      "localidad_censal_nombre": "Las Perdices",
      "municipio_id": "141463",
      "municipio_nombre": "Las Perdices",
      "nombre": "LAS PERDICES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.045928188462,
      "centroide_lon": -63.2017981667251,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161100000",
      "localidad_censal_id": "14161100",
      "localidad_censal_nombre": "Los Zorros",
      "municipio_id": "141470",
      "municipio_nombre": "Los Zorros",
      "nombre": "LOS ZORROS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0413174021447,
      "centroide_lon": -63.5693820664827,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161110000",
      "localidad_censal_id": "14161110",
      "localidad_censal_nombre": "Oliva",
      "municipio_id": "141477",
      "municipio_nombre": "Oliva",
      "nombre": "OLIVA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2416827296709,
      "centroide_lon": -63.6421868735368,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161120000",
      "localidad_censal_id": "14161120",
      "localidad_censal_nombre": "Pampayasta Norte",
      "municipio_id": "143155",
      "municipio_nombre": "Pampayasta Norte",
      "nombre": "PAMPAYASTA NORTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2507581145055,
      "centroide_lon": -63.6510140335346,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161130000",
      "localidad_censal_id": "14161130",
      "localidad_censal_nombre": "Pampayasta Sud",
      "municipio_id": "141484",
      "municipio_nombre": "Pampayasta Sud",
      "nombre": "PAMPAYASTA SUR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5754281748334,
      "centroide_lon": -63.8101580899516,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161140000",
      "localidad_censal_id": "14161140",
      "localidad_censal_nombre": "Punta del Agua",
      "municipio_id": "143162",
      "municipio_nombre": "Punta del Agua",
      "nombre": "PUNTA DEL AGUA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.173100971278,
      "centroide_lon": -64.1130560562283,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161150000",
      "localidad_censal_id": "14161150",
      "localidad_censal_nombre": "Río Tercero",
      "municipio_id": "141491",
      "municipio_nombre": "Río Tercero",
      "nombre": "RIO TERCERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2402070251544,
      "centroide_lon": -63.9799466045503,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161160000",
      "localidad_censal_id": "14161160",
      "localidad_censal_nombre": "Tancacha",
      "municipio_id": "141498",
      "municipio_nombre": "Tancacha",
      "nombre": "TANCACHA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1643583032298,
      "centroide_lon": -63.892558867184,
      "departamento_id": "14161",
      "departamento_nombre": "Tercero Arriba",
      "id": "14161170000",
      "localidad_censal_id": "14161170",
      "localidad_censal_nombre": "Villa Ascasubi",
      "municipio_id": "141505",
      "municipio_nombre": "Villa Ascasubi",
      "nombre": "VILLA ASCASUBI",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8392305968427,
      "centroide_lon": -63.8008465946487,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168010000",
      "localidad_censal_id": "14168010",
      "localidad_censal_nombre": "Candelaria Sur",
      "municipio_id": "143169",
      "municipio_nombre": "Candelaria Sud",
      "nombre": "CANDELARIA SUR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7370875150032,
      "centroide_lon": -63.723661877713,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168020000",
      "localidad_censal_id": "14168020",
      "localidad_censal_nombre": "Cañada de Luque",
      "municipio_id": "141512",
      "municipio_nombre": "Cañada de Luque",
      "nombre": "CAÑADA DE LUQUE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5729640848008,
      "centroide_lon": -63.6517416910945,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168030000",
      "localidad_censal_id": "14168030",
      "localidad_censal_nombre": "Capilla de Sitón",
      "municipio_id": "143176",
      "municipio_nombre": "Capilla del Sitón",
      "nombre": "CAPILLA DE SITON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.943220474797,
      "centroide_lon": -64.2765349210302,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168040000",
      "localidad_censal_id": "14168040",
      "localidad_censal_nombre": "La Pampa",
      "municipio_id": "143183",
      "municipio_nombre": "La Pampa",
      "nombre": "LA PAMPA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5617525961006,
      "centroide_lon": -64.0019842072006,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168060000",
      "localidad_censal_id": "14168060",
      "localidad_censal_nombre": "Las Peñas",
      "municipio_id": "141519",
      "municipio_nombre": "Las Peñas",
      "nombre": "LAS PEÑAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6259742943516,
      "centroide_lon": -63.886727301445,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168070000",
      "localidad_censal_id": "14168070",
      "localidad_censal_nombre": "Los Mistoles",
      "municipio_id": "143190",
      "municipio_nombre": "Los Mistoles",
      "nombre": "LOS MISTOLES",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8726236468488,
      "centroide_lon": -64.2313503284229,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168080000",
      "localidad_censal_id": "14168080",
      "localidad_censal_nombre": "Santa Catalina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTA CATALINA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7741941000543,
      "centroide_lon": -64.1089868115808,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168090000",
      "localidad_censal_id": "14168090",
      "localidad_censal_nombre": "Sarmiento",
      "municipio_id": "141526",
      "municipio_nombre": "Sarmiento",
      "nombre": "SARMIENTO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4752563998131,
      "centroide_lon": -63.9850395218446,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168100000",
      "localidad_censal_id": "14168100",
      "localidad_censal_nombre": "Simbolar",
      "municipio_id": "143197",
      "municipio_nombre": "Simbolar",
      "nombre": "SIMBOLAR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9428574787407,
      "centroide_lon": -64.0884974374818,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168110000",
      "localidad_censal_id": "14168110",
      "localidad_censal_nombre": "Sinsacate",
      "municipio_id": "141533",
      "municipio_nombre": "Sinsacate",
      "nombre": "SINSACATE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7038437425366,
      "centroide_lon": -64.0682991778579,
      "departamento_id": "14168",
      "departamento_nombre": "Totoral",
      "id": "14168120000",
      "localidad_censal_id": "14168120",
      "localidad_censal_nombre": "Villa del Totoral",
      "municipio_id": "141540",
      "municipio_nombre": "Villa del Totoral",
      "nombre": "VILLA DEL TOTORAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1681571983317,
      "centroide_lon": -63.9295889316832,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175020000",
      "localidad_censal_id": "14175020",
      "localidad_censal_nombre": "Churqui Cañada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHURQUI CAÑADA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.176079345889,
      "centroide_lon": -63.8679636143828,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175030000",
      "localidad_censal_id": "14175030",
      "localidad_censal_nombre": "El Rodeo",
      "municipio_id": "143211",
      "municipio_nombre": "El Rodeo",
      "nombre": "EL RODEO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7574368770199,
      "centroide_lon": -64.5298988550679,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175040000",
      "localidad_censal_id": "14175040",
      "localidad_censal_nombre": "El Tuscal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL TUSCAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3812826841011,
      "centroide_lon": -63.5966635615372,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175050000",
      "localidad_censal_id": "14175050",
      "localidad_censal_nombre": "Las Arrias",
      "municipio_id": "141547",
      "municipio_nombre": "Las Arrias",
      "nombre": "LAS ARRIAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8063717196573,
      "centroide_lon": -64.7065412644276,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175060000",
      "localidad_censal_id": "14175060",
      "localidad_censal_nombre": "Lucio V. Mansilla",
      "municipio_id": "141554",
      "municipio_nombre": "Lucio V. Mansilla",
      "nombre": "LUCIO V. MANSILLA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4293369124895,
      "centroide_lon": -63.4478615181188,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175070000",
      "localidad_censal_id": "14175070",
      "localidad_censal_nombre": "Rosario del Saladillo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROSARIO DEL SALADILLO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3546018393412,
      "centroide_lon": -63.9466529209876,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175080000",
      "localidad_censal_id": "14175080",
      "localidad_censal_nombre": "San José de la Dormida",
      "municipio_id": "141561",
      "municipio_nombre": "San José de la Dormida",
      "nombre": "SAN JOSE DE LA DORMIDA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0090773891027,
      "centroide_lon": -64.6251344233407,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175090000",
      "localidad_censal_id": "14175090",
      "localidad_censal_nombre": "San José de las Salinas",
      "municipio_id": "141568",
      "municipio_nombre": "San José de las Salinas",
      "nombre": "SAN JOSE DE LAS SALINAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0887589979373,
      "centroide_lon": -64.1559536726827,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175100000",
      "localidad_censal_id": "14175100",
      "localidad_censal_nombre": "San Pedro Norte",
      "municipio_id": "141575",
      "municipio_nombre": "San Pedro Norte",
      "nombre": "SAN PEDRO NORTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.397481563929,
      "centroide_lon": -64.1231771567642,
      "departamento_id": "14175",
      "departamento_nombre": "Tulumba",
      "id": "14175110000",
      "localidad_censal_id": "14175110",
      "localidad_censal_nombre": "Villa Tulumba",
      "municipio_id": "141582",
      "municipio_nombre": "Villa Tulumba",
      "nombre": "VILLA TULUMBA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6948816087071,
      "centroide_lon": -62.9114758842793,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182010000",
      "localidad_censal_id": "14182010",
      "localidad_censal_nombre": "Aldea Santa María",
      "municipio_id": "143225",
      "municipio_nombre": "Aldea Santa María",
      "nombre": "ALDEA SANTA MARIA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3460951978736,
      "centroide_lon": -62.8853485991764,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182020000",
      "localidad_censal_id": "14182020",
      "localidad_censal_nombre": "Alto Alegre",
      "municipio_id": "141589",
      "municipio_nombre": "Alto Alegre",
      "nombre": "ALTO ALEGRE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3908244034289,
      "centroide_lon": -63.0574835050696,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182030000",
      "localidad_censal_id": "14182030",
      "localidad_censal_nombre": "Ana Zumarán",
      "municipio_id": "143232",
      "municipio_nombre": "Ana Zumarán",
      "nombre": "ANA ZUMARAN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5453815879853,
      "centroide_lon": -62.9833005213374,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182040000",
      "localidad_censal_id": "14182040",
      "localidad_censal_nombre": "Ballesteros",
      "municipio_id": "141596",
      "municipio_nombre": "Ballesteros",
      "nombre": "BALLESTEROS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5885971215357,
      "centroide_lon": -63.027038782482,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182050000",
      "localidad_censal_id": "14182050",
      "localidad_censal_nombre": "Ballesteros Sud",
      "municipio_id": "141603",
      "municipio_nombre": "Ballesteros Sud",
      "nombre": "BALLESTEROS SUR",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6285600750708,
      "centroide_lon": -62.6891149083321,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182060000",
      "localidad_censal_id": "14182060",
      "localidad_censal_nombre": "Bell Ville",
      "municipio_id": "141610",
      "municipio_nombre": "Bell Ville",
      "nombre": "BELL VILLE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5901195408333,
      "centroide_lon": -62.7303890735446,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182070000",
      "localidad_censal_id": "14182070",
      "localidad_censal_nombre": "Benjamín Gould",
      "municipio_id": "141617",
      "municipio_nombre": "Benjamín Gould",
      "nombre": "BENJAMIN GOULD",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.562382718352,
      "centroide_lon": -62.8855974037259,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182080000",
      "localidad_censal_id": "14182080",
      "localidad_censal_nombre": "Canals",
      "municipio_id": "141624",
      "municipio_nombre": "Canals",
      "nombre": "CANALS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3325969254244,
      "centroide_lon": -62.5135995097258,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182090000",
      "localidad_censal_id": "14182090",
      "localidad_censal_nombre": "Chilibroste",
      "municipio_id": "141631",
      "municipio_nombre": "Chilibroste",
      "nombre": "CHILIBROSTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3081476332499,
      "centroide_lon": -62.6531184151953,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182100000",
      "localidad_censal_id": "14182100",
      "localidad_censal_nombre": "Cintra",
      "municipio_id": "141638",
      "municipio_nombre": "Cintra",
      "nombre": "CINTRA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3003017093034,
      "centroide_lon": -62.7132986409449,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182110000",
      "localidad_censal_id": "14182110",
      "localidad_censal_nombre": "Colonia Bismarck",
      "municipio_id": "141645",
      "municipio_nombre": "Colonia Bismarck",
      "nombre": "COLONIA BISMARCK",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4634972191589,
      "centroide_lon": -62.7323851196295,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182120000",
      "localidad_censal_id": "14182120",
      "localidad_censal_nombre": "Colonia Bremen",
      "municipio_id": "143239",
      "municipio_nombre": "Colonia Bremen",
      "nombre": "COLONIA BREMEN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.813142666622,
      "centroide_lon": -63.0329101219103,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182130000",
      "localidad_censal_id": "14182130",
      "localidad_censal_nombre": "Idiazabal",
      "municipio_id": "141652",
      "municipio_nombre": "Idiazabal",
      "nombre": "IDIAZABAL",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.883580112369,
      "centroide_lon": -62.6802767037884,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182140000",
      "localidad_censal_id": "14182140",
      "localidad_censal_nombre": "Justiniano Posse",
      "municipio_id": "141659",
      "municipio_nombre": "Justiniano Posse",
      "nombre": "JUSTINIANO POSSE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1529891830182,
      "centroide_lon": -62.8560737781526,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182150000",
      "localidad_censal_id": "14182150",
      "localidad_censal_nombre": "Laborde",
      "municipio_id": "141666",
      "municipio_nombre": "Laborde",
      "nombre": "LABORDE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6112897765277,
      "centroide_lon": -62.5903410387947,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182160000",
      "localidad_censal_id": "14182160",
      "localidad_censal_nombre": "Monte Leña",
      "municipio_id": "141673",
      "municipio_nombre": "Monte Leña",
      "nombre": "MONTE LEÑA",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2045620901114,
      "centroide_lon": -62.601248151355,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182170000",
      "localidad_censal_id": "14182170",
      "localidad_censal_nombre": "Monte Maíz",
      "municipio_id": "141680",
      "municipio_nombre": "Monte Maíz",
      "nombre": "MONTE MAIZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5936423075986,
      "centroide_lon": -62.8360084604177,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182180000",
      "localidad_censal_id": "14182180",
      "localidad_censal_nombre": "Morrison",
      "municipio_id": "141687",
      "municipio_nombre": "Morrison",
      "nombre": "MORRISON",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3667416426436,
      "centroide_lon": -62.312071766995,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182190000",
      "localidad_censal_id": "14182190",
      "localidad_censal_nombre": "Noetinger",
      "municipio_id": "141694",
      "municipio_nombre": "Noetinger",
      "nombre": "NOETINGER",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8412715334934,
      "centroide_lon": -62.866054921116,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182200000",
      "localidad_censal_id": "14182200",
      "localidad_censal_nombre": "Ordoñez",
      "municipio_id": "141701",
      "municipio_nombre": "Ordóñez",
      "nombre": "ORDOÑEZ",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1249872931372,
      "centroide_lon": -63.0426957377869,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182210000",
      "localidad_censal_id": "14182210",
      "localidad_censal_nombre": "Pascanas",
      "municipio_id": "141708",
      "municipio_nombre": "Pascanas",
      "nombre": "PASCANAS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.880722767451,
      "centroide_lon": -62.8407250305139,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182220000",
      "localidad_censal_id": "14182220",
      "localidad_censal_nombre": "Pueblo Italiano",
      "municipio_id": "141715",
      "municipio_nombre": "Pueblo Italiano",
      "nombre": "PUEBLO ITALIANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4911089118919,
      "centroide_lon": -63.1028075144056,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182230000",
      "localidad_censal_id": "14182230",
      "localidad_censal_nombre": "Ramón J. Cárcano",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RAMON J. CARCANO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2128605395629,
      "centroide_lon": -62.6330068495678,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182240000",
      "localidad_censal_id": "14182240",
      "localidad_censal_nombre": "San Antonio de Litín",
      "municipio_id": "141722",
      "municipio_nombre": "San Antonio de Litín",
      "nombre": "SAN ANTONIO DE LITIN",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6303275526998,
      "centroide_lon": -62.4819712615844,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182250000",
      "localidad_censal_id": "14182250",
      "localidad_censal_nombre": "San Marcos",
      "municipio_id": "141729",
      "municipio_nombre": "San Marcos Sud",
      "nombre": "SAN MARCOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5383254710795,
      "centroide_lon": -63.0479276785208,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182260000",
      "localidad_censal_id": "14182260",
      "localidad_censal_nombre": "San Severo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN SEVERO",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7470669728167,
      "centroide_lon": -63.0989062265371,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182270000",
      "localidad_censal_id": "14182270",
      "localidad_censal_nombre": "Viamonte",
      "municipio_id": "141736",
      "municipio_nombre": "Viamonte",
      "nombre": "VIAMONTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.763745038404,
      "centroide_lon": -62.7277954886855,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182280000",
      "localidad_censal_id": "14182280",
      "localidad_censal_nombre": "Villa Los Patos",
      "municipio_id": "143246",
      "municipio_nombre": "Villa los Patos",
      "nombre": "VILLA LOS PATOS",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1720228484368,
      "centroide_lon": -62.7700003268955,
      "departamento_id": "14182",
      "departamento_nombre": "Unión",
      "id": "14182290000",
      "localidad_censal_id": "14182290",
      "localidad_censal_nombre": "Wenceslao Escalante",
      "municipio_id": "141743",
      "municipio_nombre": "Wenceslao Escalante",
      "nombre": "WENCESLAO ESCALANTE",
      "provincia_id": "14",
      "provincia_nombre": "Córdoba"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.507677249655,
      "centroide_lon": -59.04433283502,
      "departamento_id": "18007",
      "departamento_nombre": "Bella Vista",
      "id": "18007010000",
      "localidad_censal_id": "18007010",
      "localidad_censal_nombre": "Bella Vista",
      "municipio_id": "180007",
      "municipio_nombre": "Bella Vista",
      "nombre": "BELLA VISTA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5506233626678,
      "centroide_lon": -57.5376245501398,
      "departamento_id": "18014",
      "departamento_nombre": "Berón de Astrada",
      "id": "18014010000",
      "localidad_censal_id": "18014010",
      "localidad_censal_nombre": "Berón de Astrada",
      "municipio_id": "180014",
      "municipio_nombre": "Berón de Astrada",
      "nombre": "BERON DE ASTRADA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3704269216251,
      "centroide_lon": -57.6552249966379,
      "departamento_id": "18014",
      "departamento_nombre": "Berón de Astrada",
      "id": "18014020000",
      "localidad_censal_id": "18014020",
      "localidad_censal_nombre": "Yahapé",
      "municipio_id": "180014",
      "municipio_nombre": "Berón de Astrada",
      "nombre": "YAHAPE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4632821641043,
      "centroide_lon": -58.8392333481757,
      "departamento_id": "18021",
      "departamento_nombre": "Capital",
      "id": "18021020000",
      "localidad_censal_id": "18021020",
      "localidad_censal_nombre": "Corrientes",
      "municipio_id": "180021",
      "municipio_nombre": "Corrientes",
      "nombre": "CORRIENTES",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.492827539732,
      "centroide_lon": -58.7167656641361,
      "departamento_id": "18021",
      "departamento_nombre": "Capital",
      "id": "18021030000",
      "localidad_censal_id": "18021030",
      "localidad_censal_nombre": "Laguna Brava",
      "municipio_id": "180021",
      "municipio_nombre": "Corrientes",
      "nombre": "LAGUNA BRAVA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5811452851025,
      "centroide_lon": -58.7419831223036,
      "departamento_id": "18021",
      "departamento_nombre": "Capital",
      "id": "18021040000",
      "localidad_censal_id": "18021040",
      "localidad_censal_nombre": "Riachuelo",
      "municipio_id": "180028",
      "municipio_nombre": "Riachuelo",
      "nombre": "RIACHUELO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5712103716288,
      "centroide_lon": -58.6958799100026,
      "departamento_id": "18021",
      "departamento_nombre": "Capital",
      "id": "18021050000",
      "localidad_censal_id": "18021050",
      "localidad_censal_nombre": "San Cayetano",
      "municipio_id": "180028",
      "municipio_nombre": "Riachuelo",
      "nombre": "SAN CAYETANO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3924910085097,
      "centroide_lon": -57.8866807708097,
      "departamento_id": "18028",
      "departamento_nombre": "Concepción",
      "id": "18028010000",
      "localidad_censal_id": "18028010",
      "localidad_censal_nombre": "Concepción",
      "municipio_id": "180042",
      "municipio_nombre": "Concepción",
      "nombre": "CONCEPCION",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2674256970164,
      "centroide_lon": -58.1220612497619,
      "departamento_id": "18028",
      "departamento_nombre": "Concepción",
      "id": "18028020000",
      "localidad_censal_id": "18028020",
      "localidad_censal_nombre": "Santa Rosa",
      "municipio_id": "180035",
      "municipio_nombre": "Colonia Santa Rosa",
      "nombre": "SANTA ROSA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3062678179647,
      "centroide_lon": -58.2862966985736,
      "departamento_id": "18028",
      "departamento_nombre": "Concepción",
      "id": "18028030000",
      "localidad_censal_id": "18028030",
      "localidad_censal_nombre": "Tabay",
      "municipio_id": "180049",
      "municipio_nombre": "Tabay",
      "nombre": "TABAY",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3720748439642,
      "centroide_lon": -58.3252896634835,
      "departamento_id": "18028",
      "departamento_nombre": "Concepción",
      "id": "18028040000",
      "localidad_censal_id": "18028040",
      "localidad_censal_nombre": "Tatacua",
      "municipio_id": "180056",
      "municipio_nombre": "Tatacuá",
      "nombre": "TATACUA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9787827263917,
      "centroide_lon": -58.3024356720991,
      "departamento_id": "18035",
      "departamento_nombre": "Curuzu Cuatia",
      "id": "18035010000",
      "localidad_censal_id": "18035010",
      "localidad_censal_nombre": "Cazadores Correntinos",
      "municipio_id": "180063",
      "municipio_nombre": "Curuzú Cuatiá",
      "nombre": "CAZADORES CORRENTINOS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7915233554051,
      "centroide_lon": -58.049945341682,
      "departamento_id": "18035",
      "departamento_nombre": "Curuzu Cuatia",
      "id": "18035020000",
      "localidad_censal_id": "18035020",
      "localidad_censal_nombre": "Curuzú Cuatiá",
      "municipio_id": "180063",
      "municipio_nombre": "Curuzú Cuatiá",
      "nombre": "CURUZU CUATIA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3404796154785,
      "centroide_lon": -58.6080890462901,
      "departamento_id": "18035",
      "departamento_nombre": "Curuzu Cuatia",
      "id": "18035030000",
      "localidad_censal_id": "18035030",
      "localidad_censal_nombre": "Perugorría",
      "municipio_id": "180070",
      "municipio_nombre": "Perugorría",
      "nombre": "PERUGORRIA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7035440870919,
      "centroide_lon": -58.7686060583955,
      "departamento_id": "18042",
      "departamento_nombre": "Empedrado",
      "id": "18042010000",
      "localidad_censal_id": "18042010",
      "localidad_censal_nombre": "El Sombrero",
      "municipio_id": "180077",
      "municipio_nombre": "Empedrado",
      "nombre": "EL SOMBRERO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9524556932224,
      "centroide_lon": -58.8074763893306,
      "departamento_id": "18042",
      "departamento_nombre": "Empedrado",
      "id": "18042020000",
      "localidad_censal_id": "18042020",
      "localidad_censal_nombre": "Empedrado",
      "municipio_id": "180077",
      "municipio_nombre": "Empedrado",
      "nombre": "EMPEDRADO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0159284150174,
      "centroide_lon": -59.5309812132883,
      "departamento_id": "18049",
      "departamento_nombre": "Esquina",
      "id": "18049010000",
      "localidad_censal_id": "18049010",
      "localidad_censal_nombre": "Esquina",
      "municipio_id": "180084",
      "municipio_nombre": "Esquina",
      "nombre": "ESQUINA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2195813152624,
      "centroide_lon": -59.3906028568407,
      "departamento_id": "18049",
      "departamento_nombre": "Esquina",
      "id": "18049020000",
      "localidad_censal_id": "18049020",
      "localidad_censal_nombre": "Pueblo Libertador",
      "municipio_id": "180091",
      "municipio_nombre": "Pueblo Libertador",
      "nombre": "PUEBLO LIBERTADOR",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0985990956679,
      "centroide_lon": -56.5521631297617,
      "departamento_id": "18056",
      "departamento_nombre": "General Alvear",
      "id": "18056010000",
      "localidad_censal_id": "18056010",
      "localidad_censal_nombre": "Alvear",
      "municipio_id": "180098",
      "municipio_nombre": "Alvear",
      "nombre": "ALVEAR",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8266239157245,
      "centroide_lon": -56.469525963544,
      "departamento_id": "18056",
      "departamento_nombre": "General Alvear",
      "id": "18056020000",
      "localidad_censal_id": "18056020",
      "localidad_censal_nombre": "Estación Torrent",
      "municipio_id": "180105",
      "municipio_nombre": "Estación Torrent",
      "nombre": "ESTACION TORRENT",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4250661277127,
      "centroide_lon": -57.3376993601456,
      "departamento_id": "18063",
      "departamento_nombre": "General Paz",
      "id": "18063010000",
      "localidad_censal_id": "18063010",
      "localidad_censal_nombre": "Itá Ibaté",
      "municipio_id": "180119",
      "municipio_nombre": "Itá Ibaté",
      "nombre": "ITA IBATE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7329656323888,
      "centroide_lon": -57.9193852921423,
      "departamento_id": "18063",
      "departamento_nombre": "General Paz",
      "id": "18063020000",
      "localidad_censal_id": "18063020",
      "localidad_censal_nombre": "Lomas de Vallejos",
      "municipio_id": "180126",
      "municipio_nombre": "Lomas de Vallejos",
      "nombre": "LOMAS DE VALLEJOS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.751995828158,
      "centroide_lon": -57.6225136238666,
      "departamento_id": "18063",
      "departamento_nombre": "General Paz",
      "id": "18063030000",
      "localidad_censal_id": "18063030",
      "localidad_censal_nombre": "Nuestra Señora del Rosario de Caá Catí",
      "municipio_id": "180112",
      "municipio_nombre": "Caá Catí",
      "nombre": "NUESTRA SEÑORA DEL ROSARIO DE CAA CATI",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9407694115665,
      "centroide_lon": -57.9009156630285,
      "departamento_id": "18063",
      "departamento_nombre": "General Paz",
      "id": "18063040000",
      "localidad_censal_id": "18063040",
      "localidad_censal_nombre": "Palmar Grande",
      "municipio_id": "180133",
      "municipio_nombre": "Palmar Grande",
      "nombre": "PALMAR GRANDE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1457706796124,
      "centroide_lon": -59.1820838282047,
      "departamento_id": "18070",
      "departamento_nombre": "Goya",
      "id": "18070010000",
      "localidad_censal_id": "18070010",
      "localidad_censal_nombre": "Carolina",
      "municipio_id": "180137",
      "municipio_nombre": "Carolina",
      "nombre": "COLONIA CAROLINA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1413439330685,
      "centroide_lon": -59.2605311638707,
      "departamento_id": "18070",
      "departamento_nombre": "Goya",
      "id": "18070020000",
      "localidad_censal_id": "18070020",
      "localidad_censal_nombre": "Goya",
      "municipio_id": "180140",
      "municipio_nombre": "Goya",
      "nombre": "GOYA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2693038533077,
      "centroide_lon": -58.2434782721969,
      "departamento_id": "18077",
      "departamento_nombre": "Itatí",
      "id": "18077010000",
      "localidad_censal_id": "18077010",
      "localidad_censal_nombre": "Itatí",
      "municipio_id": "180147",
      "municipio_nombre": "Itatí",
      "nombre": "ITATI",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3659289314983,
      "centroide_lon": -58.3003087514316,
      "departamento_id": "18077",
      "departamento_nombre": "Itatí",
      "id": "18077020000",
      "localidad_censal_id": "18077020",
      "localidad_censal_nombre": "Ramada Paso",
      "municipio_id": "180154",
      "municipio_nombre": "Ramada Paso",
      "nombre": "RAMADA PASO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.9156591543749,
      "centroide_lon": -55.8233285990026,
      "departamento_id": "18084",
      "departamento_nombre": "Ituzaingó",
      "id": "18084010000",
      "localidad_censal_id": "18084010",
      "localidad_censal_nombre": "Colonia Liebig's",
      "municipio_id": "180161",
      "municipio_nombre": "Colonia Liebig'S",
      "nombre": "COLONIA LIEBIG'S",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5910429413741,
      "centroide_lon": -56.7039739448011,
      "departamento_id": "18084",
      "departamento_nombre": "Ituzaingó",
      "id": "18084020000",
      "localidad_censal_id": "18084020",
      "localidad_censal_nombre": "Ituzaingó",
      "municipio_id": "180168",
      "municipio_nombre": "Ituzaingó",
      "nombre": "ITUZAINGO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5073854602181,
      "centroide_lon": -56.7411670700869,
      "departamento_id": "18084",
      "departamento_nombre": "Ituzaingó",
      "id": "18084030000",
      "localidad_censal_id": "18084030",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "180175",
      "municipio_nombre": "San Antonio",
      "nombre": "SAN ANTONIO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.745785086345,
      "centroide_lon": -55.9000479407003,
      "departamento_id": "18084",
      "departamento_nombre": "Ituzaingó",
      "id": "18084040000",
      "localidad_censal_id": "18084040",
      "localidad_censal_nombre": "San Carlos",
      "municipio_id": "180182",
      "municipio_nombre": "San Carlos",
      "nombre": "SAN CARLOS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6329473311043,
      "centroide_lon": -56.9062014827101,
      "departamento_id": "18084",
      "departamento_nombre": "Ituzaingó",
      "id": "18084050000",
      "localidad_censal_id": "18084050",
      "localidad_censal_nombre": "Villa Olivari",
      "municipio_id": "180186",
      "municipio_nombre": "Villa Olivari",
      "nombre": "VILLA OLIVARI",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8357350715385,
      "centroide_lon": -59.0068578339405,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091010000",
      "localidad_censal_id": "18091010",
      "localidad_censal_nombre": "Cruz de los Milagros",
      "municipio_id": "180189",
      "municipio_nombre": "Cruz de los Milagros",
      "nombre": "CRUZ DE LOS MILAGROS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9108688742576,
      "centroide_lon": -58.9359577546112,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091020000",
      "localidad_censal_id": "18091020",
      "localidad_censal_nombre": "Gobernador Juan E. Martínez",
      "municipio_id": "180196",
      "municipio_nombre": "Gobernador Martínez",
      "nombre": "GOBERNADOR JUAN E. MARTINEZ",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0249812495642,
      "centroide_lon": -59.1818570100087,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091030000",
      "localidad_censal_id": "18091030",
      "localidad_censal_nombre": "Lavalle",
      "municipio_id": "180203",
      "municipio_nombre": "Lavalle",
      "nombre": "LAVALLE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9847966992846,
      "centroide_lon": -59.1017576429994,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091040000",
      "localidad_censal_id": "18091040",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "180210",
      "municipio_nombre": "Santa Lucía",
      "nombre": "SANTA LUCIA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9940335487267,
      "centroide_lon": -59.0774380130144,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091050000",
      "localidad_censal_id": "18091050",
      "localidad_censal_nombre": "Villa Córdoba",
      "municipio_id": "180210",
      "municipio_nombre": "Santa Lucía",
      "nombre": "VILLA CORDOBA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0295902166451,
      "centroide_lon": -58.9097321884449,
      "departamento_id": "18091",
      "departamento_nombre": "Lavalle",
      "id": "18091060000",
      "localidad_censal_id": "18091060",
      "localidad_censal_nombre": "Yatayti Calle",
      "municipio_id": "180217",
      "municipio_nombre": "Yataytí Calle",
      "nombre": "YATAYTI CALLE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0460572855636,
      "centroide_lon": -58.224984006822,
      "departamento_id": "18098",
      "departamento_nombre": "Mburucuyá",
      "id": "18098010000",
      "localidad_censal_id": "18098010",
      "localidad_censal_nombre": "Mburucuyá",
      "municipio_id": "180224",
      "municipio_nombre": "Mburucuyá",
      "nombre": "MBURUCUYA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.105919764542,
      "centroide_lon": -58.3424746747141,
      "departamento_id": "18105",
      "departamento_nombre": "Mercedes",
      "id": "18105010000",
      "localidad_censal_id": "18105010",
      "localidad_censal_nombre": "Felipe Yofré",
      "municipio_id": "180231",
      "municipio_nombre": "Felipe Yofré",
      "nombre": "FELIPE YOFRE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3763665588886,
      "centroide_lon": -58.1960231004583,
      "departamento_id": "18105",
      "departamento_nombre": "Mercedes",
      "id": "18105020000",
      "localidad_censal_id": "18105020",
      "localidad_censal_nombre": "Mariano I. Loza",
      "municipio_id": "180238",
      "municipio_nombre": "Mariano I. Loza",
      "nombre": "MARIANO I. LOZA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1833885579996,
      "centroide_lon": -58.0742364424806,
      "departamento_id": "18105",
      "departamento_nombre": "Mercedes",
      "id": "18105030000",
      "localidad_censal_id": "18105030",
      "localidad_censal_nombre": "Mercedes",
      "municipio_id": "180245",
      "municipio_nombre": "Mercedes",
      "nombre": "MERCEDES",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0439760349315,
      "centroide_lon": -57.8235193980386,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112010000",
      "localidad_censal_id": "18112010",
      "localidad_censal_nombre": "Colonia Libertad",
      "municipio_id": "180252",
      "municipio_nombre": "Colonia Libertad",
      "nombre": "COLONIA LIBERTAD",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0125285200771,
      "centroide_lon": -57.8591295180443,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112020000",
      "localidad_censal_id": "18112020",
      "localidad_censal_nombre": "Estación Libertad",
      "municipio_id": "180252",
      "municipio_nombre": "Colonia Libertad",
      "nombre": "ESTACION LIBERTAD",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4178964519869,
      "centroide_lon": -57.8560694385919,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112030000",
      "localidad_censal_id": "18112030",
      "localidad_censal_nombre": "Juan Pujol",
      "municipio_id": "180259",
      "municipio_nombre": "Juan Pujol",
      "nombre": "JUAN PUJOL",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6176804049267,
      "centroide_lon": -57.9628254585589,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112040000",
      "localidad_censal_id": "18112040",
      "localidad_censal_nombre": "Mocoretá",
      "municipio_id": "180266",
      "municipio_nombre": "Mocoretá",
      "nombre": "MOCORETA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2515527236356,
      "centroide_lon": -57.6388140007913,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112050000",
      "localidad_censal_id": "18112050",
      "localidad_censal_nombre": "Monte Caseros",
      "municipio_id": "180273",
      "municipio_nombre": "Monte Caseros",
      "nombre": "MONTE CASEROS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9084817138799,
      "centroide_lon": -57.9581770148793,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112060000",
      "localidad_censal_id": "18112060",
      "localidad_censal_nombre": "Parada Acuña",
      "municipio_id": "180252",
      "municipio_nombre": "Colonia Libertad",
      "nombre": "PARADA ACUÑA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3190400199696,
      "centroide_lon": -57.7289868202181,
      "departamento_id": "18112",
      "departamento_nombre": "Monte Caseros",
      "id": "18112070000",
      "localidad_censal_id": "18112070",
      "localidad_censal_nombre": "Parada Labougle",
      "municipio_id": "180273",
      "municipio_nombre": "Monte Caseros",
      "nombre": "PARADA LABOUGLE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8203735190576,
      "centroide_lon": -57.4296479379825,
      "departamento_id": "18119",
      "departamento_nombre": "Paso de los Libres",
      "id": "18119010000",
      "localidad_censal_id": "18119010",
      "localidad_censal_nombre": "Bonpland",
      "municipio_id": "180280",
      "municipio_nombre": "Bonpland",
      "nombre": "BONPLAND",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9053454011034,
      "centroide_lon": -57.5743693513377,
      "departamento_id": "18119",
      "departamento_nombre": "Paso de los Libres",
      "id": "18119020000",
      "localidad_censal_id": "18119020",
      "localidad_censal_nombre": "Parada Pucheta",
      "municipio_id": "180287",
      "municipio_nombre": "Parada Pucheta",
      "nombre": "PARADA PUCHETA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7116998596983,
      "centroide_lon": -57.0877441027999,
      "departamento_id": "18119",
      "departamento_nombre": "Paso de los Libres",
      "id": "18119030000",
      "localidad_censal_id": "18119030",
      "localidad_censal_nombre": "Paso de los Libres",
      "municipio_id": "180294",
      "municipio_nombre": "Paso de los Libres",
      "nombre": "PASO DE LOS LIBRES",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5043114402955,
      "centroide_lon": -56.9760306946844,
      "departamento_id": "18119",
      "departamento_nombre": "Paso de los Libres",
      "id": "18119040000",
      "localidad_censal_id": "18119040",
      "localidad_censal_nombre": "Tapebicuá",
      "municipio_id": "180301",
      "municipio_nombre": "Tapebicuá",
      "nombre": "TAPEBICUA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2553374256059,
      "centroide_lon": -58.6238010038908,
      "departamento_id": "18126",
      "departamento_nombre": "Saladas",
      "id": "18126010000",
      "localidad_censal_id": "18126010",
      "localidad_censal_nombre": "Saladas",
      "municipio_id": "180308",
      "municipio_nombre": "Saladas",
      "nombre": "SALADAS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1314603295453,
      "centroide_lon": -58.7668321022746,
      "departamento_id": "18126",
      "departamento_nombre": "Saladas",
      "id": "18126020000",
      "localidad_censal_id": "18126020",
      "localidad_censal_nombre": "San Lorenzo",
      "municipio_id": "180315",
      "municipio_nombre": "San Lorenzo",
      "nombre": "SAN LORENZO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4336721947021,
      "centroide_lon": -58.624327887603,
      "departamento_id": "18133",
      "departamento_nombre": "San Cosme",
      "id": "18133010000",
      "localidad_censal_id": "18133010",
      "localidad_censal_nombre": "Ingenio Primer Correntino",
      "municipio_id": "180336",
      "municipio_nombre": "Santa Ana",
      "nombre": "INGENIO PRIMER CORRENTINO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3150060127599,
      "centroide_lon": -58.5720142980631,
      "departamento_id": "18133",
      "departamento_nombre": "San Cosme",
      "id": "18133020000",
      "localidad_censal_id": "18133020",
      "localidad_censal_nombre": "Paso de la Patria",
      "municipio_id": "180322",
      "municipio_nombre": "Paso de la Patria",
      "nombre": "PASO DE LA PATRIA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3711801624716,
      "centroide_lon": -58.5115291113063,
      "departamento_id": "18133",
      "departamento_nombre": "San Cosme",
      "id": "18133030000",
      "localidad_censal_id": "18133030",
      "localidad_censal_nombre": "San Cosme",
      "municipio_id": "180329",
      "municipio_nombre": "San Cosme",
      "nombre": "SAN COSME",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.455091402543,
      "centroide_lon": -58.6530388459631,
      "departamento_id": "18133",
      "departamento_nombre": "San Cosme",
      "id": "18133040000",
      "localidad_censal_id": "18133040",
      "localidad_censal_nombre": "Santa Ana",
      "municipio_id": "180336",
      "municipio_nombre": "Santa Ana",
      "nombre": "SANTA ANA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5081078409756,
      "centroide_lon": -58.5554744159758,
      "departamento_id": "18140",
      "departamento_nombre": "San Luis del Palmar",
      "id": "18140010000",
      "localidad_censal_id": "18140010",
      "localidad_censal_nombre": "San Luis del Palmar",
      "municipio_id": "180350",
      "municipio_nombre": "San Luis del Palmar",
      "nombre": "SAN LUIS DEL PALMAR",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5373400329858,
      "centroide_lon": -57.1712310407172,
      "departamento_id": "18147",
      "departamento_nombre": "San Martín",
      "id": "18147010000",
      "localidad_censal_id": "18147010",
      "localidad_censal_nombre": "Colonia Carlos Pellegrini",
      "municipio_id": "180357",
      "municipio_nombre": "Colonia Carlos Pellegrini",
      "nombre": "COLONIA CARLOS PELLEGRINI",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3669865639555,
      "centroide_lon": -56.8292389472207,
      "departamento_id": "18147",
      "departamento_nombre": "San Martín",
      "id": "18147020000",
      "localidad_censal_id": "18147020",
      "localidad_censal_nombre": "Guaviraví",
      "municipio_id": "180364",
      "municipio_nombre": "Guaviraví",
      "nombre": "GUAVIRAVI",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1736584106926,
      "centroide_lon": -56.6450161726372,
      "departamento_id": "18147",
      "departamento_nombre": "San Martín",
      "id": "18147030000",
      "localidad_censal_id": "18147030",
      "localidad_censal_nombre": "La Cruz",
      "municipio_id": "180371",
      "municipio_nombre": "La Cruz",
      "nombre": "LA CRUZ",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4706323042238,
      "centroide_lon": -56.8158358918793,
      "departamento_id": "18147",
      "departamento_nombre": "San Martín",
      "id": "18147040000",
      "localidad_censal_id": "18147040",
      "localidad_censal_nombre": "Yapeyú",
      "municipio_id": "180378",
      "municipio_nombre": "Yapeyú",
      "nombre": "YAPEYU",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7684514067067,
      "centroide_lon": -57.274839083927,
      "departamento_id": "18154",
      "departamento_nombre": "San Miguel",
      "id": "18154010000",
      "localidad_censal_id": "18154010",
      "localidad_censal_nombre": "Loreto",
      "municipio_id": "180385",
      "municipio_nombre": "Loreto",
      "nombre": "LORETO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9947735055436,
      "centroide_lon": -57.5919539782996,
      "departamento_id": "18154",
      "departamento_nombre": "San Miguel",
      "id": "18154020000",
      "localidad_censal_id": "18154020",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "180392",
      "municipio_nombre": "San Miguel",
      "nombre": "SAN MIGUEL",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9549218176889,
      "centroide_lon": -58.5716489831846,
      "departamento_id": "18161",
      "departamento_nombre": "San Roque",
      "id": "18161010000",
      "localidad_censal_id": "18161010",
      "localidad_censal_nombre": "Chavarría",
      "municipio_id": "180413",
      "municipio_nombre": "Chavarría",
      "nombre": "CHAVARRIA",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5252148833089,
      "centroide_lon": -58.4869345494056,
      "departamento_id": "18161",
      "departamento_nombre": "San Roque",
      "id": "18161020000",
      "localidad_censal_id": "18161020",
      "localidad_censal_nombre": "Colonia Pando",
      "municipio_id": "180417",
      "municipio_nombre": "Colonia Pando",
      "nombre": "COLONIA PANDO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8418348631681,
      "centroide_lon": -58.8280463835778,
      "departamento_id": "18161",
      "departamento_nombre": "San Roque",
      "id": "18161030000",
      "localidad_censal_id": "18161030",
      "localidad_censal_nombre": "9 de Julio",
      "municipio_id": "180399",
      "municipio_nombre": "9 de Julio",
      "nombre": "9 DE JULIO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7505433489253,
      "centroide_lon": -58.6545368651224,
      "departamento_id": "18161",
      "departamento_nombre": "San Roque",
      "id": "18161040000",
      "localidad_censal_id": "18161040",
      "localidad_censal_nombre": "Pedro R. Fernández",
      "municipio_id": "180406",
      "municipio_nombre": "Pedro R. Fernández",
      "nombre": "PEDRO R. FERNANDEZ",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5732308799035,
      "centroide_lon": -58.7100567505645,
      "departamento_id": "18161",
      "departamento_nombre": "San Roque",
      "id": "18161050000",
      "localidad_censal_id": "18161050",
      "localidad_censal_nombre": "San Roque",
      "municipio_id": "180420",
      "municipio_nombre": "San Roque",
      "nombre": "SAN ROQUE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2260143622167,
      "centroide_lon": -55.7844092003668,
      "departamento_id": "18168",
      "departamento_nombre": "Santo Tomé",
      "id": "18168010000",
      "localidad_censal_id": "18168010",
      "localidad_censal_nombre": "José Rafael Gómez",
      "municipio_id": "180441",
      "municipio_nombre": "Jose Rafael Gomez",
      "nombre": "JOSE R. GOMEZ",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1729435857266,
      "centroide_lon": -55.6513324031087,
      "departamento_id": "18168",
      "departamento_nombre": "Santo Tomé",
      "id": "18168020000",
      "localidad_censal_id": "18168020",
      "localidad_censal_nombre": "Garruchos",
      "municipio_id": "180427",
      "municipio_nombre": "Garruchos",
      "nombre": "GARRUCHOS",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0455566790186,
      "centroide_lon": -56.0190197167242,
      "departamento_id": "18168",
      "departamento_nombre": "Santo Tomé",
      "id": "18168030000",
      "localidad_censal_id": "18168030",
      "localidad_censal_nombre": "Gobernador Igr. Valentín Virasoro",
      "municipio_id": "180434",
      "municipio_nombre": "Gobernador Ing. Valentín Virasoro",
      "nombre": "GOBERNADOR IGR.VALENTIN VIRASORO",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5511588178368,
      "centroide_lon": -56.0420862814163,
      "departamento_id": "18168",
      "departamento_nombre": "Santo Tomé",
      "id": "18168040000",
      "localidad_censal_id": "18168040",
      "localidad_censal_nombre": "Santo Tomé",
      "municipio_id": "180448",
      "municipio_nombre": "Santo Tomé",
      "nombre": "SANTO TOME",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0867528651818,
      "centroide_lon": -58.7879617250662,
      "departamento_id": "18175",
      "departamento_nombre": "Sauce",
      "id": "18175010000",
      "localidad_censal_id": "18175010",
      "localidad_censal_nombre": "Sauce",
      "municipio_id": "180455",
      "municipio_nombre": "Sauce",
      "nombre": "SAUCE",
      "provincia_id": "18",
      "provincia_nombre": "Corrientes"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.602273964006,
      "centroide_lon": -60.9492636999567,
      "departamento_id": "22007",
      "departamento_nombre": "Almirante Brown",
      "id": "22007010000",
      "localidad_censal_id": "22007010",
      "localidad_censal_nombre": "Concepción del Bermejo",
      "municipio_id": "220007",
      "municipio_nombre": "Concepción del Bermejo",
      "nombre": "CONCEPCION DEL BERMEJO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4082976307567,
      "centroide_lon": -61.4134271371597,
      "departamento_id": "22007",
      "departamento_nombre": "Almirante Brown",
      "id": "22007020000",
      "localidad_censal_id": "22007020",
      "localidad_censal_nombre": "Los Frentones",
      "municipio_id": "220014",
      "municipio_nombre": "Los Frentones",
      "nombre": "LOS FRENTONES",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5063974406178,
      "centroide_lon": -61.1764901971888,
      "departamento_id": "22007",
      "departamento_nombre": "Almirante Brown",
      "id": "22007030000",
      "localidad_censal_id": "22007030",
      "localidad_censal_nombre": "Pampa del Infierno",
      "municipio_id": "220021",
      "municipio_nombre": "Pampa del Infierno",
      "nombre": "PAMPA DEL INFIERNO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3078818113872,
      "centroide_lon": -61.6540440649869,
      "departamento_id": "22007",
      "departamento_nombre": "Almirante Brown",
      "id": "22007040000",
      "localidad_censal_id": "22007040",
      "localidad_censal_nombre": "Río Muerto",
      "municipio_id": "220014",
      "municipio_nombre": "Los Frentones",
      "nombre": "RIO MUERTO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6156598937138,
      "centroide_lon": -63.2692964997065,
      "departamento_id": "22007",
      "departamento_nombre": "Almirante Brown",
      "id": "22007050000",
      "localidad_censal_id": "22007050",
      "localidad_censal_nombre": "Taco Pozo",
      "municipio_id": "220028",
      "municipio_nombre": "Taco Pozo",
      "nombre": "TACO POZO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9349378591637,
      "centroide_lon": -58.6612848328678,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014010000",
      "localidad_censal_id": "22014010",
      "localidad_censal_nombre": "General Vedia",
      "municipio_id": "220035",
      "municipio_nombre": "General Vedia",
      "nombre": "GENERAL VEDIA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2927852739485,
      "centroide_lon": -58.6178377359072,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014020000",
      "localidad_censal_id": "22014020",
      "localidad_censal_nombre": "Isla del Cerrito",
      "municipio_id": "220042",
      "municipio_nombre": "Isla del Cerrito",
      "nombre": "ISLA DEL CERRITO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.0379596283785,
      "centroide_lon": -58.7069377183708,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014030000",
      "localidad_censal_id": "22014030",
      "localidad_censal_nombre": "La Leonesa",
      "municipio_id": "220049",
      "municipio_nombre": "La Leonesa",
      "nombre": "LA LEONESA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.0478975207771,
      "centroide_lon": -58.6795739267563,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014040000",
      "localidad_censal_id": "22014040",
      "localidad_censal_nombre": "Las Palmas",
      "municipio_id": "220056",
      "municipio_nombre": "Las Palmas",
      "nombre": "LAS PALMAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9073753724695,
      "centroide_lon": -58.5428316633796,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014050000",
      "localidad_censal_id": "22014050",
      "localidad_censal_nombre": "Puerto Bermejo Nuevo",
      "municipio_id": "220063",
      "municipio_nombre": "Puerto Bermejo",
      "nombre": "PUERTO BERMEJO NUEVO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9285481385672,
      "centroide_lon": -58.5063036590574,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014060000",
      "localidad_censal_id": "22014060",
      "localidad_censal_nombre": "Puerto Bermejo Viejo",
      "municipio_id": "220063",
      "municipio_nombre": "Puerto Bermejo",
      "nombre": "PUERTO BERMEJO VIEJO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.661480092829,
      "centroide_lon": -58.6355818176747,
      "departamento_id": "22014",
      "departamento_nombre": "Bermejo",
      "id": "22014070000",
      "localidad_censal_id": "22014070",
      "localidad_censal_nombre": "Puerto Eva Perón",
      "municipio_id": "220070",
      "municipio_nombre": "Puerto Eva Perón",
      "nombre": "PUERTO EVA PERON",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7916058929378,
      "centroide_lon": -60.4421462814095,
      "departamento_id": "22021",
      "departamento_nombre": "Comandante Fernández",
      "id": "22021010000",
      "localidad_censal_id": "22021010",
      "localidad_censal_nombre": "Presidencia Roque Sáenz Peña",
      "municipio_id": "220077",
      "municipio_nombre": "Presidencia Roque Sáenz Peña",
      "nombre": "PRESIDENCIA ROQUE SAENZ PENA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2200458502784,
      "centroide_lon": -61.1915854495204,
      "departamento_id": "22028",
      "departamento_nombre": "Chacabuco",
      "id": "22028010000",
      "localidad_censal_id": "22028010",
      "localidad_censal_nombre": "Charata",
      "municipio_id": "220084",
      "municipio_nombre": "Charata",
      "nombre": "CHARATA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4896487904756,
      "centroide_lon": -61.6738771708385,
      "departamento_id": "22036",
      "departamento_nombre": "12 de Octubre",
      "id": "22036010000",
      "localidad_censal_id": "22036010",
      "localidad_censal_nombre": "Gancedo",
      "municipio_id": "220091",
      "municipio_nombre": "Gancedo",
      "nombre": "GANCEDO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4231812966856,
      "centroide_lon": -61.4765845551068,
      "departamento_id": "22036",
      "departamento_nombre": "12 de Octubre",
      "id": "22036020000",
      "localidad_censal_id": "22036020",
      "localidad_censal_nombre": "General Capdevila",
      "municipio_id": "220098",
      "municipio_nombre": "General Capdevila",
      "nombre": "GENERAL CAPDEVILA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3252979421708,
      "centroide_lon": -61.282210419911,
      "departamento_id": "22036",
      "departamento_nombre": "12 de Octubre",
      "id": "22036030000",
      "localidad_censal_id": "22036030",
      "localidad_censal_nombre": "General Pinedo",
      "municipio_id": "220105",
      "municipio_nombre": "General Pinedo",
      "nombre": "GENERAL PINEDO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4312285767758,
      "centroide_lon": -61.0177584158265,
      "departamento_id": "22036",
      "departamento_nombre": "12 de Octubre",
      "id": "22036040000",
      "localidad_censal_id": "22036040",
      "localidad_censal_nombre": "Mesón de Fierro",
      "municipio_id": "220105",
      "municipio_nombre": "General Pinedo",
      "nombre": "MESON DE FIERRO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3957449255406,
      "centroide_lon": -61.1029787390413,
      "departamento_id": "22036",
      "departamento_nombre": "12 de Octubre",
      "id": "22036050000",
      "localidad_censal_id": "22036050",
      "localidad_censal_nombre": "Pampa Landriel",
      "municipio_id": "220105",
      "municipio_nombre": "General Pinedo",
      "nombre": "PAMPA LANDRIEL",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.610539212014,
      "centroide_lon": -61.344844244037,
      "departamento_id": "22039",
      "departamento_nombre": "2 de Abril",
      "id": "22039010000",
      "localidad_censal_id": "22039010",
      "localidad_censal_nombre": "Hermoso Campo",
      "municipio_id": "220112",
      "municipio_nombre": "Hermoso Campo",
      "nombre": "HERMOSO CAMPO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4876216159572,
      "centroide_lon": -61.3238818419592,
      "departamento_id": "22039",
      "departamento_nombre": "2 de Abril",
      "id": "22039020000",
      "localidad_censal_id": "22039020",
      "localidad_censal_nombre": "Itín",
      "municipio_id": "220112",
      "municipio_nombre": "Hermoso Campo",
      "nombre": "ITIN",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9175852858656,
      "centroide_lon": -61.3995694680726,
      "departamento_id": "22043",
      "departamento_nombre": "Fray Justo Santa María de Oro",
      "id": "22043010000",
      "localidad_censal_id": "22043010",
      "localidad_censal_nombre": "Chorotis",
      "municipio_id": "220119",
      "municipio_nombre": "Chorotis",
      "nombre": "CHOROTIS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8356008575455,
      "centroide_lon": -61.1361742191871,
      "departamento_id": "22043",
      "departamento_nombre": "Fray Justo Santa María de Oro",
      "id": "22043020000",
      "localidad_censal_id": "22043020",
      "localidad_censal_nombre": "Santa Sylvina",
      "municipio_id": "220126",
      "municipio_nombre": "Santa Sylvina",
      "nombre": "SANTA SYLVINA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8181539157701,
      "centroide_lon": -61.3815664200405,
      "departamento_id": "22043",
      "departamento_nombre": "Fray Justo Santa María de Oro",
      "id": "22043030000",
      "localidad_censal_id": "22043030",
      "localidad_censal_nombre": "Venados Grandes",
      "municipio_id": "220119",
      "municipio_nombre": "Chorotis",
      "nombre": "VENADOS GRANDES",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9556760087163,
      "centroide_lon": -60.9707574059512,
      "departamento_id": "22049",
      "departamento_nombre": "General Belgrano",
      "id": "22049010000",
      "localidad_censal_id": "22049010",
      "localidad_censal_nombre": "Corzuela",
      "municipio_id": "220113",
      "municipio_nombre": "Corzuela",
      "nombre": "CORZUELA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1073248470025,
      "centroide_lon": -59.4475325100431,
      "departamento_id": "22056",
      "departamento_nombre": "General Donovan",
      "id": "22056010000",
      "localidad_censal_id": "22056010",
      "localidad_censal_nombre": "La Escondida",
      "municipio_id": "220140",
      "municipio_nombre": "La Escondida",
      "nombre": "LA ESCONDIDA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1297934337807,
      "centroide_lon": -59.3774619894492,
      "departamento_id": "22056",
      "departamento_nombre": "General Donovan",
      "id": "22056020000",
      "localidad_censal_id": "22056020",
      "localidad_censal_nombre": "La Verde",
      "municipio_id": "220154",
      "municipio_nombre": "La Verde",
      "nombre": "LA VERDE",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1587903465778,
      "centroide_lon": -59.3903975007013,
      "departamento_id": "22056",
      "departamento_nombre": "General Donovan",
      "id": "22056030000",
      "localidad_censal_id": "22056030",
      "localidad_censal_nombre": "Lapachito",
      "municipio_id": "220147",
      "municipio_nombre": "Lapachito",
      "nombre": "LAPACHITO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.211618544441,
      "centroide_lon": -59.2883662132688,
      "departamento_id": "22056",
      "departamento_nombre": "General Donovan",
      "id": "22056040000",
      "localidad_censal_id": "22056040",
      "localidad_censal_nombre": "Makallé",
      "municipio_id": "220161",
      "municipio_nombre": "Makallé",
      "nombre": "MAKALLE",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.4176698636006,
      "centroide_lon": -60.4139677023547,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063010000",
      "localidad_censal_id": "22063010",
      "localidad_censal_nombre": "El Espinillo",
      "municipio_id": "220203",
      "municipio_nombre": "Villa Río Bermejito",
      "nombre": "EL ESPINILLO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.579149375301,
      "centroide_lon": -61.5461702165927,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063020000",
      "localidad_censal_id": "22063020",
      "localidad_censal_nombre": "El Sauzal",
      "municipio_id": "220168",
      "municipio_nombre": "El Sauzalito",
      "nombre": "EL SAUZAL",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4345499393074,
      "centroide_lon": -61.6813716975806,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063030000",
      "localidad_censal_id": "22063030",
      "localidad_censal_nombre": "El Sauzalito",
      "municipio_id": "220168",
      "municipio_nombre": "El Sauzalito",
      "nombre": "EL SAUZALITO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7056308542578,
      "centroide_lon": -60.2037478681132,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063040000",
      "localidad_censal_id": "22063040",
      "localidad_censal_nombre": "Fortín Lavalle",
      "municipio_id": "220203",
      "municipio_nombre": "Villa Río Bermejito",
      "nombre": "FORTIN LAVALLE",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1560600566745,
      "centroide_lon": -61.8424104268078,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063050000",
      "localidad_censal_id": "22063050",
      "localidad_censal_nombre": "Fuerte Esperanza",
      "municipio_id": "220175",
      "municipio_nombre": "Fuerte Esperanza",
      "nombre": "FUERTE ESPERANZA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9504150377594,
      "centroide_lon": -60.6243211030425,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063060000",
      "localidad_censal_id": "22063060",
      "localidad_censal_nombre": "Juan José Castelli",
      "municipio_id": "220182",
      "municipio_nombre": "Juan José Castelli",
      "nombre": "JUAN JOSE CASTELLI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6489334968628,
      "centroide_lon": -60.9300401901344,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063070000",
      "localidad_censal_id": "22063070",
      "localidad_censal_nombre": "Miraflores",
      "municipio_id": "220189",
      "municipio_nombre": "Miraflores",
      "nombre": "MIRAFLORES",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9334418837191,
      "centroide_lon": -61.4846998398146,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063080000",
      "localidad_censal_id": "22063080",
      "localidad_censal_nombre": "Nueva Pompeya",
      "municipio_id": "220169",
      "municipio_nombre": "Misión Nueva Pompeya",
      "nombre": "NUEVA POMPEYA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6424413894079,
      "centroide_lon": -60.2629438807651,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063100000",
      "localidad_censal_id": "22063100",
      "localidad_censal_nombre": "Villa Río Bermejito",
      "municipio_id": "220203",
      "municipio_nombre": "Villa Río Bermejito",
      "nombre": "VILLA RIO BERMEJITO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6913977720534,
      "centroide_lon": -61.4304454802334,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063110000",
      "localidad_censal_id": "22063110",
      "localidad_censal_nombre": "Wichi",
      "municipio_id": "220168",
      "municipio_nombre": "El Sauzalito",
      "nombre": "WICHI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0673623556601,
      "centroide_lon": -60.5633873019222,
      "departamento_id": "22063",
      "departamento_nombre": "General Güemes",
      "id": "22063120000",
      "localidad_censal_id": "22063120",
      "localidad_censal_nombre": "Zaparinqui",
      "municipio_id": "220182",
      "municipio_nombre": "Juan José Castelli",
      "nombre": "ZAPARINQUI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6904269113097,
      "centroide_lon": -60.7309487066584,
      "departamento_id": "22070",
      "departamento_nombre": "Independencia",
      "id": "22070010000",
      "localidad_censal_id": "22070010",
      "localidad_censal_nombre": "Avia Terai",
      "municipio_id": "220210",
      "municipio_nombre": "Avia Terai",
      "nombre": "AVIA TERAI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8038644067827,
      "centroide_lon": -60.8423032131182,
      "departamento_id": "22070",
      "departamento_nombre": "Independencia",
      "id": "22070020000",
      "localidad_censal_id": "22070020",
      "localidad_censal_nombre": "Campo Largo",
      "municipio_id": "220217",
      "municipio_nombre": "Campo Largo",
      "nombre": "CAMPO LARGO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8891042551114,
      "centroide_lon": -60.908152214423,
      "departamento_id": "22070",
      "departamento_nombre": "Independencia",
      "id": "22070030000",
      "localidad_censal_id": "22070030",
      "localidad_censal_nombre": "Fortín Las Chuñas",
      "municipio_id": "220217",
      "municipio_nombre": "Campo Largo",
      "nombre": "FORTIN LAS CHUÑAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7314687114399,
      "centroide_lon": -60.6190285151237,
      "departamento_id": "22070",
      "departamento_nombre": "Independencia",
      "id": "22070040000",
      "localidad_censal_id": "22070040",
      "localidad_censal_nombre": "Napenay",
      "municipio_id": "220224",
      "municipio_nombre": "Napenay",
      "nombre": "NAPENAY",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2759309408353,
      "centroide_lon": -59.1523781564389,
      "departamento_id": "22077",
      "departamento_nombre": "Libertad",
      "id": "22077010000",
      "localidad_censal_id": "22077010",
      "localidad_censal_nombre": "Colonia Popular",
      "municipio_id": "220231",
      "municipio_nombre": "Colonia Popular",
      "nombre": "COLONIA POPULAR",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4128679939929,
      "centroide_lon": -59.4201579706051,
      "departamento_id": "22077",
      "departamento_nombre": "Libertad",
      "id": "22077020000",
      "localidad_censal_id": "22077020",
      "localidad_censal_nombre": "Estación General Obligado",
      "municipio_id": "220254",
      "municipio_nombre": "Puerto Tirol",
      "nombre": "ESTACION GENERAL OBLIGADO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2572195505018,
      "centroide_lon": -59.2340486127892,
      "departamento_id": "22077",
      "departamento_nombre": "Libertad",
      "id": "22077030000",
      "localidad_censal_id": "22077030",
      "localidad_censal_nombre": "Laguna Blanca",
      "municipio_id": "220238",
      "municipio_nombre": "Laguna Blanca",
      "nombre": "LAGUNA BLANCA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3745125614532,
      "centroide_lon": -59.0953159917862,
      "departamento_id": "22077",
      "departamento_nombre": "Libertad",
      "id": "22077040000",
      "localidad_censal_id": "22077040",
      "localidad_censal_nombre": "Puerto Tirol",
      "municipio_id": "220254",
      "municipio_nombre": "Puerto Tirol",
      "nombre": "PUERTO TIROL",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5815849580294,
      "centroide_lon": -59.6329803967089,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084010000",
      "localidad_censal_id": "22084010",
      "localidad_censal_nombre": "Ciervo Petiso",
      "municipio_id": "220252",
      "municipio_nombre": "Ciervo Petiso",
      "nombre": "CIERVO PETISO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5340730622708,
      "centroide_lon": -59.334711358946,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084020000",
      "localidad_censal_id": "22084020",
      "localidad_censal_nombre": "General José de San Martín",
      "municipio_id": "220259",
      "municipio_nombre": "Gral. San Martengeneral San Martín",
      "nombre": "GENERAL JOSE DE SAN MARTIN",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8374120457599,
      "centroide_lon": -59.0641175796047,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084030000",
      "localidad_censal_id": "22084030",
      "localidad_censal_nombre": "La Eduvigis",
      "municipio_id": "220266",
      "municipio_nombre": "La Eduvigis",
      "nombre": "LA EDUVIGIS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4967013914467,
      "centroide_lon": -59.6799372880349,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084040000",
      "localidad_censal_id": "22084040",
      "localidad_censal_nombre": "Laguna Limpia",
      "municipio_id": "220273",
      "municipio_nombre": "Laguna Limpia",
      "nombre": "LAGUNA LIMPIA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.702247857191,
      "centroide_lon": -59.1237850343206,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084050000",
      "localidad_censal_id": "22084050",
      "localidad_censal_nombre": "Pampa Almirón",
      "municipio_id": "220280",
      "municipio_nombre": "Pampa Almirón",
      "nombre": "PAMPA ALMIRON",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.050714758193,
      "centroide_lon": -59.9412241077467,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084060000",
      "localidad_censal_id": "22084060",
      "localidad_censal_nombre": "Pampa del Indio",
      "municipio_id": "220287",
      "municipio_nombre": "Pampa del Indio",
      "nombre": "PAMPA DEL INDIO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1402007626997,
      "centroide_lon": -59.5968452905131,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084070000",
      "localidad_censal_id": "22084070",
      "localidad_censal_nombre": "Presidencia Roca",
      "municipio_id": "220294",
      "municipio_nombre": "Presidencia Roca",
      "nombre": "PRESIDENCIA ROCA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8044755779716,
      "centroide_lon": -58.9585454452623,
      "departamento_id": "22084",
      "departamento_nombre": "Libertador General San Martín",
      "id": "22084080000",
      "localidad_censal_id": "22084080",
      "localidad_censal_nombre": "Selvas del Río de Oro",
      "municipio_id": "220266",
      "municipio_nombre": "La Eduvigis",
      "nombre": "SELVAS DEL RIO DE ORO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3378349285309,
      "centroide_lon": -60.4299349548752,
      "departamento_id": "22091",
      "departamento_nombre": "Maipú",
      "id": "22091010000",
      "localidad_censal_id": "22091010",
      "localidad_censal_nombre": "Tres Isletas",
      "municipio_id": "220301",
      "municipio_nombre": "Tres Isletas",
      "nombre": "TRES ISLETAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.682571155615,
      "centroide_lon": -60.9091956314644,
      "departamento_id": "22098",
      "departamento_nombre": "Mayor Luis J. Fontana",
      "id": "22098010000",
      "localidad_censal_id": "22098010",
      "localidad_censal_nombre": "Coronel Du Graty",
      "municipio_id": "220308",
      "municipio_nombre": "Coronel Du Graty",
      "nombre": "CORONEL DU GRATY",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5587251493486,
      "centroide_lon": -60.5259907543736,
      "departamento_id": "22098",
      "departamento_nombre": "Mayor Luis J. Fontana",
      "id": "22098020000",
      "localidad_censal_id": "22098020",
      "localidad_censal_nombre": "Enrique Urien",
      "municipio_id": "220315",
      "municipio_nombre": "Enrique Urien",
      "nombre": "ENRIQUE URIEN",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5788592432947,
      "centroide_lon": -60.7141120066309,
      "departamento_id": "22098",
      "departamento_nombre": "Mayor Luis J. Fontana",
      "id": "22098030000",
      "localidad_censal_id": "22098030",
      "localidad_censal_nombre": "Villa Angela",
      "municipio_id": "220322",
      "municipio_nombre": "Villa Ángela",
      "nombre": "VILLA ANGELA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0885167236059,
      "centroide_lon": -61.0836993088858,
      "departamento_id": "22105",
      "departamento_nombre": "9 de Julio",
      "id": "22105010000",
      "localidad_censal_id": "22105010",
      "localidad_censal_nombre": "Las Breñas",
      "municipio_id": "220329",
      "municipio_nombre": "Las Breñas",
      "nombre": "LAS BREÑAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1781993384686,
      "centroide_lon": -60.6315741848516,
      "departamento_id": "22112",
      "departamento_nombre": "O'Higgins",
      "id": "22112010000",
      "localidad_censal_id": "22112010",
      "localidad_censal_nombre": "La Clotilde",
      "municipio_id": "220336",
      "municipio_nombre": "La Clotilde",
      "nombre": "LA CLOTILDE",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1157899755692,
      "centroide_lon": -60.5898941741226,
      "departamento_id": "22112",
      "departamento_nombre": "O'Higgins",
      "id": "22112020000",
      "localidad_censal_id": "22112020",
      "localidad_censal_nombre": "La Tigra",
      "municipio_id": "220343",
      "municipio_nombre": "La Tigra",
      "nombre": "LA TIGRA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2904384132605,
      "centroide_lon": -60.7149736332962,
      "departamento_id": "22112",
      "departamento_nombre": "O'Higgins",
      "id": "22112030000",
      "localidad_censal_id": "22112030",
      "localidad_censal_nombre": "San Bernardo",
      "municipio_id": "220350",
      "municipio_nombre": "San Bernardo",
      "nombre": "SAN BERNARDO",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0029714359347,
      "centroide_lon": -59.847600385659,
      "departamento_id": "22119",
      "departamento_nombre": "Presidencia de la Plaza",
      "id": "22119010000",
      "localidad_censal_id": "22119010",
      "localidad_censal_nombre": "Presidencia de la Plaza",
      "municipio_id": "220357",
      "municipio_nombre": "Presidencia de la Plaza",
      "nombre": "PRESIDENCIA DE LA PLAZA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4480426212168,
      "centroide_lon": -58.8551013275047,
      "departamento_id": "22126",
      "departamento_nombre": "1° de Mayo",
      "id": "22126010000",
      "localidad_censal_id": "22126010",
      "localidad_censal_nombre": "Barrio de los Pescadores",
      "municipio_id": "220364",
      "municipio_nombre": "Colonia Benitez",
      "nombre": "BARRIO DE LOS PESCADORES",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3305884379906,
      "centroide_lon": -58.9450102746869,
      "departamento_id": "22126",
      "departamento_nombre": "1° de Mayo",
      "id": "22126020000",
      "localidad_censal_id": "22126020",
      "localidad_censal_nombre": "Colonia Benítez",
      "municipio_id": "220364",
      "municipio_nombre": "Colonia Benitez",
      "nombre": "COLONIA BENITEZ",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2616473234151,
      "centroide_lon": -58.9741473116095,
      "departamento_id": "22126",
      "departamento_nombre": "1° de Mayo",
      "id": "22126030000",
      "localidad_censal_id": "22126030",
      "localidad_censal_nombre": "Margarita Belén",
      "municipio_id": "220371",
      "municipio_nombre": "Margarita Belén",
      "nombre": "MARGARITA BELEN",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8732071732978,
      "centroide_lon": -60.2185241226399,
      "departamento_id": "22133",
      "departamento_nombre": "Quitilipi",
      "id": "22133010000",
      "localidad_censal_id": "22133010",
      "localidad_censal_nombre": "Quitilipi",
      "municipio_id": "220378",
      "municipio_nombre": "Quitilipi",
      "nombre": "QUITILIPI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4551186226266,
      "centroide_lon": -60.1646165913523,
      "departamento_id": "22133",
      "departamento_nombre": "Quitilipi",
      "id": "22133020000",
      "localidad_censal_id": "22133020",
      "localidad_censal_nombre": "Villa El Palmar",
      "municipio_id": "220378",
      "municipio_nombre": "Quitilipi",
      "nombre": "VILLA EL PALMAR",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4877739289761,
      "centroide_lon": -58.9327416886365,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140010000",
      "localidad_censal_id": "22140010",
      "localidad_censal_nombre": "Barranqueras",
      "municipio_id": "220385",
      "municipio_nombre": "Barranqueras",
      "nombre": "BARRANQUERAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8868655966917,
      "centroide_lon": -59.2791003619491,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140020000",
      "localidad_censal_id": "22140020",
      "localidad_censal_nombre": "Basail",
      "municipio_id": "220392",
      "municipio_nombre": "Basail",
      "nombre": "BASAIL",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5620449432874,
      "centroide_lon": -59.3096911774268,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140030000",
      "localidad_censal_id": "22140030",
      "localidad_censal_nombre": "Colonia Baranda",
      "municipio_id": "220413",
      "municipio_nombre": "Resistencia",
      "nombre": "COLONIA BARANDA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4167127425858,
      "centroide_lon": -59.04393778912,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140040000",
      "localidad_censal_id": "22140040",
      "localidad_censal_nombre": "Fontana",
      "municipio_id": "220399",
      "municipio_nombre": "Fontana",
      "nombre": "FONTANA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.5106090846354,
      "centroide_lon": -58.938994465923,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140050000",
      "localidad_censal_id": "22140050",
      "localidad_censal_nombre": "Puerto Vilelas",
      "municipio_id": "220406",
      "municipio_nombre": "Puerto Vilelas",
      "nombre": "PUERTO VILELAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4521194584549,
      "centroide_lon": -58.9876174408016,
      "departamento_id": "22140",
      "departamento_nombre": "San Fernando",
      "id": "22140060000",
      "localidad_censal_id": "22140060",
      "localidad_censal_nombre": "Resistencia",
      "municipio_id": "220413",
      "municipio_nombre": "Resistencia",
      "nombre": "RESISTENCIA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5222254234698,
      "centroide_lon": -60.3941746057505,
      "departamento_id": "22147",
      "departamento_nombre": "San Lorenzo",
      "id": "22147010000",
      "localidad_censal_id": "22147010",
      "localidad_censal_nombre": "Samuhú",
      "municipio_id": "220420",
      "municipio_nombre": "Samuhú",
      "nombre": "SAMUHU",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2895492409257,
      "centroide_lon": -60.4155962080604,
      "departamento_id": "22147",
      "departamento_nombre": "San Lorenzo",
      "id": "22147020000",
      "localidad_censal_id": "22147020",
      "localidad_censal_nombre": "Villa Berthet",
      "municipio_id": "220427",
      "municipio_nombre": "Villa Berthet",
      "nombre": "VILLA BERTHET",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8050732181614,
      "centroide_lon": -59.5596523744138,
      "departamento_id": "22154",
      "departamento_nombre": "Sargento Cabral",
      "id": "22154010000",
      "localidad_censal_id": "22154010",
      "localidad_censal_nombre": "Capitán Solari",
      "municipio_id": "220434",
      "municipio_nombre": "Capitán Solari",
      "nombre": "CAPITAN SOLARI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9324550960675,
      "centroide_lon": -59.5204851339372,
      "departamento_id": "22154",
      "departamento_nombre": "Sargento Cabral",
      "id": "22154020000",
      "localidad_censal_id": "22154020",
      "localidad_censal_nombre": "Colonia Elisa",
      "municipio_id": "220441",
      "municipio_nombre": "Colonias Elisa",
      "nombre": "COLONIA ELISA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7000971775462,
      "centroide_lon": -59.6277421824927,
      "departamento_id": "22154",
      "departamento_nombre": "Sargento Cabral",
      "id": "22154030000",
      "localidad_censal_id": "22154030",
      "localidad_censal_nombre": "Colonias Unidas",
      "municipio_id": "220448",
      "municipio_nombre": "Colonias Unidas",
      "nombre": "COLONIAS UNIDAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0032121063146,
      "centroide_lon": -59.4825136520473,
      "departamento_id": "22154",
      "departamento_nombre": "Sargento Cabral",
      "id": "22154040000",
      "localidad_censal_id": "22154040",
      "localidad_censal_nombre": "Ingeniero Barbet",
      "municipio_id": "220441",
      "municipio_nombre": "Colonias Elisa",
      "nombre": "INGENIERO BARBET",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6193460346699,
      "centroide_lon": -59.8042763819042,
      "departamento_id": "22154",
      "departamento_nombre": "Sargento Cabral",
      "id": "22154050000",
      "localidad_censal_id": "22154050",
      "localidad_censal_nombre": "Las Garcitas",
      "municipio_id": "220455",
      "municipio_nombre": "Las Garcitas",
      "nombre": "LAS GARCITAS",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6553304709616,
      "centroide_lon": -59.8624460245382,
      "departamento_id": "22161",
      "departamento_nombre": "Tapenagá",
      "id": "22161010000",
      "localidad_censal_id": "22161010",
      "localidad_censal_nombre": "Charadai",
      "municipio_id": "220469",
      "municipio_nombre": "Charadai",
      "nombre": "CHARADAI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5303116601138,
      "centroide_lon": -59.5765171513905,
      "departamento_id": "22161",
      "departamento_nombre": "Tapenagá",
      "id": "22161020000",
      "localidad_censal_id": "22161020",
      "localidad_censal_nombre": "Cote Lai",
      "municipio_id": "220462",
      "municipio_nombre": "Cote Lai",
      "nombre": "COTE LAI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5077849029352,
      "centroide_lon": -60.1635277537413,
      "departamento_id": "22161",
      "departamento_nombre": "Tapenagá",
      "id": "22161030000",
      "localidad_censal_id": "22161030",
      "localidad_censal_nombre": "Haumonia",
      "municipio_id": "220469",
      "municipio_nombre": "Charadai",
      "nombre": "HAUMONIA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5427589336639,
      "centroide_lon": -59.9578541039413,
      "departamento_id": "22161",
      "departamento_nombre": "Tapenagá",
      "id": "22161040000",
      "localidad_censal_id": "22161040",
      "localidad_censal_nombre": "Horquilla",
      "municipio_id": "220469",
      "municipio_nombre": "Charadai",
      "nombre": "HORQUILLA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8738009106984,
      "centroide_lon": -59.939569589112,
      "departamento_id": "22161",
      "departamento_nombre": "Tapenagá",
      "id": "22161050000",
      "localidad_censal_id": "22161050",
      "localidad_censal_nombre": "La Sabana",
      "municipio_id": "220469",
      "municipio_nombre": "Charadai",
      "nombre": "LA SABANA",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9583436350996,
      "centroide_lon": -60.1902370459592,
      "departamento_id": "22168",
      "departamento_nombre": "25 de Mayo",
      "id": "22168010000",
      "localidad_censal_id": "22168010",
      "localidad_censal_nombre": "Colonia Aborigen",
      "municipio_id": "220476",
      "municipio_nombre": "Machagai",
      "nombre": "COLONIA ABORIGEN",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9287335504143,
      "centroide_lon": -60.0477196130638,
      "departamento_id": "22168",
      "departamento_nombre": "25 de Mayo",
      "id": "22168020000",
      "localidad_censal_id": "22168020",
      "localidad_censal_nombre": "Machagai",
      "municipio_id": "220476",
      "municipio_nombre": "Machagai",
      "nombre": "MACHAGAI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9032617040064,
      "centroide_lon": -60.1173839257014,
      "departamento_id": "22168",
      "departamento_nombre": "25 de Mayo",
      "id": "22168030000",
      "localidad_censal_id": "22168030",
      "localidad_censal_nombre": "Napalpí",
      "municipio_id": "220476",
      "municipio_nombre": "Machagai",
      "nombre": "NAPALPI",
      "provincia_id": "22",
      "provincia_nombre": "Chaco"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.0119886969656,
      "centroide_lon": -65.3064500926092,
      "departamento_id": "26007",
      "departamento_nombre": "Biedma",
      "id": "26007010000",
      "localidad_censal_id": "26007010",
      "localidad_censal_nombre": "Arroyo Verde",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARROYO VERDE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.7550996783449,
      "centroide_lon": -65.0422298178163,
      "departamento_id": "26007",
      "departamento_nombre": "Biedma",
      "id": "26007020000",
      "localidad_censal_id": "26007020",
      "localidad_censal_nombre": "Puerto Madryn",
      "municipio_id": "260007",
      "municipio_nombre": "Puerto Madryn",
      "nombre": "PUERTO MADRYN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.5736171931039,
      "centroide_lon": -64.2836893467339,
      "departamento_id": "26007",
      "departamento_nombre": "Biedma",
      "id": "26007030000",
      "localidad_censal_id": "26007030",
      "localidad_censal_nombre": "Puerto Pirámides",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO PIRAMIDE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.811590013144,
      "centroide_lon": -65.051417803805,
      "departamento_id": "26007",
      "departamento_nombre": "Biedma",
      "id": "26007040000",
      "localidad_censal_id": "26007040",
      "localidad_censal_nombre": "Quintas El Mirador",
      "municipio_id": "260007",
      "municipio_nombre": "Puerto Madryn",
      "nombre": "QUINTA EL MIRADOR",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.6456158957863,
      "centroide_lon": -65.0647229672572,
      "departamento_id": "26007",
      "departamento_nombre": "Biedma",
      "id": "26007050000",
      "localidad_censal_id": "26007050",
      "localidad_censal_nombre": "Reserva Area Protegida El Doradillo",
      "municipio_id": "260007",
      "municipio_nombre": "Puerto Madryn",
      "nombre": "RESERVA AREA PROTEGIDA EL DORADILLO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.0687961260085,
      "centroide_lon": -71.2158343137726,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014010000",
      "localidad_censal_id": "26014010",
      "localidad_censal_nombre": "Buenos Aires Chico",
      "municipio_id": "260028",
      "municipio_nombre": "El Maitén",
      "nombre": "BUENOS AIRES CHICO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.5105313648355,
      "centroide_lon": -71.4351548288659,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014020000",
      "localidad_censal_id": "26014020",
      "localidad_censal_nombre": "Cholila",
      "municipio_id": "260014",
      "municipio_nombre": "Cholila",
      "nombre": "CHOLILA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.6016255836041,
      "centroide_lon": -70.4575737273538,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014025000",
      "localidad_censal_id": "26014025",
      "localidad_censal_nombre": "Costa del Chubut",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COSTA DE CHUBUT",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.1766611028137,
      "centroide_lon": -70.6626188429181,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014030000",
      "localidad_censal_id": "26014030",
      "localidad_censal_nombre": "Cushamen Centro",
      "municipio_id": "268014",
      "municipio_nombre": "Cushamen",
      "nombre": "CUSHAMEN CENTRO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.0679666637038,
      "centroide_lon": -71.5206708370904,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014040000",
      "localidad_censal_id": "26014040",
      "localidad_censal_nombre": "El Hoyo",
      "municipio_id": "260021",
      "municipio_nombre": "El Hoyo",
      "nombre": "EL HOYO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.0542854358373,
      "centroide_lon": -71.1673153308386,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014050000",
      "localidad_censal_id": "26014050",
      "localidad_censal_nombre": "El Maitén",
      "municipio_id": "260028",
      "municipio_nombre": "El Maitén",
      "nombre": "EL MAITEN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.2329247123472,
      "centroide_lon": -71.3695066579513,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014060000",
      "localidad_censal_id": "26014060",
      "localidad_censal_nombre": "Epuyén",
      "municipio_id": "260035",
      "municipio_nombre": "Epuyén",
      "nombre": "EPUYEN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.3910620005992,
      "centroide_lon": -70.5780784399414,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014065000",
      "localidad_censal_id": "26014065",
      "localidad_censal_nombre": "Fofo Cahuel",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FOFO CAHUEL",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.7267578946733,
      "centroide_lon": -70.5354876126711,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014070000",
      "localidad_censal_id": "26014070",
      "localidad_censal_nombre": "Gualjaina",
      "municipio_id": "265007",
      "municipio_nombre": "Gualjaina",
      "nombre": "GUALJAINA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.2135356830147,
      "centroide_lon": -71.4296625433553,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014080000",
      "localidad_censal_id": "26014080",
      "localidad_censal_nombre": "Lago Epuyén",
      "municipio_id": "260035",
      "municipio_nombre": "Epuyén",
      "nombre": "LAGO EPUYEN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.0672923130861,
      "centroide_lon": -71.5981575625236,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014090000",
      "localidad_censal_id": "26014090",
      "localidad_censal_nombre": "Lago Puelo",
      "municipio_id": "260042",
      "municipio_nombre": "Lago Puelo",
      "nombre": "LAGO PUELO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.4288626216315,
      "centroide_lon": -71.0683951630343,
      "departamento_id": "26014",
      "departamento_nombre": "Cushamen",
      "id": "26014100000",
      "localidad_censal_id": "26014100",
      "localidad_censal_nombre": "Leleque",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LELEQUE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.7366075458208,
      "centroide_lon": -67.485214728265,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021010000",
      "localidad_censal_id": "26021010",
      "localidad_censal_nombre": "Astra",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "ASTRA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.1143552826119,
      "centroide_lon": -66.5349166167103,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021020000",
      "localidad_censal_id": "26021020",
      "localidad_censal_nombre": "Bahía Bustamante",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BAHIA BUSTAMANTE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.782807331545,
      "centroide_lon": -67.5033367870766,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030001",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "ACCESO NORTE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7482508559011,
      "centroide_lon": -67.3786501769463,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030002",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO CALETA CORDOVA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7757274867383,
      "centroide_lon": -67.3895609391029,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030003",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO CALETA OLIVARES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8180472017947,
      "centroide_lon": -67.4820283811333,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030004",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO CASTELLI",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7969335159784,
      "centroide_lon": -67.5038537987439,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030005",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO CIUDADELA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7935756857887,
      "centroide_lon": -67.5039634957522,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030006",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO GASODUCTO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8217123319455,
      "centroide_lon": -67.4921204994732,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030007",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO GÜEMES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8286806171316,
      "centroide_lon": -67.5429175806012,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030008",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO LAPRIDA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8171962363307,
      "centroide_lon": -67.5384037096511,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030009",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO MANANTIAL ROSALES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7880202044185,
      "centroide_lon": -67.4666859492816,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030010",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO MILITAR - AEROPUERTO MILITAR",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7954205855384,
      "centroide_lon": -67.4728320436648,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030011",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO PROSPERO PALAZZO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7933095274399,
      "centroide_lon": -67.4097861221166,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030012",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO RESTINGA ALI",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8042084863804,
      "centroide_lon": -67.4855016639952,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030013",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO RODRIGUEZ PEÑA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8455307615478,
      "centroide_lon": -67.5145852993993,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030014",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO SAAVEDRA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8191333517328,
      "centroide_lon": -67.502587140919,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030015",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO SARMIENTO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8248454635041,
      "centroide_lon": -67.4616495988676,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030016",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO 25 DE MAYO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8367380263826,
      "centroide_lon": -67.5021112026914,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030017",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "BARRIO VILLA S.U.P.E.",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8729846778063,
      "centroide_lon": -67.5430559979427,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030018",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "COMODORO RIVADAVIA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7875522789201,
      "centroide_lon": -67.4695736235974,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030019",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "KM. 5 - PRESIDENTE ORTIZ",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7988982385959,
      "centroide_lon": -67.4314970230436,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030020",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "KM. 8 - DON BOSCO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.7707679612545,
      "centroide_lon": -67.4358046744231,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030021",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "KM. 11 - CUARTELES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -45.8318281251079,
      "centroide_lon": -67.4917096424434,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021030022",
      "localidad_censal_id": "26021030",
      "localidad_censal_nombre": "Comodoro Rivadavia",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "KM. 3 - GENERAL MOSCONI",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.7906089158021,
      "centroide_lon": -67.6738925726169,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021040000",
      "localidad_censal_id": "26021040",
      "localidad_censal_nombre": "Diadema Argentina",
      "municipio_id": "260049",
      "municipio_nombre": "Comodoro Rivadavia",
      "nombre": "DIADEMA ARGENTINA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.9368019185633,
      "centroide_lon": -67.5653960236573,
      "departamento_id": "26021",
      "departamento_nombre": "Escalante",
      "id": "26021050000",
      "localidad_censal_id": "26021050",
      "localidad_censal_nombre": "Rada Tilly",
      "municipio_id": "260056",
      "municipio_nombre": "Rada Tilly",
      "nombre": "RADA TILLY",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.79829087317,
      "centroide_lon": -65.7105992934951,
      "departamento_id": "26028",
      "departamento_nombre": "Florentino Ameghino",
      "id": "26028010000",
      "localidad_censal_id": "26028010",
      "localidad_censal_nombre": "Camarones",
      "municipio_id": "260063",
      "municipio_nombre": "Camarones",
      "nombre": "CAMARONES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.6795783728661,
      "centroide_lon": -66.6097364550235,
      "departamento_id": "26028",
      "departamento_nombre": "Florentino Ameghino",
      "id": "26028020000",
      "localidad_censal_id": "26028020",
      "localidad_censal_nombre": "Garayalde",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GARAYALDE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.1198187614025,
      "centroide_lon": -71.5588967458005,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035010000",
      "localidad_censal_id": "26035010",
      "localidad_censal_nombre": "Aldea Escolar (Los Rápidos)",
      "municipio_id": "260084",
      "municipio_nombre": "Trevelín",
      "nombre": "ALDEA ESCOLAR",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.5380021657326,
      "centroide_lon": -71.4659616215841,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035020000",
      "localidad_censal_id": "26035020",
      "localidad_censal_nombre": "Corcovado",
      "municipio_id": "260070",
      "municipio_nombre": "Corcovado",
      "nombre": "CORCOVADO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.9133238858291,
      "centroide_lon": -71.3185116319277,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035030000",
      "localidad_censal_id": "26035030",
      "localidad_censal_nombre": "Esquel",
      "municipio_id": "260077",
      "municipio_nombre": "Esquel",
      "nombre": "ESQUEL",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.2500168518714,
      "centroide_lon": -71.3512318442886,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035040000",
      "localidad_censal_id": "26035040",
      "localidad_censal_nombre": "Lago Rosario",
      "municipio_id": "260084",
      "municipio_nombre": "Trevelín",
      "nombre": "LAGO ROSARIO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.1847146210617,
      "centroide_lon": -71.6413919295155,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035050000",
      "localidad_censal_id": "26035050",
      "localidad_censal_nombre": "Los Cipreses",
      "municipio_id": "260084",
      "municipio_nombre": "Trevelín",
      "nombre": "LOS CIPRESES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.0908711905976,
      "centroide_lon": -71.4654853699539,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035060000",
      "localidad_censal_id": "26035060",
      "localidad_censal_nombre": "Trevelín",
      "municipio_id": "260084",
      "municipio_nombre": "Trevelín",
      "nombre": "TREVELIN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.9006375858067,
      "centroide_lon": -71.6061535113717,
      "departamento_id": "26035",
      "departamento_nombre": "Futaleufú",
      "id": "26035070000",
      "localidad_censal_id": "26035070",
      "localidad_censal_nombre": "Villa Futalaufquen",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA FUTALAUFQUEN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.7032847513538,
      "centroide_lon": -66.479400323535,
      "departamento_id": "26042",
      "departamento_nombre": "Gaiman",
      "id": "26042010000",
      "localidad_censal_id": "26042010",
      "localidad_censal_nombre": "Dique Florentino Ameghino",
      "municipio_id": "268028",
      "municipio_nombre": "Dique Florentino Ameghino",
      "nombre": "DIQUE FLORENTINO AMEGHINO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.309297578868,
      "centroide_lon": -65.7087388385691,
      "departamento_id": "26042",
      "departamento_nombre": "Gaiman",
      "id": "26042020000",
      "localidad_censal_id": "26042020",
      "localidad_censal_nombre": "Dolavon",
      "municipio_id": "260091",
      "municipio_nombre": "Dolavon",
      "nombre": "DOLAVON",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.288807480447,
      "centroide_lon": -65.4924135000744,
      "departamento_id": "26042",
      "departamento_nombre": "Gaiman",
      "id": "26042030000",
      "localidad_censal_id": "26042030",
      "localidad_censal_nombre": "Gaiman",
      "municipio_id": "260098",
      "municipio_nombre": "Gaiman",
      "nombre": "GAIMAN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.3809771295585,
      "centroide_lon": -65.8386877428492,
      "departamento_id": "26042",
      "departamento_nombre": "Gaiman",
      "id": "26042040000",
      "localidad_censal_id": "26042040",
      "localidad_censal_nombre": "28 de Julio",
      "municipio_id": "265014",
      "municipio_nombre": "28 de Julio",
      "nombre": "28 DE JULIO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.5683892291525,
      "centroide_lon": -68.9190916237139,
      "departamento_id": "26049",
      "departamento_nombre": "Gastre",
      "id": "26049010000",
      "localidad_censal_id": "26049010",
      "localidad_censal_nombre": "Blancuntre",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BLANCUNTRE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.098070041959,
      "centroide_lon": -68.5596033354947,
      "departamento_id": "26049",
      "departamento_nombre": "Gastre",
      "id": "26049020000",
      "localidad_censal_id": "26049020",
      "localidad_censal_nombre": "El Escorial",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL ESCORIAL",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.2656204565429,
      "centroide_lon": -69.2210978977662,
      "departamento_id": "26049",
      "departamento_nombre": "Gastre",
      "id": "26049030000",
      "localidad_censal_id": "26049030",
      "localidad_censal_nombre": "Gastre",
      "municipio_id": "268035",
      "municipio_nombre": "Gastre",
      "nombre": "GASTRE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.7167792838299,
      "centroide_lon": -69.1884723935703,
      "departamento_id": "26049",
      "departamento_nombre": "Gastre",
      "id": "26049040000",
      "localidad_censal_id": "26049040",
      "localidad_censal_nombre": "Lagunita Salada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAGUNITA SALADA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.7671354457615,
      "centroide_lon": -68.875421794475,
      "departamento_id": "26049",
      "departamento_nombre": "Gastre",
      "id": "26049050000",
      "localidad_censal_id": "26049050",
      "localidad_censal_nombre": "Yala Laubat",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "YALA LAUBAT",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.2341165952859,
      "centroide_lon": -69.7112937770404,
      "departamento_id": "26056",
      "departamento_nombre": "Languiñeo",
      "id": "26056010000",
      "localidad_censal_id": "26056010",
      "localidad_censal_nombre": "Aldea Epulef",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA EPULEF",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.5856885437306,
      "centroide_lon": -71.7009020245021,
      "departamento_id": "26056",
      "departamento_nombre": "Languiñeo",
      "id": "26056020000",
      "localidad_censal_id": "26056020",
      "localidad_censal_nombre": "Carrenleufú",
      "municipio_id": "268056",
      "municipio_nombre": "Carrenleufú",
      "nombre": "CARRENLEUFU",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.2425977048335,
      "centroide_lon": -69.9302181856069,
      "departamento_id": "26056",
      "departamento_nombre": "Languiñeo",
      "id": "26056030000",
      "localidad_censal_id": "26056030",
      "localidad_censal_nombre": "Colan Conhué",
      "municipio_id": "268063",
      "municipio_nombre": "Colan Conhué",
      "nombre": "COLAN CONHUE",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.7371109950872,
      "centroide_lon": -69.6110020875554,
      "departamento_id": "26056",
      "departamento_nombre": "Languiñeo",
      "id": "26056040000",
      "localidad_censal_id": "26056040",
      "localidad_censal_nombre": "Paso del Sapo",
      "municipio_id": "268070",
      "municipio_nombre": "Paso del Sapo",
      "nombre": "PASO DEL SAPO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.4935820815258,
      "centroide_lon": -70.8135608321009,
      "departamento_id": "26056",
      "departamento_nombre": "Languiñeo",
      "id": "26056050000",
      "localidad_censal_id": "26056050",
      "localidad_censal_nombre": "Tecka",
      "municipio_id": "260105",
      "municipio_nombre": "Tecka",
      "nombre": "TECKA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.2852932712541,
      "centroide_lon": -67.7603113819649,
      "departamento_id": "26063",
      "departamento_nombre": "Mártires",
      "id": "26063010000",
      "localidad_censal_id": "26063010",
      "localidad_censal_nombre": "El Mirasol",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL MIRASOL",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.7223979636171,
      "centroide_lon": -67.2869956666141,
      "departamento_id": "26063",
      "departamento_nombre": "Mártires",
      "id": "26063020000",
      "localidad_censal_id": "26063020",
      "localidad_censal_nombre": "Las Plumas",
      "municipio_id": "268077",
      "municipio_nombre": "Las Plumas",
      "nombre": "LAS PLUMAS",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.4233198653988,
      "centroide_lon": -69.1640923476749,
      "departamento_id": "26070",
      "departamento_nombre": "Paso de Indios",
      "id": "26070010000",
      "localidad_censal_id": "26070010",
      "localidad_censal_nombre": "Cerro Cóndor",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CERRO CONDOR",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.8711929981581,
      "centroide_lon": -68.4301168776514,
      "departamento_id": "26070",
      "departamento_nombre": "Paso de Indios",
      "id": "26070020000",
      "localidad_censal_id": "26070020",
      "localidad_censal_nombre": "Los Altares",
      "municipio_id": "268084",
      "municipio_nombre": "Los Altares",
      "nombre": "LOS ALTARES",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.8663054117658,
      "centroide_lon": -69.0448870902309,
      "departamento_id": "26070",
      "departamento_nombre": "Paso de Indios",
      "id": "26070030000",
      "localidad_censal_id": "26070030",
      "localidad_censal_nombre": "Paso de Indios",
      "municipio_id": "265021",
      "municipio_nombre": "Paso de Indios",
      "nombre": "PASO DE INDIOS",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.3821208618862,
      "centroide_lon": -65.0449325683674,
      "departamento_id": "26077",
      "departamento_nombre": "Rawson",
      "id": "26077010000",
      "localidad_censal_id": "26077010",
      "localidad_censal_nombre": "Playa Magagna",
      "municipio_id": "260112",
      "municipio_nombre": "Rawson",
      "nombre": "PLAYA MAGAGNA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.3219408530217,
      "centroide_lon": -65.0475987350977,
      "departamento_id": "26077",
      "departamento_nombre": "Rawson",
      "id": "26077020000",
      "localidad_censal_id": "26077020",
      "localidad_censal_nombre": "Playa Unión",
      "municipio_id": "260112",
      "municipio_nombre": "Rawson",
      "nombre": "PLAYA UNION",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.3010516778945,
      "centroide_lon": -65.0955202340428,
      "departamento_id": "26077",
      "departamento_nombre": "Rawson",
      "id": "26077030000",
      "localidad_censal_id": "26077030",
      "localidad_censal_nombre": "Rawson",
      "municipio_id": "260112",
      "municipio_nombre": "Rawson",
      "nombre": "RAWSON",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -43.2483538570309,
      "centroide_lon": -65.3103813319694,
      "departamento_id": "26077",
      "departamento_nombre": "Rawson",
      "id": "26077040000",
      "localidad_censal_id": "26077040",
      "localidad_censal_nombre": "Trelew",
      "municipio_id": "260119",
      "municipio_nombre": "Trelew",
      "nombre": "TRELEW",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.7052521139583,
      "centroide_lon": -70.8460010136593,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084010000",
      "localidad_censal_id": "26084010",
      "localidad_censal_nombre": "Aldea Apeleg",
      "municipio_id": "260126",
      "municipio_nombre": "Alto Río Senguer",
      "nombre": "ALDEA APELEG",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.5614644681207,
      "centroide_lon": -71.5181726162484,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084020000",
      "localidad_censal_id": "26084020",
      "localidad_censal_nombre": "Aldea Beleiro",
      "municipio_id": "268091",
      "municipio_nombre": "Aldea Beleiro",
      "nombre": "ALDEA BELEIRO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.0476417520383,
      "centroide_lon": -70.8227552077123,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084030000",
      "localidad_censal_id": "26084030",
      "localidad_censal_nombre": "Alto Río Senguer",
      "municipio_id": "260126",
      "municipio_nombre": "Alto Río Senguer",
      "nombre": "ALTO RIO SENGUER",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.5870084306305,
      "centroide_lon": -71.0290968422414,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084040000",
      "localidad_censal_id": "26084040",
      "localidad_censal_nombre": "Doctor Ricardo Rojas",
      "municipio_id": "268098",
      "municipio_nombre": "Doctor Ricardo Rojas",
      "nombre": "DOCTOR RICARDO ROJAS",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.3192710383686,
      "centroide_lon": -69.9721127349678,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084050000",
      "localidad_censal_id": "26084050",
      "localidad_censal_nombre": "Facundo",
      "municipio_id": "268105",
      "municipio_nombre": "Facundo",
      "nombre": "FACUNDO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.9468594190828,
      "centroide_lon": -71.2641106643113,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084060000",
      "localidad_censal_id": "26084060",
      "localidad_censal_nombre": "Lago Blanco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAGO BLANCO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.6964538171738,
      "centroide_lon": -70.2559804482547,
      "departamento_id": "26084",
      "departamento_nombre": "Río Senguer",
      "id": "26084070000",
      "localidad_censal_id": "26084070",
      "localidad_censal_nombre": "Río Mayo",
      "municipio_id": "260133",
      "municipio_nombre": "Río Mayo",
      "nombre": "RIO MAYO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.0804225633305,
      "centroide_lon": -69.4497777721279,
      "departamento_id": "26091",
      "departamento_nombre": "Sarmiento",
      "id": "26091010000",
      "localidad_censal_id": "26091010",
      "localidad_censal_nombre": "Buen Pasto",
      "municipio_id": "268119",
      "municipio_nombre": "Buen Pasto",
      "nombre": "BUEN PASTO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -45.590519720018,
      "centroide_lon": -69.0706825167205,
      "departamento_id": "26091",
      "departamento_nombre": "Sarmiento",
      "id": "26091020000",
      "localidad_censal_id": "26091020",
      "localidad_censal_nombre": "Sarmiento",
      "municipio_id": "260140",
      "municipio_nombre": "Sarmiento",
      "nombre": "SARMIENTO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.1988556500986,
      "centroide_lon": -71.6666681641388,
      "departamento_id": "26098",
      "departamento_nombre": "Tehuelches",
      "id": "26098010000",
      "localidad_censal_id": "26098010",
      "localidad_censal_nombre": "Doctor Oscar Atilio Viglione (Frontera de Río Pico)",
      "municipio_id": "268147",
      "municipio_nombre": "Dr. Atilio Oscar Viglione",
      "nombre": "FRONTERA DE RIO PICO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.0529826063177,
      "centroide_lon": -70.597920256446,
      "departamento_id": "26098",
      "departamento_nombre": "Tehuelches",
      "id": "26098020000",
      "localidad_censal_id": "26098020",
      "localidad_censal_nombre": "Gobernador Costa",
      "municipio_id": "260147",
      "municipio_nombre": "Gobernador Costa",
      "nombre": "GOBERNADOR COSTA",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.0545030861499,
      "centroide_lon": -70.4698423450266,
      "departamento_id": "26098",
      "departamento_nombre": "Tehuelches",
      "id": "26098030000",
      "localidad_censal_id": "26098030",
      "localidad_censal_nombre": "José de San Martín",
      "municipio_id": "260154",
      "municipio_nombre": "José de San Martín",
      "nombre": "JOSE DE SAN MARTIN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -44.1829932413496,
      "centroide_lon": -71.3700565360866,
      "departamento_id": "26098",
      "departamento_nombre": "Tehuelches",
      "id": "26098040000",
      "localidad_censal_id": "26098040",
      "localidad_censal_nombre": "Río Pico",
      "municipio_id": "260161",
      "municipio_nombre": "Río Pico",
      "nombre": "RIO PICO",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.5232330288476,
      "centroide_lon": -68.2828542288869,
      "departamento_id": "26105",
      "departamento_nombre": "Telsen",
      "id": "26105010000",
      "localidad_censal_id": "26105010",
      "localidad_censal_nombre": "Gan Gan",
      "municipio_id": "268126",
      "municipio_nombre": "Gan Gan",
      "nombre": "GAN GAN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -42.4387511614213,
      "centroide_lon": -66.941427996482,
      "departamento_id": "26105",
      "departamento_nombre": "Telsen",
      "id": "26105020000",
      "localidad_censal_id": "26105020",
      "localidad_censal_nombre": "Telsen",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TELSEN",
      "provincia_id": "26",
      "provincia_nombre": "Chubut"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8673630759862,
      "centroide_lon": -58.445702857466,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008010000",
      "localidad_censal_id": "30008010",
      "localidad_censal_nombre": "Arroyo Barú",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARROYO BARU",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2232180852093,
      "centroide_lon": -58.1419674849484,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008020000",
      "localidad_censal_id": "30008020",
      "localidad_censal_nombre": "Colón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLON",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.2963657704418,
      "centroide_lon": -58.2329681221353,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008030000",
      "localidad_censal_id": "30008030",
      "localidad_censal_nombre": "Colonia Hugues",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA HUGUES",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9629513486876,
      "centroide_lon": -58.5082059489741,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008040000",
      "localidad_censal_id": "30008040",
      "localidad_censal_nombre": "Hambis",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HAMBIS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0874958453901,
      "centroide_lon": -58.3417058444451,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008050000",
      "localidad_censal_id": "30008050",
      "localidad_censal_nombre": "Hocker",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HOCKER",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9771375050248,
      "centroide_lon": -58.3917628888627,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008060000",
      "localidad_censal_id": "30008060",
      "localidad_censal_nombre": "La Clarita",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA CLARITA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0030492517654,
      "centroide_lon": -58.4934832589352,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008070000",
      "localidad_censal_id": "30008070",
      "localidad_censal_nombre": "Pueblo Cazes",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO CAZES",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1570049045312,
      "centroide_lon": -58.1976616890773,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008080000",
      "localidad_censal_id": "30008080",
      "localidad_censal_nombre": "Pueblo Liebig's",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO LIEBIG'S",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.1797669801643,
      "centroide_lon": -58.2122328834059,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008090001",
      "localidad_censal_id": "30008090",
      "localidad_censal_nombre": "San José",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL BRILLANTE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.1819017485871,
      "centroide_lon": -58.1918561863469,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008090002",
      "localidad_censal_id": "30008090",
      "localidad_censal_nombre": "San José",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL COLORADO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.2021613289668,
      "centroide_lon": -58.2095931555666,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008090003",
      "localidad_censal_id": "30008090",
      "localidad_censal_nombre": "San José",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN JOSE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7925728023428,
      "centroide_lon": -58.3158517356,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008100000",
      "localidad_censal_id": "30008100",
      "localidad_censal_nombre": "Ubajay",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "UBAJAY",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1606202225925,
      "centroide_lon": -58.4037483450693,
      "departamento_id": "30008",
      "departamento_nombre": "Colón",
      "id": "30008110000",
      "localidad_censal_id": "30008110",
      "localidad_censal_nombre": "Villa Elisa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ELISA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.507626297952,
      "centroide_lon": -58.1334346597166,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015010000",
      "localidad_censal_id": "30015010",
      "localidad_censal_nombre": "Calabacilla",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CALABACILLA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5794028169306,
      "centroide_lon": -58.1813678170213,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015020000",
      "localidad_censal_id": "30015020",
      "localidad_censal_nombre": "Clodomiro Ledesma",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CLODOMIRO LEDESMA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2050901632153,
      "centroide_lon": -58.0338899223591,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015030000",
      "localidad_censal_id": "30015030",
      "localidad_censal_nombre": "Colonia Ayuí",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA AYUI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3244065210985,
      "centroide_lon": -58.1214096714805,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015040000",
      "localidad_censal_id": "30015040",
      "localidad_censal_nombre": "Colonia General Roca",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA GENERAL ROCA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4288786267036,
      "centroide_lon": -58.0768061386463,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015060001",
      "localidad_censal_id": "30015060",
      "localidad_censal_nombre": "Concordia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BENITO LEGEREN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3687044068789,
      "centroide_lon": -58.0450288431718,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015060002",
      "localidad_censal_id": "30015060",
      "localidad_censal_nombre": "Concordia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CONCORDIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4119399624827,
      "centroide_lon": -58.0740082494429,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015060003",
      "localidad_censal_id": "30015060",
      "localidad_censal_nombre": "Concordia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS TEJAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4037426459166,
      "centroide_lon": -58.0394128271546,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015060004",
      "localidad_censal_id": "30015060",
      "localidad_censal_nombre": "Concordia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ADELA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3299511832527,
      "centroide_lon": -58.0174848453444,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015060005",
      "localidad_censal_id": "30015060",
      "localidad_censal_nombre": "Concordia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ZORRAQUIN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.469433434517,
      "centroide_lon": -58.2636681024626,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015080000",
      "localidad_censal_id": "30015080",
      "localidad_censal_nombre": "Estación Yeruá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION YERUA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3963812386954,
      "centroide_lon": -58.1611535438718,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015083000",
      "localidad_censal_id": "30015083",
      "localidad_censal_nombre": "Estación Yuquerí",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION YUQUERI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4400282680775,
      "centroide_lon": -58.1246631255854,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015087000",
      "localidad_censal_id": "30015087",
      "localidad_censal_nombre": "Estancia Grande",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTANCIA GRANDE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2693712880357,
      "centroide_lon": -58.1060425422369,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015090000",
      "localidad_censal_id": "30015090",
      "localidad_censal_nombre": "La Criolla",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA CRIOLLA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1707840748941,
      "centroide_lon": -58.1873842951633,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015100000",
      "localidad_censal_id": "30015100",
      "localidad_censal_nombre": "Los Charrúas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS CHARRUAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6473163855612,
      "centroide_lon": -58.0158126069679,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015110000",
      "localidad_censal_id": "30015110",
      "localidad_censal_nombre": "Nueva Escocia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA ESCOCIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6714729659455,
      "centroide_lon": -58.2307912741944,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015130000",
      "localidad_censal_id": "30015130",
      "localidad_censal_nombre": "Pedernal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PEDERNAL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.530833509471,
      "centroide_lon": -58.0128947749058,
      "departamento_id": "30015",
      "departamento_nombre": "Concordia",
      "id": "30015140000",
      "localidad_censal_id": "30015140",
      "localidad_censal_nombre": "Puerto Yeruá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO YERUA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8922864518526,
      "centroide_lon": -60.591797746941,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021010000",
      "localidad_censal_id": "30021010",
      "localidad_censal_nombre": "Aldea Brasilera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA BRASILERA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9563723140333,
      "centroide_lon": -60.4961051979678,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021015000",
      "localidad_censal_id": "30021015",
      "localidad_censal_nombre": "Aldea Grapschental",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA GRAPSCHENTAL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0308328904873,
      "centroide_lon": -60.5643359117847,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021020000",
      "localidad_censal_id": "30021020",
      "localidad_censal_nombre": "Aldea Protestante",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA PROTESTANTE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9260358583332,
      "centroide_lon": -60.5483852721123,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021030000",
      "localidad_censal_id": "30021030",
      "localidad_censal_nombre": "Aldea Salto",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SALTO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9625681226479,
      "centroide_lon": -60.635713481626,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021040000",
      "localidad_censal_id": "30021040",
      "localidad_censal_nombre": "Aldea San Francisco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SAN FRANCISCO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9485292712546,
      "centroide_lon": -60.5803966416062,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021050000",
      "localidad_censal_id": "30021050",
      "localidad_censal_nombre": "Aldea Spatzenkutter",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SPATZENKUTTER",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9922440087904,
      "centroide_lon": -60.5878502274446,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021060000",
      "localidad_censal_id": "30021060",
      "localidad_censal_nombre": "Aldea Valle María",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA VALLE MARIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8672572611199,
      "centroide_lon": -60.5752901869571,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021070000",
      "localidad_censal_id": "30021070",
      "localidad_censal_nombre": "Colonia Ensayo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA ENSAYO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0671549441222,
      "centroide_lon": -60.6377365188118,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021080001",
      "localidad_censal_id": "30021080",
      "localidad_censal_nombre": "Diamante",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "DIAMANTE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0585362988015,
      "centroide_lon": -60.6108078415946,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021080002",
      "localidad_censal_id": "30021080",
      "localidad_censal_nombre": "Diamante",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "STROBEL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1133207438253,
      "centroide_lon": -60.2299017967384,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021090000",
      "localidad_censal_id": "30021090",
      "localidad_censal_nombre": "Estación Camps",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION CAMPS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9494541080354,
      "centroide_lon": -60.6779497259324,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021100000",
      "localidad_censal_id": "30021100",
      "localidad_censal_nombre": "General Alvear",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL ALVEAR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9840164487261,
      "centroide_lon": -60.4083912285494,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021110000",
      "localidad_censal_id": "30021110",
      "localidad_censal_nombre": "General Racedo (El Carmen)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL RACEDO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1772714430842,
      "centroide_lon": -60.1985605811767,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021120000",
      "localidad_censal_id": "30021120",
      "localidad_censal_nombre": "General Ramírez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL RAMIREZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8753768018271,
      "centroide_lon": -60.649121973368,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021123000",
      "localidad_censal_id": "30021123",
      "localidad_censal_nombre": "La Juanita",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA JUANITA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8327184836082,
      "centroide_lon": -60.6048471558247,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021127000",
      "localidad_censal_id": "30021127",
      "localidad_censal_nombre": "Las Jaulas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA JAULA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.917362571918,
      "centroide_lon": -60.6531968809921,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021130000",
      "localidad_censal_id": "30021130",
      "localidad_censal_nombre": "Paraje La Virgen",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARAJE LA VIRGEN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3342284848162,
      "centroide_lon": -60.4872902471305,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021140000",
      "localidad_censal_id": "30021140",
      "localidad_censal_nombre": "Puerto Las Cuevas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO LAS CUEVAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0602299925107,
      "centroide_lon": -60.4435729044204,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021150001",
      "localidad_censal_id": "30021150",
      "localidad_censal_nombre": "Villa Libertador San Martín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION PUIGGARI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.0697561751369,
      "centroide_lon": -60.4624463940416,
      "departamento_id": "30021",
      "departamento_nombre": "Diamante",
      "id": "30021150002",
      "localidad_censal_id": "30021150",
      "localidad_censal_nombre": "Villa Libertador San Martín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA LIBERTADOR SAN MARTIN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7540759107669,
      "centroide_lon": -57.9841625878637,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028010000",
      "localidad_censal_id": "30028010",
      "localidad_censal_nombre": "Chajarí",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHAJARI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.896158506292,
      "centroide_lon": -58.0221576342783,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028020000",
      "localidad_censal_id": "30028020",
      "localidad_censal_nombre": "Colonia Alemana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA ALEMANA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7974915997552,
      "centroide_lon": -58.1756384659062,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028050000",
      "localidad_censal_id": "30028050",
      "localidad_censal_nombre": "Colonia Peña",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA PEÑA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.990072444489,
      "centroide_lon": -57.9183475208406,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028070000",
      "localidad_censal_id": "30028070",
      "localidad_censal_nombre": "Federación",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FEDERACION",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5944074579969,
      "centroide_lon": -58.4682627057869,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028080000",
      "localidad_censal_id": "30028080",
      "localidad_censal_nombre": "Los Conquistadores",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS CONQUISTADORES",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3396273493524,
      "centroide_lon": -58.3080915973688,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028090000",
      "localidad_censal_id": "30028090",
      "localidad_censal_nombre": "San Jaime de la Frontera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN JAIME DE LA FRONTERA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7571887237222,
      "centroide_lon": -58.0818713823153,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028100000",
      "localidad_censal_id": "30028100",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN PEDRO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8091644325467,
      "centroide_lon": -58.2191269101013,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028105000",
      "localidad_censal_id": "30028105",
      "localidad_censal_nombre": "San Ramón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN RAMON",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9004970305733,
      "centroide_lon": -57.9310809529514,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028110000",
      "localidad_censal_id": "30028110",
      "localidad_censal_nombre": "Santa Ana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTA ANA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7968318611671,
      "centroide_lon": -57.9115719165535,
      "departamento_id": "30028",
      "departamento_nombre": "Federación",
      "id": "30028120000",
      "localidad_censal_id": "30028120",
      "localidad_censal_nombre": "Villa del Rosario",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA DEL ROSARIO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0477778936084,
      "centroide_lon": -59.0867391405808,
      "departamento_id": "30035",
      "departamento_nombre": "Federal",
      "id": "30035010000",
      "localidad_censal_id": "30035010",
      "localidad_censal_nombre": "Conscripto Bernardi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CONSCRIPTO BERNARDI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9868629826869,
      "centroide_lon": -58.9777569104134,
      "departamento_id": "30035",
      "departamento_nombre": "Federal",
      "id": "30035020000",
      "localidad_censal_id": "30035020",
      "localidad_censal_nombre": "Aldea San Isidro (El Cimarrón)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CIMARRON",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9513342178715,
      "centroide_lon": -58.7851418943148,
      "departamento_id": "30035",
      "departamento_nombre": "Federal",
      "id": "30035030000",
      "localidad_censal_id": "30035030",
      "localidad_censal_nombre": "Federal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FEDERAL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9685149773302,
      "centroide_lon": -58.6127479285248,
      "departamento_id": "30035",
      "departamento_nombre": "Federal",
      "id": "30035040000",
      "localidad_censal_id": "30035040",
      "localidad_censal_nombre": "Nueva Vizcaya",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA VIZCAYA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2384740579796,
      "centroide_lon": -59.2202829564802,
      "departamento_id": "30035",
      "departamento_nombre": "Federal",
      "id": "30035050000",
      "localidad_censal_id": "30035050",
      "localidad_censal_nombre": "Sauce de Luna",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAUCE DE LUNA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3852713114912,
      "centroide_lon": -58.7515719602207,
      "departamento_id": "30042",
      "departamento_nombre": "Feliciano",
      "id": "30042010000",
      "localidad_censal_id": "30042010",
      "localidad_censal_nombre": "San José de Feliciano",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN JOSE DE FELICIANO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4869283596556,
      "centroide_lon": -59.0316519541684,
      "departamento_id": "30042",
      "departamento_nombre": "Feliciano",
      "id": "30042020000",
      "localidad_censal_id": "30042020",
      "localidad_censal_nombre": "San Víctor",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN VICTOR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.825171529429,
      "centroide_lon": -59.2312379231862,
      "departamento_id": "30049",
      "departamento_nombre": "Gualeguay",
      "id": "30049010000",
      "localidad_censal_id": "30049010",
      "localidad_censal_nombre": "Aldea Asunción",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA ASUNCION",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8718397608117,
      "centroide_lon": -59.4223491853546,
      "departamento_id": "30049",
      "departamento_nombre": "Gualeguay",
      "id": "30049020000",
      "localidad_censal_id": "30049020",
      "localidad_censal_nombre": "Estación Lazo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION LAZO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7212713061819,
      "centroide_lon": -59.3958887031115,
      "departamento_id": "30049",
      "departamento_nombre": "Gualeguay",
      "id": "30049030000",
      "localidad_censal_id": "30049030",
      "localidad_censal_nombre": "General Galarza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL GALARZA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1485556234267,
      "centroide_lon": -59.3150160418816,
      "departamento_id": "30049",
      "departamento_nombre": "Gualeguay",
      "id": "30049040000",
      "localidad_censal_id": "30049040",
      "localidad_censal_nombre": "Gualeguay",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GUALEGUAY",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2198401773573,
      "centroide_lon": -59.3630338659967,
      "departamento_id": "30049",
      "departamento_nombre": "Gualeguay",
      "id": "30049050000",
      "localidad_censal_id": "30049050",
      "localidad_censal_nombre": "Puerto Ruiz",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO RUIZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6253281653841,
      "centroide_lon": -58.703196864174,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056010000",
      "localidad_censal_id": "30056010",
      "localidad_censal_nombre": "Aldea San Antonio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SAN ANTONIO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.703788501225,
      "centroide_lon": -58.7790625010106,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056020000",
      "localidad_censal_id": "30056020",
      "localidad_censal_nombre": "Aldea San Juan",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SAN JUAN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1478483564668,
      "centroide_lon": -59.2089401138297,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056030000",
      "localidad_censal_id": "30056030",
      "localidad_censal_nombre": "Enrique Carbó",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ENRIQUE CARBO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5932358304352,
      "centroide_lon": -58.9037416509557,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056035000",
      "localidad_censal_id": "30056035",
      "localidad_censal_nombre": "Estación Escriña",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION ESCRIÑA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8044314283598,
      "centroide_lon": -58.8826333992369,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056040000",
      "localidad_censal_id": "30056040",
      "localidad_censal_nombre": "Faustino M. Parera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FAUSTINO M. PARERA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8376334754538,
      "centroide_lon": -58.8038160467091,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056050000",
      "localidad_censal_id": "30056050",
      "localidad_censal_nombre": "General Almada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL ALMADA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5306030521064,
      "centroide_lon": -58.9339533000982,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056060000",
      "localidad_censal_id": "30056060",
      "localidad_censal_nombre": "Gilbert",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GILBERT",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0100328267382,
      "centroide_lon": -58.5164257946352,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056070000",
      "localidad_censal_id": "30056070",
      "localidad_censal_nombre": "Gualeguaychú",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GUALEGUAYCHU",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9269373297111,
      "centroide_lon": -58.9534834763678,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056080000",
      "localidad_censal_id": "30056080",
      "localidad_censal_nombre": "Irazusta",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "IRAZUSTA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0354242763647,
      "centroide_lon": -59.001853656082,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056090000",
      "localidad_censal_id": "30056090",
      "localidad_censal_nombre": "Larroque",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LARROQUE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7697426128614,
      "centroide_lon": -58.9070200410514,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056095000",
      "localidad_censal_id": "30056095",
      "localidad_censal_nombre": "Pastor Britos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PASTOR BRITOS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0162888459347,
      "centroide_lon": -58.476043760345,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056100000",
      "localidad_censal_id": "30056100",
      "localidad_censal_nombre": "Pueblo General Belgrano",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO GENERAL BELGRANO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6881311554333,
      "centroide_lon": -58.888994553976,
      "departamento_id": "30056",
      "departamento_nombre": "Gualeguaychú",
      "id": "30056110000",
      "localidad_censal_id": "30056110",
      "localidad_censal_nombre": "Urdinarrain",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "URDINARRAIN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4994124052794,
      "centroide_lon": -58.8003541709834,
      "departamento_id": "30063",
      "departamento_nombre": "Islas del Ibicuy",
      "id": "30063020000",
      "localidad_censal_id": "30063020",
      "localidad_censal_nombre": "Ceibas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CEIBAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7437863303553,
      "centroide_lon": -59.1550366922315,
      "departamento_id": "30063",
      "departamento_nombre": "Islas del Ibicuy",
      "id": "30063030000",
      "localidad_censal_id": "30063030",
      "localidad_censal_nombre": "Ibicuy",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "IBICUY",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4360995740998,
      "centroide_lon": -59.0675952112197,
      "departamento_id": "30063",
      "departamento_nombre": "Islas del Ibicuy",
      "id": "30063040000",
      "localidad_censal_id": "30063040",
      "localidad_censal_nombre": "Médanos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MEDANOS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7156244223002,
      "centroide_lon": -58.6597631447303,
      "departamento_id": "30063",
      "departamento_nombre": "Islas del Ibicuy",
      "id": "30063060000",
      "localidad_censal_id": "30063060",
      "localidad_censal_nombre": "Villa Paranacito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA PARANACITO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4565889671918,
      "centroide_lon": -59.5991388684545,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070005000",
      "localidad_censal_id": "30070005",
      "localidad_censal_nombre": "Alcaraz",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALCARAZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3428914409424,
      "centroide_lon": -59.4467309737899,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070010000",
      "localidad_censal_id": "30070010",
      "localidad_censal_nombre": "Bovril",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BOVRIL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1853384671792,
      "centroide_lon": -59.4079822910182,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070020000",
      "localidad_censal_id": "30070020",
      "localidad_censal_nombre": "Colonia Avigdor",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA AVIGDOR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1810124666205,
      "centroide_lon": -59.7328844569416,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070030000",
      "localidad_censal_id": "30070030",
      "localidad_censal_nombre": "El Solar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL SOLAR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.741541104376,
      "centroide_lon": -59.6433535241897,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070040000",
      "localidad_censal_id": "30070040",
      "localidad_censal_nombre": "La Paz",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA PAZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1859575955508,
      "centroide_lon": -59.9521591252044,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070050000",
      "localidad_censal_id": "30070050",
      "localidad_censal_nombre": "Piedras Blancas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PIEDRAS BLANCAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6905067150943,
      "centroide_lon": -59.3994540133514,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070070000",
      "localidad_censal_id": "30070070",
      "localidad_censal_nombre": "San Gustavo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN GUSTAVO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9466589584952,
      "centroide_lon": -59.7882850384119,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070080000",
      "localidad_censal_id": "30070080",
      "localidad_censal_nombre": "Santa Elena",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTA ELENA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3896679471814,
      "centroide_lon": -59.5032123815164,
      "departamento_id": "30070",
      "departamento_nombre": "La Paz",
      "id": "30070090000",
      "localidad_censal_id": "30070090",
      "localidad_censal_nombre": "Sir Leonard",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SIR LEONARD",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2443130059944,
      "centroide_lon": -60.1627988186884,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077010000",
      "localidad_censal_id": "30077010",
      "localidad_censal_nombre": "Aranguren",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARANGUREN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.374100868006,
      "centroide_lon": -59.9380818002549,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077020000",
      "localidad_censal_id": "30077020",
      "localidad_censal_nombre": "Betbeder",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BETBEDER",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0752594049271,
      "centroide_lon": -59.9943230195983,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077030000",
      "localidad_censal_id": "30077030",
      "localidad_censal_nombre": "Don Cristóbal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "DON CRISTOBAL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4774515995652,
      "centroide_lon": -59.9217117300676,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077040000",
      "localidad_censal_id": "30077040",
      "localidad_censal_nombre": "Febré",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FEBRE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3380692970598,
      "centroide_lon": -60.0301404781048,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077050000",
      "localidad_censal_id": "30077050",
      "localidad_censal_nombre": "Hernández",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HERNANDEZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3859289481651,
      "centroide_lon": -59.531300464703,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077060000",
      "localidad_censal_id": "30077060",
      "localidad_censal_nombre": "Lucas González",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LUCAS GONZALEZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3956416421841,
      "centroide_lon": -59.7880764157544,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077070000",
      "localidad_censal_id": "30077070",
      "localidad_censal_nombre": "Nogoyá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NOGOYA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3866241398753,
      "centroide_lon": -59.6630736582897,
      "departamento_id": "30077",
      "departamento_nombre": "Nogoyá",
      "id": "30077080000",
      "localidad_censal_id": "30077080",
      "localidad_censal_nombre": "XX de Setiembre",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "XX DE SETIEMBRE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8847292300203,
      "centroide_lon": -60.4087456869806,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084010000",
      "localidad_censal_id": "30084010",
      "localidad_censal_nombre": "Aldea María Luisa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA MARIA LUISA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9982237404715,
      "centroide_lon": -60.3583819889242,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084015000",
      "localidad_censal_id": "30084015",
      "localidad_censal_nombre": "Aldea San Juan",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SAN JUAN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9598299451355,
      "centroide_lon": -60.2558755485286,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084020000",
      "localidad_censal_id": "30084020",
      "localidad_censal_nombre": "Aldea San Rafael",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SAN RAFAEL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6128701885629,
      "centroide_lon": -60.0070721806963,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084030000",
      "localidad_censal_id": "30084030",
      "localidad_censal_nombre": "Aldea Santa María",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SANTA MARIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0164544602725,
      "centroide_lon": -60.2411073474122,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084040000",
      "localidad_censal_id": "30084040",
      "localidad_censal_nombre": "Aldea Santa Rosa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALDEA SANTA ROSA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5821711222967,
      "centroide_lon": -60.0740223148253,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084050001",
      "localidad_censal_id": "30084050",
      "localidad_censal_nombre": "Cerrito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CERRITO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5884913847857,
      "centroide_lon": -60.0648929343735,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084050002",
      "localidad_censal_id": "30084050",
      "localidad_censal_nombre": "Cerrito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO MORENO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7680690511493,
      "centroide_lon": -60.4050220629258,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084060000",
      "localidad_censal_id": "30084060",
      "localidad_censal_nombre": "Colonia Avellaneda",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA AVELLANEDA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6777727008412,
      "centroide_lon": -60.2436880885317,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084065000",
      "localidad_censal_id": "30084065",
      "localidad_censal_nombre": "Colonia Crespo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA CRESPO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0346491846505,
      "centroide_lon": -60.3107514290928,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084070000",
      "localidad_censal_id": "30084070",
      "localidad_censal_nombre": "Crespo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CRESPO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6625748086746,
      "centroide_lon": -60.1751144821058,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084080000",
      "localidad_censal_id": "30084080",
      "localidad_censal_nombre": "El Palenque",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL PALENQUE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5838497016925,
      "centroide_lon": -59.8938069366259,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084090000",
      "localidad_censal_id": "30084090",
      "localidad_censal_nombre": "El Pingo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL PINGO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8480473768248,
      "centroide_lon": -60.0927487719843,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084095000",
      "localidad_censal_id": "30084095",
      "localidad_censal_nombre": "El Ramblón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL RAMBLON",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5133052410614,
      "centroide_lon": -59.83696962349,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084100000",
      "localidad_censal_id": "30084100",
      "localidad_censal_nombre": "Hasenkamp",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HASENKAMP",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2324500702018,
      "centroide_lon": -59.9872576438234,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084110000",
      "localidad_censal_id": "30084110",
      "localidad_censal_nombre": "Hernandarias",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HERNANDARIAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7267100818149,
      "centroide_lon": -60.2971793004448,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084120000",
      "localidad_censal_id": "30084120",
      "localidad_censal_nombre": "La Picada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA PICADA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8706095724666,
      "centroide_lon": -59.7324361083485,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084130000",
      "localidad_censal_id": "30084130",
      "localidad_censal_nombre": "Las Tunas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS TUNAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6660715037636,
      "centroide_lon": -59.9044379252459,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084140000",
      "localidad_censal_id": "30084140",
      "localidad_censal_nombre": "María Grande",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MARIA GRANDE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.8257355466309,
      "centroide_lon": -60.5182728163031,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084150000",
      "localidad_censal_id": "30084150",
      "localidad_censal_nombre": "Oro Verde",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ORO VERDE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7415676426411,
      "centroide_lon": -60.5284145917589,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084160000",
      "localidad_censal_id": "30084160",
      "localidad_censal_nombre": "Paraná",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARANA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4287140394702,
      "centroide_lon": -59.7457885927193,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084170000",
      "localidad_censal_id": "30084170",
      "localidad_censal_nombre": "Pueblo Bellocq (Las Garzas)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO BELLOCQ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3883015926493,
      "centroide_lon": -60.0938334043815,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084180000",
      "localidad_censal_id": "30084180",
      "localidad_censal_nombre": "Pueblo Brugo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO BRUGO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4636064114686,
      "centroide_lon": -60.1679618601982,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084190000",
      "localidad_censal_id": "30084190",
      "localidad_censal_nombre": "Pueblo General San Martín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO GENERAL SAN MARTIN",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7815218098022,
      "centroide_lon": -60.4419739913356,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084200000",
      "localidad_censal_id": "30084200",
      "localidad_censal_nombre": "San Benito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN BENITO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7463194997657,
      "centroide_lon": -60.3552297078863,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084210000",
      "localidad_censal_id": "30084210",
      "localidad_censal_nombre": "Sauce Montrull",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAUCE MONTRULL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8442990730164,
      "centroide_lon": -60.3747483050584,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084220000",
      "localidad_censal_id": "30084220",
      "localidad_censal_nombre": "Sauce Pinto",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAUCE PINTO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9588750821276,
      "centroide_lon": -60.1269829104478,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084230000",
      "localidad_censal_id": "30084230",
      "localidad_censal_nombre": "Seguí",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SEGUI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7385742726697,
      "centroide_lon": -59.9115081436489,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084240000",
      "localidad_censal_id": "30084240",
      "localidad_censal_nombre": "Sosa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SOSA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8047077554997,
      "centroide_lon": -59.9379151601641,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084250000",
      "localidad_censal_id": "30084250",
      "localidad_censal_nombre": "Tabossi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TABOSSI",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8735456198919,
      "centroide_lon": -60.4970235321195,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084260000",
      "localidad_censal_id": "30084260",
      "localidad_censal_nombre": "Tezanos Pinto",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TEZANOS PINTO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8714330822777,
      "centroide_lon": -60.0099225269048,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084270000",
      "localidad_censal_id": "30084270",
      "localidad_censal_nombre": "Viale",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VIALE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.908647496665,
      "centroide_lon": -60.468352081106,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084280000",
      "localidad_censal_id": "30084280",
      "localidad_censal_nombre": "Villa Fontana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA FONTANA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.93428563973,
      "centroide_lon": -60.4279737732991,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084290000",
      "localidad_censal_id": "30084290",
      "localidad_censal_nombre": "Villa Gdor. Luis F. Etchevehere",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA GOBERNADOR LUIS F. ETCHEVEHERE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6507506100701,
      "centroide_lon": -60.3775855159669,
      "departamento_id": "30084",
      "departamento_nombre": "Paraná",
      "id": "30084300000",
      "localidad_censal_id": "30084300",
      "localidad_censal_nombre": "Villa Urquiza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA URQUIZA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5246078338723,
      "centroide_lon": -58.4047735134447,
      "departamento_id": "30088",
      "departamento_nombre": "San Salvador",
      "id": "30088010000",
      "localidad_censal_id": "30088010",
      "localidad_censal_nombre": "General Campos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GENERAL CAMPOS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6255225523021,
      "centroide_lon": -58.5040738167896,
      "departamento_id": "30088",
      "departamento_nombre": "San Salvador",
      "id": "30088020000",
      "localidad_censal_id": "30088020",
      "localidad_censal_nombre": "San Salvador",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN SALVADOR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0888342815838,
      "centroide_lon": -59.1740705545307,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091010000",
      "localidad_censal_id": "30091010",
      "localidad_censal_nombre": "Altamirano Sur",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALTAMIRANO SUR",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9865854200416,
      "centroide_lon": -59.2804623070462,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091020000",
      "localidad_censal_id": "30091020",
      "localidad_censal_nombre": "Durazno",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "DURAZNO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6338905298532,
      "centroide_lon": -59.4016108911944,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091030000",
      "localidad_censal_id": "30091030",
      "localidad_censal_nombre": "Estación Arroyo Clé",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION ARROYO CLE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3933701328502,
      "centroide_lon": -59.2758886814116,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091040000",
      "localidad_censal_id": "30091040",
      "localidad_censal_nombre": "Gobernador Echagüe",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GOBERNADOR ECHAGUE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5464913620505,
      "centroide_lon": -59.3561472624329,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091050000",
      "localidad_censal_id": "30091050",
      "localidad_censal_nombre": "Gobernador Mansilla",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GOBERNADOR MANSILLA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3372059083888,
      "centroide_lon": -59.3710906213647,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091060000",
      "localidad_censal_id": "30091060",
      "localidad_censal_nombre": "Gobernador Solá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GOBERNADOR SOLA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0826358698135,
      "centroide_lon": -59.3082772238093,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091070000",
      "localidad_censal_id": "30091070",
      "localidad_censal_nombre": "Guardamonte",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GUARDAMONTE",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4700075443165,
      "centroide_lon": -59.1726625031425,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091080000",
      "localidad_censal_id": "30091080",
      "localidad_censal_nombre": "Las Guachas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS GUACHAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1724413881098,
      "centroide_lon": -59.3986787086128,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091090000",
      "localidad_censal_id": "30091090",
      "localidad_censal_nombre": "Maciá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MACIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3026339163322,
      "centroide_lon": -59.1445792095518,
      "departamento_id": "30091",
      "departamento_nombre": "Tala",
      "id": "30091100000",
      "localidad_censal_id": "30091100",
      "localidad_censal_nombre": "Rosario del Tala",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROSARIO DEL TALA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3725729351065,
      "centroide_lon": -58.8785609437917,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098010000",
      "localidad_censal_id": "30098010",
      "localidad_censal_nombre": "Basavilbaso",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BASAVILBASO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4642772562474,
      "centroide_lon": -58.4780718727049,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098020000",
      "localidad_censal_id": "30098020",
      "localidad_censal_nombre": "Caseros",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CASEROS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6723717845853,
      "centroide_lon": -58.325119237234,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098030000",
      "localidad_censal_id": "30098030",
      "localidad_censal_nombre": "Colonia Elía",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA ELIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4853601673728,
      "centroide_lon": -58.2320517380782,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098040000",
      "localidad_censal_id": "30098040",
      "localidad_censal_nombre": "Concepción del Uruguay",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CONCEPCION DEL URUGUAY",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.434613979879,
      "centroide_lon": -58.6330137118779,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098060000",
      "localidad_censal_id": "30098060",
      "localidad_censal_nombre": "Herrera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HERRERA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0926666859344,
      "centroide_lon": -58.9575923552082,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098070000",
      "localidad_censal_id": "30098070",
      "localidad_censal_nombre": "Las Moscas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS MOSCAS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2629479332194,
      "centroide_lon": -58.9066108120783,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098080000",
      "localidad_censal_id": "30098080",
      "localidad_censal_nombre": "Líbaros",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LIBAROS",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2570116072535,
      "centroide_lon": -58.422728628517,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098090000",
      "localidad_censal_id": "30098090",
      "localidad_censal_nombre": "1º de Mayo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "1 DE MAYO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3456507831337,
      "centroide_lon": -58.4438591145895,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098100000",
      "localidad_censal_id": "30098100",
      "localidad_censal_nombre": "Pronunciamiento",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PRONUNCIAMIENTO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3459729612346,
      "centroide_lon": -58.9697454142681,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098110000",
      "localidad_censal_id": "30098110",
      "localidad_censal_nombre": "Rocamora",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROCAMORA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1762466320214,
      "centroide_lon": -58.7864822456477,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098120000",
      "localidad_censal_id": "30098120",
      "localidad_censal_nombre": "Santa Anita",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTA ANITA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3987091260597,
      "centroide_lon": -58.7440638984338,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098130000",
      "localidad_censal_id": "30098130",
      "localidad_censal_nombre": "Villa Mantero",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA MANTERO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.446319897702,
      "centroide_lon": -58.4359138343848,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098140000",
      "localidad_censal_id": "30098140",
      "localidad_censal_nombre": "Villa San Justo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SAN JUSTO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1800624013874,
      "centroide_lon": -58.9301272035017,
      "departamento_id": "30098",
      "departamento_nombre": "Uruguay",
      "id": "30098150000",
      "localidad_censal_id": "30098150",
      "localidad_censal_nombre": "Villa San Marcial (Est. Gobernador Urquiza)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SAN MARCIAL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5338102554011,
      "centroide_lon": -60.0356966118049,
      "departamento_id": "30105",
      "departamento_nombre": "Victoria",
      "id": "30105010000",
      "localidad_censal_id": "30105010",
      "localidad_censal_nombre": "Antelo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ANTELO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3100409280054,
      "centroide_lon": -60.4202063551195,
      "departamento_id": "30105",
      "departamento_nombre": "Victoria",
      "id": "30105040000",
      "localidad_censal_id": "30105040",
      "localidad_censal_nombre": "Molino Doll",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MOLINO DOLL",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6205968368011,
      "centroide_lon": -60.1533126380173,
      "departamento_id": "30105",
      "departamento_nombre": "Victoria",
      "id": "30105060000",
      "localidad_censal_id": "30105060",
      "localidad_censal_nombre": "Victoria",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VICTORIA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.908248192653,
      "centroide_lon": -59.2606926522048,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113010000",
      "localidad_censal_id": "30113010",
      "localidad_censal_nombre": "Estación Raíces",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION RAICES",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9569336301554,
      "centroide_lon": -58.8491174614017,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113020000",
      "localidad_censal_id": "30113020",
      "localidad_censal_nombre": "Ingeniero Miguel Sajaroff",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "INGENIERO MIGUEL SAJAROFF",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7336733349136,
      "centroide_lon": -58.634038044354,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113030000",
      "localidad_censal_id": "30113030",
      "localidad_censal_nombre": "Jubileo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "JUBILEO",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8057649065599,
      "centroide_lon": -59.1651613714524,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113050000",
      "localidad_censal_id": "30113050",
      "localidad_censal_nombre": "Paso de la Laguna",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PASO DE LA LAGUNA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8301004555882,
      "centroide_lon": -58.8233903183527,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113060000",
      "localidad_censal_id": "30113060",
      "localidad_censal_nombre": "Villa Clara",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA CLARA",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9870146859479,
      "centroide_lon": -58.9634179145279,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113070000",
      "localidad_censal_id": "30113070",
      "localidad_censal_nombre": "Villa Domínguez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA DOMINGUEZ",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8654413738655,
      "centroide_lon": -59.0290628351274,
      "departamento_id": "30113",
      "departamento_nombre": "Villaguay",
      "id": "30113080000",
      "localidad_censal_id": "30113080",
      "localidad_censal_nombre": "Villaguay",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLAGUAY",
      "provincia_id": "30",
      "provincia_nombre": "Entre Ríos"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1490607210153,
      "centroide_lon": -60.692324164757,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007003000",
      "localidad_censal_id": "34007003",
      "localidad_censal_nombre": "Fortín Soledad",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA SOLEDAD",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6823938545951,
      "centroide_lon": -61.1611089476784,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007005000",
      "localidad_censal_id": "34007005",
      "localidad_censal_nombre": "Guadalcazar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GUADALCAZAR",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4942751905754,
      "centroide_lon": -61.5763888694601,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007007000",
      "localidad_censal_id": "34007007",
      "localidad_censal_nombre": "La Rinconada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA RINCONADA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2554647197914,
      "centroide_lon": -61.2439980075436,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007010000",
      "localidad_censal_id": "34007010",
      "localidad_censal_nombre": "Laguna Yema",
      "municipio_id": "340007",
      "municipio_nombre": "Laguna Yema",
      "nombre": "LAGUNA YEMA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9376982637237,
      "centroide_lon": -60.7400985635583,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007015000",
      "localidad_censal_id": "34007015",
      "localidad_censal_nombre": "Lamadrid",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAMADRID",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.0986835294011,
      "centroide_lon": -61.4675867695849,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007020000",
      "localidad_censal_id": "34007020",
      "localidad_censal_nombre": "Los Chiriguanos",
      "municipio_id": "345007",
      "municipio_nombre": "Los Chiriguanos",
      "nombre": "LOS CHIRIGUANOS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5691832483278,
      "centroide_lon": -61.7055334817331,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007030000",
      "localidad_censal_id": "34007030",
      "localidad_censal_nombre": "Pozo de Maza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZO DE MAZA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4064403681891,
      "centroide_lon": -61.031313748118,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007040000",
      "localidad_censal_id": "34007040",
      "localidad_censal_nombre": "Pozo del Mortero",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZO DEL MORTERO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4948646893285,
      "centroide_lon": -61.6499982689001,
      "departamento_id": "34007",
      "departamento_nombre": "Bermejo",
      "id": "34007050000",
      "localidad_censal_id": "34007050",
      "localidad_censal_nombre": "Vaca Perdida",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VACA PERDIDA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6701404606648,
      "centroide_lon": -58.2626302834096,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014010000",
      "localidad_censal_id": "34014010",
      "localidad_censal_nombre": "Colonia Pastoril",
      "municipio_id": "345014",
      "municipio_nombre": "Colonia Pastoril",
      "nombre": "COLONIA PASTORIL",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1828223055764,
      "centroide_lon": -58.1733930549121,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014020000",
      "localidad_censal_id": "34014020",
      "localidad_censal_nombre": "Formosa",
      "municipio_id": "340028",
      "municipio_nombre": "Formosa",
      "nombre": "FORMOSA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.8628496341956,
      "centroide_lon": -58.8859915019649,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014030000",
      "localidad_censal_id": "34014030",
      "localidad_censal_nombre": "Gran Guardia",
      "municipio_id": "345021",
      "municipio_nombre": "Gran Guardia",
      "nombre": "GRAN GUARDIA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1106357408727,
      "centroide_lon": -58.4884270518243,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014040000",
      "localidad_censal_id": "34014040",
      "localidad_censal_nombre": "Mariano Boedo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MARIANO BOEDO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0345119719636,
      "centroide_lon": -58.0499307616902,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014050000",
      "localidad_censal_id": "34014050",
      "localidad_censal_nombre": "Mojón de Fierro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MOJON DE FIERRO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0248676792093,
      "centroide_lon": -58.6500726228772,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014060000",
      "localidad_censal_id": "34014060",
      "localidad_censal_nombre": "San Hilario",
      "municipio_id": "345028",
      "municipio_nombre": "San Hilario",
      "nombre": "SAN HILARIO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2522590977589,
      "centroide_lon": -58.252552869015,
      "departamento_id": "34014",
      "departamento_nombre": "Formosa",
      "id": "34014070000",
      "localidad_censal_id": "34014070",
      "localidad_censal_nombre": "Villa del Carmen",
      "municipio_id": "340028",
      "municipio_nombre": "Formosa",
      "nombre": "VILLA DEL CARMEN",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7071297025561,
      "centroide_lon": -58.3390745827256,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021010000",
      "localidad_censal_id": "34021010",
      "localidad_censal_nombre": "Banco Payaguá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BANCO PAYAGUA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.654180225538,
      "centroide_lon": -58.6293214756534,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021020000",
      "localidad_censal_id": "34021020",
      "localidad_censal_nombre": "General Lucio V. Mansilla",
      "municipio_id": "340049",
      "municipio_nombre": "General Lucio Victorio Mansilla",
      "nombre": "GENERAL LUCIO V MANSILLA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4874646396691,
      "centroide_lon": -58.3122424597995,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021030000",
      "localidad_censal_id": "34021030",
      "localidad_censal_nombre": "Herradura",
      "municipio_id": "340056",
      "municipio_nombre": "Herradura",
      "nombre": "HERRADURA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2423010252437,
      "centroide_lon": -58.6300642415106,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021040000",
      "localidad_censal_id": "34021040",
      "localidad_censal_nombre": "San Francisco de Laishi",
      "municipio_id": "340063",
      "municipio_nombre": "Misión San Francisco de Laishí",
      "nombre": "SAN FRANCISCO DE LAISHI",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3987186827879,
      "centroide_lon": -58.3575600493034,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021050000",
      "localidad_censal_id": "34021050",
      "localidad_censal_nombre": "Tatané",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TATANE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6211844819298,
      "centroide_lon": -58.6718336940894,
      "departamento_id": "34021",
      "departamento_nombre": "Laishí",
      "id": "34021060000",
      "localidad_censal_id": "34021060",
      "localidad_censal_nombre": "Villa Escolar",
      "municipio_id": "340070",
      "municipio_nombre": "Villa Escolar",
      "nombre": "VILLA ESCOLAR",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.8976004129254,
      "centroide_lon": -61.8538220483946,
      "departamento_id": "34028",
      "departamento_nombre": "Matacos",
      "id": "34028010000",
      "localidad_censal_id": "34028010",
      "localidad_censal_nombre": "Ingeniero Guillermo N. Juárez",
      "municipio_id": "340077",
      "municipio_nombre": "Ingeniero Guillermo N. Juárez",
      "nombre": "INGENIERO GUILLERMO N. JUAREZ",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -25.3474022268881,
      "centroide_lon": -59.6181476016645,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035010000",
      "localidad_censal_id": "34035010",
      "localidad_censal_nombre": "Bartolomé de las Casas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BARTOLOME DE LAS CASAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -25.4941218117267,
      "centroide_lon": -59.6120583687409,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035010001",
      "localidad_censal_id": "34035010",
      "localidad_censal_nombre": "Bartolomé de las Casas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BARTOLOME DE LAS CASAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -25.3482562961789,
      "centroide_lon": -59.6147617547837,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035010002",
      "localidad_censal_id": "34035010",
      "localidad_censal_nombre": "Bartolomé de las Casas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COMUNIDAD ABORIGEN BARTOLOME DE LAS CASAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6444789665474,
      "centroide_lon": -59.4332808273711,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035020000",
      "localidad_censal_id": "34035020",
      "localidad_censal_nombre": "Colonia Sarmiento",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA SARMIENTO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3347934740298,
      "centroide_lon": -59.6828071882955,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035030000",
      "localidad_censal_id": "34035030",
      "localidad_censal_nombre": "Comandante Fontana",
      "municipio_id": "340091",
      "municipio_nombre": "Comandante Fontana",
      "nombre": "COMANDANTE FONTANA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0663152545433,
      "centroide_lon": -59.2513514627224,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035040000",
      "localidad_censal_id": "34035040",
      "localidad_censal_nombre": "El Recreo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL RECREO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0531922647351,
      "centroide_lon": -60.0939271832043,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035050000",
      "localidad_censal_id": "34035050",
      "localidad_censal_nombre": "Estanislao del Campo",
      "municipio_id": "340098",
      "municipio_nombre": "Estanislao del Campo",
      "nombre": "ESTANISLAO DEL CAMPO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2854864616665,
      "centroide_lon": -59.8282499173384,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035060000",
      "localidad_censal_id": "34035060",
      "localidad_censal_nombre": "Fortín Cabo 1º Lugones",
      "municipio_id": "345042",
      "municipio_nombre": "Fortín Lugones",
      "nombre": "FORTIN CABO 1° LUGONES",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5503048524758,
      "centroide_lon": -59.390271557059,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035070000",
      "localidad_censal_id": "34035070",
      "localidad_censal_nombre": "Fortín Sargento 1º Leyes",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FORTIN SARGENTO 1° LEYES",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2113191232301,
      "centroide_lon": -59.8570398440914,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035080000",
      "localidad_censal_id": "34035080",
      "localidad_censal_nombre": "Ibarreta",
      "municipio_id": "340126",
      "municipio_nombre": "Ibarreta",
      "nombre": "IBARRETA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5418137126365,
      "centroide_lon": -60.8341896995338,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035090000",
      "localidad_censal_id": "34035090",
      "localidad_censal_nombre": "Juan G. Bazán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "JUAN G. BAZAN",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7105776136691,
      "centroide_lon": -60.5937892586839,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035100000",
      "localidad_censal_id": "34035100",
      "localidad_censal_nombre": "Las Lomitas",
      "municipio_id": "340133",
      "municipio_nombre": "Las Lomitas",
      "nombre": "LAS LOMITAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2115848629554,
      "centroide_lon": -60.198666969414,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035110000",
      "localidad_censal_id": "34035110",
      "localidad_censal_nombre": "Posta Cambio Zalazar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POSTA CAMBIO ZALAZAR",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.8975997693171,
      "centroide_lon": -60.3187890218463,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035120000",
      "localidad_censal_id": "34035120",
      "localidad_censal_nombre": "Pozo del Tigre",
      "municipio_id": "340140",
      "municipio_nombre": "Pozo del Tigre",
      "nombre": "POZO DEL TIGRE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5319053014019,
      "centroide_lon": -59.902051229705,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035130000",
      "localidad_censal_id": "34035130",
      "localidad_censal_nombre": "San Martín I",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN MARTIN I",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4331529800639,
      "centroide_lon": -59.656351890373,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035140000",
      "localidad_censal_id": "34035140",
      "localidad_censal_nombre": "San Martín II",
      "municipio_id": "340147",
      "municipio_nombre": "San Martín Dos",
      "nombre": "SAN MARTIN II",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5350397293764,
      "centroide_lon": -60.0190827630116,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035150000",
      "localidad_censal_id": "34035150",
      "localidad_censal_nombre": "Subteniente Perín",
      "municipio_id": "345049",
      "municipio_nombre": "Subteniente Perín",
      "nombre": "SUBTENIENTE PERIN",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7529456908497,
      "centroide_lon": -59.4916405606824,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035160000",
      "localidad_censal_id": "34035160",
      "localidad_censal_nombre": "Villa General Güemes",
      "municipio_id": "340119",
      "municipio_nombre": "General Güemes",
      "nombre": "VILLA GENERAL GUEMES",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9388283555888,
      "centroide_lon": -59.0290877277901,
      "departamento_id": "34035",
      "departamento_nombre": "Patiño",
      "id": "34035170000",
      "localidad_censal_id": "34035170",
      "localidad_censal_nombre": "Villa General Manuel Belgrano",
      "municipio_id": "340112",
      "municipio_nombre": "General Belgrano",
      "nombre": "VILLA GENERAL MANUEL BELGRANO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0712122663672,
      "centroide_lon": -58.3867799081037,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042010000",
      "localidad_censal_id": "34042010",
      "localidad_censal_nombre": "Buena Vista",
      "municipio_id": "345056",
      "municipio_nombre": "Buena Vista",
      "nombre": "BUENA VISTA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9799888263685,
      "centroide_lon": -58.5537877142505,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042020000",
      "localidad_censal_id": "34042020",
      "localidad_censal_nombre": "El Espinillo",
      "municipio_id": "340168",
      "municipio_nombre": "Espinillo",
      "nombre": "EL ESPINILLO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2677752833691,
      "centroide_lon": -58.7427948347143,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042030000",
      "localidad_censal_id": "34042030",
      "localidad_censal_nombre": "Laguna Gallo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAGUNA GALLO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.979650567657,
      "centroide_lon": -58.8227434439732,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042040000",
      "localidad_censal_id": "34042040",
      "localidad_censal_nombre": "Misión Tacaaglé",
      "municipio_id": "340175",
      "municipio_nombre": "Misión Taacaglé",
      "nombre": "MISION TACAAGLE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9663598975615,
      "centroide_lon": -58.7419749100008,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042050000",
      "localidad_censal_id": "34042050",
      "localidad_censal_nombre": "Portón Negro",
      "municipio_id": "349105",
      "municipio_nombre": "Portón Negro",
      "nombre": "PORTON NEGRO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2153644371658,
      "centroide_lon": -58.5132694906705,
      "departamento_id": "34042",
      "departamento_nombre": "Pilagás",
      "id": "34042060000",
      "localidad_censal_id": "34042060",
      "localidad_censal_nombre": "Tres Lagunas",
      "municipio_id": "345063",
      "municipio_nombre": "Tres Lagunas",
      "nombre": "TRES LAGUNAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2921612747741,
      "centroide_lon": -57.7178091082588,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049010000",
      "localidad_censal_id": "34049010",
      "localidad_censal_nombre": "Clorinda",
      "municipio_id": "340189",
      "municipio_nombre": "Clorinda",
      "nombre": "CLORINDA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1302522059542,
      "centroide_lon": -58.2458430258241,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049020000",
      "localidad_censal_id": "34049020",
      "localidad_censal_nombre": "Laguna Blanca",
      "municipio_id": "340169",
      "municipio_nombre": "Laguna Blanca",
      "nombre": "LAGUNA BLANCA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2193910506962,
      "centroide_lon": -58.1230791260523,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049030000",
      "localidad_censal_id": "34049030",
      "localidad_censal_nombre": "Laguna Naick-Neck",
      "municipio_id": "340203",
      "municipio_nombre": "Laguna Naik Neck",
      "nombre": "LAGUNA NAICK-NECK",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2475512345955,
      "centroide_lon": -57.9768403788083,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049040000",
      "localidad_censal_id": "34049040",
      "localidad_censal_nombre": "Palma Sola",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PALMA SOLA",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3688131700972,
      "centroide_lon": -57.6524943008573,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049050000",
      "localidad_censal_id": "34049050",
      "localidad_censal_nombre": "Puerto Pilcomayo",
      "municipio_id": "340189",
      "municipio_nombre": "Clorinda",
      "nombre": "PUERTO PILCOMAYO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3617117019838,
      "centroide_lon": -58.2782518825352,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049060000",
      "localidad_censal_id": "34049060",
      "localidad_censal_nombre": "Riacho He-He",
      "municipio_id": "340210",
      "municipio_nombre": "Riacho He-He",
      "nombre": "RIACHO HE-HE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.4224320225992,
      "centroide_lon": -57.7914545139812,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049070000",
      "localidad_censal_id": "34049070",
      "localidad_censal_nombre": "Riacho Negro",
      "municipio_id": "340189",
      "municipio_nombre": "Clorinda",
      "nombre": "RIACHO NEGRO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2015076619063,
      "centroide_lon": -58.330676377416,
      "departamento_id": "34049",
      "departamento_nombre": "Pilcomayo",
      "id": "34049080000",
      "localidad_censal_id": "34049080",
      "localidad_censal_nombre": "Siete Palmas",
      "municipio_id": "345070",
      "municipio_nombre": "Siete Palmas",
      "nombre": "SIETE PALMAS",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2048681517277,
      "centroide_lon": -59.0780331803401,
      "departamento_id": "34056",
      "departamento_nombre": "Pirané",
      "id": "34056010000",
      "localidad_censal_id": "34056010",
      "localidad_censal_nombre": "Colonia Campo Villafañe",
      "municipio_id": "340231",
      "municipio_nombre": "Mayor Vicente E. Villafañe",
      "nombre": "COLONIA CAMPO VILLAFAÑE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3117354640006,
      "centroide_lon": -59.3684434678409,
      "departamento_id": "34056",
      "departamento_nombre": "Pirané",
      "id": "34056020000",
      "localidad_censal_id": "34056020",
      "localidad_censal_nombre": "El Colorado",
      "municipio_id": "340224",
      "municipio_nombre": "El Colorado",
      "nombre": "EL COLORADO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5639063797961,
      "centroide_lon": -59.3360059836081,
      "departamento_id": "34056",
      "departamento_nombre": "Pirané",
      "id": "34056030000",
      "localidad_censal_id": "34056030",
      "localidad_censal_nombre": "Palo Santo",
      "municipio_id": "340238",
      "municipio_nombre": "Palo Santo",
      "nombre": "PALO SANTO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7306417902346,
      "centroide_lon": -59.1075205904775,
      "departamento_id": "34056",
      "departamento_nombre": "Pirané",
      "id": "34056040000",
      "localidad_censal_id": "34056040",
      "localidad_censal_nombre": "Pirané",
      "municipio_id": "340245",
      "municipio_nombre": "Pirané",
      "nombre": "PIRANE",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1864626491659,
      "centroide_lon": -59.3683950578867,
      "departamento_id": "34056",
      "departamento_nombre": "Pirané",
      "id": "34056050000",
      "localidad_censal_id": "34056050",
      "localidad_censal_nombre": "Villa Kilómetro 213",
      "municipio_id": "340252",
      "municipio_nombre": "Villa Dos Trece",
      "nombre": "VILLA KILOMETRO 213",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1607663192891,
      "centroide_lon": -62.0107787024286,
      "departamento_id": "34063",
      "departamento_nombre": "Ramón Lista",
      "id": "34063010000",
      "localidad_censal_id": "34063010",
      "localidad_censal_nombre": "El Potrillo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL POTRILLO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1827186246626,
      "centroide_lon": -62.304068544241,
      "departamento_id": "34063",
      "departamento_nombre": "Ramón Lista",
      "id": "34063020000",
      "localidad_censal_id": "34063020",
      "localidad_censal_nombre": "General Mosconi",
      "municipio_id": "340259",
      "municipio_nombre": "General Mosconi",
      "nombre": "GENERAL MOSCONI",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3394869143818,
      "centroide_lon": -61.8734659544108,
      "departamento_id": "34063",
      "departamento_nombre": "Ramón Lista",
      "id": "34063030000",
      "localidad_censal_id": "34063030",
      "localidad_censal_nombre": "El Quebracho",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL QUEBRACHO",
      "provincia_id": "34",
      "provincia_nombre": "Formosa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3419368713503,
      "centroide_lon": -66.0901014487309,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007010000",
      "localidad_censal_id": "38007010",
      "localidad_censal_nombre": "Abdón Castro Tolay",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "ABDON CASTRO TOLAY",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.7223526298672,
      "centroide_lon": -65.6965045525232,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007020000",
      "localidad_censal_id": "38007020",
      "localidad_censal_nombre": "Abra Pampa",
      "municipio_id": "380014",
      "municipio_nombre": "Abra Pampa",
      "nombre": "ABRA PAMPA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1692063444784,
      "centroide_lon": -65.7944004862785,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007030000",
      "localidad_censal_id": "38007030",
      "localidad_censal_nombre": "Abralaite",
      "municipio_id": "386014",
      "municipio_nombre": "Abralaite",
      "nombre": "ABRALAITE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2167374833476,
      "centroide_lon": -65.8082738847531,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007035000",
      "localidad_censal_id": "38007035",
      "localidad_censal_nombre": "Agua de Castilla",
      "municipio_id": "386014",
      "municipio_nombre": "Abralaite",
      "nombre": "AGUA DE CASTILLA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.9850219724833,
      "centroide_lon": -66.0328013633041,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007040000",
      "localidad_censal_id": "38007040",
      "localidad_censal_nombre": "Casabindo",
      "municipio_id": "380014",
      "municipio_nombre": "Abra Pampa",
      "nombre": "CASABINDO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.744265021174,
      "centroide_lon": -65.8951221139841,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007050000",
      "localidad_censal_id": "38007050",
      "localidad_censal_nombre": "Cochinoca",
      "municipio_id": "380014",
      "municipio_nombre": "Abra Pampa",
      "nombre": "COCHINOCA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5294608585997,
      "centroide_lon": -65.5222832606241,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007055000",
      "localidad_censal_id": "38007055",
      "localidad_censal_nombre": "La Redonda",
      "municipio_id": "386021",
      "municipio_nombre": "Puesto del Marqués",
      "nombre": "LA REDONDA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5384288906185,
      "centroide_lon": -65.6978923774993,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007060000",
      "localidad_censal_id": "38007060",
      "localidad_censal_nombre": "Puesto del Marquéz",
      "municipio_id": "386021",
      "municipio_nombre": "Puesto del Marqués",
      "nombre": "PUESTO DEL MARQUEZ",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2857891128733,
      "centroide_lon": -65.7701494793729,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007063000",
      "localidad_censal_id": "38007063",
      "localidad_censal_nombre": "Quebraleña",
      "municipio_id": "386014",
      "municipio_nombre": "Abralaite",
      "nombre": "QUEBRALEÑA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.100830176506,
      "centroide_lon": -65.7627638222671,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007067000",
      "localidad_censal_id": "38007067",
      "localidad_censal_nombre": "Quera",
      "municipio_id": "386014",
      "municipio_nombre": "Abralaite",
      "nombre": "QUERA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3846029198326,
      "centroide_lon": -65.9573754338264,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007070000",
      "localidad_censal_id": "38007070",
      "localidad_censal_nombre": "Rinconadillas",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "RINCONADILLAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3198701399198,
      "centroide_lon": -65.9744058851841,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007080000",
      "localidad_censal_id": "38007080",
      "localidad_censal_nombre": "San Francisco de Alfarcito",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "SAN FRANCISCO DE ALFARCITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1248833823314,
      "centroide_lon": -66.05216103631,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007085000",
      "localidad_censal_id": "38007085",
      "localidad_censal_nombre": "Santa Ana de la Puna",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "SANTA ANA DE LA PUNA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5332448464011,
      "centroide_lon": -65.9632841858024,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007090000",
      "localidad_censal_id": "38007090",
      "localidad_censal_nombre": "Santuario de Tres Pozos",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "SANTUARIO DE TRES POZOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.896870936112,
      "centroide_lon": -65.9620225137437,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007095000",
      "localidad_censal_id": "38007095",
      "localidad_censal_nombre": "Tambillos",
      "municipio_id": "380014",
      "municipio_nombre": "Abra Pampa",
      "nombre": "TAMBILLOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1901128134571,
      "centroide_lon": -65.9890162373631,
      "departamento_id": "38007",
      "departamento_nombre": "Cochinoca",
      "id": "38007100000",
      "localidad_censal_id": "38007100",
      "localidad_censal_nombre": "Tusaquillas",
      "municipio_id": "386007",
      "municipio_nombre": "Abdón Castro Tolay",
      "nombre": "TUSAQUILLAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.5838759624546,
      "centroide_lon": -64.9235272487523,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014010001",
      "localidad_censal_id": "38014010",
      "localidad_censal_nombre": "Aguas Calientes",
      "municipio_id": "386035",
      "municipio_nombre": "Aguas Calientes",
      "nombre": "AGUAS CALIENTES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.587376342521,
      "centroide_lon": -64.924057792361,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014010002",
      "localidad_censal_id": "38014010",
      "localidad_censal_nombre": "Aguas Calientes",
      "municipio_id": "386035",
      "municipio_nombre": "Aguas Calientes",
      "nombre": "FLEMING",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.5598827881681,
      "centroide_lon": -64.9141975517301,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014010003",
      "localidad_censal_id": "38014010",
      "localidad_censal_nombre": "Aguas Calientes",
      "municipio_id": "386035",
      "municipio_nombre": "Aguas Calientes",
      "nombre": "PILA PARDO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4610014305425,
      "centroide_lon": -65.1190583597652,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014020000",
      "localidad_censal_id": "38014020",
      "localidad_censal_nombre": "Barrio El Milagro",
      "municipio_id": "380063",
      "municipio_nombre": "Monterrico",
      "nombre": "BARRIO EL MILAGRO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4043300097514,
      "centroide_lon": -65.0697401829896,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014030000",
      "localidad_censal_id": "38014030",
      "localidad_censal_nombre": "Barrio La Unión",
      "municipio_id": "380077",
      "municipio_nombre": "Perico",
      "nombre": "BARRIO LA UNION",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3891095885225,
      "centroide_lon": -65.2605453068598,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014040000",
      "localidad_censal_id": "38014040",
      "localidad_censal_nombre": "El Carmen",
      "municipio_id": "380056",
      "municipio_nombre": "El Carmen",
      "nombre": "EL CARMEN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4759200783409,
      "centroide_lon": -65.0776012050617,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014050000",
      "localidad_censal_id": "38014050",
      "localidad_censal_nombre": "Los Lapachos",
      "municipio_id": "380063",
      "municipio_nombre": "Monterrico",
      "nombre": "LOS LAPACHOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4293248380693,
      "centroide_lon": -65.1843971543033,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014055000",
      "localidad_censal_id": "38014055",
      "localidad_censal_nombre": "Loteo San Vicente",
      "municipio_id": "380063",
      "municipio_nombre": "Monterrico",
      "nombre": "SAN VICENTE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5336970280067,
      "centroide_lon": -64.9771546670321,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014060000",
      "localidad_censal_id": "38014060",
      "localidad_censal_nombre": "Manantiales",
      "municipio_id": "386049",
      "municipio_nombre": "Puesto Viejo",
      "nombre": "MANANTIALES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4421177051028,
      "centroide_lon": -65.1622505537209,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014070000",
      "localidad_censal_id": "38014070",
      "localidad_censal_nombre": "Monterrico",
      "municipio_id": "380063",
      "municipio_nombre": "Monterrico",
      "nombre": "MONTERRICO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5325323540548,
      "centroide_lon": -65.0742522158109,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014080000",
      "localidad_censal_id": "38014080",
      "localidad_censal_nombre": "Pampa Blanca",
      "municipio_id": "386042",
      "municipio_nombre": "Pampa Blanca",
      "nombre": "PAMPA BLANCA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3807802774746,
      "centroide_lon": -65.1162073560982,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014090000",
      "localidad_censal_id": "38014090",
      "localidad_censal_nombre": "Perico",
      "municipio_id": "380077",
      "municipio_nombre": "Perico",
      "nombre": "PERICO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.485003513907,
      "centroide_lon": -64.9670262152006,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014100000",
      "localidad_censal_id": "38014100",
      "localidad_censal_nombre": "Puesto Viejo",
      "municipio_id": "386049",
      "municipio_nombre": "Puesto Viejo",
      "nombre": "PUESTO VIEJO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5556368462513,
      "centroide_lon": -64.9424207944567,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014110000",
      "localidad_censal_id": "38014110",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "386035",
      "municipio_nombre": "Aguas Calientes",
      "nombre": "SAN ISIDRO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3843856940085,
      "centroide_lon": -64.9937381095943,
      "departamento_id": "38014",
      "departamento_nombre": "El Carmen",
      "id": "38014120000",
      "localidad_censal_id": "38014120",
      "localidad_censal_nombre": "San Juancito",
      "municipio_id": "380077",
      "municipio_nombre": "Perico",
      "nombre": "SAN JUANCITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1859719091941,
      "centroide_lon": -65.4487115603426,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021010000",
      "localidad_censal_id": "38021010",
      "localidad_censal_nombre": "Guerrero",
      "municipio_id": "380035",
      "municipio_nombre": "San Salvador de Jujuy",
      "nombre": "GUERRERO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2656799176145,
      "centroide_lon": -65.3972038835293,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021020000",
      "localidad_censal_id": "38021020",
      "localidad_censal_nombre": "La Almona",
      "municipio_id": "380035",
      "municipio_nombre": "San Salvador de Jujuy",
      "nombre": "LA ALMONA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.0395227299023,
      "centroide_lon": -65.4309577730475,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021030000",
      "localidad_censal_id": "38021030",
      "localidad_censal_nombre": "León",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "LEON",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.0820553565565,
      "centroide_lon": -65.4037790153252,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021040000",
      "localidad_censal_id": "38021040",
      "localidad_censal_nombre": "Lozano",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "LOZANO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9440584396018,
      "centroide_lon": -65.2322154882925,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021050000",
      "localidad_censal_id": "38021050",
      "localidad_censal_nombre": "Ocloyas",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "OCLOYAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.1844139008337,
      "centroide_lon": -65.3039986701092,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021060000",
      "localidad_censal_id": "38021060",
      "localidad_censal_nombre": "San Salvador de Jujuy",
      "municipio_id": "380035",
      "municipio_nombre": "San Salvador de Jujuy",
      "nombre": "SAN SALVADOR DE JUJUY",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9486188183993,
      "centroide_lon": -65.2985111585154,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021065000",
      "localidad_censal_id": "38021065",
      "localidad_censal_nombre": "Tesorero",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "TESORERO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.0840270539675,
      "centroide_lon": -65.4760846258348,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021070001",
      "localidad_censal_id": "38021070",
      "localidad_censal_nombre": "Yala",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "LOS NOGALES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.1436588948106,
      "centroide_lon": -65.482919981479,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021070002",
      "localidad_censal_id": "38021070",
      "localidad_censal_nombre": "Yala",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "SAN PABLO DE REYES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.1217546163942,
      "centroide_lon": -65.4145726152103,
      "departamento_id": "38021",
      "departamento_nombre": "Dr. Manuel Belgrano",
      "id": "38021070003",
      "localidad_censal_id": "38021070",
      "localidad_censal_nombre": "Yala",
      "municipio_id": "386028",
      "municipio_nombre": "Yala",
      "nombre": "YALA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.0992150250753,
      "centroide_lon": -65.1865385997584,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028003000",
      "localidad_censal_id": "38028003",
      "localidad_censal_nombre": "Aparzo",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "APARZO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1732331272464,
      "centroide_lon": -65.1578176759527,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028007000",
      "localidad_censal_id": "38028007",
      "localidad_censal_nombre": "Cianzo",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "CIANZO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1543348556287,
      "centroide_lon": -65.2936644075552,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028010000",
      "localidad_censal_id": "38028010",
      "localidad_censal_nombre": "Coctaca",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "COCTACA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2139846798174,
      "centroide_lon": -65.6788754303526,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028020000",
      "localidad_censal_id": "38028020",
      "localidad_censal_nombre": "El Aguilar",
      "municipio_id": "380091",
      "municipio_nombre": "El Aguilar",
      "nombre": "EL AGUILAR",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.9790987916947,
      "centroide_lon": -65.3527979015935,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028030000",
      "localidad_censal_id": "38028030",
      "localidad_censal_nombre": "Hipólito Yrigoyen",
      "municipio_id": "386056",
      "municipio_nombre": "Hipólito Yrigoyen",
      "nombre": "HIPOLITO YRIGOYEN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2118256384836,
      "centroide_lon": -65.3507419495251,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028040000",
      "localidad_censal_id": "38028040",
      "localidad_censal_nombre": "Humahuaca",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "HUMAHUACA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1220406414082,
      "centroide_lon": -65.134806337775,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028043000",
      "localidad_censal_id": "38028043",
      "localidad_censal_nombre": "Palca de Aparzo",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "PALCA DE APARZO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1018612642193,
      "centroide_lon": -65.112920847023,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028045000",
      "localidad_censal_id": "38028045",
      "localidad_censal_nombre": "Palca de Varas",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "PALCA DE VARAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.0621839336364,
      "centroide_lon": -65.3260425196353,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028047000",
      "localidad_censal_id": "38028047",
      "localidad_censal_nombre": "Rodero",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "RODERO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.9169380002989,
      "centroide_lon": -65.5878327366614,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028050000",
      "localidad_censal_id": "38028050",
      "localidad_censal_nombre": "Tres Cruces",
      "municipio_id": "386063",
      "municipio_nombre": "Tres Cruces",
      "nombre": "TRES CRUCES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3041905968327,
      "centroide_lon": -65.356558164038,
      "departamento_id": "38028",
      "departamento_nombre": "Humahuaca",
      "id": "38028060000",
      "localidad_censal_id": "38028060",
      "localidad_censal_nombre": "Uquía",
      "municipio_id": "380105",
      "municipio_nombre": "Humahuaca",
      "nombre": "UQUIA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.565439613584,
      "centroide_lon": -64.5011631978961,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035010000",
      "localidad_censal_id": "38035010",
      "localidad_censal_nombre": "Bananal",
      "municipio_id": "380147",
      "municipio_nombre": "Yuto",
      "nombre": "BANANAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7881113454008,
      "centroide_lon": -64.73005792215,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035020000",
      "localidad_censal_id": "38035020",
      "localidad_censal_nombre": "Bermejito",
      "municipio_id": "380126",
      "municipio_nombre": "Calilegua",
      "nombre": "BERMEJITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7390273358675,
      "centroide_lon": -64.5927674367986,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035030000",
      "localidad_censal_id": "38035030",
      "localidad_censal_nombre": "Caimancito",
      "municipio_id": "380119",
      "municipio_nombre": "Caimancito",
      "nombre": "CAIMANCITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.77580785145,
      "centroide_lon": -64.7714502012243,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035040000",
      "localidad_censal_id": "38035040",
      "localidad_censal_nombre": "Calilegua",
      "municipio_id": "380126",
      "municipio_nombre": "Calilegua",
      "nombre": "CALILEGUA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.0701319470485,
      "centroide_lon": -64.8068636697633,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035050000",
      "localidad_censal_id": "38035050",
      "localidad_censal_nombre": "Chalicán",
      "municipio_id": "380133",
      "municipio_nombre": "Fraile Pintado",
      "nombre": "CHALICAN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9420214191485,
      "centroide_lon": -64.8029446573235,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035060000",
      "localidad_censal_id": "38035060",
      "localidad_censal_nombre": "Fraile Pintado",
      "municipio_id": "380133",
      "municipio_nombre": "Fraile Pintado",
      "nombre": "FRAILE PINTADO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7623858141283,
      "centroide_lon": -64.7259945367992,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035070000",
      "localidad_censal_id": "38035070",
      "localidad_censal_nombre": "Libertad",
      "municipio_id": "380126",
      "municipio_nombre": "Calilegua",
      "nombre": "LIBERTAD",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -23.8091072493712,
      "centroide_lon": -64.7927821518223,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035080000",
      "localidad_censal_id": "38035080",
      "localidad_censal_nombre": "Libertador General San Martín",
      "municipio_id": "380140",
      "municipio_nombre": "Libertador General San Martín",
      "nombre": "LIBERTADOR GENERAL SAN MARTIN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.8226030728583,
      "centroide_lon": -64.9000065559959,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035080001",
      "localidad_censal_id": "38035080",
      "localidad_censal_nombre": "Libertador General San Martín",
      "municipio_id": "380140",
      "municipio_nombre": "Libertador General San Martín",
      "nombre": "LIBERTADOR GENERAL SAN MARTIN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.8273072049676,
      "centroide_lon": -64.791360563704,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035080002",
      "localidad_censal_id": "38035080",
      "localidad_censal_nombre": "Libertador General San Martín",
      "municipio_id": "380140",
      "municipio_nombre": "Libertador General San Martín",
      "nombre": "PUEBLO LEDESMA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9506323797642,
      "centroide_lon": -64.7736232797616,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035090000",
      "localidad_censal_id": "38035090",
      "localidad_censal_nombre": "Maíz Negro",
      "municipio_id": "380133",
      "municipio_nombre": "Fraile Pintado",
      "nombre": "MAIZ NEGRO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.8445381270952,
      "centroide_lon": -64.7389846268417,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035100000",
      "localidad_censal_id": "38035100",
      "localidad_censal_nombre": "Paulina",
      "municipio_id": "380140",
      "municipio_nombre": "Libertador General San Martín",
      "nombre": "PAULINA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6473910528443,
      "centroide_lon": -64.4683378490048,
      "departamento_id": "38035",
      "departamento_nombre": "Ledesma",
      "id": "38035110000",
      "localidad_censal_id": "38035110",
      "localidad_censal_nombre": "Yuto",
      "municipio_id": "380147",
      "municipio_nombre": "Yuto",
      "nombre": "YUTO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3076479727425,
      "centroide_lon": -65.0834468981217,
      "departamento_id": "38042",
      "departamento_nombre": "Palpalá",
      "id": "38042010000",
      "localidad_censal_id": "38042010",
      "localidad_censal_nombre": "Carahunco",
      "municipio_id": "380154",
      "municipio_nombre": "Palpalá",
      "nombre": "CARAHUNCO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2287296415511,
      "centroide_lon": -65.1776468508489,
      "departamento_id": "38042",
      "departamento_nombre": "Palpalá",
      "id": "38042020000",
      "localidad_censal_id": "38042020",
      "localidad_censal_nombre": "Centro Forestal",
      "municipio_id": "380154",
      "municipio_nombre": "Palpalá",
      "nombre": "CENTRO FORESTAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.2660503354304,
      "centroide_lon": -65.2120271030598,
      "departamento_id": "38042",
      "departamento_nombre": "Palpalá",
      "id": "38042040001",
      "localidad_censal_id": "38042040",
      "localidad_censal_nombre": "Palpalá",
      "municipio_id": "380154",
      "municipio_nombre": "Palpalá",
      "nombre": "PALPALA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.2342342593785,
      "centroide_lon": -65.2300747773282,
      "departamento_id": "38042",
      "departamento_nombre": "Palpalá",
      "id": "38042040002",
      "localidad_censal_id": "38042040",
      "localidad_censal_nombre": "Palpalá",
      "municipio_id": "380154",
      "municipio_nombre": "Palpalá",
      "nombre": "RIO BLANCO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.3253946072654,
      "centroide_lon": -66.2837654791341,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049003000",
      "localidad_censal_id": "38049003",
      "localidad_censal_nombre": "Casa Colorada",
      "municipio_id": "386077",
      "municipio_nombre": "Rinconada",
      "nombre": "CASA COLORADA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.7704435619992,
      "centroide_lon": -66.440426993556,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049007000",
      "localidad_censal_id": "38049007",
      "localidad_censal_nombre": "Coyaguaima",
      "municipio_id": "386070",
      "municipio_nombre": "Mina Pirquitas",
      "nombre": "COYAGUAIMA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4621492240335,
      "centroide_lon": -66.6281060495323,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049010000",
      "localidad_censal_id": "38049010",
      "localidad_censal_nombre": "Lagunillas de Farallón",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "LAGUNILLAS DE FARALLON",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5205427089875,
      "centroide_lon": -66.3370942259576,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049020000",
      "localidad_censal_id": "38049020",
      "localidad_censal_nombre": "Liviara",
      "municipio_id": "386070",
      "municipio_nombre": "Mina Pirquitas",
      "nombre": "LIVIARA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4715310141327,
      "centroide_lon": -66.4842002316312,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049025000",
      "localidad_censal_id": "38049025",
      "localidad_censal_nombre": "Loma Blanca",
      "municipio_id": "386070",
      "municipio_nombre": "Mina Pirquitas",
      "nombre": "LOMA BLANCA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.6879554218994,
      "centroide_lon": -66.4563756677863,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049030000",
      "localidad_censal_id": "38049030",
      "localidad_censal_nombre": "Nuevo Pirquitas",
      "municipio_id": "386070",
      "municipio_nombre": "Mina Pirquitas",
      "nombre": "NUEVO PIRQUITAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5570555914003,
      "centroide_lon": -66.3563627312909,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049035000",
      "localidad_censal_id": "38049035",
      "localidad_censal_nombre": "Orosmayo",
      "municipio_id": "386070",
      "municipio_nombre": "Mina Pirquitas",
      "nombre": "OROSMAYO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4403477816105,
      "centroide_lon": -66.1673735179392,
      "departamento_id": "38049",
      "departamento_nombre": "Rinconada",
      "id": "38049040000",
      "localidad_censal_id": "38049040",
      "localidad_censal_nombre": "Rinconada",
      "municipio_id": "386077",
      "municipio_nombre": "Rinconada",
      "nombre": "RINCONADA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3013918074401,
      "centroide_lon": -65.2794378200693,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056010000",
      "localidad_censal_id": "38056010",
      "localidad_censal_nombre": "El Ceibal",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "EL CEIBAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2694294471429,
      "centroide_lon": -65.3038293509073,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056017000",
      "localidad_censal_id": "38056017",
      "localidad_censal_nombre": "Los Alisos",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "LOS ALISOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2701433897522,
      "centroide_lon": -65.2740648045537,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056020000",
      "localidad_censal_id": "38056020",
      "localidad_censal_nombre": "Loteo Navea",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "LOTEO NAVEA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3184045981955,
      "centroide_lon": -65.400922691233,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056025000",
      "localidad_censal_id": "38056025",
      "localidad_censal_nombre": "Nuestra Señora del Rosario",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "NUESTRA SE?ORA DEL ROSARIO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3672400343176,
      "centroide_lon": -65.3377007673345,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056030000",
      "localidad_censal_id": "38056030",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "SAN ANTONIO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1768075323298,
      "centroide_lon": -64.8403333563822,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063010000",
      "localidad_censal_id": "38063010",
      "localidad_censal_nombre": "Arrayanal",
      "municipio_id": "386091",
      "municipio_nombre": "Arrayanal",
      "nombre": "ARRAYANAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3365556568725,
      "centroide_lon": -64.6635805195007,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063020000",
      "localidad_censal_id": "38063020",
      "localidad_censal_nombre": "Arroyo Colorado",
      "municipio_id": "380224",
      "municipio_nombre": "San Pedro de Jujuy",
      "nombre": "ARROYO COLORADO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3167328574824,
      "centroide_lon": -64.9078893331684,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063030000",
      "localidad_censal_id": "38063030",
      "localidad_censal_nombre": "Don Emilio",
      "municipio_id": "386112",
      "municipio_nombre": "Rosario de Río Grande",
      "nombre": "DON EMILIO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4035872902004,
      "centroide_lon": -64.8010495450204,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063040000",
      "localidad_censal_id": "38063040",
      "localidad_censal_nombre": "El Acheral",
      "municipio_id": "380224",
      "municipio_nombre": "San Pedro de Jujuy",
      "nombre": "EL ACHERAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1989198465405,
      "centroide_lon": -64.7892891441781,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063050000",
      "localidad_censal_id": "38063050",
      "localidad_censal_nombre": "El Puesto",
      "municipio_id": "380196",
      "municipio_nombre": "La Esperanza",
      "nombre": "EL PUESTO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1058565563838,
      "centroide_lon": -64.823062158756,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063060000",
      "localidad_censal_id": "38063060",
      "localidad_censal_nombre": "El Quemado",
      "municipio_id": "386091",
      "municipio_nombre": "Arrayanal",
      "nombre": "EL QUEMADO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -24.2237083446708,
      "centroide_lon": -64.8373717906107,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063070000",
      "localidad_censal_id": "38063070",
      "localidad_censal_nombre": "La Esperanza",
      "municipio_id": "380196",
      "municipio_nombre": "La Esperanza",
      "nombre": "LA ESPERANZA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.1691296094135,
      "centroide_lon": -64.7828725492928,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063070001",
      "localidad_censal_id": "38063070",
      "localidad_censal_nombre": "La Esperanza",
      "municipio_id": "386091",
      "municipio_nombre": "Arrayanal",
      "nombre": "LA ESPERANZA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.191027398496,
      "centroide_lon": -64.847140304232,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063080000",
      "localidad_censal_id": "38063080",
      "localidad_censal_nombre": "La Manga",
      "municipio_id": "386091",
      "municipio_nombre": "Arrayanal",
      "nombre": "LA MANGA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3164922129456,
      "centroide_lon": -64.9670192886888,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063090000",
      "localidad_censal_id": "38063090",
      "localidad_censal_nombre": "La Mendieta",
      "municipio_id": "380203",
      "municipio_nombre": "La Mendieta",
      "nombre": "LA MENDIETA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.2247852710015,
      "centroide_lon": -64.8201208754985,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063100000",
      "localidad_censal_id": "38063100",
      "localidad_censal_nombre": "Miraflores",
      "municipio_id": "380196",
      "municipio_nombre": "La Esperanza",
      "nombre": "MIRAFLORES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3269093157963,
      "centroide_lon": -64.9472509634032,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063110000",
      "localidad_censal_id": "38063110",
      "localidad_censal_nombre": "Palos Blancos",
      "municipio_id": "386112",
      "municipio_nombre": "Rosario de Río Grande",
      "nombre": "PALOS BLANCOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.236443947266,
      "centroide_lon": -64.8448511951718,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063120000",
      "localidad_censal_id": "38063120",
      "localidad_censal_nombre": "Parapetí",
      "municipio_id": "380196",
      "municipio_nombre": "La Esperanza",
      "nombre": "PARAPETI",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2961714666534,
      "centroide_lon": -64.8821863479764,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063130000",
      "localidad_censal_id": "38063130",
      "localidad_censal_nombre": "Piedritas",
      "municipio_id": "386112",
      "municipio_nombre": "Rosario de Río Grande",
      "nombre": "PIEDRITAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2716140097952,
      "centroide_lon": -64.768970964685,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063140000",
      "localidad_censal_id": "38063140",
      "localidad_censal_nombre": "Rodeito",
      "municipio_id": "386105",
      "municipio_nombre": "Rodeíto",
      "nombre": "RODEITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3085245850739,
      "centroide_lon": -64.9316446809993,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063150000",
      "localidad_censal_id": "38063150",
      "localidad_censal_nombre": "Rosario de Río Grande (ex Barro Negro)",
      "municipio_id": "386112",
      "municipio_nombre": "Rosario de Río Grande",
      "nombre": "ROSARIO DE RIO GRANDE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.219671559876,
      "centroide_lon": -64.8045172910273,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063160000",
      "localidad_censal_id": "38063160",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "380196",
      "municipio_nombre": "La Esperanza",
      "nombre": "SAN ANTONIO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2685961573007,
      "centroide_lon": -64.8642893202695,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063170000",
      "localidad_censal_id": "38063170",
      "localidad_censal_nombre": "San Lucas",
      "municipio_id": "386105",
      "municipio_nombre": "Rodeíto",
      "nombre": "SAN LUCAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.2313021129282,
      "centroide_lon": -64.8681693070463,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063180000",
      "localidad_censal_id": "38063180",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "380224",
      "municipio_nombre": "San Pedro de Jujuy",
      "nombre": "SAN PEDRO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2987583243037,
      "centroide_lon": -64.9571556712013,
      "departamento_id": "38063",
      "departamento_nombre": "San Pedro",
      "id": "38063190000",
      "localidad_censal_id": "38063190",
      "localidad_censal_nombre": "Sauzal",
      "municipio_id": "380203",
      "municipio_nombre": "La Mendieta",
      "nombre": "SAUZAL",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.260560497029,
      "centroide_lon": -64.4155132039794,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070010000",
      "localidad_censal_id": "38070010",
      "localidad_censal_nombre": "El Fuerte",
      "municipio_id": "386098",
      "municipio_nombre": "El Fuerte",
      "nombre": "EL FUERTE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1791481712823,
      "centroide_lon": -64.6776330087428,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070020000",
      "localidad_censal_id": "38070020",
      "localidad_censal_nombre": "El Piquete",
      "municipio_id": "386119",
      "municipio_nombre": "El Piquete",
      "nombre": "EL PIQUETE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5555611045113,
      "centroide_lon": -64.3643099072085,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070030000",
      "localidad_censal_id": "38070030",
      "localidad_censal_nombre": "El Talar",
      "municipio_id": "380238",
      "municipio_nombre": "El Talar",
      "nombre": "EL TALAR",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9678426401893,
      "centroide_lon": -64.3034683351392,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070040000",
      "localidad_censal_id": "38070040",
      "localidad_censal_nombre": "Palma Sola",
      "municipio_id": "380245",
      "municipio_nombre": "Palma Sola",
      "nombre": "PALMA SOLA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2725269338812,
      "centroide_lon": -64.7161865903699,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070050000",
      "localidad_censal_id": "38070050",
      "localidad_censal_nombre": "Puente Lavayén",
      "municipio_id": "380252",
      "municipio_nombre": "Santa Clara",
      "nombre": "PUENTE LAVAYEN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3078151766845,
      "centroide_lon": -64.6610648577922,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070060000",
      "localidad_censal_id": "38070060",
      "localidad_censal_nombre": "Santa Clara",
      "municipio_id": "380252",
      "municipio_nombre": "Santa Clara",
      "nombre": "SANTA CLARA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6681883228337,
      "centroide_lon": -64.4131178374229,
      "departamento_id": "38070",
      "departamento_nombre": "Santa Bárbara",
      "id": "38070070000",
      "localidad_censal_id": "38070070",
      "localidad_censal_nombre": "Vinalito",
      "municipio_id": "386126",
      "municipio_nombre": "Vinalito",
      "nombre": "VINALITO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -21.9788187103851,
      "centroide_lon": -65.8958558436093,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077010000",
      "localidad_censal_id": "38077010",
      "localidad_censal_nombre": "Casira",
      "municipio_id": "386133",
      "municipio_nombre": "Cieneguillas",
      "nombre": "CASIRA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1769689683858,
      "centroide_lon": -66.4139607069096,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077020000",
      "localidad_censal_id": "38077020",
      "localidad_censal_nombre": "Ciénega de Paicone",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "CIENEGA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1009002170764,
      "centroide_lon": -65.867209693443,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077030000",
      "localidad_censal_id": "38077030",
      "localidad_censal_nombre": "Cieneguillas",
      "municipio_id": "386133",
      "municipio_nombre": "Cieneguillas",
      "nombre": "CIENEGUILLAS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.3402234558008,
      "centroide_lon": -66.4926397388327,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077040000",
      "localidad_censal_id": "38077040",
      "localidad_censal_nombre": "Cusi Cusi",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "CUSI CUSI",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -21.8764898712784,
      "centroide_lon": -66.1886427089451,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077045000",
      "localidad_censal_id": "38077045",
      "localidad_censal_nombre": "El Angosto",
      "municipio_id": "386147",
      "municipio_nombre": "Santa Catalina",
      "nombre": "EL ANGOSTO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -21.9606332568994,
      "centroide_lon": -66.263084676456,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077050000",
      "localidad_censal_id": "38077050",
      "localidad_censal_nombre": "La Ciénega",
      "municipio_id": "386147",
      "municipio_nombre": "Santa Catalina",
      "nombre": "LA CIENEGA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2593865040609,
      "centroide_lon": -66.3668608819379,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077060000",
      "localidad_censal_id": "38077060",
      "localidad_censal_nombre": "Misarrumi",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "MISARRUMI",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.0991710039884,
      "centroide_lon": -66.1325561087962,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077070000",
      "localidad_censal_id": "38077070",
      "localidad_censal_nombre": "Oratorio",
      "municipio_id": "386147",
      "municipio_nombre": "Santa Catalina",
      "nombre": "ORATORIO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2193202745887,
      "centroide_lon": -66.4252604137157,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077080000",
      "localidad_censal_id": "38077080",
      "localidad_censal_nombre": "Paicone",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "PAICONE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2243859011206,
      "centroide_lon": -66.2489981842614,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077090000",
      "localidad_censal_id": "38077090",
      "localidad_censal_nombre": "San Juan de Oros",
      "municipio_id": "386140",
      "municipio_nombre": "Cusi Cusi",
      "nombre": "SAN JUAN DE OROS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -21.9457402673178,
      "centroide_lon": -66.0524460482788,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077100000",
      "localidad_censal_id": "38077100",
      "localidad_censal_nombre": "Santa Catalina",
      "municipio_id": "386147",
      "municipio_nombre": "Santa Catalina",
      "nombre": "SANTA CATALINA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1671932548934,
      "centroide_lon": -66.0247356350832,
      "departamento_id": "38077",
      "departamento_nombre": "Santa Catalina",
      "id": "38077110000",
      "localidad_censal_id": "38077110",
      "localidad_censal_nombre": "Yoscaba",
      "municipio_id": "386133",
      "municipio_nombre": "Cieneguillas",
      "nombre": "YOSCABA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.8697878292198,
      "centroide_lon": -67.0052751921339,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084010000",
      "localidad_censal_id": "38084010",
      "localidad_censal_nombre": "Catua",
      "municipio_id": "386154",
      "municipio_nombre": "Catua",
      "nombre": "CATUA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.0145482730193,
      "centroide_lon": -66.3666374411625,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084020000",
      "localidad_censal_id": "38084020",
      "localidad_censal_nombre": "Coranzuli",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "CORANZULI",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.0836526940797,
      "centroide_lon": -66.7015910904293,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084030000",
      "localidad_censal_id": "38084030",
      "localidad_censal_nombre": "El Toro",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "EL TORO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5638430752997,
      "centroide_lon": -66.4093156495626,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084040000",
      "localidad_censal_id": "38084040",
      "localidad_censal_nombre": "Huáncar",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "HUANCAR",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2381696240536,
      "centroide_lon": -67.0272646457146,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084045000",
      "localidad_censal_id": "38084045",
      "localidad_censal_nombre": "Jama",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "JAMA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2654412559675,
      "centroide_lon": -66.6556621241901,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084050000",
      "localidad_censal_id": "38084050",
      "localidad_censal_nombre": "Mina Providencia",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "MINA PROVIDENCIA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1152805507803,
      "centroide_lon": -66.7186508694002,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084055000",
      "localidad_censal_id": "38084055",
      "localidad_censal_nombre": "Olacapato",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "OLACAPATO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3934649138842,
      "centroide_lon": -66.8017391151812,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084060000",
      "localidad_censal_id": "38084060",
      "localidad_censal_nombre": "Olaroz Chico",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "OLAROZ CHICO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7667799522763,
      "centroide_lon": -66.4362241723231,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084070000",
      "localidad_censal_id": "38084070",
      "localidad_censal_nombre": "Pastos Chicos",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "PASTOS CHICOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9450800745364,
      "centroide_lon": -66.4880705176388,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084080000",
      "localidad_censal_id": "38084080",
      "localidad_censal_nombre": "Puesto Sey",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "PUESTO SEY",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2303525084842,
      "centroide_lon": -66.3437115747179,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084090000",
      "localidad_censal_id": "38084090",
      "localidad_censal_nombre": "San Juan de Quillaqués",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "SAN JUAN DE QUILLAQUES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3993487788994,
      "centroide_lon": -66.3663509046119,
      "departamento_id": "38084",
      "departamento_nombre": "Susques",
      "id": "38084100000",
      "localidad_censal_id": "38084100",
      "localidad_censal_nombre": "Susques",
      "municipio_id": "386161",
      "municipio_nombre": "Coranzuli",
      "nombre": "SUSQUES",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.4023544678311,
      "centroide_lon": -65.3910451552268,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094010001",
      "localidad_censal_id": "38094010",
      "localidad_censal_nombre": "Colonia San José",
      "municipio_id": "386175",
      "municipio_nombre": "Huacalera",
      "nombre": "COLONIA SAN JOSE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.4023544678311,
      "centroide_lon": -65.3910451552268,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094010002",
      "localidad_censal_id": "38094010",
      "localidad_censal_nombre": "Colonia San José",
      "municipio_id": "386175",
      "municipio_nombre": "Huacalera",
      "nombre": "YACORAITE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4369569456923,
      "centroide_lon": -65.3498233567527,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094020000",
      "localidad_censal_id": "38094020",
      "localidad_censal_nombre": "Huacalera",
      "municipio_id": "386175",
      "municipio_nombre": "Huacalera",
      "nombre": "HUACALERA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5175106247741,
      "centroide_lon": -65.4081712327054,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094030000",
      "localidad_censal_id": "38094030",
      "localidad_censal_nombre": "Juella",
      "municipio_id": "380329",
      "municipio_nombre": "Tilcara",
      "nombre": "JUELLA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6251650641141,
      "centroide_lon": -65.4090898914489,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094040000",
      "localidad_censal_id": "38094040",
      "localidad_censal_nombre": "Maimará",
      "municipio_id": "386182",
      "municipio_nombre": "Maimará",
      "nombre": "MAIMARA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5758626116635,
      "centroide_lon": -65.3936940685486,
      "departamento_id": "38094",
      "departamento_nombre": "Tilcara",
      "id": "38094050000",
      "localidad_censal_id": "38094050",
      "localidad_censal_nombre": "Tilcara",
      "municipio_id": "380329",
      "municipio_nombre": "Tilcara",
      "nombre": "TILCARA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9826097875936,
      "centroide_lon": -65.4550931589626,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098010000",
      "localidad_censal_id": "38098010",
      "localidad_censal_nombre": "Bárcena",
      "municipio_id": "386203",
      "municipio_nombre": "Volcán",
      "nombre": "BARCENA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.8509115379981,
      "centroide_lon": -65.8316510262887,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098020000",
      "localidad_censal_id": "38098020",
      "localidad_censal_nombre": "El Moreno",
      "municipio_id": "386189",
      "municipio_nombre": "Purmamarca",
      "nombre": "EL MORENO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5715104796996,
      "centroide_lon": -65.698000202945,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098025000",
      "localidad_censal_id": "38098025",
      "localidad_censal_nombre": "Puerta de Colorados",
      "municipio_id": "386189",
      "municipio_nombre": "Purmamarca",
      "nombre": "PUERTA DE COLORADOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7450544260753,
      "centroide_lon": -65.5003903899528,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098030000",
      "localidad_censal_id": "38098030",
      "localidad_censal_nombre": "Purmamarca",
      "municipio_id": "386189",
      "municipio_nombre": "Purmamarca",
      "nombre": "PURMAMARCA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.8561961637236,
      "centroide_lon": -65.4663650677457,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098040000",
      "localidad_censal_id": "38098040",
      "localidad_censal_nombre": "Tumbaya",
      "municipio_id": "386196",
      "municipio_nombre": "Tumbaya",
      "nombre": "TUMBAYA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9165986512201,
      "centroide_lon": -65.4655058903446,
      "departamento_id": "38098",
      "departamento_nombre": "Tumbaya",
      "id": "38098050000",
      "localidad_censal_id": "38098050",
      "localidad_censal_nombre": "Volcán",
      "municipio_id": "386203",
      "municipio_nombre": "Volcán",
      "nombre": "VOLCAN",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3626169910686,
      "centroide_lon": -65.0914951382906,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105010000",
      "localidad_censal_id": "38105010",
      "localidad_censal_nombre": "Caspalá",
      "municipio_id": "386210",
      "municipio_nombre": "Caspalá",
      "nombre": "CASPALA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5548845300142,
      "centroide_lon": -65.0066887909796,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105020000",
      "localidad_censal_id": "38105020",
      "localidad_censal_nombre": "Pampichuela",
      "municipio_id": "386217",
      "municipio_nombre": "Pampichuela",
      "nombre": "PAMPICHUELA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6213393482903,
      "centroide_lon": -64.9501640840905,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105030000",
      "localidad_censal_id": "38105030",
      "localidad_censal_nombre": "San Francisco",
      "municipio_id": "386224",
      "municipio_nombre": "San Francisco",
      "nombre": "SAN FRANCISCO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3564321535763,
      "centroide_lon": -64.9877247699547,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105040000",
      "localidad_censal_id": "38105040",
      "localidad_censal_nombre": "Santa Ana",
      "municipio_id": "386231",
      "municipio_nombre": "Santa Ana",
      "nombre": "SANTA ANA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4139544374567,
      "centroide_lon": -64.93377255055,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105050000",
      "localidad_censal_id": "38105050",
      "localidad_censal_nombre": "Valle Colorado",
      "municipio_id": "386231",
      "municipio_nombre": "Santa Ana",
      "nombre": "VALLE COLORADO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4758465611018,
      "centroide_lon": -64.9469047343163,
      "departamento_id": "38105",
      "departamento_nombre": "Valle Grande",
      "id": "38105060000",
      "localidad_censal_id": "38105060",
      "localidad_censal_nombre": "Valle Grande",
      "municipio_id": "386238",
      "municipio_nombre": "Valle Grande",
      "nombre": "VALLE GRANDE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2477578344269,
      "centroide_lon": -65.529042968307,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112010000",
      "localidad_censal_id": "38112010",
      "localidad_censal_nombre": "Barrios",
      "municipio_id": "386245",
      "municipio_nombre": "Barrios",
      "nombre": "BARRIOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.423791437897,
      "centroide_lon": -65.579872144671,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112020000",
      "localidad_censal_id": "38112020",
      "localidad_censal_nombre": "Cangrejillos",
      "municipio_id": "386252",
      "municipio_nombre": "Cangrejillos",
      "nombre": "CANGREJILLOS",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.3866192862254,
      "centroide_lon": -65.3395952168589,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112030000",
      "localidad_censal_id": "38112030",
      "localidad_censal_nombre": "El Cóndor",
      "municipio_id": "386259",
      "municipio_nombre": "El Cóndor",
      "nombre": "EL CONDOR",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4230485492938,
      "centroide_lon": -65.7004227358985,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112040000",
      "localidad_censal_id": "38112040",
      "localidad_censal_nombre": "La Intermedia",
      "municipio_id": "386266",
      "municipio_nombre": "Pumahuasi",
      "nombre": "LA INTERMEDIA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1064369293997,
      "centroide_lon": -65.5957257876868,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112050000",
      "localidad_censal_id": "38112050",
      "localidad_censal_nombre": "La Quiaca",
      "municipio_id": "380406",
      "municipio_nombre": "La Quiaca",
      "nombre": "LA QUIACA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5507612616611,
      "centroide_lon": -65.3872645925388,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112060000",
      "localidad_censal_id": "38112060",
      "localidad_censal_nombre": "Llulluchayoc",
      "municipio_id": "386021",
      "municipio_nombre": "Puesto del Marqués",
      "nombre": "LLULLUCHAYOC",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2899363240929,
      "centroide_lon": -65.6798827753743,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112070000",
      "localidad_censal_id": "38112070",
      "localidad_censal_nombre": "Pumahuasi",
      "municipio_id": "386266",
      "municipio_nombre": "Pumahuasi",
      "nombre": "PUMAHUASI",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.1378271107898,
      "centroide_lon": -65.3097886156379,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112080001",
      "localidad_censal_id": "38112080",
      "localidad_censal_nombre": "Yavi",
      "municipio_id": "386273",
      "municipio_nombre": "Yavi",
      "nombre": "SAN JOSE",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.0983777844843,
      "centroide_lon": -65.4559189939558,
      "departamento_id": "38112",
      "departamento_nombre": "Yaví",
      "id": "38112090000",
      "localidad_censal_id": "38112090",
      "localidad_censal_nombre": "Yavi Chico",
      "municipio_id": "386273",
      "municipio_nombre": "Yavi",
      "nombre": "YAVI CHICO",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1521838816608,
      "centroide_lon": -64.0140550927574,
      "departamento_id": "42007",
      "departamento_nombre": "Atreucó",
      "id": "42007010000",
      "localidad_censal_id": "42007010",
      "localidad_censal_nombre": "Doblas",
      "municipio_id": "420112",
      "municipio_nombre": "Doblas",
      "nombre": "DOBLAS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1369555361687,
      "centroide_lon": -63.667141835534,
      "departamento_id": "42007",
      "departamento_nombre": "Atreucó",
      "id": "42007020000",
      "localidad_censal_id": "42007020",
      "localidad_censal_nombre": "Macachín",
      "municipio_id": "420231",
      "municipio_nombre": "Macachín",
      "nombre": "MACACHIN",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8538749058938,
      "centroide_lon": -63.6881846143541,
      "departamento_id": "42007",
      "departamento_nombre": "Atreucó",
      "id": "42007030000",
      "localidad_censal_id": "42007030",
      "localidad_censal_nombre": "Miguel Riglos",
      "municipio_id": "420259",
      "municipio_nombre": "Miguel Riglos",
      "nombre": "MIGUEL RIGLOS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.166824214775,
      "centroide_lon": -63.4171582009534,
      "departamento_id": "42007",
      "departamento_nombre": "Atreucó",
      "id": "42007040000",
      "localidad_censal_id": "42007040",
      "localidad_censal_nombre": "Rolón",
      "municipio_id": "420322",
      "municipio_nombre": "Rolón",
      "nombre": "ROLON",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8406606874148,
      "centroide_lon": -63.5205021645149,
      "departamento_id": "42007",
      "departamento_nombre": "Atreucó",
      "id": "42007050000",
      "localidad_censal_id": "42007050",
      "localidad_censal_nombre": "Tomás M. Anchorena",
      "municipio_id": "420364",
      "municipio_nombre": "Tomás Manuel Anchorena",
      "nombre": "TOMAS M. ANCHORENA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9630526765691,
      "centroide_lon": -63.8608882456791,
      "departamento_id": "42014",
      "departamento_nombre": "Caleu Caleu",
      "id": "42014010000",
      "localidad_censal_id": "42014010",
      "localidad_censal_nombre": "Anzoátegui",
      "municipio_id": "420189",
      "municipio_nombre": "La Adela",
      "nombre": "ANZOATEGUI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9739618507806,
      "centroide_lon": -64.0893166949512,
      "departamento_id": "42014",
      "departamento_nombre": "Caleu Caleu",
      "id": "42014020000",
      "localidad_censal_id": "42014020",
      "localidad_censal_nombre": "La Adela",
      "municipio_id": "420189",
      "municipio_nombre": "La Adela",
      "nombre": "LA ADELA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.527922625355,
      "centroide_lon": -64.010555897973,
      "departamento_id": "42021",
      "departamento_nombre": "Capital",
      "id": "42021010000",
      "localidad_censal_id": "42021010",
      "localidad_censal_nombre": "Anguil",
      "municipio_id": "420028",
      "municipio_nombre": "Anguil",
      "nombre": "ANGUIL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -36.61828979857,
      "centroide_lon": -64.2916770389461,
      "departamento_id": "42021",
      "departamento_nombre": "Capital",
      "id": "42021020000",
      "localidad_censal_id": "42021020",
      "localidad_censal_nombre": "Santa Rosa",
      "municipio_id": "420336",
      "municipio_nombre": "Santa Rosa",
      "nombre": "SANTA ROSA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4077407331528,
      "centroide_lon": -63.4231236479695,
      "departamento_id": "42028",
      "departamento_nombre": "Catriló",
      "id": "42028010000",
      "localidad_censal_id": "42028010",
      "localidad_censal_nombre": "Catriló",
      "municipio_id": "420077",
      "municipio_nombre": "Catriló",
      "nombre": "CATRILO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5079177159884,
      "centroide_lon": -63.7444083139952,
      "departamento_id": "42028",
      "departamento_nombre": "Catriló",
      "id": "42028020000",
      "localidad_censal_id": "42028020",
      "localidad_censal_nombre": "La Gloria",
      "municipio_id": "420378",
      "municipio_nombre": "Uriburu",
      "nombre": "LA GLORIA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4676263417811,
      "centroide_lon": -63.6237223449231,
      "departamento_id": "42028",
      "departamento_nombre": "Catriló",
      "id": "42028030000",
      "localidad_censal_id": "42028030",
      "localidad_censal_nombre": "Lonquimay",
      "municipio_id": "420217",
      "municipio_nombre": "Lonquimay",
      "nombre": "LONQUIMAY",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.5077574745038,
      "centroide_lon": -63.8626307655783,
      "departamento_id": "42028",
      "departamento_nombre": "Catriló",
      "id": "42028040000",
      "localidad_censal_id": "42028040",
      "localidad_censal_nombre": "Uriburu",
      "municipio_id": "420378",
      "municipio_nombre": "Uriburu",
      "nombre": "URIBURU",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.9994878462166,
      "centroide_lon": -64.5959233383525,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035010000",
      "localidad_censal_id": "42035010",
      "localidad_censal_nombre": "Conhelo",
      "municipio_id": "420098",
      "municipio_nombre": "Conhelo",
      "nombre": "CONHELO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.916589710926,
      "centroide_lon": -64.2945868368038,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035020000",
      "localidad_censal_id": "42035020",
      "localidad_censal_nombre": "Eduardo Castex",
      "municipio_id": "420119",
      "municipio_nombre": "Eduardo Castex",
      "nombre": "EDUARDO CASTEX",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2106631640542,
      "centroide_lon": -64.0335412257945,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035030000",
      "localidad_censal_id": "42035030",
      "localidad_censal_nombre": "Mauricio Mayer",
      "municipio_id": "420238",
      "municipio_nombre": "Mauricio Mayer",
      "nombre": "MAURICIO MAYER",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8617464058389,
      "centroide_lon": -64.1569675208376,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035040000",
      "localidad_censal_id": "42035040",
      "localidad_censal_nombre": "Monte Nievas",
      "municipio_id": "420266",
      "municipio_nombre": "Monte Nievas",
      "nombre": "MONTE NIEVAS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0429376779565,
      "centroide_lon": -64.8353214769324,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035050000",
      "localidad_censal_id": "42035050",
      "localidad_censal_nombre": "Rucanelo",
      "municipio_id": "425133",
      "municipio_nombre": "Rucanelo",
      "nombre": "RUCANELO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2272741396174,
      "centroide_lon": -64.2348767956417,
      "departamento_id": "42035",
      "departamento_nombre": "Conhelo",
      "id": "42035060000",
      "localidad_censal_id": "42035060",
      "localidad_censal_nombre": "Winifreda",
      "municipio_id": "420413",
      "municipio_nombre": "Winifreda",
      "nombre": "WINIFREDA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7413407663938,
      "centroide_lon": -66.4360653362481,
      "departamento_id": "42042",
      "departamento_nombre": "Curacó",
      "id": "42042010000",
      "localidad_censal_id": "42042010",
      "localidad_censal_nombre": "Gobernador Duval",
      "municipio_id": "425070",
      "municipio_nombre": "Gobernador Duval",
      "nombre": "GOBERNADOR DUVAL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.1437722310811,
      "centroide_lon": -65.9102790812435,
      "departamento_id": "42042",
      "departamento_nombre": "Curacó",
      "id": "42042020000",
      "localidad_censal_id": "42042020",
      "localidad_censal_nombre": "Puelches",
      "municipio_id": "420280",
      "municipio_nombre": "Puelches",
      "nombre": "PUELCHES",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2298585551973,
      "centroide_lon": -66.9416696902043,
      "departamento_id": "42049",
      "departamento_nombre": "Chalileo",
      "id": "42049010000",
      "localidad_censal_id": "42049010",
      "localidad_censal_nombre": "Santa Isabel",
      "municipio_id": "420329",
      "municipio_nombre": "Santa Isabel",
      "nombre": "SANTA ISABEL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0240766140268,
      "centroide_lon": -63.5814786778188,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056010000",
      "localidad_censal_id": "42056010",
      "localidad_censal_nombre": "Bernardo Larroude",
      "municipio_id": "420049",
      "municipio_nombre": "Bernardo Larroude",
      "nombre": "BERNARDO LARROUDE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2904849982905,
      "centroide_lon": -63.77586064487,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056020000",
      "localidad_censal_id": "42056020",
      "localidad_censal_nombre": "Ceballos",
      "municipio_id": "420084",
      "municipio_nombre": "Ceballos",
      "nombre": "CEBALLOS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0223098759754,
      "centroide_lon": -63.9123145859878,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056030000",
      "localidad_censal_id": "42056030",
      "localidad_censal_nombre": "Coronel Hilario Lagos",
      "municipio_id": "420105",
      "municipio_nombre": "Coronel Hilario Lagos",
      "nombre": "CORONEL HILARIO LAGOS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2368693365619,
      "centroide_lon": -63.5923274522769,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056040000",
      "localidad_censal_id": "42056040",
      "localidad_censal_nombre": "Intendente Alvear",
      "municipio_id": "420175",
      "municipio_nombre": "Intendente Alvear",
      "nombre": "INTENDENTE ALVEAR",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0246923056998,
      "centroide_lon": -63.686962377693,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056050000",
      "localidad_censal_id": "42056050",
      "localidad_censal_nombre": "Sarah",
      "municipio_id": "425140",
      "municipio_nombre": "Sarah",
      "nombre": "SARAH",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4262411079929,
      "centroide_lon": -63.9063646669037,
      "departamento_id": "42056",
      "departamento_nombre": "Chapaleufú",
      "id": "42056060000",
      "localidad_censal_id": "42056060",
      "localidad_censal_nombre": "Vértiz",
      "municipio_id": "420392",
      "municipio_nombre": "Vértiz",
      "nombre": "VERTIZ",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.4011467185658,
      "centroide_lon": -67.1484015946157,
      "departamento_id": "42063",
      "departamento_nombre": "Chical Co",
      "id": "42063010000",
      "localidad_censal_id": "42063010",
      "localidad_censal_nombre": "Algarrobo del Águila",
      "municipio_id": "420011",
      "municipio_nombre": "Algarrobo del Águila",
      "nombre": "ALGARROBO DEL AGUILA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.3500276379509,
      "centroide_lon": -68.0141950027482,
      "departamento_id": "42063",
      "departamento_nombre": "Chical Co",
      "id": "42063020000",
      "localidad_censal_id": "42063020",
      "localidad_censal_nombre": "La Humada",
      "municipio_id": "420196",
      "municipio_nombre": "La Humada",
      "nombre": "LA HUMADA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3758811926005,
      "centroide_lon": -63.7738735909085,
      "departamento_id": "42070",
      "departamento_nombre": "Guatraché",
      "id": "42070010000",
      "localidad_censal_id": "42070010",
      "localidad_censal_nombre": "Alpachiri",
      "municipio_id": "420014",
      "municipio_nombre": "Alpachiri",
      "nombre": "ALPACHIRI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4589894326191,
      "centroide_lon": -63.5872408548874,
      "departamento_id": "42070",
      "departamento_nombre": "Guatraché",
      "id": "42070020000",
      "localidad_censal_id": "42070020",
      "localidad_censal_nombre": "General Manuel J. Campos",
      "municipio_id": "420140",
      "municipio_nombre": "General Campos",
      "nombre": "GENERAL MANUEL J. CAMPOS",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6669652272263,
      "centroide_lon": -63.5415367289386,
      "departamento_id": "42070",
      "departamento_nombre": "Guatraché",
      "id": "42070030000",
      "localidad_censal_id": "42070030",
      "localidad_censal_nombre": "Guatraché",
      "municipio_id": "420161",
      "municipio_nombre": "Guatraché",
      "nombre": "GUATRACHE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6623849438401,
      "centroide_lon": -64.1373213494442,
      "departamento_id": "42070",
      "departamento_nombre": "Guatraché",
      "id": "42070040000",
      "localidad_censal_id": "42070040",
      "localidad_censal_nombre": "Perú",
      "municipio_id": "425105",
      "municipio_nombre": "Perú",
      "nombre": "PERU",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5749339058948,
      "centroide_lon": -63.4328864695669,
      "departamento_id": "42070",
      "departamento_nombre": "Guatraché",
      "id": "42070050000",
      "localidad_censal_id": "42070050",
      "localidad_censal_nombre": "Santa Teresa",
      "municipio_id": "420343",
      "municipio_nombre": "Santa Teresa",
      "nombre": "SANTA TERESA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8943313295474,
      "centroide_lon": -63.8515025914131,
      "departamento_id": "42077",
      "departamento_nombre": "Hucal",
      "id": "42077010000",
      "localidad_censal_id": "42077010",
      "localidad_censal_nombre": "Abramo",
      "municipio_id": "420007",
      "municipio_nombre": "Abramo",
      "nombre": "ABRAMO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9020681912485,
      "centroide_lon": -63.7450793526802,
      "departamento_id": "42077",
      "departamento_nombre": "Hucal",
      "id": "42077020000",
      "localidad_censal_id": "42077020",
      "localidad_censal_nombre": "Bernasconi",
      "municipio_id": "420056",
      "municipio_nombre": "Bernasconi",
      "nombre": "BERNASCONI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9785301335454,
      "centroide_lon": -63.6065854529977,
      "departamento_id": "42077",
      "departamento_nombre": "Hucal",
      "id": "42077030000",
      "localidad_censal_id": "42077030",
      "localidad_censal_nombre": "General San Martín",
      "municipio_id": "420154",
      "municipio_nombre": "General San Martín",
      "nombre": "GENERAL SAN MARTIN",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7849629183535,
      "centroide_lon": -64.0306677804584,
      "departamento_id": "42077",
      "departamento_nombre": "Hucal",
      "id": "42077040000",
      "localidad_censal_id": "42077040",
      "localidad_censal_nombre": "Hucal",
      "municipio_id": "420007",
      "municipio_nombre": "Abramo",
      "nombre": "HUCAL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0837587172211,
      "centroide_lon": -63.4321816643775,
      "departamento_id": "42077",
      "departamento_nombre": "Hucal",
      "id": "42077050000",
      "localidad_censal_id": "42077050",
      "localidad_censal_nombre": "Jacinto Aráuz",
      "municipio_id": "420182",
      "municipio_nombre": "Jacinto Arauz",
      "nombre": "JACINTO ARAUZ",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3336784745408,
      "centroide_lon": -64.6422526958764,
      "departamento_id": "42084",
      "departamento_nombre": "Lihuel Calel",
      "id": "42084010000",
      "localidad_censal_id": "42084010",
      "localidad_censal_nombre": "Cuchillo Co",
      "municipio_id": "425042",
      "municipio_nombre": "Cuchillo Có",
      "nombre": "CUCHILLO CO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5520049423349,
      "centroide_lon": -66.2268538972728,
      "departamento_id": "42091",
      "departamento_nombre": "Limay Mahuida",
      "id": "42091010000",
      "localidad_censal_id": "42091010",
      "localidad_censal_nombre": "La Reforma",
      "municipio_id": "425077",
      "municipio_nombre": "La Reforma",
      "nombre": "LA REFORMA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1597120530545,
      "centroide_lon": -66.6745400356471,
      "departamento_id": "42091",
      "departamento_nombre": "Limay Mahuida",
      "id": "42091020000",
      "localidad_censal_id": "42091020",
      "localidad_censal_nombre": "Limay Mahuida",
      "municipio_id": "425084",
      "municipio_nombre": "Limay Mahuida",
      "nombre": "LIMAY MAHUIDA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.47556498406,
      "centroide_lon": -65.3429160249895,
      "departamento_id": "42098",
      "departamento_nombre": "Loventué",
      "id": "42098010000",
      "localidad_censal_id": "42098010",
      "localidad_censal_nombre": "Carro Quemado",
      "municipio_id": "420070",
      "municipio_nombre": "Carro Quemado",
      "nombre": "CARRO QUEMADO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1887459763907,
      "centroide_lon": -65.2899702728719,
      "departamento_id": "42098",
      "departamento_nombre": "Loventué",
      "id": "42098020000",
      "localidad_censal_id": "42098020",
      "localidad_censal_nombre": "Loventué",
      "municipio_id": "425091",
      "municipio_nombre": "Loventué",
      "nombre": "LOVENTUE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2016818772905,
      "centroide_lon": -65.0972782402489,
      "departamento_id": "42098",
      "departamento_nombre": "Loventué",
      "id": "42098030000",
      "localidad_censal_id": "42098030",
      "localidad_censal_nombre": "Luan Toro",
      "municipio_id": "420224",
      "municipio_nombre": "Luan Toro",
      "nombre": "LUAN TORO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2640564040186,
      "centroide_lon": -65.5097731802402,
      "departamento_id": "42098",
      "departamento_nombre": "Loventué",
      "id": "42098040000",
      "localidad_censal_id": "42098040",
      "localidad_censal_nombre": "Telén",
      "municipio_id": "420350",
      "municipio_nombre": "Telén",
      "nombre": "TELEN",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2048593679219,
      "centroide_lon": -65.4403752727095,
      "departamento_id": "42098",
      "departamento_nombre": "Loventué",
      "id": "42098050000",
      "localidad_censal_id": "42098050",
      "localidad_censal_nombre": "Victorica",
      "municipio_id": "420399",
      "municipio_nombre": "Victorica",
      "nombre": "VICTORICA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7811018772957,
      "centroide_lon": -63.3932493460357,
      "departamento_id": "42105",
      "departamento_nombre": "Maracó",
      "id": "42105010000",
      "localidad_censal_id": "42105010",
      "localidad_censal_nombre": "Agustoni",
      "municipio_id": "425014",
      "municipio_nombre": "Agustoni",
      "nombre": "AGUSTONI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7763229509013,
      "centroide_lon": -63.716840693317,
      "departamento_id": "42105",
      "departamento_nombre": "Maracó",
      "id": "42105020000",
      "localidad_censal_id": "42105020",
      "localidad_censal_nombre": "Dorila",
      "municipio_id": "420116",
      "municipio_nombre": "Dorila",
      "nombre": "DORILA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6633805937206,
      "centroide_lon": -63.760929267739,
      "departamento_id": "42105",
      "departamento_nombre": "Maracó",
      "id": "42105030000",
      "localidad_censal_id": "42105030",
      "localidad_censal_nombre": "General Pico",
      "municipio_id": "420147",
      "municipio_nombre": "General Pico",
      "nombre": "GENERAL PICO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.547293621716,
      "centroide_lon": -63.8200403343494,
      "departamento_id": "42105",
      "departamento_nombre": "Maracó",
      "id": "42105040000",
      "localidad_censal_id": "42105040",
      "localidad_censal_nombre": "Speluzzi",
      "municipio_id": "425147",
      "municipio_nombre": "Speluzzi",
      "nombre": "SPELUZZI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5855840986961,
      "centroide_lon": -63.5887521403772,
      "departamento_id": "42105",
      "departamento_nombre": "Maracó",
      "id": "42105050000",
      "localidad_censal_id": "42105050",
      "localidad_censal_nombre": "Trebolares",
      "municipio_id": "420147",
      "municipio_nombre": "General Pico",
      "nombre": "TREBOLARES",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.2230515311115,
      "centroide_lon": -67.1709263654639,
      "departamento_id": "42112",
      "departamento_nombre": "Puelén",
      "id": "42112005000",
      "localidad_censal_id": "42112005",
      "localidad_censal_nombre": "Casa de Piedra",
      "municipio_id": "422007",
      "municipio_nombre": "Casa de Piedra",
      "nombre": "CASA DE PIEDRA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3400655492209,
      "centroide_lon": -67.6218500509991,
      "departamento_id": "42112",
      "departamento_nombre": "Puelén",
      "id": "42112010000",
      "localidad_censal_id": "42112010",
      "localidad_censal_nombre": "Puelén",
      "municipio_id": "420287",
      "municipio_nombre": "Puelén",
      "nombre": "PUELEN",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.7697053748681,
      "centroide_lon": -67.7172958236186,
      "departamento_id": "42112",
      "departamento_nombre": "Puelén",
      "id": "42112020000",
      "localidad_censal_id": "42112020",
      "localidad_censal_nombre": "25 de Mayo",
      "municipio_id": "420385",
      "municipio_nombre": "Veinticinco de Mayo",
      "nombre": "25 DE MAYO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1513889624763,
      "centroide_lon": -63.8541959060258,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119010000",
      "localidad_censal_id": "42119010",
      "localidad_censal_nombre": "Colonia Barón",
      "municipio_id": "420091",
      "municipio_nombre": "Colonia Barón",
      "nombre": "COLONIA BARON",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1170514464671,
      "centroide_lon": -63.9040708614432,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119020000",
      "localidad_censal_id": "42119020",
      "localidad_censal_nombre": "Colonia San José",
      "municipio_id": "420091",
      "municipio_nombre": "Colonia Barón",
      "nombre": "COLONIA SAN JOSE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1549581794141,
      "centroide_lon": -63.5121543730908,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119030000",
      "localidad_censal_id": "42119030",
      "localidad_censal_nombre": "Miguel Cané",
      "municipio_id": "420252",
      "municipio_nombre": "Miguel Cané",
      "nombre": "MIGUEL CANE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.053677480752,
      "centroide_lon": -63.5632432919433,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119040000",
      "localidad_censal_id": "42119040",
      "localidad_censal_nombre": "Quemú Quemú",
      "municipio_id": "420301",
      "municipio_nombre": "Quemú Quemú",
      "nombre": "QUEMU QUEMU",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.2573837868412,
      "centroide_lon": -63.4481302030919,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119050000",
      "localidad_censal_id": "42119050",
      "localidad_censal_nombre": "Relmo",
      "municipio_id": "425126",
      "municipio_nombre": "Relmo",
      "nombre": "RELMO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.0757513459043,
      "centroide_lon": -63.8875837941037,
      "departamento_id": "42119",
      "departamento_nombre": "Quemú Quemú",
      "id": "42119060000",
      "localidad_censal_id": "42119060",
      "localidad_censal_nombre": "Villa Mirasol",
      "municipio_id": "420406",
      "municipio_nombre": "Villa Mirasol",
      "nombre": "VILLA MIRASOL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.5928646334622,
      "centroide_lon": -64.5586842581366,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126010000",
      "localidad_censal_id": "42126010",
      "localidad_censal_nombre": "Caleufú",
      "municipio_id": "420063",
      "municipio_nombre": "Caleufú",
      "nombre": "CALEUFU",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7014889174834,
      "centroide_lon": -65.1025535202338,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126020000",
      "localidad_censal_id": "42126020",
      "localidad_censal_nombre": "Ingeniero Foster",
      "municipio_id": "420210",
      "municipio_nombre": "La Maruja",
      "nombre": "INGENIERO FOSTER",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.671688678266,
      "centroide_lon": -64.940569826581,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126030000",
      "localidad_censal_id": "42126030",
      "localidad_censal_nombre": "La Maruja",
      "municipio_id": "420210",
      "municipio_nombre": "La Maruja",
      "nombre": "LA MARUJA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1462930750947,
      "centroide_lon": -64.5006491231723,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126040000",
      "localidad_censal_id": "42126040",
      "localidad_censal_nombre": "Parera",
      "municipio_id": "420273",
      "municipio_nombre": "Parera",
      "nombre": "PARERA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6473423932767,
      "centroide_lon": -64.7695280662632,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126050000",
      "localidad_censal_id": "42126050",
      "localidad_censal_nombre": "Pichi Huinca",
      "municipio_id": "425112",
      "municipio_nombre": "Pichi Huinca",
      "nombre": "PICHI HUINCA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0548439319726,
      "centroide_lon": -64.5213693396743,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126060000",
      "localidad_censal_id": "42126060",
      "localidad_censal_nombre": "Quetrequén",
      "municipio_id": "425119",
      "municipio_nombre": "Quetrequén",
      "nombre": "QUETREQUEN",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0661985558406,
      "centroide_lon": -64.683114985952,
      "departamento_id": "42126",
      "departamento_nombre": "Rancul",
      "id": "42126070000",
      "localidad_censal_id": "42126070",
      "localidad_censal_nombre": "Rancul",
      "municipio_id": "420308",
      "municipio_nombre": "Rancul",
      "nombre": "RANCUL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0179130302315,
      "centroide_lon": -64.035117861781,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133010000",
      "localidad_censal_id": "42133010",
      "localidad_censal_nombre": "Adolfo Van Praet",
      "municipio_id": "425007",
      "municipio_nombre": "Adolfo Van Praet",
      "nombre": "ADOLFO VAN PRAET",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3338595551664,
      "centroide_lon": -64.1182405361085,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133020000",
      "localidad_censal_id": "42133020",
      "localidad_censal_nombre": "Alta Italia",
      "municipio_id": "420021",
      "municipio_nombre": "Alta Italia",
      "nombre": "ALTA ITALIA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0415558502239,
      "centroide_lon": -64.3847126106129,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133030000",
      "localidad_censal_id": "42133030",
      "localidad_censal_nombre": "Damián Maisonave",
      "municipio_id": "425098",
      "municipio_nombre": "Maisonnave",
      "nombre": "DAMIAN MAISONAVE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3861854808904,
      "centroide_lon": -64.2836857257322,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133040000",
      "localidad_censal_id": "42133040",
      "localidad_censal_nombre": "Embajador Martini",
      "municipio_id": "420126",
      "municipio_nombre": "Embajador Martini",
      "nombre": "EMBAJADOR MARTINI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1890814426625,
      "centroide_lon": -64.1038732251149,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133050000",
      "localidad_censal_id": "42133050",
      "localidad_censal_nombre": "Falucho",
      "municipio_id": "425063",
      "municipio_nombre": "Falucho",
      "nombre": "FALUCHO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3837504384968,
      "centroide_lon": -64.4685685991593,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133060000",
      "localidad_censal_id": "42133060",
      "localidad_censal_nombre": "Ingeniero Luiggi",
      "municipio_id": "420168",
      "municipio_nombre": "Ingeniero Luiggi",
      "nombre": "INGENIERO LUIGGI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.3068277686526,
      "centroide_lon": -64.0054725550363,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133070000",
      "localidad_censal_id": "42133070",
      "localidad_censal_nombre": "Ojeda",
      "municipio_id": "420021",
      "municipio_nombre": "Alta Italia",
      "nombre": "OJEDA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0368857536117,
      "centroide_lon": -64.2454209673953,
      "departamento_id": "42133",
      "departamento_nombre": "Realicó",
      "id": "42133080000",
      "localidad_censal_id": "42133080",
      "localidad_censal_nombre": "Realicó",
      "municipio_id": "420315",
      "municipio_nombre": "Realicó",
      "nombre": "REALICO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7473961516096,
      "centroide_lon": -64.3673907018325,
      "departamento_id": "42140",
      "departamento_nombre": "Toay",
      "id": "42140005000",
      "localidad_censal_id": "42140005",
      "localidad_censal_nombre": "Cachirulo",
      "municipio_id": "420357",
      "municipio_nombre": "Toay",
      "nombre": "CACHIRULO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9283865222889,
      "centroide_lon": -64.3952508587586,
      "departamento_id": "42140",
      "departamento_nombre": "Toay",
      "id": "42140010000",
      "localidad_censal_id": "42140010",
      "localidad_censal_nombre": "Naicó",
      "municipio_id": "420042",
      "municipio_nombre": "Ataliva Roca",
      "nombre": "NAICO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -36.6751577652887,
      "centroide_lon": -64.3803253806581,
      "departamento_id": "42140",
      "departamento_nombre": "Toay",
      "id": "42140020000",
      "localidad_censal_id": "42140020",
      "localidad_censal_nombre": "Toay",
      "municipio_id": "420357",
      "municipio_nombre": "Toay",
      "nombre": "TOAY",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6381231858073,
      "centroide_lon": -64.3574125644643,
      "departamento_id": "42147",
      "departamento_nombre": "Trenel",
      "id": "42147010000",
      "localidad_censal_id": "42147010",
      "localidad_censal_nombre": "Arata",
      "municipio_id": "420035",
      "municipio_nombre": "Arata",
      "nombre": "ARATA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7732438729278,
      "centroide_lon": -63.9428308886673,
      "departamento_id": "42147",
      "departamento_nombre": "Trenel",
      "id": "42147020000",
      "localidad_censal_id": "42147020",
      "localidad_censal_nombre": "Metileo",
      "municipio_id": "420245",
      "municipio_nombre": "Metileo",
      "nombre": "METILEO",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6941743318846,
      "centroide_lon": -64.1358999676104,
      "departamento_id": "42147",
      "departamento_nombre": "Trenel",
      "id": "42147030000",
      "localidad_censal_id": "42147030",
      "localidad_censal_nombre": "Trenel",
      "municipio_id": "420371",
      "municipio_nombre": "Trenel",
      "nombre": "TRENEL",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0323315728651,
      "centroide_lon": -64.2865724433513,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154010000",
      "localidad_censal_id": "42154010",
      "localidad_censal_nombre": "Ataliva Roca",
      "municipio_id": "420042",
      "municipio_nombre": "Ataliva Roca",
      "nombre": "ATALIVA ROCA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3318147762102,
      "centroide_lon": -65.650772794634,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154020000",
      "localidad_censal_id": "42154020",
      "localidad_censal_nombre": "Chacharramendi",
      "municipio_id": "425049",
      "municipio_nombre": "Chacharramendi",
      "nombre": "CHACHARRAMENDI",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4952435783299,
      "centroide_lon": -64.2248805481774,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154030000",
      "localidad_censal_id": "42154030",
      "localidad_censal_nombre": "Colonia Santa María",
      "municipio_id": "425028",
      "municipio_nombre": "Colonia Santa María",
      "nombre": "COLONIA SANTA MARIA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.373672668717,
      "centroide_lon": -64.6038964711256,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154040000",
      "localidad_censal_id": "42154040",
      "localidad_censal_nombre": "General Acha",
      "municipio_id": "420133",
      "municipio_nombre": "General Acha",
      "nombre": "GENERAL ACHA",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1212005028742,
      "centroide_lon": -64.5133661628312,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154050000",
      "localidad_censal_id": "42154050",
      "localidad_censal_nombre": "Quehué",
      "municipio_id": "420294",
      "municipio_nombre": "Quehué",
      "nombre": "QUEHUE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5439767222425,
      "centroide_lon": -64.3525554864311,
      "departamento_id": "42154",
      "departamento_nombre": "Utracán",
      "id": "42154060000",
      "localidad_censal_id": "42154060",
      "localidad_censal_nombre": "Unanué",
      "municipio_id": "425154",
      "municipio_nombre": "Unanue",
      "nombre": "UNANUE",
      "provincia_id": "42",
      "provincia_nombre": "La Pampa"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5589898446523,
      "centroide_lon": -66.8272851017638,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007010001",
      "localidad_censal_id": "46007010",
      "localidad_censal_nombre": "Aimogasta",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "AIMOGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5410843774808,
      "centroide_lon": -66.8070296105313,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007010002",
      "localidad_censal_id": "46007010",
      "localidad_censal_nombre": "Aimogasta",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "MACHIGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5553801376497,
      "centroide_lon": -66.8013856528183,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007010003",
      "localidad_censal_id": "46007010",
      "localidad_censal_nombre": "Aimogasta",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "SAN ANTONIO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5834031870202,
      "centroide_lon": -66.8036412937626,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007020000",
      "localidad_censal_id": "46007020",
      "localidad_censal_nombre": "Arauco",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "ARAUCO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3871788034279,
      "centroide_lon": -66.8369122348367,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007030000",
      "localidad_censal_id": "46007030",
      "localidad_censal_nombre": "Bañado de los Pantanos",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "BAÑADO DE LOS PANTANOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6648262993633,
      "centroide_lon": -66.5166195609666,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007040000",
      "localidad_censal_id": "46007040",
      "localidad_censal_nombre": "Estación Mazán",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "ESTACION MAZAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.591774966521,
      "centroide_lon": -66.5563885931113,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007045000",
      "localidad_censal_id": "46007045",
      "localidad_censal_nombre": "Termas de Santa Teresita",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "TERMAS DE SANTA TERESITA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6586281834455,
      "centroide_lon": -66.5552758852995,
      "departamento_id": "46007",
      "departamento_nombre": "Arauco",
      "id": "46007050000",
      "localidad_censal_id": "46007050",
      "localidad_censal_nombre": "Villa Mazán",
      "municipio_id": "460007",
      "municipio_nombre": "Arauco",
      "nombre": "VILLA MAZAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4217827668094,
      "centroide_lon": -66.856675379963,
      "departamento_id": "46014",
      "departamento_nombre": "Capital",
      "id": "46014010000",
      "localidad_censal_id": "46014010",
      "localidad_censal_nombre": "La Rioja",
      "municipio_id": "460014",
      "municipio_nombre": "Capital",
      "nombre": "LA RIOJA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8554609845772,
      "centroide_lon": -66.9376617661779,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021010000",
      "localidad_censal_id": "46021010",
      "localidad_censal_nombre": "Aminga",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "AMINGA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8155900262519,
      "centroide_lon": -66.9439245714904,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021020000",
      "localidad_censal_id": "46021020",
      "localidad_censal_nombre": "Anillaco",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "ANILLACO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7272509428901,
      "centroide_lon": -66.9328944677286,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021030000",
      "localidad_censal_id": "46021030",
      "localidad_censal_nombre": "Anjullón",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "ANJULLON",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8979801822511,
      "centroide_lon": -66.9765194957537,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021040000",
      "localidad_censal_id": "46021040",
      "localidad_censal_nombre": "Chuquis",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "CHUQUIS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7544152281739,
      "centroide_lon": -66.9389202939919,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021050000",
      "localidad_censal_id": "46021050",
      "localidad_censal_nombre": "Los Molinos",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "LOS MOLINOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9370760269909,
      "centroide_lon": -66.9665171496043,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021060000",
      "localidad_censal_id": "46021060",
      "localidad_censal_nombre": "Pinchas",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "PINCHAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6697090457958,
      "centroide_lon": -66.9281338379098,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021070000",
      "localidad_censal_id": "46021070",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "SAN PEDRO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.683504219205,
      "centroide_lon": -66.9654939868506,
      "departamento_id": "46021",
      "departamento_nombre": "Castro Barros",
      "id": "46021080000",
      "localidad_censal_id": "46021080",
      "localidad_censal_nombre": "Santa Vera Cruz",
      "municipio_id": "460021",
      "municipio_nombre": "Castro Barros",
      "nombre": "SANTA VERA CRUZ",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4772470519152,
      "centroide_lon": -67.7729134098149,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028010000",
      "localidad_censal_id": "46028010",
      "localidad_censal_nombre": "Aicuñá",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "AICUÑA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.509859321691,
      "centroide_lon": -68.5522045398452,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028020001",
      "localidad_censal_id": "46028020",
      "localidad_censal_nombre": "Guandacol",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "GUANDACOL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.361377257847,
      "centroide_lon": -68.6264098036917,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028020002",
      "localidad_censal_id": "46028020",
      "localidad_censal_nombre": "Guandacol",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "SANTA CLARA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3713119289112,
      "centroide_lon": -68.2271328398885,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028030000",
      "localidad_censal_id": "46028030",
      "localidad_censal_nombre": "Los Palacios",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "LOS PALACIOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5412536132819,
      "centroide_lon": -68.0970404734465,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028040000",
      "localidad_censal_id": "46028040",
      "localidad_censal_nombre": "Pagancillo",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "PAGANCILLO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.3032949590649,
      "centroide_lon": -68.2439876146285,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028050001",
      "localidad_censal_id": "46028050",
      "localidad_censal_nombre": "Villa Unión",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "BANDA FLORIDA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.3227046223336,
      "centroide_lon": -68.2230741292594,
      "departamento_id": "46028",
      "departamento_nombre": "Coronel Felipe Varela",
      "id": "46028050002",
      "localidad_censal_id": "46028050",
      "localidad_censal_nombre": "Villa Unión",
      "municipio_id": "460028",
      "municipio_nombre": "Coronel Felipe Varela",
      "nombre": "VILLA UNION",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3772951613355,
      "centroide_lon": -66.3240368838483,
      "departamento_id": "46035",
      "departamento_nombre": "Chamical",
      "id": "46035010000",
      "localidad_censal_id": "46035010",
      "localidad_censal_nombre": "Chamical",
      "municipio_id": "460035",
      "municipio_nombre": "Chamical",
      "nombre": "CHAMICAL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4293153810224,
      "centroide_lon": -66.3586422456665,
      "departamento_id": "46035",
      "departamento_nombre": "Chamical",
      "id": "46035020000",
      "localidad_censal_id": "46035020",
      "localidad_censal_nombre": "Polco",
      "municipio_id": "460035",
      "municipio_nombre": "Chamical",
      "nombre": "POLCO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.2060142258143,
      "centroide_lon": -67.4589845307511,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042010001",
      "localidad_censal_id": "46042010",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "ANGUINAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.1634978575196,
      "centroide_lon": -67.5074284990176,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042010002",
      "localidad_censal_id": "46042010",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "CHILECITO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.1484698105368,
      "centroide_lon": -67.4191244361359,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042010003",
      "localidad_censal_id": "46042010",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "LA PUNTILLA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.1517467165263,
      "centroide_lon": -67.4826010369502,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042010004",
      "localidad_censal_id": "46042010",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "LOS SARMIENTOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -29.184674818415,
      "centroide_lon": -67.4780826682806,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042010005",
      "localidad_censal_id": "46042010",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "SAN MIGUEL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2540980171571,
      "centroide_lon": -67.426136513409,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042020000",
      "localidad_censal_id": "46042020",
      "localidad_censal_nombre": "Colonia Anguinán",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "COLONIA ANGUINAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.6731660020358,
      "centroide_lon": -67.3823669132229,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042030000",
      "localidad_censal_id": "46042030",
      "localidad_censal_nombre": "Colonia Catinzaco",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "COLONIA CATINZACO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2029666175252,
      "centroide_lon": -67.4003467202303,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042040000",
      "localidad_censal_id": "46042040",
      "localidad_censal_nombre": "Colonia Malligasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "COLONIA MALLIGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4490716382323,
      "centroide_lon": -67.4914198732182,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042050000",
      "localidad_censal_id": "46042050",
      "localidad_censal_nombre": "Colonia Vichigasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "COLONIA VICHIGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1927256872976,
      "centroide_lon": -67.6392754274079,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042060000",
      "localidad_censal_id": "46042060",
      "localidad_censal_nombre": "Guanchín",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "GUANCHIN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1772024890585,
      "centroide_lon": -67.4408316312776,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042070000",
      "localidad_censal_id": "46042070",
      "localidad_censal_nombre": "Malligasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "MALLIGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3410380679447,
      "centroide_lon": -67.6618699588305,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042080000",
      "localidad_censal_id": "46042080",
      "localidad_censal_nombre": "Miranda",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "MIRANDA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3040959439682,
      "centroide_lon": -67.5072784622018,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042090000",
      "localidad_censal_id": "46042090",
      "localidad_censal_nombre": "Nonogasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "NONOGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1217634888802,
      "centroide_lon": -67.475895961327,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042100000",
      "localidad_censal_id": "46042100",
      "localidad_censal_nombre": "San Nicolás",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "SAN NICOLAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1330615805931,
      "centroide_lon": -67.5576081781931,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042110000",
      "localidad_censal_id": "46042110",
      "localidad_censal_nombre": "Santa Florentina",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "SANTA FLORENTINA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3203738957214,
      "centroide_lon": -67.6277959527272,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042120000",
      "localidad_censal_id": "46042120",
      "localidad_censal_nombre": "Sañogasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "SAÑOGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1494438672162,
      "centroide_lon": -67.4296645756575,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042130000",
      "localidad_censal_id": "46042130",
      "localidad_censal_nombre": "Tilimuqui",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "TILIMUQUI",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4885973737241,
      "centroide_lon": -67.50228520978,
      "departamento_id": "46042",
      "departamento_nombre": "Chilecito",
      "id": "46042140000",
      "localidad_censal_id": "46042140",
      "localidad_censal_nombre": "Vichigasta",
      "municipio_id": "460042",
      "municipio_nombre": "Chilecito",
      "nombre": "VICHIGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8736691237645,
      "centroide_lon": -67.5689979264386,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049010000",
      "localidad_censal_id": "46049010",
      "localidad_censal_nombre": "Alto Carrizal",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "ALTO CARRIZAL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6595753462725,
      "centroide_lon": -67.6529605556859,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049020000",
      "localidad_censal_id": "46049020",
      "localidad_censal_nombre": "Angulos",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "ANGULOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8250781693493,
      "centroide_lon": -67.3233196534937,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049030000",
      "localidad_censal_id": "46049030",
      "localidad_censal_nombre": "Antinaco",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "ANTINACO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8911007689177,
      "centroide_lon": -67.5667461563502,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049040000",
      "localidad_censal_id": "46049040",
      "localidad_censal_nombre": "Bajo Carrizal",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "BAJO CARRIZAL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5560208289878,
      "centroide_lon": -67.6264406513676,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049050000",
      "localidad_censal_id": "46049050",
      "localidad_censal_nombre": "Campanas",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "CAMPANAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6123449106977,
      "centroide_lon": -67.5874723425836,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049060000",
      "localidad_censal_id": "46049060",
      "localidad_censal_nombre": "Chañarmuyo",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "CHAÑARMUYO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9291000348798,
      "centroide_lon": -67.5207596756722,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049070000",
      "localidad_censal_id": "46049070",
      "localidad_censal_nombre": "Famatina",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "FAMATINA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4724817643376,
      "centroide_lon": -67.6918652569621,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049080000",
      "localidad_censal_id": "46049080",
      "localidad_censal_nombre": "La Cuadra",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "LA CUADRA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5815077306519,
      "centroide_lon": -67.4508379497994,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049090000",
      "localidad_censal_id": "46049090",
      "localidad_censal_nombre": "Pituil",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "PITUIL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9731598381662,
      "centroide_lon": -67.5201315513043,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049100000",
      "localidad_censal_id": "46049100",
      "localidad_censal_nombre": "Plaza Vieja",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "PLAZA VIEJA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4907371198,
      "centroide_lon": -67.6899252647169,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049110000",
      "localidad_censal_id": "46049110",
      "localidad_censal_nombre": "Santa Cruz",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "SANTA CRUZ",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5600668428725,
      "centroide_lon": -67.6855704208219,
      "departamento_id": "46049",
      "departamento_nombre": "Famatina",
      "id": "46049120000",
      "localidad_censal_id": "46049120",
      "localidad_censal_nombre": "Santo Domingo",
      "municipio_id": "460049",
      "municipio_nombre": "Famatina",
      "nombre": "SANTO DOMINGO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.165433857594,
      "centroide_lon": -66.556575245663,
      "departamento_id": "46056",
      "departamento_nombre": "General Ángel V. Peñaloza",
      "id": "46056010000",
      "localidad_censal_id": "46056010",
      "localidad_censal_nombre": "Punta de los Llanos",
      "municipio_id": "460056",
      "municipio_nombre": "General Ángel Vicente Peñaloza",
      "nombre": "PUNTA DE LOS LLANOS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5173084388038,
      "centroide_lon": -66.5413792482074,
      "departamento_id": "46056",
      "departamento_nombre": "General Ángel V. Peñaloza",
      "id": "46056020000",
      "localidad_censal_id": "46056020",
      "localidad_censal_nombre": "Tama",
      "municipio_id": "460056",
      "municipio_nombre": "General Ángel Vicente Peñaloza",
      "nombre": "TAMA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5956924271806,
      "centroide_lon": -65.7419372748158,
      "departamento_id": "46063",
      "departamento_nombre": "General Belgrano",
      "id": "46063010000",
      "localidad_censal_id": "46063010",
      "localidad_censal_nombre": "Castro Barros",
      "municipio_id": "460063",
      "municipio_nombre": "General Belgrano",
      "nombre": "CASTRO BARROS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5590304532238,
      "centroide_lon": -65.9735931205601,
      "departamento_id": "46063",
      "departamento_nombre": "General Belgrano",
      "id": "46063020000",
      "localidad_censal_id": "46063020",
      "localidad_censal_nombre": "Chañar",
      "municipio_id": "460063",
      "municipio_nombre": "General Belgrano",
      "nombre": "CHAÑAR",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6446137810742,
      "centroide_lon": -66.238741686799,
      "departamento_id": "46063",
      "departamento_nombre": "General Belgrano",
      "id": "46063030000",
      "localidad_censal_id": "46063030",
      "localidad_censal_nombre": "Loma Blanca",
      "municipio_id": "460063",
      "municipio_nombre": "General Belgrano",
      "nombre": "LOMA BLANCA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6399759885817,
      "centroide_lon": -66.278060412461,
      "departamento_id": "46063",
      "departamento_nombre": "General Belgrano",
      "id": "46063040000",
      "localidad_censal_id": "46063040",
      "localidad_censal_nombre": "Olta",
      "municipio_id": "460063",
      "municipio_nombre": "General Belgrano",
      "nombre": "OLTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.815543887629,
      "centroide_lon": -66.6194814951279,
      "departamento_id": "46070",
      "departamento_nombre": "General Juan F. Quiroga",
      "id": "46070010000",
      "localidad_censal_id": "46070010",
      "localidad_censal_nombre": "Malanzán",
      "municipio_id": "460070",
      "municipio_nombre": "General Juan Facundo Quiroga",
      "nombre": "MALANZAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8640381344546,
      "centroide_lon": -66.4051056083957,
      "departamento_id": "46070",
      "departamento_nombre": "General Juan F. Quiroga",
      "id": "46070020000",
      "localidad_censal_id": "46070020",
      "localidad_censal_nombre": "Nácate",
      "municipio_id": "460070",
      "municipio_nombre": "General Juan Facundo Quiroga",
      "nombre": "NACATE",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.847619186484,
      "centroide_lon": -66.7186798501751,
      "departamento_id": "46070",
      "departamento_nombre": "General Juan F. Quiroga",
      "id": "46070030000",
      "localidad_censal_id": "46070030",
      "localidad_censal_nombre": "Portezuelo",
      "municipio_id": "460070",
      "municipio_nombre": "General Juan Facundo Quiroga",
      "nombre": "PORTEZUELO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0900907205412,
      "centroide_lon": -66.7571869922571,
      "departamento_id": "46070",
      "departamento_nombre": "General Juan F. Quiroga",
      "id": "46070040000",
      "localidad_censal_id": "46070040",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "460070",
      "municipio_nombre": "General Juan Facundo Quiroga",
      "nombre": "SAN ANTONIO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0207515543035,
      "centroide_lon": -68.2253713917754,
      "departamento_id": "46077",
      "departamento_nombre": "General Lamadrid",
      "id": "46077010000",
      "localidad_censal_id": "46077010",
      "localidad_censal_nombre": "Villa Castelli",
      "municipio_id": "460077",
      "municipio_nombre": "General Lamadrid",
      "nombre": "VILLA CASTELLI",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1413359277957,
      "centroide_lon": -66.3603819377386,
      "departamento_id": "46084",
      "departamento_nombre": "General Ocampo",
      "id": "46084010000",
      "localidad_censal_id": "46084010",
      "localidad_censal_nombre": "Ambil",
      "municipio_id": "460084",
      "municipio_nombre": "General Ocampo",
      "nombre": "AMBIL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9752909491233,
      "centroide_lon": -66.1953883794961,
      "departamento_id": "46084",
      "departamento_nombre": "General Ocampo",
      "id": "46084020000",
      "localidad_censal_id": "46084020",
      "localidad_censal_nombre": "Colonia Ortiz de Ocampo",
      "municipio_id": "460084",
      "municipio_nombre": "General Ocampo",
      "nombre": "COLONIA ORTIZ DE OCAMPO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0131717520524,
      "centroide_lon": -65.9882554540382,
      "departamento_id": "46084",
      "departamento_nombre": "General Ocampo",
      "id": "46084030000",
      "localidad_censal_id": "46084030",
      "localidad_censal_nombre": "Milagro",
      "municipio_id": "460084",
      "municipio_nombre": "General Ocampo",
      "nombre": "MILAGRO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8195242375591,
      "centroide_lon": -66.2483100669124,
      "departamento_id": "46084",
      "departamento_nombre": "General Ocampo",
      "id": "46084040000",
      "localidad_censal_id": "46084040",
      "localidad_censal_nombre": "Olpas",
      "municipio_id": "460084",
      "municipio_nombre": "General Ocampo",
      "nombre": "OLPAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9665198359206,
      "centroide_lon": -66.2356607957536,
      "departamento_id": "46084",
      "departamento_nombre": "General Ocampo",
      "id": "46084050000",
      "localidad_censal_id": "46084050",
      "localidad_censal_nombre": "Santa Rita de Catuna",
      "municipio_id": "460084",
      "municipio_nombre": "General Ocampo",
      "nombre": "SANTA RITA DE CATUNA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.582978877023,
      "centroide_lon": -66.2514026305989,
      "departamento_id": "46091",
      "departamento_nombre": "General San Martín",
      "id": "46091010000",
      "localidad_censal_id": "46091010",
      "localidad_censal_nombre": "Ulapes",
      "municipio_id": "460091",
      "municipio_nombre": "General San Martín",
      "nombre": "ULAPES",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6604900543905,
      "centroide_lon": -68.3668741365882,
      "departamento_id": "46098",
      "departamento_nombre": "Vinchina",
      "id": "46098010000",
      "localidad_censal_id": "46098010",
      "localidad_censal_nombre": "Jagüé",
      "municipio_id": "460098",
      "municipio_nombre": "Vinchina",
      "nombre": "JAGUE",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7797111463416,
      "centroide_lon": -68.2060368408699,
      "departamento_id": "46098",
      "departamento_nombre": "Vinchina",
      "id": "46098020000",
      "localidad_censal_id": "46098020",
      "localidad_censal_nombre": "Villa San José de Vinchina",
      "municipio_id": "460098",
      "municipio_nombre": "Vinchina",
      "nombre": "VILLA SAN JOSE DE VINCHINA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0611119791655,
      "centroide_lon": -67.5084950612514,
      "departamento_id": "46105",
      "departamento_nombre": "Independencia",
      "id": "46105010000",
      "localidad_censal_id": "46105010",
      "localidad_censal_nombre": "Amaná",
      "municipio_id": "460105",
      "municipio_nombre": "Independencia",
      "nombre": "AMANA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0522114405959,
      "centroide_lon": -66.8915886798437,
      "departamento_id": "46105",
      "departamento_nombre": "Independencia",
      "id": "46105020000",
      "localidad_censal_id": "46105020",
      "localidad_censal_nombre": "Patquía",
      "municipio_id": "460105",
      "municipio_nombre": "Independencia",
      "nombre": "PATQUIA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3506186314416,
      "centroide_lon": -66.6046417930744,
      "departamento_id": "46112",
      "departamento_nombre": "Rosario Vera Peñaloza",
      "id": "46112010000",
      "localidad_censal_id": "46112010",
      "localidad_censal_nombre": "Chepes",
      "municipio_id": "460112",
      "municipio_nombre": "Rosario Vera Peñaloza",
      "nombre": "CHEPES",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2240295500516,
      "centroide_lon": -66.3334915975105,
      "departamento_id": "46112",
      "departamento_nombre": "Rosario Vera Peñaloza",
      "id": "46112020000",
      "localidad_censal_id": "46112020",
      "localidad_censal_nombre": "Desiderio Tello",
      "municipio_id": "460112",
      "municipio_nombre": "Rosario Vera Peñaloza",
      "nombre": "DESIDERIO TELLO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.3536422425472,
      "centroide_lon": -67.080433367311,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010001",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "ALPASINCHE",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5555816291232,
      "centroide_lon": -67.1592836516078,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010002",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "AMUSCHINA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5555816291232,
      "centroide_lon": -67.1592836516078,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010003",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "ANDOLUCAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.3536422425472,
      "centroide_lon": -67.080433367311,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010004",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "CHAUPIHUASI",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4517974906601,
      "centroide_lon": -67.1178978512143,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010005",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "CUIPAN",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4517974906601,
      "centroide_lon": -67.1178978512143,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010006",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "LAS TALAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4517974906601,
      "centroide_lon": -67.1178978512143,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010007",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "LOS ROBLES",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.407625810512,
      "centroide_lon": -67.0961546311905,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010009",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "SAN BLAS",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.4812201375784,
      "centroide_lon": -67.128235105005,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010010",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "SHAQUI",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5555816291232,
      "centroide_lon": -67.1592836516078,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010011",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "SURIYACO",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -28.5555816291232,
      "centroide_lon": -67.1592836516078,
      "departamento_id": "46119",
      "departamento_nombre": "San Blas de Los Sauces",
      "id": "46119010012",
      "localidad_censal_id": "46119010",
      "localidad_censal_nombre": "Salicas - San Blas",
      "municipio_id": "460119",
      "municipio_nombre": "San Blas de los Sauces",
      "nombre": "TUYUBIL",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3064491456943,
      "centroide_lon": -67.0414097362521,
      "departamento_id": "46126",
      "departamento_nombre": "Sanagasta",
      "id": "46126010000",
      "localidad_censal_id": "46126010",
      "localidad_censal_nombre": "Villa Sanagasta",
      "municipio_id": "460126",
      "municipio_nombre": "Sanagasta",
      "nombre": "VILLA SANAGASTA",
      "provincia_id": "46",
      "provincia_nombre": "La Rioja"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8774938474965,
      "centroide_lon": -68.852787067524,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010001",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "1A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8939366401965,
      "centroide_lon": -68.8548989896162,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010002",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "2A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8852234170157,
      "centroide_lon": -68.8445606513402,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010003",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "3A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8682492511423,
      "centroide_lon": -68.842426668283,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010004",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "4A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8897126457887,
      "centroide_lon": -68.8671660840729,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010005",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "5A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8699135628239,
      "centroide_lon": -68.8623511106204,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010006",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "6A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8737448660325,
      "centroide_lon": -68.8758699221604,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010007",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "7A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8663164516233,
      "centroide_lon": -68.8830572695415,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010008",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "8A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8888290596505,
      "centroide_lon": -68.8943435432101,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010009",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "9A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8762926131736,
      "centroide_lon": -68.935890793212,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010010",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "10A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8885015214853,
      "centroide_lon": -68.9052495878294,
      "departamento_id": "50007",
      "departamento_nombre": "Capital",
      "id": "50007010011",
      "localidad_censal_id": "50007010",
      "localidad_censal_nombre": "Mendoza",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "11A. SECCION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0009124317209,
      "centroide_lon": -67.5161818289008,
      "departamento_id": "50014",
      "departamento_nombre": "General Alvear",
      "id": "50014010000",
      "localidad_censal_id": "50014010",
      "localidad_censal_nombre": "Bowen",
      "municipio_id": "500014",
      "municipio_nombre": "General Alvear",
      "nombre": "BOWEN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1449057898791,
      "centroide_lon": -67.662955044003,
      "departamento_id": "50014",
      "departamento_nombre": "General Alvear",
      "id": "50014020000",
      "localidad_censal_id": "50014020",
      "localidad_censal_nombre": "Carmensa",
      "municipio_id": "500014",
      "municipio_nombre": "General Alvear",
      "nombre": "CARMENSA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9804694766564,
      "centroide_lon": -67.7009950828176,
      "departamento_id": "50014",
      "departamento_nombre": "General Alvear",
      "id": "50014030000",
      "localidad_censal_id": "50014030",
      "localidad_censal_nombre": "General Alvear",
      "municipio_id": "500014",
      "municipio_nombre": "General Alvear",
      "nombre": "GENERAL ALVEAR",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9792604557582,
      "centroide_lon": -67.6590893207424,
      "departamento_id": "50014",
      "departamento_nombre": "General Alvear",
      "id": "50014040000",
      "localidad_censal_id": "50014040",
      "localidad_censal_nombre": "Los Compartos",
      "municipio_id": "500014",
      "municipio_nombre": "General Alvear",
      "nombre": "LOS COMPARTOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9215283150576,
      "centroide_lon": -68.8549231831208,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010001",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "GODOY CRUZ",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9443750737376,
      "centroide_lon": -68.8651880637399,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010002",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "GOBERNADOR BENEGAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9453107154249,
      "centroide_lon": -68.8411734730396,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010003",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "LAS TORTUGAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9171352448748,
      "centroide_lon": -68.9190971058586,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010004",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "PRESIDENTE SARMIENTO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9301248190877,
      "centroide_lon": -68.8333388830804,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010005",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "SAN FRANCISCO DEL MONTE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9085519196216,
      "centroide_lon": -68.8709990197701,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010006",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "VILLA HIPODROMO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9368994348648,
      "centroide_lon": -68.8625261619583,
      "departamento_id": "50021",
      "departamento_nombre": "Godoy Cruz",
      "id": "50021010007",
      "localidad_censal_id": "50021010",
      "localidad_censal_nombre": "Godoy Cruz",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "VILLA MARINI",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8445720739406,
      "centroide_lon": -68.7264015008637,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028010000",
      "localidad_censal_id": "50028010",
      "localidad_censal_nombre": "Colonia Segovia",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "COLONIA SEGOVIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8674494392665,
      "centroide_lon": -68.8015983577909,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020001",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "BERMEJO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.887610360379,
      "centroide_lon": -68.7797113648865,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020002",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "BUENA NUEVA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9057059086968,
      "centroide_lon": -68.7753107154796,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020003",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "CAPILLA DEL ROSARIO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9079284079055,
      "centroide_lon": -68.8402487586896,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020004",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500021",
      "municipio_nombre": "Godoy Cruz",
      "nombre": "DORREGO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.866493937014,
      "centroide_lon": -68.7583334039687,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020005",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "EL SAUCE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8819026438146,
      "centroide_lon": -68.8087568548896,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020006",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "GENERAL BELGRANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9208013873904,
      "centroide_lon": -68.7868066982545,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020007",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "JESUS NAZARENO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9057702142765,
      "centroide_lon": -68.8263412149075,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020008",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "LAS CAÑAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8925289639529,
      "centroide_lon": -68.820068537927,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020009",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "GUAYMALLEN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8749826070419,
      "centroide_lon": -68.8287743942338,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020010",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "PEDRO MOLINA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9233876380597,
      "centroide_lon": -68.7484037679985,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020011",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "RODEO DE LA CRUZ",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9176425793206,
      "centroide_lon": -68.8127477726945,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020012",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "SAN FRANCISCO DEL MONTE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.887910080091,
      "centroide_lon": -68.8336077167937,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020013",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500007",
      "municipio_nombre": "Capital",
      "nombre": "SAN JOSE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9010420892697,
      "centroide_lon": -68.8031458310547,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028020014",
      "localidad_censal_id": "50028020",
      "localidad_censal_nombre": "Guaymallén",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "VILLA NUEVA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9212115151732,
      "centroide_lon": -68.6797682708562,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028030000",
      "localidad_censal_id": "50028030",
      "localidad_censal_nombre": "La Primavera",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "LA PRIMAVERA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8978664693342,
      "centroide_lon": -68.7059893326547,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028040000",
      "localidad_censal_id": "50028040",
      "localidad_censal_nombre": "Los Corralitos",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "LOS CORRALITOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8596996528436,
      "centroide_lon": -68.6889630695891,
      "departamento_id": "50028",
      "departamento_nombre": "Guaymallén",
      "id": "50028050000",
      "localidad_censal_id": "50028050",
      "localidad_censal_nombre": "Puente de Hierro",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "PUENTE DE HIERRO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1247748459194,
      "centroide_lon": -68.4208925887498,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035010000",
      "localidad_censal_id": "50035010",
      "localidad_censal_nombre": "Ingeniero Giagnoni",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "INGENIERO GIAGNONI",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1450869905894,
      "centroide_lon": -68.4921156012444,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035020000",
      "localidad_censal_id": "50035020",
      "localidad_censal_nombre": "Junín",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "JUNIN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0927994762934,
      "centroide_lon": -68.4874957269843,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035030000",
      "localidad_censal_id": "50035030",
      "localidad_censal_nombre": "La Colonia",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "LA COLONIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1010405156356,
      "centroide_lon": -68.567527971482,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035040000",
      "localidad_censal_id": "50035040",
      "localidad_censal_nombre": "Los Barriales",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "LOS BARRIALES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1762912284126,
      "centroide_lon": -68.6231695474222,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035050000",
      "localidad_censal_id": "50035050",
      "localidad_censal_nombre": "Medrano",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "MEDRANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2032507142375,
      "centroide_lon": -68.3832295478144,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035060000",
      "localidad_censal_id": "50035060",
      "localidad_censal_nombre": "Phillips",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "PHILLIPS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1211519220358,
      "centroide_lon": -68.6046800215005,
      "departamento_id": "50035",
      "departamento_nombre": "Junín",
      "id": "50035070000",
      "localidad_censal_id": "50035070",
      "localidad_censal_nombre": "Rodríguez Peña",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "RODRIGUEZ PEÑA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.4052265769401,
      "centroide_lon": -67.1641664357197,
      "departamento_id": "50042",
      "departamento_nombre": "La Paz",
      "id": "50042010000",
      "localidad_censal_id": "50042010",
      "localidad_censal_nombre": "Desaguadero",
      "municipio_id": "500042",
      "municipio_nombre": "La Paz",
      "nombre": "DESAGUADERO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4610193184125,
      "centroide_lon": -67.5595785270435,
      "departamento_id": "50042",
      "departamento_nombre": "La Paz",
      "id": "50042020000",
      "localidad_censal_id": "50042020",
      "localidad_censal_nombre": "La Paz",
      "municipio_id": "500042",
      "municipio_nombre": "La Paz",
      "nombre": "LA PAZ",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4645156410531,
      "centroide_lon": -67.6055785043576,
      "departamento_id": "50042",
      "departamento_nombre": "La Paz",
      "id": "50042030000",
      "localidad_censal_id": "50042030",
      "localidad_censal_nombre": "Villa Antigua",
      "municipio_id": "500042",
      "municipio_nombre": "La Paz",
      "nombre": "VILLA ANTIGUA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0355485376289,
      "centroide_lon": -69.0065315897085,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049010000",
      "localidad_censal_id": "50049010",
      "localidad_censal_nombre": "Blanco Encalada",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "BLANCO ENCALADA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.5843190924112,
      "centroide_lon": -68.6808088140348,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049030000",
      "localidad_censal_id": "50049030",
      "localidad_censal_nombre": "Jocolí",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "JOCOLI",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8079619437825,
      "centroide_lon": -70.0851824266087,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049040000",
      "localidad_censal_id": "50049040",
      "localidad_censal_nombre": "Las Cuevas",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "LAS CUEVAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.7248779288865,
      "centroide_lon": -68.9085584856171,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050001",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "CAPDEVILA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8499236875213,
      "centroide_lon": -68.8452567330483,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050002",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "LAS HERAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8327487536079,
      "centroide_lon": -68.7957179005563,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050003",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL ALGARROBAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8069208013923,
      "centroide_lon": -68.7772864976942,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050004",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL BORBOLLON",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8187507127469,
      "centroide_lon": -68.9164320667614,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050005",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL CHALLAO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8468358627701,
      "centroide_lon": -68.8191474531696,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050007",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL PLUMERILLO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.7650499892594,
      "centroide_lon": -68.7913047281368,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050008",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL RESGUARDO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8555537707294,
      "centroide_lon": -68.8280317752065,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050009",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "EL ZAPALLAR",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8444580470853,
      "centroide_lon": -68.8646206504362,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050010",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "LA CIENEGUITA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8349236425418,
      "centroide_lon": -68.8451652007894,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050011",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "PANQUEHUA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9869460291704,
      "centroide_lon": -68.9485091767272,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049050012",
      "localidad_censal_id": "50049050",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "SIERRAS DE ENCALADA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8369504266306,
      "centroide_lon": -69.853921289708,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049060000",
      "localidad_censal_id": "50049060",
      "localidad_censal_nombre": "Los Penitentes",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LOS PENITENTES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8190384814445,
      "centroide_lon": -69.7097346552796,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049080000",
      "localidad_censal_id": "50049080",
      "localidad_censal_nombre": "Polvaredas",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "POLVAREDAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8198835580194,
      "centroide_lon": -69.9250020499226,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049090000",
      "localidad_censal_id": "50049090",
      "localidad_censal_nombre": "Puente del Inca",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "PUENTE DEL INCA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8488063589156,
      "centroide_lon": -69.7763007026825,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049100000",
      "localidad_censal_id": "50049100",
      "localidad_censal_nombre": "Punta de Vacas",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "PUNTA DE VACAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.595534534037,
      "centroide_lon": -69.3582053741335,
      "departamento_id": "50049",
      "departamento_nombre": "Las Heras",
      "id": "50049110000",
      "localidad_censal_id": "50049110",
      "localidad_censal_nombre": "Uspallata",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "USPALLATA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6727794218396,
      "centroide_lon": -68.5922473767644,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056010000",
      "localidad_censal_id": "50056010",
      "localidad_censal_nombre": "Barrio Alto del Olvido",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO ALTO DEL OLVIDO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6740793089036,
      "centroide_lon": -68.6692696211749,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056020000",
      "localidad_censal_id": "50056020",
      "localidad_censal_nombre": "Barrio Jocolí II",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "BARRIO JOCOLI II",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6969328275291,
      "centroide_lon": -68.5506213221534,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056030000",
      "localidad_censal_id": "50056030",
      "localidad_censal_nombre": "Barrio La Palmera",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LA PALMERA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8128590043094,
      "centroide_lon": -68.6835373227479,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056040000",
      "localidad_censal_id": "50056040",
      "localidad_censal_nombre": "Barrio La Pega",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LA PEGA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6111088672137,
      "centroide_lon": -68.5714746331489,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056050000",
      "localidad_censal_id": "50056050",
      "localidad_censal_nombre": "Barrio Lagunas de Bartoluzzi",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LAGUNAS DE BARTOLUZZI",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7153965740662,
      "centroide_lon": -68.6584021819475,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056060000",
      "localidad_censal_id": "50056060",
      "localidad_censal_nombre": "Barrio Los Jarilleros",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LOS JARILLEROS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6972155730911,
      "centroide_lon": -68.3295580099939,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056070000",
      "localidad_censal_id": "50056070",
      "localidad_censal_nombre": "Barrio Los Olivos",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LOS OLIVOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7027597161508,
      "centroide_lon": -68.3124921903406,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056075000",
      "localidad_censal_id": "50056075",
      "localidad_censal_nombre": "Barrio Virgen del Rosario",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO VIRGEN DEL ROSARIO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7568943573314,
      "centroide_lon": -68.4085951866641,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056080000",
      "localidad_censal_id": "50056080",
      "localidad_censal_nombre": "Costa de Araujo",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "COSTA DE ARAUJO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7813088245414,
      "centroide_lon": -68.5336830930131,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056090000",
      "localidad_censal_id": "50056090",
      "localidad_censal_nombre": "El Paramillo",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "EL PARAMILLO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7623132216047,
      "centroide_lon": -68.6476011792189,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056100000",
      "localidad_censal_id": "50056100",
      "localidad_censal_nombre": "El Vergel",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "EL VERGEL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.6735728612892,
      "centroide_lon": -68.3840802981371,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056110001",
      "localidad_censal_id": "50056110",
      "localidad_censal_nombre": "Ingeniero Gustavo André",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "BARRIO LA ESPERANZA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.6824002043157,
      "centroide_lon": -68.3268969073829,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056110002",
      "localidad_censal_id": "50056110",
      "localidad_censal_nombre": "Ingeniero Gustavo André",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "INGENIERO GUSTAVO ANDRE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.6125410510042,
      "centroide_lon": -68.6790408967958,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056120000",
      "localidad_censal_id": "50056120",
      "localidad_censal_nombre": "Jocolí",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "JOCOLI",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7274623415005,
      "centroide_lon": -68.6602319575528,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056130000",
      "localidad_censal_id": "50056130",
      "localidad_censal_nombre": "Jocolí Viejo",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "JOCOLI VIEJO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8208151905733,
      "centroide_lon": -68.6164140763718,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056140000",
      "localidad_censal_id": "50056140",
      "localidad_censal_nombre": "Las Violetas",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "LAS VIOLETAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.675527945267,
      "centroide_lon": -68.6462113031921,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056150000",
      "localidad_censal_id": "50056150",
      "localidad_censal_nombre": "3 de Mayo",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "3 DE MAYO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7196142429269,
      "centroide_lon": -68.6030438537926,
      "departamento_id": "50056",
      "departamento_nombre": "Lavalle",
      "id": "50056160000",
      "localidad_censal_id": "50056160",
      "localidad_censal_nombre": "Villa Tulumaya",
      "municipio_id": "500056",
      "municipio_nombre": "Lavalle",
      "nombre": "VILLA TULUMAYA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1173844439975,
      "centroide_lon": -68.8960235351828,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063010000",
      "localidad_censal_id": "50063010",
      "localidad_censal_nombre": "Agrelo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "AGRELO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0753282280469,
      "centroide_lon": -68.9250404043338,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063020000",
      "localidad_censal_id": "50063020",
      "localidad_censal_nombre": "Barrio Perdriel IV",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "BARRIO PERDRIEL IV",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0367104413315,
      "centroide_lon": -69.1159068278817,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063030000",
      "localidad_censal_id": "50063030",
      "localidad_censal_nombre": "Cacheuta",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "CACHEUTA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0705184504059,
      "centroide_lon": -68.9343214868201,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063040000",
      "localidad_censal_id": "50063040",
      "localidad_censal_nombre": "Costa Flores",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "COSTA FLORES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3037433557192,
      "centroide_lon": -68.7554951968491,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063050000",
      "localidad_censal_id": "50063050",
      "localidad_censal_nombre": "El Carrizal",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "EL CARRIZAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9551111218674,
      "centroide_lon": -69.281192394514,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063060001",
      "localidad_censal_id": "50063060",
      "localidad_censal_nombre": "El Salto",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "EL CARMELO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8994781760003,
      "centroide_lon": -69.3336484431929,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063060002",
      "localidad_censal_id": "50063060",
      "localidad_censal_nombre": "El Salto",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "EL SALTO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9551111218674,
      "centroide_lon": -69.281192394514,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063060003",
      "localidad_censal_id": "50063060",
      "localidad_censal_nombre": "El Salto",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LAS CARDITAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.8990128008468,
      "centroide_lon": -69.3340868979844,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063060004",
      "localidad_censal_id": "50063060",
      "localidad_censal_nombre": "El Salto",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LOS MANANTIALES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0343953638207,
      "centroide_lon": -68.9757201428593,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063070000",
      "localidad_censal_id": "50063070",
      "localidad_censal_nombre": "Las Compuertas",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LAS COMPUERTAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0058788620171,
      "centroide_lon": -69.2764002200338,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063080001",
      "localidad_censal_id": "50063080",
      "localidad_censal_nombre": "Las Vegas",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LAS VEGAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0198804741995,
      "centroide_lon": -69.3030036808918,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063080002",
      "localidad_censal_id": "50063080",
      "localidad_censal_nombre": "Las Vegas",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "PIEDRAS BLANCAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0174255607471,
      "centroide_lon": -69.2972914626044,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063080003",
      "localidad_censal_id": "50063080",
      "localidad_censal_nombre": "Las Vegas",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "VALLE DEL SOL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9975296309707,
      "centroide_lon": -69.317680250878,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063080004",
      "localidad_censal_id": "50063080",
      "localidad_censal_nombre": "Las Vegas",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "VILLA EL REFUGIO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9776578285745,
      "centroide_lon": -68.8524394649607,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090001",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "CARRODILLA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9899032062811,
      "centroide_lon": -68.8913679185275,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090002",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "CHACRAS DE CORIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0328665626243,
      "centroide_lon": -68.8912066261393,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090003",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "LUJAN DE CUYO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9609713860174,
      "centroide_lon": -68.8723323876365,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090004",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500049",
      "municipio_nombre": "Las Heras",
      "nombre": "LA PUNTILLA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0038391611615,
      "centroide_lon": -68.8612966964436,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090005",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "MAYOR DRUMMOND",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0237918976098,
      "centroide_lon": -68.9247432402669,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063090006",
      "localidad_censal_id": "50063090",
      "localidad_censal_nombre": "Luján de Cuyo",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "VISTALBA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0649388173829,
      "centroide_lon": -68.8877014932113,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063100001",
      "localidad_censal_id": "50063100",
      "localidad_censal_nombre": "Perdriel",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "BARRIO ADINA I Y II",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0690096392441,
      "centroide_lon": -68.8986010698045,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063100002",
      "localidad_censal_id": "50063100",
      "localidad_censal_nombre": "Perdriel",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "PERDRIEL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.945486814506,
      "centroide_lon": -69.2086449063504,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063110000",
      "localidad_censal_id": "50063110",
      "localidad_censal_nombre": "Potrerillos",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "POTRERILLOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2109451573216,
      "centroide_lon": -68.8972088041994,
      "departamento_id": "50063",
      "departamento_nombre": "Luján de Cuyo",
      "id": "50063120000",
      "localidad_censal_id": "50063120",
      "localidad_censal_nombre": "Ugarteche",
      "municipio_id": "500063",
      "municipio_nombre": "Luján de Cuyo",
      "nombre": "UGARTECHE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0833355400244,
      "centroide_lon": -68.7343792557021,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070010000",
      "localidad_censal_id": "50070010",
      "localidad_censal_nombre": "Barrancas",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "BARRANCAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0103244179699,
      "centroide_lon": -68.7265920537236,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070020000",
      "localidad_censal_id": "50070020",
      "localidad_censal_nombre": "Barrio Jesús de Nazaret",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "BARRIO JESUS DE NAZARET",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0293391100097,
      "centroide_lon": -68.7922412248266,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070030000",
      "localidad_censal_id": "50070030",
      "localidad_censal_nombre": "Cruz de Piedra",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "CRUZ DE PIEDRA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8558805014704,
      "centroide_lon": -68.6544793531012,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070040000",
      "localidad_censal_id": "50070040",
      "localidad_censal_nombre": "El Pedregal",
      "municipio_id": "500028",
      "municipio_nombre": "Guaymallén",
      "nombre": "EL PEDREGAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0049403047291,
      "centroide_lon": -68.6612377129718,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070050000",
      "localidad_censal_id": "50070050",
      "localidad_censal_nombre": "Fray Luis Beltrán",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "FRAY LUIS BELTRAN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9792545593489,
      "centroide_lon": -68.8089838444183,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070060001",
      "localidad_censal_id": "50070060",
      "localidad_censal_nombre": "Maipú",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "COQUIMBITO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9797721809011,
      "centroide_lon": -68.769429675373,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070060002",
      "localidad_censal_id": "50070060",
      "localidad_censal_nombre": "Maipú",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "GENERAL GUTIERREZ",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9500582112409,
      "centroide_lon": -68.8034450473205,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070060003",
      "localidad_censal_id": "50070060",
      "localidad_censal_nombre": "Maipú",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "LUZURIAGA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -32.9394310178507,
      "centroide_lon": -68.8068438760291,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070060004",
      "localidad_censal_id": "50070060",
      "localidad_censal_nombre": "Maipú",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "MAIPU",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9880056754694,
      "centroide_lon": -68.7010652608485,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070070000",
      "localidad_censal_id": "50070070",
      "localidad_censal_nombre": "Rodeo del Medio",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "RODEO DEL MEDIO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0046770772729,
      "centroide_lon": -68.8012715093769,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070080000",
      "localidad_censal_id": "50070080",
      "localidad_censal_nombre": "Russell",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "RUSSELL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0285638174611,
      "centroide_lon": -68.5981475648442,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070090000",
      "localidad_censal_id": "50070090",
      "localidad_censal_nombre": "San Roque",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "SAN ROQUE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0183178912335,
      "centroide_lon": -68.6288154157882,
      "departamento_id": "50070",
      "departamento_nombre": "Maipú",
      "id": "50070100000",
      "localidad_censal_id": "50070100",
      "localidad_censal_nombre": "Villa Teresa",
      "municipio_id": "500070",
      "municipio_nombre": "Maipú",
      "nombre": "VILLA TERESA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.1532390359812,
      "centroide_lon": -68.3048428543176,
      "departamento_id": "50077",
      "departamento_nombre": "Malargüe",
      "id": "50077010000",
      "localidad_censal_id": "50077010",
      "localidad_censal_nombre": "Agua Escondida",
      "municipio_id": "500077",
      "municipio_nombre": "Malargüe",
      "nombre": "AGUA ESCONDIDA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1539650781638,
      "centroide_lon": -70.0817910991633,
      "departamento_id": "50077",
      "departamento_nombre": "Malargüe",
      "id": "50077030000",
      "localidad_censal_id": "50077030",
      "localidad_censal_nombre": "Las Leñas",
      "municipio_id": "500077",
      "municipio_nombre": "Malargüe",
      "nombre": "LAS LEÑAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.4770107120892,
      "centroide_lon": -69.5886491865783,
      "departamento_id": "50077",
      "departamento_nombre": "Malargüe",
      "id": "50077040000",
      "localidad_censal_id": "50077040",
      "localidad_censal_nombre": "Malargüe",
      "municipio_id": "500077",
      "municipio_nombre": "Malargüe",
      "nombre": "MALARGUE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1624680014146,
      "centroide_lon": -68.506066911759,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084010000",
      "localidad_censal_id": "50084010",
      "localidad_censal_nombre": "Andrade",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "ANDRADE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2692489302548,
      "centroide_lon": -68.4383631651486,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084020000",
      "localidad_censal_id": "50084020",
      "localidad_censal_nombre": "Barrio Cooperativa Los Campamentos",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "BARRIO COOPERATIVA LOS CAMPAMENTOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2304643070476,
      "centroide_lon": -68.4837256054182,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084030000",
      "localidad_censal_id": "50084030",
      "localidad_censal_nombre": "Barrio Rivadavia",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "BARRIO RIVADAVIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2906467543213,
      "centroide_lon": -68.3475556585345,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084040000",
      "localidad_censal_id": "50084040",
      "localidad_censal_nombre": "El Mirador",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "EL MIRADOR",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2736424903477,
      "centroide_lon": -68.3224073182181,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084050000",
      "localidad_censal_id": "50084050",
      "localidad_censal_nombre": "La Central",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LA CENTRAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3170659709211,
      "centroide_lon": -68.3343426790403,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084060000",
      "localidad_censal_id": "50084060",
      "localidad_censal_nombre": "La Esperanza",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LA ESPERANZA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.282949271283,
      "centroide_lon": -68.4657441362603,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084070001",
      "localidad_censal_id": "50084070",
      "localidad_censal_nombre": "La Florida",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "CUADRO ORTEGA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.2827922646265,
      "centroide_lon": -68.4657172961175,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084070002",
      "localidad_censal_id": "50084070",
      "localidad_censal_nombre": "La Florida",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LA FLORIDA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2163869013321,
      "centroide_lon": -68.5166268853637,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084080000",
      "localidad_censal_id": "50084080",
      "localidad_censal_nombre": "La Libertad",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LA LIBERTAD",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1813162523339,
      "centroide_lon": -68.5759160345244,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084090000",
      "localidad_censal_id": "50084090",
      "localidad_censal_nombre": "Los Árboles",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LOS ARBOLES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2763607628549,
      "centroide_lon": -68.4008493516637,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084100000",
      "localidad_censal_id": "50084100",
      "localidad_censal_nombre": "Los Campamentos",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "LOS CAMPAMENTOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1787463382932,
      "centroide_lon": -68.6219271508795,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084110000",
      "localidad_censal_id": "50084110",
      "localidad_censal_nombre": "Medrano",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "MEDRANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1753415989261,
      "centroide_lon": -68.4371725862256,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084120000",
      "localidad_censal_id": "50084120",
      "localidad_censal_nombre": "Mundo Nuevo",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "MUNDO NUEVO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2063088772501,
      "centroide_lon": -68.5835152115814,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084130000",
      "localidad_censal_id": "50084130",
      "localidad_censal_nombre": "Reducción de Abajo",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "REDUCCION DE ABAJO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.19403215031,
      "centroide_lon": -68.4736188877526,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084140000",
      "localidad_censal_id": "50084140",
      "localidad_censal_nombre": "Rivadavia",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "RIVADAVIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2059806305116,
      "centroide_lon": -68.4335589109359,
      "departamento_id": "50084",
      "departamento_nombre": "Rivadavia",
      "id": "50084150000",
      "localidad_censal_id": "50084150",
      "localidad_censal_nombre": "Santa María de Oro",
      "municipio_id": "500084",
      "municipio_nombre": "Rivadavia",
      "nombre": "SANTA MARIA DE ORO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8321066130502,
      "centroide_lon": -69.0490032074462,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091005000",
      "localidad_censal_id": "50091005",
      "localidad_censal_nombre": "Barrio Carrasco",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "BARRIO CARRASCO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8386533809471,
      "centroide_lon": -69.1303497215532,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091010000",
      "localidad_censal_id": "50091010",
      "localidad_censal_nombre": "Barrio El Cepillo",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "BARRIO EL CEPILLO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8934020968296,
      "centroide_lon": -69.0804633582269,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091020000",
      "localidad_censal_id": "50091020",
      "localidad_censal_nombre": "Chilecito",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "CHILECITO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7850224488548,
      "centroide_lon": -69.0729045258216,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091030000",
      "localidad_censal_id": "50091030",
      "localidad_censal_nombre": "Eugenio Bustos",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "EUGENIO BUSTOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7386757381913,
      "centroide_lon": -69.1281255649691,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091040000",
      "localidad_censal_id": "50091040",
      "localidad_censal_nombre": "La Consulta",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "LA CONSULTA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.9452563037172,
      "centroide_lon": -69.0824859034273,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091050000",
      "localidad_censal_id": "50091050",
      "localidad_censal_nombre": "Pareditas",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "PAREDITAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7744494556708,
      "centroide_lon": -69.0490909178745,
      "departamento_id": "50091",
      "departamento_nombre": "San Carlos",
      "id": "50091060000",
      "localidad_censal_id": "50091060",
      "localidad_censal_nombre": "San Carlos",
      "municipio_id": "500091",
      "municipio_nombre": "San Carlos",
      "nombre": "SAN CARLOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1195037386563,
      "centroide_lon": -68.4180218961221,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098020000",
      "localidad_censal_id": "50098020",
      "localidad_censal_nombre": "Alto Verde",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "ALTO VERDE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0411467362082,
      "centroide_lon": -68.3541527366296,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098030000",
      "localidad_censal_id": "50098030",
      "localidad_censal_nombre": "Barrio Chivilcoy",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "BARRIO CHIVILCOY",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8468555962866,
      "centroide_lon": -68.4100199226668,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098040000",
      "localidad_censal_id": "50098040",
      "localidad_censal_nombre": "Barrio Emanuel",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "BARRIO EMANUEL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.155290161095,
      "centroide_lon": -68.3543621643557,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098045000",
      "localidad_censal_id": "50098045",
      "localidad_censal_nombre": "Barrio La Estación",
      "municipio_id": "500035",
      "municipio_nombre": "Junín",
      "nombre": "BARRIO LA ESTACION",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9785115683367,
      "centroide_lon": -68.3051018239824,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098050000",
      "localidad_censal_id": "50098050",
      "localidad_censal_nombre": "Barrio Los Charabones",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "BARRIO LOS CHARABONES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1309714824444,
      "centroide_lon": -68.351277983232,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098055000",
      "localidad_censal_id": "50098055",
      "localidad_censal_nombre": "Barrio Ntra. Sra. De Fátima",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "BARRIO NTRA. SRA. DE FATIMA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9801177295667,
      "centroide_lon": -68.4765253845307,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098060000",
      "localidad_censal_id": "50098060",
      "localidad_censal_nombre": "Chapanay",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "CHAPANAY",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.038335582925,
      "centroide_lon": -68.4594029398778,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098070000",
      "localidad_censal_id": "50098070",
      "localidad_censal_nombre": "Chivilcoy",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "CHIVILCOY",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.077333062302,
      "centroide_lon": -68.3982838200909,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098073000",
      "localidad_censal_id": "50098073",
      "localidad_censal_nombre": "El Espino",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "EL ESPINO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1617709736953,
      "centroide_lon": -68.2954844260235,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098077000",
      "localidad_censal_id": "50098077",
      "localidad_censal_nombre": "El Ramblón",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "EL RAMBLON",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0109966347898,
      "centroide_lon": -68.3936801268915,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098080000",
      "localidad_censal_id": "50098080",
      "localidad_censal_nombre": "Montecaseros",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "MONTECASEROS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7450606095364,
      "centroide_lon": -68.335450804198,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098090000",
      "localidad_censal_id": "50098090",
      "localidad_censal_nombre": "Nueva California",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "NUEVA CALIFORNIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0499932818968,
      "centroide_lon": -68.5490896779466,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098100001",
      "localidad_censal_id": "50098100",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "PALMIRA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.0732178033808,
      "centroide_lon": -68.4605125505865,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098100002",
      "localidad_censal_id": "50098100",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "CIUDAD DE SAN MARTIN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8994801779561,
      "centroide_lon": -68.3990962886724,
      "departamento_id": "50098",
      "departamento_nombre": "San Martín",
      "id": "50098110000",
      "localidad_censal_id": "50098110",
      "localidad_censal_nombre": "Tres Porteñas",
      "municipio_id": "500098",
      "municipio_nombre": "San Martín",
      "nombre": "TRES PORTEÑAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.573654040475,
      "centroide_lon": -68.3294341868083,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105010000",
      "localidad_censal_id": "50105010",
      "localidad_censal_nombre": "Barrio Campos El Toledano",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO CAMPOS EL TOLEDANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7898637210549,
      "centroide_lon": -67.9874651693673,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105020000",
      "localidad_censal_id": "50105020",
      "localidad_censal_nombre": "Barrio El Nevado",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO EL NEVADO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5522300096664,
      "centroide_lon": -68.3032401705615,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105030000",
      "localidad_censal_id": "50105030",
      "localidad_censal_nombre": "Barrio Empleados de Comercio",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO EMPLEADOS DE COMERCIO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.645100562517,
      "centroide_lon": -68.2754543992412,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105040000",
      "localidad_censal_id": "50105040",
      "localidad_censal_nombre": "Barrio Intendencia",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO INTENDENCIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5835613146884,
      "centroide_lon": -68.4654391114406,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105050000",
      "localidad_censal_id": "50105050",
      "localidad_censal_nombre": "Capitán Montoya",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "CAPITAN MONTOYA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6278079094528,
      "centroide_lon": -68.4330730738277,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105060001",
      "localidad_censal_id": "50105060",
      "localidad_censal_nombre": "Cuadro Benegas",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO ECHEVERRIA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6278079094528,
      "centroide_lon": -68.4330730738277,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105060002",
      "localidad_censal_id": "50105060",
      "localidad_censal_nombre": "Cuadro Benegas",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO LAS ROSAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6298100948936,
      "centroide_lon": -68.5105923910427,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105060003",
      "localidad_censal_id": "50105060",
      "localidad_censal_nombre": "Cuadro Benegas",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "BARRIO PRIMAVERA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0353713864246,
      "centroide_lon": -68.6806149266928,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105070000",
      "localidad_censal_id": "50105070",
      "localidad_censal_nombre": "El Nihuil",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "EL NIHUIL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.0821772227253,
      "centroide_lon": -69.5924127887533,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105080000",
      "localidad_censal_id": "50105080",
      "localidad_censal_nombre": "El Sosneado",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "EL SOSNEADO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6856449091453,
      "centroide_lon": -68.2855505604444,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105090000",
      "localidad_censal_id": "50105090",
      "localidad_censal_nombre": "El Tropezón",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "EL TROPEZON",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6803228490363,
      "centroide_lon": -68.1358422919666,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105100000",
      "localidad_censal_id": "50105100",
      "localidad_censal_nombre": "Goudge",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "GOUDGE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.9146831219286,
      "centroide_lon": -67.8191470107259,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105110000",
      "localidad_censal_id": "50105110",
      "localidad_censal_nombre": "Jaime Prats",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "JAIME PRATS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6449297466889,
      "centroide_lon": -68.0147704723126,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105120000",
      "localidad_censal_id": "50105120",
      "localidad_censal_nombre": "La Llave Nueva",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "LA LLAVE NUEVA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8384149976168,
      "centroide_lon": -68.2539362219611,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105130000",
      "localidad_censal_id": "50105130",
      "localidad_censal_nombre": "Las Malvinas",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "LAS MALVINAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6099288763693,
      "centroide_lon": -68.6205430790631,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105140000",
      "localidad_censal_id": "50105140",
      "localidad_censal_nombre": "Los Reyunos",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "LOS REYUNOS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5971809945468,
      "centroide_lon": -67.8842899055404,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105150000",
      "localidad_censal_id": "50105150",
      "localidad_censal_nombre": "Monte Comán",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "MONTE COMAN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6703587937035,
      "centroide_lon": -68.3591845637218,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105160000",
      "localidad_censal_id": "50105160",
      "localidad_censal_nombre": "Pobre Diablo",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "POBRE DIABLO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.530165567926,
      "centroide_lon": -68.0825092985548,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105170000",
      "localidad_censal_id": "50105170",
      "localidad_censal_nombre": "Punta del Agua",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "PUNTA DEL AGUA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7043760307316,
      "centroide_lon": -68.3708631047738,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105180000",
      "localidad_censal_id": "50105180",
      "localidad_censal_nombre": "Rama Caída",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "RAMA CAIDA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8433149755939,
      "centroide_lon": -67.7674203749059,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105190000",
      "localidad_censal_id": "50105190",
      "localidad_censal_nombre": "Real del Padre",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "REAL DEL PADRE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.726958284268,
      "centroide_lon": -68.2329031579027,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105200000",
      "localidad_censal_id": "50105200",
      "localidad_censal_nombre": "Salto de las Rosas",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "SALTO DE LAS ROSAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6175050676523,
      "centroide_lon": -68.2817525680486,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105210001",
      "localidad_censal_id": "50105210",
      "localidad_censal_nombre": "San Rafael",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "CUADRO NACIONAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6356104202068,
      "centroide_lon": -68.3310920132847,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105210002",
      "localidad_censal_id": "50105210",
      "localidad_censal_nombre": "San Rafael",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "SAN RAFAEL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.5859138924719,
      "centroide_lon": -68.5495682219631,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105220000",
      "localidad_censal_id": "50105220",
      "localidad_censal_nombre": "25 de Mayo",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "25 DE MAYO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.8345022006919,
      "centroide_lon": -67.9257905889932,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105230000",
      "localidad_censal_id": "50105230",
      "localidad_censal_nombre": "Villa Atuel",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "VILLA ATUEL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7605998347692,
      "centroide_lon": -68.0374059028043,
      "departamento_id": "50105",
      "departamento_nombre": "San Rafael",
      "id": "50105240000",
      "localidad_censal_id": "50105240",
      "localidad_censal_nombre": "Villa Atuel Norte",
      "municipio_id": "500105",
      "municipio_nombre": "San Rafael",
      "nombre": "VILLA ATUEL NORTE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1912015360595,
      "centroide_lon": -68.2809624398751,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112010000",
      "localidad_censal_id": "50112010",
      "localidad_censal_nombre": "Barrio 12 de Octubre",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "BARRIO 12 DE OCTUBRE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2425136237648,
      "centroide_lon": -68.1826758332982,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112020000",
      "localidad_censal_id": "50112020",
      "localidad_censal_nombre": "Barrio María Auxiliadora",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "BARRIO MARIA AUXILIADORA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1297119555186,
      "centroide_lon": -68.2037878560507,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112030000",
      "localidad_censal_id": "50112030",
      "localidad_censal_nombre": "Barrio Molina Cabrera",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "BARRIO MOLINA CABRERA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3499243562079,
      "centroide_lon": -67.9160460628593,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112040000",
      "localidad_censal_id": "50112040",
      "localidad_censal_nombre": "La Dormida",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "LA DORMIDA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3001058785583,
      "centroide_lon": -68.0532182373693,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112050000",
      "localidad_censal_id": "50112050",
      "localidad_censal_nombre": "Las Catitas",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "LAS CATITAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2544227888887,
      "centroide_lon": -68.1567438797332,
      "departamento_id": "50112",
      "departamento_nombre": "Santa Rosa",
      "id": "50112060000",
      "localidad_censal_id": "50112060",
      "localidad_censal_nombre": "Santa Rosa",
      "municipio_id": "500112",
      "municipio_nombre": "Santa Rosa",
      "nombre": "SANTA ROSA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6339135898779,
      "centroide_lon": -69.1879023870713,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119010000",
      "localidad_censal_id": "50119010",
      "localidad_censal_nombre": "Barrio San Cayetano",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "BARRIO SAN CAYETANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.707078744557,
      "centroide_lon": -69.1826331369796,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119020000",
      "localidad_censal_id": "50119020",
      "localidad_censal_nombre": "Campo Los Andes",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "CAMPO LOS ANDES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6129364669522,
      "centroide_lon": -69.1126485401013,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119030000",
      "localidad_censal_id": "50119030",
      "localidad_censal_nombre": "Colonia Las Rosas",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "COLONIA LAS ROSAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6005741925646,
      "centroide_lon": -69.3350878551815,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119040000",
      "localidad_censal_id": "50119040",
      "localidad_censal_nombre": "El Manzano",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "EL MANZANO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5952101670008,
      "centroide_lon": -69.1840214848841,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119050000",
      "localidad_censal_id": "50119050",
      "localidad_censal_nombre": "Los Sauces",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "LOS SAUCES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5775549686559,
      "centroide_lon": -69.0253759357637,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119060000",
      "localidad_censal_id": "50119060",
      "localidad_censal_nombre": "Tunuyán",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "TUNUYAN",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.651355463945,
      "centroide_lon": -69.1646922660824,
      "departamento_id": "50119",
      "departamento_nombre": "Tunuyán",
      "id": "50119070000",
      "localidad_censal_id": "50119070",
      "localidad_censal_nombre": "Vista Flores",
      "municipio_id": "500119",
      "municipio_nombre": "Tunuyán",
      "nombre": "VISTA FLORES",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3301032817585,
      "centroide_lon": -69.1359513617454,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126010000",
      "localidad_censal_id": "50126010",
      "localidad_censal_nombre": "Barrio Belgrano Norte",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "BARRIO BELGRANO NORTE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4689491349532,
      "centroide_lon": -69.1399451802858,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126020000",
      "localidad_censal_id": "50126020",
      "localidad_censal_nombre": "Cordón del Plata",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "CORDON DEL PLATA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3706550679362,
      "centroide_lon": -69.1923487111206,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126030000",
      "localidad_censal_id": "50126030",
      "localidad_censal_nombre": "El Peral",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "EL PERAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3989500475535,
      "centroide_lon": -69.1057367245591,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126035000",
      "localidad_censal_id": "50126035",
      "localidad_censal_nombre": "El Zampal",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "EL ZAMPAL",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3688267818118,
      "centroide_lon": -69.1247363670469,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126040000",
      "localidad_censal_id": "50126040",
      "localidad_censal_nombre": "La Arboleda",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "LA ARBOLEDA",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3068450101282,
      "centroide_lon": -69.1645780862147,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126050000",
      "localidad_censal_id": "50126050",
      "localidad_censal_nombre": "San José",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "SAN JOSE",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3643638135734,
      "centroide_lon": -69.1513190958561,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126060001",
      "localidad_censal_id": "50126060",
      "localidad_censal_nombre": "Tupungato",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "TUPUNGATO",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.3420986445545,
      "centroide_lon": -69.1487758488674,
      "departamento_id": "50126",
      "departamento_nombre": "Tupungato",
      "id": "50126060002",
      "localidad_censal_id": "50126060",
      "localidad_censal_nombre": "Tupungato",
      "municipio_id": "500126",
      "municipio_nombre": "Tupungato",
      "nombre": "VILLA BASTIAS",
      "provincia_id": "50",
      "provincia_nombre": "Mendoza"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.909806877888,
      "centroide_lon": -55.7532126178864,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007010000",
      "localidad_censal_id": "54007010",
      "localidad_censal_nombre": "Apóstoles",
      "municipio_id": "540007",
      "municipio_nombre": "Apóstoles",
      "nombre": "APOSTOLES",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0572512053051,
      "centroide_lon": -55.6767993766976,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007020000",
      "localidad_censal_id": "54007020",
      "localidad_censal_nombre": "Azara",
      "municipio_id": "540014",
      "municipio_nombre": "Azara",
      "nombre": "AZARA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8839188226826,
      "centroide_lon": -55.7857809648065,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007025000",
      "localidad_censal_id": "54007025",
      "localidad_censal_nombre": "Barrio Rural",
      "municipio_id": "540007",
      "municipio_nombre": "Apóstoles",
      "nombre": "BARRIO RURAL",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.9079594261468,
      "centroide_lon": -55.8079584118965,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007030000",
      "localidad_censal_id": "54007030",
      "localidad_censal_nombre": "Estación Apóstoles",
      "municipio_id": "540007",
      "municipio_nombre": "Apóstoles",
      "nombre": "ESTACION APOSTOLES",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7474272299697,
      "centroide_lon": -55.7932485753782,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007040000",
      "localidad_censal_id": "54007040",
      "localidad_censal_nombre": "Pindapoy",
      "municipio_id": "540021",
      "municipio_nombre": "San José",
      "nombre": "PINDAPOY",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.108077909743,
      "centroide_lon": -55.6318879848456,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007050000",
      "localidad_censal_id": "54007050",
      "localidad_censal_nombre": "Rincón de Azara",
      "municipio_id": "540014",
      "municipio_nombre": "Azara",
      "nombre": "RINCON DE AZARA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7653179990542,
      "centroide_lon": -55.7746920758949,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007060000",
      "localidad_censal_id": "54007060",
      "localidad_censal_nombre": "San José",
      "municipio_id": "540021",
      "municipio_nombre": "San José",
      "nombre": "SAN JOSE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0000872703207,
      "centroide_lon": -55.608042877744,
      "departamento_id": "54007",
      "departamento_nombre": "Apóstoles",
      "id": "54007070000",
      "localidad_censal_id": "54007070",
      "localidad_censal_nombre": "Tres Capones",
      "municipio_id": "540028",
      "municipio_nombre": "Tres Capones",
      "nombre": "TRES CAPONES",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0952902000827,
      "centroide_lon": -54.8949054572105,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014010000",
      "localidad_censal_id": "54014010",
      "localidad_censal_nombre": "Aristóbulo del Valle",
      "municipio_id": "540035",
      "municipio_nombre": "Aristóbulo del Valle",
      "nombre": "ARISTOBULO DEL VALLE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2062681295066,
      "centroide_lon": -54.979095503669,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014020000",
      "localidad_censal_id": "54014020",
      "localidad_censal_nombre": "Campo Grande",
      "municipio_id": "540042",
      "municipio_nombre": "Campo Grande",
      "nombre": "CAMPO GRANDE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -27.0205393332885,
      "centroide_lon": -54.6877327006159,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014030000",
      "localidad_censal_id": "54014030",
      "localidad_censal_nombre": "Dos de Mayo",
      "municipio_id": "540049",
      "municipio_nombre": "Dos de Mayo",
      "nombre": "DOS DE MAYO NUCLEO I",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.9808943053968,
      "centroide_lon": -54.7030774930753,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014030001",
      "localidad_censal_id": "54014030",
      "localidad_censal_nombre": "Dos de Mayo",
      "municipio_id": "540049",
      "municipio_nombre": "Dos de Mayo",
      "nombre": "NUCLEO I",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.0234502899983,
      "centroide_lon": -54.6788256442778,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014030002",
      "localidad_censal_id": "54014030",
      "localidad_censal_nombre": "Dos de Mayo",
      "municipio_id": "540049",
      "municipio_nombre": "Dos de Mayo",
      "nombre": "NUCLEO II",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0065768537216,
      "centroide_lon": -54.6123536244069,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014050000",
      "localidad_censal_id": "54014050",
      "localidad_censal_nombre": "Dos de Mayo Nucleo III (Bº Bernardino Rivadavia)",
      "municipio_id": "540049",
      "municipio_nombre": "Dos de Mayo",
      "nombre": "DOS DE MAYO NUCLEO III",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3105260940585,
      "centroide_lon": -54.9030555649231,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014055000",
      "localidad_censal_id": "54014055",
      "localidad_censal_nombre": "Kilómetro 17",
      "municipio_id": "540042",
      "municipio_nombre": "Campo Grande",
      "nombre": "KILOMETRO 17 (RUTA 8)",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1660848947707,
      "centroide_lon": -55.029165269622,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014060000",
      "localidad_censal_id": "54014060",
      "localidad_censal_nombre": "1º de Mayo",
      "municipio_id": "540042",
      "municipio_nombre": "Campo Grande",
      "nombre": "1 DE MAYO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1485153432864,
      "centroide_lon": -54.5624940052185,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014070000",
      "localidad_censal_id": "54014070",
      "localidad_censal_nombre": "Pueblo Illia",
      "municipio_id": "540049",
      "municipio_nombre": "Dos de Mayo",
      "nombre": "PUEBLO ILLIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0822797871022,
      "centroide_lon": -54.8334856092969,
      "departamento_id": "54014",
      "departamento_nombre": "Cainguás",
      "id": "54014080000",
      "localidad_censal_id": "54014080",
      "localidad_censal_nombre": "Salto Encantado",
      "municipio_id": "540035",
      "municipio_nombre": "Aristóbulo del Valle",
      "nombre": "SALTO ENCANTADO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4636033945712,
      "centroide_lon": -55.8005801049338,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021005000",
      "localidad_censal_id": "54021005",
      "localidad_censal_nombre": "Barrio del Lago",
      "municipio_id": "540063",
      "municipio_nombre": "Candelaria",
      "nombre": "BARRIO DEL LAGO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4820696887808,
      "centroide_lon": -55.4774200837194,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021010000",
      "localidad_censal_id": "54021010",
      "localidad_censal_nombre": "Bonpland",
      "municipio_id": "540056",
      "municipio_nombre": "Bonpland",
      "nombre": "BONPLAND",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4591653471738,
      "centroide_lon": -55.7430844145913,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021020000",
      "localidad_censal_id": "54021020",
      "localidad_censal_nombre": "Candelaria",
      "municipio_id": "540063",
      "municipio_nombre": "Candelaria",
      "nombre": "CANDELARIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.508609969523,
      "centroide_lon": -55.6038621436184,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021030000",
      "localidad_censal_id": "54021030",
      "localidad_censal_nombre": "Cerro Corá",
      "municipio_id": "540070",
      "municipio_nombre": "Cerro Corá",
      "nombre": "CERRO CORA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3296012279697,
      "centroide_lon": -55.5228090005038,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021040000",
      "localidad_censal_id": "54021040",
      "localidad_censal_nombre": "Loreto",
      "municipio_id": "540077",
      "municipio_nombre": "Loreto",
      "nombre": "LORETO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4188647592881,
      "centroide_lon": -55.3777936639947,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021050000",
      "localidad_censal_id": "54021050",
      "localidad_censal_nombre": "Mártires",
      "municipio_id": "540084",
      "municipio_nombre": "Mártires",
      "nombre": "MARTIRES",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5585198137316,
      "centroide_lon": -55.7034474067364,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021060000",
      "localidad_censal_id": "54021060",
      "localidad_censal_nombre": "Profundidad",
      "municipio_id": "540091",
      "municipio_nombre": "Profundidad",
      "nombre": "PROFUNDIDAD",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3330798818829,
      "centroide_lon": -55.5864347793367,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021070000",
      "localidad_censal_id": "54021070",
      "localidad_censal_nombre": "Puerto Santa Ana",
      "municipio_id": "540098",
      "municipio_nombre": "Santa Ana",
      "nombre": "PUERTO SANTA ANA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3671212837411,
      "centroide_lon": -55.5805568560225,
      "departamento_id": "54021",
      "departamento_nombre": "Candelaria",
      "id": "54021080000",
      "localidad_censal_id": "54021080",
      "localidad_censal_nombre": "Santa Ana",
      "municipio_id": "540098",
      "municipio_nombre": "Santa Ana",
      "nombre": "SANTA ANA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4321009059125,
      "centroide_lon": -55.826138545257,
      "departamento_id": "54028",
      "departamento_nombre": "Capital",
      "id": "54028005000",
      "localidad_censal_id": "54028005",
      "localidad_censal_nombre": "Barrio Nuevo Garupa",
      "municipio_id": "540112",
      "municipio_nombre": "Garupá",
      "nombre": "BARRIO NUEVO GARUPA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4788128514289,
      "centroide_lon": -55.8224411787639,
      "departamento_id": "54028",
      "departamento_nombre": "Capital",
      "id": "54028010000",
      "localidad_censal_id": "54028010",
      "localidad_censal_nombre": "Garupá",
      "municipio_id": "540112",
      "municipio_nombre": "Garupá",
      "nombre": "GARUPA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.367003658843,
      "centroide_lon": -55.9982208456294,
      "departamento_id": "54028",
      "departamento_nombre": "Capital",
      "id": "54028020000",
      "localidad_censal_id": "54028020",
      "localidad_censal_nombre": "Nemesio Parma",
      "municipio_id": "540119",
      "municipio_nombre": "Posadas",
      "nombre": "NEMESIO PARMA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.36621704276,
      "centroide_lon": -55.8940020980262,
      "departamento_id": "54028",
      "departamento_nombre": "Capital",
      "id": "54028030000",
      "localidad_censal_id": "54028030",
      "localidad_censal_nombre": "Posadas",
      "municipio_id": "540119",
      "municipio_nombre": "Posadas",
      "nombre": "POSADAS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1109522025734,
      "centroide_lon": -55.5820048143948,
      "departamento_id": "54035",
      "departamento_nombre": "Concepción",
      "id": "54035010000",
      "localidad_censal_id": "54035010",
      "localidad_censal_nombre": "Barra Concepción",
      "municipio_id": "540126",
      "municipio_nombre": "Concepción de la Sierra",
      "nombre": "BARRA CONCEPCION",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9813155596218,
      "centroide_lon": -55.5209343056683,
      "departamento_id": "54035",
      "departamento_nombre": "Concepción",
      "id": "54035020000",
      "localidad_censal_id": "54035020",
      "localidad_censal_nombre": "Concepción de la Sierra",
      "municipio_id": "540126",
      "municipio_nombre": "Concepción de la Sierra",
      "nombre": "CONCEPCION DE LA SIERRA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8884115577679,
      "centroide_lon": -55.3552996953,
      "departamento_id": "54035",
      "departamento_nombre": "Concepción",
      "id": "54035030000",
      "localidad_censal_id": "54035030",
      "localidad_censal_nombre": "La Corita",
      "municipio_id": "540133",
      "municipio_nombre": "Santa María",
      "nombre": "LA CORITA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9292852570213,
      "centroide_lon": -55.4120566457096,
      "departamento_id": "54035",
      "departamento_nombre": "Concepción",
      "id": "54035040000",
      "localidad_censal_id": "54035040",
      "localidad_censal_nombre": "Santa María",
      "municipio_id": "540133",
      "municipio_nombre": "Santa María",
      "nombre": "SANTA MARIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3298887730818,
      "centroide_lon": -54.6215572258235,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042010000",
      "localidad_censal_id": "54042010",
      "localidad_censal_nombre": "Colonia Victoria",
      "municipio_id": "540147",
      "municipio_nombre": "Colonia Victoria",
      "nombre": "COLONIA VICTORIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4086211746541,
      "centroide_lon": -54.6238428075538,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042020000",
      "localidad_censal_id": "54042020",
      "localidad_censal_nombre": "Eldorado",
      "municipio_id": "540154",
      "municipio_nombre": "Eldorado",
      "nombre": "ELDORADO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2380924409231,
      "centroide_lon": -54.6018096123882,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042030000",
      "localidad_censal_id": "54042030",
      "localidad_censal_nombre": "María Magdalena",
      "municipio_id": "540140",
      "municipio_nombre": "Colonia Delicia",
      "nombre": "MARIA MAGDALENA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1791395472552,
      "centroide_lon": -54.5836572818887,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042035000",
      "localidad_censal_id": "54042035",
      "localidad_censal_nombre": "Nueva Delicia",
      "municipio_id": "540140",
      "municipio_nombre": "Colonia Delicia",
      "nombre": "NUEVA DELICIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4302810162412,
      "centroide_lon": -54.4664666896921,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042040000",
      "localidad_censal_id": "54042040",
      "localidad_censal_nombre": "9 de Julio Kilómetro 28",
      "municipio_id": "540161",
      "municipio_nombre": "9 de Julio",
      "nombre": "9 DE JULIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4145333109522,
      "centroide_lon": -54.4976116231155,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042050000",
      "localidad_censal_id": "54042050",
      "localidad_censal_nombre": "9 de Julio Kilómetro 20",
      "municipio_id": "540161",
      "municipio_nombre": "9 de Julio",
      "nombre": "9 DE JULIO KILOMETRO 20",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2445413608016,
      "centroide_lon": -54.5904675610809,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042055000",
      "localidad_censal_id": "54042055",
      "localidad_censal_nombre": "Pueblo Nuevo",
      "municipio_id": "540140",
      "municipio_nombre": "Colonia Delicia",
      "nombre": "PUEBLO NUEVO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2310899121585,
      "centroide_lon": -54.6247146607981,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042060000",
      "localidad_censal_id": "54042060",
      "localidad_censal_nombre": "Puerto Mado",
      "municipio_id": "540140",
      "municipio_nombre": "Colonia Delicia",
      "nombre": "PUERTO MADO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4268174271826,
      "centroide_lon": -54.6857660084129,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042070000",
      "localidad_censal_id": "54042070",
      "localidad_censal_nombre": "Puerto Pinares",
      "municipio_id": "540154",
      "municipio_nombre": "Eldorado",
      "nombre": "PUERTO PINARES",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3905866143255,
      "centroide_lon": -54.3947099277232,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042080000",
      "localidad_censal_id": "54042080",
      "localidad_censal_nombre": "Santiago de Liniers",
      "municipio_id": "540168",
      "municipio_nombre": "Santiago de Liniers",
      "nombre": "SANTIAGO DE LINIERS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.382535288747,
      "centroide_lon": -54.4652848386631,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042090000",
      "localidad_censal_id": "54042090",
      "localidad_censal_nombre": "Valle Hermoso",
      "municipio_id": "540161",
      "municipio_nombre": "9 de Julio",
      "nombre": "VALLE HERMOSO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4422594958772,
      "centroide_lon": -54.6405734856487,
      "departamento_id": "54042",
      "departamento_nombre": "Eldorado",
      "id": "54042100000",
      "localidad_censal_id": "54042100",
      "localidad_censal_nombre": "Villa Roulet",
      "municipio_id": "540154",
      "municipio_nombre": "Eldorado",
      "nombre": "VILLA ROULET",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6674361424553,
      "centroide_lon": -54.0456434709472,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049010000",
      "localidad_censal_id": "54049010",
      "localidad_censal_nombre": "Comandante Andresito",
      "municipio_id": "540182",
      "municipio_nombre": "Comandante A. Guacurary",
      "nombre": "ALMIRANTE BROWN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2546761021204,
      "centroide_lon": -53.6472133435976,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049020000",
      "localidad_censal_id": "54049020",
      "localidad_censal_nombre": "Bernardo de Irigoyen",
      "municipio_id": "540175",
      "municipio_nombre": "Bernardo de Irigoyen",
      "nombre": "BERNARDO DE IRIGOYEN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6820367479588,
      "centroide_lon": -54.1426092989677,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049025000",
      "localidad_censal_id": "54049025",
      "localidad_censal_nombre": "Caburei",
      "municipio_id": "540182",
      "municipio_nombre": "Comandante A. Guacurary",
      "nombre": "CABUREI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2918653520361,
      "centroide_lon": -53.7575832277415,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049030000",
      "localidad_censal_id": "54049030",
      "localidad_censal_nombre": "Dos Hermanas",
      "municipio_id": "540175",
      "municipio_nombre": "Bernardo de Irigoyen",
      "nombre": "DOS HERMANAS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7724726219691,
      "centroide_lon": -53.8522974773477,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049040000",
      "localidad_censal_id": "54049040",
      "localidad_censal_nombre": "Integración",
      "municipio_id": "540182",
      "municipio_nombre": "Comandante A. Guacurary",
      "nombre": "INTEGRACION",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.926985370994,
      "centroide_lon": -53.9254399080528,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049043000",
      "localidad_censal_id": "54049043",
      "localidad_censal_nombre": "Piñalito Norte",
      "municipio_id": "540189",
      "municipio_nombre": "San Antonio",
      "nombre": "PIÑALITO NORTE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5880676406692,
      "centroide_lon": -54.0084915753095,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049045000",
      "localidad_censal_id": "54049045",
      "localidad_censal_nombre": "Puerto Andresito",
      "municipio_id": "540182",
      "municipio_nombre": "Comandante A. Guacurary",
      "nombre": "PUERTO ANDRESITO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7862399598743,
      "centroide_lon": -54.038267293845,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049047000",
      "localidad_censal_id": "54049047",
      "localidad_censal_nombre": "Puerto Deseado",
      "municipio_id": "540182",
      "municipio_nombre": "Comandante A. Guacurary",
      "nombre": "PUERTO DESEADO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0557838105078,
      "centroide_lon": -53.7339959536295,
      "departamento_id": "54049",
      "departamento_nombre": "General Manuel Belgrano",
      "id": "54049050000",
      "localidad_censal_id": "54049050",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "540189",
      "municipio_nombre": "San Antonio",
      "nombre": "SAN ANTONIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2908601020425,
      "centroide_lon": -54.2007777459323,
      "departamento_id": "54056",
      "departamento_nombre": "Guaraní",
      "id": "54056010000",
      "localidad_censal_id": "54056010",
      "localidad_censal_nombre": "El Soberbio",
      "municipio_id": "540196",
      "municipio_nombre": "El Soberbio",
      "nombre": "EL SOBERBIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.740151400562,
      "centroide_lon": -54.3000439575816,
      "departamento_id": "54056",
      "departamento_nombre": "Guaraní",
      "id": "54056020000",
      "localidad_censal_id": "54056020",
      "localidad_censal_nombre": "Fracrán",
      "municipio_id": "540203",
      "municipio_nombre": "San Vicente",
      "nombre": "FRACRAN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9953791755128,
      "centroide_lon": -54.4834965149084,
      "departamento_id": "54056",
      "departamento_nombre": "Guaraní",
      "id": "54056030000",
      "localidad_censal_id": "54056030",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "540203",
      "municipio_nombre": "San Vicente",
      "nombre": "SAN VICENTE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0232970897792,
      "centroide_lon": -54.6125092333468,
      "departamento_id": "54063",
      "departamento_nombre": "Iguazú",
      "id": "54063010000",
      "localidad_censal_id": "54063010",
      "localidad_censal_nombre": "Puerto Esperanza",
      "municipio_id": "540210",
      "municipio_nombre": "Puerto Esperanza",
      "nombre": "ESPERANZA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9216713372731,
      "centroide_lon": -54.5821209872029,
      "departamento_id": "54063",
      "departamento_nombre": "Iguazú",
      "id": "54063020000",
      "localidad_censal_id": "54063020",
      "localidad_censal_nombre": "Puerto Libertad",
      "municipio_id": "540224",
      "municipio_nombre": "Libertad",
      "nombre": "LIBERTAD",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6010431152667,
      "centroide_lon": -54.576736880982,
      "departamento_id": "54063",
      "departamento_nombre": "Iguazú",
      "id": "54063030000",
      "localidad_censal_id": "54063030",
      "localidad_censal_nombre": "Puerto Iguazú",
      "municipio_id": "540217",
      "municipio_nombre": "Iguazú",
      "nombre": "PUERTO IGUAZU",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.935986701389,
      "centroide_lon": -54.5384663539082,
      "departamento_id": "54063",
      "departamento_nombre": "Iguazú",
      "id": "54063035000",
      "localidad_censal_id": "54063035",
      "localidad_censal_nombre": "Villa Cooperativa",
      "municipio_id": "540224",
      "municipio_nombre": "Libertad",
      "nombre": "VILLA COOPERATIVA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9713257064178,
      "centroide_lon": -54.5610255460819,
      "departamento_id": "54063",
      "departamento_nombre": "Iguazú",
      "id": "54063040000",
      "localidad_censal_id": "54063040",
      "localidad_censal_nombre": "Colonia Wanda",
      "municipio_id": "540231",
      "municipio_nombre": "Colonia Wanda",
      "nombre": "WANDA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5062138362368,
      "centroide_lon": -55.4018892063726,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070010000",
      "localidad_censal_id": "54070010",
      "localidad_censal_nombre": "Almafuerte",
      "municipio_id": "540238",
      "municipio_nombre": "Almafuerte",
      "nombre": "ALMAFUERTE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.698002675484,
      "centroide_lon": -55.4064788856063,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070020000",
      "localidad_censal_id": "54070020",
      "localidad_censal_nombre": "Arroyo del Medio",
      "municipio_id": "540245",
      "municipio_nombre": "Arroyo del Medio",
      "nombre": "ARROYO DEL MEDIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4807844367608,
      "centroide_lon": -55.2992348104351,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070030000",
      "localidad_censal_id": "54070030",
      "localidad_censal_nombre": "Caá - Yarí",
      "municipio_id": "540252",
      "municipio_nombre": "Caa Yarí",
      "nombre": "CAA - YARI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6310347455951,
      "centroide_lon": -55.4938681670811,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070040000",
      "localidad_censal_id": "54070040",
      "localidad_censal_nombre": "Cerro Azul",
      "municipio_id": "540259",
      "municipio_nombre": "Cerro Azul",
      "nombre": "CERRO AZUL",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.694171423305,
      "centroide_lon": -55.2587801693628,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070050000",
      "localidad_censal_id": "54070050",
      "localidad_censal_nombre": "Dos Arroyos",
      "municipio_id": "540266",
      "municipio_nombre": "Dos Arroyos",
      "nombre": "DOS ARROYOS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6615245708492,
      "centroide_lon": -55.2124455595203,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070060000",
      "localidad_censal_id": "54070060",
      "localidad_censal_nombre": "Gobernador López",
      "municipio_id": "540273",
      "municipio_nombre": "Gobernador López",
      "nombre": "GOBERNADOR LOPEZ",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.601867604082,
      "centroide_lon": -55.3264659218849,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070070000",
      "localidad_censal_id": "54070070",
      "localidad_censal_nombre": "Leandro N. Alem",
      "municipio_id": "540280",
      "municipio_nombre": "Leandro N. Alem",
      "nombre": "LEANDRO N. ALEM",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5658220357689,
      "centroide_lon": -55.5017254277441,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070080000",
      "localidad_censal_id": "54070080",
      "localidad_censal_nombre": "Olegario V. Andrade",
      "municipio_id": "540287",
      "municipio_nombre": "Olegario V. Andrade",
      "nombre": "OLEGARIO V. ANDRADE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5583422242545,
      "centroide_lon": -55.3160862221368,
      "departamento_id": "54070",
      "departamento_nombre": "Leandro N. Alem",
      "id": "54070090000",
      "localidad_censal_id": "54070090",
      "localidad_censal_nombre": "Villa Libertad",
      "municipio_id": "540252",
      "municipio_nombre": "Caa Yarí",
      "nombre": "VILLA LIBERTAD",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9292033223206,
      "centroide_lon": -55.0569367980451,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077010000",
      "localidad_censal_id": "54077010",
      "localidad_censal_nombre": "Capioví",
      "municipio_id": "540294",
      "municipio_nombre": "Capioví",
      "nombre": "CAPIOVI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8795016704132,
      "centroide_lon": -55.0705133852003,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077015000",
      "localidad_censal_id": "54077015",
      "localidad_censal_nombre": "Capioviciño",
      "municipio_id": "540322",
      "municipio_nombre": "Puerto Rico",
      "nombre": "CAPIOVICIÑO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7102586382645,
      "centroide_lon": -54.8161444547822,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077020000",
      "localidad_censal_id": "54077020",
      "localidad_censal_nombre": "El Alcázar",
      "municipio_id": "540301",
      "municipio_nombre": "El Alcázar",
      "nombre": "EL ALCAZAR",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8186828823083,
      "centroide_lon": -54.9576120074303,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077030000",
      "localidad_censal_id": "54077030",
      "localidad_censal_nombre": "Garuhapé",
      "municipio_id": "540308",
      "municipio_nombre": "Garuhapé",
      "nombre": "GARUHAPE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8615012222942,
      "centroide_lon": -55.0463438652711,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077040000",
      "localidad_censal_id": "54077040",
      "localidad_censal_nombre": "Mbopicuá",
      "municipio_id": "540322",
      "municipio_nombre": "Puerto Rico",
      "nombre": "MBOPICUA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9840910613646,
      "centroide_lon": -55.1694842635982,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077050000",
      "localidad_censal_id": "54077050",
      "localidad_censal_nombre": "Puerto Leoni",
      "municipio_id": "540315",
      "municipio_nombre": "Leoni",
      "nombre": "PUERTO LEONI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8148524027618,
      "centroide_lon": -55.0240728847562,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077060000",
      "localidad_censal_id": "54077060",
      "localidad_censal_nombre": "Puerto Rico",
      "municipio_id": "540322",
      "municipio_nombre": "Puerto Rico",
      "nombre": "PUERTO RICO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9665882832067,
      "centroide_lon": -55.05758992095,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077070000",
      "localidad_censal_id": "54077070",
      "localidad_censal_nombre": "Ruiz de Montoya",
      "municipio_id": "540329",
      "municipio_nombre": "Ruiz de Montoya",
      "nombre": "RUIZ DE MONTOYA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8048950436231,
      "centroide_lon": -54.9881508224082,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077080000",
      "localidad_censal_id": "54077080",
      "localidad_censal_nombre": "San Alberto",
      "municipio_id": "540322",
      "municipio_nombre": "Puerto Rico",
      "nombre": "SAN ALBERTO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9225122221192,
      "centroide_lon": -55.1238743204073,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077090000",
      "localidad_censal_id": "54077090",
      "localidad_censal_nombre": "San Gotardo",
      "municipio_id": "540294",
      "municipio_nombre": "Capioví",
      "nombre": "SAN GOTARDO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8533367010148,
      "centroide_lon": -54.8892924101276,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077100000",
      "localidad_censal_id": "54077100",
      "localidad_censal_nombre": "San Miguel",
      "municipio_id": "540308",
      "municipio_nombre": "Garuhapé",
      "nombre": "SAN MIGUEL",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9412646689407,
      "centroide_lon": -55.0957711615406,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077110000",
      "localidad_censal_id": "54077110",
      "localidad_censal_nombre": "Villa Akerman",
      "municipio_id": "540294",
      "municipio_nombre": "Capioví",
      "nombre": "VILLA AKERMAN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8462676092826,
      "centroide_lon": -54.739753574325,
      "departamento_id": "54077",
      "departamento_nombre": "Libertador General San Martín",
      "id": "54077120000",
      "localidad_censal_id": "54077120",
      "localidad_censal_nombre": "Villa Urrutia",
      "municipio_id": "540301",
      "municipio_nombre": "El Alcázar",
      "nombre": "VILLA URRUTIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5482209436331,
      "centroide_lon": -54.6748836684444,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084003000",
      "localidad_censal_id": "54084003",
      "localidad_censal_nombre": "Barrio Cuatro Bocas",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "BARRIO CUATRO BOCAS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6027932981786,
      "centroide_lon": -54.6955807827058,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084005000",
      "localidad_censal_id": "54084005",
      "localidad_censal_nombre": "Barrio Guatambu",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "BARRIO GUATAMBU",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5283257581417,
      "centroide_lon": -54.7089179903484,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084007000",
      "localidad_censal_id": "54084007",
      "localidad_censal_nombre": "Bario Ita",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "BARIO ITA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6563488324986,
      "centroide_lon": -54.7392272649556,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084010000",
      "localidad_censal_id": "54084010",
      "localidad_censal_nombre": "Caraguatay",
      "municipio_id": "540336",
      "municipio_nombre": "Caraguatay",
      "nombre": "CARAGUATAY",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.532818826907,
      "centroide_lon": -54.6506736537237,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084020000",
      "localidad_censal_id": "54084020",
      "localidad_censal_nombre": "Laharrague",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "LAHARRAGUE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5661764915312,
      "centroide_lon": -54.7614267394945,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084030000",
      "localidad_censal_id": "54084030",
      "localidad_censal_nombre": "Montecarlo",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "MONTECARLO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5237915925702,
      "centroide_lon": -54.5916547620688,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084040000",
      "localidad_censal_id": "54084040",
      "localidad_censal_nombre": "Piray Kilómetro 18",
      "municipio_id": "540350",
      "municipio_nombre": "Puerto Piray",
      "nombre": "PIRAY KILOMETRO 18",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.467494823767,
      "centroide_lon": -54.7136760762691,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084050000",
      "localidad_censal_id": "54084050",
      "localidad_censal_nombre": "Puerto Piray",
      "municipio_id": "540350",
      "municipio_nombre": "Puerto Piray",
      "nombre": "PUERTO PIRAY",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7282097915611,
      "centroide_lon": -54.7278209571683,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084060000",
      "localidad_censal_id": "54084060",
      "localidad_censal_nombre": "Tarumá",
      "municipio_id": "540336",
      "municipio_nombre": "Caraguatay",
      "nombre": "TARUMA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4980184491189,
      "centroide_lon": -54.6804147225647,
      "departamento_id": "54084",
      "departamento_nombre": "Montecarlo",
      "id": "54084070000",
      "localidad_censal_id": "54084070",
      "localidad_censal_nombre": "Villa Parodi",
      "municipio_id": "540343",
      "municipio_nombre": "Montecarlo",
      "nombre": "VILLA PARODI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3569655848168,
      "centroide_lon": -55.2326585711531,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091010000",
      "localidad_censal_id": "54091010",
      "localidad_censal_nombre": "Colonia Alberdi",
      "municipio_id": "540371",
      "municipio_nombre": "Colonia Alberdi",
      "nombre": "ALBERDI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.616902888101,
      "centroide_lon": -55.0424750470795,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091013000",
      "localidad_censal_id": "54091013",
      "localidad_censal_nombre": "Barrio Escuela 461",
      "municipio_id": "540392",
      "municipio_nombre": "Los Helechos",
      "nombre": "BARRIO ESCUELA 461",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4689933468661,
      "centroide_lon": -55.0771065017179,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091017000",
      "localidad_censal_id": "54091017",
      "localidad_censal_nombre": "Barrio Escuela 633",
      "municipio_id": "540357",
      "municipio_nombre": "Campo Ramón",
      "nombre": "BARRIO ESCUELA 633",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4522700869441,
      "centroide_lon": -55.0237352385306,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091020000",
      "localidad_censal_id": "54091020",
      "localidad_censal_nombre": "Campo Ramón",
      "municipio_id": "540357",
      "municipio_nombre": "Campo Ramón",
      "nombre": "CAMPO RAMON",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3310558767329,
      "centroide_lon": -55.0528548158544,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091030000",
      "localidad_censal_id": "54091030",
      "localidad_censal_nombre": "Campo Viera",
      "municipio_id": "540364",
      "municipio_nombre": "Campo Viera",
      "nombre": "CAMPO VIERA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4917334406127,
      "centroide_lon": -55.1989608083666,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091040000",
      "localidad_censal_id": "54091040",
      "localidad_censal_nombre": "El Salto",
      "municipio_id": "540399",
      "municipio_nombre": "Oberá",
      "nombre": "EL SALTO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4228667193793,
      "centroide_lon": -55.1702709710527,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091050000",
      "localidad_censal_id": "54091050",
      "localidad_censal_nombre": "General Alvear",
      "municipio_id": "540378",
      "municipio_nombre": "Gral. Alvear",
      "nombre": "GENERAL ALVEAR",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5203317456103,
      "centroide_lon": -55.161363476829,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091060000",
      "localidad_censal_id": "54091060",
      "localidad_censal_nombre": "Guaraní",
      "municipio_id": "540385",
      "municipio_nombre": "Guaraní",
      "nombre": "GUARANI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5531853202103,
      "centroide_lon": -55.0778227687919,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091070000",
      "localidad_censal_id": "54091070",
      "localidad_censal_nombre": "Los Helechos",
      "municipio_id": "540392",
      "municipio_nombre": "Los Helechos",
      "nombre": "LOS HELECHOS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.4816559378759,
      "centroide_lon": -55.1251546931471,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091080000",
      "localidad_censal_id": "54091080",
      "localidad_censal_nombre": "Oberá",
      "municipio_id": "540399",
      "municipio_nombre": "Oberá",
      "nombre": "OBERA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7185151219435,
      "centroide_lon": -54.9183708210206,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091090000",
      "localidad_censal_id": "54091090",
      "localidad_censal_nombre": "Panambí",
      "municipio_id": "540406",
      "municipio_nombre": "Panambí",
      "nombre": "PANAMBI",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6631606292713,
      "centroide_lon": -54.9846517202885,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091100000",
      "localidad_censal_id": "54091100",
      "localidad_censal_nombre": "Panambí Kilómetro 8",
      "municipio_id": "540406",
      "municipio_nombre": "Panambí",
      "nombre": "PANAMBI KILOMETRO 8",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7043305063396,
      "centroide_lon": -54.9664319816326,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091105000",
      "localidad_censal_id": "54091105",
      "localidad_censal_nombre": "Panambi Kilómetro 15",
      "municipio_id": "540406",
      "municipio_nombre": "Panambí",
      "nombre": "PANAMBI KILOMETRO 15",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4618128955547,
      "centroide_lon": -55.2783440740601,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091110000",
      "localidad_censal_id": "54091110",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": "540413",
      "municipio_nombre": "San Martín",
      "nombre": "SAN MARTIN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.479699295829,
      "centroide_lon": -54.9639234038824,
      "departamento_id": "54091",
      "departamento_nombre": "Oberá",
      "id": "54091120000",
      "localidad_censal_id": "54091120",
      "localidad_censal_nombre": "Villa Bonita",
      "municipio_id": "540357",
      "municipio_nombre": "Campo Ramón",
      "nombre": "VILLA BONITA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1077568549388,
      "centroide_lon": -55.3881151371992,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098005000",
      "localidad_censal_id": "54098005",
      "localidad_censal_nombre": "Barrio Tungoil",
      "municipio_id": "540469",
      "municipio_nombre": "Santo Pipo",
      "nombre": "BARRIO TUNGOIL",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9810475025624,
      "centroide_lon": -55.3170685541177,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098010000",
      "localidad_censal_id": "54098010",
      "localidad_censal_nombre": "Colonia Polana",
      "municipio_id": "540420",
      "municipio_nombre": "Colonia Polana",
      "nombre": "COLONIA POLANA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1278038790718,
      "centroide_lon": -55.5094179289212,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098020000",
      "localidad_censal_id": "54098020",
      "localidad_censal_nombre": "Corpus",
      "municipio_id": "540427",
      "municipio_nombre": "Corpus",
      "nombre": "CORPUS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3546875776242,
      "centroide_lon": -55.3368344966029,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098030000",
      "localidad_censal_id": "54098030",
      "localidad_censal_nombre": "Domingo Savio",
      "municipio_id": "540462",
      "municipio_nombre": "San Ignacio",
      "nombre": "DOMINGO SAVIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1077317789884,
      "centroide_lon": -55.3737660823345,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098040000",
      "localidad_censal_id": "54098040",
      "localidad_censal_nombre": "General Urquiza",
      "municipio_id": "540434",
      "municipio_nombre": "General J. J. Urquiza",
      "nombre": "GENERAL URQUIZA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1910170928285,
      "centroide_lon": -55.4682802860195,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098050000",
      "localidad_censal_id": "54098050",
      "localidad_censal_nombre": "Gobernador Roca",
      "municipio_id": "540441",
      "municipio_nombre": "Gobernador Roca",
      "nombre": "GOBERNADOR ROCA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.110525539393,
      "centroide_lon": -55.3438282986148,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098060000",
      "localidad_censal_id": "54098060",
      "localidad_censal_nombre": "Helvecia",
      "municipio_id": "540448",
      "municipio_nombre": "Hipólito Irigoyen",
      "nombre": "HELVECIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0900613258757,
      "centroide_lon": -55.2870716950334,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098070000",
      "localidad_censal_id": "54098070",
      "localidad_censal_nombre": "Hipólito Yrigoyen",
      "municipio_id": "540448",
      "municipio_nombre": "Hipólito Irigoyen",
      "nombre": "HIPOLITO YRIGOYEN",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0410707861025,
      "centroide_lon": -55.2320858824009,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098080000",
      "localidad_censal_id": "54098080",
      "localidad_censal_nombre": "Jardín América",
      "municipio_id": "540455",
      "municipio_nombre": "Jardín América",
      "nombre": "JARDIN AMERICA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9708359693882,
      "centroide_lon": -55.2491782114283,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098090000",
      "localidad_censal_id": "54098090",
      "localidad_censal_nombre": "Oasis",
      "municipio_id": "540455",
      "municipio_nombre": "Jardín América",
      "nombre": "OASIS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2143687134287,
      "centroide_lon": -55.4202789802254,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098100000",
      "localidad_censal_id": "54098100",
      "localidad_censal_nombre": "Roca Chica",
      "municipio_id": "540441",
      "municipio_nombre": "Gobernador Roca",
      "nombre": "ROCA CHICA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2573496537482,
      "centroide_lon": -55.5397360869442,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098110000",
      "localidad_censal_id": "54098110",
      "localidad_censal_nombre": "San Ignacio",
      "municipio_id": "540462",
      "municipio_nombre": "San Ignacio",
      "nombre": "SAN IGNACIO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1418557045843,
      "centroide_lon": -55.4074695445505,
      "departamento_id": "54098",
      "departamento_nombre": "San Ignacio",
      "id": "54098120000",
      "localidad_censal_id": "54098120",
      "localidad_censal_nombre": "Santo Pipó",
      "municipio_id": "540469",
      "municipio_nombre": "Santo Pipo",
      "nombre": "SANTO PIPO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6349575540286,
      "centroide_lon": -55.0877551421696,
      "departamento_id": "54105",
      "departamento_nombre": "San Javier",
      "id": "54105010000",
      "localidad_censal_id": "54105010",
      "localidad_censal_nombre": "Florentino Ameghino",
      "municipio_id": "540476",
      "municipio_nombre": "Ameghino",
      "nombre": "FLORENTINO AMEGHINO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8655824463799,
      "centroide_lon": -55.2638525290793,
      "departamento_id": "54105",
      "departamento_nombre": "San Javier",
      "id": "54105020000",
      "localidad_censal_id": "54105020",
      "localidad_censal_nombre": "Itacaruaré",
      "municipio_id": "540483",
      "municipio_nombre": "Itacaruaré",
      "nombre": "ITACARUARE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7077678291474,
      "centroide_lon": -55.1582021663854,
      "departamento_id": "54105",
      "departamento_nombre": "San Javier",
      "id": "54105030000",
      "localidad_censal_id": "54105030",
      "localidad_censal_nombre": "Mojón Grande",
      "municipio_id": "540490",
      "municipio_nombre": "Mojón Grande",
      "nombre": "MOJON GRANDE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8653231566702,
      "centroide_lon": -55.1348376530446,
      "departamento_id": "54105",
      "departamento_nombre": "San Javier",
      "id": "54105040000",
      "localidad_censal_id": "54105040",
      "localidad_censal_nombre": "San Javier",
      "municipio_id": "540497",
      "municipio_nombre": "San Javier",
      "nombre": "SAN JAVIER",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5379470287742,
      "centroide_lon": -53.9437324956095,
      "departamento_id": "54112",
      "departamento_nombre": "San Pedro",
      "id": "54112010000",
      "localidad_censal_id": "54112010",
      "localidad_censal_nombre": "Cruce Caballero",
      "municipio_id": "540504",
      "municipio_nombre": "San Pedro",
      "nombre": "CRUCE CABALLERO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6835663891035,
      "centroide_lon": -54.2050107484876,
      "departamento_id": "54112",
      "departamento_nombre": "San Pedro",
      "id": "54112020000",
      "localidad_censal_id": "54112020",
      "localidad_censal_nombre": "Paraíso",
      "municipio_id": "540504",
      "municipio_nombre": "San Pedro",
      "nombre": "PARAISO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4160781340411,
      "centroide_lon": -53.8369134466205,
      "departamento_id": "54112",
      "departamento_nombre": "San Pedro",
      "id": "54112030000",
      "localidad_censal_id": "54112030",
      "localidad_censal_nombre": "Piñalito Sur",
      "municipio_id": "540504",
      "municipio_nombre": "San Pedro",
      "nombre": "PIÑALITO SUR",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6197903071037,
      "centroide_lon": -54.1146818942839,
      "departamento_id": "54112",
      "departamento_nombre": "San Pedro",
      "id": "54112040000",
      "localidad_censal_id": "54112040",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "540504",
      "municipio_nombre": "San Pedro",
      "nombre": "SAN PEDRO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4664417575785,
      "centroide_lon": -53.8919888142393,
      "departamento_id": "54112",
      "departamento_nombre": "San Pedro",
      "id": "54112050000",
      "localidad_censal_id": "54112050",
      "localidad_censal_nombre": "Tobuna",
      "municipio_id": "540504",
      "municipio_nombre": "San Pedro",
      "nombre": "TOBUNA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5641504979908,
      "centroide_lon": -54.687011236345,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119010000",
      "localidad_censal_id": "54119010",
      "localidad_censal_nombre": "Alba Posse",
      "municipio_id": "540511",
      "municipio_nombre": "Alba Posse",
      "nombre": "ALBA POSSE",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3792090956035,
      "centroide_lon": -54.3672999680752,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119020000",
      "localidad_censal_id": "54119020",
      "localidad_censal_nombre": "Alicia Alta",
      "municipio_id": "540518",
      "municipio_nombre": "Colonia Aurora",
      "nombre": "COLONIA ALICIA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4285421255893,
      "centroide_lon": -54.3575081480921,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119025000",
      "localidad_censal_id": "54119025",
      "localidad_censal_nombre": "Alicia Baja",
      "municipio_id": "540518",
      "municipio_nombre": "Colonia Aurora",
      "nombre": "ALICIA BAJA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.479557957166,
      "centroide_lon": -54.5154356645941,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119030000",
      "localidad_censal_id": "54119030",
      "localidad_censal_nombre": "Colonia Aurora",
      "municipio_id": "540518",
      "municipio_nombre": "Colonia Aurora",
      "nombre": "COLONIA AURORA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4597786529494,
      "centroide_lon": -54.7478069154214,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119040000",
      "localidad_censal_id": "54119040",
      "localidad_censal_nombre": "San Francisco de Asís",
      "municipio_id": "540511",
      "municipio_nombre": "Alba Posse",
      "nombre": "SAN FRANCISCO DE ASIS",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5183529379611,
      "centroide_lon": -54.7305712624723,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119050000",
      "localidad_censal_id": "54119050",
      "localidad_censal_nombre": "Santa Rita",
      "municipio_id": "540511",
      "municipio_nombre": "Alba Posse",
      "nombre": "SANTA RITA",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3715719938072,
      "centroide_lon": -54.7475224881518,
      "departamento_id": "54119",
      "departamento_nombre": "25 de Mayo",
      "id": "54119060000",
      "localidad_censal_id": "54119060",
      "localidad_censal_nombre": "25 de Mayo",
      "municipio_id": "540525",
      "municipio_nombre": "25 de Mayo",
      "nombre": "25 DE MAYO",
      "provincia_id": "54",
      "provincia_nombre": "Misiones"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2395006622098,
      "centroide_lon": -70.9180131491474,
      "departamento_id": "58007",
      "departamento_nombre": "Aluminé",
      "id": "58007010000",
      "localidad_censal_id": "58007010",
      "localidad_censal_nombre": "Aluminé",
      "municipio_id": "580007",
      "municipio_nombre": "Aluminé",
      "nombre": "ALUMINE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9431841513779,
      "centroide_lon": -71.3282934446594,
      "departamento_id": "58007",
      "departamento_nombre": "Aluminé",
      "id": "58007015000",
      "localidad_censal_id": "58007015",
      "localidad_censal_nombre": "Moquehue",
      "municipio_id": "580252",
      "municipio_nombre": "Villa Pehuenia",
      "nombre": "MOQUEHUE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.88385673767,
      "centroide_lon": -71.1721341418285,
      "departamento_id": "58007",
      "departamento_nombre": "Aluminé",
      "id": "58007020000",
      "localidad_censal_id": "58007020",
      "localidad_censal_nombre": "Villa Pehuenia",
      "municipio_id": "580252",
      "municipio_nombre": "Villa Pehuenia",
      "nombre": "VILLA PEHUENIA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.9994791767967,
      "centroide_lon": -68.9231103975687,
      "departamento_id": "58014",
      "departamento_nombre": "Añelo",
      "id": "58014005000",
      "localidad_censal_id": "58014005",
      "localidad_censal_nombre": "Aguada San Roque",
      "municipio_id": "585014",
      "municipio_nombre": "Aguada San Roque",
      "nombre": "AGUADA SAN ROQUE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3514440221419,
      "centroide_lon": -68.7919715586172,
      "departamento_id": "58014",
      "departamento_nombre": "Añelo",
      "id": "58014010000",
      "localidad_censal_id": "58014010",
      "localidad_censal_nombre": "Añelo",
      "municipio_id": "580014",
      "municipio_nombre": "Añelo",
      "nombre": "AÑELO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6257824069657,
      "centroide_lon": -68.2986456816821,
      "departamento_id": "58014",
      "departamento_nombre": "Añelo",
      "id": "58014020000",
      "localidad_censal_id": "58014020",
      "localidad_censal_nombre": "San Patricio del Chañar",
      "municipio_id": "580021",
      "municipio_nombre": "San Patricio del Chañar",
      "nombre": "SAN PATRICIO DEL CHAÑAR",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.5578638822685,
      "centroide_lon": -70.5932894653763,
      "departamento_id": "58021",
      "departamento_nombre": "Catán Lil",
      "id": "58021010000",
      "localidad_censal_id": "58021010",
      "localidad_censal_nombre": "Las Coloradas",
      "municipio_id": "580028",
      "municipio_nombre": "Las Coloradas",
      "nombre": "LAS COLORADAS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.0465538638606,
      "centroide_lon": -70.077319578901,
      "departamento_id": "58028",
      "departamento_nombre": "Collón Curá",
      "id": "58028010000",
      "localidad_censal_id": "58028010",
      "localidad_censal_nombre": "Piedra del Águila",
      "municipio_id": "580035",
      "municipio_nombre": "Piedra del Águila",
      "nombre": "PIEDRA DEL AGUILA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.8218007278609,
      "centroide_lon": -70.1028884849476,
      "departamento_id": "58028",
      "departamento_nombre": "Collón Curá",
      "id": "58028020000",
      "localidad_censal_id": "58028020",
      "localidad_censal_nombre": "Santo Tomás",
      "municipio_id": "585035",
      "municipio_nombre": "Santo Tomás",
      "nombre": "SANTO TOMAS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0743997961308,
      "centroide_lon": -68.5703350642529,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035010000",
      "localidad_censal_id": "58035010",
      "localidad_censal_nombre": "Arroyito",
      "municipio_id": "580077",
      "municipio_nombre": "Senillosa",
      "nombre": "ARROYITO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7543907123239,
      "centroide_lon": -68.1803399743279,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035020000",
      "localidad_censal_id": "58035020",
      "localidad_censal_nombre": "Barrio Ruca Luhé",
      "municipio_id": "580091",
      "municipio_nombre": "Vista Alegre",
      "nombre": "BARRIO RUCA LUHE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8275777081112,
      "centroide_lon": -68.1532191639297,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035030000",
      "localidad_censal_id": "58035030",
      "localidad_censal_nombre": "Centenario",
      "municipio_id": "580042",
      "municipio_nombre": "Centenario",
      "nombre": "CENTENARIO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9366016135518,
      "centroide_lon": -69.2413390332884,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035040000",
      "localidad_censal_id": "58035040",
      "localidad_censal_nombre": "Cutral Có",
      "municipio_id": "580049",
      "municipio_nombre": "Cutral Có",
      "nombre": "CUTRAL CO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2589786425295,
      "centroide_lon": -68.8268723660918,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035050000",
      "localidad_censal_id": "58035050",
      "localidad_censal_nombre": "El Chocón",
      "municipio_id": "580084",
      "municipio_nombre": "Villa el Chocón",
      "nombre": "EL CHOCON",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5383043330689,
      "centroide_lon": -68.557547832041,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035060000",
      "localidad_censal_id": "58035060",
      "localidad_censal_nombre": "Mari Menuco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MARI MENUCO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9492856796033,
      "centroide_lon": -68.0839057621977,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035070000",
      "localidad_censal_id": "58035070",
      "localidad_censal_nombre": "Neuquén",
      "municipio_id": "580056",
      "municipio_nombre": "Neuquén",
      "nombre": "NEUQUEN",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.878684024658,
      "centroide_lon": -68.1001707201744,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035080000",
      "localidad_censal_id": "58035080",
      "localidad_censal_nombre": "11 de Octubre",
      "municipio_id": "580042",
      "municipio_nombre": "Centenario",
      "nombre": "11 DE OCTUBRE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9290709532125,
      "centroide_lon": -69.2021594435231,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035090000",
      "localidad_censal_id": "58035090",
      "localidad_censal_nombre": "Plaza Huincul",
      "municipio_id": "580063",
      "municipio_nombre": "Plaza Huincul",
      "nombre": "PLAZA HUINCUL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9510561611213,
      "centroide_lon": -68.2478403865049,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035100000",
      "localidad_censal_id": "58035100",
      "localidad_censal_nombre": "Plottier",
      "municipio_id": "580070",
      "municipio_nombre": "Plottier",
      "nombre": "PLOTTIER",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0113034801537,
      "centroide_lon": -68.4333833911279,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035110000",
      "localidad_censal_id": "58035110",
      "localidad_censal_nombre": "Senillosa",
      "municipio_id": "580077",
      "municipio_nombre": "Senillosa",
      "nombre": "SENILLOSA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2610101785628,
      "centroide_lon": -68.7842564223565,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035120000",
      "localidad_censal_id": "58035120",
      "localidad_censal_nombre": "Villa El Chocón",
      "municipio_id": "580084",
      "municipio_nombre": "Villa el Chocón",
      "nombre": "VILLA EL CHOCON",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7277123127679,
      "centroide_lon": -68.1721438862367,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035130000",
      "localidad_censal_id": "58035130",
      "localidad_censal_nombre": "Vista Alegre Norte",
      "municipio_id": "580091",
      "municipio_nombre": "Vista Alegre",
      "nombre": "VISTA ALEGRE NORTE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7715537254998,
      "centroide_lon": -68.1369187198489,
      "departamento_id": "58035",
      "departamento_nombre": "Confluencia",
      "id": "58035140000",
      "localidad_censal_id": "58035140",
      "localidad_censal_nombre": "Vista Alegre Sur",
      "municipio_id": "580091",
      "municipio_nombre": "Vista Alegre",
      "nombre": "VISTA ALEGRE SUR",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3792566593514,
      "centroide_lon": -70.2723772223192,
      "departamento_id": "58042",
      "departamento_nombre": "Chos Malal",
      "id": "58042010000",
      "localidad_censal_id": "58042010",
      "localidad_censal_nombre": "Chos Malal",
      "municipio_id": "580098",
      "municipio_nombre": "Chos Malal",
      "nombre": "CHOS MALAL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0428490399834,
      "centroide_lon": -70.3347175778926,
      "departamento_id": "58042",
      "departamento_nombre": "Chos Malal",
      "id": "58042020000",
      "localidad_censal_id": "58042020",
      "localidad_censal_nombre": "Tricao Malal",
      "municipio_id": "580105",
      "municipio_nombre": "Tricao Malal",
      "nombre": "TRICAO MALAL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1337365202592,
      "centroide_lon": -70.3969231250344,
      "departamento_id": "58042",
      "departamento_nombre": "Chos Malal",
      "id": "58042030000",
      "localidad_censal_id": "58042030",
      "localidad_censal_nombre": "Villa del Curi Leuvú",
      "municipio_id": "585056",
      "municipio_nombre": "Villa Curi Leuvú",
      "nombre": "VILLA DEL CURI LEUVU",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.9494720138355,
      "centroide_lon": -71.0703335609136,
      "departamento_id": "58049",
      "departamento_nombre": "Huiliches",
      "id": "58049010000",
      "localidad_censal_id": "58049010",
      "localidad_censal_nombre": "Junín de los Andes",
      "municipio_id": "580112",
      "municipio_nombre": "Junín de los Andes",
      "nombre": "JUNIN DE LOS ANDES",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.1537600653137,
      "centroide_lon": -71.3550098619322,
      "departamento_id": "58056",
      "departamento_nombre": "Lácar",
      "id": "58056010000",
      "localidad_censal_id": "58056010",
      "localidad_censal_nombre": "San Martín de los Andes",
      "municipio_id": "580119",
      "municipio_nombre": "San Martín de los Andes",
      "nombre": "SAN MARTIN DE LOS ANDES",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.3816875508143,
      "centroide_lon": -71.2616152793248,
      "departamento_id": "58056",
      "departamento_nombre": "Lácar",
      "id": "58056020000",
      "localidad_censal_id": "58056020",
      "localidad_censal_nombre": "Villa Lago Meliquina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAGO MELIQUINA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.929624048638,
      "centroide_lon": -70.0562158162232,
      "departamento_id": "58063",
      "departamento_nombre": "Loncopué",
      "id": "58063010000",
      "localidad_censal_id": "58063010",
      "localidad_censal_nombre": "Chorriaca",
      "municipio_id": "585063",
      "municipio_nombre": "Chorriaca",
      "nombre": "CHORRIACA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.0699432722928,
      "centroide_lon": -70.6124039046889,
      "departamento_id": "58063",
      "departamento_nombre": "Loncopué",
      "id": "58063020000",
      "localidad_censal_id": "58063020",
      "localidad_censal_nombre": "Loncopué",
      "municipio_id": "580126",
      "municipio_nombre": "Loncopué",
      "nombre": "LONCOPUE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.7631760692681,
      "centroide_lon": -71.6451967512926,
      "departamento_id": "58070",
      "departamento_nombre": "Los Lagos",
      "id": "58070010000",
      "localidad_censal_id": "58070010",
      "localidad_censal_nombre": "Villa La Angostura",
      "municipio_id": "580133",
      "municipio_nombre": "Villa la Angostura",
      "nombre": "VILLA LA ANGOSTURA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.6515336234792,
      "centroide_lon": -71.4069733427812,
      "departamento_id": "58070",
      "departamento_nombre": "Los Lagos",
      "id": "58070020000",
      "localidad_censal_id": "58070020",
      "localidad_censal_nombre": "Villa Traful",
      "municipio_id": "585070",
      "municipio_nombre": "Villa Traful",
      "nombre": "VILLA TRAFUL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1814100578481,
      "centroide_lon": -70.6690765415839,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077010000",
      "localidad_censal_id": "58077010",
      "localidad_censal_nombre": "Andacollo",
      "municipio_id": "580140",
      "municipio_nombre": "Andacollo",
      "nombre": "ANDACOLLO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.162253742004,
      "centroide_lon": -70.6240350766311,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077020000",
      "localidad_censal_id": "58077020",
      "localidad_censal_nombre": "Huinganco",
      "municipio_id": "580147",
      "municipio_nombre": "Huinganco",
      "nombre": "HUINGANCO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.9881343331798,
      "centroide_lon": -70.7487001311443,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077030000",
      "localidad_censal_id": "58077030",
      "localidad_censal_nombre": "Las Ovejas",
      "municipio_id": "580154",
      "municipio_nombre": "Las Ovejas",
      "nombre": "LAS OVEJAS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.2079272879054,
      "centroide_lon": -70.8208608595818,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077040000",
      "localidad_censal_id": "58077040",
      "localidad_censal_nombre": "Los Miches",
      "municipio_id": "580161",
      "municipio_nombre": "Los Miches",
      "nombre": "LOS MICHES",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.7475538529054,
      "centroide_lon": -70.7652790008941,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077050000",
      "localidad_censal_id": "58077050",
      "localidad_censal_nombre": "Manzano Amargo",
      "municipio_id": "585084",
      "municipio_nombre": "Manzano Amargo",
      "nombre": "MANZANO AMARGO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8575631715293,
      "centroide_lon": -70.6784177984494,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077060000",
      "localidad_censal_id": "58077060",
      "localidad_censal_nombre": "Varvarco",
      "municipio_id": "585091",
      "municipio_nombre": "Varvarco - Invernada Vieja",
      "nombre": "VARVARCO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.1209838887878,
      "centroide_lon": -70.7687465935033,
      "departamento_id": "58077",
      "departamento_nombre": "Minas",
      "id": "58077070000",
      "localidad_censal_id": "58077070",
      "localidad_censal_nombre": "Villa del Nahueve",
      "municipio_id": "585098",
      "municipio_nombre": "Villa del Nahueve",
      "nombre": "VILLA DEL NAHUEVE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8741211534774,
      "centroide_lon": -71.0537792468958,
      "departamento_id": "58084",
      "departamento_nombre": "Ñorquín",
      "id": "58084010000",
      "localidad_censal_id": "58084010",
      "localidad_censal_nombre": "Caviahue",
      "municipio_id": "580168",
      "municipio_nombre": "Caviahue-Copahue",
      "nombre": "CAVIAHUE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8191699254756,
      "centroide_lon": -71.0991107043764,
      "departamento_id": "58084",
      "departamento_nombre": "Ñorquín",
      "id": "58084020000",
      "localidad_censal_id": "58084020",
      "localidad_censal_nombre": "Copahue",
      "municipio_id": "580168",
      "municipio_nombre": "Caviahue-Copahue",
      "nombre": "COPAHUE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.4408647694624,
      "centroide_lon": -70.6441818646861,
      "departamento_id": "58084",
      "departamento_nombre": "Ñorquín",
      "id": "58084030000",
      "localidad_censal_id": "58084030",
      "localidad_censal_nombre": "El Cholar",
      "municipio_id": "580175",
      "municipio_nombre": "El Cholar",
      "nombre": "EL CHOLAR",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6415832578483,
      "centroide_lon": -70.5790877358744,
      "departamento_id": "58084",
      "departamento_nombre": "Ñorquín",
      "id": "58084040000",
      "localidad_censal_id": "58084040",
      "localidad_censal_nombre": "El Huecú",
      "municipio_id": "580182",
      "municipio_nombre": "El Huecú",
      "nombre": "EL HUECU",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5169103878888,
      "centroide_lon": -70.2503863712688,
      "departamento_id": "58084",
      "departamento_nombre": "Ñorquín",
      "id": "58084050000",
      "localidad_censal_id": "58084050",
      "localidad_censal_nombre": "Taquimilán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TAQUIMILAN",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -36.8247372962673,
      "centroide_lon": -69.9164782914754,
      "departamento_id": "58091",
      "departamento_nombre": "Pehuenches",
      "id": "58091010000",
      "localidad_censal_id": "58091010",
      "localidad_censal_nombre": "Barrancas",
      "municipio_id": "580196",
      "municipio_nombre": "Barrancas",
      "nombre": "BARRANCAS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.0507876062807,
      "centroide_lon": -69.873851086363,
      "departamento_id": "58091",
      "departamento_nombre": "Pehuenches",
      "id": "58091020000",
      "localidad_censal_id": "58091020",
      "localidad_censal_nombre": "Buta Ranquil",
      "municipio_id": "580203",
      "municipio_nombre": "Buta Ranquil",
      "nombre": "BUTA RANQUIL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.5865104880568,
      "centroide_lon": -68.2686075936205,
      "departamento_id": "58091",
      "departamento_nombre": "Pehuenches",
      "id": "58091030000",
      "localidad_censal_id": "58091030",
      "localidad_censal_nombre": "Octavio Pico",
      "municipio_id": "585105",
      "municipio_nombre": "Octavio Pico",
      "nombre": "OCTAVIO PICO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.3899075901743,
      "centroide_lon": -68.9309943279283,
      "departamento_id": "58091",
      "departamento_nombre": "Pehuenches",
      "id": "58091040000",
      "localidad_censal_id": "58091040",
      "localidad_censal_nombre": "Rincón de los Sauces",
      "municipio_id": "580210",
      "municipio_nombre": "Rincón de los Sauces",
      "nombre": "RINCON DE LOS SAUCES",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.4750725824587,
      "centroide_lon": -69.5298228549207,
      "departamento_id": "58098",
      "departamento_nombre": "Picún Leufú",
      "id": "58098005000",
      "localidad_censal_id": "58098005",
      "localidad_censal_nombre": "El Sauce",
      "municipio_id": "585112",
      "municipio_nombre": "El Sauce",
      "nombre": "EL SAUCE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.3393978445221,
      "centroide_lon": -69.8442147950698,
      "departamento_id": "58098",
      "departamento_nombre": "Picún Leufú",
      "id": "58098010000",
      "localidad_censal_id": "58098010",
      "localidad_censal_nombre": "Paso Aguerre",
      "municipio_id": "585119",
      "municipio_nombre": "Paso Aguerre",
      "nombre": "PASO AGUERRE",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.5191361709356,
      "centroide_lon": -69.2962673114558,
      "departamento_id": "58098",
      "departamento_nombre": "Picún Leufú",
      "id": "58098020000",
      "localidad_censal_id": "58098020",
      "localidad_censal_nombre": "Picún Leufú",
      "municipio_id": "580217",
      "municipio_nombre": "Picún Leufú",
      "nombre": "PICUN LEUFU",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.4079798578262,
      "centroide_lon": -70.0276785984599,
      "departamento_id": "58105",
      "departamento_nombre": "Picunches",
      "id": "58105010000",
      "localidad_censal_id": "58105010",
      "localidad_censal_nombre": "Bajada del Agrio",
      "municipio_id": "580224",
      "municipio_nombre": "Bajada del Agrio",
      "nombre": "BAJADA DEL AGRIO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5574702395959,
      "centroide_lon": -70.3665560313021,
      "departamento_id": "58105",
      "departamento_nombre": "Picunches",
      "id": "58105020000",
      "localidad_censal_id": "58105020",
      "localidad_censal_nombre": "La Buitrera",
      "municipio_id": "580231",
      "municipio_nombre": "Las Lajas",
      "nombre": "LA BUITRERA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.5292612011937,
      "centroide_lon": -70.3689502898328,
      "departamento_id": "58105",
      "departamento_nombre": "Picunches",
      "id": "58105030000",
      "localidad_censal_id": "58105030",
      "localidad_censal_nombre": "Las Lajas",
      "municipio_id": "580231",
      "municipio_nombre": "Las Lajas",
      "nombre": "LAS LAJAS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.3213178634179,
      "centroide_lon": -69.8143687558623,
      "departamento_id": "58105",
      "departamento_nombre": "Picunches",
      "id": "58105040000",
      "localidad_censal_id": "58105040",
      "localidad_censal_nombre": "Quili Malal",
      "municipio_id": "585126",
      "municipio_nombre": "Quili Malal",
      "nombre": "QUILI MALAL",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8384807985925,
      "centroide_lon": -70.1962106815081,
      "departamento_id": "58112",
      "departamento_nombre": "Zapala",
      "id": "58112010000",
      "localidad_censal_id": "58112010",
      "localidad_censal_nombre": "Los Catutos",
      "municipio_id": "585140",
      "municipio_nombre": "Los Catutos",
      "nombre": "LOS CATUTOS",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7532470280083,
      "centroide_lon": -70.0275921805241,
      "departamento_id": "58112",
      "departamento_nombre": "Zapala",
      "id": "58112020001",
      "localidad_censal_id": "58112020",
      "localidad_censal_nombre": "Mariano Moreno",
      "municipio_id": "580238",
      "municipio_nombre": "Mariano Moreno",
      "nombre": "COVUNCO CENTRO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7642591265553,
      "centroide_lon": -70.0386669795207,
      "departamento_id": "58112",
      "departamento_nombre": "Zapala",
      "id": "58112020002",
      "localidad_censal_id": "58112020",
      "localidad_censal_nombre": "Mariano Moreno",
      "municipio_id": "580238",
      "municipio_nombre": "Mariano Moreno",
      "nombre": "MARIANO MORENO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.865500620555,
      "centroide_lon": -69.750265287383,
      "departamento_id": "58112",
      "departamento_nombre": "Zapala",
      "id": "58112030000",
      "localidad_censal_id": "58112030",
      "localidad_censal_nombre": "Ramón M. Castro",
      "municipio_id": "585147",
      "municipio_nombre": "Ramón Castro",
      "nombre": "RAMON M. CASTRO",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8961687323721,
      "centroide_lon": -70.0668545323772,
      "departamento_id": "58112",
      "departamento_nombre": "Zapala",
      "id": "58112040000",
      "localidad_censal_id": "58112040",
      "localidad_censal_nombre": "Zapala",
      "municipio_id": "580245",
      "municipio_nombre": "Zapala",
      "nombre": "ZAPALA",
      "provincia_id": "58",
      "provincia_nombre": "Neuquén"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0954235915941,
      "centroide_lon": -63.9092240856567,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007010000",
      "localidad_censal_id": "62007010",
      "localidad_censal_nombre": "Bahía Creek",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BAHIA CREEK",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.043074401021,
      "centroide_lon": -62.8212339732456,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007020000",
      "localidad_censal_id": "62007020",
      "localidad_censal_nombre": "El Cóndor",
      "municipio_id": "620014",
      "municipio_nombre": "Viedma",
      "nombre": "EL CONDOR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8040464324129,
      "centroide_lon": -63.119258465235,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007030000",
      "localidad_censal_id": "62007030",
      "localidad_censal_nombre": "El Juncal",
      "municipio_id": "620014",
      "municipio_nombre": "Viedma",
      "nombre": "EL JUNCAL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.4302951883973,
      "centroide_lon": -63.6719140103256,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007040000",
      "localidad_censal_id": "62007040",
      "localidad_censal_nombre": "Guardia Mitre",
      "municipio_id": "620007",
      "municipio_nombre": "Guardia Mitre",
      "nombre": "GUARDIA MITRE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1541008885686,
      "centroide_lon": -63.1234800366543,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007050000",
      "localidad_censal_id": "62007050",
      "localidad_censal_nombre": "La Lobería",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA LOBERIA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8722977227525,
      "centroide_lon": -62.9146635826372,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007060000",
      "localidad_censal_id": "62007060",
      "localidad_censal_nombre": "Loteo Costa de Río",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOTEO COSTA DE RIO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0178488120515,
      "centroide_lon": -64.1403933053127,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007070000",
      "localidad_censal_id": "62007070",
      "localidad_censal_nombre": "Pozo Salado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZO SALADO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.7472509496074,
      "centroide_lon": -63.2646524967149,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007080000",
      "localidad_censal_id": "62007080",
      "localidad_censal_nombre": "San Javier",
      "municipio_id": "625014",
      "municipio_nombre": "San Javier",
      "nombre": "SAN JAVIER",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -40.8093232712389,
      "centroide_lon": -62.9853203682712,
      "departamento_id": "62007",
      "departamento_nombre": "Adolfo Alsina",
      "id": "62007090000",
      "localidad_censal_id": "62007090",
      "localidad_censal_nombre": "Viedma",
      "municipio_id": "620014",
      "municipio_nombre": "Viedma",
      "nombre": "VIEDMA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1585373145235,
      "centroide_lon": -66.1856066477135,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014010000",
      "localidad_censal_id": "62014010",
      "localidad_censal_nombre": "Barrio Unión",
      "municipio_id": "620021",
      "municipio_nombre": "Chimpay",
      "nombre": "BARRIO UNION",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0881631155042,
      "centroide_lon": -66.5209546613811,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014020000",
      "localidad_censal_id": "62014020",
      "localidad_censal_nombre": "Chelforó",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHELFORO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1651527318617,
      "centroide_lon": -66.1447069274027,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014030000",
      "localidad_censal_id": "62014030",
      "localidad_censal_nombre": "Chimpay",
      "municipio_id": "620021",
      "municipio_nombre": "Chimpay",
      "nombre": "CHIMPAY",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2884543350453,
      "centroide_lon": -65.663280823108,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014040000",
      "localidad_censal_id": "62014040",
      "localidad_censal_nombre": "Choele Choel",
      "municipio_id": "620028",
      "municipio_nombre": "Choele Choel",
      "nombre": "CHOELE CHOEL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1858299915745,
      "centroide_lon": -65.9563191181266,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014050000",
      "localidad_censal_id": "62014050",
      "localidad_censal_nombre": "Coronel Belisle",
      "municipio_id": "620035",
      "municipio_nombre": "Coronel Belisle",
      "nombre": "CORONEL BELISLE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.2026912901608,
      "centroide_lon": -65.7409172296959,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014060000",
      "localidad_censal_id": "62014060",
      "localidad_censal_nombre": "Darwin",
      "municipio_id": "620042",
      "municipio_nombre": "Darwin",
      "nombre": "DARWIN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.4232652920959,
      "centroide_lon": -65.7014504976777,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014070000",
      "localidad_censal_id": "62014070",
      "localidad_censal_nombre": "Lamarque",
      "municipio_id": "620049",
      "municipio_nombre": "Lamarque",
      "nombre": "LAMARQUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.3088800984468,
      "centroide_lon": -65.7648714193881,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014080000",
      "localidad_censal_id": "62014080",
      "localidad_censal_nombre": "Luis Beltrán",
      "municipio_id": "620056",
      "municipio_nombre": "Luis Beltran",
      "nombre": "LUIS BELTRAN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.4841604573414,
      "centroide_lon": -65.6124600513947,
      "departamento_id": "62014",
      "departamento_nombre": "Avellaneda",
      "id": "62014090000",
      "localidad_censal_id": "62014090",
      "localidad_censal_nombre": "Pomona",
      "municipio_id": "620063",
      "municipio_nombre": "Pomona",
      "nombre": "POMONA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1700881080441,
      "centroide_lon": -71.3851119262238,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021005000",
      "localidad_censal_id": "62021005",
      "localidad_censal_nombre": "Arelauquen",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "VILLA ARELAUQUEN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1814203033089,
      "centroide_lon": -71.3493437707973,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021010000",
      "localidad_censal_id": "62021010",
      "localidad_censal_nombre": "Barrio El Pilar",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "BARRIO EL PILAR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0947497360429,
      "centroide_lon": -71.505527376881,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021020000",
      "localidad_censal_id": "62021020",
      "localidad_censal_nombre": "Colonia Suiza",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "COLONIA SUIZA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.9804859657332,
      "centroide_lon": -71.5336172136647,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021030000",
      "localidad_censal_id": "62021030",
      "localidad_censal_nombre": "El Bolsón",
      "municipio_id": "620070",
      "municipio_nombre": "El Bolson",
      "nombre": "EL BOLSON",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.6571199223738,
      "centroide_lon": -71.4592776287102,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021040000",
      "localidad_censal_id": "62021040",
      "localidad_censal_nombre": "El Foyel",
      "municipio_id": "625028",
      "municipio_nombre": "El Manso",
      "nombre": "EL FOYEL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.8412736734112,
      "centroide_lon": -71.5091292986442,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021047000",
      "localidad_censal_id": "62021047",
      "localidad_censal_nombre": "Mallín Ahogado",
      "municipio_id": "620070",
      "municipio_nombre": "El Bolson",
      "nombre": "MALLIN AHOGADO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.5822159155763,
      "centroide_lon": -71.5012934002795,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021050000",
      "localidad_censal_id": "62021050",
      "localidad_censal_nombre": "Río Villegas",
      "municipio_id": "625028",
      "municipio_nombre": "El Manso",
      "nombre": "RIO VILLEGAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -41.1369282850916,
      "centroide_lon": -71.2990645403112,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021060000",
      "localidad_censal_id": "62021060",
      "localidad_censal_nombre": "San Carlos de Bariloche",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "SAN CARLOS DE BARILOCHE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -41.1192625668718,
      "centroide_lon": -71.351142488652,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021060001",
      "localidad_censal_id": "62021060",
      "localidad_censal_nombre": "San Carlos de Bariloche",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN CARLOS DE BARILOCHE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -41.0649702714092,
      "centroide_lon": -71.4726736795373,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021060002",
      "localidad_censal_id": "62021060",
      "localidad_censal_nombre": "San Carlos de Bariloche",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA CAMPANARIO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -41.0697604387258,
      "centroide_lon": -71.5396513534561,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021060003",
      "localidad_censal_id": "62021060",
      "localidad_censal_nombre": "San Carlos de Bariloche",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "VILLA LLAO LLAO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1666351264313,
      "centroide_lon": -71.4375745404969,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021080000",
      "localidad_censal_id": "62021080",
      "localidad_censal_nombre": "Villa Catedral",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "VILLA CATEDRAL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1575793746786,
      "centroide_lon": -71.4131558610974,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021100000",
      "localidad_censal_id": "62021100",
      "localidad_censal_nombre": "Villa Los Coihues",
      "municipio_id": "620077",
      "municipio_nombre": "San Carlos de Bariloche",
      "nombre": "VILLA LOS COIHUES",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.3495536441427,
      "centroide_lon": -71.5090416294131,
      "departamento_id": "62021",
      "departamento_nombre": "Bariloche",
      "id": "62021110000",
      "localidad_censal_id": "62021110",
      "localidad_censal_nombre": "Villa Mascardi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA MASCARDI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.1405715071916,
      "centroide_lon": -64.3297427915782,
      "departamento_id": "62028",
      "departamento_nombre": "Conesa",
      "id": "62028010000",
      "localidad_censal_id": "62028010",
      "localidad_censal_nombre": "Barrio Colonia Conesa",
      "municipio_id": "620084",
      "municipio_nombre": "Gral. Conesa",
      "nombre": "BARRIO COLONIA CONESA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.1047354258125,
      "centroide_lon": -64.452961945825,
      "departamento_id": "62028",
      "departamento_nombre": "Conesa",
      "id": "62028020000",
      "localidad_censal_id": "62028020",
      "localidad_censal_nombre": "General Conesa",
      "municipio_id": "620084",
      "municipio_nombre": "Gral. Conesa",
      "nombre": "GENERAL CONESA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.0564744924401,
      "centroide_lon": -64.4726087632632,
      "departamento_id": "62028",
      "departamento_nombre": "Conesa",
      "id": "62028030000",
      "localidad_censal_id": "62028030",
      "localidad_censal_nombre": "Barrio Planta Compresora de Gas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BARRIO PLANTA COMPRESORA DE GAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.9787588388031,
      "centroide_lon": -68.8683979876685,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035010000",
      "localidad_censal_id": "62035010",
      "localidad_censal_nombre": "Aguada Guzmán",
      "municipio_id": "625077",
      "municipio_nombre": "Aguada Guzman",
      "nombre": "AGUADA GUZMAN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.7252943733893,
      "centroide_lon": -68.4939533269128,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035020000",
      "localidad_censal_id": "62035020",
      "localidad_censal_nombre": "Cerro Policía",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "CERRO POLICIA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.9230912187031,
      "centroide_lon": -68.3369498427779,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035030000",
      "localidad_censal_id": "62035030",
      "localidad_censal_nombre": "El Cuy",
      "municipio_id": "625056",
      "municipio_nombre": "El Cuy",
      "nombre": "EL CUY",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9840690690676,
      "centroide_lon": -68.1403501822625,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035040000",
      "localidad_censal_id": "62035040",
      "localidad_censal_nombre": "Las Perlas",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "LAS PERLAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.4229029710507,
      "centroide_lon": -69.6143877108253,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035050000",
      "localidad_censal_id": "62035050",
      "localidad_censal_nombre": "Mencué",
      "municipio_id": "625070",
      "municipio_nombre": "Mencuen",
      "nombre": "MENCUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.8282845926545,
      "centroide_lon": -69.5089726874799,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035060000",
      "localidad_censal_id": "62035060",
      "localidad_censal_nombre": "Naupa Huen",
      "municipio_id": "625098",
      "municipio_nombre": "Naupa Huen",
      "nombre": "NAUPA HUEN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -39.1153330396607,
      "centroide_lon": -67.6264974744394,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035070000",
      "localidad_censal_id": "62035070",
      "localidad_censal_nombre": "Paso Córdova",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "PASO CORDOVA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1404827453952,
      "centroide_lon": -66.7285641693515,
      "departamento_id": "62035",
      "departamento_nombre": "El Cuy",
      "id": "62035080000",
      "localidad_censal_id": "62035080",
      "localidad_censal_nombre": "Valle Azul",
      "municipio_id": "625112",
      "municipio_nombre": "Valle Azul",
      "nombre": "VALLE AZUL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9795106570689,
      "centroide_lon": -67.828021398161,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042010000",
      "localidad_censal_id": "62042010",
      "localidad_censal_nombre": "Allen",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "ALLEN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7513229744846,
      "centroide_lon": -68.0085536061591,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042020000",
      "localidad_censal_id": "62042020",
      "localidad_censal_nombre": "Paraje Arroyón (Bajo San Cayetano)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BAJO SAN CAYETANO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7246633358539,
      "centroide_lon": -68.1580560913419,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042030000",
      "localidad_censal_id": "62042030",
      "localidad_censal_nombre": "Barda del Medio",
      "municipio_id": "620140",
      "municipio_nombre": "Contralmirante Cordero",
      "nombre": "BARDA DEL MEDIO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0309641335839,
      "centroide_lon": -67.7863228650653,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042040000",
      "localidad_censal_id": "62042040",
      "localidad_censal_nombre": "Barrio Blanco",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO BLANCO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0230092190233,
      "centroide_lon": -67.8005036132986,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042050000",
      "localidad_censal_id": "62042050",
      "localidad_censal_nombre": "Barrio Calle Ciega Nº 10",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO CALLE CIEGA N? 10",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0430891464152,
      "centroide_lon": -67.7520658420811,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042060000",
      "localidad_censal_id": "62042060",
      "localidad_censal_nombre": "Barrio Calle Ciega Nº 6",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO CALLE CIEGA N? 6",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0682963331395,
      "centroide_lon": -67.6389236900117,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042070000",
      "localidad_censal_id": "62042070",
      "localidad_censal_nombre": "Barrio Canale",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO CANALE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0516494451284,
      "centroide_lon": -67.6345853523327,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042080000",
      "localidad_censal_id": "62042080",
      "localidad_censal_nombre": "Barrio Chacra Monte",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO CHACRA MONTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0418587269806,
      "centroide_lon": -67.8075970544366,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042090000",
      "localidad_censal_id": "62042090",
      "localidad_censal_nombre": "Barrio Costa Este",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO COSTA ESTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9435967131876,
      "centroide_lon": -67.9095130600985,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042100000",
      "localidad_censal_id": "62042100",
      "localidad_censal_nombre": "Barrio Costa Linda",
      "municipio_id": "620154",
      "municipio_nombre": "Gral. Fdez. Oro",
      "nombre": "BARRIO COSTA LINDA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0280740237159,
      "centroide_lon": -67.8406950461568,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042110000",
      "localidad_censal_id": "62042110",
      "localidad_censal_nombre": "Barrio Costa Oeste",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO COSTA OESTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.688801948065,
      "centroide_lon": -67.8695487107459,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042115000",
      "localidad_censal_id": "62042115",
      "localidad_censal_nombre": "Barrio Destacamento",
      "municipio_id": "625105",
      "municipio_nombre": "Peñas Blancas",
      "nombre": "BARRIO DESTACAMENTO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6719231721601,
      "centroide_lon": -68.2343296924898,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042120000",
      "localidad_censal_id": "62042120",
      "localidad_censal_nombre": "Barrio El Labrador",
      "municipio_id": "620098",
      "municipio_nombre": "Campo Grande",
      "nombre": "BARRIO EL LABRADOR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9965483470472,
      "centroide_lon": -67.7600066651023,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042130000",
      "localidad_censal_id": "62042130",
      "localidad_censal_nombre": "Barrio El Maruchito",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO EL MARUCHITO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0639270176593,
      "centroide_lon": -67.5120162799717,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042140000",
      "localidad_censal_id": "62042140",
      "localidad_censal_nombre": "Barrio El Petróleo",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO EL PETROLEO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.001652251504,
      "centroide_lon": -67.8506842254546,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042143000",
      "localidad_censal_id": "62042143",
      "localidad_censal_nombre": "Barrio Emergente",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO EMERGENTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0531680628778,
      "centroide_lon": -67.472967428649,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042147000",
      "localidad_censal_id": "62042147",
      "localidad_censal_nombre": "Barrio Fátima",
      "municipio_id": "620112",
      "municipio_nombre": "Cervantes",
      "nombre": "COLONIA FATIMA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.071916321907,
      "centroide_lon": -67.7129100460388,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042150000",
      "localidad_censal_id": "62042150",
      "localidad_censal_nombre": "Barrio Frontera",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO FRONTERA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0416838101557,
      "centroide_lon": -67.7340081423714,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042160000",
      "localidad_censal_id": "62042160",
      "localidad_censal_nombre": "Barrio Guerrico",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO GUERRICO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9966443708843,
      "centroide_lon": -67.9181463726224,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042170000",
      "localidad_censal_id": "62042170",
      "localidad_censal_nombre": "Barrio Isla 10",
      "municipio_id": "620154",
      "municipio_nombre": "Gral. Fdez. Oro",
      "nombre": "BARRIO ISLA 10",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0494648024568,
      "centroide_lon": -67.217793659392,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042180000",
      "localidad_censal_id": "62042180",
      "localidad_censal_nombre": "Barrio La Barda",
      "municipio_id": "620168",
      "municipio_nombre": "Ingeniero Huergo",
      "nombre": "BARRIO LA BARDA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0753070627553,
      "centroide_lon": -67.5392261916358,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042190000",
      "localidad_censal_id": "62042190",
      "localidad_censal_nombre": "Barrio La Costa",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO LA COSTA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0923450479727,
      "centroide_lon": -67.2007281871129,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042200000",
      "localidad_censal_id": "62042200",
      "localidad_censal_nombre": "Barrio La Costa",
      "municipio_id": "620168",
      "municipio_nombre": "Ingeniero Huergo",
      "nombre": "BARRIO LA COSTA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0331815789057,
      "centroide_lon": -67.3862477660724,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042210000",
      "localidad_censal_id": "62042210",
      "localidad_censal_nombre": "Barrio La Defensa",
      "municipio_id": "620112",
      "municipio_nombre": "Cervantes",
      "nombre": "BARRIO LA DEFENSA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0370774236916,
      "centroide_lon": -67.7820594526764,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042215000",
      "localidad_censal_id": "62042215",
      "localidad_censal_nombre": "Barrio La Herradura",
      "municipio_id": "620091",
      "municipio_nombre": "Allen",
      "nombre": "BARRIO LA HERRADURA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0772588097086,
      "centroide_lon": -67.5794991279564,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042230000",
      "localidad_censal_id": "62042230",
      "localidad_censal_nombre": "Barrio La Ribera - Barrio APYCAR",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO LA RIBERA - BARRIO APYCAR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0298112121202,
      "centroide_lon": -67.5009911848666,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042240000",
      "localidad_censal_id": "62042240",
      "localidad_censal_nombre": "Puente Cero",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO LAS ANGUSTIAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0514047890722,
      "centroide_lon": -67.3468986066245,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042245000",
      "localidad_censal_id": "62042245",
      "localidad_censal_nombre": "Barrio Luisillo",
      "municipio_id": "620175",
      "municipio_nombre": "Mainque",
      "nombre": "BARRIO LUISILLO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0827873095255,
      "centroide_lon": -67.6523985545637,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042250000",
      "localidad_censal_id": "62042250",
      "localidad_censal_nombre": "Barrio Mar del Plata",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO MAR DEL PLATA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.9901442627179,
      "centroide_lon": -67.9613095600255,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042260000",
      "localidad_censal_id": "62042260",
      "localidad_censal_nombre": "Barrio María Elvira",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO MARIA ELVIRA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1306874014844,
      "centroide_lon": -66.8941649716278,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042265000",
      "localidad_censal_id": "62042265",
      "localidad_censal_nombre": "Barrio Moño Azul",
      "municipio_id": "620119",
      "municipio_nombre": "Chichinales",
      "nombre": "BARRIO MOÑO AZUL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0907183953718,
      "centroide_lon": -67.5898603467619,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042270000",
      "localidad_censal_id": "62042270",
      "localidad_censal_nombre": "Barrio Mosconi",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO MOSCONI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8637279259729,
      "centroide_lon": -68.0231905596235,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042280000",
      "localidad_censal_id": "62042280",
      "localidad_censal_nombre": "Barrio Norte",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO NORTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.6748275939008,
      "centroide_lon": -67.8736521306821,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042297000",
      "localidad_censal_id": "62042297",
      "localidad_censal_nombre": "Barrio Pinar",
      "municipio_id": "625105",
      "municipio_nombre": "Peñas Blancas",
      "nombre": "BARRIO PINAR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0430283937958,
      "centroide_lon": -67.4642040039168,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042310000",
      "localidad_censal_id": "62042310",
      "localidad_censal_nombre": "Barrio Porvenir",
      "municipio_id": "620112",
      "municipio_nombre": "Cervantes",
      "nombre": "BARRIO PORVENIR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8937119363148,
      "centroide_lon": -67.971068167392,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330001",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO EL TREINTA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9655980210737,
      "centroide_lon": -67.9509550220406,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330002",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO GORETTI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9655980210737,
      "centroide_lon": -67.9509550220406,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330003",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO PUENTE 83",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9589646944033,
      "centroide_lon": -67.9492414342193,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330004",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO PUENTE DE MADERA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9386794797604,
      "centroide_lon": -67.9440033559728,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330005",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO TRES LUCES",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9655980210737,
      "centroide_lon": -67.9509550220406,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042330006",
      "localidad_censal_id": "62042330",
      "localidad_censal_nombre": "Barrio Puente 83",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "TRES LUCES",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0282517975929,
      "centroide_lon": -67.2984549123711,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042335000",
      "localidad_censal_id": "62042335",
      "localidad_censal_nombre": "Barrio Santa Lucia",
      "municipio_id": "620175",
      "municipio_nombre": "Mainque",
      "nombre": "SANTA LUCIA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1260785745275,
      "centroide_lon": -67.1035165050666,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042340000",
      "localidad_censal_id": "62042340",
      "localidad_censal_nombre": "Barrio Santa Rita",
      "municipio_id": "620182",
      "municipio_nombre": "Villa Regina",
      "nombre": "BARRIO SANTA RITA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.979186876102,
      "centroide_lon": -67.941976373184,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042350000",
      "localidad_censal_id": "62042350",
      "localidad_censal_nombre": "Barrio Unión",
      "municipio_id": "620154",
      "municipio_nombre": "Gral. Fdez. Oro",
      "nombre": "BARRIO UNION",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -37.8815288625891,
      "centroide_lon": -67.7945569701758,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042360000",
      "localidad_censal_id": "62042360",
      "localidad_censal_nombre": "Catriel",
      "municipio_id": "620105",
      "municipio_nombre": "Catriel",
      "nombre": "CATRIEL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0515785846632,
      "centroide_lon": -67.3930560378066,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042370000",
      "localidad_censal_id": "62042370",
      "localidad_censal_nombre": "Cervantes",
      "municipio_id": "620112",
      "municipio_nombre": "Cervantes",
      "nombre": "CERVANTES",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1148814347412,
      "centroide_lon": -66.9425150029993,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042380000",
      "localidad_censal_id": "62042380",
      "localidad_censal_nombre": "Chichinales",
      "municipio_id": "620119",
      "municipio_nombre": "Chichinales",
      "nombre": "CHICHINALES",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -38.8275620197177,
      "centroide_lon": -68.0660962055201,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042390000",
      "localidad_censal_id": "62042390",
      "localidad_censal_nombre": "Cinco Saltos",
      "municipio_id": "620126",
      "municipio_nombre": "Cinco Saltos",
      "nombre": "CINCO SALTOS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.7961848310008,
      "centroide_lon": -68.0734334344623,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042390001",
      "localidad_censal_id": "62042390",
      "localidad_censal_nombre": "Cinco Saltos",
      "municipio_id": "620126",
      "municipio_nombre": "Cinco Saltos",
      "nombre": "BARRIO PRESIDENTE PERON",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.8405541009144,
      "centroide_lon": -68.0623005797764,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042390002",
      "localidad_censal_id": "62042390",
      "localidad_censal_nombre": "Cinco Saltos",
      "municipio_id": "620126",
      "municipio_nombre": "Cinco Saltos",
      "nombre": "CINCO SALTOS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -38.924558895075,
      "centroide_lon": -68.035384250397,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042400000",
      "localidad_censal_id": "62042400",
      "localidad_censal_nombre": "Cipolletti",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "CIPOLLETTI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9245848988383,
      "centroide_lon": -68.0307899442508,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042400001",
      "localidad_censal_id": "62042400",
      "localidad_censal_nombre": "Cipolletti",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "BARRIO LA LOR",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -38.9220596281776,
      "centroide_lon": -67.9955493313849,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042400002",
      "localidad_censal_id": "62042400",
      "localidad_censal_nombre": "Cipolletti",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "CIPOLLETTI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7570062611988,
      "centroide_lon": -68.0994972104866,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042410000",
      "localidad_censal_id": "62042410",
      "localidad_censal_nombre": "Contralmirante Cordero",
      "municipio_id": "620140",
      "municipio_nombre": "Contralmirante Cordero",
      "nombre": "CONTRALMIRANTE CORDERO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8871754648156,
      "centroide_lon": -68.0068433988189,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042420000",
      "localidad_censal_id": "62042420",
      "localidad_censal_nombre": "Ferri",
      "municipio_id": "620133",
      "municipio_nombre": "Cipolletti",
      "nombre": "FERRI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0795501222919,
      "centroide_lon": -67.1575508259755,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042430000",
      "localidad_censal_id": "62042430",
      "localidad_censal_nombre": "General Enrique Godoy",
      "municipio_id": "620147",
      "municipio_nombre": "Gral. Enrique Godoy",
      "nombre": "GENERAL ENRIQUE GODOY",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.95436020459,
      "centroide_lon": -67.9251089784989,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042440000",
      "localidad_censal_id": "62042440",
      "localidad_censal_nombre": "General Fernández Oro",
      "municipio_id": "620154",
      "municipio_nombre": "Gral. Fdez. Oro",
      "nombre": "GENERAL FERNANDEZ ORO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -39.0267025182087,
      "centroide_lon": -67.5748540962425,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042450000",
      "localidad_censal_id": "62042450",
      "localidad_censal_nombre": "General Roca",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "GENERAL ROCA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -39.055737401237,
      "centroide_lon": -67.5935301061017,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042450001",
      "localidad_censal_id": "62042450",
      "localidad_censal_nombre": "General Roca",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "BARRIO PINO AZUL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0711757468082,
      "centroide_lon": -67.2328876350348,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042460000",
      "localidad_censal_id": "62042460",
      "localidad_censal_nombre": "Ingeniero Luis A. Huergo",
      "municipio_id": "620168",
      "municipio_nombre": "Ingeniero Huergo",
      "nombre": "INGENIERO LUIS A. HUERGO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1114281023989,
      "centroide_lon": -66.9939096262054,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042470000",
      "localidad_censal_id": "62042470",
      "localidad_censal_nombre": "Ingeniero Otto Krause",
      "municipio_id": "620119",
      "municipio_nombre": "Chichinales",
      "nombre": "INGENIERO OTTO KRAUSE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0637561999382,
      "centroide_lon": -67.3042811813575,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042480000",
      "localidad_censal_id": "62042480",
      "localidad_censal_nombre": "Mainqué",
      "municipio_id": "620175",
      "municipio_nombre": "Mainque",
      "nombre": "MAINQUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -39.1077504096641,
      "centroide_lon": -67.6277074998828,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042490000",
      "localidad_censal_id": "62042490",
      "localidad_censal_nombre": "Paso Córdova",
      "municipio_id": "620161",
      "municipio_nombre": "Gral. Roca",
      "nombre": "PASO CORDOVA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7010665100453,
      "centroide_lon": -68.0277179698786,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042500000",
      "localidad_censal_id": "62042500",
      "localidad_censal_nombre": "Península Ruca Co",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PENINSULA RUCA CO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6856409614896,
      "centroide_lon": -68.1580780282182,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042520000",
      "localidad_censal_id": "62042520",
      "localidad_censal_nombre": "Sargento Vidal",
      "municipio_id": "620098",
      "municipio_nombre": "Campo Grande",
      "nombre": "SARGENTO VIDAL",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1283463654662,
      "centroide_lon": -67.0481125141998,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042530000",
      "localidad_censal_id": "62042530",
      "localidad_censal_nombre": "Villa Alberdi",
      "municipio_id": "620182",
      "municipio_nombre": "Villa Regina",
      "nombre": "VILLA ALBERDI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.1258079817539,
      "centroide_lon": -66.9976300412367,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042540000",
      "localidad_censal_id": "62042540",
      "localidad_censal_nombre": "Villa del Parque",
      "municipio_id": "620119",
      "municipio_nombre": "Chichinales",
      "nombre": "VILLA DEL PARQUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.6806041543676,
      "centroide_lon": -68.2157180851266,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042550000",
      "localidad_censal_id": "62042550",
      "localidad_censal_nombre": "Villa Manzano",
      "municipio_id": "620098",
      "municipio_nombre": "Campo Grande",
      "nombre": "VILLA MANZANO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0962966627004,
      "centroide_lon": -67.0828092630939,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042560000",
      "localidad_censal_id": "62042560",
      "localidad_censal_nombre": "Villa Regina",
      "municipio_id": "620182",
      "municipio_nombre": "Villa Regina",
      "nombre": "VILLA REGINA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.7064851078187,
      "centroide_lon": -68.1737455217997,
      "departamento_id": "62042",
      "departamento_nombre": "General Roca",
      "id": "62042570000",
      "localidad_censal_id": "62042570",
      "localidad_censal_nombre": "Villa San Isidro",
      "municipio_id": "620098",
      "municipio_nombre": "Campo Grande",
      "nombre": "VILLA SAN ISIDRO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0632755118249,
      "centroide_lon": -67.5965600975269,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049010000",
      "localidad_censal_id": "62049010",
      "localidad_censal_nombre": "Comicó",
      "municipio_id": "625210",
      "municipio_nombre": "Comico",
      "nombre": "COMICO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.8812021704501,
      "centroide_lon": -66.9407404270844,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049020000",
      "localidad_censal_id": "62049020",
      "localidad_censal_nombre": "Cona Niyeu",
      "municipio_id": "625161",
      "municipio_nombre": "Cona Niyeu",
      "nombre": "CONA NIYEU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.5085400133825,
      "centroide_lon": -67.2619207148835,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049030000",
      "localidad_censal_id": "62049030",
      "localidad_censal_nombre": "Ministro Ramos Mexía",
      "municipio_id": "620189",
      "municipio_nombre": "Ministro Ramos Mexia",
      "nombre": "MINISTRO RAMOS MEXIA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.3591519247092,
      "centroide_lon": -67.9314763422669,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049040000",
      "localidad_censal_id": "62049040",
      "localidad_censal_nombre": "Prahuaniyeu",
      "municipio_id": "625259",
      "municipio_nombre": "Prahuaniyeu",
      "nombre": "PRAHUANIYEU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.5850993527615,
      "centroide_lon": -67.7555445864448,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049050000",
      "localidad_censal_id": "62049050",
      "localidad_censal_nombre": "Sierra Colorada",
      "municipio_id": "620196",
      "municipio_nombre": "Sierra Colorada",
      "nombre": "SIERRA COLORADA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8508277432449,
      "centroide_lon": -66.9816846927042,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049060000",
      "localidad_censal_id": "62049060",
      "localidad_censal_nombre": "Treneta",
      "municipio_id": "625119",
      "municipio_nombre": "Rincon de Treneta",
      "nombre": "TRENETA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8429274507992,
      "centroide_lon": -67.1922500656909,
      "departamento_id": "62049",
      "departamento_nombre": "9 de julio",
      "id": "62049070000",
      "localidad_censal_id": "62049070",
      "localidad_censal_nombre": "Yaminué",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "YAMINUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.4504057812872,
      "centroide_lon": -70.6827894626766,
      "departamento_id": "62056",
      "departamento_nombre": "Ñorquinco",
      "id": "62056010000",
      "localidad_censal_id": "62056010",
      "localidad_censal_nombre": "Las Bayas",
      "municipio_id": "620224",
      "municipio_nombre": "Pilcaniyeu",
      "nombre": "LAS BAYAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.7698416427745,
      "centroide_lon": -70.1708632216436,
      "departamento_id": "62056",
      "departamento_nombre": "Ñorquinco",
      "id": "62056020000",
      "localidad_censal_id": "62056020",
      "localidad_censal_nombre": "Mamuel Choique",
      "municipio_id": "625238",
      "municipio_nombre": "Mamuel Choique",
      "nombre": "MAMUEL CHOIQUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.843448611748,
      "centroide_lon": -70.8943932149944,
      "departamento_id": "62056",
      "departamento_nombre": "Ñorquinco",
      "id": "62056030000",
      "localidad_censal_id": "62056030",
      "localidad_censal_nombre": "Ñorquincó",
      "municipio_id": "620203",
      "municipio_nombre": "Ñorquinco",
      "nombre": "ÑORQUINCO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.534892227381,
      "centroide_lon": -69.854645970072,
      "departamento_id": "62056",
      "departamento_nombre": "Ñorquinco",
      "id": "62056040000",
      "localidad_censal_id": "62056040",
      "localidad_censal_nombre": "Ojos de Agua",
      "municipio_id": "625245",
      "municipio_nombre": "Ojos de Agua",
      "nombre": "OJOS DE AGUA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.7167227835394,
      "centroide_lon": -70.4710839014636,
      "departamento_id": "62056",
      "departamento_nombre": "Ñorquinco",
      "id": "62056050000",
      "localidad_censal_id": "62056050",
      "localidad_censal_nombre": "Río Chico",
      "municipio_id": "625266",
      "municipio_nombre": "Rio Chico",
      "nombre": "RIO CHICO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.041876855319,
      "centroide_lon": -63.9985907357656,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063005000",
      "localidad_censal_id": "62063005",
      "localidad_censal_nombre": "Barrio Esperanza",
      "municipio_id": "620210",
      "municipio_nombre": "Rìo Colorado",
      "nombre": "BARRIO ESPERANZA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0361229259361,
      "centroide_lon": -64.0139390564756,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063010000",
      "localidad_censal_id": "62063010",
      "localidad_censal_nombre": "Colonia Juliá y Echarren",
      "municipio_id": "620210",
      "municipio_nombre": "Rìo Colorado",
      "nombre": "COLONIA JULIA Y ECHARREN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -39.0125652171478,
      "centroide_lon": -64.0650447267437,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063013000",
      "localidad_censal_id": "62063013",
      "localidad_censal_nombre": "Juventud Unida",
      "municipio_id": "620210",
      "municipio_nombre": "Rìo Colorado",
      "nombre": "JUVENTUD UNIDA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8296136177488,
      "centroide_lon": -64.9374198062825,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063017000",
      "localidad_censal_id": "62063017",
      "localidad_censal_nombre": "Pichi Mahuida",
      "municipio_id": "420189",
      "municipio_nombre": "La Adela",
      "nombre": "PICHI MAHUIDA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -38.9914136078743,
      "centroide_lon": -64.0874682295519,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063020000",
      "localidad_censal_id": "62063020",
      "localidad_censal_nombre": "Río Colorado",
      "municipio_id": "620210",
      "municipio_nombre": "Rìo Colorado",
      "nombre": "RIO COLORADO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -38.8229789232812,
      "centroide_lon": -64.8182778708068,
      "departamento_id": "62063",
      "departamento_nombre": "Pichi Mahuida",
      "id": "62063060000",
      "localidad_censal_id": "62063060",
      "localidad_censal_nombre": "Salto Andersen",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SALTO ANDERSEN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8843058831975,
      "centroide_lon": -70.0229634453504,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070005000",
      "localidad_censal_id": "62070005",
      "localidad_censal_nombre": "Cañadón Chileno",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CAÑADON CHILENO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0298746753183,
      "centroide_lon": -70.269980649853,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070010000",
      "localidad_censal_id": "62070010",
      "localidad_censal_nombre": "Comallo",
      "municipio_id": "620217",
      "municipio_nombre": "Comallo",
      "nombre": "COMALLO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0691934226159,
      "centroide_lon": -71.16219332616,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070020000",
      "localidad_censal_id": "62070020",
      "localidad_censal_nombre": "Dina Huapi",
      "municipio_id": "620273",
      "municipio_nombre": "Dina Huapi",
      "nombre": "DINA HUAPI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.7925954846977,
      "centroide_lon": -69.8810399581108,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070030000",
      "localidad_censal_id": "62070030",
      "localidad_censal_nombre": "Laguna Blanca",
      "municipio_id": "625231",
      "municipio_nombre": "Laguna Blanca",
      "nombre": "LAGUNA BLANCA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0885592834836,
      "centroide_lon": -71.1369133751581,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070040000",
      "localidad_censal_id": "62070040",
      "localidad_censal_nombre": "Ñirihuau",
      "municipio_id": "620273",
      "municipio_nombre": "Dina Huapi",
      "nombre": "ÑIRIHUAU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1252901833754,
      "centroide_lon": -70.7216943906912,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070060000",
      "localidad_censal_id": "62070060",
      "localidad_censal_nombre": "Pilcaniyeu",
      "municipio_id": "620224",
      "municipio_nombre": "Pilcaniyeu",
      "nombre": "PILCANIYEU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.5448524836234,
      "centroide_lon": -70.0532314661515,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070070000",
      "localidad_censal_id": "62070070",
      "localidad_censal_nombre": "Pilquiniyeu del Limay",
      "municipio_id": "625140",
      "municipio_nombre": "Pilquiniyeu del Limay",
      "nombre": "PILQUINIYEU DEL LIMAY",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.9239102047089,
      "centroide_lon": -71.0338579770764,
      "departamento_id": "62070",
      "departamento_nombre": "Pilcaniyeu",
      "id": "62070080000",
      "localidad_censal_id": "62070080",
      "localidad_censal_nombre": "Villa Llanquín",
      "municipio_id": "625042",
      "municipio_nombre": "Villa Llanquin",
      "nombre": "VILLA LLANQUIN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.706602544179,
      "centroide_lon": -65.0030743715644,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077005000",
      "localidad_censal_id": "62077005",
      "localidad_censal_nombre": "El Empalme",
      "municipio_id": "620231",
      "municipio_nombre": "San Antonio Oeste",
      "nombre": "ESTACION EMPALME",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.806093320236,
      "centroide_lon": -65.0847019757487,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077010000",
      "localidad_censal_id": "62077010",
      "localidad_censal_nombre": "Las Grutas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS GRUTAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.6276787888305,
      "centroide_lon": -65.0236746017627,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077020000",
      "localidad_censal_id": "62077020",
      "localidad_censal_nombre": "Playas Doradas",
      "municipio_id": "620238",
      "municipio_nombre": "Sierra Grande",
      "nombre": "PLAYAS DORADAS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8008815532575,
      "centroide_lon": -64.8778974161853,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077030000",
      "localidad_censal_id": "62077030",
      "localidad_censal_nombre": "Puerto San Antonio Este",
      "municipio_id": "620231",
      "municipio_nombre": "San Antonio Oeste",
      "nombre": "PUERTO SAN ANTONIO ESTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.6941221979021,
      "centroide_lon": -65.0245573411298,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077040000",
      "localidad_censal_id": "62077040",
      "localidad_censal_nombre": "Punta Colorada",
      "municipio_id": "620238",
      "municipio_nombre": "Sierra Grande",
      "nombre": "PUNTA COLORADA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.811004684265,
      "centroide_lon": -64.7581129382783,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077045000",
      "localidad_censal_id": "62077045",
      "localidad_censal_nombre": "Saco Viejo",
      "municipio_id": "620231",
      "municipio_nombre": "San Antonio Oeste",
      "nombre": "SACO VIEJO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.7312945275013,
      "centroide_lon": -64.9552941307172,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077050000",
      "localidad_censal_id": "62077050",
      "localidad_censal_nombre": "San Antonio Oeste",
      "municipio_id": "620231",
      "municipio_nombre": "San Antonio Oeste",
      "nombre": "SAN ANTONIO OESTE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.6071863315513,
      "centroide_lon": -65.3534017406091,
      "departamento_id": "62077",
      "departamento_nombre": "San Antonio",
      "id": "62077060000",
      "localidad_censal_id": "62077060",
      "localidad_censal_nombre": "Sierra Grande",
      "municipio_id": "620238",
      "municipio_nombre": "Sierra Grande",
      "nombre": "SIERRA GRANDE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8478737807916,
      "centroide_lon": -65.8393274592303,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084010000",
      "localidad_censal_id": "62084010",
      "localidad_censal_nombre": "Aguada Cecilio",
      "municipio_id": "625168",
      "municipio_nombre": "Aguada Cecilio",
      "nombre": "AGUADA CECILIO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.4372536416486,
      "centroide_lon": -66.0950773167367,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084020000",
      "localidad_censal_id": "62084020",
      "localidad_censal_nombre": "Arroyo Los Berros",
      "municipio_id": "625147",
      "municipio_nombre": "Arroyo los Berros",
      "nombre": "ARROYO LOS BERROS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.6653937822189,
      "centroide_lon": -66.0860135550914,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084030000",
      "localidad_censal_id": "62084030",
      "localidad_censal_nombre": "Arroyo Ventana",
      "municipio_id": "625154",
      "municipio_nombre": "Arroyo Ventana",
      "nombre": "ARROYO VENTANA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.504198321368,
      "centroide_lon": -66.5657529968209,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084040000",
      "localidad_censal_id": "62084040",
      "localidad_censal_nombre": "Nahuel Niyeu",
      "municipio_id": "625182",
      "municipio_nombre": "Nahuel Niyeu",
      "nombre": "NAHUEL NIYEU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.1812714523245,
      "centroide_lon": -65.9614292512301,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084050000",
      "localidad_censal_id": "62084050",
      "localidad_censal_nombre": "Sierra Pailemán",
      "municipio_id": "625189",
      "municipio_nombre": "Sierra Paileman",
      "nombre": "SIERRA PAILEMAN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.6778617788507,
      "centroide_lon": -66.1653261356888,
      "departamento_id": "62084",
      "departamento_nombre": "Valcheta",
      "id": "62084060000",
      "localidad_censal_id": "62084060",
      "localidad_censal_nombre": "Valcheta",
      "municipio_id": "620245",
      "municipio_nombre": "Valcheta",
      "nombre": "VALCHETA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.0639017102391,
      "centroide_lon": -68.3843810353051,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091010000",
      "localidad_censal_id": "62091010",
      "localidad_censal_nombre": "Aguada de Guerra",
      "municipio_id": "625196",
      "municipio_nombre": "Aguada de Guerra",
      "nombre": "AGUADA DE GUERRA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.2448350046555,
      "centroide_lon": -70.0342029410146,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091020000",
      "localidad_censal_id": "62091020",
      "localidad_censal_nombre": "Clemente Onelli",
      "municipio_id": "625217",
      "municipio_nombre": "Clemente Onelli",
      "nombre": "CLEMENTE ONELLI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.6706084126156,
      "centroide_lon": -69.1112545579992,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091030000",
      "localidad_censal_id": "62091030",
      "localidad_censal_nombre": "Colan Conhue",
      "municipio_id": "625203",
      "municipio_nombre": "Colan Conhué",
      "nombre": "COLAN CONUE",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.8182874251641,
      "centroide_lon": -68.0774152377244,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091040000",
      "localidad_censal_id": "62091040",
      "localidad_censal_nombre": "El Caín",
      "municipio_id": "625224",
      "municipio_nombre": "El Cain",
      "nombre": "EL CAIN",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.3268134240327,
      "centroide_lon": -69.5441263890306,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091050000",
      "localidad_censal_id": "62091050",
      "localidad_censal_nombre": "Ingeniero Jacobacci",
      "municipio_id": "620252",
      "municipio_nombre": "Ing. Jacobacci",
      "nombre": "INGENIERO JACOBACCI",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.8451911426273,
      "centroide_lon": -68.0832025061279,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091060000",
      "localidad_censal_id": "62091060",
      "localidad_censal_nombre": "Los Menucos",
      "municipio_id": "620259",
      "municipio_nombre": "Los Menucos",
      "nombre": "LOS MENUCOS",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.2475165638608,
      "centroide_lon": -68.7003410687453,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091070000",
      "localidad_censal_id": "62091070",
      "localidad_censal_nombre": "Maquinchao",
      "municipio_id": "620266",
      "municipio_nombre": "Maquinchao",
      "nombre": "MAQUINCHAO",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -40.9343435582627,
      "centroide_lon": -69.411658784618,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091080000",
      "localidad_censal_id": "62091080",
      "localidad_censal_nombre": "Mina Santa Teresita",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MINA SANTA TERESITA",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -41.9083865405739,
      "centroide_lon": -68.3424732450183,
      "departamento_id": "62091",
      "departamento_nombre": "25 de Mayo",
      "id": "62091090000",
      "localidad_censal_id": "62091090",
      "localidad_censal_nombre": "Pilquiniyeu",
      "municipio_id": "625252",
      "municipio_nombre": "Pilquiniyeu",
      "nombre": "PILQUINIYEU",
      "provincia_id": "62",
      "provincia_nombre": "Río Negro"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.4402117565864,
      "centroide_lon": -64.0003361370349,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007010000",
      "localidad_censal_id": "66007010",
      "localidad_censal_nombre": "Apolinario Saravia",
      "municipio_id": "660007",
      "municipio_nombre": "Apolinario Saravia",
      "nombre": "APOLINARIO SARAVIA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1278603615752,
      "centroide_lon": -64.2896735059411,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007020000",
      "localidad_censal_id": "66007020",
      "localidad_censal_nombre": "Ceibalito",
      "municipio_id": "660028",
      "municipio_nombre": "Joaquín V. González",
      "nombre": "CEIBALITO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9773189675124,
      "centroide_lon": -63.8698564395644,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007030000",
      "localidad_censal_id": "66007030",
      "localidad_censal_nombre": "Centro 25 de Junio",
      "municipio_id": "660028",
      "municipio_nombre": "Joaquín V. González",
      "nombre": "CENTRO 25 DE JUNIO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5136418287603,
      "centroide_lon": -64.0648836625582,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007040000",
      "localidad_censal_id": "66007040",
      "localidad_censal_nombre": "Coronel Mollinedo",
      "municipio_id": "660007",
      "municipio_nombre": "Apolinario Saravia",
      "nombre": "CORONEL MOLLINEDO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1146923148948,
      "centroide_lon": -64.2258664425772,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007050000",
      "localidad_censal_id": "66007050",
      "localidad_censal_nombre": "Coronel Olleros",
      "municipio_id": "660028",
      "municipio_nombre": "Joaquín V. González",
      "nombre": "CORONEL OLLEROS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3519821824981,
      "centroide_lon": -64.0287963887805,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007060000",
      "localidad_censal_id": "66007060",
      "localidad_censal_nombre": "El Quebrachal",
      "municipio_id": "660014",
      "municipio_nombre": "El Quebrachal",
      "nombre": "EL QUEBRACHAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2581750325612,
      "centroide_lon": -64.0470243002426,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007070000",
      "localidad_censal_id": "66007070",
      "localidad_censal_nombre": "Gaona",
      "municipio_id": "660014",
      "municipio_nombre": "El Quebrachal",
      "nombre": "GAONA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2342248497577,
      "centroide_lon": -63.9910915395849,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007080000",
      "localidad_censal_id": "66007080",
      "localidad_censal_nombre": "General Pizarro",
      "municipio_id": "660021",
      "municipio_nombre": "General Pizarro",
      "nombre": "GENERAL PIZARRO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1294324707809,
      "centroide_lon": -64.1385893694805,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007090000",
      "localidad_censal_id": "66007090",
      "localidad_censal_nombre": "Joaquín V. González",
      "municipio_id": "660028",
      "municipio_nombre": "Joaquín V. González",
      "nombre": "JOAQUIN V. GONZALEZ",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7331988444238,
      "centroide_lon": -64.1994831202005,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007100000",
      "localidad_censal_id": "66007100",
      "localidad_censal_nombre": "Las Lajitas",
      "municipio_id": "660035",
      "municipio_nombre": "Las Lajitas",
      "nombre": "LAS LAJITAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.397713500488,
      "centroide_lon": -63.9961108041489,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007110000",
      "localidad_censal_id": "66007110",
      "localidad_censal_nombre": "Luis Burela",
      "municipio_id": "660021",
      "municipio_nombre": "General Pizarro",
      "nombre": "LUIS BURELA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.421569027557,
      "centroide_lon": -63.9896049019406,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007120000",
      "localidad_censal_id": "66007120",
      "localidad_censal_nombre": "Macapillo",
      "municipio_id": "660014",
      "municipio_nombre": "El Quebrachal",
      "nombre": "MACAPILLO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.481072557645,
      "centroide_lon": -63.7880008281707,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007130000",
      "localidad_censal_id": "66007130",
      "localidad_censal_nombre": "Nuestra Señora de Talavera",
      "municipio_id": "660014",
      "municipio_nombre": "El Quebrachal",
      "nombre": "NUESTRA SEÑORA DE TALAVERA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.8260406674686,
      "centroide_lon": -64.1855790667322,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007140000",
      "localidad_censal_id": "66007140",
      "localidad_censal_nombre": "Piquete Cabado",
      "municipio_id": "660028",
      "municipio_nombre": "Joaquín V. González",
      "nombre": "PIQUETE CABADO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6839685116395,
      "centroide_lon": -64.2017888257331,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007150000",
      "localidad_censal_id": "66007150",
      "localidad_censal_nombre": "Río del Valle",
      "municipio_id": "660035",
      "municipio_nombre": "Las Lajitas",
      "nombre": "RIO DEL VALLE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5466456710139,
      "centroide_lon": -63.5342918601747,
      "departamento_id": "66007",
      "departamento_nombre": "Anta",
      "id": "66007160000",
      "localidad_censal_id": "66007160",
      "localidad_censal_nombre": "Tolloche",
      "municipio_id": "660014",
      "municipio_nombre": "El Quebrachal",
      "nombre": "TOLLOCHE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1201640072396,
      "centroide_lon": -66.1679494412163,
      "departamento_id": "66014",
      "departamento_nombre": "Cachi",
      "id": "66014010000",
      "localidad_censal_id": "66014010",
      "localidad_censal_nombre": "Cachi",
      "municipio_id": "660042",
      "municipio_nombre": "Cachi",
      "nombre": "CACHI",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0489479804199,
      "centroide_lon": -66.1027102397315,
      "departamento_id": "66014",
      "departamento_nombre": "Cachi",
      "id": "66014020000",
      "localidad_censal_id": "66014020",
      "localidad_censal_nombre": "Payogasta",
      "municipio_id": "660049",
      "municipio_nombre": "Payogasta",
      "nombre": "PAYOGASTA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0765384358548,
      "centroide_lon": -65.9862897320293,
      "departamento_id": "66021",
      "departamento_nombre": "Cafayate",
      "id": "66021010000",
      "localidad_censal_id": "66021010",
      "localidad_censal_nombre": "Cafayate",
      "municipio_id": "660056",
      "municipio_nombre": "Cafayate",
      "nombre": "CAFAYATE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2029574679842,
      "centroide_lon": -65.9467957704698,
      "departamento_id": "66021",
      "departamento_nombre": "Cafayate",
      "id": "66021020000",
      "localidad_censal_id": "66021020",
      "localidad_censal_nombre": "Tolombón",
      "municipio_id": "660056",
      "municipio_nombre": "Cafayate",
      "nombre": "TOLOMBOM",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.8166716062155,
      "centroide_lon": -65.4787180407276,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028010000",
      "localidad_censal_id": "66028010",
      "localidad_censal_nombre": "Atocha",
      "municipio_id": "660070",
      "municipio_nombre": "San Lorenzo",
      "nombre": "ATOCHA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8141965630247,
      "centroide_lon": -65.4579729482216,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028030001",
      "localidad_censal_id": "66028030",
      "localidad_censal_nombre": "La Ciénaga y Barrio San Rafael",
      "municipio_id": "660070",
      "municipio_nombre": "San Lorenzo",
      "nombre": "BARRIO SAN RAFAEL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8086153228208,
      "centroide_lon": -65.5127341551445,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028030002",
      "localidad_censal_id": "66028030",
      "localidad_censal_nombre": "La Ciénaga y Barrio San Rafael",
      "municipio_id": "660070",
      "municipio_nombre": "San Lorenzo",
      "nombre": "LA CIENAGA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7690498139422,
      "centroide_lon": -65.4860333610823,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028040000",
      "localidad_censal_id": "66028040",
      "localidad_censal_nombre": "Las Costas",
      "municipio_id": "660070",
      "municipio_nombre": "San Lorenzo",
      "nombre": "LAS COSTAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -24.7823766403156,
      "centroide_lon": -65.4141329991055,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028050000",
      "localidad_censal_id": "66028050",
      "localidad_censal_nombre": "Salta",
      "municipio_id": "660063",
      "municipio_nombre": "Salta",
      "nombre": "SALTA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.7656882287643,
      "centroide_lon": -65.4762993863652,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028050001",
      "localidad_censal_id": "66028050",
      "localidad_censal_nombre": "Salta",
      "municipio_id": "660063",
      "municipio_nombre": "Salta",
      "nombre": "CC EL TIPAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.763544418349,
      "centroide_lon": -65.4639762070424,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028050002",
      "localidad_censal_id": "66028050",
      "localidad_censal_nombre": "Salta",
      "municipio_id": "660063",
      "municipio_nombre": "Salta",
      "nombre": "CC LA ALMUDENA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.7865548806711,
      "centroide_lon": -65.3976354202705,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028050003",
      "localidad_censal_id": "66028050",
      "localidad_censal_nombre": "Salta",
      "municipio_id": "660063",
      "municipio_nombre": "Salta",
      "nombre": "SALTA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7333009865645,
      "centroide_lon": -65.4847891426227,
      "departamento_id": "66028",
      "departamento_nombre": "Capital",
      "id": "66028060000",
      "localidad_censal_id": "66028060",
      "localidad_censal_nombre": "Villa San Lorenzo",
      "municipio_id": "660070",
      "municipio_nombre": "San Lorenzo",
      "nombre": "VILLA SAN LORENZO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.899748268594,
      "centroide_lon": -65.4884058419647,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035010000",
      "localidad_censal_id": "66035010",
      "localidad_censal_nombre": "Cerrillos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "CERRILLOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9660896493444,
      "centroide_lon": -65.4895901036815,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035020000",
      "localidad_censal_id": "66035020",
      "localidad_censal_nombre": "La Merced",
      "municipio_id": "660084",
      "municipio_nombre": "La Merced",
      "nombre": "LA MERCED",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9967896088099,
      "centroide_lon": -65.4377599331623,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035030000",
      "localidad_censal_id": "66035030",
      "localidad_censal_nombre": "San Agustín",
      "municipio_id": "660084",
      "municipio_nombre": "La Merced",
      "nombre": "SAN AGUSTIN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -24.8630942974132,
      "centroide_lon": -65.4594268472934,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040000",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "VILLA LOS ALAMOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8774314408788,
      "centroide_lon": -65.478464215737,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040001",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "BARRIO EL CONGRESO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8581686105385,
      "centroide_lon": -65.3991341395925,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040002",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "BARRIO LAS TUNAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8784306645047,
      "centroide_lon": -65.466289926351,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040003",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "BARRIO LOS OLMOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8581686105385,
      "centroide_lon": -65.3991341395925,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040004",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "BARRIO LOS PINARES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -24.8640287340554,
      "centroide_lon": -65.4641823560161,
      "departamento_id": "66035",
      "departamento_nombre": "Cerrillos",
      "id": "66035040005",
      "localidad_censal_id": "66035040",
      "localidad_censal_nombre": "Villa Los Álamos - El Congreso - Las Tunas - Los Pinares - Los Olmos",
      "municipio_id": "660077",
      "municipio_nombre": "Cerrillos",
      "nombre": "VILLA LOS ALAMOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -25.1512347050013,
      "centroide_lon": -65.4426106862489,
      "departamento_id": "66042",
      "departamento_nombre": "Chicoana",
      "id": "66042003000",
      "localidad_censal_id": "66042003",
      "localidad_censal_nombre": "Barrio Finca La Maroma",
      "municipio_id": "660091",
      "municipio_nombre": "Chicoana",
      "nombre": "LA MAROMA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0825653440585,
      "centroide_lon": -65.5369944152078,
      "departamento_id": "66042",
      "departamento_nombre": "Chicoana",
      "id": "66042005000",
      "localidad_censal_id": "66042005",
      "localidad_censal_nombre": "Barrio La Rotonda",
      "municipio_id": "660091",
      "municipio_nombre": "Chicoana",
      "nombre": "BARRIO LA ROTONDA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0370260259242,
      "centroide_lon": -65.5726459572587,
      "departamento_id": "66042",
      "departamento_nombre": "Chicoana",
      "id": "66042007000",
      "localidad_censal_id": "66042007",
      "localidad_censal_nombre": "Barrio Santa Teresita",
      "municipio_id": "660091",
      "municipio_nombre": "Chicoana",
      "nombre": "BARRIO SANTA TERESITA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.1056023710125,
      "centroide_lon": -65.5368903535961,
      "departamento_id": "66042",
      "departamento_nombre": "Chicoana",
      "id": "66042010000",
      "localidad_censal_id": "66042010",
      "localidad_censal_nombre": "Chicoana",
      "municipio_id": "660091",
      "municipio_nombre": "Chicoana",
      "nombre": "CHICOANA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.0768547028405,
      "centroide_lon": -65.4938473919709,
      "departamento_id": "66042",
      "departamento_nombre": "Chicoana",
      "id": "66042020000",
      "localidad_censal_id": "66042020",
      "localidad_censal_nombre": "El Carril",
      "municipio_id": "660098",
      "municipio_nombre": "El Carril",
      "nombre": "EL CARRIL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6839924533229,
      "centroide_lon": -65.102855681603,
      "departamento_id": "66049",
      "departamento_nombre": "General Güemes",
      "id": "66049010000",
      "localidad_censal_id": "66049010",
      "localidad_censal_nombre": "Campo Santo",
      "municipio_id": "660105",
      "municipio_nombre": "Campo Santo",
      "nombre": "CAMPO SANTO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7414709076566,
      "centroide_lon": -65.0839324652619,
      "departamento_id": "66049",
      "departamento_nombre": "General Güemes",
      "id": "66049020000",
      "localidad_censal_id": "66049020",
      "localidad_censal_nombre": "Cobos",
      "municipio_id": "660105",
      "municipio_nombre": "Campo Santo",
      "nombre": "COBOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6610743291372,
      "centroide_lon": -65.1054298866391,
      "departamento_id": "66049",
      "departamento_nombre": "General Güemes",
      "id": "66049030000",
      "localidad_censal_id": "66049030",
      "localidad_censal_nombre": "El Bordo",
      "municipio_id": "660112",
      "municipio_nombre": "El Bordo",
      "nombre": "EL BORDO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6684436198384,
      "centroide_lon": -65.0493527755239,
      "departamento_id": "66049",
      "departamento_nombre": "General Güemes",
      "id": "66049040000",
      "localidad_censal_id": "66049040",
      "localidad_censal_nombre": "General Güemes",
      "municipio_id": "660119",
      "municipio_nombre": "General Güemes",
      "nombre": "GENERAL GUEMES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2388143093458,
      "centroide_lon": -63.7283889210813,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056010000",
      "localidad_censal_id": "66056010",
      "localidad_censal_nombre": "Aguaray",
      "municipio_id": "660126",
      "municipio_nombre": "Aguaray",
      "nombre": "AGUARAY",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5800471662206,
      "centroide_lon": -63.8523231844895,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056020000",
      "localidad_censal_id": "66056020",
      "localidad_censal_nombre": "Campamento Vespucio",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "CAMPAMENTO VESPUCIO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1062412034464,
      "centroide_lon": -63.9953139246427,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056030000",
      "localidad_censal_id": "66056030",
      "localidad_censal_nombre": "Campichuelo",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "CAMPICHUELO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1925191184959,
      "centroide_lon": -63.6556166497527,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056040000",
      "localidad_censal_id": "66056040",
      "localidad_censal_nombre": "Campo Durán",
      "municipio_id": "660154",
      "municipio_nombre": "Profesor Salvador Mazza",
      "nombre": "CAMPO DURAN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1673388747523,
      "centroide_lon": -63.7086209918975,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056050000",
      "localidad_censal_id": "66056050",
      "localidad_censal_nombre": "Capiazuti",
      "municipio_id": "660126",
      "municipio_nombre": "Aguaray",
      "nombre": "CAPIAZUTI",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2595016179972,
      "centroide_lon": -63.8026685275306,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056060000",
      "localidad_censal_id": "66056060",
      "localidad_censal_nombre": "Carboncito",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "CARBONCITO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.736606161274,
      "centroide_lon": -63.8212340428559,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056070000",
      "localidad_censal_id": "66056070",
      "localidad_censal_nombre": "Coronel Cornejo",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "CORONEL CORNEJO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2581867482105,
      "centroide_lon": -63.3390555232417,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056080000",
      "localidad_censal_id": "66056080",
      "localidad_censal_nombre": "Dragones",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "DRAGONES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -23.2042135269827,
      "centroide_lon": -64.0900817936338,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056090000",
      "localidad_censal_id": "66056090",
      "localidad_censal_nombre": "Embarcación",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "EMBARCACION",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.2350868335657,
      "centroide_lon": -64.0122926146674,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056090001",
      "localidad_censal_id": "66056090",
      "localidad_censal_nombre": "Embarcación",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "EMBARCACION",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.2644560588445,
      "centroide_lon": -64.0052653883187,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056090002",
      "localidad_censal_id": "66056090",
      "localidad_censal_nombre": "Embarcación",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "MISION TIERRAS FISCALES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.9276105225425,
      "centroide_lon": -63.8522934424036,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056100000",
      "localidad_censal_id": "66056100",
      "localidad_censal_nombre": "General Ballivián",
      "municipio_id": "660140",
      "municipio_nombre": "General Ballivián",
      "nombre": "GENERAL BALLIVIAN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -22.5872984531874,
      "centroide_lon": -63.8075098662841,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056110000",
      "localidad_censal_id": "66056110",
      "localidad_censal_nombre": "General Mosconi",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "GENERAL MOSCONI",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.5888626468449,
      "centroide_lon": -63.8118898123133,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056110001",
      "localidad_censal_id": "66056110",
      "localidad_censal_nombre": "General Mosconi",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "GENERAL MOSCONI",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.4497652091167,
      "centroide_lon": -63.8856192155364,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056110002",
      "localidad_censal_id": "66056110",
      "localidad_censal_nombre": "General Mosconi",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "RECAREDO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2174884897188,
      "centroide_lon": -63.5643624737058,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056120000",
      "localidad_censal_id": "66056120",
      "localidad_censal_nombre": "Hickman",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "HICKMAN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2761412353834,
      "centroide_lon": -63.7361800240654,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056130000",
      "localidad_censal_id": "66056130",
      "localidad_censal_nombre": "Misión Chaqueña",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "MISION CHAQUEÑA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.5413249097327,
      "centroide_lon": -63.7927888759365,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056140001",
      "localidad_censal_id": "66056140",
      "localidad_censal_nombre": "Misión El Cruce - El Milagro - El Jardín de San Martín",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "BARRIO EL MILAGRO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.4497652091167,
      "centroide_lon": -63.8856192155364,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056140002",
      "localidad_censal_id": "66056140",
      "localidad_censal_nombre": "Misión El Cruce - El Milagro - El Jardín de San Martín",
      "municipio_id": "660147",
      "municipio_nombre": "General Mosconi",
      "nombre": "BARRIO EL JARDIN DE SAN MARTIN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -22.5413249097327,
      "centroide_lon": -63.7927888759365,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056140003",
      "localidad_censal_id": "66056140",
      "localidad_censal_nombre": "Misión El Cruce - El Milagro - El Jardín de San Martín",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "MISION EL CRUCE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.5060228278102,
      "centroide_lon": -63.7399721901134,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056150000",
      "localidad_censal_id": "66056150",
      "localidad_censal_nombre": "Misión Kilómetro 6",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "MISION KILOMETRO 6",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4452723425454,
      "centroide_lon": -63.43543212618,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056170000",
      "localidad_censal_id": "66056170",
      "localidad_censal_nombre": "Pacará",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "PACARA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.215724803759,
      "centroide_lon": -63.8426467906727,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056180000",
      "localidad_censal_id": "66056180",
      "localidad_censal_nombre": "Padre Lozano",
      "municipio_id": "660133",
      "municipio_nombre": "Embarcación",
      "nombre": "PADRE LOZANO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.3335490812005,
      "centroide_lon": -63.7596847135861,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056190000",
      "localidad_censal_id": "66056190",
      "localidad_censal_nombre": "Piquirenda",
      "municipio_id": "660126",
      "municipio_nombre": "Aguaray",
      "nombre": "PIQUIRENDA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.0542634419098,
      "centroide_lon": -63.6872829649674,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056200000",
      "localidad_censal_id": "66056200",
      "localidad_censal_nombre": "Profesor Salvador Mazza",
      "municipio_id": "660154",
      "municipio_nombre": "Profesor Salvador Mazza",
      "nombre": "PROFESOR SALVADOR MAZZA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -22.5098645099451,
      "centroide_lon": -63.7970472996098,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056220000",
      "localidad_censal_id": "66056220",
      "localidad_censal_nombre": "Tartagal",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "TARTAGAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.13833247169,
      "centroide_lon": -63.7061819675909,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056230000",
      "localidad_censal_id": "66056230",
      "localidad_censal_nombre": "Tobantirenda",
      "municipio_id": "660126",
      "municipio_nombre": "Aguaray",
      "nombre": "TOBANTIRENDA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4076174158757,
      "centroide_lon": -63.7681399387898,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056240000",
      "localidad_censal_id": "66056240",
      "localidad_censal_nombre": "Tranquitas",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "TRANQUITAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.3765769868638,
      "centroide_lon": -63.7654304903786,
      "departamento_id": "66056",
      "departamento_nombre": "General José de San Martín",
      "id": "66056250000",
      "localidad_censal_id": "66056250",
      "localidad_censal_nombre": "Yacuy",
      "municipio_id": "660161",
      "municipio_nombre": "Tartagal",
      "nombre": "YACUY",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5235484724461,
      "centroide_lon": -65.5187644501144,
      "departamento_id": "66063",
      "departamento_nombre": "Guachipas",
      "id": "66063010000",
      "localidad_censal_id": "66063010",
      "localidad_censal_nombre": "Guachipas",
      "municipio_id": "660168",
      "municipio_nombre": "Guachipas",
      "nombre": "GUACHIPAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.7789158765788,
      "centroide_lon": -65.2063598136374,
      "departamento_id": "66070",
      "departamento_nombre": "Iruya",
      "id": "66070010000",
      "localidad_censal_id": "66070010",
      "localidad_censal_nombre": "Iruya",
      "municipio_id": "660175",
      "municipio_nombre": "Iruya",
      "nombre": "IRUYA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.8864200252899,
      "centroide_lon": -64.6573757192202,
      "departamento_id": "66070",
      "departamento_nombre": "Iruya",
      "id": "66070020000",
      "localidad_censal_id": "66070020",
      "localidad_censal_nombre": "Isla de Cañas",
      "municipio_id": "660182",
      "municipio_nombre": "Isla de Cañas",
      "nombre": "ISLA DE CAÑAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.8263430234688,
      "centroide_lon": -65.2018356755618,
      "departamento_id": "66070",
      "departamento_nombre": "Iruya",
      "id": "66070030000",
      "localidad_censal_id": "66070030",
      "localidad_censal_nombre": "Pueblo Viejo",
      "municipio_id": "660175",
      "municipio_nombre": "Iruya",
      "nombre": "PUEBLO VIEJO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.6049486234217,
      "centroide_lon": -65.3823377302988,
      "departamento_id": "66077",
      "departamento_nombre": "La Caldera",
      "id": "66077010000",
      "localidad_censal_id": "66077010",
      "localidad_censal_nombre": "La Caldera",
      "municipio_id": "660189",
      "municipio_nombre": "La Caldera",
      "nombre": "LA CALDERA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.6945211920197,
      "centroide_lon": -65.4026944187147,
      "departamento_id": "66077",
      "departamento_nombre": "La Caldera",
      "id": "66077020000",
      "localidad_censal_id": "66077020",
      "localidad_censal_nombre": "Vaqueros",
      "municipio_id": "660196",
      "municipio_nombre": "Vaqueros",
      "nombre": "VAQUEROS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0938834199791,
      "centroide_lon": -65.41384156239,
      "departamento_id": "66084",
      "departamento_nombre": "La Candelaria",
      "id": "66084010000",
      "localidad_censal_id": "66084010",
      "localidad_censal_nombre": "El Jardín",
      "municipio_id": "660203",
      "municipio_nombre": "El Jardín",
      "nombre": "EL JARDIN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1208917578309,
      "centroide_lon": -65.2873058787689,
      "departamento_id": "66084",
      "departamento_nombre": "La Candelaria",
      "id": "66084020000",
      "localidad_censal_id": "66084020",
      "localidad_censal_nombre": "El Tala",
      "municipio_id": "660210",
      "municipio_nombre": "El Tala",
      "nombre": "EL TALA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0961790089843,
      "centroide_lon": -65.0610119525095,
      "departamento_id": "66084",
      "departamento_nombre": "La Candelaria",
      "id": "66084030000",
      "localidad_censal_id": "66084030",
      "localidad_censal_nombre": "La Candelaria",
      "municipio_id": "660217",
      "municipio_nombre": "La Candelaria",
      "nombre": "LA CANDELARIA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.6391998500842,
      "centroide_lon": -66.2684039729566,
      "departamento_id": "66091",
      "departamento_nombre": "La Poma",
      "id": "66091010000",
      "localidad_censal_id": "66091010",
      "localidad_censal_nombre": "Cobres",
      "municipio_id": "660224",
      "municipio_nombre": "La Poma",
      "nombre": "COBRES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.7129010493055,
      "centroide_lon": -66.1997385176463,
      "departamento_id": "66091",
      "departamento_nombre": "La Poma",
      "id": "66091020000",
      "localidad_censal_id": "66091020",
      "localidad_censal_nombre": "La Poma",
      "municipio_id": "660224",
      "municipio_nombre": "La Poma",
      "nombre": "LA POMA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.355455514438,
      "centroide_lon": -65.5323044668329,
      "departamento_id": "66098",
      "departamento_nombre": "La Viña",
      "id": "66098010000",
      "localidad_censal_id": "66098010",
      "localidad_censal_nombre": "Ampascachi",
      "municipio_id": "660238",
      "municipio_nombre": "La Viña",
      "nombre": "AMPASCACHI",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.290036707535,
      "centroide_lon": -65.38778537012,
      "departamento_id": "66098",
      "departamento_nombre": "La Viña",
      "id": "66098020000",
      "localidad_censal_id": "66098020",
      "localidad_censal_nombre": "Cabra Corral",
      "municipio_id": "660168",
      "municipio_nombre": "Guachipas",
      "nombre": "CABRA CORRAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2889404681421,
      "centroide_lon": -65.4745373510335,
      "departamento_id": "66098",
      "departamento_nombre": "La Viña",
      "id": "66098030000",
      "localidad_censal_id": "66098030",
      "localidad_censal_nombre": "Coronel Moldes",
      "municipio_id": "660231",
      "municipio_nombre": "Coronel Moldes",
      "nombre": "CORONEL MOLDES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.4719724619835,
      "centroide_lon": -65.5719838890076,
      "departamento_id": "66098",
      "departamento_nombre": "La Viña",
      "id": "66098040000",
      "localidad_censal_id": "66098040",
      "localidad_censal_nombre": "La Viña",
      "municipio_id": "660238",
      "municipio_nombre": "La Viña",
      "nombre": "LA VIÑA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5463746287562,
      "centroide_lon": -65.559672881754,
      "departamento_id": "66098",
      "departamento_nombre": "La Viña",
      "id": "66098050000",
      "localidad_censal_id": "66098050",
      "localidad_censal_nombre": "Talapampa",
      "municipio_id": "660238",
      "municipio_nombre": "La Viña",
      "nombre": "TALAPAMPA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1197745515994,
      "centroide_lon": -66.7142594000385,
      "departamento_id": "66105",
      "departamento_nombre": "Los Andes",
      "id": "66105010000",
      "localidad_censal_id": "66105010",
      "localidad_censal_nombre": "Olacapato",
      "municipio_id": "386168",
      "municipio_nombre": "Susques",
      "nombre": "OLACAPATO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.2099310029638,
      "centroide_lon": -66.315435998094,
      "departamento_id": "66105",
      "departamento_nombre": "Los Andes",
      "id": "66105020000",
      "localidad_censal_id": "66105020",
      "localidad_censal_nombre": "San Antonio de los Cobres",
      "municipio_id": "660245",
      "municipio_nombre": "San Antonio de los Cobres",
      "nombre": "SAN ANTONIO DE LOS COBRES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.478105777316,
      "centroide_lon": -66.6785400667592,
      "departamento_id": "66105",
      "departamento_nombre": "Los Andes",
      "id": "66105030000",
      "localidad_censal_id": "66105030",
      "localidad_censal_nombre": "Santa Rosa de los Pastos Grandes",
      "municipio_id": "660245",
      "municipio_nombre": "San Antonio de los Cobres",
      "nombre": "SANTA ROSA DE LOS PASTOS GRANDES",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.5579851944765,
      "centroide_lon": -67.4369653675359,
      "departamento_id": "66105",
      "departamento_nombre": "Los Andes",
      "id": "66105040000",
      "localidad_censal_id": "66105040",
      "localidad_censal_nombre": "Tolar Grande",
      "municipio_id": "660252",
      "municipio_nombre": "Tolar Grande",
      "nombre": "TOLAR GRANDE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3915864503967,
      "centroide_lon": -64.6595062933993,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112010000",
      "localidad_censal_id": "66112010",
      "localidad_censal_nombre": "El Galpón",
      "municipio_id": "660259",
      "municipio_nombre": "El Galpón",
      "nombre": "EL GALPON",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2652645771513,
      "centroide_lon": -64.4061042227155,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112020000",
      "localidad_censal_id": "66112020",
      "localidad_censal_nombre": "El Tunal",
      "municipio_id": "660259",
      "municipio_nombre": "El Galpón",
      "nombre": "EL TUNAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2172575790264,
      "centroide_lon": -64.9306861458005,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112030000",
      "localidad_censal_id": "66112030",
      "localidad_censal_nombre": "Lumbreras",
      "municipio_id": "660280",
      "municipio_nombre": "Río Piedras",
      "nombre": "LUMBRERAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5077205377148,
      "centroide_lon": -64.9821245409304,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112040000",
      "localidad_censal_id": "66112040",
      "localidad_censal_nombre": "San José de Metán (Est. Metán)",
      "municipio_id": "660266",
      "municipio_nombre": "Metán",
      "nombre": "SAN JOSE DE METAN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.5418039517985,
      "centroide_lon": -64.9849385087879,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112050000",
      "localidad_censal_id": "66112050",
      "localidad_censal_nombre": "Metán Viejo",
      "municipio_id": "660266",
      "municipio_nombre": "Metán",
      "nombre": "METAN VIEJO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3211818761179,
      "centroide_lon": -64.917329424853,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112070000",
      "localidad_censal_id": "66112070",
      "localidad_censal_nombre": "Río Piedras",
      "municipio_id": "660280",
      "municipio_nombre": "Río Piedras",
      "nombre": "RIO PIEDRAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2783094165373,
      "centroide_lon": -64.0850561648465,
      "departamento_id": "66112",
      "departamento_nombre": "Metán",
      "id": "66112080000",
      "localidad_censal_id": "66112080",
      "localidad_censal_nombre": "San José de Orquera",
      "municipio_id": "660259",
      "municipio_nombre": "El Galpón",
      "nombre": "SAN JOSE DE ORQUERA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.2804538149509,
      "centroide_lon": -66.4508396566503,
      "departamento_id": "66119",
      "departamento_nombre": "Molinos",
      "id": "66119010000",
      "localidad_censal_id": "66119010",
      "localidad_censal_nombre": "La Puerta",
      "municipio_id": "660273",
      "municipio_nombre": "Molinos",
      "nombre": "LA PUERTA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.4442432937572,
      "centroide_lon": -66.3088500894253,
      "departamento_id": "66119",
      "departamento_nombre": "Molinos",
      "id": "66119020000",
      "localidad_censal_id": "66119020",
      "localidad_censal_nombre": "Molinos",
      "municipio_id": "660273",
      "municipio_nombre": "Molinos",
      "nombre": "MOLINOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.3305099574445,
      "centroide_lon": -66.2484466228606,
      "departamento_id": "66119",
      "departamento_nombre": "Molinos",
      "id": "66119030000",
      "localidad_censal_id": "66119030",
      "localidad_censal_nombre": "Seclantás",
      "municipio_id": "660287",
      "municipio_nombre": "Seclantás",
      "nombre": "SECLANTAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.7245611902241,
      "centroide_lon": -64.346319220401,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126010000",
      "localidad_censal_id": "66126010",
      "localidad_censal_nombre": "Aguas Blancas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "AGUAS BLANCAS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -23.3885957203176,
      "centroide_lon": -64.4234333229489,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126020000",
      "localidad_censal_id": "66126020",
      "localidad_censal_nombre": "Colonia Santa Rosa",
      "municipio_id": "660294",
      "municipio_nombre": "Colonia Santa Rosa",
      "nombre": "COLONIA SANTA ROSA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.3664492093477,
      "centroide_lon": -64.3524071790769,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126020001",
      "localidad_censal_id": "66126020",
      "localidad_censal_nombre": "Colonia Santa Rosa",
      "municipio_id": "660294",
      "municipio_nombre": "Colonia Santa Rosa",
      "nombre": "COLONIA SANTA ROSA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -23.3154629033465,
      "centroide_lon": -64.4077092056574,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126020002",
      "localidad_censal_id": "66126020",
      "localidad_censal_nombre": "Colonia Santa Rosa",
      "municipio_id": "660294",
      "municipio_nombre": "Colonia Santa Rosa",
      "nombre": "LA MISION",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2500270361093,
      "centroide_lon": -64.2445058938392,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126030000",
      "localidad_censal_id": "66126030",
      "localidad_censal_nombre": "El Tabacal",
      "municipio_id": "660301",
      "municipio_nombre": "Hipólito Yrigoyen",
      "nombre": "EL TABACAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.2382702479068,
      "centroide_lon": -64.2718125360107,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126040000",
      "localidad_censal_id": "66126040",
      "localidad_censal_nombre": "Hipólito Yrigoyen",
      "municipio_id": "660301",
      "municipio_nombre": "Hipólito Yrigoyen",
      "nombre": "HIPOLITO YRIGOYEN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3133896525098,
      "centroide_lon": -64.219751841333,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126060000",
      "localidad_censal_id": "66126060",
      "localidad_censal_nombre": "Pichanal",
      "municipio_id": "660308",
      "municipio_nombre": "Pichanal",
      "nombre": "PICHANAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.1298138873917,
      "centroide_lon": -64.3185884747041,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126070000",
      "localidad_censal_id": "66126070",
      "localidad_censal_nombre": "San Ramón de la Nueva Orán",
      "municipio_id": "660315",
      "municipio_nombre": "San Ramón de la Nueva Orán",
      "nombre": "SAN RAMON DE LA NUEVA ORAN",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.5516314679633,
      "centroide_lon": -64.3965188048038,
      "departamento_id": "66126",
      "departamento_nombre": "Orán",
      "id": "66126080000",
      "localidad_censal_id": "66126080",
      "localidad_censal_nombre": "Urundel",
      "municipio_id": "660322",
      "municipio_nombre": "Urundel",
      "nombre": "URUNDEL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.6893772924132,
      "centroide_lon": -62.4527756437584,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133010000",
      "localidad_censal_id": "66133010",
      "localidad_censal_nombre": "Alto de la Sierra",
      "municipio_id": "660343",
      "municipio_nombre": "Santa Victoria Este",
      "nombre": "ALTO DE LA SIERRA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.7084876711103,
      "centroide_lon": -62.3817949445121,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133020000",
      "localidad_censal_id": "66133020",
      "localidad_censal_nombre": "Capitán Juan Pagé",
      "municipio_id": "660329",
      "municipio_nombre": "Rivadavia Banda Norte",
      "nombre": "CAPITAN JUAN PAGE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.4836432078231,
      "centroide_lon": -62.8913108733338,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133030000",
      "localidad_censal_id": "66133030",
      "localidad_censal_nombre": "Coronel Juan Solá",
      "municipio_id": "660329",
      "municipio_nombre": "Rivadavia Banda Norte",
      "nombre": "CORONEL JUAN SOLA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -21.9997109682413,
      "centroide_lon": -62.8237382800005,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133035000",
      "localidad_censal_id": "66133035",
      "localidad_censal_nombre": "Hito 1",
      "municipio_id": "660343",
      "municipio_nombre": "Santa Victoria Este",
      "nombre": "HITO 1",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.9462046990241,
      "centroide_lon": -63.1119727442095,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133040000",
      "localidad_censal_id": "66133040",
      "localidad_censal_nombre": "La Unión",
      "municipio_id": "660336",
      "municipio_nombre": "Rivadavia Banda Sur",
      "nombre": "LA UNION",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.630147809494,
      "centroide_lon": -62.5972373009179,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133050000",
      "localidad_censal_id": "66133050",
      "localidad_censal_nombre": "Los Blancos",
      "municipio_id": "660329",
      "municipio_nombre": "Rivadavia Banda Norte",
      "nombre": "LOS BLANCOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -23.3808426324425,
      "centroide_lon": -63.0980556496311,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133060000",
      "localidad_censal_id": "66133060",
      "localidad_censal_nombre": "Pluma de Pato",
      "municipio_id": "660329",
      "municipio_nombre": "Rivadavia Banda Norte",
      "nombre": "PLUMA DE PATO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.1928479299753,
      "centroide_lon": -62.8855846188739,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133070000",
      "localidad_censal_id": "66133070",
      "localidad_censal_nombre": "Rivadavia",
      "municipio_id": "660336",
      "municipio_nombre": "Rivadavia Banda Sur",
      "nombre": "RIVADAVIA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.1394975256357,
      "centroide_lon": -62.8383245890232,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133080000",
      "localidad_censal_id": "66133080",
      "localidad_censal_nombre": "Santa María",
      "municipio_id": "660343",
      "municipio_nombre": "Santa Victoria Este",
      "nombre": "SANTA MARIA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.0763765853123,
      "centroide_lon": -63.1236773681358,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133090000",
      "localidad_censal_id": "66133090",
      "localidad_censal_nombre": "Santa Rosa",
      "municipio_id": "660336",
      "municipio_nombre": "Rivadavia Banda Sur",
      "nombre": "SANTA ROSA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2772903307188,
      "centroide_lon": -62.7043719454645,
      "departamento_id": "66133",
      "departamento_nombre": "Rivadavia",
      "id": "66133100000",
      "localidad_censal_id": "66133100",
      "localidad_censal_nombre": "Santa Victoria Este",
      "municipio_id": "660343",
      "municipio_nombre": "Santa Victoria Este",
      "nombre": "SANTA VICTORIA ESTE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1367474431656,
      "centroide_lon": -64.6079955159401,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140010000",
      "localidad_censal_id": "66140010",
      "localidad_censal_nombre": "Antillá",
      "municipio_id": "660350",
      "municipio_nombre": "El Potrero",
      "nombre": "ANTILLA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0280256948964,
      "centroide_lon": -64.6831914609089,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140020000",
      "localidad_censal_id": "66140020",
      "localidad_censal_nombre": "Copo Quile",
      "municipio_id": "660350",
      "municipio_nombre": "El Potrero",
      "nombre": "COPO QUILE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7376092528727,
      "centroide_lon": -65.0197887857072,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140030000",
      "localidad_censal_id": "66140030",
      "localidad_censal_nombre": "El Naranjo",
      "municipio_id": "660357",
      "municipio_nombre": "Rosario de la Frontera",
      "nombre": "EL NARANJO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0638952332339,
      "centroide_lon": -64.656831424558,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140040000",
      "localidad_censal_id": "66140040",
      "localidad_censal_nombre": "El Potrero",
      "municipio_id": "660350",
      "municipio_nombre": "El Potrero",
      "nombre": "EL POTRERO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.8088971820865,
      "centroide_lon": -64.9840618242784,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140050000",
      "localidad_censal_id": "66140050",
      "localidad_censal_nombre": "Rosario de la Frontera",
      "municipio_id": "660357",
      "municipio_nombre": "Rosario de la Frontera",
      "nombre": "ROSARIO DE LA FRONTERA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7231125127283,
      "centroide_lon": -64.8260626677768,
      "departamento_id": "66140",
      "departamento_nombre": "Rosario de la Frontera",
      "id": "66140060000",
      "localidad_censal_id": "66140060",
      "localidad_censal_nombre": "San Felipe",
      "municipio_id": "660357",
      "municipio_nombre": "Rosario de la Frontera",
      "nombre": "SAN FELIPE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9095603902622,
      "centroide_lon": -65.6395050258431,
      "departamento_id": "66147",
      "departamento_nombre": "Rosario de Lerma",
      "id": "66147010000",
      "localidad_censal_id": "66147010",
      "localidad_censal_nombre": "Campo Quijano",
      "municipio_id": "660364",
      "municipio_nombre": "Campo Quijano",
      "nombre": "CAMPO QUIJANO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -24.8709646124106,
      "centroide_lon": -65.5607604731238,
      "departamento_id": "66147",
      "departamento_nombre": "Rosario de Lerma",
      "id": "66147015000",
      "localidad_censal_id": "66147015",
      "localidad_censal_nombre": "La Merced del Encón",
      "municipio_id": "660371",
      "municipio_nombre": "Rosario de Lerma",
      "nombre": "LA MERCED DEL ENCON",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.8784643256455,
      "centroide_lon": -65.5901794974296,
      "departamento_id": "66147",
      "departamento_nombre": "Rosario de Lerma",
      "id": "66147020000",
      "localidad_censal_id": "66147020",
      "localidad_censal_nombre": "La Silleta",
      "municipio_id": "660364",
      "municipio_nombre": "Campo Quijano",
      "nombre": "LA SILLETA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.9780005506773,
      "centroide_lon": -65.5804316181731,
      "departamento_id": "66147",
      "departamento_nombre": "Rosario de Lerma",
      "id": "66147030000",
      "localidad_censal_id": "66147030",
      "localidad_censal_nombre": "Rosario de Lerma",
      "municipio_id": "660371",
      "municipio_nombre": "Rosario de Lerma",
      "nombre": "ROSARIO DE LERMA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.6837320095857,
      "centroide_lon": -66.1630084648275,
      "departamento_id": "66154",
      "departamento_nombre": "San Carlos",
      "id": "66154010000",
      "localidad_censal_id": "66154010",
      "localidad_censal_nombre": "Angastaco",
      "municipio_id": "660378",
      "municipio_nombre": "Angastaco",
      "nombre": "ANGASTACO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9250695350422,
      "centroide_lon": -65.9634886394429,
      "departamento_id": "66154",
      "departamento_nombre": "San Carlos",
      "id": "66154020000",
      "localidad_censal_id": "66154020",
      "localidad_censal_nombre": "Animaná",
      "municipio_id": "660385",
      "municipio_nombre": "Animaná",
      "nombre": "ANIMANA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9119189620255,
      "centroide_lon": -65.9509642786695,
      "departamento_id": "66154",
      "departamento_nombre": "San Carlos",
      "id": "66154030000",
      "localidad_censal_id": "66154030",
      "localidad_censal_nombre": "El Barrial",
      "municipio_id": "660385",
      "municipio_nombre": "Animaná",
      "nombre": "EL BARRIAL",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.8954496537353,
      "centroide_lon": -65.9374115460195,
      "departamento_id": "66154",
      "departamento_nombre": "San Carlos",
      "id": "66154040000",
      "localidad_censal_id": "66154040",
      "localidad_censal_nombre": "San Carlos",
      "municipio_id": "660392",
      "municipio_nombre": "San Carlos",
      "nombre": "SAN CARLOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2625708057672,
      "centroide_lon": -64.9993425915289,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161010000",
      "localidad_censal_id": "66161010",
      "localidad_censal_nombre": "Acoyte",
      "municipio_id": "660413",
      "municipio_nombre": "Santa Victoria Oeste",
      "nombre": "ACOYTE",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4282266803066,
      "centroide_lon": -65.1454049664521,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161020000",
      "localidad_censal_id": "66161020",
      "localidad_censal_nombre": "Campo La Cruz",
      "municipio_id": "660406",
      "municipio_nombre": "Nazareno",
      "nombre": "CAMPO LA CRUZ",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2528953049178,
      "centroide_lon": -64.6816676603736,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161030000",
      "localidad_censal_id": "66161030",
      "localidad_censal_nombre": "Los Toldos",
      "municipio_id": "660399",
      "municipio_nombre": "Los Toldos",
      "nombre": "LOS TOLDOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4818544506086,
      "centroide_lon": -65.0949733414157,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161040000",
      "localidad_censal_id": "66161040",
      "localidad_censal_nombre": "Nazareno",
      "municipio_id": "660406",
      "municipio_nombre": "Nazareno",
      "nombre": "NAZARENO",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.4336266127546,
      "centroide_lon": -65.0678957138662,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161050000",
      "localidad_censal_id": "66161050",
      "localidad_censal_nombre": "Poscaya",
      "municipio_id": "660406",
      "municipio_nombre": "Nazareno",
      "nombre": "POSCAYA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.50900544813,
      "centroide_lon": -65.1012423839061,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161060000",
      "localidad_censal_id": "66161060",
      "localidad_censal_nombre": "San Marcos",
      "municipio_id": "660406",
      "municipio_nombre": "Nazareno",
      "nombre": "SAN MARCOS",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -22.2294615004585,
      "centroide_lon": -64.9503255468472,
      "departamento_id": "66161",
      "departamento_nombre": "Santa Victoria",
      "id": "66161070000",
      "localidad_censal_id": "66161070",
      "localidad_censal_nombre": "Santa Victoria",
      "municipio_id": "660413",
      "municipio_nombre": "Santa Victoria Oeste",
      "nombre": "SANTA VICTORIA",
      "provincia_id": "66",
      "provincia_nombre": "Salta"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4480943761332,
      "centroide_lon": -68.5425141343624,
      "departamento_id": "70007",
      "departamento_nombre": "Albardón",
      "id": "70007010000",
      "localidad_censal_id": "70007010",
      "localidad_censal_nombre": "El Rincón",
      "municipio_id": "700007",
      "municipio_nombre": "Albardón",
      "nombre": "EL RINCON",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4165329806484,
      "centroide_lon": -68.5316143378439,
      "departamento_id": "70007",
      "departamento_nombre": "Albardón",
      "id": "70007020001",
      "localidad_censal_id": "70007020",
      "localidad_censal_nombre": "Villa General San Martín - Campo Afuera",
      "municipio_id": "700007",
      "municipio_nombre": "Albardón",
      "nombre": "CAMPO AFUERA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4475643930837,
      "centroide_lon": -68.4817213288378,
      "departamento_id": "70007",
      "departamento_nombre": "Albardón",
      "id": "70007020002",
      "localidad_censal_id": "70007020",
      "localidad_censal_nombre": "Villa General San Martín - Campo Afuera",
      "municipio_id": "700007",
      "municipio_nombre": "Albardón",
      "nombre": "LA CAÑADA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.3864085743851,
      "centroide_lon": -68.4572411115084,
      "departamento_id": "70007",
      "departamento_nombre": "Albardón",
      "id": "70007020003",
      "localidad_censal_id": "70007020",
      "localidad_censal_nombre": "Villa General San Martín - Campo Afuera",
      "municipio_id": "700007",
      "municipio_nombre": "Albardón",
      "nombre": "VILLA AMPACAMA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.421071066377,
      "centroide_lon": -68.4907605706737,
      "departamento_id": "70007",
      "departamento_nombre": "Albardón",
      "id": "70007020004",
      "localidad_censal_id": "70007020",
      "localidad_censal_nombre": "Villa General San Martín - Campo Afuera",
      "municipio_id": "700007",
      "municipio_nombre": "Albardón",
      "nombre": "VILLA GENERAL SAN MARTIN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4084472492778,
      "centroide_lon": -68.3999429561259,
      "departamento_id": "70014",
      "departamento_nombre": "Angaco",
      "id": "70014010000",
      "localidad_censal_id": "70014010",
      "localidad_censal_nombre": "Las Tapias",
      "municipio_id": "700014",
      "municipio_nombre": "Angaco",
      "nombre": "LAS TAPIAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4454020414681,
      "centroide_lon": -68.417640160613,
      "departamento_id": "70014",
      "departamento_nombre": "Angaco",
      "id": "70014020001",
      "localidad_censal_id": "70014020",
      "localidad_censal_nombre": "Villa El Salvador - Villa Sefair",
      "municipio_id": "700014",
      "municipio_nombre": "Angaco",
      "nombre": "VILLA EL SALVADOR",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4439397880938,
      "centroide_lon": -68.4217688171581,
      "departamento_id": "70014",
      "departamento_nombre": "Angaco",
      "id": "70014020002",
      "localidad_censal_id": "70014020",
      "localidad_censal_nombre": "Villa El Salvador - Villa Sefair",
      "municipio_id": "700014",
      "municipio_nombre": "Angaco",
      "nombre": "VILLA SEFAIR (TALACASTO)",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.652328851699,
      "centroide_lon": -69.479673849861,
      "departamento_id": "70021",
      "departamento_nombre": "Calingasta",
      "id": "70021010001",
      "localidad_censal_id": "70021010",
      "localidad_censal_nombre": "Barreal - Villa Pituil",
      "municipio_id": "700021",
      "municipio_nombre": "Calingasta",
      "nombre": "BARREAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6528323304108,
      "centroide_lon": -69.4815385910816,
      "departamento_id": "70021",
      "departamento_nombre": "Calingasta",
      "id": "70021010002",
      "localidad_censal_id": "70021010",
      "localidad_censal_nombre": "Barreal - Villa Pituil",
      "municipio_id": "700021",
      "municipio_nombre": "Calingasta",
      "nombre": "VILLA PITUIL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.335410441528,
      "centroide_lon": -69.4273817834109,
      "departamento_id": "70021",
      "departamento_nombre": "Calingasta",
      "id": "70021020000",
      "localidad_censal_id": "70021020",
      "localidad_censal_nombre": "Calingasta",
      "municipio_id": "700021",
      "municipio_nombre": "Calingasta",
      "nombre": "CALINGASTA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.46037726198,
      "centroide_lon": -69.4229130996947,
      "departamento_id": "70021",
      "departamento_nombre": "Calingasta",
      "id": "70021030000",
      "localidad_censal_id": "70021030",
      "localidad_censal_nombre": "Tamberías",
      "municipio_id": "700021",
      "municipio_nombre": "Calingasta",
      "nombre": "TAMBERIAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.5371970378027,
      "centroide_lon": -68.5250183173793,
      "departamento_id": "70028",
      "departamento_nombre": "Capital",
      "id": "70028010000",
      "localidad_censal_id": "70028010",
      "localidad_censal_nombre": "San Juan",
      "municipio_id": "700028",
      "municipio_nombre": "San Juan",
      "nombre": "SAN JUAN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5918363542723,
      "centroide_lon": -67.6623620492559,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035010000",
      "localidad_censal_id": "70035010",
      "localidad_censal_nombre": "Bermejo",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "BERMEJO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6283025583976,
      "centroide_lon": -68.3008272782109,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035015000",
      "localidad_censal_id": "70035015",
      "localidad_censal_nombre": "Barrio Justo P. Castro IV",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "BARRIO JUSTO P. CASTRO IV",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6514787868907,
      "centroide_lon": -68.2821404352408,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035020000",
      "localidad_censal_id": "70035020",
      "localidad_censal_nombre": "Caucete",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "CAUCETE",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6648793452851,
      "centroide_lon": -68.3211580078388,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035030000",
      "localidad_censal_id": "70035030",
      "localidad_censal_nombre": "El Rincón",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "EL RINCON",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6009569892315,
      "centroide_lon": -68.2979743657121,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035040001",
      "localidad_censal_id": "70035040",
      "localidad_censal_nombre": "Las Talas - Los Médanos",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "LAS TALAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6267504756331,
      "centroide_lon": -68.2618585178493,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035040002",
      "localidad_censal_id": "70035040",
      "localidad_censal_nombre": "Las Talas - Los Médanos",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "LOS MEDANOS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4776695630337,
      "centroide_lon": -67.3093651877693,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035050000",
      "localidad_censal_id": "70035050",
      "localidad_censal_nombre": "Marayes",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "MARAYES",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6609347801137,
      "centroide_lon": -68.2204015492999,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035060000",
      "localidad_censal_id": "70035060",
      "localidad_censal_nombre": "Pie de Palo",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "PIE DE PALO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7403744670002,
      "centroide_lon": -67.9885245900589,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035070000",
      "localidad_censal_id": "70035070",
      "localidad_censal_nombre": "Vallecito",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "VALLECITO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6245292071939,
      "centroide_lon": -68.3138150331219,
      "departamento_id": "70035",
      "departamento_nombre": "Caucete",
      "id": "70035080000",
      "localidad_censal_id": "70035080",
      "localidad_censal_nombre": "Villa Independencia",
      "municipio_id": "700035",
      "municipio_nombre": "Caucete",
      "nombre": "VILLA INDEPENDENCIA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4929011532856,
      "centroide_lon": -68.4840477499344,
      "departamento_id": "70042",
      "departamento_nombre": "Chimbas",
      "id": "70042010001",
      "localidad_censal_id": "70042010",
      "localidad_censal_nombre": "Chimbas",
      "municipio_id": "700042",
      "municipio_nombre": "Chimbas",
      "nombre": "CHIMBAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4800263304441,
      "centroide_lon": -68.4821821646732,
      "departamento_id": "70042",
      "departamento_nombre": "Chimbas",
      "id": "70042010002",
      "localidad_censal_id": "70042010",
      "localidad_censal_nombre": "Chimbas",
      "municipio_id": "700042",
      "municipio_nombre": "Chimbas",
      "nombre": "EL MOGOTE",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.4936358990356,
      "centroide_lon": -68.5357082138994,
      "departamento_id": "70042",
      "departamento_nombre": "Chimbas",
      "id": "70042010003",
      "localidad_censal_id": "70042010",
      "localidad_censal_nombre": "Chimbas",
      "municipio_id": "700042",
      "municipio_nombre": "Chimbas",
      "nombre": "VILLA PAULA ALBARRACIN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0536455973497,
      "centroide_lon": -69.1717079231376,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049010000",
      "localidad_censal_id": "70049010",
      "localidad_censal_nombre": "Angualasto",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "ANGUALASTO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4401531794669,
      "centroide_lon": -69.2447866603611,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049020000",
      "localidad_censal_id": "70049020",
      "localidad_censal_nombre": "Bella Vista",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "BELLA VISTA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4128218913916,
      "centroide_lon": -69.2051642049627,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049030000",
      "localidad_censal_id": "70049030",
      "localidad_censal_nombre": "Iglesia",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "IGLESIA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3242644504455,
      "centroide_lon": -69.2453069928074,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049040000",
      "localidad_censal_id": "70049040",
      "localidad_censal_nombre": "Las Flores",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "LAS FLORES",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2709800462776,
      "centroide_lon": -69.2286104874715,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049050000",
      "localidad_censal_id": "70049050",
      "localidad_censal_nombre": "Pismanta",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "PISMANTA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.209958054186,
      "centroide_lon": -69.1336132382893,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049060000",
      "localidad_censal_id": "70049060",
      "localidad_censal_nombre": "Rodeo",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "RODEO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1883781131447,
      "centroide_lon": -69.2702835946583,
      "departamento_id": "70049",
      "departamento_nombre": "Iglesia",
      "id": "70049070000",
      "localidad_censal_id": "70049070",
      "localidad_censal_nombre": "Tudcum",
      "municipio_id": "700049",
      "municipio_nombre": "Iglesia",
      "nombre": "TUDCUM",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1293832105379,
      "centroide_lon": -68.6791733044187,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056010000",
      "localidad_censal_id": "70056010",
      "localidad_censal_nombre": "El Médano",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "EL MEDANO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1225273246059,
      "centroide_lon": -68.7161999143951,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056020000",
      "localidad_censal_id": "70056020",
      "localidad_censal_nombre": "Gran China",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "GRAN CHINA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1570667184839,
      "centroide_lon": -68.4809365791381,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056030000",
      "localidad_censal_id": "70056030",
      "localidad_censal_nombre": "Huaco",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "HUACO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6845100664473,
      "centroide_lon": -68.3775543569819,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056040000",
      "localidad_censal_id": "70056040",
      "localidad_censal_nombre": "Mogna",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "MOGNA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4006750071532,
      "centroide_lon": -68.6910630973149,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056050000",
      "localidad_censal_id": "70056050",
      "localidad_censal_nombre": "Niquivil",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "NIQUIVIL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -30.2202750575676,
      "centroide_lon": -68.6909967597496,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056060001",
      "localidad_censal_id": "70056060",
      "localidad_censal_nombre": "Pampa Vieja",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "EL FISCAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -30.1628729670379,
      "centroide_lon": -68.6652633159112,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056060002",
      "localidad_censal_id": "70056060",
      "localidad_censal_nombre": "Pampa Vieja",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "LA FALDA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -30.2202750575676,
      "centroide_lon": -68.6909967597496,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056060003",
      "localidad_censal_id": "70056060",
      "localidad_censal_nombre": "Pampa Vieja",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "PAMPA VIEJA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1488923473271,
      "centroide_lon": -68.7044945534412,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056070000",
      "localidad_censal_id": "70056070",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "SAN ISIDRO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2427672850044,
      "centroide_lon": -68.7454934559357,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056080000",
      "localidad_censal_id": "70056080",
      "localidad_censal_nombre": "San José de Jáchal",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "SAN JOSE DE JACHAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1822864487075,
      "centroide_lon": -68.7277857836372,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056090000",
      "localidad_censal_id": "70056090",
      "localidad_censal_nombre": "Tamberías",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "TAMBERIAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2142413595614,
      "centroide_lon": -68.7165558327867,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056100000",
      "localidad_censal_id": "70056100",
      "localidad_censal_nombre": "Villa Malvinas Argentinas",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "VILLA MALVINAS ARGENTINAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1088721898605,
      "centroide_lon": -68.7009429184891,
      "departamento_id": "70056",
      "departamento_nombre": "Jáchal",
      "id": "70056110000",
      "localidad_censal_id": "70056110",
      "localidad_censal_nombre": "Villa Mercedes",
      "municipio_id": "700056",
      "municipio_nombre": "Jáchal",
      "nombre": "VILLA MERCEDES",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5551785871028,
      "centroide_lon": -68.4205987445022,
      "departamento_id": "70063",
      "departamento_nombre": "9 de Julio",
      "id": "70063020000",
      "localidad_censal_id": "70063020",
      "localidad_censal_nombre": "Colonia Fiorito",
      "municipio_id": "700063",
      "municipio_nombre": "9 de Julio",
      "nombre": "COLONIA FIORITO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5936417922213,
      "centroide_lon": -68.4077196718125,
      "departamento_id": "70063",
      "departamento_nombre": "9 de Julio",
      "id": "70063030000",
      "localidad_censal_id": "70063030",
      "localidad_censal_nombre": "Las Chacritas",
      "municipio_id": "700063",
      "municipio_nombre": "9 de Julio",
      "nombre": "LAS CHACRITAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.669750919971,
      "centroide_lon": -68.3900416378963,
      "departamento_id": "70063",
      "departamento_nombre": "9 de Julio",
      "id": "70063040000",
      "localidad_censal_id": "70063040",
      "localidad_censal_nombre": "9 de Julio",
      "municipio_id": "700063",
      "municipio_nombre": "9 de Julio",
      "nombre": "9 DE JULIO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7523486776689,
      "centroide_lon": -68.5597213740804,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070005000",
      "localidad_censal_id": "70070005",
      "localidad_censal_nombre": "Barrio Municipal",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "BARRIO MUNICIPAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8595308229437,
      "centroide_lon": -68.5345166485174,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070010000",
      "localidad_censal_id": "70070010",
      "localidad_censal_nombre": "Barrio Ruta 40",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "BARRIO RUTA 40",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8301409850684,
      "centroide_lon": -68.5420989256398,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070020000",
      "localidad_censal_id": "70070020",
      "localidad_censal_nombre": "Carpintería",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "CARPINTERIA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.629474322189,
      "centroide_lon": -68.6080765638159,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070025000",
      "localidad_censal_id": "70070025",
      "localidad_censal_nombre": "Las Piedritas",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "LAS PIEDRITAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6140359426027,
      "centroide_lon": -68.6001745092873,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070030000",
      "localidad_censal_id": "70070030",
      "localidad_censal_nombre": "Quinto Cuartel",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "QUINTO CUARTEL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6952378674877,
      "centroide_lon": -68.5923025771378,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070040001",
      "localidad_censal_id": "70070040",
      "localidad_censal_nombre": "Villa Aberastain - La Rinconada",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "LA RINCONADA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.65701277213,
      "centroide_lon": -68.573608949336,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070040002",
      "localidad_censal_id": "70070040",
      "localidad_censal_nombre": "Villa Aberastain - La Rinconada",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "VILLA ABERASTAIN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5921799746835,
      "centroide_lon": -68.5723924425069,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070050001",
      "localidad_censal_id": "70070050",
      "localidad_censal_nombre": "Villa Barboza - Villa Nacusi",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "VILLA BARBOZA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.6219383886537,
      "centroide_lon": -68.5359580395485,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070050002",
      "localidad_censal_id": "70070050",
      "localidad_censal_nombre": "Villa Barboza - Villa Nacusi",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "VILLA NACUSI",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.668402703149,
      "centroide_lon": -68.5222919302359,
      "departamento_id": "70070",
      "departamento_nombre": "Pocito",
      "id": "70070060000",
      "localidad_censal_id": "70070060",
      "localidad_censal_nombre": "Villa Centenario",
      "municipio_id": "700070",
      "municipio_nombre": "Pocito",
      "nombre": "VILLA CENTENARIO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.583482529045,
      "centroide_lon": -68.4697872532749,
      "departamento_id": "70077",
      "departamento_nombre": "Rawson",
      "id": "70077010001",
      "localidad_censal_id": "70077010",
      "localidad_censal_nombre": "Rawson",
      "municipio_id": "700077",
      "municipio_nombre": "Rawson",
      "nombre": "EL MEDANITO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5696190485463,
      "centroide_lon": -68.5577689018569,
      "departamento_id": "70077",
      "departamento_nombre": "Rawson",
      "id": "70077010002",
      "localidad_censal_id": "70077010",
      "localidad_censal_nombre": "Rawson",
      "municipio_id": "700077",
      "municipio_nombre": "Rawson",
      "nombre": "RAWSON",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.583718555606,
      "centroide_lon": -68.5410817167198,
      "departamento_id": "70077",
      "departamento_nombre": "Rawson",
      "id": "70077010003",
      "localidad_censal_id": "70077010",
      "localidad_censal_nombre": "Rawson",
      "municipio_id": "700077",
      "municipio_nombre": "Rawson",
      "nombre": "VILLA KRAUSE",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6292970053945,
      "centroide_lon": -68.4706676954017,
      "departamento_id": "70077",
      "departamento_nombre": "Rawson",
      "id": "70077020000",
      "localidad_censal_id": "70077020",
      "localidad_censal_nombre": "Villa Bolaños (Médano de Oro)",
      "municipio_id": "700077",
      "municipio_nombre": "Rawson",
      "nombre": "VILLA BOLAÑOS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.5335200372447,
      "centroide_lon": -68.5920689785483,
      "departamento_id": "70084",
      "departamento_nombre": "Rivadavia",
      "id": "70084010000",
      "localidad_censal_id": "70084010",
      "localidad_censal_nombre": "Rivadavia",
      "municipio_id": "700084",
      "municipio_nombre": "Rivadavia",
      "nombre": "RIVADAVIA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5367182711489,
      "centroide_lon": -68.3955676778174,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091010000",
      "localidad_censal_id": "70091010",
      "localidad_censal_nombre": "Barrio Sadop - Bella Vista",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "BARRIO SADOP",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4913855475125,
      "centroide_lon": -68.4185298472673,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091020000",
      "localidad_censal_id": "70091020",
      "localidad_censal_nombre": "Dos Acequias",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "DOS ACEQUIAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4870993000717,
      "centroide_lon": -68.325134344897,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091030000",
      "localidad_censal_id": "70091030",
      "localidad_censal_nombre": "San Isidro",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "SAN ISIDRO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4639044466924,
      "centroide_lon": -68.4095847372199,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091040000",
      "localidad_censal_id": "70091040",
      "localidad_censal_nombre": "Villa del Salvador",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "VILLA EL SALVADOR",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.55912095737,
      "centroide_lon": -68.2976446699079,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091050000",
      "localidad_censal_id": "70091050",
      "localidad_censal_nombre": "Villa Dominguito",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "VILLA DOMINGUITO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5518882180598,
      "centroide_lon": -68.3390185136148,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091060000",
      "localidad_censal_id": "70091060",
      "localidad_censal_nombre": "Villa Don Bosco",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "VILLA DON BOSCO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.516761980837,
      "centroide_lon": -68.3526471246019,
      "departamento_id": "70091",
      "departamento_nombre": "San Martín",
      "id": "70091070000",
      "localidad_censal_id": "70091070",
      "localidad_censal_nombre": "Villa San Martín",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "VILLA SAN MARTIN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5265278957606,
      "centroide_lon": -68.4106014181706,
      "departamento_id": "70098",
      "departamento_nombre": "Santa Lucía",
      "id": "70098010001",
      "localidad_censal_id": "70098010",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "700091",
      "municipio_nombre": "San Martín",
      "nombre": "ALTO DE SIERRA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5173968715659,
      "centroide_lon": -68.4546487927052,
      "departamento_id": "70098",
      "departamento_nombre": "Santa Lucía",
      "id": "70098010002",
      "localidad_censal_id": "70098010",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "700098",
      "municipio_nombre": "Santa Lucía",
      "nombre": "COLONIA GUTIERREZ",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5130353688268,
      "centroide_lon": -68.4565988777122,
      "departamento_id": "70098",
      "departamento_nombre": "Santa Lucía",
      "id": "70098010003",
      "localidad_censal_id": "70098010",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "700098",
      "municipio_nombre": "Santa Lucía",
      "nombre": "SANTA LUCIA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9850527438787,
      "centroide_lon": -68.5481911385325,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105010000",
      "localidad_censal_id": "70105010",
      "localidad_censal_nombre": "Cañada Honda",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "CAÑADA HONDA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0763381339971,
      "centroide_lon": -68.690762577729,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105020000",
      "localidad_censal_id": "70105020",
      "localidad_censal_nombre": "Cienaguita",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "CIENAGUITA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9027437264496,
      "centroide_lon": -68.4696588673219,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105030000",
      "localidad_censal_id": "70105030",
      "localidad_censal_nombre": "Colonia Fiscal",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "COLONIA FISCAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0104984358581,
      "centroide_lon": -68.6904861018003,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105040000",
      "localidad_censal_id": "70105040",
      "localidad_censal_nombre": "Divisadero",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "DIVISADERO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0753813271385,
      "centroide_lon": -68.5856950572685,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105050000",
      "localidad_censal_id": "70105050",
      "localidad_censal_nombre": "Guanacache",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "GUANACACHE",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0443775419211,
      "centroide_lon": -68.3778990229646,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105060000",
      "localidad_censal_id": "70105060",
      "localidad_censal_nombre": "Las Lagunas",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "LAS LAGUNAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9514576383242,
      "centroide_lon": -68.6510941298035,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105070000",
      "localidad_censal_id": "70105070",
      "localidad_censal_nombre": "Los Berros",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "LOS BERROS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9950077029707,
      "centroide_lon": -68.7590874861116,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105080000",
      "localidad_censal_id": "70105080",
      "localidad_censal_nombre": "Pedernal",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "PEDERNAL",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8946043291143,
      "centroide_lon": -68.4183104905607,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105090000",
      "localidad_censal_id": "70105090",
      "localidad_censal_nombre": "Punta del Médano",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "PUNTA DEL MEDANO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9810022624945,
      "centroide_lon": -68.4270029177237,
      "departamento_id": "70105",
      "departamento_nombre": "Sarmiento",
      "id": "70105100000",
      "localidad_censal_id": "70105100",
      "localidad_censal_nombre": "Villa Media Agua",
      "municipio_id": "700105",
      "municipio_nombre": "Sarmiento",
      "nombre": "VILLA MEDIA AGUA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4659385111811,
      "centroide_lon": -68.7353338028121,
      "departamento_id": "70112",
      "departamento_nombre": "Ullum",
      "id": "70112010000",
      "localidad_censal_id": "70112010",
      "localidad_censal_nombre": "Villa Ibáñez",
      "municipio_id": "700112",
      "municipio_nombre": "Ullum",
      "nombre": "VILLA IBAÑEZ",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9538850864023,
      "centroide_lon": -67.3006351406982,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119010000",
      "localidad_censal_id": "70119010",
      "localidad_censal_nombre": "Astica",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "ASTICA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3207151787316,
      "centroide_lon": -67.6951969177397,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119020000",
      "localidad_censal_id": "70119020",
      "localidad_censal_nombre": "Balde del Rosario",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "BALDE DEL ROSARIO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0688861561951,
      "centroide_lon": -67.2788289085567,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119030000",
      "localidad_censal_id": "70119030",
      "localidad_censal_nombre": "Chucuma",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "CHUCUMA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2241888429483,
      "centroide_lon": -67.7014210305429,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119040000",
      "localidad_censal_id": "70119040",
      "localidad_censal_nombre": "Los Baldecitos",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "LOS BALDECITOS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5640053498102,
      "centroide_lon": -67.540327100629,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119050000",
      "localidad_censal_id": "70119050",
      "localidad_censal_nombre": "Usno",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "USNO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6367084796207,
      "centroide_lon": -67.4650598603898,
      "departamento_id": "70119",
      "departamento_nombre": "Valle Fértil",
      "id": "70119060000",
      "localidad_censal_id": "70119060",
      "localidad_censal_nombre": "Villa San Agustín",
      "municipio_id": "700119",
      "municipio_nombre": "Valle Fértil",
      "nombre": "VILLA SAN AGUSTIN",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2163733476113,
      "centroide_lon": -67.793057098676,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126010000",
      "localidad_censal_id": "70126010",
      "localidad_censal_nombre": "El Encón",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "EL ENCON",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8390315742989,
      "centroide_lon": -68.3573941216943,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126020000",
      "localidad_censal_id": "70126020",
      "localidad_censal_nombre": "Tupelí",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "TUPELI",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.8505514541041,
      "centroide_lon": -68.309461754649,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126030001",
      "localidad_censal_id": "70126030",
      "localidad_censal_nombre": "Villa Borjas - La Chimbera",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "LA CHIMBERA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.8100072529359,
      "centroide_lon": -68.3289623063813,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126030002",
      "localidad_censal_id": "70126030",
      "localidad_censal_nombre": "Villa Borjas - La Chimbera",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "VILLA BORJAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.763268918716,
      "centroide_lon": -68.2222966354108,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126040000",
      "localidad_censal_id": "70126040",
      "localidad_censal_nombre": "Villa El Tango",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "VILLA EL TANGO",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7448085385712,
      "centroide_lon": -68.3142412332522,
      "departamento_id": "70126",
      "departamento_nombre": "25 de Mayo",
      "id": "70126050000",
      "localidad_censal_id": "70126050",
      "localidad_censal_nombre": "Villa Santa Rosa",
      "municipio_id": "700126",
      "municipio_nombre": "25 de Mayo",
      "nombre": "VILLA SANTA ROSA",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.543878931951,
      "centroide_lon": -68.7407758815646,
      "departamento_id": "70133",
      "departamento_nombre": "Zonda",
      "id": "70133010001",
      "localidad_censal_id": "70133010",
      "localidad_censal_nombre": "Villa Basilio Nievas",
      "municipio_id": "700133",
      "municipio_nombre": "Zonda",
      "nombre": "VILLA BASILIO NIEVAS",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5293321449519,
      "centroide_lon": -68.7253410683189,
      "departamento_id": "70133",
      "departamento_nombre": "Zonda",
      "id": "70133010002",
      "localidad_censal_id": "70133010",
      "localidad_censal_nombre": "Villa Basilio Nievas",
      "municipio_id": "700133",
      "municipio_nombre": "Zonda",
      "nombre": "VILLA TACU",
      "provincia_id": "70",
      "provincia_nombre": "San Juan"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0607491134468,
      "centroide_lon": -65.8276913716033,
      "departamento_id": "74007",
      "departamento_nombre": "Ayacucho",
      "id": "74007010000",
      "localidad_censal_id": "74007010",
      "localidad_censal_nombre": "Candelaria",
      "municipio_id": "740007",
      "municipio_nombre": "Candelaria",
      "nombre": "CANDELARIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1047821044922,
      "centroide_lon": -65.9621124267578,
      "departamento_id": "74007",
      "departamento_nombre": "Ayacucho",
      "id": "74007030000",
      "localidad_censal_id": "74007030",
      "localidad_censal_nombre": "Leandro N. Alem",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LEANDRO N. ALEM",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3660349058479,
      "centroide_lon": -65.9425439975458,
      "departamento_id": "74007",
      "departamento_nombre": "Ayacucho",
      "id": "74007040000",
      "localidad_censal_id": "74007040",
      "localidad_censal_nombre": "Luján",
      "municipio_id": "740014",
      "municipio_nombre": "Lujan",
      "nombre": "LUJAN",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2330822365033,
      "centroide_lon": -65.8056329969317,
      "departamento_id": "74007",
      "departamento_nombre": "Ayacucho",
      "id": "74007050000",
      "localidad_censal_id": "74007050",
      "localidad_censal_nombre": "Quines",
      "municipio_id": "740021",
      "municipio_nombre": "Quines",
      "nombre": "QUINES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6006392247112,
      "centroide_lon": -66.1273702635842,
      "departamento_id": "74007",
      "departamento_nombre": "Ayacucho",
      "id": "74007070000",
      "localidad_censal_id": "74007070",
      "localidad_censal_nombre": "San Francisco del Monte de Oro",
      "municipio_id": "740028",
      "municipio_nombre": "San Francisco",
      "nombre": "SAN FRANCISCO DEL MONTE DE ORO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8619918957825,
      "centroide_lon": -66.8506529764617,
      "departamento_id": "74014",
      "departamento_nombre": "Belgrano",
      "id": "74014010000",
      "localidad_censal_id": "74014010",
      "localidad_censal_nombre": "La Calera",
      "municipio_id": "746014",
      "municipio_nombre": "La Calera",
      "nombre": "LA CALERA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9173752831041,
      "centroide_lon": -66.3257602058349,
      "departamento_id": "74014",
      "departamento_nombre": "Belgrano",
      "id": "74014020000",
      "localidad_censal_id": "74014020",
      "localidad_censal_nombre": "Nogolí",
      "municipio_id": "746021",
      "municipio_nombre": "Nogoli",
      "nombre": "NOGOLI",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0161741932684,
      "centroide_lon": -66.2920464806382,
      "departamento_id": "74014",
      "departamento_nombre": "Belgrano",
      "id": "74014030000",
      "localidad_censal_id": "74014030",
      "localidad_censal_nombre": "Villa de la Quebrada",
      "municipio_id": "746028",
      "municipio_nombre": "V, de la Quebrada",
      "nombre": "VILLA DE LA QUEBRADA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6661925630759,
      "centroide_lon": -66.4524010178082,
      "departamento_id": "74014",
      "departamento_nombre": "Belgrano",
      "id": "74014040000",
      "localidad_censal_id": "74014040",
      "localidad_censal_nombre": "Villa General Roca",
      "municipio_id": "740035",
      "municipio_nombre": "V, Gral, Roca",
      "nombre": "VILLA GENERAL ROCA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8128571637146,
      "centroide_lon": -66.0932327577628,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021010000",
      "localidad_censal_id": "74021010",
      "localidad_censal_nombre": "Carolina",
      "municipio_id": "746035",
      "municipio_nombre": "Carolina",
      "nombre": "CAROLINA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1082715280981,
      "centroide_lon": -66.063371211902,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021020000",
      "localidad_censal_id": "74021020",
      "localidad_censal_nombre": "El Trapiche",
      "municipio_id": "746042",
      "municipio_nombre": "El Trapiche",
      "nombre": "EL TRAPICHE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1905465526598,
      "centroide_lon": -66.1527985566353,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021025000",
      "localidad_censal_id": "74021025",
      "localidad_censal_nombre": "Estancia Grande",
      "municipio_id": "740038",
      "municipio_nombre": "Estancia Grande",
      "nombre": "ESTANCIA GRANDE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.502087069887,
      "centroide_lon": -65.7926477789763,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021030000",
      "localidad_censal_id": "74021030",
      "localidad_censal_nombre": "Fraga",
      "municipio_id": "746049",
      "municipio_nombre": "Fraga",
      "nombre": "FRAGA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.157161301266,
      "centroide_lon": -66.0131877714641,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021040000",
      "localidad_censal_id": "74021040",
      "localidad_censal_nombre": "La Bajada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA BAJADA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1167587248711,
      "centroide_lon": -66.0020090018267,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021050000",
      "localidad_censal_id": "74021050",
      "localidad_censal_nombre": "La Florida",
      "municipio_id": "746042",
      "municipio_nombre": "El Trapiche",
      "nombre": "LA FLORIDA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0544376204479,
      "centroide_lon": -65.62269821015,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021060000",
      "localidad_censal_id": "74021060",
      "localidad_censal_nombre": "La Toma",
      "municipio_id": "740042",
      "municipio_nombre": "La Toma",
      "nombre": "LA TOMA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0964304439761,
      "centroide_lon": -65.990838382791,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021070000",
      "localidad_censal_id": "74021070",
      "localidad_censal_nombre": "Riocito",
      "municipio_id": "746042",
      "municipio_nombre": "El Trapiche",
      "nombre": "RIOCITO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0485046022503,
      "centroide_lon": -66.0717543089664,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021080000",
      "localidad_censal_id": "74021080",
      "localidad_censal_nombre": "Río Grande",
      "municipio_id": "746042",
      "municipio_nombre": "El Trapiche",
      "nombre": "RIO GRANDE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2005664915694,
      "centroide_lon": -65.8531363375249,
      "departamento_id": "74021",
      "departamento_nombre": "Coronel Pringles",
      "id": "74021090000",
      "localidad_censal_id": "74021090",
      "localidad_censal_nombre": "Saladillo",
      "municipio_id": "746063",
      "municipio_nombre": "Saladillo",
      "nombre": "SALADILLO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5607275180287,
      "centroide_lon": -65.2452806076649,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028010000",
      "localidad_censal_id": "74028010",
      "localidad_censal_nombre": "Concarán",
      "municipio_id": "740049",
      "municipio_nombre": "Concaran",
      "nombre": "CONCARAN",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5076504245814,
      "centroide_lon": -64.9869655159434,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028020000",
      "localidad_censal_id": "74028020",
      "localidad_censal_nombre": "Cortaderas",
      "municipio_id": "746070",
      "municipio_nombre": "Cortaderas",
      "nombre": "CORTADERAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9168215937221,
      "centroide_lon": -65.3755476812695,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028030000",
      "localidad_censal_id": "74028030",
      "localidad_censal_nombre": "Naschel",
      "municipio_id": "740056",
      "municipio_nombre": "Naschel",
      "nombre": "NASCHEL",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6786405437002,
      "centroide_lon": -64.9881795223679,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028040000",
      "localidad_censal_id": "74028040",
      "localidad_censal_nombre": "Papagayos",
      "municipio_id": "746077",
      "municipio_nombre": "Papagayos",
      "nombre": "PAPAGAYOS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7717680736652,
      "centroide_lon": -65.3637605007649,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028050000",
      "localidad_censal_id": "74028050",
      "localidad_censal_nombre": "Renca",
      "municipio_id": "746084",
      "municipio_nombre": "Renca",
      "nombre": "RENCA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.658342389353,
      "centroide_lon": -65.3080920411456,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028060000",
      "localidad_censal_id": "74028060",
      "localidad_censal_nombre": "San Pablo",
      "municipio_id": "746091",
      "municipio_nombre": "San Pablo",
      "nombre": "SAN PABLO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7329281106581,
      "centroide_lon": -65.2915274383295,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028070000",
      "localidad_censal_id": "74028070",
      "localidad_censal_nombre": "Tilisarao",
      "municipio_id": "740063",
      "municipio_nombre": "Tilisarao",
      "nombre": "TILISARAO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9411267835778,
      "centroide_lon": -65.0394664526979,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028080000",
      "localidad_censal_id": "74028080",
      "localidad_censal_nombre": "Villa del Carmen",
      "municipio_id": "746098",
      "municipio_nombre": "Villa del Carmen",
      "nombre": "VILLA DEL CARMEN",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6175433008771,
      "centroide_lon": -64.9809087535547,
      "departamento_id": "74028",
      "departamento_nombre": "Chacabuco",
      "id": "74028090000",
      "localidad_censal_id": "74028090",
      "localidad_censal_nombre": "Villa Larca",
      "municipio_id": "746105",
      "municipio_nombre": "Villa Larca",
      "nombre": "VILLA LARCA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6132806299836,
      "centroide_lon": -65.2717502963014,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035010000",
      "localidad_censal_id": "74035010",
      "localidad_censal_nombre": "Juan Jorba",
      "municipio_id": "749119",
      "municipio_nombre": "Juan Jorba",
      "nombre": "JUAN JORBA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2811132563767,
      "centroide_lon": -65.6145766804084,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035020000",
      "localidad_censal_id": "74035020",
      "localidad_censal_nombre": "Juan Llerena",
      "municipio_id": "746126",
      "municipio_nombre": "Juan Llerena",
      "nombre": "JUAN LLERENA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8585834476817,
      "centroide_lon": -65.1870408754728,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035030000",
      "localidad_censal_id": "74035030",
      "localidad_censal_nombre": "Justo Daract",
      "municipio_id": "740070",
      "municipio_nombre": "Justo Daract",
      "nombre": "JUSTO DARACT",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1429537099622,
      "centroide_lon": -65.0861306811866,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035040000",
      "localidad_censal_id": "74035040",
      "localidad_censal_nombre": "La Punilla",
      "municipio_id": "746133",
      "municipio_nombre": "La Punilla",
      "nombre": "LA PUNILLA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8221476840744,
      "centroide_lon": -65.4237934358157,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035050000",
      "localidad_censal_id": "74035050",
      "localidad_censal_nombre": "Lavaisse",
      "municipio_id": "746140",
      "municipio_nombre": "Lavaisse",
      "nombre": "LAVAISSE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6086190002894,
      "centroide_lon": -65.7348529983919,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035055000",
      "localidad_censal_id": "74035055",
      "localidad_censal_nombre": "Nación Ranquel",
      "municipio_id": "740073",
      "municipio_nombre": "Ranqueles",
      "nombre": "NACION RANQUEL",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2119853031031,
      "centroide_lon": -65.4927334484497,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035060000",
      "localidad_censal_id": "74035060",
      "localidad_censal_nombre": "San José del Morro",
      "municipio_id": "746112",
      "municipio_nombre": "San Jose del Morro",
      "nombre": "SAN JOSE DEL MORRO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -33.6738636408858,
      "centroide_lon": -65.4624765290195,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035070000",
      "localidad_censal_id": "74035070",
      "localidad_censal_nombre": "Villa Mercedes",
      "municipio_id": "740077",
      "municipio_nombre": "Villa Mercedes",
      "nombre": "VILLA MERCEDES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.6918122338202,
      "centroide_lon": -65.5007310600543,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035070001",
      "localidad_censal_id": "74035070",
      "localidad_censal_nombre": "Villa Mercedes",
      "municipio_id": "740077",
      "municipio_nombre": "Villa Mercedes",
      "nombre": "LA RIBERA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.6773697773889,
      "centroide_lon": -65.4731433656901,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035070002",
      "localidad_censal_id": "74035070",
      "localidad_censal_nombre": "Villa Mercedes",
      "municipio_id": "740077",
      "municipio_nombre": "Villa Mercedes",
      "nombre": "VILLA MERCEDES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple con entidad",
      "centroide_lat": -33.722961850163,
      "centroide_lon": -65.3814163660133,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035080000",
      "localidad_censal_id": "74035080",
      "localidad_censal_nombre": "Villa Reynolds",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA REYNOLDS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.7036125779234,
      "centroide_lon": -65.3290340917624,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035080001",
      "localidad_censal_id": "74035080",
      "localidad_censal_nombre": "Villa Reynolds",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COUNTRY CLUB LOS CALDENES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.7239942428561,
      "centroide_lon": -65.3810379035533,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035080002",
      "localidad_censal_id": "74035080",
      "localidad_censal_nombre": "Villa Reynolds",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "5TA BRIGADA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.84292794921,
      "centroide_lon": -65.2147610955176,
      "departamento_id": "74035",
      "departamento_nombre": "General Pedernera",
      "id": "74035090000",
      "localidad_censal_id": "74035090",
      "localidad_censal_nombre": "Villa Salles",
      "municipio_id": "740070",
      "municipio_nombre": "Justo Daract",
      "nombre": "VILLA SALLES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.6746450244219,
      "centroide_lon": -65.4244230509371,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042010000",
      "localidad_censal_id": "74042010",
      "localidad_censal_nombre": "Anchorena",
      "municipio_id": "746147",
      "municipio_nombre": "Anchorena",
      "nombre": "ANCHORENA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7229145031177,
      "centroide_lon": -65.3187210360081,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042020000",
      "localidad_censal_id": "74042020",
      "localidad_censal_nombre": "Arizona",
      "municipio_id": "746154",
      "municipio_nombre": "Arizona",
      "nombre": "ARIZONA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1448484513353,
      "centroide_lon": -65.5676652450981,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042030000",
      "localidad_censal_id": "74042030",
      "localidad_censal_nombre": "Bagual",
      "municipio_id": "746161",
      "municipio_nombre": "Bagual",
      "nombre": "BAGUAL",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7755332557227,
      "centroide_lon": -65.6862987148176,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042040000",
      "localidad_censal_id": "74042040",
      "localidad_censal_nombre": "Batavia",
      "municipio_id": "746168",
      "municipio_nombre": "Batavia",
      "nombre": "BATAVIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7582787968944,
      "centroide_lon": -65.2503691694475,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042050000",
      "localidad_censal_id": "74042050",
      "localidad_censal_nombre": "Buena Esperanza",
      "municipio_id": "740084",
      "municipio_nombre": "Buena Esperanza",
      "nombre": "BUENA ESPERANZA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.769756515675,
      "centroide_lon": -65.5229345175781,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042060000",
      "localidad_censal_id": "74042060",
      "localidad_censal_nombre": "Fortín El Patria",
      "municipio_id": "749175",
      "municipio_nombre": "Fortin el Patria",
      "nombre": "FORTIN EL PATRIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1282918635603,
      "centroide_lon": -65.3818218917708,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042070000",
      "localidad_censal_id": "74042070",
      "localidad_censal_nombre": "Fortuna",
      "municipio_id": "749182",
      "municipio_nombre": "Fortuna",
      "nombre": "FORTUNA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.2127003045126,
      "centroide_lon": -66.3264252245578,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042080000",
      "localidad_censal_id": "74042080",
      "localidad_censal_nombre": "La Maroma",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA MAROMA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.8808971047928,
      "centroide_lon": -66.4450142548098,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042090000",
      "localidad_censal_id": "74042090",
      "localidad_censal_nombre": "Los Overos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS OVEROS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.7111681131223,
      "centroide_lon": -66.3525275844943,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042100000",
      "localidad_censal_id": "74042100",
      "localidad_censal_nombre": "Martín de Loyola",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MARTIN DE LOYOLA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7833678660504,
      "centroide_lon": -66.1701498297947,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042110000",
      "localidad_censal_id": "74042110",
      "localidad_censal_nombre": "Nahuel Mapá",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NAHUEL MAPA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.7726733665409,
      "centroide_lon": -66.5862788668642,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042120000",
      "localidad_censal_id": "74042120",
      "localidad_censal_nombre": "Navia",
      "municipio_id": "746189",
      "municipio_nombre": "Navia",
      "nombre": "NAVIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1124747000615,
      "centroide_lon": -65.2532109154966,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042130000",
      "localidad_censal_id": "74042130",
      "localidad_censal_nombre": "Nueva Galia",
      "municipio_id": "746196",
      "municipio_nombre": "Nueva Galia",
      "nombre": "NUEVA GALIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -35.1549261061197,
      "centroide_lon": -65.9424468511458,
      "departamento_id": "74042",
      "departamento_nombre": "Gobernador Dupuy",
      "id": "74042140000",
      "localidad_censal_id": "74042140",
      "localidad_censal_nombre": "Unión",
      "municipio_id": "740091",
      "municipio_nombre": "Union",
      "nombre": "UNION",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4102495120789,
      "centroide_lon": -65.0113926769819,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049010000",
      "localidad_censal_id": "74049010",
      "localidad_censal_nombre": "Carpintería",
      "municipio_id": "746203",
      "municipio_nombre": "Carpinteria",
      "nombre": "CARPINTERIA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3851963369856,
      "centroide_lon": -64.9859436108737,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049020000",
      "localidad_censal_id": "74049020",
      "localidad_censal_nombre": "Cerro de Oro",
      "municipio_id": "740105",
      "municipio_nombre": "Merlo",
      "nombre": "CERRO DE ORO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0621151953791,
      "centroide_lon": -65.3496815467824,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049030000",
      "localidad_censal_id": "74049030",
      "localidad_censal_nombre": "Lafinur",
      "municipio_id": "746210",
      "municipio_nombre": "Lafinur",
      "nombre": "LAFINUR",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0257513048361,
      "centroide_lon": -65.3749361476641,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049040000",
      "localidad_censal_id": "74049040",
      "localidad_censal_nombre": "Los Cajones",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS CAJONES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4395447509138,
      "centroide_lon": -65.0106822684226,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049050000",
      "localidad_censal_id": "74049050",
      "localidad_censal_nombre": "Los Molles",
      "municipio_id": "746217",
      "municipio_nombre": "Los Molles",
      "nombre": "LOS MOLLES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3425391142336,
      "centroide_lon": -65.0141372032908,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049060000",
      "localidad_censal_id": "74049060",
      "localidad_censal_nombre": "Merlo",
      "municipio_id": "740105",
      "municipio_nombre": "Merlo",
      "nombre": "MERLO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3423081509941,
      "centroide_lon": -65.2071206614116,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049070000",
      "localidad_censal_id": "74049070",
      "localidad_censal_nombre": "Santa Rosa del Conlara",
      "municipio_id": "740098",
      "municipio_nombre": "Sta Rosa del Conlara",
      "nombre": "SANTA ROSA DEL CONLARA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2481203088315,
      "centroide_lon": -65.5838734087874,
      "departamento_id": "74049",
      "departamento_nombre": "Junín",
      "id": "74049080000",
      "localidad_censal_id": "74049080",
      "localidad_censal_nombre": "Talita",
      "municipio_id": "746224",
      "municipio_nombre": "Talita",
      "nombre": "TALITA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8425029072214,
      "centroide_lon": -66.1375993056552,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056010000",
      "localidad_censal_id": "74056010",
      "localidad_censal_nombre": "Alto Pelado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALTO PELADO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4306363842929,
      "centroide_lon": -66.9279669116981,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056020000",
      "localidad_censal_id": "74056020",
      "localidad_censal_nombre": "Alto Pencoso",
      "municipio_id": "746238",
      "municipio_nombre": "Alto Pencoso",
      "nombre": "ALTO PENCOSO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3432695635018,
      "centroide_lon": -66.626047423873,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056030000",
      "localidad_censal_id": "74056030",
      "localidad_censal_nombre": "Balde",
      "municipio_id": "746245",
      "municipio_nombre": "Balde",
      "nombre": "BALDE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.757734110106,
      "centroide_lon": -66.6459600305752,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056040000",
      "localidad_censal_id": "74056040",
      "localidad_censal_nombre": "Beazley",
      "municipio_id": "746252",
      "municipio_nombre": "Beazley",
      "nombre": "BEAZLEY",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8576584773376,
      "centroide_lon": -66.3696150667545,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056050000",
      "localidad_censal_id": "74056050",
      "localidad_censal_nombre": "Cazador",
      "municipio_id": "746280",
      "municipio_nombre": "Zanjitas",
      "nombre": "CAZADOR",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3959968949714,
      "centroide_lon": -66.7463973832244,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056060000",
      "localidad_censal_id": "74056060",
      "localidad_censal_nombre": "Chosmes",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHOSMES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.4004585255775,
      "centroide_lon": -67.148082207766,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056070000",
      "localidad_censal_id": "74056070",
      "localidad_censal_nombre": "Desaguadero",
      "municipio_id": "740108",
      "municipio_nombre": "Desaguadero",
      "nombre": "DESAGUADERO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2512966067626,
      "centroide_lon": -66.1877022971966,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056080000",
      "localidad_censal_id": "74056080",
      "localidad_censal_nombre": "El Volcán",
      "municipio_id": "746259",
      "municipio_nombre": "El Volcan",
      "nombre": "EL VOLCAN",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.398658341972,
      "centroide_lon": -67.0274295478727,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056090000",
      "localidad_censal_id": "74056090",
      "localidad_censal_nombre": "Jarilla",
      "municipio_id": "740108",
      "municipio_nombre": "Desaguadero",
      "nombre": "JARILLA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.2550955344668,
      "centroide_lon": -66.2286548309473,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056100001",
      "localidad_censal_id": "74056100",
      "localidad_censal_nombre": "Juana Koslay",
      "municipio_id": "740119",
      "municipio_nombre": "Juana Koslay",
      "nombre": "CERRO COLORADO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.266266753132,
      "centroide_lon": -66.2171495179704,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056100002",
      "localidad_censal_id": "74056100",
      "localidad_censal_nombre": "Juana Koslay",
      "municipio_id": "740119",
      "municipio_nombre": "Juana Koslay",
      "nombre": "CRUZ DE PIEDRA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.2889484546354,
      "centroide_lon": -66.2580406259221,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056100003",
      "localidad_censal_id": "74056100",
      "localidad_censal_nombre": "Juana Koslay",
      "municipio_id": "740119",
      "municipio_nombre": "Juana Koslay",
      "nombre": "EL CHORRILLO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.2608224121538,
      "centroide_lon": -66.2381061398228,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056100004",
      "localidad_censal_id": "74056100",
      "localidad_censal_nombre": "Juana Koslay",
      "municipio_id": "740119",
      "municipio_nombre": "Juana Koslay",
      "nombre": "LAS CHACRAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -33.2720248979515,
      "centroide_lon": -66.2276619762377,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056100005",
      "localidad_censal_id": "74056100",
      "localidad_censal_nombre": "Juana Koslay",
      "municipio_id": "740119",
      "municipio_nombre": "Juana Koslay",
      "nombre": "SAN ROQUE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1816571259341,
      "centroide_lon": -66.313607690249,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056105000",
      "localidad_censal_id": "74056105",
      "localidad_censal_nombre": "La Punta",
      "municipio_id": "749007",
      "municipio_nombre": "La Punta",
      "nombre": "CIUDAD DE LA PUNTA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6458915525894,
      "centroide_lon": -66.9934495323611,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056110000",
      "localidad_censal_id": "74056110",
      "localidad_censal_nombre": "Mosmota",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MOSMOTA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2187787091432,
      "centroide_lon": -66.2307288183005,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056120000",
      "localidad_censal_id": "74056120",
      "localidad_censal_nombre": "Potrero de los Funes",
      "municipio_id": "746266",
      "municipio_nombre": "P. de los Funes",
      "nombre": "POTRERO DE LOS FUNES",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.501125039306,
      "centroide_lon": -66.6514406177997,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056130000",
      "localidad_censal_id": "74056130",
      "localidad_censal_nombre": "Salinas del Bebedero",
      "municipio_id": "746245",
      "municipio_nombre": "Balde",
      "nombre": "SALINAS DEL BEBEDERO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1383053611749,
      "centroide_lon": -66.5166859326585,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056140000",
      "localidad_censal_id": "74056140",
      "localidad_censal_nombre": "San Jerónimo",
      "municipio_id": "746273",
      "municipio_nombre": "San Jeronimo",
      "nombre": "SAN JERONIMO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.3023139659883,
      "centroide_lon": -66.3360877357358,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056150000",
      "localidad_censal_id": "74056150",
      "localidad_censal_nombre": "San Luis",
      "municipio_id": "740133",
      "municipio_nombre": "San Luis",
      "nombre": "SAN LUIS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8022746844103,
      "centroide_lon": -66.415462549085,
      "departamento_id": "74056",
      "departamento_nombre": "Juan Martín de Pueyrredón",
      "id": "74056160000",
      "localidad_censal_id": "74056160",
      "localidad_censal_nombre": "Zanjitas",
      "municipio_id": "746280",
      "municipio_nombre": "Zanjitas",
      "nombre": "ZANJITAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7975027746268,
      "centroide_lon": -65.7568974316301,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063010000",
      "localidad_censal_id": "74063010",
      "localidad_censal_nombre": "La Vertiente",
      "municipio_id": "746287",
      "municipio_nombre": "Las Vertientes",
      "nombre": "LA VERTIENTE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3763580460129,
      "centroide_lon": -65.5012041382004,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063020000",
      "localidad_censal_id": "74063020",
      "localidad_censal_nombre": "Las Aguadas",
      "municipio_id": "746294",
      "municipio_nombre": "Las Aguadas",
      "nombre": "LAS AGUADAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5435132286539,
      "centroide_lon": -65.7446386901218,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063030000",
      "localidad_censal_id": "74063030",
      "localidad_censal_nombre": "Las Chacras",
      "municipio_id": "746301",
      "municipio_nombre": "Las Chacras",
      "nombre": "LAS CHACRAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6298120296294,
      "centroide_lon": -65.551144128608,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063040000",
      "localidad_censal_id": "74063040",
      "localidad_censal_nombre": "Las Lagunas",
      "municipio_id": "746308",
      "municipio_nombre": "Las Lagunas",
      "nombre": "LAS LAGUNAS",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8769737284291,
      "centroide_lon": -65.6345301522078,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063050000",
      "localidad_censal_id": "74063050",
      "localidad_censal_nombre": "Paso Grande",
      "municipio_id": "746315",
      "municipio_nombre": "Paso Grande",
      "nombre": "PASO GRANDE",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6714012444881,
      "centroide_lon": -65.6626272217676,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063060000",
      "localidad_censal_id": "74063060",
      "localidad_censal_nombre": "Potrerillo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POTRERILLO",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4132690463753,
      "centroide_lon": -65.6759306690121,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063070000",
      "localidad_censal_id": "74063070",
      "localidad_censal_nombre": "San Martín",
      "municipio_id": "740140",
      "municipio_nombre": "San Martin",
      "nombre": "SAN MARTIN",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5339640778182,
      "centroide_lon": -65.6477042790939,
      "departamento_id": "74063",
      "departamento_nombre": "Libertador General San Martín",
      "id": "74063080000",
      "localidad_censal_id": "74063080",
      "localidad_censal_nombre": "Villa de Praga",
      "municipio_id": "746322",
      "municipio_nombre": "V, de Praga",
      "nombre": "VILLA DE PRAGA",
      "provincia_id": "74",
      "provincia_nombre": "San Luis"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -49.9859909808201,
      "centroide_lon": -68.9130816915927,
      "departamento_id": "78007",
      "departamento_nombre": "Corpen Aike",
      "id": "78007010000",
      "localidad_censal_id": "78007010",
      "localidad_censal_nombre": "Comandante Luis Piedrabuena",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COMANDANTE LUIS PIEDRABUENA",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -50.0171892721878,
      "centroide_lon": -68.5248246324655,
      "departamento_id": "78007",
      "departamento_nombre": "Corpen Aike",
      "id": "78007020000",
      "localidad_censal_id": "78007020",
      "localidad_censal_nombre": "Puerto Santa Cruz",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO SANTA CRUZ",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.4459492303195,
      "centroide_lon": -67.5251564969847,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014010000",
      "localidad_censal_id": "78014010",
      "localidad_censal_nombre": "Caleta Olivia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CALETA OLIVIA",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.5588457703439,
      "centroide_lon": -67.616856368573,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014020000",
      "localidad_censal_id": "78014020",
      "localidad_censal_nombre": "Cañadón Seco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CAÑADON SECO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.0257940308205,
      "centroide_lon": -67.2542856564871,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014030000",
      "localidad_censal_id": "78014030",
      "localidad_censal_nombre": "Fitz Roy",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FITZ ROY",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.1847032165887,
      "centroide_lon": -67.145582198474,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014040000",
      "localidad_censal_id": "78014040",
      "localidad_censal_nombre": "Jaramillo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "JARAMILLO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.7168267498228,
      "centroide_lon": -68.2279614588974,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014050000",
      "localidad_censal_id": "78014050",
      "localidad_censal_nombre": "Koluel Kaike",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "KOLUEL KAIKE",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.5424553787867,
      "centroide_lon": -68.9341773229667,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014060000",
      "localidad_censal_id": "78014060",
      "localidad_censal_nombre": "Las Heras",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS HERAS",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.7938981244061,
      "centroide_lon": -67.9575704898943,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014070000",
      "localidad_censal_id": "78014070",
      "localidad_censal_nombre": "Pico Truncado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PICO TRUNCADO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.7514649275067,
      "centroide_lon": -65.9012043680086,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014080000",
      "localidad_censal_id": "78014080",
      "localidad_censal_nombre": "Puerto Deseado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO DESEADO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.6487686074581,
      "centroide_lon": -66.0446356831872,
      "departamento_id": "78014",
      "departamento_nombre": "Deseado",
      "id": "78014090000",
      "localidad_censal_id": "78014090",
      "localidad_censal_nombre": "Tellier",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TELLIER",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.6805338519685,
      "centroide_lon": -72.0874860687819,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021010000",
      "localidad_censal_id": "78021010",
      "localidad_censal_nombre": "El Turbio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL TURBIO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.5406111767208,
      "centroide_lon": -72.2398967258576,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021020000",
      "localidad_censal_id": "78021020",
      "localidad_censal_nombre": "Julia Dufour",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "JULIA DUFOUR",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.5487660455569,
      "centroide_lon": -72.2333909775383,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021030000",
      "localidad_censal_id": "78021030",
      "localidad_censal_nombre": "Mina 3",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MINA 3",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.6214349839165,
      "centroide_lon": -69.2290509293744,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021040000",
      "localidad_censal_id": "78021040",
      "localidad_censal_nombre": "Río Gallegos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RIO GALLEGOS",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.6639328934924,
      "centroide_lon": -72.1426988306974,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021050000",
      "localidad_censal_id": "78021050",
      "localidad_censal_nombre": "Rospentek",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROSPENTEK",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.5787581525544,
      "centroide_lon": -72.2080410883792,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021060000",
      "localidad_censal_id": "78021060",
      "localidad_censal_nombre": "28 de Noviembre",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "28 DE NOVIEMBRE",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -51.5328383738253,
      "centroide_lon": -72.3341032077956,
      "departamento_id": "78021",
      "departamento_nombre": "Güer Aike",
      "id": "78021070000",
      "localidad_censal_id": "78021070",
      "localidad_censal_nombre": "Yacimientos Río Turbio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "YACIMIENTOS RIO TURBIO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -50.3373208485428,
      "centroide_lon": -72.2619950698979,
      "departamento_id": "78028",
      "departamento_nombre": "Lago Argentino",
      "id": "78028010000",
      "localidad_censal_id": "78028010",
      "localidad_censal_nombre": "El Calafate",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CALAFATE",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -49.3319731177032,
      "centroide_lon": -72.8916267088672,
      "departamento_id": "78028",
      "departamento_nombre": "Lago Argentino",
      "id": "78028020000",
      "localidad_censal_id": "78028020",
      "localidad_censal_nombre": "El Chaltén",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CHALTEN",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -49.5990148275349,
      "centroide_lon": -71.445802241684,
      "departamento_id": "78028",
      "departamento_nombre": "Lago Argentino",
      "id": "78028030000",
      "localidad_censal_id": "78028030",
      "localidad_censal_nombre": "Tres Lagos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TRES LAGOS",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.5487484894558,
      "centroide_lon": -71.6274835082657,
      "departamento_id": "78035",
      "departamento_nombre": "Lago Buenos Aires",
      "id": "78035010000",
      "localidad_censal_id": "78035010",
      "localidad_censal_nombre": "Los Antiguos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS ANTIGUOS",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -46.5921416878797,
      "centroide_lon": -70.9257278426639,
      "departamento_id": "78035",
      "departamento_nombre": "Lago Buenos Aires",
      "id": "78035020000",
      "localidad_censal_id": "78035020",
      "localidad_censal_nombre": "Perito Moreno",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PERITO MORENO",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -49.307703595363,
      "centroide_lon": -67.7319702926075,
      "departamento_id": "78042",
      "departamento_nombre": "Magallanes",
      "id": "78042010000",
      "localidad_censal_id": "78042010",
      "localidad_censal_nombre": "Puerto San Julián",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO SAN JULIAN",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.4461490043983,
      "centroide_lon": -70.9285155605965,
      "departamento_id": "78049",
      "departamento_nombre": "Río Chico",
      "id": "78049010000",
      "localidad_censal_id": "78049010",
      "localidad_censal_nombre": "Bajo Caracoles",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BAJO CARACOLES",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -48.7521223249696,
      "centroide_lon": -70.2442035901869,
      "departamento_id": "78049",
      "departamento_nombre": "Río Chico",
      "id": "78049020000",
      "localidad_censal_id": "78049020",
      "localidad_censal_nombre": "Gobernador Gregores",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GOBERNADOR GREGORES",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -47.5667812360399,
      "centroide_lon": -71.7434756314323,
      "departamento_id": "78049",
      "departamento_nombre": "Río Chico",
      "id": "78049030000",
      "localidad_censal_id": "78049030",
      "localidad_censal_nombre": "Hipólito Yrigoyen",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HIPOLITO YRIGOYEN",
      "provincia_id": "78",
      "provincia_nombre": "Santa Cruz"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7846557002825,
      "centroide_lon": -61.605481877095,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007010000",
      "localidad_censal_id": "82007010",
      "localidad_censal_nombre": "Armstrong",
      "municipio_id": "820007",
      "municipio_nombre": "Armstrong",
      "nombre": "ARMSTRONG",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4247998561473,
      "centroide_lon": -61.8903345863257,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007020000",
      "localidad_censal_id": "82007020",
      "localidad_censal_nombre": "Bouquet",
      "municipio_id": "822007",
      "municipio_nombre": "Bouquet",
      "nombre": "BOUQUET",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6826842550048,
      "centroide_lon": -61.5185798347876,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007030000",
      "localidad_censal_id": "82007030",
      "localidad_censal_nombre": "Las Parejas",
      "municipio_id": "820014",
      "municipio_nombre": "Las Parejas",
      "nombre": "LAS PAREJAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4785768176046,
      "centroide_lon": -61.5748036822531,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007040000",
      "localidad_censal_id": "82007040",
      "localidad_censal_nombre": "Las Rosas",
      "municipio_id": "820021",
      "municipio_nombre": "Las Rosas",
      "nombre": "LAS ROSAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.567997020224,
      "centroide_lon": -61.7680762352029,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007050000",
      "localidad_censal_id": "82007050",
      "localidad_censal_nombre": "Montes de Oca",
      "municipio_id": "822014",
      "municipio_nombre": "Montes de Oca",
      "nombre": "MONTES DE OCA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7482982621557,
      "centroide_lon": -61.8203359267394,
      "departamento_id": "82007",
      "departamento_nombre": "Belgrano",
      "id": "82007060000",
      "localidad_censal_id": "82007060",
      "localidad_censal_nombre": "Tortugas",
      "municipio_id": "822021",
      "municipio_nombre": "Tortugas",
      "nombre": "TORTUGAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1483008378636,
      "centroide_lon": -61.4713349349693,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014010000",
      "localidad_censal_id": "82014010",
      "localidad_censal_nombre": "Arequito",
      "municipio_id": "822028",
      "municipio_nombre": "Arequito",
      "nombre": "AREQUITO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0911311154911,
      "centroide_lon": -61.7917113876172,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014020000",
      "localidad_censal_id": "82014020",
      "localidad_censal_nombre": "Arteaga",
      "municipio_id": "822035",
      "municipio_nombre": "Arteaga",
      "nombre": "ARTEAGA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3414152210784,
      "centroide_lon": -61.8622809808056,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014030000",
      "localidad_censal_id": "82014030",
      "localidad_censal_nombre": "Beravebú",
      "municipio_id": "822042",
      "municipio_nombre": "Berabevú",
      "nombre": "BERAVEBU",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3761183026397,
      "centroide_lon": -61.185501310201,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014040000",
      "localidad_censal_id": "82014040",
      "localidad_censal_nombre": "Bigand",
      "municipio_id": "822049",
      "municipio_nombre": "Bigand",
      "nombre": "BIGAND",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0424955546762,
      "centroide_lon": -61.1693311184421,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014050000",
      "localidad_censal_id": "82014050",
      "localidad_censal_nombre": "Casilda",
      "municipio_id": "820028",
      "municipio_nombre": "Casilda",
      "nombre": "CASILDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2470674643201,
      "centroide_lon": -61.3575935561826,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014060000",
      "localidad_censal_id": "82014060",
      "localidad_censal_nombre": "Chabas",
      "municipio_id": "822056",
      "municipio_nombre": "Chabas",
      "nombre": "CHABAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3258409849243,
      "centroide_lon": -62.0386496702618,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014070000",
      "localidad_censal_id": "82014070",
      "localidad_censal_nombre": "Chañar Ladeado",
      "municipio_id": "822063",
      "municipio_nombre": "Chañar Ladeado",
      "nombre": "CHAÑAR LADEADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.402405547162,
      "centroide_lon": -61.8449306727075,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014080000",
      "localidad_censal_id": "82014080",
      "localidad_censal_nombre": "Gödeken",
      "municipio_id": "822070",
      "municipio_nombre": "Gödeken",
      "nombre": "GODEKEN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1054899954916,
      "centroide_lon": -61.3265015329286,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014090000",
      "localidad_censal_id": "82014090",
      "localidad_censal_nombre": "Los Molinos",
      "municipio_id": "822077",
      "municipio_nombre": "Los Molinos",
      "nombre": "LOS MOLINOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1430061971604,
      "centroide_lon": -61.6066128669784,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014100000",
      "localidad_censal_id": "82014100",
      "localidad_censal_nombre": "Los Nogales",
      "municipio_id": "822091",
      "municipio_nombre": "San José de la Esquina",
      "nombre": "LOS NOGALES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3769918276119,
      "centroide_lon": -61.7121943064881,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014110000",
      "localidad_censal_id": "82014110",
      "localidad_censal_nombre": "Los Quirquinchos",
      "municipio_id": "822084",
      "municipio_nombre": "Los Quirquinchos",
      "nombre": "LOS QUIRQUINCHOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1140485294151,
      "centroide_lon": -61.703312346428,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014120000",
      "localidad_censal_id": "82014120",
      "localidad_censal_nombre": "San José de la Esquina",
      "municipio_id": "822091",
      "municipio_nombre": "San José de la Esquina",
      "nombre": "SAN JOSE DE LA ESQUINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1477729818051,
      "centroide_lon": -61.2778572887319,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014130000",
      "localidad_censal_id": "82014130",
      "localidad_censal_nombre": "Sanford",
      "municipio_id": "822098",
      "municipio_nombre": "Sanford",
      "nombre": "SANFORD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3503085722913,
      "centroide_lon": -61.4460374000461,
      "departamento_id": "82014",
      "departamento_nombre": "Caseros",
      "id": "82014140000",
      "localidad_censal_id": "82014140",
      "localidad_censal_nombre": "Villada",
      "municipio_id": "822105",
      "municipio_nombre": "Villada",
      "nombre": "VILLADA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9823474545911,
      "centroide_lon": -61.7439869348366,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021010000",
      "localidad_censal_id": "82021010",
      "localidad_censal_nombre": "Aldao",
      "municipio_id": "822147",
      "municipio_nombre": "Colonia Aldao",
      "nombre": "ALDAO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5526328296108,
      "centroide_lon": -61.5462650173096,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021020000",
      "localidad_censal_id": "82021020",
      "localidad_censal_nombre": "Angélica",
      "municipio_id": "822112",
      "municipio_nombre": "Angélica",
      "nombre": "ANGELICA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9981187823937,
      "centroide_lon": -61.4325186889204,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021030000",
      "localidad_censal_id": "82021030",
      "localidad_censal_nombre": "Ataliva",
      "municipio_id": "822119",
      "municipio_nombre": "Ataliva",
      "nombre": "ATALIVA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4236023757354,
      "centroide_lon": -61.4244708809147,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021040000",
      "localidad_censal_id": "82021040",
      "localidad_censal_nombre": "Aurelia",
      "municipio_id": "822126",
      "municipio_nombre": "Aurelia",
      "nombre": "AURELIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4192515021464,
      "centroide_lon": -62.059146376237,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021050000",
      "localidad_censal_id": "82021050",
      "localidad_censal_nombre": "Barrios Acapulco y Veracruz",
      "municipio_id": "822280",
      "municipio_nombre": "Josefina",
      "nombre": "BARRIOS ACAPULCO Y VERACRUZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2731232182116,
      "centroide_lon": -61.9448854961658,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021060000",
      "localidad_censal_id": "82021060",
      "localidad_censal_nombre": "Bauer y Sigel",
      "municipio_id": "822133",
      "municipio_nombre": "Bauer y Sigel",
      "nombre": "BAUER Y SIGEL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2839795053037,
      "centroide_lon": -61.409342518123,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021070000",
      "localidad_censal_id": "82021070",
      "localidad_censal_nombre": "Bella Italia",
      "municipio_id": "822140",
      "municipio_nombre": "Bella Italia",
      "nombre": "BELLA ITALIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2085396563705,
      "centroide_lon": -61.7255955564221,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021080000",
      "localidad_censal_id": "82021080",
      "localidad_censal_nombre": "Castellanos",
      "municipio_id": "822168",
      "municipio_nombre": "Castellanos",
      "nombre": "CASTELLANOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.855781133807,
      "centroide_lon": -61.8509147420168,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021090000",
      "localidad_censal_id": "82021090",
      "localidad_censal_nombre": "Colonia Bicha",
      "municipio_id": "822154",
      "municipio_nombre": "Colonia Bicha",
      "nombre": "COLONIA BICHA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4338497252165,
      "centroide_lon": -61.8417021711297,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021100000",
      "localidad_censal_id": "82021100",
      "localidad_censal_nombre": "Colonia Cello",
      "municipio_id": "822175",
      "municipio_nombre": "Colonia Cello",
      "nombre": "COLONIA CELLO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6868334221829,
      "centroide_lon": -61.6492968757313,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021110000",
      "localidad_censal_id": "82021110",
      "localidad_censal_nombre": "Colonia Margarita",
      "municipio_id": "822189",
      "municipio_nombre": "Colonia Margarita",
      "nombre": "COLONIA MARGARITA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8392235567556,
      "centroide_lon": -61.4897111638226,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021120000",
      "localidad_censal_id": "82021120",
      "localidad_censal_nombre": "Colonia Raquel",
      "municipio_id": "822196",
      "municipio_nombre": "Colonia Raquel",
      "nombre": "COLONIA RAQUEL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.176158616875,
      "centroide_lon": -61.9194620018005,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021130000",
      "localidad_censal_id": "82021130",
      "localidad_censal_nombre": "Coronel Fraga",
      "municipio_id": "822203",
      "municipio_nombre": "Coronel Fraga",
      "nombre": "CORONEL FRAGA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.097624747292,
      "centroide_lon": -61.6283124096055,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021140000",
      "localidad_censal_id": "82021140",
      "localidad_censal_nombre": "Egusquiza",
      "municipio_id": "822210",
      "municipio_nombre": "Egusquiza",
      "nombre": "EGUSQUIZA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6178586482318,
      "centroide_lon": -61.9329258733186,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021150000",
      "localidad_censal_id": "82021150",
      "localidad_censal_nombre": "Esmeralda",
      "municipio_id": "822217",
      "municipio_nombre": "Esmeralda",
      "nombre": "ESMERALDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.52416914925,
      "centroide_lon": -61.7206457133884,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021160000",
      "localidad_censal_id": "82021160",
      "localidad_censal_nombre": "Estación Clucellas",
      "municipio_id": "822224",
      "municipio_nombre": "Estación Clucellas",
      "nombre": "ESTACION CLUCELLAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3174603823906,
      "centroide_lon": -61.6940034650798,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021170000",
      "localidad_censal_id": "82021170",
      "localidad_censal_nombre": "Estación Saguier",
      "municipio_id": "822336",
      "municipio_nombre": "Saguier",
      "nombre": "ESTACION SAGUIER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9476135702694,
      "centroide_lon": -61.8577735272139,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021180000",
      "localidad_censal_id": "82021180",
      "localidad_censal_nombre": "Eusebia y Carolina",
      "municipio_id": "822231",
      "municipio_nombre": "Eusebia y Carolina",
      "nombre": "EUSEBIA Y CAROLINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5777448514157,
      "centroide_lon": -61.7836661825715,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021190000",
      "localidad_censal_id": "82021190",
      "localidad_censal_nombre": "Eustolia",
      "municipio_id": "822238",
      "municipio_nombre": "Eustolia",
      "nombre": "EUSTOLIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4313990849769,
      "centroide_lon": -62.0634917387843,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021200000",
      "localidad_censal_id": "82021200",
      "localidad_censal_nombre": "Frontera",
      "municipio_id": "820035",
      "municipio_nombre": "Frontera",
      "nombre": "FRONTERA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.652027726261,
      "centroide_lon": -61.8053907511433,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021210000",
      "localidad_censal_id": "82021210",
      "localidad_censal_nombre": "Garibaldi",
      "municipio_id": "822259",
      "municipio_nombre": "Garibaldi",
      "nombre": "GARIBALDI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8702088524931,
      "centroide_lon": -61.3485902390711,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021220000",
      "localidad_censal_id": "82021220",
      "localidad_censal_nombre": "Humberto Primo",
      "municipio_id": "822273",
      "municipio_nombre": "Humberto Primo",
      "nombre": "HUMBERTO PRIMO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4079691369305,
      "centroide_lon": -61.9921847239399,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021230000",
      "localidad_censal_id": "82021230",
      "localidad_censal_nombre": "Josefina",
      "municipio_id": "822280",
      "municipio_nombre": "Josefina",
      "nombre": "JOSEFINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1272471029986,
      "centroide_lon": -61.4529569120506,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021240000",
      "localidad_censal_id": "82021240",
      "localidad_censal_nombre": "Lehmann",
      "municipio_id": "822287",
      "municipio_nombre": "Lehmann",
      "nombre": "LEHMANN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.676627573626,
      "centroide_lon": -61.7536009774385,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021250000",
      "localidad_censal_id": "82021250",
      "localidad_censal_nombre": "María Juana",
      "municipio_id": "822294",
      "municipio_nombre": "María Juana",
      "nombre": "MARIA JUANA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1189046819859,
      "centroide_lon": -61.5151982929847,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021260000",
      "localidad_censal_id": "82021260",
      "localidad_censal_nombre": "Nueva Lehmann",
      "municipio_id": "822287",
      "municipio_nombre": "Lehmann",
      "nombre": "NUEVA LEHMANN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4545505198837,
      "centroide_lon": -61.7074756228147,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021270000",
      "localidad_censal_id": "82021270",
      "localidad_censal_nombre": "Plaza Clucellas",
      "municipio_id": "822308",
      "municipio_nombre": "Plaza Clucellas",
      "nombre": "PLAZA CLUCELLAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3251049515902,
      "centroide_lon": -61.6777373324018,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021280000",
      "localidad_censal_id": "82021280",
      "localidad_censal_nombre": "Plaza Saguier",
      "municipio_id": "822336",
      "municipio_nombre": "Saguier",
      "nombre": "PLAZA SAGUIER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2319180453244,
      "centroide_lon": -61.6101076012158,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021290001",
      "localidad_censal_id": "82021290",
      "localidad_censal_nombre": "Presidente Roca",
      "municipio_id": "822315",
      "municipio_nombre": "Presidente Roca",
      "nombre": "ESTACION PRESIDENTE ROCA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.2296979058147,
      "centroide_lon": -61.6101036240639,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021290002",
      "localidad_censal_id": "82021290",
      "localidad_censal_nombre": "Presidente Roca",
      "municipio_id": "822315",
      "municipio_nombre": "Presidente Roca",
      "nombre": "PRESIDENTE ROCA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0409745475723,
      "centroide_lon": -61.8898826370338,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021300000",
      "localidad_censal_id": "82021300",
      "localidad_censal_nombre": "Pueblo Marini",
      "municipio_id": "822322",
      "municipio_nombre": "Pueblo Marini",
      "nombre": "PUEBLO MARINI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2482482413205,
      "centroide_lon": -61.4998117939868,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021310000",
      "localidad_censal_id": "82021310",
      "localidad_censal_nombre": "Rafaela",
      "municipio_id": "820042",
      "municipio_nombre": "Rafaela",
      "nombre": "RAFAELA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0937041766842,
      "centroide_lon": -61.9032318673573,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021320000",
      "localidad_censal_id": "82021320",
      "localidad_censal_nombre": "Ramona",
      "municipio_id": "822329",
      "municipio_nombre": "Ramona",
      "nombre": "RAMONA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.2128134181244,
      "centroide_lon": -61.7257200007794,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021330000",
      "localidad_censal_id": "82021330",
      "localidad_censal_nombre": "San Antonio",
      "municipio_id": "822343",
      "municipio_nombre": "San Antonio",
      "nombre": "SAN ANTONIO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6999505604131,
      "centroide_lon": -61.5688417180763,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021340000",
      "localidad_censal_id": "82021340",
      "localidad_censal_nombre": "San Vicente",
      "municipio_id": "822350",
      "municipio_nombre": "San Vicente",
      "nombre": "SAN VICENTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.337359049521,
      "centroide_lon": -61.8181783780784,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021350000",
      "localidad_censal_id": "82021350",
      "localidad_censal_nombre": "Santa Clara de Saguier",
      "municipio_id": "822357",
      "municipio_nombre": "Santa Clara de Saguier",
      "nombre": "SANTA CLARA DE SAGUIER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9468555127161,
      "centroide_lon": -61.5612504315515,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021360000",
      "localidad_censal_id": "82021360",
      "localidad_censal_nombre": "Sunchales",
      "municipio_id": "820049",
      "municipio_nombre": "Sunchales",
      "nombre": "SUNCHALES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3575963913649,
      "centroide_lon": -61.5164389963223,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021370000",
      "localidad_censal_id": "82021370",
      "localidad_censal_nombre": "Susana",
      "municipio_id": "822364",
      "municipio_nombre": "Susana",
      "nombre": "SUSANA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8481071493852,
      "centroide_lon": -61.5924018154956,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021380000",
      "localidad_censal_id": "82021380",
      "localidad_censal_nombre": "Tacural",
      "municipio_id": "822371",
      "municipio_nombre": "Tacural",
      "nombre": "TACURAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1923707919053,
      "centroide_lon": -61.8336140985666,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021390000",
      "localidad_censal_id": "82021390",
      "localidad_censal_nombre": "Vila",
      "municipio_id": "822385",
      "municipio_nombre": "Vila",
      "nombre": "VILA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4418599622731,
      "centroide_lon": -62.0297169711427,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021400000",
      "localidad_censal_id": "82021400",
      "localidad_censal_nombre": "Villa Josefina",
      "municipio_id": "820035",
      "municipio_nombre": "Frontera",
      "nombre": "VILLA JOSEFINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3391346339244,
      "centroide_lon": -61.622588386296,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021410000",
      "localidad_censal_id": "82021410",
      "localidad_censal_nombre": "Villa San José",
      "municipio_id": "822392",
      "municipio_nombre": "Villa San José",
      "nombre": "VILLA SAN JOSE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7402529602428,
      "centroide_lon": -61.3409665931228,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021420000",
      "localidad_censal_id": "82021420",
      "localidad_censal_nombre": "Virginia",
      "municipio_id": "822399",
      "municipio_nombre": "Virginia",
      "nombre": "VIRGINIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5643972137494,
      "centroide_lon": -61.898425060781,
      "departamento_id": "82021",
      "departamento_nombre": "Castellanos",
      "id": "82021430000",
      "localidad_censal_id": "82021430",
      "localidad_censal_nombre": "Zenón Pereyra",
      "municipio_id": "822406",
      "municipio_nombre": "Zenón Pereyra",
      "nombre": "ZENON PEREYRA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5401979791092,
      "centroide_lon": -61.1246337163915,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028010000",
      "localidad_censal_id": "82028010",
      "localidad_censal_nombre": "Alcorta",
      "municipio_id": "822413",
      "municipio_nombre": "Alcorta",
      "nombre": "ALCORTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.2860666179194,
      "centroide_lon": -60.2720977056001,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028020000",
      "localidad_censal_id": "82028020",
      "localidad_censal_nombre": "Barrio Arroyo del Medio",
      "municipio_id": "820056",
      "municipio_nombre": "Villa Constitución",
      "nombre": "BARRIO ARROYO DEL MEDIO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.255594638469,
      "centroide_lon": -60.3897758707887,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028030000",
      "localidad_censal_id": "82028030",
      "localidad_censal_nombre": "Barrio Mitre",
      "municipio_id": "822490",
      "municipio_nombre": "Pavón",
      "nombre": "BARRIO MITRE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4600615218579,
      "centroide_lon": -61.3189324391797,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028040000",
      "localidad_censal_id": "82028040",
      "localidad_censal_nombre": "Bombal",
      "municipio_id": "822420",
      "municipio_nombre": "Bombal",
      "nombre": "BOMBAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5174175561246,
      "centroide_lon": -60.6132968867711,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028050000",
      "localidad_censal_id": "82028050",
      "localidad_censal_nombre": "Cañada Rica",
      "municipio_id": "822427",
      "municipio_nombre": "Cañada Rica",
      "nombre": "CAÑADA RICA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3984208661427,
      "centroide_lon": -60.6241114199013,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028060000",
      "localidad_censal_id": "82028060",
      "localidad_censal_nombre": "Cepeda",
      "municipio_id": "822434",
      "municipio_nombre": "Cepeda",
      "nombre": "CEPEDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.2628625471248,
      "centroide_lon": -60.3804644639294,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028070000",
      "localidad_censal_id": "82028070",
      "localidad_censal_nombre": "Empalme Villa Constitución",
      "municipio_id": "822441",
      "municipio_nombre": "Empalme Villa Constitución",
      "nombre": "EMPALME VILLA CONSTITUCION",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.441023527734,
      "centroide_lon": -61.473143421839,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028080000",
      "localidad_censal_id": "82028080",
      "localidad_censal_nombre": "Firmat",
      "municipio_id": "820063",
      "municipio_nombre": "Firmat",
      "nombre": "FIRMAT",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6018434911159,
      "centroide_lon": -60.5989383522422,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028090000",
      "localidad_censal_id": "82028090",
      "localidad_censal_nombre": "General Gelly",
      "municipio_id": "822448",
      "municipio_nombre": "General Gelly",
      "nombre": "GENERAL GELLY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3697098731789,
      "centroide_lon": -60.5094130215383,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028100000",
      "localidad_censal_id": "82028100",
      "localidad_censal_nombre": "Godoy",
      "municipio_id": "822455",
      "municipio_nombre": "Godoy",
      "nombre": "GODOY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.496020689866,
      "centroide_lon": -60.5122133295946,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028110000",
      "localidad_censal_id": "82028110",
      "localidad_censal_nombre": "Juan B. Molina",
      "municipio_id": "822462",
      "municipio_nombre": "Juan Bernabé Molina",
      "nombre": "JUAN B. MOLINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.71761494002,
      "centroide_lon": -61.0500179215707,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028120000",
      "localidad_censal_id": "82028120",
      "localidad_censal_nombre": "Juncal",
      "municipio_id": "822469",
      "municipio_nombre": "Juncal",
      "nombre": "JUNCAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3596257970974,
      "centroide_lon": -60.6584568664422,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028130000",
      "localidad_censal_id": "82028130",
      "localidad_censal_nombre": "La Vanguardia",
      "municipio_id": "822476",
      "municipio_nombre": "La Vanguardia",
      "nombre": "LA VANGUARDIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4851439474937,
      "centroide_lon": -60.9567885809825,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028140000",
      "localidad_censal_id": "82028140",
      "localidad_censal_nombre": "Máximo Paz",
      "municipio_id": "822483",
      "municipio_nombre": "M9Ximo Paz",
      "nombre": "MAXIMO PAZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2430754805422,
      "centroide_lon": -60.4062757935089,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028150000",
      "localidad_censal_id": "82028150",
      "localidad_censal_nombre": "Pavón",
      "municipio_id": "822490",
      "municipio_nombre": "Pavón",
      "nombre": "PAVON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3134173933438,
      "centroide_lon": -60.8249758944661,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028160000",
      "localidad_censal_id": "82028160",
      "localidad_censal_nombre": "Pavón Arriba",
      "municipio_id": "822497",
      "municipio_nombre": "Pavón Arriba",
      "nombre": "PAVON ARRIBA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5411378598405,
      "centroide_lon": -60.804329558703,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028170000",
      "localidad_censal_id": "82028170",
      "localidad_censal_nombre": "Peyrano",
      "municipio_id": "822504",
      "municipio_nombre": "Peyrano",
      "nombre": "PEYRANO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.335855095367,
      "centroide_lon": -60.4608740548372,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028180000",
      "localidad_censal_id": "82028180",
      "localidad_censal_nombre": "Rueda",
      "municipio_id": "822511",
      "municipio_nombre": "Rueda",
      "nombre": "RUEDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4385471621789,
      "centroide_lon": -60.7911542772276,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028190000",
      "localidad_censal_id": "82028190",
      "localidad_censal_nombre": "Santa Teresa",
      "municipio_id": "822518",
      "municipio_nombre": "Santa Teresa",
      "nombre": "SANTA TERESA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4330128597835,
      "centroide_lon": -60.6301906902003,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028200000",
      "localidad_censal_id": "82028200",
      "localidad_censal_nombre": "Sargento Cabral",
      "municipio_id": "822525",
      "municipio_nombre": "Sargento Cabral",
      "nombre": "SARGENTO CABRAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4178567628002,
      "centroide_lon": -60.5572137324943,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028210000",
      "localidad_censal_id": "82028210",
      "localidad_censal_nombre": "Stephenson",
      "municipio_id": "822434",
      "municipio_nombre": "Cepeda",
      "nombre": "STEPHENSON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3122172562755,
      "centroide_lon": -60.3120513587534,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028220000",
      "localidad_censal_id": "82028220",
      "localidad_censal_nombre": "Theobald",
      "municipio_id": "822532",
      "municipio_nombre": "Theobald",
      "nombre": "THEOBALD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2324133911798,
      "centroide_lon": -60.3324988273466,
      "departamento_id": "82028",
      "departamento_nombre": "Villa Constitución",
      "id": "82028230000",
      "localidad_censal_id": "82028230",
      "localidad_censal_nombre": "Villa Constitución",
      "municipio_id": "820056",
      "municipio_nombre": "Villa Constitución",
      "nombre": "VILLA CONSTITUCION",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2023253009306,
      "centroide_lon": -60.1614504193394,
      "departamento_id": "82035",
      "departamento_nombre": "Garay",
      "id": "82035010000",
      "localidad_censal_id": "82035010",
      "localidad_censal_nombre": "Cayastá",
      "municipio_id": "822539",
      "municipio_nombre": "Cayastá",
      "nombre": "CAYASTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0992706781177,
      "centroide_lon": -60.0881512872483,
      "departamento_id": "82035",
      "departamento_nombre": "Garay",
      "id": "82035020000",
      "localidad_censal_id": "82035020",
      "localidad_censal_nombre": "Helvecia",
      "municipio_id": "822553",
      "municipio_nombre": "Helvecia",
      "nombre": "HELVECIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4956365531425,
      "centroide_lon": -60.4286467714748,
      "departamento_id": "82035",
      "departamento_nombre": "Garay",
      "id": "82035030000",
      "localidad_censal_id": "82035030",
      "localidad_censal_nombre": "Los Zapallos",
      "municipio_id": "822567",
      "municipio_nombre": "Santa Rosa de Calchines",
      "nombre": "LOS ZAPALLOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9229849971234,
      "centroide_lon": -60.0481154588667,
      "departamento_id": "82035",
      "departamento_nombre": "Garay",
      "id": "82035040000",
      "localidad_censal_id": "82035040",
      "localidad_censal_nombre": "Saladero Mariano Cabal",
      "municipio_id": "822560",
      "municipio_nombre": "Saladero Mariano Cabal",
      "nombre": "SALADERO MARIANO CABAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.422365592266,
      "centroide_lon": -60.3348940800468,
      "departamento_id": "82035",
      "departamento_nombre": "Garay",
      "id": "82035050000",
      "localidad_censal_id": "82035050",
      "localidad_censal_nombre": "Santa Rosa de Calchines",
      "municipio_id": "822567",
      "municipio_nombre": "Santa Rosa de Calchines",
      "nombre": "SANTA ROSA DE CALCHINES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3345323396045,
      "centroide_lon": -62.3748007656351,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042010000",
      "localidad_censal_id": "82042010",
      "localidad_censal_nombre": "Aarón Castellanos",
      "municipio_id": "822574",
      "municipio_nombre": "Aarón Castellanos",
      "nombre": "AARON CASTELLANOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.136118440775,
      "centroide_lon": -62.4229084851079,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042020000",
      "localidad_censal_id": "82042020",
      "localidad_censal_nombre": "Amenábar",
      "municipio_id": "822581",
      "municipio_nombre": "Amenábar",
      "nombre": "AMENABAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4414158758935,
      "centroide_lon": -62.0868714692657,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042030000",
      "localidad_censal_id": "82042030",
      "localidad_censal_nombre": "Cafferata",
      "municipio_id": "822588",
      "municipio_nombre": "Cafferata",
      "nombre": "CAFFERATA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.4103440716357,
      "centroide_lon": -61.6070433975613,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042040000",
      "localidad_censal_id": "82042040",
      "localidad_censal_nombre": "Cañada del Ucle",
      "municipio_id": "822595",
      "municipio_nombre": "Cañada del Ucle",
      "nombre": "CAÑADA DEL UCLE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7327788629688,
      "centroide_lon": -61.7609271479418,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042050000",
      "localidad_censal_id": "82042050",
      "localidad_censal_nombre": "Carmen",
      "municipio_id": "822602",
      "municipio_nombre": "Carmen",
      "nombre": "CARMEN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5982834566103,
      "centroide_lon": -61.3117684362059,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042060000",
      "localidad_censal_id": "82042060",
      "localidad_censal_nombre": "Carreras",
      "municipio_id": "822609",
      "municipio_nombre": "Carreras",
      "nombre": "CARRERAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8005299368969,
      "centroide_lon": -61.7440181936851,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042070000",
      "localidad_censal_id": "82042070",
      "localidad_censal_nombre": "Chapuy",
      "municipio_id": "822616",
      "municipio_nombre": "Chapuy",
      "nombre": "CHAPUY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6007825133126,
      "centroide_lon": -61.6046646604986,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042080000",
      "localidad_censal_id": "82042080",
      "localidad_censal_nombre": "Chovet",
      "municipio_id": "822623",
      "municipio_nombre": "Chovet",
      "nombre": "CHOVET",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1846191181657,
      "centroide_lon": -62.0235323680591,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042090000",
      "localidad_censal_id": "82042090",
      "localidad_censal_nombre": "Christophersen",
      "municipio_id": "822630",
      "municipio_nombre": "Christophersen",
      "nombre": "CHRISTOPHERSEN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3743429234538,
      "centroide_lon": -62.1289273623308,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042100000",
      "localidad_censal_id": "82042100",
      "localidad_censal_nombre": "Diego de Alvear",
      "municipio_id": "822637",
      "municipio_nombre": "Diego de Alvear",
      "nombre": "DIEGO DE ALVEAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7016560168056,
      "centroide_lon": -61.6163727056605,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042110000",
      "localidad_censal_id": "82042110",
      "localidad_censal_nombre": "Elortondo",
      "municipio_id": "822644",
      "municipio_nombre": "Elortondo",
      "nombre": "ELORTONDO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.4580496833225,
      "centroide_lon": -61.4914525089409,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042120000",
      "localidad_censal_id": "82042120",
      "localidad_censal_nombre": "Firmat",
      "municipio_id": "820063",
      "municipio_nombre": "Firmat",
      "nombre": "FIRMAT",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8028556653324,
      "centroide_lon": -61.3358033222625,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042130000",
      "localidad_censal_id": "82042130",
      "localidad_censal_nombre": "Hughes",
      "municipio_id": "822651",
      "municipio_nombre": "Hughes",
      "nombre": "HUGHES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5446704008322,
      "centroide_lon": -61.9736324283312,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042140000",
      "localidad_censal_id": "82042140",
      "localidad_censal_nombre": "La Chispa",
      "municipio_id": "822658",
      "municipio_nombre": "La Chispa",
      "nombre": "LA CHISPA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7196527453715,
      "centroide_lon": -61.3149208168028,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042150000",
      "localidad_censal_id": "82042150",
      "localidad_censal_nombre": "Labordeboy",
      "municipio_id": "822665",
      "municipio_nombre": "Labordeboy",
      "nombre": "LABORDEBOY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1663295663381,
      "centroide_lon": -62.428038027595,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042160000",
      "localidad_censal_id": "82042160",
      "localidad_censal_nombre": "Lazzarino",
      "municipio_id": "822672",
      "municipio_nombre": "Lazzarino",
      "nombre": "LAZZARINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7244236308417,
      "centroide_lon": -62.2478391856324,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042170000",
      "localidad_censal_id": "82042170",
      "localidad_censal_nombre": "Maggiolo",
      "municipio_id": "822679",
      "municipio_nombre": "Maggiolo",
      "nombre": "MAGGIOLO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0062849828557,
      "centroide_lon": -61.900439675435,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042180000",
      "localidad_censal_id": "82042180",
      "localidad_censal_nombre": "María Teresa",
      "municipio_id": "822686",
      "municipio_nombre": "María Teresa",
      "nombre": "MARIA TERESA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6619144262235,
      "centroide_lon": -61.4576887408087,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042190000",
      "localidad_censal_id": "82042190",
      "localidad_censal_nombre": "Melincué",
      "municipio_id": "822693",
      "municipio_nombre": "Melincué",
      "nombre": "MELINCUE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5299502620402,
      "centroide_lon": -61.4662068731284,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042200000",
      "localidad_censal_id": "82042200",
      "localidad_censal_nombre": "Miguel Torres",
      "municipio_id": "822700",
      "municipio_nombre": "Miguel Torres",
      "nombre": "MIGUEL TORRES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.6428623685969,
      "centroide_lon": -61.8577974691015,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042210000",
      "localidad_censal_id": "82042210",
      "localidad_censal_nombre": "Murphy",
      "municipio_id": "822707",
      "municipio_nombre": "Murphy",
      "nombre": "MURPHY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.2636098414032,
      "centroide_lon": -62.7117038844931,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042220000",
      "localidad_censal_id": "82042220",
      "localidad_censal_nombre": "Rufino",
      "municipio_id": "820070",
      "municipio_nombre": "Rufino",
      "nombre": "RUFINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8723970130663,
      "centroide_lon": -62.0917158307714,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042230000",
      "localidad_censal_id": "82042230",
      "localidad_censal_nombre": "San Eduardo",
      "municipio_id": "822714",
      "municipio_nombre": "San Eduardo",
      "nombre": "SAN EDUARDO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.5905400040189,
      "centroide_lon": -62.1244693933144,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042240000",
      "localidad_censal_id": "82042240",
      "localidad_censal_nombre": "San Francisco de Santa Fe",
      "municipio_id": "822721",
      "municipio_nombre": "San Francisco de Santa Fe",
      "nombre": "SAN FRANCISCO DE SANTA FE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.3265186547551,
      "centroide_lon": -62.0379529693876,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042250000",
      "localidad_censal_id": "82042250",
      "localidad_censal_nombre": "San Gregorio",
      "municipio_id": "822728",
      "municipio_nombre": "San Gregorio",
      "nombre": "SAN GREGORIO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0095285599362,
      "centroide_lon": -62.2424503438155,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042260000",
      "localidad_censal_id": "82042260",
      "localidad_censal_nombre": "Sancti Spiritu",
      "municipio_id": "822735",
      "municipio_nombre": "Sancti Spiritu",
      "nombre": "SANCTI SPIRITU",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.8894892658382,
      "centroide_lon": -61.6965906556385,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042270000",
      "localidad_censal_id": "82042270",
      "localidad_censal_nombre": "Santa Isabel",
      "municipio_id": "822742",
      "municipio_nombre": "Santa Isabel",
      "nombre": "SANTA ISABEL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.1916130913817,
      "centroide_lon": -61.5272264570788,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042280000",
      "localidad_censal_id": "82042280",
      "localidad_censal_nombre": "Teodelina",
      "municipio_id": "822749",
      "municipio_nombre": "Teodelina",
      "nombre": "TEODELINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.747315292187,
      "centroide_lon": -61.9695358692001,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042290000",
      "localidad_censal_id": "82042290",
      "localidad_censal_nombre": "Venado Tuerto",
      "municipio_id": "820277",
      "municipio_nombre": "Venado Tuerto",
      "nombre": "VENADO TUERTO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.0061339956012,
      "centroide_lon": -61.6063880455097,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042300000",
      "localidad_censal_id": "82042300",
      "localidad_censal_nombre": "Villa Cañás",
      "municipio_id": "820084",
      "municipio_nombre": "Villa Cañás",
      "nombre": "VILLA CAÑAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.7942942799185,
      "centroide_lon": -61.2114409469748,
      "departamento_id": "82042",
      "departamento_nombre": "General López",
      "id": "82042310000",
      "localidad_censal_id": "82042310",
      "localidad_censal_nombre": "Wheelwright",
      "municipio_id": "822756",
      "municipio_nombre": "Wheelwright",
      "nombre": "WHEELWRIGHT",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7250869970621,
      "centroide_lon": -59.4804164807602,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049010000",
      "localidad_censal_id": "82049010",
      "localidad_censal_nombre": "Arroyo Ceibal",
      "municipio_id": "822763",
      "municipio_nombre": "Arroyo Ceibal",
      "nombre": "ARROYO CEIBAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -29.1193659780886,
      "centroide_lon": -59.6592512444638,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049020000",
      "localidad_censal_id": "82049020",
      "localidad_censal_nombre": "Avellaneda",
      "municipio_id": "820091",
      "municipio_nombre": "Avellaneda",
      "nombre": "AVELLANEDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2752681077911,
      "centroide_lon": -59.8472221836471,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049030000",
      "localidad_censal_id": "82049030",
      "localidad_censal_nombre": "Berna",
      "municipio_id": "822770",
      "municipio_nombre": "Berna",
      "nombre": "BERNA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1334936783763,
      "centroide_lon": -59.9473145917686,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049040000",
      "localidad_censal_id": "82049040",
      "localidad_censal_nombre": "El Araza",
      "municipio_id": "822777",
      "municipio_nombre": "El Arazá",
      "nombre": "EL ARAZA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2302084157377,
      "centroide_lon": -59.2639844821107,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049050000",
      "localidad_censal_id": "82049050",
      "localidad_censal_nombre": "El Rabón",
      "municipio_id": "822784",
      "municipio_nombre": "El Rabón",
      "nombre": "EL RABON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0427359947317,
      "centroide_lon": -59.2187469070082,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049060000",
      "localidad_censal_id": "82049060",
      "localidad_censal_nombre": "Florencia",
      "municipio_id": "822798",
      "municipio_nombre": "Florencia",
      "nombre": "FLORENCIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9453022044499,
      "centroide_lon": -59.5634151929235,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049070000",
      "localidad_censal_id": "82049070",
      "localidad_censal_nombre": "Guadalupe Norte",
      "municipio_id": "822805",
      "municipio_nombre": "Guadalupe Norte",
      "nombre": "GUADALUPE NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.759813885955,
      "centroide_lon": -59.5772324985847,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049080000",
      "localidad_censal_id": "82049080",
      "localidad_censal_nombre": "Ingeniero Chanourdie",
      "municipio_id": "822812",
      "municipio_nombre": "Ingeniero Chanourdie",
      "nombre": "INGENIERO CHANOURDIE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.493935731005,
      "centroide_lon": -59.2950745667521,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049090000",
      "localidad_censal_id": "82049090",
      "localidad_censal_nombre": "La Isleta",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA ISLETA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9728525913241,
      "centroide_lon": -59.8484991818248,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049100000",
      "localidad_censal_id": "82049100",
      "localidad_censal_nombre": "La Sarita",
      "municipio_id": "822819",
      "municipio_nombre": "La Sarita",
      "nombre": "LA SARITA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8429706211446,
      "centroide_lon": -59.6379287718138,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049110000",
      "localidad_censal_id": "82049110",
      "localidad_censal_nombre": "Lanteri",
      "municipio_id": "822826",
      "municipio_nombre": "Lanteri",
      "nombre": "LANTERI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8490101824631,
      "centroide_lon": -59.5005781021303,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049120000",
      "localidad_censal_id": "82049120",
      "localidad_censal_nombre": "Las Garzas",
      "municipio_id": "822833",
      "municipio_nombre": "Las Garzas",
      "nombre": "LAS GARZAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3540749299597,
      "centroide_lon": -59.2595521000375,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049130000",
      "localidad_censal_id": "82049130",
      "localidad_censal_nombre": "Las Toscas",
      "municipio_id": "820098",
      "municipio_nombre": "Las Toscas",
      "nombre": "LAS TOSCAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3701211027814,
      "centroide_lon": -59.7378677770758,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049140000",
      "localidad_censal_id": "82049140",
      "localidad_censal_nombre": "Los Laureles",
      "municipio_id": "822840",
      "municipio_nombre": "Los Laureles",
      "nombre": "LOS LAURELES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3506755184496,
      "centroide_lon": -59.9705883109423,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049150000",
      "localidad_censal_id": "82049150",
      "localidad_censal_nombre": "Malabrigo",
      "municipio_id": "820105",
      "municipio_nombre": "Malabrigo",
      "nombre": "MALABRIGO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8716623885107,
      "centroide_lon": -59.86560439575,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049160000",
      "localidad_censal_id": "82049160",
      "localidad_censal_nombre": "Paraje San Manuel",
      "municipio_id": "822819",
      "municipio_nombre": "La Sarita",
      "nombre": "PARAJE SAN MANUEL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2349873699001,
      "centroide_lon": -59.5802691789886,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049170000",
      "localidad_censal_id": "82049170",
      "localidad_censal_nombre": "Puerto Reconquista",
      "municipio_id": "820112",
      "municipio_nombre": "Reconquista",
      "nombre": "PUERTO RECONQUISTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -29.1451468389264,
      "centroide_lon": -59.6510730563582,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049180000",
      "localidad_censal_id": "82049180",
      "localidad_censal_nombre": "Reconquista",
      "municipio_id": "820112",
      "municipio_nombre": "Reconquista",
      "nombre": "RECONQUISTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3823470938865,
      "centroide_lon": -59.2645742897512,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049190000",
      "localidad_censal_id": "82049190",
      "localidad_censal_nombre": "San Antonio de Obligado",
      "municipio_id": "822854",
      "municipio_nombre": "San Antonio de Obligado",
      "nombre": "SAN ANTONIO DE OBLIGADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4202062467088,
      "centroide_lon": -59.2556740044459,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049200000",
      "localidad_censal_id": "82049200",
      "localidad_censal_nombre": "Tacuarendí",
      "municipio_id": "822861",
      "municipio_nombre": "Tacuarendí",
      "nombre": "TACUARENDI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4933207178133,
      "centroide_lon": -59.6141214862397,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049210000",
      "localidad_censal_id": "82049210",
      "localidad_censal_nombre": "Villa Ana",
      "municipio_id": "822868",
      "municipio_nombre": "Villa Ana",
      "nombre": "VILLA ANA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2450754331528,
      "centroide_lon": -59.4547598305134,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049220000",
      "localidad_censal_id": "82049220",
      "localidad_censal_nombre": "Villa Guillermina",
      "municipio_id": "822875",
      "municipio_nombre": "Villa Guillermina",
      "nombre": "VILLA GUILLERMINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4904509578913,
      "centroide_lon": -59.3587641281681,
      "departamento_id": "82049",
      "departamento_nombre": "General Obligado",
      "id": "82049230000",
      "localidad_censal_id": "82049230",
      "localidad_censal_nombre": "Villa Ocampo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA OCAMPO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6103776107001,
      "centroide_lon": -61.3244409322018,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056010000",
      "localidad_censal_id": "82056010",
      "localidad_censal_nombre": "Barrio Cicarelli",
      "municipio_id": "820133",
      "municipio_nombre": "Totoras",
      "nombre": "BARRIO CICARELLI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7399961772877,
      "centroide_lon": -61.2915869736645,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056020000",
      "localidad_censal_id": "82056020",
      "localidad_censal_nombre": "Bustinza",
      "municipio_id": "822882",
      "municipio_nombre": "Bustinza",
      "nombre": "BUSTINZA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8166867292145,
      "centroide_lon": -61.3899661468272,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056030000",
      "localidad_censal_id": "82056030",
      "localidad_censal_nombre": "Cañada de Gómez",
      "municipio_id": "820126",
      "municipio_nombre": "Cañada de Gómez",
      "nombre": "CAÑADA DE GOMEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5112871642714,
      "centroide_lon": -61.0305147885477,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056040000",
      "localidad_censal_id": "82056040",
      "localidad_censal_nombre": "Carrizales",
      "municipio_id": "822889",
      "municipio_nombre": "Carrizales",
      "nombre": "CARRIZALES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4634203095763,
      "centroide_lon": -61.2910434085442,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056050000",
      "localidad_censal_id": "82056050",
      "localidad_censal_nombre": "Classon",
      "municipio_id": "822896",
      "municipio_nombre": "Clason",
      "nombre": "CLASSON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5988903254118,
      "centroide_lon": -61.3767121670301,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056060000",
      "localidad_censal_id": "82056060",
      "localidad_censal_nombre": "Colonia Médici",
      "municipio_id": "820133",
      "municipio_nombre": "Totoras",
      "nombre": "COLONIA MEDICI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8494610803638,
      "centroide_lon": -61.2545569082818,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056070000",
      "localidad_censal_id": "82056070",
      "localidad_censal_nombre": "Correa",
      "municipio_id": "822903",
      "municipio_nombre": "Correa",
      "nombre": "CORREA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5539810535705,
      "centroide_lon": -61.2197995428986,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056080000",
      "localidad_censal_id": "82056080",
      "localidad_censal_nombre": "Larguía",
      "municipio_id": "820133",
      "municipio_nombre": "Totoras",
      "nombre": "LARGUIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7147297231149,
      "centroide_lon": -61.0226073568773,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056090000",
      "localidad_censal_id": "82056090",
      "localidad_censal_nombre": "Lucio V. López",
      "municipio_id": "822910",
      "municipio_nombre": "Lucio V. López",
      "nombre": "LUCIO V. LOPEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5758215248523,
      "centroide_lon": -60.8553515331513,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056100000",
      "localidad_censal_id": "82056100",
      "localidad_censal_nombre": "Oliveros",
      "municipio_id": "822917",
      "municipio_nombre": "Oliveros",
      "nombre": "OLIVEROS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6717914164971,
      "centroide_lon": -60.8761806736397,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056110000",
      "localidad_censal_id": "82056110",
      "localidad_censal_nombre": "Pueblo Andino",
      "municipio_id": "822924",
      "municipio_nombre": "Pueblo Andino",
      "nombre": "PUEBLO ANDINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6680220468782,
      "centroide_lon": -61.0890486524519,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056120000",
      "localidad_censal_id": "82056120",
      "localidad_censal_nombre": "Salto Grande",
      "municipio_id": "822931",
      "municipio_nombre": "Salto Grande",
      "nombre": "SALTO GRANDE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6055740792488,
      "centroide_lon": -60.9481762653391,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056130000",
      "localidad_censal_id": "82056130",
      "localidad_censal_nombre": "Serodino",
      "municipio_id": "822938",
      "municipio_nombre": "Serodino",
      "nombre": "SERODINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.5863515505869,
      "centroide_lon": -61.1673292562769,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056140000",
      "localidad_censal_id": "82056140",
      "localidad_censal_nombre": "Totoras",
      "municipio_id": "820133",
      "municipio_nombre": "Totoras",
      "nombre": "TOTORAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.9643241574442,
      "centroide_lon": -61.5478333256653,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056150000",
      "localidad_censal_id": "82056150",
      "localidad_censal_nombre": "Villa Eloísa",
      "municipio_id": "822945",
      "municipio_nombre": "Villa Eloísa",
      "nombre": "VILLA ELOISA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.633431229414,
      "centroide_lon": -60.8208776739619,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056160000",
      "localidad_censal_id": "82056160",
      "localidad_censal_nombre": "Villa La Rivera (Oliveros)",
      "municipio_id": "822917",
      "municipio_nombre": "Oliveros",
      "nombre": "VILLA LA RIVERA - OLIVEROS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.6413858836004,
      "centroide_lon": -60.8234183677953,
      "departamento_id": "82056",
      "departamento_nombre": "Iriondo",
      "id": "82056170000",
      "localidad_censal_id": "82056170",
      "localidad_censal_nombre": "Villa La Rivera (Pueblo Andino)",
      "municipio_id": "822924",
      "municipio_nombre": "Pueblo Andino",
      "nombre": "VILLA LA RIVERA - PUEBLO ANDINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5551884917333,
      "centroide_lon": -60.6783108244693,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063010000",
      "localidad_censal_id": "82063010",
      "localidad_censal_nombre": "Angel Gallardo",
      "municipio_id": "823001",
      "municipio_nombre": "Monte Vera",
      "nombre": "ANGEL GALLARDO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4322203707863,
      "centroide_lon": -60.6676588774069,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063020000",
      "localidad_censal_id": "82063020",
      "localidad_censal_nombre": "Arroyo Aguiar",
      "municipio_id": "822952",
      "municipio_nombre": "Arroyo Aguiar",
      "nombre": "ARROYO AGUIAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5577005393669,
      "centroide_lon": -60.5166665613111,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063030001",
      "localidad_censal_id": "82063030",
      "localidad_censal_nombre": "Arroyo Leyes",
      "municipio_id": "822959",
      "municipio_nombre": "Arroyo Leyes",
      "nombre": "ARROYO LEYES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.5644326802115,
      "centroide_lon": -60.414954582383,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063030002",
      "localidad_censal_id": "82063030",
      "localidad_censal_nombre": "Arroyo Leyes",
      "municipio_id": "822959",
      "municipio_nombre": "Arroyo Leyes",
      "nombre": "RINCON NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1039907773825,
      "centroide_lon": -60.7271526484511,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063040000",
      "localidad_censal_id": "82063040",
      "localidad_censal_nombre": "Cabal",
      "municipio_id": "822966",
      "municipio_nombre": "Cabal",
      "nombre": "CABAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2410698890417,
      "centroide_lon": -60.531189241674,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063050000",
      "localidad_censal_id": "82063050",
      "localidad_censal_nombre": "Campo Andino",
      "municipio_id": "822973",
      "municipio_nombre": "Campo Andino",
      "nombre": "CAMPO ANDINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3995244031815,
      "centroide_lon": -60.7491149288676,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063060000",
      "localidad_censal_id": "82063060",
      "localidad_censal_nombre": "Candioti",
      "municipio_id": "822980",
      "municipio_nombre": "Candioti",
      "nombre": "CANDIOTI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0610951750412,
      "centroide_lon": -60.7464379477764,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063070000",
      "localidad_censal_id": "82063070",
      "localidad_censal_nombre": "Emilia",
      "municipio_id": "822987",
      "municipio_nombre": "Emilia",
      "nombre": "EMILIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3092619266382,
      "centroide_lon": -60.6607816085928,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063080000",
      "localidad_censal_id": "82063080",
      "localidad_censal_nombre": "Laguna Paiva",
      "municipio_id": "820140",
      "municipio_nombre": "Laguna Paiva",
      "nombre": "LAGUNA PAIVA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1862574038904,
      "centroide_lon": -60.7484785939749,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063090000",
      "localidad_censal_id": "82063090",
      "localidad_censal_nombre": "Llambi Campbell",
      "municipio_id": "822994",
      "municipio_nombre": "Llambi Campbell",
      "nombre": "LLAMBI CAMPBELL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5184864327594,
      "centroide_lon": -60.6780997937426,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063100000",
      "localidad_censal_id": "82063100",
      "localidad_censal_nombre": "Monte Vera",
      "municipio_id": "823001",
      "municipio_nombre": "Monte Vera",
      "nombre": "MONTE VERA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2670754515365,
      "centroide_lon": -60.7621355398529,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063110000",
      "localidad_censal_id": "82063110",
      "localidad_censal_nombre": "Nelson",
      "municipio_id": "823008",
      "municipio_nombre": "Nelson",
      "nombre": "NELSON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.567299963712,
      "centroide_lon": -60.6617466185791,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063120000",
      "localidad_censal_id": "82063120",
      "localidad_censal_nombre": "Paraje Chaco Chico",
      "municipio_id": "823001",
      "municipio_nombre": "Monte Vera",
      "nombre": "PARAJE CHACO CHICO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5177974435199,
      "centroide_lon": -60.6115160069236,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063130000",
      "localidad_censal_id": "82063130",
      "localidad_censal_nombre": "Paraje La Costa",
      "municipio_id": "823001",
      "municipio_nombre": "Monte Vera",
      "nombre": "PARAJE LA COSTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.4935807560402,
      "centroide_lon": -60.735411088708,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063140000",
      "localidad_censal_id": "82063140",
      "localidad_censal_nombre": "Recreo",
      "municipio_id": "820144",
      "municipio_nombre": "Recreo",
      "nombre": "RECREO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5286179375841,
      "centroide_lon": -60.4756662346225,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063150000",
      "localidad_censal_id": "82063150",
      "localidad_censal_nombre": "Rincón Potrero",
      "municipio_id": "822959",
      "municipio_nombre": "Arroyo Leyes",
      "nombre": "RINCON POTRERO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.6061471114691,
      "centroide_lon": -60.569631684875,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063160000",
      "localidad_censal_id": "82063160",
      "localidad_censal_nombre": "San José del Rincón",
      "municipio_id": "823022",
      "municipio_nombre": "San José del Rincón",
      "nombre": "SAN JOSE DEL RINCON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.645164805431,
      "centroide_lon": -60.7093147118987,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063170000",
      "localidad_censal_id": "82063170",
      "localidad_censal_nombre": "Santa Fe",
      "municipio_id": "820147",
      "municipio_nombre": "Santa Fe",
      "nombre": "SANTA FE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.6648423299398,
      "centroide_lon": -60.7626399841519,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063180000",
      "localidad_censal_id": "82063180",
      "localidad_censal_nombre": "Santo Tomé",
      "municipio_id": "820154",
      "municipio_nombre": "Santo Tomé",
      "nombre": "SANTO TOME",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.7630683465248,
      "centroide_lon": -60.8898606108667,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063190001",
      "localidad_censal_id": "82063190",
      "localidad_censal_nombre": "Sauce Viejo",
      "municipio_id": "823029",
      "municipio_nombre": "Sauce Viejo",
      "nombre": "SAUCE VIEJO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -31.7077603088951,
      "centroide_lon": -60.7883380397856,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063190002",
      "localidad_censal_id": "82063190",
      "localidad_censal_nombre": "Sauce Viejo",
      "municipio_id": "823029",
      "municipio_nombre": "Sauce Viejo",
      "nombre": "VILLA ADELINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3738964582973,
      "centroide_lon": -60.664717579411,
      "departamento_id": "82063",
      "departamento_nombre": "La Capital",
      "id": "82063200000",
      "localidad_censal_id": "82063200",
      "localidad_censal_nombre": "Villa Laura",
      "municipio_id": "822952",
      "municipio_nombre": "Arroyo Aguiar",
      "nombre": "VILLA LAURA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3666330323604,
      "centroide_lon": -61.0172254853249,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070010000",
      "localidad_censal_id": "82070010",
      "localidad_censal_nombre": "Cavour",
      "municipio_id": "823036",
      "municipio_nombre": "Cavour",
      "nombre": "CAVOUR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2053110846326,
      "centroide_lon": -60.9314003415917,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070020000",
      "localidad_censal_id": "82070020",
      "localidad_censal_nombre": "Cululú",
      "municipio_id": "823050",
      "municipio_nombre": "Cululú",
      "nombre": "CULULU",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6980797094538,
      "centroide_lon": -61.0487593646417,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070030000",
      "localidad_censal_id": "82070030",
      "localidad_censal_nombre": "Elisa",
      "municipio_id": "823057",
      "municipio_nombre": "Elisa",
      "nombre": "ELISA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5487780871674,
      "centroide_lon": -60.8127223355579,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070040000",
      "localidad_censal_id": "82070040",
      "localidad_censal_nombre": "Empalme San Carlos",
      "municipio_id": "823064",
      "municipio_nombre": "Empalme San Carlos",
      "nombre": "EMPALME SAN CARLOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4505966144136,
      "centroide_lon": -60.9310068119638,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070050000",
      "localidad_censal_id": "82070050",
      "localidad_censal_nombre": "Esperanza",
      "municipio_id": "820161",
      "municipio_nombre": "Esperanza",
      "nombre": "ESPERANZA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.246393987915,
      "centroide_lon": -61.2128816673977,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070060000",
      "localidad_censal_id": "82070060",
      "localidad_censal_nombre": "Felicia",
      "municipio_id": "823071",
      "municipio_nombre": "Felicia",
      "nombre": "FELICIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5888736174502,
      "centroide_lon": -60.9388945830321,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070070000",
      "localidad_censal_id": "82070070",
      "localidad_censal_nombre": "Franck",
      "municipio_id": "823078",
      "municipio_nombre": "Franck",
      "nombre": "FRANCK",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.2705026899597,
      "centroide_lon": -61.0727542695818,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070080000",
      "localidad_censal_id": "82070080",
      "localidad_censal_nombre": "Grutly",
      "municipio_id": "823085",
      "municipio_nombre": "Grutly",
      "nombre": "GRUTLY",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1282518725208,
      "centroide_lon": -61.0327676754247,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070090000",
      "localidad_censal_id": "82070090",
      "localidad_censal_nombre": "Hipatía",
      "municipio_id": "823092",
      "municipio_nombre": "Hipatía",
      "nombre": "HIPATIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.4009210645041,
      "centroide_lon": -61.0825515659905,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070100000",
      "localidad_censal_id": "82070100",
      "localidad_censal_nombre": "Humboldt",
      "municipio_id": "823099",
      "municipio_nombre": "Humboldt",
      "nombre": "HUMBOLDT",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7370340516557,
      "centroide_lon": -60.9759511253175,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070110000",
      "localidad_censal_id": "82070110",
      "localidad_censal_nombre": "Jacinto L. Aráuz",
      "municipio_id": "823113",
      "municipio_nombre": "Jacinto L. Arauz",
      "nombre": "JACINTO L. ARAUZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8679477693336,
      "centroide_lon": -60.9718116807155,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070120000",
      "localidad_censal_id": "82070120",
      "localidad_censal_nombre": "La Pelada",
      "municipio_id": "823120",
      "municipio_nombre": "La Pelada",
      "nombre": "LA PELADA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5722370638803,
      "centroide_lon": -60.9959287992772,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070130000",
      "localidad_censal_id": "82070130",
      "localidad_censal_nombre": "Las Tunas",
      "municipio_id": "823127",
      "municipio_nombre": "Las Tunas",
      "nombre": "LAS TUNAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0126440481519,
      "centroide_lon": -60.9114327492504,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070140000",
      "localidad_censal_id": "82070140",
      "localidad_censal_nombre": "María Luisa",
      "municipio_id": "823134",
      "municipio_nombre": "María Luisa",
      "nombre": "MARIA LUISA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7932160880623,
      "centroide_lon": -60.9818059784844,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070150000",
      "localidad_censal_id": "82070150",
      "localidad_censal_nombre": "Matilde",
      "municipio_id": "823141",
      "municipio_nombre": "Matilde",
      "nombre": "MATILDE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.3468823835409,
      "centroide_lon": -61.235725734005,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070160000",
      "localidad_censal_id": "82070160",
      "localidad_censal_nombre": "Nuevo Torino",
      "municipio_id": "823148",
      "municipio_nombre": "Nuevo Torino",
      "nombre": "NUEVO TORINO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.442015817123,
      "centroide_lon": -61.2600249614063,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070170000",
      "localidad_censal_id": "82070170",
      "localidad_censal_nombre": "Pilar",
      "municipio_id": "823155",
      "municipio_nombre": "Pilar",
      "nombre": "PILAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7974834888763,
      "centroide_lon": -61.0111678232689,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070180000",
      "localidad_censal_id": "82070180",
      "localidad_censal_nombre": "Plaza Matilde",
      "municipio_id": "823141",
      "municipio_nombre": "Matilde",
      "nombre": "PLAZA MATILDE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1397689545802,
      "centroide_lon": -60.990254156931,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070190000",
      "localidad_censal_id": "82070190",
      "localidad_censal_nombre": "Progreso",
      "municipio_id": "823162",
      "municipio_nombre": "Progreso",
      "nombre": "PROGRESO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9845868369063,
      "centroide_lon": -61.0218281498813,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070200000",
      "localidad_censal_id": "82070200",
      "localidad_censal_nombre": "Providencia",
      "municipio_id": "823169",
      "municipio_nombre": "Providencia",
      "nombre": "PROVIDENCIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5721513951563,
      "centroide_lon": -61.3782231110791,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070210000",
      "localidad_censal_id": "82070210",
      "localidad_censal_nombre": "Sa Pereyra",
      "municipio_id": "823190",
      "municipio_nombre": "Sa Pereyra",
      "nombre": "SA PEREYRA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6847778866246,
      "centroide_lon": -60.9413147450231,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070220000",
      "localidad_censal_id": "82070220",
      "localidad_censal_nombre": "San Agustín",
      "municipio_id": "823197",
      "municipio_nombre": "San Agustín",
      "nombre": "SAN AGUSTIN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7284179989089,
      "centroide_lon": -61.0913957859145,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070230000",
      "localidad_censal_id": "82070230",
      "localidad_censal_nombre": "San Carlos Centro",
      "municipio_id": "820168",
      "municipio_nombre": "San Carlos Centro",
      "nombre": "SAN CARLOS CENTRO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6743105766747,
      "centroide_lon": -61.0762585195433,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070240000",
      "localidad_censal_id": "82070240",
      "localidad_censal_nombre": "San Carlos Norte",
      "municipio_id": "823204",
      "municipio_nombre": "San Carlos Norte",
      "nombre": "SAN CARLOS NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -31.7575954064363,
      "centroide_lon": -61.1007514980914,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070250000",
      "localidad_censal_id": "82070250",
      "localidad_censal_nombre": "San Carlos Sud",
      "municipio_id": "823211",
      "municipio_nombre": "San Carlos Sud",
      "nombre": "SAN CARLOS SUD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6112124759047,
      "centroide_lon": -61.1425180717315,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070260000",
      "localidad_censal_id": "82070260",
      "localidad_censal_nombre": "San Jerónimo del Sauce",
      "municipio_id": "823218",
      "municipio_nombre": "San Jerónimo del Sauce",
      "nombre": "SAN JERONIMO DEL SAUCE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.5545316682675,
      "centroide_lon": -61.078514153956,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070270000",
      "localidad_censal_id": "82070270",
      "localidad_censal_nombre": "San Jerónimo Norte",
      "municipio_id": "823225",
      "municipio_nombre": "San Jerónimo Norte",
      "nombre": "SAN JERONIMO NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6702105890861,
      "centroide_lon": -61.3480211476449,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070280000",
      "localidad_censal_id": "82070280",
      "localidad_censal_nombre": "San Mariano",
      "municipio_id": "823232",
      "municipio_nombre": "San Mariano",
      "nombre": "SAN MARIANO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7657963305203,
      "centroide_lon": -61.3210236384566,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070290000",
      "localidad_censal_id": "82070290",
      "localidad_censal_nombre": "Santa Clara de Buena Vista",
      "municipio_id": "823239",
      "municipio_nombre": "Santa Clara de Buena Vista",
      "nombre": "SANTA CLARA DE BUENA VISTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1222768363914,
      "centroide_lon": -60.8888854567848,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070300000",
      "localidad_censal_id": "82070300",
      "localidad_censal_nombre": "Santo Domingo",
      "municipio_id": "823260",
      "municipio_nombre": "Santo Domingo",
      "nombre": "SANTO DOMINGO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0606024884219,
      "centroide_lon": -61.167889729979,
      "departamento_id": "82070",
      "departamento_nombre": "Las Colonias",
      "id": "82070310000",
      "localidad_censal_id": "82070310",
      "localidad_censal_nombre": "Sarmiento",
      "municipio_id": "823267",
      "municipio_nombre": "Sarmiento",
      "nombre": "SARMIENTO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7726717957721,
      "centroide_lon": -61.4881512346811,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077010000",
      "localidad_censal_id": "82077010",
      "localidad_censal_nombre": "Esteban Rams",
      "municipio_id": "823281",
      "municipio_nombre": "Esteban Rams",
      "nombre": "ESTEBAN RAMS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0245785095892,
      "centroide_lon": -61.1879373297868,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077020000",
      "localidad_censal_id": "82077020",
      "localidad_censal_nombre": "Gato Colorado",
      "municipio_id": "823288",
      "municipio_nombre": "Gato Colorado",
      "nombre": "GATO COLORADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2296230607257,
      "centroide_lon": -61.5297529930521,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077030000",
      "localidad_censal_id": "82077030",
      "localidad_censal_nombre": "Gregoria Pérez de Denis",
      "municipio_id": "823295",
      "municipio_nombre": "Gregoria Pérez de Denis",
      "nombre": "GREGORIA PEREZ DE DENIS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5042812797874,
      "centroide_lon": -61.6967327802837,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077040000",
      "localidad_censal_id": "82077040",
      "localidad_censal_nombre": "Logroño",
      "municipio_id": "823309",
      "municipio_nombre": "Logroño",
      "nombre": "LOGROÑO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.6678698226711,
      "centroide_lon": -61.867108287483,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077050000",
      "localidad_censal_id": "82077050",
      "localidad_censal_nombre": "Montefiore",
      "municipio_id": "823316",
      "municipio_nombre": "Montefiore",
      "nombre": "MONTEFIORE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9399423031181,
      "centroide_lon": -61.7055999743386,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077060000",
      "localidad_censal_id": "82077060",
      "localidad_censal_nombre": "Pozo Borrado",
      "municipio_id": "823323",
      "municipio_nombre": "Pozo Borrado",
      "nombre": "POZO BORRADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6300342810186,
      "centroide_lon": -61.5069219893981,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077065000",
      "localidad_censal_id": "82077065",
      "localidad_censal_nombre": "San Bernardo",
      "municipio_id": "823330",
      "municipio_nombre": "San Bernardo",
      "nombre": "SAN BERNARDO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3149549140447,
      "centroide_lon": -61.550329937355,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077070000",
      "localidad_censal_id": "82077070",
      "localidad_censal_nombre": "Santa Margarita",
      "municipio_id": "823337",
      "municipio_nombre": "Santa Margarita",
      "nombre": "SANTA MARGARITA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2344732488739,
      "centroide_lon": -61.7719824163622,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077080000",
      "localidad_censal_id": "82077080",
      "localidad_censal_nombre": "Tostado",
      "municipio_id": "820175",
      "municipio_nombre": "Tostado",
      "nombre": "TOSTADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6247095403571,
      "centroide_lon": -61.6279859869967,
      "departamento_id": "82077",
      "departamento_nombre": "9 de Julio",
      "id": "82077090000",
      "localidad_censal_id": "82077090",
      "localidad_censal_nombre": "Villa Minetti",
      "municipio_id": "823344",
      "municipio_nombre": "Villa Minetti",
      "nombre": "VILLA MINETTI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2436505576999,
      "centroide_lon": -60.837195481459,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084010000",
      "localidad_censal_id": "82084010",
      "localidad_censal_nombre": "Acébal",
      "municipio_id": "823351",
      "municipio_nombre": "Acebal",
      "nombre": "ACEBAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2413031665306,
      "centroide_lon": -60.6365751407418,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084020000",
      "localidad_censal_id": "82084020",
      "localidad_censal_nombre": "Albarellos",
      "municipio_id": "823358",
      "municipio_nombre": "Albarellos",
      "nombre": "ALBARELLOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1306253585247,
      "centroide_lon": -60.8039626859935,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084030000",
      "localidad_censal_id": "82084030",
      "localidad_censal_nombre": "Álvarez",
      "municipio_id": "823365",
      "municipio_nombre": "Álvarez",
      "nombre": "ALVAREZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0614584695641,
      "centroide_lon": -60.6159765613734,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084040000",
      "localidad_censal_id": "82084040",
      "localidad_censal_nombre": "Alvear",
      "municipio_id": "823372",
      "municipio_nombre": "Alvear",
      "nombre": "ALVEAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0919516648506,
      "centroide_lon": -60.6993159324653,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084050000",
      "localidad_censal_id": "82084050",
      "localidad_censal_nombre": "Arbilla",
      "municipio_id": "823372",
      "municipio_nombre": "Alvear",
      "nombre": "ARBILLA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2658615254501,
      "centroide_lon": -60.9669188397385,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084060000",
      "localidad_censal_id": "82084060",
      "localidad_censal_nombre": "Arminda",
      "municipio_id": "823379",
      "municipio_nombre": "Arminda",
      "nombre": "ARMINDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1560225761175,
      "centroide_lon": -60.5101308080247,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084070000",
      "localidad_censal_id": "82084070",
      "localidad_censal_nombre": "Arroyo Seco",
      "municipio_id": "820182",
      "municipio_nombre": "Arroyo Seco",
      "nombre": "ARROYO SECO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2376879120566,
      "centroide_lon": -60.8118679160701,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084080000",
      "localidad_censal_id": "82084080",
      "localidad_censal_nombre": "Carmen del Sauce",
      "municipio_id": "823386",
      "municipio_nombre": "Carmen del Sauce",
      "nombre": "CARMEN DEL SAUCE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3175115859048,
      "centroide_lon": -60.6036347878573,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084090000",
      "localidad_censal_id": "82084090",
      "localidad_censal_nombre": "Coronel Bogado",
      "municipio_id": "823393",
      "municipio_nombre": "Coronel Bogado",
      "nombre": "CORONEL BOGADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1854760061025,
      "centroide_lon": -60.7228633313771,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084100000",
      "localidad_censal_id": "82084100",
      "localidad_censal_nombre": "Coronel Rodolfo S. Domínguez",
      "municipio_id": "823470",
      "municipio_nombre": "Coronel Domínguez",
      "nombre": "CORONEL RODOLFO S. DOMINGUEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2466228889596,
      "centroide_lon": -60.7649740219949,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084110000",
      "localidad_censal_id": "82084110",
      "localidad_censal_nombre": "Cuatro Esquinas",
      "municipio_id": "823386",
      "municipio_nombre": "Carmen del Sauce",
      "nombre": "CUATRO ESQUINAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1288733975401,
      "centroide_lon": -60.7130394393533,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084120000",
      "localidad_censal_id": "82084120",
      "localidad_censal_nombre": "El Caramelo",
      "municipio_id": "823365",
      "municipio_nombre": "Álvarez",
      "nombre": "EL CARAMELO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1950187794417,
      "centroide_lon": -60.4706038463519,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084130000",
      "localidad_censal_id": "82084130",
      "localidad_censal_nombre": "Fighiera",
      "municipio_id": "823400",
      "municipio_nombre": "Fighiera",
      "nombre": "FIGHIERA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.922782783063,
      "centroide_lon": -60.8121802825957,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084140000",
      "localidad_censal_id": "82084140",
      "localidad_censal_nombre": "Funes",
      "municipio_id": "820189",
      "municipio_nombre": "Funes",
      "nombre": "FUNES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1121588884353,
      "centroide_lon": -60.5665737837063,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084150000",
      "localidad_censal_id": "82084150",
      "localidad_censal_nombre": "General Lagos",
      "municipio_id": "823407",
      "municipio_nombre": "General Lagos",
      "nombre": "GENERAL LAGOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.8613641656775,
      "centroide_lon": -60.7062159770827,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084160000",
      "localidad_censal_id": "82084160",
      "localidad_censal_nombre": "Granadero Baigorria",
      "municipio_id": "820196",
      "municipio_nombre": "Granadero Baigorria",
      "nombre": "GRANADERO BAIGORRIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8512561304404,
      "centroide_lon": -60.7884936646076,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084170000",
      "localidad_censal_id": "82084170",
      "localidad_censal_nombre": "Ibarlucea",
      "municipio_id": "823414",
      "municipio_nombre": "Ibarlucea",
      "nombre": "IBARLUCEA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.060584803082,
      "centroide_lon": -60.6856440118334,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084180000",
      "localidad_censal_id": "82084180",
      "localidad_censal_nombre": "Kilómetro 101",
      "municipio_id": "823372",
      "municipio_nombre": "Alvear",
      "nombre": "KILOMETRO 101",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0915734104105,
      "centroide_lon": -60.7354254261055,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084190000",
      "localidad_censal_id": "82084190",
      "localidad_censal_nombre": "Los Muchachos - La Alborada",
      "municipio_id": "823421",
      "municipio_nombre": "Piñero",
      "nombre": "LOS MUCHACHOS - LA ALBORADA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0800073993365,
      "centroide_lon": -60.6355226681116,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084200000",
      "localidad_censal_id": "82084200",
      "localidad_censal_nombre": "Monte Flores",
      "municipio_id": "823372",
      "municipio_nombre": "Alvear",
      "nombre": "MONTE FLORES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.99881116903,
      "centroide_lon": -60.7721592101064,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084210000",
      "localidad_censal_id": "82084210",
      "localidad_censal_nombre": "Pérez",
      "municipio_id": "820203",
      "municipio_nombre": "Pérez",
      "nombre": "PEREZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1112071010784,
      "centroide_lon": -60.7964961630631,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084220000",
      "localidad_censal_id": "82084220",
      "localidad_censal_nombre": "Piñero",
      "municipio_id": "823421",
      "municipio_nombre": "Piñero",
      "nombre": "PIÑERO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0730969403696,
      "centroide_lon": -60.5789195429283,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084230000",
      "localidad_censal_id": "82084230",
      "localidad_censal_nombre": "Pueblo Esther",
      "municipio_id": "823428",
      "municipio_nombre": "Pueblo Esther",
      "nombre": "PUEBLO ESTHER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1744833504332,
      "centroide_lon": -60.8971398400989,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084240000",
      "localidad_censal_id": "82084240",
      "localidad_censal_nombre": "Pueblo Muñóz",
      "municipio_id": "823435",
      "municipio_nombre": "Pueblo Muñoz",
      "nombre": "PUEBLO MUÑOZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.2645299958204,
      "centroide_lon": -60.7083345859989,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084250000",
      "localidad_censal_id": "82084250",
      "localidad_censal_nombre": "Pueblo Uranga",
      "municipio_id": "823449",
      "municipio_nombre": "Uranga",
      "nombre": "PUEBLO URANGA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1315113496399,
      "centroide_lon": -60.5078884705177,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084260000",
      "localidad_censal_id": "82084260",
      "localidad_censal_nombre": "Puerto Arroyo Seco",
      "municipio_id": "820182",
      "municipio_nombre": "Arroyo Seco",
      "nombre": "PUERTO ARROYO SECO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.9538142575214,
      "centroide_lon": -60.6515379354516,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084270000",
      "localidad_censal_id": "82084270",
      "localidad_censal_nombre": "Rosario",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROSARIO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0239868445344,
      "centroide_lon": -60.7561883192345,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084280000",
      "localidad_censal_id": "82084280",
      "localidad_censal_nombre": "Soldini",
      "municipio_id": "823442",
      "municipio_nombre": "Soldini",
      "nombre": "SOLDINI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1771929125315,
      "centroide_lon": -60.6677297311463,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084290000",
      "localidad_censal_id": "82084290",
      "localidad_censal_nombre": "Villa Amelia",
      "municipio_id": "823456",
      "municipio_nombre": "Villa Amelia",
      "nombre": "VILLA AMELIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.1271769621356,
      "centroide_lon": -60.7090282248961,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084300000",
      "localidad_censal_id": "82084300",
      "localidad_censal_nombre": "Villa del Plata",
      "municipio_id": "823456",
      "municipio_nombre": "Villa Amelia",
      "nombre": "VILLA DEL PLATA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -33.0224078611601,
      "centroide_lon": -60.6336422555152,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084310000",
      "localidad_censal_id": "82084310",
      "localidad_censal_nombre": "Villa Gobernador Gálvez",
      "municipio_id": "820217",
      "municipio_nombre": "Villa Gobernador Gálvez",
      "nombre": "VILLA GOBERNADOR GALVEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0215698285974,
      "centroide_lon": -60.879303488531,
      "departamento_id": "82084",
      "departamento_nombre": "Rosario",
      "id": "82084320000",
      "localidad_censal_id": "82084320",
      "localidad_censal_nombre": "Zavalla",
      "municipio_id": "823463",
      "municipio_nombre": "Zavalla",
      "nombre": "ZAVALLA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1093090599224,
      "centroide_lon": -60.9437539322397,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091010000",
      "localidad_censal_id": "82091010",
      "localidad_censal_nombre": "Aguará Grande",
      "municipio_id": "823477",
      "municipio_nombre": "Aguará Grande",
      "nombre": "AGUARA GRANDE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.016999568893,
      "centroide_lon": -61.5765785011442,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091020000",
      "localidad_censal_id": "82091020",
      "localidad_censal_nombre": "Ambrosetti",
      "municipio_id": "823484",
      "municipio_nombre": "Ambrosetti",
      "nombre": "AMBROSETTI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2341495301456,
      "centroide_lon": -61.7285807001253,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091030000",
      "localidad_censal_id": "82091030",
      "localidad_censal_nombre": "Arrufo",
      "municipio_id": "823491",
      "municipio_nombre": "Arrufo",
      "nombre": "ARRUFO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9827373481556,
      "centroide_lon": -61.2428659094376,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091040000",
      "localidad_censal_id": "82091040",
      "localidad_censal_nombre": "Balneario La Verde",
      "municipio_id": "823554",
      "municipio_nombre": "Huanqueros",
      "nombre": "BALNEARIO LA VERDE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4615028173869,
      "centroide_lon": -61.2722735128607,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091050000",
      "localidad_censal_id": "82091050",
      "localidad_censal_nombre": "Capivara",
      "municipio_id": "823498",
      "municipio_nombre": "Capivara",
      "nombre": "CAPIVARA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8823371283479,
      "centroide_lon": -61.9452374270961,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091060000",
      "localidad_censal_id": "82091060",
      "localidad_censal_nombre": "Ceres",
      "municipio_id": "820224",
      "municipio_nombre": "Ceres",
      "nombre": "CERES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1449998391803,
      "centroide_lon": -61.9147917462344,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091070000",
      "localidad_censal_id": "82091070",
      "localidad_censal_nombre": "Colonia Ana",
      "municipio_id": "823505",
      "municipio_nombre": "Colonia Ana",
      "nombre": "COLONIA ANA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6691245233432,
      "centroide_lon": -61.7896030300364,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091080000",
      "localidad_censal_id": "82091080",
      "localidad_censal_nombre": "Colonia Bossi",
      "municipio_id": "823512",
      "municipio_nombre": "Colonia Bossi",
      "nombre": "COLONIA BOSSI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3022785889636,
      "centroide_lon": -61.9844913702309,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091090000",
      "localidad_censal_id": "82091090",
      "localidad_censal_nombre": "Colonia Rosa",
      "municipio_id": "823519",
      "municipio_nombre": "Colonia Rosa",
      "nombre": "COLONIA ROSA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.664633909429,
      "centroide_lon": -61.3207552160733,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091100000",
      "localidad_censal_id": "82091100",
      "localidad_censal_nombre": "Constanza",
      "municipio_id": "823526",
      "municipio_nombre": "Constanza",
      "nombre": "CONSTANZA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.397911889967,
      "centroide_lon": -61.6518044215673,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091110000",
      "localidad_censal_id": "82091110",
      "localidad_censal_nombre": "Curupaytí",
      "municipio_id": "823533",
      "municipio_nombre": "Curupaity",
      "nombre": "CURUPAYTI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0056765366847,
      "centroide_lon": -61.8396467676821,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091120000",
      "localidad_censal_id": "82091120",
      "localidad_censal_nombre": "Hersilia",
      "municipio_id": "823547",
      "municipio_nombre": "Hersilia",
      "nombre": "HERSILIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0136747533996,
      "centroide_lon": -61.2192947646911,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091130000",
      "localidad_censal_id": "82091130",
      "localidad_censal_nombre": "Huanqueros",
      "municipio_id": "823554",
      "municipio_nombre": "Huanqueros",
      "nombre": "HUANQUEROS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0876438007142,
      "centroide_lon": -61.1797070550573,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091140000",
      "localidad_censal_id": "82091140",
      "localidad_censal_nombre": "La Cabral",
      "municipio_id": "823561",
      "municipio_nombre": "La Cabral",
      "nombre": "LA CABRAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4196635334388,
      "centroide_lon": -61.0033073134897,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091145000",
      "localidad_censal_id": "82091145",
      "localidad_censal_nombre": "La Lucila",
      "municipio_id": "823575",
      "municipio_nombre": "La Lucila",
      "nombre": "LA LUCILA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1113966728017,
      "centroide_lon": -61.7927608545145,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091150000",
      "localidad_censal_id": "82091150",
      "localidad_censal_nombre": "La Rubia",
      "municipio_id": "823582",
      "municipio_nombre": "La Rubia",
      "nombre": "LA RUBIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8953127955717,
      "centroide_lon": -61.2911992453769,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091160000",
      "localidad_censal_id": "82091160",
      "localidad_censal_nombre": "Las Avispas",
      "municipio_id": "823589",
      "municipio_nombre": "Las Avispas",
      "nombre": "LAS AVISPAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6326345828984,
      "centroide_lon": -61.6277233886562,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091170000",
      "localidad_censal_id": "82091170",
      "localidad_censal_nombre": "Las Palmeras",
      "municipio_id": "823596",
      "municipio_nombre": "Las Palmeras",
      "nombre": "LAS PALMERAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7182008702613,
      "centroide_lon": -61.469140085345,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091180000",
      "localidad_censal_id": "82091180",
      "localidad_censal_nombre": "Moisés Ville",
      "municipio_id": "823603",
      "municipio_nombre": "Moisés Ville",
      "nombre": "MOISES VILLE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4901359922347,
      "centroide_lon": -61.6348224695165,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091190000",
      "localidad_censal_id": "82091190",
      "localidad_censal_nombre": "Monigotes",
      "municipio_id": "823610",
      "municipio_nombre": "Monigotes",
      "nombre": "MONIGOTES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.37245992257,
      "centroide_lon": -61.1326015812096,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091200000",
      "localidad_censal_id": "82091200",
      "localidad_censal_nombre": "Ñanducita",
      "municipio_id": "823624",
      "municipio_nombre": "Ñanducita",
      "nombre": "ÑANDUCITA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7106694051354,
      "centroide_lon": -61.6236952048733,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091210000",
      "localidad_censal_id": "82091210",
      "localidad_censal_nombre": "Palacios",
      "municipio_id": "823631",
      "municipio_nombre": "Palacios",
      "nombre": "PALACIOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.311687011314,
      "centroide_lon": -61.2386444593771,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091220000",
      "localidad_censal_id": "82091220",
      "localidad_censal_nombre": "San Cristóbal",
      "municipio_id": "820231",
      "municipio_nombre": "San Cristóbal",
      "nombre": "SAN CRISTOBAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3602098529676,
      "centroide_lon": -61.9178272634037,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091230000",
      "localidad_censal_id": "82091230",
      "localidad_censal_nombre": "San Guillermo",
      "municipio_id": "823645",
      "municipio_nombre": "San Guillermo",
      "nombre": "SAN GUILLERMO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1866452738191,
      "centroide_lon": -61.178528021881,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091240000",
      "localidad_censal_id": "82091240",
      "localidad_censal_nombre": "Santurce",
      "municipio_id": "823652",
      "municipio_nombre": "Santurce",
      "nombre": "SANTURCE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6225538384456,
      "centroide_lon": -60.9166112630514,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091250000",
      "localidad_censal_id": "82091250",
      "localidad_censal_nombre": "Soledad",
      "municipio_id": "823659",
      "municipio_nombre": "Soledad",
      "nombre": "SOLEDAD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5361453460922,
      "centroide_lon": -61.9616805839765,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091260000",
      "localidad_censal_id": "82091260",
      "localidad_censal_nombre": "Suardi",
      "municipio_id": "823666",
      "municipio_nombre": "Suardi",
      "nombre": "SUARDI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5427027882285,
      "centroide_lon": -60.7477102618236,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091270000",
      "localidad_censal_id": "82091270",
      "localidad_censal_nombre": "Villa Saralegui",
      "municipio_id": "823673",
      "municipio_nombre": "Villa Saralegui",
      "nombre": "VILLA SARALEGUI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2176460355738,
      "centroide_lon": -61.877568026694,
      "departamento_id": "82091",
      "departamento_nombre": "San Cristóbal",
      "id": "82091280000",
      "localidad_censal_id": "82091280",
      "localidad_censal_nombre": "Villa Trinidad",
      "municipio_id": "823680",
      "municipio_nombre": "Villa Trinidad",
      "nombre": "VILLA TRINIDAD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.9103566747475,
      "centroide_lon": -59.8281612200693,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098010000",
      "localidad_censal_id": "82098010",
      "localidad_censal_nombre": "Alejandra",
      "municipio_id": "823687",
      "municipio_nombre": "Alejandra",
      "nombre": "ALEJANDRA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6581364074941,
      "centroide_lon": -60.2307356488107,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098020000",
      "localidad_censal_id": "82098020",
      "localidad_censal_nombre": "Cacique Ariacaiquín",
      "municipio_id": "823694",
      "municipio_nombre": "Cacique Ariacaiquín",
      "nombre": "CACIQUE ARIACAIQUIN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5607537201086,
      "centroide_lon": -59.9270693537131,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098030000",
      "localidad_censal_id": "82098030",
      "localidad_censal_nombre": "Colonia Durán",
      "municipio_id": "823701",
      "municipio_nombre": "Colonia Durán",
      "nombre": "COLONIA DURAN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4477825105448,
      "centroide_lon": -60.1409356882605,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098040000",
      "localidad_censal_id": "82098040",
      "localidad_censal_nombre": "La Brava",
      "municipio_id": "823708",
      "municipio_nombre": "La Brava",
      "nombre": "LA BRAVA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5018294527814,
      "centroide_lon": -59.7485934535059,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098050000",
      "localidad_censal_id": "82098050",
      "localidad_censal_nombre": "Romang",
      "municipio_id": "823715",
      "municipio_nombre": "Romang",
      "nombre": "ROMANG",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5822068409869,
      "centroide_lon": -59.9313985183809,
      "departamento_id": "82098",
      "departamento_nombre": "San Javier",
      "id": "82098060000",
      "localidad_censal_id": "82098060",
      "localidad_censal_nombre": "San Javier",
      "municipio_id": "820238",
      "municipio_nombre": "San Javier",
      "nombre": "SAN JAVIER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0800271942926,
      "centroide_lon": -60.9770049639573,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105010000",
      "localidad_censal_id": "82105010",
      "localidad_censal_nombre": "Arocena",
      "municipio_id": "823722",
      "municipio_nombre": "Arocena",
      "nombre": "AROCENA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3349560866515,
      "centroide_lon": -60.8760522539552,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105020000",
      "localidad_censal_id": "82105020",
      "localidad_censal_nombre": "Balneario Monje",
      "municipio_id": "823827",
      "municipio_nombre": "Monje",
      "nombre": "BALNEARIO MONJE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2366357816462,
      "centroide_lon": -60.9827401330363,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105030000",
      "localidad_censal_id": "82105030",
      "localidad_censal_nombre": "Barrancas",
      "municipio_id": "823729",
      "municipio_nombre": "Barrancas",
      "nombre": "BARRANCAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8320800807618,
      "centroide_lon": -60.8719647067245,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105040000",
      "localidad_censal_id": "82105040",
      "localidad_censal_nombre": "Barrio Caima",
      "municipio_id": "823764",
      "municipio_nombre": "Desvío Arijón",
      "nombre": "BARRIO CAIMA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1313356998216,
      "centroide_lon": -60.9282685049176,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105050000",
      "localidad_censal_id": "82105050",
      "localidad_censal_nombre": "Barrio El Pacaá - Barrio Comipini",
      "municipio_id": "823722",
      "municipio_nombre": "Arocena",
      "nombre": "BARRIO EL PACAA - BARRIO COMIPINI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1709306189148,
      "centroide_lon": -61.1572748282837,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105060000",
      "localidad_censal_id": "82105060",
      "localidad_censal_nombre": "Bernardo de Irigoyen",
      "municipio_id": "823736",
      "municipio_nombre": "Bernardo de Irigoyen",
      "nombre": "BERNARDO DE IRIGOYEN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2638306241466,
      "centroide_lon": -61.1261488657874,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105070000",
      "localidad_censal_id": "82105070",
      "localidad_censal_nombre": "Casalegno",
      "municipio_id": "823750",
      "municipio_nombre": "Casalegno",
      "nombre": "CASALEGNO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.298023717348,
      "centroide_lon": -61.4107241466286,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105080000",
      "localidad_censal_id": "82105080",
      "localidad_censal_nombre": "Centeno",
      "municipio_id": "823757",
      "municipio_nombre": "Centeno",
      "nombre": "CENTENO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.975646712145,
      "centroide_lon": -60.9201341188126,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105090000",
      "localidad_censal_id": "82105090",
      "localidad_censal_nombre": "Coronda",
      "municipio_id": "820245",
      "municipio_nombre": "Coronda",
      "nombre": "CORONDA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8727393656094,
      "centroide_lon": -60.8896993521491,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105100000",
      "localidad_censal_id": "82105100",
      "localidad_censal_nombre": "Desvío Arijón",
      "municipio_id": "823764",
      "municipio_nombre": "Desvío Arijón",
      "nombre": "DESVIO ARIJON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3750588242506,
      "centroide_lon": -61.091442512126,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105110000",
      "localidad_censal_id": "82105110",
      "localidad_censal_nombre": "Díaz",
      "municipio_id": "823771",
      "municipio_nombre": "Díaz",
      "nombre": "DIAZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4343760818819,
      "centroide_lon": -60.8185390941723,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105120000",
      "localidad_censal_id": "82105120",
      "localidad_censal_nombre": "Gaboto",
      "municipio_id": "823778",
      "municipio_nombre": "Gaboto",
      "nombre": "GABOTO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0326455920318,
      "centroide_lon": -61.2199610274248,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105130000",
      "localidad_censal_id": "82105130",
      "localidad_censal_nombre": "Gálvez",
      "municipio_id": "820252",
      "municipio_nombre": "Gálvez",
      "nombre": "GALVEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8770449531764,
      "centroide_lon": -61.1288367653984,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105140000",
      "localidad_censal_id": "82105140",
      "localidad_censal_nombre": "Gessler",
      "municipio_id": "823785",
      "municipio_nombre": "Gessler",
      "nombre": "GESSLER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.16077341012,
      "centroide_lon": -61.1104258428642,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105150000",
      "localidad_censal_id": "82105150",
      "localidad_censal_nombre": "Irigoyen",
      "municipio_id": "823792",
      "municipio_nombre": "Pueblo Irigoyen",
      "nombre": "IRIGOYEN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9361305275575,
      "centroide_lon": -61.0477166860408,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105160000",
      "localidad_censal_id": "82105160",
      "localidad_censal_nombre": "Larrechea",
      "municipio_id": "823799",
      "municipio_nombre": "Larrechea",
      "nombre": "LARRECHEA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9614431890621,
      "centroide_lon": -61.1783800456556,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105170000",
      "localidad_censal_id": "82105170",
      "localidad_censal_nombre": "Loma Alta",
      "municipio_id": "823806",
      "municipio_nombre": "Loma Alta",
      "nombre": "LOMA ALTA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9069668930749,
      "centroide_lon": -61.2799295377437,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105180000",
      "localidad_censal_id": "82105180",
      "localidad_censal_nombre": "López",
      "municipio_id": "823813",
      "municipio_nombre": "López",
      "nombre": "LOPEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.4587652553057,
      "centroide_lon": -60.8931126475643,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105190000",
      "localidad_censal_id": "82105190",
      "localidad_censal_nombre": "Maciel",
      "municipio_id": "823820",
      "municipio_nombre": "Maciel",
      "nombre": "MACIEL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.358735658961,
      "centroide_lon": -60.9429043010117,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105200000",
      "localidad_censal_id": "82105200",
      "localidad_censal_nombre": "Monje",
      "municipio_id": "823827",
      "municipio_nombre": "Monje",
      "nombre": "MONJE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2447218281238,
      "centroide_lon": -60.9239839784214,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105210000",
      "localidad_censal_id": "82105210",
      "localidad_censal_nombre": "Puerto Aragón",
      "municipio_id": "823729",
      "municipio_nombre": "Barrancas",
      "nombre": "PUERTO ARAGON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0768953861827,
      "centroide_lon": -61.1174234926527,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105220000",
      "localidad_censal_id": "82105220",
      "localidad_censal_nombre": "San Eugenio",
      "municipio_id": "823834",
      "municipio_nombre": "San Eugenio",
      "nombre": "SAN EUGENIO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1383718722873,
      "centroide_lon": -60.9832660246346,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105230000",
      "localidad_censal_id": "82105230",
      "localidad_censal_nombre": "San Fabián",
      "municipio_id": "823841",
      "municipio_nombre": "San Fabián",
      "nombre": "SAN FABIAN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.3735383941398,
      "centroide_lon": -61.3606569211003,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105240000",
      "localidad_censal_id": "82105240",
      "localidad_censal_nombre": "San Genaro",
      "municipio_id": "820256",
      "municipio_nombre": "San Genaro",
      "nombre": "SAN GENARO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.3658073636946,
      "centroide_lon": -61.3401798286706,
      "departamento_id": "82105",
      "departamento_nombre": "San Jerónimo",
      "id": "82105250000",
      "localidad_censal_id": "82105250",
      "localidad_censal_nombre": "San Genaro Norte",
      "municipio_id": "820256",
      "municipio_nombre": "San Genaro",
      "nombre": "SAN GENARO NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8575177090903,
      "centroide_lon": -60.6486098681792,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112010000",
      "localidad_censal_id": "82112010",
      "localidad_censal_nombre": "Angeloni",
      "municipio_id": "823862",
      "municipio_nombre": "Angeloni",
      "nombre": "ANGELONI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.1150071573313,
      "centroide_lon": -60.5814003944463,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112020000",
      "localidad_censal_id": "82112020",
      "localidad_censal_nombre": "Cayastacito",
      "municipio_id": "823869",
      "municipio_nombre": "Cayastacito",
      "nombre": "CAYASTACITO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3836825866932,
      "centroide_lon": -60.3307254883154,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112030000",
      "localidad_censal_id": "82112030",
      "localidad_censal_nombre": "Colonia Dolores",
      "municipio_id": "823876",
      "municipio_nombre": "Colonia Dolores",
      "nombre": "COLONIA DOLORES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.0434861128335,
      "centroide_lon": -60.6448661369776,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112040000",
      "localidad_censal_id": "82112040",
      "localidad_censal_nombre": "Esther",
      "municipio_id": "823883",
      "municipio_nombre": "Esther",
      "nombre": "ESTHER",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3648346894928,
      "centroide_lon": -60.4011684048308,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112050000",
      "localidad_censal_id": "82112050",
      "localidad_censal_nombre": "Gobernador Crespo",
      "municipio_id": "823890",
      "municipio_nombre": "Gobernador Crespo",
      "nombre": "GOBERNADOR CRESPO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.2261579523496,
      "centroide_lon": -60.3664968225823,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112060000",
      "localidad_censal_id": "82112060",
      "localidad_censal_nombre": "La Criolla",
      "municipio_id": "823904",
      "municipio_nombre": "La Criolla",
      "nombre": "LA CRIOLLA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3484964424709,
      "centroide_lon": -60.5217853393141,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112070000",
      "localidad_censal_id": "82112070",
      "localidad_censal_nombre": "La Penca y Caraguatá",
      "municipio_id": "823911",
      "municipio_nombre": "La Penca y Caraguatá",
      "nombre": "LA PENCA Y CARAGUATA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.5819493055119,
      "centroide_lon": -60.4693860436357,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112080000",
      "localidad_censal_id": "82112080",
      "localidad_censal_nombre": "Marcelino Escalada",
      "municipio_id": "823918",
      "municipio_nombre": "Marcelino Escalada",
      "nombre": "MARCELINO ESCALADA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.951076187335,
      "centroide_lon": -60.4682102211441,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112090000",
      "localidad_censal_id": "82112090",
      "localidad_censal_nombre": "Naré",
      "municipio_id": "823925",
      "municipio_nombre": "Naré",
      "nombre": "NARE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0384743425904,
      "centroide_lon": -60.3152431288441,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112100000",
      "localidad_censal_id": "82112100",
      "localidad_censal_nombre": "Pedro Gómez Cello",
      "municipio_id": "823932",
      "municipio_nombre": "Pedro Gómez Cello",
      "nombre": "PEDRO GOMEZ CELLO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.6758153384973,
      "centroide_lon": -60.4995553794067,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112110000",
      "localidad_censal_id": "82112110",
      "localidad_censal_nombre": "Ramayón",
      "municipio_id": "823939",
      "municipio_nombre": "Ramayón",
      "nombre": "RAMAYON",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.8905395009228,
      "centroide_lon": -60.5751464890853,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112120000",
      "localidad_censal_id": "82112120",
      "localidad_censal_nombre": "San Bernardo",
      "municipio_id": "823946",
      "municipio_nombre": "San Bernardo",
      "nombre": "SAN BERNARDO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.7908665162845,
      "centroide_lon": -60.5940368250334,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112130000",
      "localidad_censal_id": "82112130",
      "localidad_censal_nombre": "San Justo",
      "municipio_id": "820259",
      "municipio_nombre": "San Justo",
      "nombre": "SAN JUSTO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.3493004076432,
      "centroide_lon": -60.3040548934806,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112140000",
      "localidad_censal_id": "82112140",
      "localidad_censal_nombre": "San Martín Norte",
      "municipio_id": "823953",
      "municipio_nombre": "San Mart",
      "nombre": "SAN MARTIN NORTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.4486399849806,
      "centroide_lon": -60.430070013139,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112150000",
      "localidad_censal_id": "82112150",
      "localidad_censal_nombre": "Silva",
      "municipio_id": "823960",
      "municipio_nombre": "Silva",
      "nombre": "SILVA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.1436425790617,
      "centroide_lon": -60.3373530550458,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112160000",
      "localidad_censal_id": "82112160",
      "localidad_censal_nombre": "Vera y Pintado",
      "municipio_id": "823967",
      "municipio_nombre": "Vera y Pintado",
      "nombre": "VERA Y PINTADO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.9465032627862,
      "centroide_lon": -60.6564412400796,
      "departamento_id": "82112",
      "departamento_nombre": "San Justo",
      "id": "82112170000",
      "localidad_censal_id": "82112170",
      "localidad_censal_nombre": "Videla",
      "municipio_id": "823974",
      "municipio_nombre": "Videla",
      "nombre": "VIDELA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7069853236032,
      "centroide_lon": -60.8179518202362,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119010000",
      "localidad_censal_id": "82119010",
      "localidad_censal_nombre": "Aldao",
      "municipio_id": "823981",
      "municipio_nombre": "Aldao",
      "nombre": "ALDAO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.8282349449349,
      "centroide_lon": -60.7168207550494,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119020000",
      "localidad_censal_id": "82119020",
      "localidad_censal_nombre": "Capitán Bermúdez",
      "municipio_id": "820266",
      "municipio_nombre": "Capitán Bermúdez",
      "nombre": "CAPITAN BERMUDEZ",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8588281760163,
      "centroide_lon": -61.1523502197425,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119030000",
      "localidad_censal_id": "82119030",
      "localidad_censal_nombre": "Carcarañá",
      "municipio_id": "820273",
      "municipio_nombre": "Carcarañá",
      "nombre": "CARCARAÑA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1066175391399,
      "centroide_lon": -60.9665015588484,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119040000",
      "localidad_censal_id": "82119040",
      "localidad_censal_nombre": "Coronel Arnold",
      "municipio_id": "823988",
      "municipio_nombre": "Coronel Arnold",
      "nombre": "CORONEL ARNOLD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.7855581880336,
      "centroide_lon": -60.7291236444004,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119050000",
      "localidad_censal_id": "82119050",
      "localidad_censal_nombre": "Fray Luis Beltrán",
      "municipio_id": "820280",
      "municipio_nombre": "Fray Luis Beltrán",
      "nombre": "FRAY LUIS BELTRAN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.1744416497872,
      "centroide_lon": -61.0750536976089,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119060000",
      "localidad_censal_id": "82119060",
      "localidad_censal_nombre": "Fuentes",
      "municipio_id": "823995",
      "municipio_nombre": "Fuentes",
      "nombre": "FUENTES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.784870422621,
      "centroide_lon": -60.9076379614075,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119070000",
      "localidad_censal_id": "82119070",
      "localidad_censal_nombre": "Luis Palacios",
      "municipio_id": "824002",
      "municipio_nombre": "Luis Palacios",
      "nombre": "LUIS PALACIOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.7190025958741,
      "centroide_lon": -60.7334925388318,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119080000",
      "localidad_censal_id": "82119080",
      "localidad_censal_nombre": "Puerto General San Martín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO GENERAL SAN MARTIN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.0195733543103,
      "centroide_lon": -61.0438316490822,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119090000",
      "localidad_censal_id": "82119090",
      "localidad_censal_nombre": "Pujato",
      "municipio_id": "824009",
      "municipio_nombre": "Pujato",
      "nombre": "PUJATO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.7736805491372,
      "centroide_lon": -60.786927658223,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119100000",
      "localidad_censal_id": "82119100",
      "localidad_censal_nombre": "Ricardone",
      "municipio_id": "824016",
      "municipio_nombre": "Ricardone",
      "nombre": "RICARDONE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.9023879302416,
      "centroide_lon": -60.9108827950649,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119110000",
      "localidad_censal_id": "82119110",
      "localidad_censal_nombre": "Roldán",
      "municipio_id": "820294",
      "municipio_nombre": "Roldán",
      "nombre": "ROLDAN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.8787353332163,
      "centroide_lon": -61.0243903952404,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119120000",
      "localidad_censal_id": "82119120",
      "localidad_censal_nombre": "San Jerónimo Sud",
      "municipio_id": "824023",
      "municipio_nombre": "San Jerónimo Sud",
      "nombre": "SAN JERONIMO SUD",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.7523069549362,
      "centroide_lon": -60.7356209815072,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119130000",
      "localidad_censal_id": "82119130",
      "localidad_censal_nombre": "San Lorenzo",
      "municipio_id": "820301",
      "municipio_nombre": "San Lorenzo",
      "nombre": "SAN LORENZO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.6696252682834,
      "centroide_lon": -60.7943548240758,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119140000",
      "localidad_censal_id": "82119140",
      "localidad_censal_nombre": "Timbúes",
      "municipio_id": "824030",
      "municipio_nombre": "Timbúes",
      "nombre": "TIMBUES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -32.6429185717722,
      "centroide_lon": -60.8177095118801,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119150000",
      "localidad_censal_id": "82119150",
      "localidad_censal_nombre": "Villa Elvira",
      "municipio_id": "824030",
      "municipio_nombre": "Timbúes",
      "nombre": "VILLA ELVIRA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -33.3139349307156,
      "centroide_lon": -61.0570695285482,
      "departamento_id": "82119",
      "departamento_nombre": "San Lorenzo",
      "id": "82119160000",
      "localidad_censal_id": "82119160",
      "localidad_censal_nombre": "Villa Mugueta",
      "municipio_id": "824037",
      "municipio_nombre": "Villa Mugueta",
      "nombre": "VILLA MUGUETA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0562934036888,
      "centroide_lon": -61.6025429947826,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126010000",
      "localidad_censal_id": "82126010",
      "localidad_censal_nombre": "Cañada Rosquín",
      "municipio_id": "824044",
      "municipio_nombre": "Cañada Rosquín",
      "nombre": "CAÑADA ROSQUIN",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0526642372536,
      "centroide_lon": -61.78894991246,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126020000",
      "localidad_censal_id": "82126020",
      "localidad_censal_nombre": "Carlos Pellegrini",
      "municipio_id": "824051",
      "municipio_nombre": "Carlos Pellegrini",
      "nombre": "CARLOS PELLEGRINI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1279976039457,
      "centroide_lon": -61.5421282200809,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126030000",
      "localidad_censal_id": "82126030",
      "localidad_censal_nombre": "Casas",
      "municipio_id": "824058",
      "municipio_nombre": "Casas",
      "nombre": "CASAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.6691692674795,
      "centroide_lon": -62.0899867120516,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126040000",
      "localidad_censal_id": "82126040",
      "localidad_censal_nombre": "Castelar",
      "municipio_id": "824065",
      "municipio_nombre": "Castelar",
      "nombre": "CASTELAR",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9118940838345,
      "centroide_lon": -61.4023835358505,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126050000",
      "localidad_censal_id": "82126050",
      "localidad_censal_nombre": "Colonia Belgrano",
      "municipio_id": "824072",
      "municipio_nombre": "Colonia Belgrano",
      "nombre": "COLONIA BELGRANO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7417252131656,
      "centroide_lon": -62.0378765362955,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126060000",
      "localidad_censal_id": "82126060",
      "localidad_censal_nombre": "Crispi",
      "municipio_id": "824079",
      "municipio_nombre": "Crispi",
      "nombre": "CRISPI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2030242455008,
      "centroide_lon": -61.7028944914559,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126070000",
      "localidad_censal_id": "82126070",
      "localidad_censal_nombre": "El Trébol",
      "municipio_id": "820308",
      "municipio_nombre": "El Trébol",
      "nombre": "EL TREBOL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.0138147005941,
      "centroide_lon": -62.0611771301989,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126080000",
      "localidad_censal_id": "82126080",
      "localidad_censal_nombre": "Landeta",
      "municipio_id": "824086",
      "municipio_nombre": "Landeta",
      "nombre": "LANDETA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1991899646564,
      "centroide_lon": -61.4927940449511,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126090000",
      "localidad_censal_id": "82126090",
      "localidad_censal_nombre": "Las Bandurrias",
      "municipio_id": "824093",
      "municipio_nombre": "Las Bandurrias",
      "nombre": "LAS BANDURRIAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8247278111096,
      "centroide_lon": -62.1089907898346,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126100000",
      "localidad_censal_id": "82126100",
      "localidad_censal_nombre": "Las Petacas",
      "municipio_id": "824100",
      "municipio_nombre": "Las Petacas",
      "nombre": "LAS PETACAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.3243031135987,
      "centroide_lon": -61.6321111218833,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126110000",
      "localidad_censal_id": "82126110",
      "localidad_censal_nombre": "Los Cardos",
      "municipio_id": "824107",
      "municipio_nombre": "Los Cardos",
      "nombre": "LOS CARDOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.2654856687264,
      "centroide_lon": -61.9010253248989,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126120000",
      "localidad_censal_id": "82126120",
      "localidad_censal_nombre": "María Susana",
      "municipio_id": "824114",
      "municipio_nombre": "María Susana",
      "nombre": "MARIA SUSANA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -32.1458665241358,
      "centroide_lon": -61.9811202181808,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126130000",
      "localidad_censal_id": "82126130",
      "localidad_censal_nombre": "Piamonte",
      "municipio_id": "824121",
      "municipio_nombre": "Piamonte",
      "nombre": "PIAMONTE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.898003817155,
      "centroide_lon": -61.8603287777206,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126140000",
      "localidad_censal_id": "82126140",
      "localidad_censal_nombre": "San Jorge",
      "municipio_id": "820315",
      "municipio_nombre": "San Jorge",
      "nombre": "SAN JORGE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.8596737471682,
      "centroide_lon": -61.5702157763693,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126150000",
      "localidad_censal_id": "82126150",
      "localidad_censal_nombre": "San Martín de las Escobas",
      "municipio_id": "824128",
      "municipio_nombre": "San Mart",
      "nombre": "SAN MARTIN DE LAS ESCOBAS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.7695325241636,
      "centroide_lon": -61.8294529565696,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126160000",
      "localidad_censal_id": "82126160",
      "localidad_censal_nombre": "Sastre",
      "municipio_id": "820322",
      "municipio_nombre": "Sastre",
      "nombre": "SASTRE",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9225991219581,
      "centroide_lon": -61.7024471962412,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126170000",
      "localidad_censal_id": "82126170",
      "localidad_censal_nombre": "Traill",
      "municipio_id": "824135",
      "municipio_nombre": "Traill",
      "nombre": "TRAILL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -31.9469900287545,
      "centroide_lon": -61.4025551450022,
      "departamento_id": "82126",
      "departamento_nombre": "San Martín",
      "id": "82126180000",
      "localidad_censal_id": "82126180",
      "localidad_censal_nombre": "Wildermuth",
      "municipio_id": "824072",
      "municipio_nombre": "Colonia Belgrano",
      "nombre": "WILDERMUTH",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.8905436040639,
      "centroide_lon": -60.285737676357,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133010000",
      "localidad_censal_id": "82133010",
      "localidad_censal_nombre": "Calchaquí",
      "municipio_id": "820329",
      "municipio_nombre": "Calchaquí",
      "nombre": "CALCHAQUI",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.31083042196,
      "centroide_lon": -59.9837166450891,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133020000",
      "localidad_censal_id": "82133020",
      "localidad_censal_nombre": "Cañada Ombú",
      "municipio_id": "824142",
      "municipio_nombre": "Cañada Ombú",
      "nombre": "CAÑADA OMBU",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7658190432744,
      "centroide_lon": -60.0880421430925,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133030000",
      "localidad_censal_id": "82133030",
      "localidad_censal_nombre": "Colmena",
      "municipio_id": "824170",
      "municipio_nombre": "Intiyaco",
      "nombre": "COLMENA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0560211845387,
      "centroide_lon": -60.4143945851651,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133040000",
      "localidad_censal_id": "82133040",
      "localidad_censal_nombre": "Fortín Olmos",
      "municipio_id": "824149",
      "municipio_nombre": "Fortín Olmos",
      "nombre": "FORTIN OLMOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9553447407242,
      "centroide_lon": -60.1384384332405,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133050000",
      "localidad_censal_id": "82133050",
      "localidad_censal_nombre": "Garabato",
      "municipio_id": "824156",
      "municipio_nombre": "Garabato",
      "nombre": "GARABATO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.5588709585467,
      "centroide_lon": -60.0251919319472,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133060000",
      "localidad_censal_id": "82133060",
      "localidad_censal_nombre": "Golondrina",
      "municipio_id": "824163",
      "municipio_nombre": "Golondrina",
      "nombre": "GOLONDRINA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6779253668564,
      "centroide_lon": -60.0724684333926,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133070000",
      "localidad_censal_id": "82133070",
      "localidad_censal_nombre": "Intiyaco",
      "municipio_id": "824170",
      "municipio_nombre": "Intiyaco",
      "nombre": "INTIYACO",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8247510907656,
      "centroide_lon": -60.2256036446499,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133080000",
      "localidad_censal_id": "82133080",
      "localidad_censal_nombre": "Kilómetro 115",
      "municipio_id": "824156",
      "municipio_nombre": "Garabato",
      "nombre": "KILOMETRO 115",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5854889982809,
      "centroide_lon": -60.3799393729758,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133090000",
      "localidad_censal_id": "82133090",
      "localidad_censal_nombre": "La Gallareta",
      "municipio_id": "824177",
      "municipio_nombre": "La Gallareta",
      "nombre": "LA GALLARETA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1063721680455,
      "centroide_lon": -59.9786648326772,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133100000",
      "localidad_censal_id": "82133100",
      "localidad_censal_nombre": "Los Amores",
      "municipio_id": "824184",
      "municipio_nombre": "Los Amores",
      "nombre": "LOS AMORES",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.6910879587641,
      "centroide_lon": -60.2524652684442,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133110000",
      "localidad_censal_id": "82133110",
      "localidad_censal_nombre": "Margarita",
      "municipio_id": "824191",
      "municipio_nombre": "Margarita",
      "nombre": "MARGARITA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1097652045099,
      "centroide_lon": -60.2391297738451,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133120000",
      "localidad_censal_id": "82133120",
      "localidad_censal_nombre": "Paraje 29",
      "municipio_id": "824149",
      "municipio_nombre": "Fortín Olmos",
      "nombre": "PARAJE 29",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9451306017322,
      "centroide_lon": -60.2520035726174,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133130000",
      "localidad_censal_id": "82133130",
      "localidad_censal_nombre": "Pozo de los Indios",
      "municipio_id": "824156",
      "municipio_nombre": "Garabato",
      "nombre": "POZO DE LOS INDIOS",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2838158063585,
      "centroide_lon": -60.4038564051536,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133140000",
      "localidad_censal_id": "82133140",
      "localidad_censal_nombre": "Pueblo Santa Lucía",
      "municipio_id": "820336",
      "municipio_nombre": "Vera",
      "nombre": "PUEBLO SANTA LUCIA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6722104503175,
      "centroide_lon": -59.8468195282736,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133150000",
      "localidad_censal_id": "82133150",
      "localidad_censal_nombre": "Tartagal",
      "municipio_id": "824198",
      "municipio_nombre": "Tartagal",
      "nombre": "TARTAGAL",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2675884279209,
      "centroide_lon": -60.1726559005792,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133160000",
      "localidad_censal_id": "82133160",
      "localidad_censal_nombre": "Toba",
      "municipio_id": "824205",
      "municipio_nombre": "Toba",
      "nombre": "TOBA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4629204507651,
      "centroide_lon": -60.2133477841634,
      "departamento_id": "82133",
      "departamento_nombre": "Vera",
      "id": "82133170000",
      "localidad_censal_id": "82133170",
      "localidad_censal_nombre": "Vera",
      "municipio_id": "820336",
      "municipio_nombre": "Vera",
      "nombre": "VERA",
      "provincia_id": "82",
      "provincia_nombre": "Santa Fe"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5347584629763,
      "centroide_lon": -62.2668040651927,
      "departamento_id": "86007",
      "departamento_nombre": "Aguirre",
      "id": "86007010000",
      "localidad_censal_id": "86007010",
      "localidad_censal_nombre": "Argentina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARGENTINA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9529394000082,
      "centroide_lon": -62.8005346895448,
      "departamento_id": "86007",
      "departamento_nombre": "Aguirre",
      "id": "86007020000",
      "localidad_censal_id": "86007020",
      "localidad_censal_nombre": "Casares",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CASARES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3480281688774,
      "centroide_lon": -62.4374715654784,
      "departamento_id": "86007",
      "departamento_nombre": "Aguirre",
      "id": "86007030000",
      "localidad_censal_id": "86007030",
      "localidad_censal_nombre": "Malbrán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MALBRAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1446444838565,
      "centroide_lon": -62.6541996550291,
      "departamento_id": "86007",
      "departamento_nombre": "Aguirre",
      "id": "86007040000",
      "localidad_censal_id": "86007040",
      "localidad_censal_nombre": "Villa General Mitre",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA GENERAL MITRE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.581588722034,
      "centroide_lon": -62.8521376221858,
      "departamento_id": "86014",
      "departamento_nombre": "Alberdi",
      "id": "86014010000",
      "localidad_censal_id": "86014010",
      "localidad_censal_nombre": "Campo Gallo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CAMPO GALLO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3836121510521,
      "centroide_lon": -61.8096460345964,
      "departamento_id": "86014",
      "departamento_nombre": "Alberdi",
      "id": "86014020000",
      "localidad_censal_id": "86014020",
      "localidad_censal_nombre": "Coronel Manuel L. Rico",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CORONEL MANUEL L. RICO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7266820556935,
      "centroide_lon": -62.7208021510899,
      "departamento_id": "86014",
      "departamento_nombre": "Alberdi",
      "id": "86014030000",
      "localidad_censal_id": "86014030",
      "localidad_censal_nombre": "Donadeu",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "DONADEU",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.671433914307,
      "centroide_lon": -61.8174308800717,
      "departamento_id": "86014",
      "departamento_nombre": "Alberdi",
      "id": "86014040000",
      "localidad_censal_id": "86014040",
      "localidad_censal_nombre": "Sachayoj",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SACHAYOJ",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6920901207094,
      "centroide_lon": -63.5584892838706,
      "departamento_id": "86014",
      "departamento_nombre": "Alberdi",
      "id": "86014050000",
      "localidad_censal_id": "86014050",
      "localidad_censal_nombre": "Santos Lugares",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTOS LUGARES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4946171651834,
      "centroide_lon": -63.9414267036415,
      "departamento_id": "86021",
      "departamento_nombre": "Atamisqui",
      "id": "86021010000",
      "localidad_censal_id": "86021010",
      "localidad_censal_nombre": "Estación Atamisqui",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION ATAMISQUI",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6499385815911,
      "centroide_lon": -63.787545374207,
      "departamento_id": "86021",
      "departamento_nombre": "Atamisqui",
      "id": "86021020000",
      "localidad_censal_id": "86021020",
      "localidad_censal_nombre": "Medellín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MEDELLIN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4939256027398,
      "centroide_lon": -63.8205160146802,
      "departamento_id": "86021",
      "departamento_nombre": "Atamisqui",
      "id": "86021030000",
      "localidad_censal_id": "86021030",
      "localidad_censal_nombre": "Villa Atamisqui",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ATAMISQUI",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.603116660495,
      "centroide_lon": -62.9510729035731,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028010000",
      "localidad_censal_id": "86028010",
      "localidad_censal_nombre": "Colonia Dora",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA DORA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4852360558267,
      "centroide_lon": -63.0697575257717,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028020000",
      "localidad_censal_id": "86028020",
      "localidad_censal_nombre": "Herrera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HERRERA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6784711687351,
      "centroide_lon": -62.8846263455873,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028030000",
      "localidad_censal_id": "86028030",
      "localidad_censal_nombre": "Icaño",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ICAÑO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3346445647532,
      "centroide_lon": -63.3436023986024,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028040000",
      "localidad_censal_id": "86028040",
      "localidad_censal_nombre": "Lugones",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LUGONES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8166325823721,
      "centroide_lon": -62.8455339441355,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028050000",
      "localidad_censal_id": "86028050",
      "localidad_censal_nombre": "Real Sayana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "REAL SAYANA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4831042788773,
      "centroide_lon": -63.2790903120191,
      "departamento_id": "86028",
      "departamento_nombre": "Avellaneda",
      "id": "86028060000",
      "localidad_censal_id": "86028060",
      "localidad_censal_nombre": "Villa Mailín",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA MAILIN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2931336131427,
      "centroide_lon": -64.3790656722546,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035010000",
      "localidad_censal_id": "86035010",
      "localidad_censal_nombre": "Abra Grande",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ABRA GRANDE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6273165422381,
      "centroide_lon": -64.252782204773,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035020000",
      "localidad_censal_id": "86035020",
      "localidad_censal_nombre": "Antajé",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ANTAJE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.415006517686,
      "centroide_lon": -64.5005841049022,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035030000",
      "localidad_censal_id": "86035030",
      "localidad_censal_nombre": "Ardiles",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARDILES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7093508468277,
      "centroide_lon": -64.0345634947704,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035040000",
      "localidad_censal_id": "86035040",
      "localidad_censal_nombre": "Cañada Escobar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CAÑADA ESCOBAR",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5090071798518,
      "centroide_lon": -64.4230093979117,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035050000",
      "localidad_censal_id": "86035050",
      "localidad_censal_nombre": "Chaupi Pozo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHAUPI POZO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5762755617113,
      "centroide_lon": -64.1322398015217,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035060000",
      "localidad_censal_id": "86035060",
      "localidad_censal_nombre": "Clodomira",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CLODOMIRA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3875685641419,
      "centroide_lon": -64.296255139046,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035070000",
      "localidad_censal_id": "86035070",
      "localidad_censal_nombre": "Huyamampa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HUYAMAMPA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4964060720214,
      "centroide_lon": -64.2315605665017,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035080000",
      "localidad_censal_id": "86035080",
      "localidad_censal_nombre": "La Aurora",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA AURORA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.7339063576954,
      "centroide_lon": -64.2389609885924,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035090000",
      "localidad_censal_id": "86035090",
      "localidad_censal_nombre": "La Banda",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA BANDA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6984439320512,
      "centroide_lon": -64.2891593020827,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035100000",
      "localidad_censal_id": "86035100",
      "localidad_censal_nombre": "La Dársena",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA DARSENA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6541808200018,
      "centroide_lon": -64.3550857252435,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035110000",
      "localidad_censal_id": "86035110",
      "localidad_censal_nombre": "Los Quiroga",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS QUIROGA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6278438576024,
      "centroide_lon": -64.3665478243818,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035120000",
      "localidad_censal_id": "86035120",
      "localidad_censal_nombre": "Los Soria",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS SORIA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6504840077902,
      "centroide_lon": -64.1912416790425,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035130000",
      "localidad_censal_id": "86035130",
      "localidad_censal_nombre": "Simbolar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SIMBOLAR",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6966791825107,
      "centroide_lon": -64.171980690304,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035140000",
      "localidad_censal_id": "86035140",
      "localidad_censal_nombre": "Tramo 16",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TRAMO 16",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.724169959056,
      "centroide_lon": -64.119953835997,
      "departamento_id": "86035",
      "departamento_nombre": "Banda",
      "id": "86035150000",
      "localidad_censal_id": "86035150",
      "localidad_censal_nombre": "Tramo 20",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TRAMO 20",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8825052182774,
      "centroide_lon": -62.2661992861707,
      "departamento_id": "86042",
      "departamento_nombre": "Belgrano",
      "id": "86042010000",
      "localidad_censal_id": "86042010",
      "localidad_censal_nombre": "Bandera",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BANDERA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.8813440964016,
      "centroide_lon": -61.8646065822,
      "departamento_id": "86042",
      "departamento_nombre": "Belgrano",
      "id": "86042020000",
      "localidad_censal_id": "86042020",
      "localidad_censal_nombre": "Cuatro Bocas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CUATRO BOCAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.1249938081928,
      "centroide_lon": -61.9377330722992,
      "departamento_id": "86042",
      "departamento_nombre": "Belgrano",
      "id": "86042030000",
      "localidad_censal_id": "86042030",
      "localidad_censal_nombre": "Fortín Inca",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FORTIN INCA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9901980198517,
      "centroide_lon": -62.1274458413758,
      "departamento_id": "86042",
      "departamento_nombre": "Belgrano",
      "id": "86042040000",
      "localidad_censal_id": "86042040",
      "localidad_censal_nombre": "Guardia Escolta",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GUARDIA ESCOLTA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7173434793387,
      "centroide_lon": -64.3318930408752,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049010000",
      "localidad_censal_id": "86049010",
      "localidad_censal_nombre": "El Deán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL DEAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9899319139286,
      "centroide_lon": -64.2133551714841,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049020000",
      "localidad_censal_id": "86049020",
      "localidad_censal_nombre": "El Mojón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL MOJON",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.8750408217321,
      "centroide_lon": -64.2433909658935,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049030000",
      "localidad_censal_id": "86049030",
      "localidad_censal_nombre": "El Zanjón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL ZANJON",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9125126696643,
      "centroide_lon": -64.1953803450085,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049040000",
      "localidad_censal_id": "86049040",
      "localidad_censal_nombre": "Los Cardozos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS CARDOZOS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8684490761323,
      "centroide_lon": -64.2176846841573,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049050000",
      "localidad_censal_id": "86049050",
      "localidad_censal_nombre": "Maco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MACO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.883071657444,
      "centroide_lon": -64.2103645086341,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049060000",
      "localidad_censal_id": "86049060",
      "localidad_censal_nombre": "Maquito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MAQUITO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.681136956593,
      "centroide_lon": -64.3628119718648,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049070000",
      "localidad_censal_id": "86049070",
      "localidad_censal_nombre": "Morales",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MORALES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6663887276734,
      "centroide_lon": -64.3770291350394,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049080000",
      "localidad_censal_id": "86049080",
      "localidad_censal_nombre": "Puesto de San Antonio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUESTO DE SAN ANTONIO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9463558793645,
      "centroide_lon": -64.1638901235678,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049090000",
      "localidad_censal_id": "86049090",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN PEDRO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9515163858504,
      "centroide_lon": -64.2196924435025,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049100000",
      "localidad_censal_id": "86049100",
      "localidad_censal_nombre": "Santa María",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTA MARIA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.7906472093484,
      "centroide_lon": -64.2622741290181,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049110000",
      "localidad_censal_id": "86049110",
      "localidad_censal_nombre": "Santiago del Estero",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTIAGO DEL ESTERO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8762024322533,
      "centroide_lon": -64.1878789215783,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049120000",
      "localidad_censal_id": "86049120",
      "localidad_censal_nombre": "Vuelta de la Barranca",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VUELTA DE LA BARRANCA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9106239556579,
      "centroide_lon": -64.2300554961573,
      "departamento_id": "86049",
      "departamento_nombre": "Capital",
      "id": "86049130000",
      "localidad_censal_id": "86049130",
      "localidad_censal_nombre": "Yanda",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "YANDA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.0165513217409,
      "centroide_lon": -62.3330208736259,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056010000",
      "localidad_censal_id": "86056010",
      "localidad_censal_nombre": "El Caburé",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CABURE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9734494403489,
      "centroide_lon": -63.121334820867,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056020000",
      "localidad_censal_id": "86056020",
      "localidad_censal_nombre": "La Firmeza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA FIRMEZA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1336342552401,
      "centroide_lon": -62.0623998821941,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056030000",
      "localidad_censal_id": "86056030",
      "localidad_censal_nombre": "Los Pirpintos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS PIRPINTOS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.9094984239476,
      "centroide_lon": -62.5920547989978,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056040000",
      "localidad_censal_id": "86056040",
      "localidad_censal_nombre": "Los Tigres",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS TIGRES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.8055238661729,
      "centroide_lon": -62.8421851386474,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056050000",
      "localidad_censal_id": "86056050",
      "localidad_censal_nombre": "Monte Quemado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MONTE QUEMADO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.033847347527,
      "centroide_lon": -63.3180059411213,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056060000",
      "localidad_censal_id": "86056060",
      "localidad_censal_nombre": "Nueva Esperanza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA ESPERANZA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2344065294949,
      "centroide_lon": -61.8376679266549,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056070000",
      "localidad_censal_id": "86056070",
      "localidad_censal_nombre": "Pampa de los Guanacos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PAMPA DE LOS GUANACOS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.1203999513037,
      "centroide_lon": -63.7053351206531,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056080000",
      "localidad_censal_id": "86056080",
      "localidad_censal_nombre": "San José del Boquerón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN JOSE DEL BOQUERON",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -25.7123774702614,
      "centroide_lon": -63.041276297827,
      "departamento_id": "86056",
      "departamento_nombre": "Copo",
      "id": "86056090000",
      "localidad_censal_id": "86056090",
      "localidad_censal_nombre": "Urutaú",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "URUTAU",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4309064612063,
      "centroide_lon": -64.9233001766413,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063010000",
      "localidad_censal_id": "86063010",
      "localidad_censal_nombre": "Ancaján",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ANCAJAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4942404547901,
      "centroide_lon": -64.8563028233751,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063020000",
      "localidad_censal_id": "86063020",
      "localidad_censal_nombre": "Choya",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHOYA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4119636214997,
      "centroide_lon": -64.7564290050951,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063030000",
      "localidad_censal_id": "86063030",
      "localidad_censal_nombre": "Estación La Punta",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION LA PUNTA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6399178570768,
      "centroide_lon": -65.130637009131,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063040000",
      "localidad_censal_id": "86063040",
      "localidad_censal_nombre": "Frías",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FRIAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3756319929951,
      "centroide_lon": -64.5305517169124,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063050000",
      "localidad_censal_id": "86063050",
      "localidad_censal_nombre": "Laprida",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAPRIDA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4601109681789,
      "centroide_lon": -64.8665614531529,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063070000",
      "localidad_censal_id": "86063070",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN PEDRO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -28.4037795215625,
      "centroide_lon": -65.0964321177706,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063080000",
      "localidad_censal_id": "86063080",
      "localidad_censal_nombre": "Tapso",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TAPSO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.371365281259,
      "centroide_lon": -64.7921192520059,
      "departamento_id": "86063",
      "departamento_nombre": "Choya",
      "id": "86063090000",
      "localidad_censal_id": "86063090",
      "localidad_censal_nombre": "Villa La Punta",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA LA PUNTA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2724258053036,
      "centroide_lon": -63.5140111288855,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070010000",
      "localidad_censal_id": "86070010",
      "localidad_censal_nombre": "Bandera Bajada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BANDERA BAJADA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3907597837259,
      "centroide_lon": -63.5489564711117,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070020000",
      "localidad_censal_id": "86070020",
      "localidad_censal_nombre": "Caspi Corral",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CASPI CORRAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.617543825893,
      "centroide_lon": -63.301877607639,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070030000",
      "localidad_censal_id": "86070030",
      "localidad_censal_nombre": "Colonia San Juan",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA SAN JUAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5779248328345,
      "centroide_lon": -63.3309305482062,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070040000",
      "localidad_censal_id": "86070040",
      "localidad_censal_nombre": "El Crucero",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CRUCERO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3845037887346,
      "centroide_lon": -63.5296772428548,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070050000",
      "localidad_censal_id": "86070050",
      "localidad_censal_nombre": "Kilómetro 30",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "KILOMETRO 30",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7113503879057,
      "centroide_lon": -63.776465678451,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070060000",
      "localidad_censal_id": "86070060",
      "localidad_censal_nombre": "La Cañada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA CAÑADA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3853384819687,
      "centroide_lon": -63.4863113485716,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070070000",
      "localidad_censal_id": "86070070",
      "localidad_censal_nombre": "La Invernada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA INVERNADA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5383379504139,
      "centroide_lon": -63.3844467629454,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070080000",
      "localidad_censal_id": "86070080",
      "localidad_censal_nombre": "Minerva",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MINERVA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4744910479525,
      "centroide_lon": -63.4699371865895,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070090000",
      "localidad_censal_id": "86070090",
      "localidad_censal_nombre": "Vaca Huañuna",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VACA HUAÑUNA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7220223636284,
      "centroide_lon": -63.5077024611833,
      "departamento_id": "86070",
      "departamento_nombre": "Figueroa",
      "id": "86070100000",
      "localidad_censal_id": "86070100",
      "localidad_censal_nombre": "Villa Figueroa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA FIGUEROA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4645613476513,
      "centroide_lon": -62.8371553125604,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077010000",
      "localidad_censal_id": "86077010",
      "localidad_censal_nombre": "Añatuya",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "AÑATUYA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.7471563597272,
      "centroide_lon": -62.4500332099209,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077020000",
      "localidad_censal_id": "86077020",
      "localidad_censal_nombre": "Averías",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "AVERIAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.625400373791,
      "centroide_lon": -62.6050049232664,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077030000",
      "localidad_censal_id": "86077030",
      "localidad_censal_nombre": "Estación Tacañitas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION TACAÑITAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4600119281847,
      "centroide_lon": -61.8401411379322,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077040000",
      "localidad_censal_id": "86077040",
      "localidad_censal_nombre": "La Nena",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA NENA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.4678989822603,
      "centroide_lon": -62.1097288458217,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077050000",
      "localidad_censal_id": "86077050",
      "localidad_censal_nombre": "Los Juríes",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS JURIES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.6023457181773,
      "centroide_lon": -62.1835145984131,
      "departamento_id": "86077",
      "departamento_nombre": "General Taboada",
      "id": "86077060000",
      "localidad_censal_id": "86077060",
      "localidad_censal_nombre": "Tomás Young",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TOMAS YOUNG",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -28.1989652184744,
      "centroide_lon": -65.1121667400926,
      "departamento_id": "86084",
      "departamento_nombre": "Guasayán",
      "id": "86084010000",
      "localidad_censal_id": "86084010",
      "localidad_censal_nombre": "Lavalle",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "LAVALLE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.9573058480013,
      "centroide_lon": -65.1704506415815,
      "departamento_id": "86084",
      "departamento_nombre": "Guasayán",
      "id": "86084020000",
      "localidad_censal_id": "86084020",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": "100217",
      "municipio_nombre": "Santa Rosa",
      "nombre": "SAN PEDRO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7716728234233,
      "centroide_lon": -64.6015462356661,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091005000",
      "localidad_censal_id": "86091005",
      "localidad_censal_nombre": "El Arenal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL ARENAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7184011548025,
      "centroide_lon": -64.3982906362897,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091010000",
      "localidad_censal_id": "86091010",
      "localidad_censal_nombre": "El Bobadal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL BOBADAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.2257680209403,
      "centroide_lon": -64.7003027141644,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091020000",
      "localidad_censal_id": "86091020",
      "localidad_censal_nombre": "El Charco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CHARCO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7333694233426,
      "centroide_lon": -64.4768351977429,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091030000",
      "localidad_censal_id": "86091030",
      "localidad_censal_nombre": "El Rincón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL RINCON",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.2971935237082,
      "centroide_lon": -64.61075364749,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091040000",
      "localidad_censal_id": "86091040",
      "localidad_censal_nombre": "Gramilla",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GRAMILLA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9408021196944,
      "centroide_lon": -62.9565689431121,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119070000",
      "localidad_censal_id": "86119070",
      "localidad_censal_nombre": "Lilo Viejo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LILO VIEJO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.843267415023,
      "centroide_lon": -62.9328920767785,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119080000",
      "localidad_censal_id": "86119080",
      "localidad_censal_nombre": "Patay",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PATAY",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3309034121122,
      "centroide_lon": -62.2263991173581,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119090000",
      "localidad_censal_id": "86119090",
      "localidad_censal_nombre": "Pueblo Pablo Torelo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUEBLO PABLO TORELO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6502221618861,
      "centroide_lon": -62.4162413545379,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119100000",
      "localidad_censal_id": "86119100",
      "localidad_censal_nombre": "Quimili",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "QUIMILI",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5934675544521,
      "centroide_lon": -61.9454404681047,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119110000",
      "localidad_censal_id": "86119110",
      "localidad_censal_nombre": "Roversi",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ROVERSI",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0269428383778,
      "centroide_lon": -62.7014758111564,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119120000",
      "localidad_censal_id": "86119120",
      "localidad_censal_nombre": "Tintina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TINTINA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3177468248599,
      "centroide_lon": -62.5883450951591,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119130000",
      "localidad_censal_id": "86119130",
      "localidad_censal_nombre": "Weisburd",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "WEISBURD",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.0559761149567,
      "centroide_lon": -63.9559623784872,
      "departamento_id": "86126",
      "departamento_nombre": "Ojo de Agua",
      "id": "86126010000",
      "localidad_censal_id": "86126010",
      "localidad_censal_nombre": "El 49",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL 49",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.562340331816,
      "centroide_lon": -63.4575916015313,
      "departamento_id": "86126",
      "departamento_nombre": "Ojo de Agua",
      "id": "86126020000",
      "localidad_censal_id": "86126020",
      "localidad_censal_nombre": "Sol de Julio",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SOL DE JULIO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.5029027910505,
      "centroide_lon": -63.6939738398896,
      "departamento_id": "86126",
      "departamento_nombre": "Ojo de Agua",
      "id": "86126030000",
      "localidad_censal_id": "86126030",
      "localidad_censal_nombre": "Villa Ojo de Agua",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA OJO DE AGUA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.094177918867,
      "centroide_lon": -64.3077511775358,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133010000",
      "localidad_censal_id": "86133010",
      "localidad_censal_nombre": "El Mojón",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL MOJON",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6821239251425,
      "centroide_lon": -64.0011052202083,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133020000",
      "localidad_censal_id": "86133020",
      "localidad_censal_nombre": "Las Delicias",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS DELICIAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2029493632974,
      "centroide_lon": -64.2386862818792,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133030000",
      "localidad_censal_id": "86133030",
      "localidad_censal_nombre": "Nueva Esperanza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA ESPERANZA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4117975451662,
      "centroide_lon": -64.3406016723268,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133040000",
      "localidad_censal_id": "86133040",
      "localidad_censal_nombre": "Pozo Betbeder",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZO BETBEDER",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3973763982116,
      "centroide_lon": -64.5044626210659,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133050000",
      "localidad_censal_id": "86133050",
      "localidad_censal_nombre": "Rapelli",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RAPELLI",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2295353888576,
      "centroide_lon": -63.7775041035997,
      "departamento_id": "86133",
      "departamento_nombre": "Pellegrini",
      "id": "86133060000",
      "localidad_censal_id": "86133060",
      "localidad_censal_nombre": "Santo Domingo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SANTO DOMINGO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.2346591274202,
      "centroide_lon": -63.4745902285707,
      "departamento_id": "86140",
      "departamento_nombre": "Quebrachos",
      "id": "86140010000",
      "localidad_censal_id": "86140010",
      "localidad_censal_nombre": "Ramírez de Velazco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RAMIREZ DE VELAZCO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3856768376799,
      "centroide_lon": -63.4739812028011,
      "departamento_id": "86140",
      "departamento_nombre": "Quebrachos",
      "id": "86140020000",
      "localidad_censal_id": "86140020",
      "localidad_censal_nombre": "Sumampa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SUMAMPA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.3873857117046,
      "centroide_lon": -63.4413442606189,
      "departamento_id": "86140",
      "departamento_nombre": "Quebrachos",
      "id": "86140030000",
      "localidad_censal_id": "86140030",
      "localidad_censal_nombre": "Sumampa Viejo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SUMAMPA VIEJO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.566815560625,
      "centroide_lon": -64.6802356354788,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147010000",
      "localidad_censal_id": "86147010",
      "localidad_censal_nombre": "Chañar Pozo de Abajo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHAÑAR POZO DE ABAJO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5214742780514,
      "centroide_lon": -64.5558862404344,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147020000",
      "localidad_censal_id": "86147020",
      "localidad_censal_nombre": "Chauchillas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHAUCHILLAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4319202445614,
      "centroide_lon": -64.9313037647628,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147030000",
      "localidad_censal_id": "86147030",
      "localidad_censal_nombre": "Colonia Tinco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA TINCO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.2348881521517,
      "centroide_lon": -64.697822026062,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147040000",
      "localidad_censal_id": "86147040",
      "localidad_censal_nombre": "El Charco",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CHARCO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.3020072164497,
      "centroide_lon": -64.6130855140247,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147050000",
      "localidad_censal_id": "86147050",
      "localidad_censal_nombre": "Gramilla",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GRAMILLA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4563912240441,
      "centroide_lon": -64.9277000920229,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147060000",
      "localidad_censal_id": "86147060",
      "localidad_censal_nombre": "La Nueva Donosa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LA NUEVA DONOSA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4719466367193,
      "centroide_lon": -64.615993605497,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147070000",
      "localidad_censal_id": "86147070",
      "localidad_censal_nombre": "Los Miranda",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS MIRANDA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5338396475268,
      "centroide_lon": -64.5310863310616,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147080000",
      "localidad_censal_id": "86147080",
      "localidad_censal_nombre": "Los Núñez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS NUÑEZ",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4611344607229,
      "centroide_lon": -64.9065546199272,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147090000",
      "localidad_censal_id": "86147090",
      "localidad_censal_nombre": "Mansupa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MANSUPA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3041200176408,
      "centroide_lon": -64.7530797214091,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147100000",
      "localidad_censal_id": "86147100",
      "localidad_censal_nombre": "Pozuelos",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZUELOS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5527256617805,
      "centroide_lon": -64.5111174447533,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147110000",
      "localidad_censal_id": "86147110",
      "localidad_censal_nombre": "Rodeo de Valdez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RODEO DE VALDEZ",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.491671176486,
      "centroide_lon": -64.6013992349245,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147120000",
      "localidad_censal_id": "86147120",
      "localidad_censal_nombre": "El Sauzal",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAUZAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5012564484806,
      "centroide_lon": -64.8552586634364,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147130000",
      "localidad_censal_id": "86147130",
      "localidad_censal_nombre": "Termas de Río Hondo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "TERMAS DE RIO HONDO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.574990170048,
      "centroide_lon": -64.4753061878465,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147140000",
      "localidad_censal_id": "86147140",
      "localidad_censal_nombre": "Villa Giménez",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA GIMENEZ",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5983201543603,
      "centroide_lon": -64.8739939701803,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147150000",
      "localidad_censal_id": "86147150",
      "localidad_censal_nombre": "Villa Río Hondo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA RIO HONDO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.516849315862,
      "centroide_lon": -64.9027287200084,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147160000",
      "localidad_censal_id": "86147160",
      "localidad_censal_nombre": "Villa Turística del Embalse de Rio Hondo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA TURISTICA DEL EMBALSE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.379706365694,
      "centroide_lon": -64.7967473078135,
      "departamento_id": "86147",
      "departamento_nombre": "Río Hondo",
      "id": "86147170000",
      "localidad_censal_id": "86147170",
      "localidad_censal_nombre": "Vinará",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VINARA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -30.0603796584752,
      "centroide_lon": -62.1051312621272,
      "departamento_id": "86154",
      "departamento_nombre": "Rivadavia",
      "id": "86154010000",
      "localidad_censal_id": "86154010",
      "localidad_censal_nombre": "Colonia Alpina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA ALPINA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.6773076972314,
      "centroide_lon": -62.1374427216007,
      "departamento_id": "86154",
      "departamento_nombre": "Rivadavia",
      "id": "86154020000",
      "localidad_censal_id": "86154020",
      "localidad_censal_nombre": "Palo Negro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PALO NEGRO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.7604898000173,
      "centroide_lon": -62.0525063759069,
      "departamento_id": "86154",
      "departamento_nombre": "Rivadavia",
      "id": "86154030000",
      "localidad_censal_id": "86154030",
      "localidad_censal_nombre": "Selva",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SELVA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8315913828113,
      "centroide_lon": -64.061379252439,
      "departamento_id": "86161",
      "departamento_nombre": "Robles",
      "id": "86161010000",
      "localidad_censal_id": "86161010",
      "localidad_censal_nombre": "Beltrán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BELTRAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7226465873966,
      "centroide_lon": -63.8598770739209,
      "departamento_id": "86161",
      "departamento_nombre": "Robles",
      "id": "86161020000",
      "localidad_censal_id": "86161020",
      "localidad_censal_nombre": "Colonia El Simbolar",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLONIA EL SIMBOLAR",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9241249979993,
      "centroide_lon": -63.8937760389577,
      "departamento_id": "86161",
      "departamento_nombre": "Robles",
      "id": "86161030000",
      "localidad_censal_id": "86161030",
      "localidad_censal_nombre": "Fernández",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FERNANDEZ",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8774908793598,
      "centroide_lon": -63.9791359214751,
      "departamento_id": "86161",
      "departamento_nombre": "Robles",
      "id": "86161040000",
      "localidad_censal_id": "86161040",
      "localidad_censal_nombre": "Ingeniero Forres",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "INGENIERO FORRES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7878023282581,
      "centroide_lon": -64.1510014560828,
      "departamento_id": "86161",
      "departamento_nombre": "Robles",
      "id": "86161050000",
      "localidad_censal_id": "86161050",
      "localidad_censal_nombre": "Vilmer",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILMER",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.796459373074,
      "centroide_lon": -63.5791247989506,
      "departamento_id": "86168",
      "departamento_nombre": "Salavina",
      "id": "86168010000",
      "localidad_censal_id": "86168010",
      "localidad_censal_nombre": "Chilca Juliana",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHILCA JULIANA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.9858790620734,
      "centroide_lon": -63.4474428106687,
      "departamento_id": "86168",
      "departamento_nombre": "Salavina",
      "id": "86168020000",
      "localidad_censal_id": "86168020",
      "localidad_censal_nombre": "Los Telares",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LOS TELARES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.804933488379,
      "centroide_lon": -63.4286953351842,
      "departamento_id": "86168",
      "departamento_nombre": "Salavina",
      "id": "86168030000",
      "localidad_censal_id": "86168030",
      "localidad_censal_nombre": "Villa Salavina",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SALAVINA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2433820721478,
      "centroide_lon": -63.9464098988816,
      "departamento_id": "86175",
      "departamento_nombre": "San Martín",
      "id": "86175010000",
      "localidad_censal_id": "86175010",
      "localidad_censal_nombre": "Brea Pozo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BREA POZO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0511950311521,
      "centroide_lon": -63.9907281128401,
      "departamento_id": "86175",
      "departamento_nombre": "San Martín",
      "id": "86175020000",
      "localidad_censal_id": "86175020",
      "localidad_censal_nombre": "Estación Robles",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION ROBLES",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0088248181919,
      "centroide_lon": -63.7454952959481,
      "departamento_id": "86175",
      "departamento_nombre": "San Martín",
      "id": "86175030000",
      "localidad_censal_id": "86175030",
      "localidad_censal_nombre": "Estación Taboada",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ESTACION TABOADA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3143322879508,
      "centroide_lon": -63.9957666086631,
      "departamento_id": "86175",
      "departamento_nombre": "San Martín",
      "id": "86175040000",
      "localidad_censal_id": "86175040",
      "localidad_censal_nombre": "Villa Nueva",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA NUEVA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1529581822136,
      "centroide_lon": -63.5345160915478,
      "departamento_id": "86182",
      "departamento_nombre": "Sarmiento",
      "id": "86182010000",
      "localidad_censal_id": "86182010",
      "localidad_censal_nombre": "Garza",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "GARZA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.0530238668842,
      "centroide_lon": -64.2227939749583,
      "departamento_id": "86189",
      "departamento_nombre": "Silípica",
      "id": "86189010000",
      "localidad_censal_id": "86189010",
      "localidad_censal_nombre": "Árraga",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ARRAGA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1833426920499,
      "centroide_lon": -64.1968111536257,
      "departamento_id": "86189",
      "departamento_nombre": "Silípica",
      "id": "86189020000",
      "localidad_censal_id": "86189020",
      "localidad_censal_nombre": "Nueva Francia",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA FRANCIA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1030540788067,
      "centroide_lon": -64.2130484994317,
      "departamento_id": "86189",
      "departamento_nombre": "Silípica",
      "id": "86189030000",
      "localidad_censal_id": "86189030",
      "localidad_censal_nombre": "Simbol",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SIMBOL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1717134352764,
      "centroide_lon": -64.101040971413,
      "departamento_id": "86189",
      "departamento_nombre": "Silípica",
      "id": "86189040000",
      "localidad_censal_id": "86189040",
      "localidad_censal_nombre": "Sumamao",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SUMAMAO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.1105007595588,
      "centroide_lon": -64.1476921495566,
      "departamento_id": "86189",
      "departamento_nombre": "Silípica",
      "id": "86189050000",
      "localidad_censal_id": "86189050",
      "localidad_censal_nombre": "Villa Silípica",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SILIPICA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6843396000243,
      "centroide_lon": -65.0481349066456,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007010000",
      "localidad_censal_id": "90007010",
      "localidad_censal_nombre": "Barrio San Jorge",
      "municipio_id": "908021",
      "municipio_nombre": "El Naranjo y el Sunchal",
      "nombre": "BARRIO SAN JORGE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7583605607957,
      "centroide_lon": -65.0693888917573,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007020000",
      "localidad_censal_id": "90007020",
      "localidad_censal_nombre": "El Chañar",
      "municipio_id": "908014",
      "municipio_nombre": "El Chañar",
      "nombre": "EL CHAÑAR",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6571859218044,
      "centroide_lon": -65.0483602838482,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007030000",
      "localidad_censal_id": "90007030",
      "localidad_censal_nombre": "El Naranjo",
      "municipio_id": "908021",
      "municipio_nombre": "El Naranjo y el Sunchal",
      "nombre": "EL NARANJO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5736893005766,
      "centroide_lon": -64.5576861101138,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007040000",
      "localidad_censal_id": "90007040",
      "localidad_censal_nombre": "Garmendia",
      "municipio_id": "908042",
      "municipio_nombre": "Garmendia",
      "nombre": "GARMENDIA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6881473308745,
      "centroide_lon": -64.9464369337093,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007050000",
      "localidad_censal_id": "90007050",
      "localidad_censal_nombre": "La Ramada",
      "municipio_id": "908056",
      "municipio_nombre": "La Ramada y la Cruz",
      "nombre": "LA RAMADA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7303436942447,
      "centroide_lon": -65.0113600491402,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007060000",
      "localidad_censal_id": "90007060",
      "localidad_censal_nombre": "Macomitas",
      "municipio_id": "908014",
      "municipio_nombre": "El Chañar",
      "nombre": "MACOMITAS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7398606901892,
      "centroide_lon": -64.6493040222716,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007070000",
      "localidad_censal_id": "90007070",
      "localidad_censal_nombre": "Piedrabuena",
      "municipio_id": "908049",
      "municipio_nombre": "Gdor. Piedrabuena",
      "nombre": "PIEDRABUENA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2918847472245,
      "centroide_lon": -64.5005128631092,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007080000",
      "localidad_censal_id": "90007080",
      "localidad_censal_nombre": "7 de Abril",
      "municipio_id": "908007",
      "municipio_nombre": "7 de Abril",
      "nombre": "7 DE ABRIL",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5561603640592,
      "centroide_lon": -64.7983838278956,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007090000",
      "localidad_censal_id": "90007090",
      "localidad_censal_nombre": "Villa Benjamín Aráoz",
      "municipio_id": "908063",
      "municipio_nombre": "Benjamin Araoz y el Tajamar",
      "nombre": "VILLA BENJAMIN ARAOZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5003323203292,
      "centroide_lon": -64.7419938212453,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007100000",
      "localidad_censal_id": "90007100",
      "localidad_censal_nombre": "Villa Burruyacú",
      "municipio_id": "900007",
      "municipio_nombre": "Municipalidad de Burruyacu",
      "nombre": "VILLA BURRUYACU",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5064025936102,
      "centroide_lon": -64.9998985377047,
      "departamento_id": "90007",
      "departamento_nombre": "Burruyacú",
      "id": "90007110000",
      "localidad_censal_id": "90007110",
      "localidad_censal_nombre": "Villa Padre Monti",
      "municipio_id": "908070",
      "municipio_nombre": "Villa Padre Monti",
      "nombre": "VILLA PADRE MONTI",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8220641277209,
      "centroide_lon": -65.1385924038502,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014010001",
      "localidad_censal_id": "90014010",
      "localidad_censal_nombre": "Alderetes",
      "municipio_id": "900021",
      "municipio_nombre": "Municipalidad de Alderetes",
      "nombre": "ALDERETES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8184460052552,
      "centroide_lon": -65.1556778939315,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014010002",
      "localidad_censal_id": "90014010",
      "localidad_censal_nombre": "Alderetes",
      "municipio_id": "900021",
      "municipio_nombre": "Municipalidad de Alderetes",
      "nombre": "EL CORTE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.80204642135,
      "centroide_lon": -65.1258766860824,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014010003",
      "localidad_censal_id": "90014010",
      "localidad_censal_nombre": "Alderetes",
      "municipio_id": "900021",
      "municipio_nombre": "Municipalidad de Alderetes",
      "nombre": "LOS GUTIERREZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8438678881267,
      "centroide_lon": -65.1632837939025,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014020001",
      "localidad_censal_id": "90014020",
      "localidad_censal_nombre": "Banda del Río Salí",
      "municipio_id": "900028",
      "municipio_nombre": "Municipalidad de Banda del Rio Sali",
      "nombre": "BANDA DEL RIO SALI",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8498779490703,
      "centroide_lon": -65.1293137289629,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014020002",
      "localidad_censal_id": "90014020",
      "localidad_censal_nombre": "Banda del Río Salí",
      "municipio_id": "900028",
      "municipio_nombre": "Municipalidad de Banda del Rio Sali",
      "nombre": "BARRIO AEROPUERTO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8621611908602,
      "centroide_lon": -65.1625825810305,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014020003",
      "localidad_censal_id": "90014020",
      "localidad_censal_nombre": "Banda del Río Salí",
      "municipio_id": "900028",
      "municipio_nombre": "Municipalidad de Banda del Rio Sali",
      "nombre": "LASTENIA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.8835749144059,
      "centroide_lon": -65.0999411428557,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014040000",
      "localidad_censal_id": "90014040",
      "localidad_censal_nombre": "Colombres",
      "municipio_id": "908077",
      "municipio_nombre": "Colombres",
      "nombre": "COLOMBRES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8339561201266,
      "centroide_lon": -64.9893799370396,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014050000",
      "localidad_censal_id": "90014050",
      "localidad_censal_nombre": "Colonia Mayo - Barrio La Milagrosa",
      "municipio_id": "908133",
      "municipio_nombre": "Los Perez",
      "nombre": "COLONIA MAYO - BARRIO LA MILAGROSA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8346473974393,
      "centroide_lon": -65.0955588658241,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014060001",
      "localidad_censal_id": "90014060",
      "localidad_censal_nombre": "Delfín Gallo",
      "municipio_id": "908084",
      "municipio_nombre": "Delfin Gallo",
      "nombre": "EL PARAISO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8440192084768,
      "centroide_lon": -65.0924758564779,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014060002",
      "localidad_censal_id": "90014060",
      "localidad_censal_nombre": "Delfín Gallo",
      "municipio_id": "908084",
      "municipio_nombre": "Delfin Gallo",
      "nombre": "EX INGENIO ESPERANZA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8541670602329,
      "centroide_lon": -65.0915199952372,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014060003",
      "localidad_censal_id": "90014060",
      "localidad_censal_nombre": "Delfín Gallo",
      "municipio_id": "908084",
      "municipio_nombre": "Delfin Gallo",
      "nombre": "EX INGENIO LUJAN",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9921019189366,
      "centroide_lon": -65.1812450251063,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014070000",
      "localidad_censal_id": "90014070",
      "localidad_censal_nombre": "El Bracho",
      "municipio_id": "908091",
      "municipio_nombre": "El Bracho y el Cevilar",
      "nombre": "EL BRACHO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8167179154155,
      "centroide_lon": -65.0802671019889,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014080001",
      "localidad_censal_id": "90014080",
      "localidad_censal_nombre": "La Florida",
      "municipio_id": "908105",
      "municipio_nombre": "La Florida y Luisiana",
      "nombre": "INGENIO LA FLORIDA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8190592540013,
      "centroide_lon": -65.0943210848409,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014080002",
      "localidad_censal_id": "90014080",
      "localidad_censal_nombre": "La Florida",
      "municipio_id": "908105",
      "municipio_nombre": "La Florida y Luisiana",
      "nombre": "LA FLORIDA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8878173829847,
      "centroide_lon": -64.7430772509051,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014090000",
      "localidad_censal_id": "90014090",
      "localidad_censal_nombre": "Las Cejas",
      "municipio_id": "908112",
      "municipio_nombre": "Las Cejas",
      "nombre": "LAS CEJAS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8770998455409,
      "centroide_lon": -64.9748987513019,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014100001",
      "localidad_censal_id": "90014100",
      "localidad_censal_nombre": "Los Ralos",
      "municipio_id": "908140",
      "municipio_nombre": "Los Ralos",
      "nombre": "EX INGENIO LOS RALOS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8868084047256,
      "centroide_lon": -65.0074356034814,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014100002",
      "localidad_censal_id": "90014100",
      "localidad_censal_nombre": "Los Ralos",
      "municipio_id": "908140",
      "municipio_nombre": "Los Ralos",
      "nombre": "VILLA RECASTE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8914692726412,
      "centroide_lon": -65.0131822116958,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014100003",
      "localidad_censal_id": "90014100",
      "localidad_censal_nombre": "Los Ralos",
      "municipio_id": "908140",
      "municipio_nombre": "Los Ralos",
      "nombre": "VILLA TERCERA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8992133086383,
      "centroide_lon": -65.1492861893342,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014110000",
      "localidad_censal_id": "90014110",
      "localidad_censal_nombre": "Pacará",
      "municipio_id": "900028",
      "municipio_nombre": "Municipalidad de Banda del Rio Sali",
      "nombre": "PACARA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9547865827356,
      "centroide_lon": -65.0483776049689,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014120000",
      "localidad_censal_id": "90014120",
      "localidad_censal_nombre": "Ranchillos",
      "municipio_id": "908147",
      "municipio_nombre": "Ranchillos y San Miguel",
      "nombre": "RANCHILLOS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8884969343068,
      "centroide_lon": -65.1963401762121,
      "departamento_id": "90014",
      "departamento_nombre": "Cruz Alta",
      "id": "90014130000",
      "localidad_censal_id": "90014130",
      "localidad_censal_nombre": "San Andrés",
      "municipio_id": "908154",
      "municipio_nombre": "San Andres",
      "nombre": "SAN ANDRES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3364644479185,
      "centroide_lon": -65.7560706396094,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021010000",
      "localidad_censal_id": "90021010",
      "localidad_censal_nombre": "Alpachiri",
      "municipio_id": "908161",
      "municipio_nombre": "Alpachiri y el Molino",
      "nombre": "ALPACHIRI",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3793992485609,
      "centroide_lon": -65.6079258666538,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021020000",
      "localidad_censal_id": "90021020",
      "localidad_censal_nombre": "Alto Verde",
      "municipio_id": "908168",
      "municipio_nombre": "Alto Verde y los Guchea",
      "nombre": "ALTO VERDE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3069484835773,
      "centroide_lon": -65.576067487854,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021030000",
      "localidad_censal_id": "90021030",
      "localidad_censal_nombre": "Arcadia",
      "municipio_id": "908175",
      "municipio_nombre": "Arcadia",
      "nombre": "ARCADIA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.3293616351221,
      "centroide_lon": -65.5823860081286,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021040000",
      "localidad_censal_id": "90021040",
      "localidad_censal_nombre": "Barrio San Roque",
      "municipio_id": "908175",
      "municipio_nombre": "Arcadia",
      "nombre": "SAN ROQUE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.3421785890325,
      "centroide_lon": -65.598557597657,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021050000",
      "localidad_censal_id": "90021050",
      "localidad_censal_nombre": "Concepción",
      "municipio_id": "900035",
      "municipio_nombre": "Municipalidad de Concepcion",
      "nombre": "CONCEPCION",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3359615187055,
      "centroide_lon": -65.6527259102121,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021060000",
      "localidad_censal_id": "90021060",
      "localidad_censal_nombre": "Iltico",
      "municipio_id": "900035",
      "municipio_nombre": "Municipalidad de Concepcion",
      "nombre": "ILTICO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.413472537684,
      "centroide_lon": -65.5152314804134,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021070000",
      "localidad_censal_id": "90021070",
      "localidad_censal_nombre": "La Trinidad",
      "municipio_id": "908189",
      "municipio_nombre": "Trinidad",
      "nombre": "LA TRINIDAD",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.418836093134,
      "centroide_lon": -65.5333450199588,
      "departamento_id": "90021",
      "departamento_nombre": "Chicligasta",
      "id": "90021080000",
      "localidad_censal_id": "90021080",
      "localidad_censal_nombre": "Medina",
      "municipio_id": "908196",
      "municipio_nombre": "Medina",
      "nombre": "MEDINA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0430593855029,
      "centroide_lon": -65.4291435588925,
      "departamento_id": "90028",
      "departamento_nombre": "Famaillá",
      "id": "90028010000",
      "localidad_censal_id": "90028010",
      "localidad_censal_nombre": "Barrio Casa Rosada",
      "municipio_id": "900042",
      "municipio_nombre": "Municipalidad de Famailla",
      "nombre": "BARRIO CASA ROSADA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0256026652764,
      "centroide_lon": -65.3464718463273,
      "departamento_id": "90028",
      "departamento_nombre": "Famaillá",
      "id": "90028020000",
      "localidad_censal_id": "90028020",
      "localidad_censal_nombre": "Campo de Herrera",
      "municipio_id": "900042",
      "municipio_nombre": "Municipalidad de Famailla",
      "nombre": "CAMPO DE HERRERA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.0697371751242,
      "centroide_lon": -65.3999011552298,
      "departamento_id": "90028",
      "departamento_nombre": "Famaillá",
      "id": "90028030001",
      "localidad_censal_id": "90028030",
      "localidad_censal_nombre": "Famaillá",
      "municipio_id": "900042",
      "municipio_nombre": "Municipalidad de Famailla",
      "nombre": "EX INGENIO NUEVA BAVIERA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.0450456348935,
      "centroide_lon": -65.3956359866229,
      "departamento_id": "90028",
      "departamento_nombre": "Famaillá",
      "id": "90028030002",
      "localidad_censal_id": "90028030",
      "localidad_censal_nombre": "Famaillá",
      "municipio_id": "900042",
      "municipio_nombre": "Municipalidad de Famailla",
      "nombre": "FAMAILLA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0354707281153,
      "centroide_lon": -65.455616412105,
      "departamento_id": "90028",
      "departamento_nombre": "Famaillá",
      "id": "90028040000",
      "localidad_censal_id": "90028040",
      "localidad_censal_nombre": "Ingenio Fronterita",
      "municipio_id": "900042",
      "municipio_nombre": "Municipalidad de Famailla",
      "nombre": "INGENIO FRONTERITA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6485245594157,
      "centroide_lon": -65.4389216777288,
      "departamento_id": "90035",
      "departamento_nombre": "Graneros",
      "id": "90035010000",
      "localidad_censal_id": "90035010",
      "localidad_censal_nombre": "Graneros",
      "municipio_id": "900049",
      "municipio_nombre": "Municipalidad de Graneros",
      "nombre": "GRANEROS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.6466143101473,
      "centroide_lon": -65.2504927223474,
      "departamento_id": "90035",
      "departamento_nombre": "Graneros",
      "id": "90035020000",
      "localidad_censal_id": "90035020",
      "localidad_censal_nombre": "Lamadrid",
      "municipio_id": "908203",
      "municipio_nombre": "Lamadrid",
      "nombre": "LAMADRID",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.8350683836867,
      "centroide_lon": -65.1946017699559,
      "departamento_id": "90035",
      "departamento_nombre": "Graneros",
      "id": "90035030000",
      "localidad_censal_id": "90035030",
      "localidad_censal_nombre": "Taco Ralo",
      "municipio_id": "908210",
      "municipio_nombre": "Taco Ralo",
      "nombre": "TACO RALO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5859774039585,
      "centroide_lon": -65.620843677012,
      "departamento_id": "90042",
      "departamento_nombre": "Juan Bautista Alberdi",
      "id": "90042010000",
      "localidad_censal_id": "90042010",
      "localidad_censal_nombre": "Juan Bautista Alberdi",
      "municipio_id": "900056",
      "municipio_nombre": "Municipalidad de Juan Bautista Alberdi",
      "nombre": "JUAN BAUTISTA ALBERDI",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5271408525417,
      "centroide_lon": -65.614612836175,
      "departamento_id": "90042",
      "departamento_nombre": "Juan Bautista Alberdi",
      "id": "90042020000",
      "localidad_censal_id": "90042020",
      "localidad_censal_nombre": "Villa Belgrano",
      "municipio_id": "908224",
      "municipio_nombre": "Comuna Villa Belgrano",
      "nombre": "VILLA BELGRANO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7730048354523,
      "centroide_lon": -65.5864568354594,
      "departamento_id": "90049",
      "departamento_nombre": "La Cocha",
      "id": "90049010000",
      "localidad_censal_id": "90049010",
      "localidad_censal_nombre": "La Cocha",
      "municipio_id": "900063",
      "municipio_nombre": "Municipalidad de la Cocha",
      "nombre": "LA COCHA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7315967012344,
      "centroide_lon": -65.5827595206013,
      "departamento_id": "90049",
      "departamento_nombre": "La Cocha",
      "id": "90049020000",
      "localidad_censal_id": "90049020",
      "localidad_censal_nombre": "San José de La Cocha",
      "municipio_id": "908259",
      "municipio_nombre": "San Jose de la Cocha",
      "nombre": "SAN JOSE DE LA COCHA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0310036528782,
      "centroide_lon": -65.3086376425668,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056010000",
      "localidad_censal_id": "90056010",
      "localidad_censal_nombre": "Bella Vista",
      "municipio_id": "900070",
      "municipio_nombre": "Municipalidad de Bella Vista",
      "nombre": "BELLA VISTA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0571152473018,
      "centroide_lon": -64.9218123993231,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056020000",
      "localidad_censal_id": "90056020",
      "localidad_censal_nombre": "Estación Aráoz",
      "municipio_id": "908294",
      "municipio_nombre": "Estacion Araoz y Tacanas",
      "nombre": "ESTACION ARAOZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2808998221653,
      "centroide_lon": -65.0190299575107,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056030000",
      "localidad_censal_id": "90056030",
      "localidad_censal_nombre": "Los Puestos",
      "municipio_id": "908315",
      "municipio_nombre": "Los Puestos",
      "nombre": "LOS PUESTOS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9557033913379,
      "centroide_lon": -65.272073063004,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056040000",
      "localidad_censal_id": "90056040",
      "localidad_censal_nombre": "Manuel García Fernández",
      "municipio_id": "908322",
      "municipio_nombre": "Manuel Garcia Fernandez",
      "nombre": "MANUEL GARCIA FERNANDEZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0563442946496,
      "centroide_lon": -65.2181035623283,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056050000",
      "localidad_censal_id": "90056050",
      "localidad_censal_nombre": "Pala Pala",
      "municipio_id": "908329",
      "municipio_nombre": "Quilmes y los Sueldos",
      "nombre": "PALA PALA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1496800217878,
      "centroide_lon": -65.3560513582608,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056060000",
      "localidad_censal_id": "90056060",
      "localidad_censal_nombre": "Río Colorado",
      "municipio_id": "908336",
      "municipio_nombre": "Rio Colorado",
      "nombre": "RIO COLORADO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1378928767222,
      "centroide_lon": -65.2614173877659,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056070000",
      "localidad_censal_id": "90056070",
      "localidad_censal_nombre": "Santa Rosa de Leales",
      "municipio_id": "908343",
      "municipio_nombre": "Santa Rosa de Leales y L. Blanca",
      "nombre": "SANTA ROSA DE LEALES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0677472403134,
      "centroide_lon": -65.2354487523003,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056080000",
      "localidad_censal_id": "90056080",
      "localidad_censal_nombre": "Villa Fiad - Ingenio Leales",
      "municipio_id": "908329",
      "municipio_nombre": "Quilmes y los Sueldos",
      "nombre": "VILLA FIAD - INGENIO LEALES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1942605268211,
      "centroide_lon": -65.3095561336322,
      "departamento_id": "90056",
      "departamento_nombre": "Leales",
      "id": "90056090000",
      "localidad_censal_id": "90056090",
      "localidad_censal_nombre": "Villa de Leales",
      "municipio_id": "908350",
      "municipio_nombre": "Villa de Leales",
      "nombre": "VILLA DE LEALES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.8749835532106,
      "centroide_lon": -65.2322262379924,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063010000",
      "localidad_censal_id": "90063010",
      "localidad_censal_nombre": "Barrio San Felipe",
      "municipio_id": "900014",
      "municipio_nombre": "Municipalidad de San Miguel De Tucuman",
      "nombre": "BARRIO SAN FELIPE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8418175731977,
      "centroide_lon": -65.2753559359172,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063020001",
      "localidad_censal_id": "90063020",
      "localidad_censal_nombre": "El Manantial",
      "municipio_id": "908357",
      "municipio_nombre": "El Manantial",
      "nombre": "BARRIO ARAUJO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8445604430497,
      "centroide_lon": -65.2856690422125,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063020002",
      "localidad_censal_id": "90063020",
      "localidad_censal_nombre": "El Manantial",
      "municipio_id": "908357",
      "municipio_nombre": "El Manantial",
      "nombre": "EL MANANTIAL",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8739965593381,
      "centroide_lon": -65.3103499893471,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063030000",
      "localidad_censal_id": "90063030",
      "localidad_censal_nombre": "Ingenio San Pablo",
      "municipio_id": "908371",
      "municipio_nombre": "San Pablo y Villa Nougues",
      "nombre": "INGENIO SAN PABLO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9601338991114,
      "centroide_lon": -65.3514727517897,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063040000",
      "localidad_censal_id": "90063040",
      "localidad_censal_nombre": "La Reducción",
      "municipio_id": "900077",
      "municipio_nombre": "Municipalidad de Lules",
      "nombre": "LA REDUCCION",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9239379177434,
      "centroide_lon": -65.3364017910576,
      "departamento_id": "90063",
      "departamento_nombre": "Lules",
      "id": "90063050000",
      "localidad_censal_id": "90063050",
      "localidad_censal_nombre": "Lules",
      "municipio_id": "900077",
      "municipio_nombre": "Municipalidad de Lules",
      "nombre": "LULES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.12072050474,
      "centroide_lon": -65.4705647177524,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070010000",
      "localidad_censal_id": "90070010",
      "localidad_censal_nombre": "Acheral",
      "municipio_id": "908378",
      "municipio_nombre": "Acheral",
      "nombre": "ACHERAL",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1895217713073,
      "centroide_lon": -65.6039648120293,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070020000",
      "localidad_censal_id": "90070020",
      "localidad_censal_nombre": "Capitán Cáceres",
      "municipio_id": "908392",
      "municipio_nombre": "Capitan Caceres",
      "nombre": "CAPITAN CACERES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1674766637374,
      "centroide_lon": -65.4987774491472,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070030000",
      "localidad_censal_id": "90070030",
      "localidad_censal_nombre": "Monteros",
      "municipio_id": "900084",
      "municipio_nombre": "Municipalidad de Monteros",
      "nombre": "MONTEROS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2207848342688,
      "centroide_lon": -65.527417311463,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070040000",
      "localidad_censal_id": "90070040",
      "localidad_censal_nombre": "Pueblo Independencia",
      "municipio_id": "908406",
      "municipio_nombre": "Leon Rouges y Santa Rosa",
      "nombre": "PUEBLO INDEPENDENCIA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.2690482043801,
      "centroide_lon": -65.5593459528459,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070050000",
      "localidad_censal_id": "90070050",
      "localidad_censal_nombre": "Río Seco",
      "municipio_id": "908420",
      "municipio_nombre": "Rio Seco",
      "nombre": "RIO SECO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0930813575252,
      "centroide_lon": -65.5325624964532,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070060000",
      "localidad_censal_id": "90070060",
      "localidad_censal_nombre": "Santa Lucía",
      "municipio_id": "908427",
      "municipio_nombre": "Santa Lucia",
      "nombre": "SANTA LUCIA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2278200846423,
      "centroide_lon": -65.6598562004025,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070070000",
      "localidad_censal_id": "90070070",
      "localidad_censal_nombre": "Sargento Moya",
      "municipio_id": "908434",
      "municipio_nombre": "Sargento Moya",
      "nombre": "SARGENTO MOYA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1423360079647,
      "centroide_lon": -65.5656822015637,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070080000",
      "localidad_censal_id": "90070080",
      "localidad_censal_nombre": "Soldado Maldonado",
      "municipio_id": "908441",
      "municipio_nombre": "Soldado Maldonado",
      "nombre": "SOLDADO MALDONADO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.050034787094,
      "centroide_lon": -65.4875831419552,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070090000",
      "localidad_censal_id": "90070090",
      "localidad_censal_nombre": "Teniente Berdina",
      "municipio_id": "908448",
      "municipio_nombre": "Teniente Berdina",
      "nombre": "TENIENTE BERDINA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -27.2536712257247,
      "centroide_lon": -65.5524720047212,
      "departamento_id": "90070",
      "departamento_nombre": "Monteros",
      "id": "90070100000",
      "localidad_censal_id": "90070100",
      "localidad_censal_nombre": "Villa Quinteros",
      "municipio_id": "908455",
      "municipio_nombre": "Villa Quinteros",
      "nombre": "VILLA QUINTEROS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4298511184032,
      "centroide_lon": -65.6204066716154,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077010001",
      "localidad_censal_id": "90077010",
      "localidad_censal_nombre": "Aguilares",
      "municipio_id": "900091",
      "municipio_nombre": "Municipalidad de Aguilares",
      "nombre": "AGUILARES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -27.4557975105898,
      "centroide_lon": -65.6064955796251,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077010002",
      "localidad_censal_id": "90077010",
      "localidad_censal_nombre": "Aguilares",
      "municipio_id": "900091",
      "municipio_nombre": "Municipalidad de Aguilares",
      "nombre": "INGENIO SANTA BARBARA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.413720870217,
      "centroide_lon": -65.695439891394,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077020000",
      "localidad_censal_id": "90077020",
      "localidad_censal_nombre": "Los Sarmientos",
      "municipio_id": "908469",
      "municipio_nombre": "Los Sarmiento y la Tipa",
      "nombre": "LOS SARMIENTOS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4809961150586,
      "centroide_lon": -65.6214061319444,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077030000",
      "localidad_censal_id": "90077030",
      "localidad_censal_nombre": "Río Chico",
      "municipio_id": "908483",
      "municipio_nombre": "Santa Ana",
      "nombre": "RIO CHICO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4722321360222,
      "centroide_lon": -65.6841675424243,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077040000",
      "localidad_censal_id": "90077040",
      "localidad_censal_nombre": "Santa Ana",
      "municipio_id": "908483",
      "municipio_nombre": "Santa Ana",
      "nombre": "SANTA ANA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4739466117854,
      "centroide_lon": -65.6592300213907,
      "departamento_id": "90077",
      "departamento_nombre": "Río Chico",
      "id": "90077050000",
      "localidad_censal_id": "90077050",
      "localidad_censal_nombre": "Villa Clodomiro Hileret",
      "municipio_id": "908483",
      "municipio_nombre": "Santa Ana",
      "nombre": "VILLA CLODOMIRO HILERET",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.82900979033,
      "centroide_lon": -65.2105441811048,
      "departamento_id": "90084",
      "departamento_nombre": "Capital",
      "id": "90084010000",
      "localidad_censal_id": "90084010",
      "localidad_censal_nombre": "San Miguel de Tucumán",
      "municipio_id": "900014",
      "municipio_nombre": "Municipalidad de San Miguel De Tucuman",
      "nombre": "SAN MIGUEL DE TUCUMAN",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4175004497156,
      "centroide_lon": -65.2878399932446,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091010000",
      "localidad_censal_id": "90091010",
      "localidad_censal_nombre": "Atahona",
      "municipio_id": "908490",
      "municipio_nombre": "Atahona",
      "nombre": "ATAHONA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.5109095164641,
      "centroide_lon": -65.2776930194393,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091020000",
      "localidad_censal_id": "90091020",
      "localidad_censal_nombre": "Monteagudo",
      "municipio_id": "908518",
      "municipio_nombre": "Monteagudo",
      "nombre": "MONTEAGUDO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4845113363814,
      "centroide_lon": -65.4912509331708,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091030000",
      "localidad_censal_id": "90091030",
      "localidad_censal_nombre": "Nueva Trinidad",
      "municipio_id": "908532",
      "municipio_nombre": "Rio Chico y Nueva Trinidad",
      "nombre": "NUEVA TRINIDAD",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.3865618797716,
      "centroide_lon": -65.4563894902029,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091040000",
      "localidad_censal_id": "90091040",
      "localidad_censal_nombre": "Santa Cruz",
      "municipio_id": "908546",
      "municipio_nombre": "Santa Cruz y la Tuna",
      "nombre": "SANTA CRUZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2624226707095,
      "centroide_lon": -65.3552894738936,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091050000",
      "localidad_censal_id": "90091050",
      "localidad_censal_nombre": "Simoca",
      "municipio_id": "900098",
      "municipio_nombre": "Municipalidad de Simoca",
      "nombre": "SIMOCA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4352233904382,
      "centroide_lon": -65.163544429074,
      "departamento_id": "90091",
      "departamento_nombre": "Simoca",
      "id": "90091060000",
      "localidad_censal_id": "90091060",
      "localidad_censal_nombre": "Villa Chicligasta",
      "municipio_id": "908553",
      "municipio_nombre": "Villa de Chicligasta",
      "nombre": "VILLA CHICLIGASTA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.5936376610691,
      "centroide_lon": -65.9279885664724,
      "departamento_id": "90098",
      "departamento_nombre": "Tafí del Valle",
      "id": "90098010000",
      "localidad_censal_id": "90098010",
      "localidad_censal_nombre": "Amaicha del Valle",
      "municipio_id": "908567",
      "municipio_nombre": "Amaicha del Valle",
      "nombre": "AMAICHA DEL VALLE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.3605355069816,
      "centroide_lon": -65.9589195037969,
      "departamento_id": "90098",
      "departamento_nombre": "Tafí del Valle",
      "id": "90098020000",
      "localidad_censal_id": "90098020",
      "localidad_censal_nombre": "Colalao del Valle",
      "municipio_id": "908574",
      "municipio_nombre": "Colalao del Valle",
      "nombre": "COLALAO DEL VALLE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.9392209221338,
      "centroide_lon": -65.7081542446876,
      "departamento_id": "90098",
      "departamento_nombre": "Tafí del Valle",
      "id": "90098030000",
      "localidad_censal_id": "90098030",
      "localidad_censal_nombre": "El Mollar",
      "municipio_id": "908581",
      "municipio_nombre": "El Mollar",
      "nombre": "EL MOLLAR",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.8527978993495,
      "centroide_lon": -65.7085573791718,
      "departamento_id": "90098",
      "departamento_nombre": "Tafí del Valle",
      "id": "90098040000",
      "localidad_censal_id": "90098040",
      "localidad_censal_nombre": "Tafí del Valle",
      "municipio_id": "900105",
      "municipio_nombre": "Municipalidad de Tafi del Valle",
      "nombre": "TAFI DEL VALLE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7081441784822,
      "centroide_lon": -65.2200815585159,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105010000",
      "localidad_censal_id": "90105010",
      "localidad_censal_nombre": "Barrio El Cruce",
      "municipio_id": "908616",
      "municipio_nombre": "Los Nogales",
      "nombre": "BARRIO EL CRUCE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7465814588322,
      "centroide_lon": -65.2334174034289,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105020000",
      "localidad_censal_id": "90105020",
      "localidad_censal_nombre": "Barrio Lomas de Tafí",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "BARRIO LOMAS DE TAFI",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7180167498877,
      "centroide_lon": -65.2249109374961,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105030000",
      "localidad_censal_id": "90105030",
      "localidad_censal_nombre": "Barrio Mutual San Martín",
      "municipio_id": "908616",
      "municipio_nombre": "Los Nogales",
      "nombre": "BARRIO MUTUAL SAN MARTIN",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7527987012624,
      "centroide_lon": -65.248427031041,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105040000",
      "localidad_censal_id": "90105040",
      "localidad_censal_nombre": "Barrio Parada 14",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "BARRIO PARADA 14",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.756263713385,
      "centroide_lon": -65.2390248245137,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105050000",
      "localidad_censal_id": "90105050",
      "localidad_censal_nombre": "Barrio U.T.A. II",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "BARRIO U.T.A. II",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.7815251300034,
      "centroide_lon": -65.2193041804807,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105060000",
      "localidad_censal_id": "90105060",
      "localidad_censal_nombre": "Diagonal Norte - Luz y Fuerza - Los Pocitos - Villa Nueva Italia",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "DIAG. NORTE - LUZ Y FUERZA - LOS POCITOS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.6325444298094,
      "centroide_lon": -65.2057408343078,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105070000",
      "localidad_censal_id": "90105070",
      "localidad_censal_nombre": "El Cadillal",
      "municipio_id": "908602",
      "municipio_nombre": "El Cadillal",
      "nombre": "EL CADILLAL",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7312682798666,
      "centroide_lon": -65.2558176904322,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105080000",
      "localidad_censal_id": "90105080",
      "localidad_censal_nombre": "Tafí Viejo",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "TAFI VIEJO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7695777143465,
      "centroide_lon": -65.233609939011,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105090000",
      "localidad_censal_id": "90105090",
      "localidad_censal_nombre": "Villa Las Flores",
      "municipio_id": "900119",
      "municipio_nombre": "Municipalidad de Tafi Viejo",
      "nombre": "VILLA LAS FLORES",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta",
      "centroide_lat": -26.7759821127859,
      "centroide_lon": -65.2019701714422,
      "departamento_id": "90105",
      "departamento_nombre": "Tafí Viejo",
      "id": "90105100000",
      "localidad_censal_id": "90105100",
      "localidad_censal_nombre": "Villa Mariano Moreno - El Colmenar",
      "municipio_id": "900112",
      "municipio_nombre": "Municipalidad de las Talitas",
      "nombre": "VILLA MARIANO MORENO - EL COLMENAR",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.4107463918543,
      "centroide_lon": -65.3199726179681,
      "departamento_id": "90112",
      "departamento_nombre": "Trancas",
      "id": "90112010000",
      "localidad_censal_id": "90112010",
      "localidad_censal_nombre": "Choromoro",
      "municipio_id": "908623",
      "municipio_nombre": "Choromoro",
      "nombre": "CHOROMORO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2357654346434,
      "centroide_lon": -65.4938515563214,
      "departamento_id": "90112",
      "departamento_nombre": "Trancas",
      "id": "90112020000",
      "localidad_censal_id": "90112020",
      "localidad_censal_nombre": "San Pedro de Colalao",
      "municipio_id": "908630",
      "municipio_nombre": "San Pedro de Colalao",
      "nombre": "SAN PEDRO DE COLALAO",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.2307317035992,
      "centroide_lon": -65.285166490246,
      "departamento_id": "90112",
      "departamento_nombre": "Trancas",
      "id": "90112030000",
      "localidad_censal_id": "90112030",
      "localidad_censal_nombre": "Villa de Trancas",
      "municipio_id": "900126",
      "municipio_nombre": "Municipalidad de Trancas",
      "nombre": "VILLA DE TRANCAS",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Componente de localidad compuesta con entidad",
      "centroide_lat": -26.7964431224326,
      "centroide_lon": -65.2657159116952,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119010000",
      "localidad_censal_id": "90119010",
      "localidad_censal_nombre": "Barrio San José III",
      "municipio_id": "908560",
      "municipio_nombre": "Municipalidad de Yerba Buena",
      "nombre": "BARRIO SAN JOSE III",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7954020539638,
      "centroide_lon": -65.2653910211364,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119010001",
      "localidad_censal_id": "90119010",
      "localidad_censal_nombre": "Barrio San José III",
      "municipio_id": "908560",
      "municipio_nombre": "Municipalidad de Yerba Buena",
      "nombre": "BARRIO SAN JOSE III",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7629680018015,
      "centroide_lon": -65.3079476709365,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119010002",
      "localidad_censal_id": "90119010",
      "localidad_censal_nombre": "Barrio San José III",
      "municipio_id": "908644",
      "municipio_nombre": "Cevil Redondo",
      "nombre": "COUNTRY JOCKEY CLUB",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7677751896138,
      "centroide_lon": -65.2708444093707,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119020000",
      "localidad_censal_id": "90119020",
      "localidad_censal_nombre": "Villa Carmela",
      "municipio_id": "908644",
      "municipio_nombre": "Cevil Redondo",
      "nombre": "VILLA CARMELA",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.7992050305522,
      "centroide_lon": -65.2646764135868,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119030001",
      "localidad_censal_id": "90119030",
      "localidad_censal_nombre": "Yerba Buena - Marcos Paz",
      "municipio_id": "908560",
      "municipio_nombre": "Municipalidad de Yerba Buena",
      "nombre": "EX INGENIO SAN JOSE",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -26.8088452576169,
      "centroide_lon": -65.3106988268486,
      "departamento_id": "90119",
      "departamento_nombre": "Yerba Buena",
      "id": "90119030002",
      "localidad_censal_id": "90119030",
      "localidad_censal_nombre": "Yerba Buena - Marcos Paz",
      "municipio_id": "908560",
      "municipio_nombre": "Municipalidad de Yerba Buena",
      "nombre": "YERBA BUENA - MARCOS PAZ",
      "provincia_id": "90",
      "provincia_nombre": "Tucumán"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6250478642294,
      "centroide_lon": -58.384387226424,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CONSTITUCION",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6126880821742,
      "centroide_lon": -58.379652044818,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MONSERRAT",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6092161933037,
      "centroide_lon": -58.3563809151849,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PUERTO MADERO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5884243429366,
      "centroide_lon": -58.3759359131827,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RETIRO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6036683192305,
      "centroide_lon": -58.3805143256131,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010005",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN NICOLAS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6215200115838,
      "centroide_lon": -58.3715453876304,
      "departamento_id": "02007",
      "departamento_nombre": "Comuna 1",
      "id": "02007010006",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN TELMO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5858760078165,
      "centroide_lon": -58.3949961802632,
      "departamento_id": "02014",
      "departamento_nombre": "Comuna 2",
      "id": "02014010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "RECOLETA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6090997738407,
      "centroide_lon": -58.4030625195747,
      "departamento_id": "02021",
      "departamento_nombre": "Comuna 3",
      "id": "02021010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BALVANERA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.623864473461,
      "centroide_lon": -58.4018857979853,
      "departamento_id": "02021",
      "departamento_nombre": "Comuna 3",
      "id": "02021010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN CRISTOBAL",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6464135507847,
      "centroide_lon": -58.3842710319828,
      "departamento_id": "02028",
      "departamento_nombre": "Comuna 4",
      "id": "02028010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BARRACAS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6310700719721,
      "centroide_lon": -58.3568304109892,
      "departamento_id": "02028",
      "departamento_nombre": "Comuna 4",
      "id": "02028010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BOCA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6505501963997,
      "centroide_lon": -58.4188550090093,
      "departamento_id": "02028",
      "departamento_nombre": "Comuna 4",
      "id": "02028010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUEVA POMPEYA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6375496406835,
      "centroide_lon": -58.4016756206755,
      "departamento_id": "02028",
      "departamento_nombre": "Comuna 4",
      "id": "02028010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARQUE PATRICIOS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6092276891202,
      "centroide_lon": -58.4217452253939,
      "departamento_id": "02035",
      "departamento_nombre": "Comuna 5",
      "id": "02035010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALMAGRO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6299600080664,
      "centroide_lon": -58.4188403738383,
      "departamento_id": "02035",
      "departamento_nombre": "Comuna 5",
      "id": "02035010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BOEDO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6168254996915,
      "centroide_lon": -58.443603110353,
      "departamento_id": "02042",
      "departamento_nombre": "Comuna 6",
      "id": "02042010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CABALLITO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6368070795903,
      "centroide_lon": -58.4582698515258,
      "departamento_id": "02049",
      "departamento_nombre": "Comuna 7",
      "id": "02049010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FLORES",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6359392274734,
      "centroide_lon": -58.4376956019926,
      "departamento_id": "02049",
      "departamento_nombre": "Comuna 7",
      "id": "02049010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARQUE CHACABUCO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6749935792889,
      "centroide_lon": -58.4761629844706,
      "departamento_id": "02056",
      "departamento_nombre": "Comuna 8",
      "id": "02056010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA LUGANO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6918955269685,
      "centroide_lon": -58.4632519393435,
      "departamento_id": "02056",
      "departamento_nombre": "Comuna 8",
      "id": "02056010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA RIACHUELO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6654688444976,
      "centroide_lon": -58.4465225253229,
      "departamento_id": "02056",
      "departamento_nombre": "Comuna 8",
      "id": "02056010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SOLDATI",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6437960835294,
      "centroide_lon": -58.5191298958228,
      "departamento_id": "02063",
      "departamento_nombre": "Comuna 9",
      "id": "02063010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LINIERS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6583682885816,
      "centroide_lon": -58.5017319521437,
      "departamento_id": "02063",
      "departamento_nombre": "Comuna 9",
      "id": "02063010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MATADEROS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.64864128675,
      "centroide_lon": -58.47645640345,
      "departamento_id": "02063",
      "departamento_nombre": "Comuna 9",
      "id": "02063010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARQUE AVELLANEDA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6276862905939,
      "centroide_lon": -58.4835872783595,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "FLORESTA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6192974826904,
      "centroide_lon": -58.5065803391285,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MONTE CASTRO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6313610222,
      "centroide_lon": -58.493276996941,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VELEZ SARSFIELD",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6301234618064,
      "centroide_lon": -58.5224017483433,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VERSALLES",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6364127783977,
      "centroide_lon": -58.5027288050931,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010005",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA LURO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6194831337073,
      "centroide_lon": -58.5259860729102,
      "departamento_id": "02070",
      "departamento_nombre": "Comuna 10",
      "id": "02070010006",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA REAL",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6042463004493,
      "centroide_lon": -58.4906764324396,
      "departamento_id": "02077",
      "departamento_nombre": "Comuna 11",
      "id": "02077010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA DEL PARQUE",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6023803136553,
      "centroide_lon": -58.5142437101484,
      "departamento_id": "02077",
      "departamento_nombre": "Comuna 11",
      "id": "02077010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA DEVOTO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6100289507588,
      "centroide_lon": -58.4689425541216,
      "departamento_id": "02077",
      "departamento_nombre": "Comuna 11",
      "id": "02077010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA GENERAL MITRE",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0297706094062,
      "centroide_lon": -64.6107284533432,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091050000",
      "localidad_censal_id": "86091050",
      "localidad_censal_nombre": "Isca Yacu",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ISCA YACU",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.033281523993,
      "centroide_lon": -64.6122143434088,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091060000",
      "localidad_censal_id": "86091060",
      "localidad_censal_nombre": "Isca Yacu Semaul",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ISCA YACU SEMAUL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.164621631919,
      "centroide_lon": -64.483386426973,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091070000",
      "localidad_censal_id": "86091070",
      "localidad_censal_nombre": "Pozo Hondo",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "POZO HONDO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -26.7405309182605,
      "centroide_lon": -64.3965511764775,
      "departamento_id": "86091",
      "departamento_nombre": "Jiménez",
      "id": "86091080000",
      "localidad_censal_id": "86091080",
      "localidad_censal_nombre": "San Pedro",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAN PEDRO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.916852444404,
      "centroide_lon": -62.1779644703931,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098010000",
      "localidad_censal_id": "86098010",
      "localidad_censal_nombre": "El Colorado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL COLORADO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.2978508912148,
      "centroide_lon": -61.8013595132333,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098020000",
      "localidad_censal_id": "86098020",
      "localidad_censal_nombre": "El Cuadrado",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "EL CUADRADO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.110413912625,
      "centroide_lon": -63.1950712509572,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098030000",
      "localidad_censal_id": "86098030",
      "localidad_censal_nombre": "Matará",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "MATARA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9373441459042,
      "centroide_lon": -63.4286561024091,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098040000",
      "localidad_censal_id": "86098040",
      "localidad_censal_nombre": "Suncho Corral",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SUNCHO CORRAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.9593446946828,
      "centroide_lon": -62.6095801174635,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098050000",
      "localidad_censal_id": "86098050",
      "localidad_censal_nombre": "Vilelas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILELAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.7812827220804,
      "centroide_lon": -62.9762834233876,
      "departamento_id": "86098",
      "departamento_nombre": "Juan F. Ibarra",
      "id": "86098060000",
      "localidad_censal_id": "86098060",
      "localidad_censal_nombre": "Yuchán",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "YUCHAN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -28.3039639606794,
      "centroide_lon": -64.1851457926154,
      "departamento_id": "86105",
      "departamento_nombre": "Loreto",
      "id": "86105010000",
      "localidad_censal_id": "86105010",
      "localidad_censal_nombre": "Villa San Martín (Est. Loreto)",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SAN MARTIN",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -29.4169970813328,
      "centroide_lon": -62.7903866994546,
      "departamento_id": "86112",
      "departamento_nombre": "Mitre",
      "id": "86112010000",
      "localidad_censal_id": "86112010",
      "localidad_censal_nombre": "Villa Unión",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA UNION",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.2367908165246,
      "centroide_lon": -62.3796548631942,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119010000",
      "localidad_censal_id": "86119010",
      "localidad_censal_nombre": "Aerolito",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "AEROLITO",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1327719671086,
      "centroide_lon": -62.5491667779808,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119020000",
      "localidad_censal_id": "86119020",
      "localidad_censal_nombre": "Alhuampa",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "ALHUAMPA",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.0720923042891,
      "centroide_lon": -62.6462009108307,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119030000",
      "localidad_censal_id": "86119030",
      "localidad_censal_nombre": "Hasse",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HASSE",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.1785919494814,
      "centroide_lon": -62.4685537207535,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119040000",
      "localidad_censal_id": "86119040",
      "localidad_censal_nombre": "Hernán Mejía Miraval",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "HERNAN MEJIA MIRAVAL",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.4618186135826,
      "centroide_lon": -62.9188082355465,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119050000",
      "localidad_censal_id": "86119050",
      "localidad_censal_nombre": "Las Tinajas",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAS TINAJAS",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -27.076215168082,
      "centroide_lon": -63.0708907011302,
      "departamento_id": "86119",
      "departamento_nombre": "Moreno",
      "id": "86119060000",
      "localidad_censal_id": "86119060",
      "localidad_censal_nombre": "Libertad",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LIBERTAD",
      "provincia_id": "86",
      "provincia_nombre": "Santiago del Estero"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.6161936201559,
      "centroide_lon": -58.4829573518477,
      "departamento_id": "02077",
      "departamento_nombre": "Comuna 11",
      "id": "02077010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA SANTA RITA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5606251344745,
      "centroide_lon": -58.4749447458843,
      "departamento_id": "02084",
      "departamento_nombre": "Comuna 12",
      "id": "02084010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COGHLAN",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.553063289604,
      "centroide_lon": -58.4887266668384,
      "departamento_id": "02084",
      "departamento_nombre": "Comuna 12",
      "id": "02084010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "SAAVEDRA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5821104495703,
      "centroide_lon": -58.5034848793052,
      "departamento_id": "02084",
      "departamento_nombre": "Comuna 12",
      "id": "02084010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA PUEYRREDON",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5715411303449,
      "centroide_lon": -58.4878560596649,
      "departamento_id": "02084",
      "departamento_nombre": "Comuna 12",
      "id": "02084010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA URQUIZA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5548815240237,
      "centroide_lon": -58.4502890947349,
      "departamento_id": "02091",
      "departamento_nombre": "Comuna 13",
      "id": "02091010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "BELGRANO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5746427442082,
      "centroide_lon": -58.4509682045183,
      "departamento_id": "02091",
      "departamento_nombre": "Comuna 13",
      "id": "02091010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "COLEGIALES",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5437376606688,
      "centroide_lon": -58.4628575470422,
      "departamento_id": "02091",
      "departamento_nombre": "Comuna 13",
      "id": "02091010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "NUÑEZ",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5738999836423,
      "centroide_lon": -58.4224364523189,
      "departamento_id": "02098",
      "departamento_nombre": "Comuna 14",
      "id": "02098010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PALERMO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5929442472726,
      "centroide_lon": -58.4886714155361,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010001",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "AGRONOMIA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5883730730472,
      "centroide_lon": -58.4541748568778,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010002",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "CHACARITA",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5855218978064,
      "centroide_lon": -58.4791226108546,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010003",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PARQUE CHAS",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5974220425569,
      "centroide_lon": -58.4686652940674,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010004",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "PATERNAL",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5988344062647,
      "centroide_lon": -58.4427221412974,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010005",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA CRESPO",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Entidad",
      "centroide_lat": -34.5809741138535,
      "centroide_lon": -58.4676516664339,
      "departamento_id": "02105",
      "departamento_nombre": "Comuna 15",
      "id": "02105010006",
      "localidad_censal_id": "02000010",
      "localidad_censal_nombre": "Ciudad Autónoma de Buenos Aires",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "VILLA ORTUZAR",
      "provincia_id": "02",
      "provincia_nombre": "Ciudad Autónoma de Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -24.3374800000056,
      "centroide_lon": -65.36524,
      "departamento_id": "38056",
      "departamento_nombre": "San Antonio",
      "id": "38056025001",
      "localidad_censal_id": "38056025",
      "localidad_censal_nombre": "Nuestra Señora del Rosario",
      "municipio_id": "386084",
      "municipio_nombre": "San Antonio",
      "nombre": "LA TOMA",
      "provincia_id": "38",
      "provincia_nombre": "Jujuy"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -34.6882159472973,
      "centroide_lon": -58.9574297137089,
      "departamento_id": "06364",
      "departamento_nombre": "General Rodríguez",
      "id": "06364030005",
      "localidad_censal_id": "06364030",
      "localidad_censal_nombre": "General Rodríguez",
      "municipio_id": "060364",
      "municipio_nombre": "General Rodríguez",
      "nombre": "BARRIO RUTA 24 KILOMETRO 10",
      "provincia_id": "06",
      "provincia_nombre": "Buenos Aires"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -53.7870473449159,
      "centroide_lon": -67.7132350718047,
      "departamento_id": "94008",
      "departamento_nombre": "Río Grande",
      "id": "94008010000",
      "localidad_censal_id": "94008010",
      "localidad_censal_nombre": "Río Grande",
      "municipio_id": "940007",
      "municipio_nombre": "Río Grande",
      "nombre": "RIO GRANDE",
      "provincia_id": "94",
      "provincia_nombre": "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -54.5113867546733,
      "centroide_lon": -67.195804463563,
      "departamento_id": "94008",
      "departamento_nombre": "Río Grande",
      "id": "94008020000",
      "localidad_censal_id": "94008020",
      "localidad_censal_nombre": "Tolhuin",
      "municipio_id": "942007",
      "municipio_nombre": "Tolhuin",
      "nombre": "TOLHUIN",
      "provincia_id": "94",
      "provincia_nombre": "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -54.6376860828691,
      "centroide_lon": -67.766940855265,
      "departamento_id": "94015",
      "departamento_nombre": "Ushuaia",
      "id": "94015010000",
      "localidad_censal_id": "94015010",
      "localidad_censal_nombre": "Laguna Escondida",
      "municipio_id": null,
      "municipio_nombre": null,
      "nombre": "LAGUNA ESCONDIDA",
      "provincia_id": "94",
      "provincia_nombre": "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
    },
    {
      "categoria": "Localidad simple",
      "centroide_lat": -54.8036404601709,
      "centroide_lon": -68.3160624772532,
      "departamento_id": "94015",
      "departamento_nombre": "Ushuaia",
      "id": "94015020000",
      "localidad_censal_id": "94015020",
      "localidad_censal_nombre": "Ushuaia",
      "municipio_id": "940014",
      "municipio_nombre": "Ushuaia",
      "nombre": "USHUAIA",
      "provincia_id": "94",
      "provincia_nombre": "Tierra del Fuego, Antártida e Islas del Atlántico Sur"
    }
  ]

export default localidades;