import React from "react";
import { connect } from 'react-redux';
import { postFigura } from "../../redux/actions";
import { auth, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import Loader from "../loader"
import BackDash from '../../components/backDash/backDash';

import '../add.css';
import Swal from "sweetalert2";
import tokens from "../../variables";

class AddFigura extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            buttonUpload: true,
            progress: 0,
            imgURL: "",
            hasError: false,
            error: "",
            figura_info: {},
            loading: true,
            isLogged: false,
            checked: false
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);

        setTimeout(()=>{
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                buttons[i].disabled = false;
            }
        }, 500)
    }

    uploadImage = (e)=>{

        e.preventDefault();

        const image = document.getElementById("input-cover").files[0];
        if(!image){
            Swal.fire({
                title: "Cargue una imágen válida!",
                icon: "warning",
                button: "Cerrar"
            })
            return 0;
        }
        const storageRef = ref(storage, `/figuras/${image.name}`);
        const task = uploadBytesResumable(storageRef, image);

        task.on(
            "state_changed",
            (snapshot) => {
              const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    ...this.state,
                    progress: percentage
                })
            },
            (error) => {
              console.log(error.message);
            },
            () => {
                getDownloadURL(task.snapshot.ref).then((downloadURL)=>{
                    this.setState({
                        buttonUpload: false,
                        imgURL: downloadURL
                    })
                })
            }
          );
    };

    reset = ()=>{
        this.setState({
            hasError: false,
            error: "",
            nombre: "",
            tooltip: false
        });
    }

    controlInfo = ()=>{
        let title = document.getElementById("input-title");
        let cant = document.getElementById("input-cant");
        let precio = document.getElementById("input-price");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        title.style = "border-color: none";
        cant.style = "border-color: none";
        precio.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(title.value === "" || !title.value){
            title.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Title" ${vacio}`,
                checked: true
            });
        }

        if(cant.value === "" || !cant.value){
            cant.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Cant. stock" ${vacio}`,
                checked: true
            });
        }

        if(precio.value === "" || !precio.value){
            precio.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Precio" ${vacio}`,
                checked: true
            });
        }

        //CONTROLAR ESPACIOS
        if(title.value[0] === " "){
            title.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Title" ${espacio}`,
                checked: true
            })
        }

        return this.setState({
            checked: true
        })

    }

    onSubmit = (e)=>{
        e.preventDefault();
        e.target.disabled = true;

        const figura_info = {
            title: document.getElementById("input-title").value,
            stock: document.getElementById("input-cant").value,
            precio: document.getElementById("input-price").value,
            tags: document.getElementById('sinopsis').value,
            imgURL: this.state.imgURL
        }
        
        this.setState({
            figura_info: figura_info
        }, ()=>{
            this.controlInfo();
        })

    }

    componentDidUpdate(prevProps, prevState){
        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                if(auth.currentUser.uid === tokens.admin){
                    this.setState({
                        isLogged: true,
                        loading: false
                    })
                }else{
                    window.location.href = '/';
                }
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }else if(!auth.currentUser && !this.state.isLogged){
                window.location.href = '/';
            }
        })

        if(prevProps.state.post_state !== this.props.state.post_state){
            if(this.props.state.post_state !== "success"){
                if(this.props.state.post_state.error){
                    Swal.fire({
                        title: this.props.state.post_state.error.detail,
                        text: this.props.state.post_state.error.code,
                        icon: "error"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: this.props.state.post_state,
                        icon: "warning",
                        button: "Cerrar"
                    }).then(()=>{
                        window.location.reload()
                    })
                }

            }else{
                Swal.fire({
                    title: "Agregada!",
                    text: `${this.state.figura_info.title} agregada exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                }).then(()=>{
                    window.location.reload()
                })
            }
        }
        
        if(prevState.checked !== this.state.checked && this.state.checked){
            if(this.state.hasError){
                Swal.fire({
                    title: "Error!",
                    text: this.state.error,
                    icon: "error",
                    button: "Volver"
                })
                .then(()=>{
                    window.location.reload()
                })
            }else{  
                this.props.postFigura(this.state.figura_info);
            }
        }
    }

    render(){

        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir figura</h1>
                    <span className="form-container">
                        <span className="input-add">
                            <p className="p-input">Título</p>
                            <input className="input-" id="input-title" type="text" />
                        </span>
                        <br />
                        <span className="input-add">
                            <p className="p-input">Cant. stock</p>
                            <input className="input-" id="input-cant" type="number" min="1" />
                        </span>
                        <br />
                        <span className="input-add">
                            <p className="p-input">Precio</p>
                            <input className="input-" id="input-price" type="number" min="0" />
                        </span>
                        <br />
                        <span className="input-add">
                            <p className="p-input">Tags</p>
                            <textarea className="input-" id="sinopsis" />
                        </span>
                        <br />
                        <span className="input-add">
                            <p className="p-input">Imagen</p>
                            <input className="input-" id="input-cover" type="file" accept="image/*" />
                        </span>
                        <br />
                        <br />
                            <progress className="input-" value={this.state.progress} />
                            <br />
                            <button className="input-add" id="upload" onClick={this.uploadImage}>Cargar imagen</button>    
                            <br />
                            <button className="add-button" onClick={this.onSubmit} disabled={this.state.buttonUpload} id="post-button">Crear</button>
                    </span>
                </div>
            );
        }

    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postFigura
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(AddFigura);