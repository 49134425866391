import React from "react";
import Banners from "../../components/banners/banners";
import Explore from "./explore/explore";
import "./home.css";
import News from "./news/news";
import Products from "./products/products";
import Figuras from "./figuras/figuras";

class Home extends React.Component{
    
    componentDidMount(){
        setTimeout(()=>{
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                buttons[i].disabled = false;
            }
        }, 500)
    }

    render(){
        return(
            <div className="home-main-container">
                <Banners />
                <News />
                <Explore />
                <Products />
            </div>
        );
    }
}

export default Home;