import React from 'react'

import './reporter.css';

import { Link } from 'react-router-dom';

class Reporter extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='reporter-main-container'>
                <span className='reporter-span'>
                    <p className='reporter-p'>
                        Esta página se encuentra en fase BETA, los errores son de esperar.
                    </p>
                </span>
                <span className='reporter'>
                    <Link to="/reportbugs">
                        <button className='reporter-button'>
                            Reportar errores
                        </button>
                    </Link>
                </span>
            </div>
        )
    }
}

export default Reporter;