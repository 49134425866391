import React from "react";
import "./card.css";
import { Link } from 'react-router-dom';

import { BsBookmarkStarFill } from 'react-icons/bs';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { ImCross } from 'react-icons/im';
import { auth } from "../../firebase";

import { connect } from "react-redux";
import { addToCart, addtofavs } from "../../redux/actions";
import Swal from "sweetalert2";
import Loader from "../../pages/loader";

class Card extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            vol: {},
            url: "",
            type: "",
            loading: true
        }
    }

    componentDidMount(){

        let vol = this.props.vol;
        
        this.setState({
            vol: vol
        }, ()=>{
            let link;

            if(this.props.serie){
                link = "/serie?has=true&name=" + this.state.vol.serie
            }else{
                if(this.state.vol.autor){
                    link = "/libro/" + this.state.vol.id
                }else{
                    if(this.state.vol.autores || this.state.vol.coleccion){
                        link = "/comic/" + this.state.vol.id;
                    }else{
                        link = "/manga/" + this.state.vol.id;
                    }
                }
            }

            if(this.props.vol.figura){
                link = "/figuras/" + this.state.vol.id
            }

            let type;

            if(this.state.vol.coleccion){
                type = "coleccion"
            }else{
                type = "serie"
            }

            this.setState({
                url: link,
                type: type
            })
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.state.status !== this.props.state.status){
            document.getElementById("shop").disabled = false;
            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }

        if(prevProps.state.post_state !== this.props.state.post_state){
            if(this.props.state.post_state === "success"){
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "success",
                    title: "Añadido al favoritos!"
                })
                this.props.state.post_state = ""
            }else{
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "error",
                    title: "Este tomo ya está en favoritos!"
                })
                this.props.state.post_state = ""
            }
        }

        if(prevState.type !== this.state.type){
            this.setState({
                loading: false
            })
        }
    }

    isTop = () => {
        if(this.props.isTop){
            return "top"
        }else{
            return "noTop"
        }
    }

    favsClick = (e)=>{
        e.preventDefault();

        if(auth.currentUser){
            e.target.disabled = true;
            this.props.addtofavs({
                user_id: auth.currentUser.uid,
                id: this.state.vol.id
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "Necesitas estar registrado para usar los favoritos!"
            })
        }
    }

    addToCart = (e) => {
        e.preventDefault();

        if(auth.currentUser){
            const data = {
                id: auth.currentUser.uid,
                itemId: this.state.vol.id
            }

            this.props.addToCart(data);
            e.target.disabled = true;
        }else{
            const prevStorage = JSON.parse(localStorage.getItem('cart'));

            if(prevStorage){
                if(prevStorage.items){
                    const cart = {
                        items: [...prevStorage.items, this.state.vol.id]
                    }
                    
                    localStorage.setItem('cart', JSON.stringify(cart));
                }else{
                    const cart = {
                        items: [this.state.vol.id]
                    }
                    
                    localStorage.setItem('cart', JSON.stringify(cart));
                }
            }else{
                const cart = {
                    items: [this.state.vol.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }

            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }
    }

    getTitle = () => {
        if(this.state.vol.figura){
            return `${this.state.vol.title}`
        }
        if(this.props.serie){
            if(this.state.type === "serie"){
                return `${this.state.vol.serie}`
            }else{
                return `${this.state.vol.nombre}`
            }
        }else{
            if(this.state.type === "serie"){
                if(this.state.vol.subtitulo){
                    return `${this.state.vol.serie} ${this.state.vol.numero}: ${this.state.vol.subtitulo}`
                }else{
                    if(parseInt(this.state.vol.vol_amount) === 1 && parseInt(this.state.vol.numero) === 1){
                        if(this.state.vol.special){
                            return `${this.state.vol.serie} - ${this.state.vol.comment}`
                        }else{
                            return `${this.state.vol.serie}`
                        }
                    }else{
                        if(this.state.vol.special){
                            return `${this.state.vol.serie} #${this.state.vol.numero} - ${this.state.vol.comment}`
                        }else{
                            return `${this.state.vol.serie} #${this.state.vol.numero}`
                        }
                    }
                }
            }else{
                return `${this.state.vol.nombre}`
            }
        }
    }

    getPrecio = () => {
        if(this.props.serie){
            return null;
        }else{
            if(this.state.vol.descuento){
                const precio = parseInt(this.state.vol.precio)
                const descuento = parseInt(this.state.vol.descuento)
                const oldPrecio = Math.floor(100 * precio / (100 - descuento))
                return(
                    <div className="card-bs-price" id="descuento">
                        <p className="bs-price">
                            {`$${precio}`}
                        </p>
                        <p className="bs-price-old">
                            {`$${oldPrecio}`}
                        </p>
                        <span className="card-bs-promo">
                            OFERTA
                        </span>
                    </div>
                )
            }else{
                return(
                    <div className="card-bs-price">
                        <p className="bs-price">
                            {`$${this.state.vol.precio}`}
                        </p>
                    </div>
                )
            }
        }
    }

    getStyle = () => {
        if(this.props.serie){
            return {"display":"none"}
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            if(parseInt(this.state.vol.stock) > 0){
                return(
                    <Link to={this.state.url} className="link-bs">
                        <div className="card-bs-container" id={this.isTop()}>
                            <div className="card-bs-img">
                                <img className="bs-img" src={this.state.vol.imgURL} alt={`${this.state.vol.serie}${this.state.vol.numero}`} />
                            </div>
                            <div className="card-bs-title">
                                {this.getTitle()}
                            </div>
                            {this.getPrecio()}
                            <div className="card-bs-icon-container" style={this.getStyle()}>
                                <button className="button-card-bs" id="favs" onClick={this.favsClick}>
                                    <BsBookmarkStarFill />
                                </button>
                                <button className="button-card-bs" id="shop" onClick={this.addToCart}>
                                    <RiShoppingCart2Fill />
                                </button>
                            </div>
                        </div>
                    </Link>
                )
            }else{
                return(
                    <Link to={this.state.url} className="link-bs">
                        <div className="card-bs-container" id={this.isTop()}>
                            <div className="card-bs-img">
                                <img className="bs-img" src={this.state.vol.imgURL} alt={`${this.state.vol.coleccion}${this.state.vol.nombre}`} />
                            </div>
                            <div className="card-bs-title">
                                {this.getTitle()}
                            </div>
                            {this.getPrecio()}
                            <div className="card-bs-stock" style={this.getStyle()}>
                                <ImCross className="icon-bs" />
                                <p className="no-stock-bs">
                                    SIN STOCK
                                </p>
                            </div>
                        </div>
                    </Link>
                )
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    addToCart,
    addtofavs
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Card);