import React from "react";

import "./explore.css"

class Explore extends React.Component{
    render(){
        return(
            <div className="explore-main-container">
                <span className="explore-container">
                    <span className="explore-title-container">
                        <h1 className="explore-title">
                            ¿NO SABÉS QUÉ COMPRAR?
                        </h1>
                    </span>
                    <span className="explore-content-container">
                        <span className="content-container">
                            <p className="content-info">
                                ¡Probá nuestra Búsqueda Avanzada y descubrí nuevas series!
                            </p>
                            <span className="explore-buttons-container">
                                <button className="explore-button" onClick={()=>{
                                    window.location.hash = "/search/mangas?search=true"
                                }}>
                                    BUSCAR MANGAS
                                </button>
                                <button className="explore-button" onClick={()=>{
                                    window.location.hash = "/search/comics?search=true"
                                }}>
                                    BUSCAR CÓMICS
                                </button>
                                <button className="explore-button" onClick={()=>{
                                    window.location.hash = "/search/libros?search=true"
                                }}>
                                    BUSCAR LIBROS
                                </button>
                            </span>
                        </span>
                        <span className="content-container">
                            <p className="content-info">
                                o explora nuestro catálogo
                            </p>
                            <button className="explore-button" onClick={()=>{
                                window.location.hash = "/catalogo"
                            }}>
                                VER CATÁLOGO
                            </button>
                        </span>
                    </span>
                </span>
            </div>
        )
    }
}

export default Explore;