import React from "react";

import { connect } from "react-redux";
import Loader from "../../pages/loader";
import { updateStock } from "../../redux/actions";

import Swal from 'sweetalert2'

import './updateStock.css';
import { Link } from "react-router-dom";

class UpdateStock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            series: [],
            colecciones: [],
            special: []
        }
    }

    componentDidMount(){
        this.setState({
            series: this.props.series,
            colecciones: this.props.comics,
            special: this.props.special
        }, ()=>{
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.status !== this.props.state.status){
            if(this.props.state.status === "success"){
                Swal.fire({
                    icon: "success",
                    text: "Stock actualizado con éxito!"
                })
                .then(()=>{
                    window.location.reload()
                })
            }else if(this.props.state.status === "error"){
                Swal.fire({
                    icon: "error",
                    text: "No se encontró el tomo deseado."
                })
                .then(()=>{
                    this.props.state.status = "";
                })
            }
        }
    }

    handleUpdate = () => {
        const serie = document.getElementById("update-serie").value;
        const nombre = document.getElementById("update-col").value;
        const numero = document.getElementById("update-number").value;
        const stock = document.getElementById("update-stock").value;
        const special = document.getElementById("update-special").value;

        if(serie === "No seleccionar"){
            const data = {
                type: "coleccion",
                nombre: nombre,
                stock: stock
            }

            this.props.updateStock(data)
        }else{
            if(special === "No seleccionar"){
                const data = {
                    type: "serie",
                    serie: serie,
                    stock: stock,
                    numero: numero
                }
    
                this.props.updateStock(data)
            }else{
                const data = {
                    type: "serie",
                    serie: serie.trim(),
                    stock: stock,
                    numero: numero.trim(),
                    special: special.trim()
                }
    
                this.props.updateStock(data)
            }
        }

    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="update-main-container">
                    <p className="update-p">
                        Actualizar stock
                    </p>
                    <label className="update-l" htmlFor="update-serie">
                        Series
                    </label>
                    <select className="update-select" id="update-serie" onChange="this.nextElementSibling.value=this.value">
                        <option className="update-option">
                            No seleccionar
                        </option>
                        {
                            this.state.series.map((e)=>{
                                return <option className="update-option" key={Math.random()} id={e}>
                                    {e}
                                </option>
                            })
                        }
                    </select>

                    <label className="update-l" htmlFor="update-number">
                        Número
                    </label>
                    <input type="number" id="update-number" className="input-update" />

                    <label className="update-l" htmlFor="update-serie">
                        Ed. especial
                    </label>
                    <select className="update-select" id="update-special">
                        <option className="update-option">
                            No seleccionar
                        </option>
                        {
                            this.state.special.map((e)=>{
                                return <option className="update-option" key={Math.random()} id={e.id}>
                                    {e.serie} {e.numero} - {e.comment}
                                </option>
                            })
                        }
                    </select>

                    <label className="update-l" htmlFor="update-col">
                        Colecciones
                    </label>
                    <select className="update-select" id="update-col">
                        <option className="update-option">
                            No seleccionar
                        </option>
                        {
                            this.state.colecciones.map((e)=>{
                                return <option className="update-option" key={e} id={e}>
                                    {e}
                                </option>
                            })
                        }
                    </select>

                    <label className="update-l" htmlFor="update-stock" id="new">
                        Nuevo stock
                    </label>
                    <input type="number" id="update-stock" className="input-update" min={1} />

                    <button className="update-button" onClick={this.handleUpdate}>
                        Actualizar
                    </button>
                    <p className="update-p" id="view-stock">
                        Ver stock
                    </p>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    updateStock
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(UpdateStock);