import React from "react";
import { connect } from 'react-redux';
import { getEditoriales, getComics } from "../../redux/actions";
import BestSellers from "./bestSellers/bestSellers";

import "./comics.css";
import "../loader.css";
import Loader from "../loader";
import Card from "../../components/card/card";
import AdvancedSearchComics from "../../components/advancedSearchComics/advancedSearchComics";

class Comics extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            editoriales: [],
            new_n: 0,
            loading: true,
            comics: [],
            selected: "",
            ed_view: {
                recos: "flex",
                eds: "none"
            },
            cant_reco: 3,
            getEditoriales: false,
            get: false
        }
    }

    componentDidMount(){

        if(window.screen.width <= 450){
            this.setState({
                cant_reco: 2
            })
        }

        if(!this.state.getEditoriales){
            this.setState({
                getEditoriales: true
            }, ()=>{
                this.props.getEditoriales();
            })
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        setTimeout(()=>{
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                if(buttons[i].id === "comics-nav-button"){
                    buttons[i].disabled = true;
                }else{
                    buttons[i].disabled = false;
                }
            }
        }, 250);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.state.editoriales !== this.props.state.editoriales){
            let editoriales = this.props.state.editoriales;
            let filtered = editoriales.filter((e)=>{
                if(e.comics > 0){
                    return e
                }else{
                    return false;
                }
            })
            
            let sorted = filtered.sort((a, b)=>{
                return b.comics - a.comics;
            })

            this.setState({
                ...this.state,
                editoriales: sorted
            }, ()=>{
                if(this.state.editoriales.length > 0){
                    this.setState({
                        selected: this.props.state.editoriales[0].nombre
                    }, ()=>{
                        if(!this.state.get){
                            this.setState({
                                get: true
                            }, ()=>{
                                this.props.getComics();
                            })
                        }
                    })
                }
            })
        }

        if(prevProps.state.comics !== this.props.state.comics){
            console.log('COMICS: ')
            console.log(this.props.state.comics)
            this.setState({
                ...this.state,
                comics: this.props.state.comics
            }, ()=>{
                this.setState({
                    loading: false
                }, ()=>{
                    let url = window.location.href.split("?")[1]
                    if(url){
                        window.scrollTo({
                            top: 2565,
                            behavior: "smooth"
                        })
                    }
                })
            })
        }

        if(prevState.ed_view !== this.state.ed_view){
            const viewport = document.getElementById("editoriales-viewport");
            const recos = document.getElementById("recomendados");
            if(this.state.ed_view.recos === "animate"){
                setTimeout(() => {
                    this.setState({
                        ed_view: {
                            recos: "none",
                            eds: "flex"
                        }
                    })
                }, 250);
                viewport.animate([
                    {transform: 'translateX(-300px)'},
                    {transform: 'translateX(150px)'}
                ],{
                    duration: 250,
                    iterations: 1
                })
                recos.animate([
                    {transform: 'translateX(-150px)'},
                    {transform: 'translateX(300px)'}
                ],{
                    duration: 250,
                    iterations: 1
                })
            }else if(this.state.ed_view.eds === "animate"){
                viewport.animate([
                    {transform: 'translateX(150px)'},
                    {transform: 'translateX(-300px)'}
                ],{
                    duration: 250,
                    iterations: 1
                })
                recos.animate([
                    {transform: 'translateX(300px)'},
                    {transform: 'translateX(-150px)'}
                ],{
                    duration: 250,
                    iterations: 1
                })
                setTimeout(() => {
                    this.setState({
                        ed_view: {
                            recos: "flex",
                            eds: "none"
                        }
                    })
                }, 250);
            }
        }
    }

    getUnitarios = () => {
        let comics = this.state.comics;

        comics = comics.sort((a, b)=>{
            return b.ventas - a.ventas;
        })

        let comics_filter = [];

        for(let i = 0; i < comics.length; i++){
            if(comics[i].coleccion){
                if(comics_filter.length < this.state.cant_reco){
                    comics_filter.push(comics[i])
                }
            }else{
                if(parseInt(comics[i].numero) === 1 && parseInt(comics[i].vol_amount) === 1){
                    if(comics_filter.length < this.state.cant_reco){
                        comics_filter.push(comics[i])
                    }
                }
            }
        }

        return(
            <span className="grid-mangas-editorial">
                <span className="grid-mangas-title-container">
                    <h1 className="grid-mangas-title">TOMOS ÚNICOS</h1>
                </span>
                {
                comics_filter.map((e)=>{
                    return (<Card vol={e} key={e.id} />)
                })
                }
            </span>
        )

    }

    getNuevas = () => {
        let comics = this.state.comics;

        comics = comics.sort((a, b)=>{
            return b.ventas - a.ventas;
        })

        let comics_filter = [];

        for(let i = 0; i < comics.length; i++){
            if(comics[i].serie){
                if(parseInt(comics[i].numero) === 1
                && parseInt(comics[i].tomos_arg) < 3
                && parseInt(comics[i].vol_amount) > 1){
                    if(comics_filter.length < this.state.cant_reco){
                        comics_filter.push(comics[i])
                    }
                }
            }
        }

        return(
            <span className="grid-mangas-editorial">
                <span className="grid-mangas-title-container">
                    <h1 className="grid-mangas-title">SERIES NUEVAS</h1>
                </span>
                {
                    comics_filter.map((e)=>{
                        return (<Card vol={e} key={e.id} />)
                    })
                }
            </span>
        )

    }

    handleEditorial = (e) => {
        window.location.href = `#/search/editorial?name=${e.target.id}`
    }

    editorialesStyle = () => {
        if(window.screen.width <= 450){
            return {"display": `${this.state.ed_view.eds}`}
        }else{
            return {"display": "flex"}
        }
    }

    handleRecomendados = () => {
        if(window.screen.width <= 450){
            if(window.screen.width <= 450){
                return {"display": `${this.state.ed_view.recos}`}
            }else{
                return {"display": "flex"}
            }
        }else{
            return {"display": "flex"}
        }
    }

    handelEds = (e) => {
        e.preventDefault();

        if(this.state.ed_view.eds === "none"){
            this.setState({
                ed_view: {
                    recos: "animate",
                    eds: "flex"
                }
            })
        }else{
            this.setState({
                ed_view: {
                    recos: "flex",
                    eds: "animate"
                }
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="mangas-main-container">
                    <BestSellers />
                    <div className="editoriales-main-container">
                        <button onClick={this.handelEds} className="eds_button">EDITORIALES</button>
                        <span className="editoriales-container" style={this.editorialesStyle()} id="editoriales-viewport">
                            <h1 className="ed-editoriales-title">EDITORIALES</h1>
                            {
                                this.state.editoriales.map((ed)=>{
                                    return(
                                        <span className="editorial-container" key={ed.id}>
                                            <img className="editorial-img" alt={ed.nombre} src={ed.imgURL} onClick={this.handleEditorial} id={`${ed.id}`}/>
                                            <button className="editorial-button" onClick={this.handleEditorial} id={`${ed.id}`} key={`${ed.nombre}`} >
                                                <p className="p-editorial-button" id={`${ed.id}`}>
                                                    {ed.nombre}
                                                </p>
                                            </button>
                                        </span>
                                    )
                                })
                            }
                        </span>
                        <span className="ed-mangas-container" id="recomendados" style={this.handleRecomendados()}>
                            <h1 className="ed-mangas-title">RECOMENDADOS</h1>
                            <span className="grid-mangas-title-container" id="phone">
                                <h1 className="grid-mangas-title">TOMOS ÚNICOS</h1>
                            </span>
                            {
                                this.getUnitarios()
                            }
                            <span className="grid-mangas-title-container" id="phone">
                                <h1 className="grid-mangas-title">SERIES NUEVAS</h1>
                            </span>
                            {
                                this.getNuevas()
                            }
                        </span>
                    </div>
                    <div className="busqueda-avanzada">
                        <AdvancedSearchComics comics={this.state.comics}/>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getEditoriales,
    getComics
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Comics);