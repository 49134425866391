import React from "react";
import Loader from "../../pages/loader";

import Card from "../card/card";

import { connect } from "react-redux";
import { getMangas, getComics, getLibros } from "../../redux/actions";

import "../same.css"

class SameSerie extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            vols: [],
            n: 0
        }
    }

    componentDidMount(){
        if(window.screen.width < 550){
            this.setState({
                n: 2
            })
        }else if(window.screen.width < 850){
            this.setState({
                n: 4
            })
        }else{
            this.setState({
                n: 5
            })
        }

        if(this.props.type === "manga"){
            this.props.getMangas()
        }else if(this.props.type === "comic"){
            this.props.getComics()
        }else{
            this.props.getLibros()
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.state.mangas !== prevProps.state.mangas){
            let mangas = this.props.state.mangas;
            let filtered = [];

            for(let i = 0; i < mangas.length; i++){
                if(mangas[i].serie === this.props.serie && filtered.length < this.state.n){
                    filtered.push(mangas[i])
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        if(this.props.state.comics !== prevProps.state.comics){
            let comics = this.props.state.comics;
            let filtered = [];

            for(let i = 0; i < comics.length; i++){
                if(comics[i].serie){
                    if(comics[i].serie === this.props.serie && filtered.length <= this.state.n){
                        filtered.push(comics[i])
                    }
                }else{
                    if(comics[i].coleccion === this.props.coleccion && filtered.length <= this.state.n){
                        filtered.push(comics[i])
                    }
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        if(prevProps.state.libros !== this.props.state.libros){

            let libros = this.props.state.libros;

            let filtered = [];
            for(let i = 0; i < libros.length; i++){
                if(libros[i].serie === this.props.serie){
                    filtered.push(libros[i])
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        
    }

    handleRedirect = () => {
        if(this.props.type === "manga"){
            let url = `/serie?has=true&name=${this.props.serie}`
            window.location.hash = url
        }else if(this.props.type === "comic"){
            if(this.props.serie){
                let url = `/serie?has=true&name=${this.props.serie}`
                window.location.hash = url
            }else{
                let url = `/serie?has=true&name=${this.props.coleccion}`
                window.location.hash = url
            }
        }else{
            let url = `/serie?has=true&name=${this.props.serie}`
            window.location.hash = url
        }
    }

    render(){
        if(this.state.loading){
            return(
                <Loader />
            )
        }else{
            return(
                <div className="same-main-container">
                    <span className="same-grid">
                        {this.state.vols.map((e)=>{
                            return <Card vol={e} key={e.id} />
                        })}
                    </span>
                    <button className="editorial-ver-mas" id="nuevas" onClick={this.handleRedirect}>VER MÁS</button> 
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getMangas,
    getComics,
    getLibros
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(SameSerie);