import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Navbar from './components/NavBar/navbar';
import { HashRouter, BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './redux/index';
import Footer from './components/footer/footer';
import Building from './components/pageBuilding/pageBuilding';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Navbar />
        <App />
        <Footer />
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);