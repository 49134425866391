import React from "react";

import '../products/products.css'

import { connect } from 'react-redux';

import { getFiguras } from "../../../redux/actions";
import Loader from "../../loader";
import Card from "../../../components/card/card";

class Figuras extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            figuras: []
        }
    }

    componentDidMount(){
        this.props.getFiguras()
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.figuras !== this.props.state.figuras){
            this.setState({
                figuras: this.props.state.figuras
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="products-main-container">
                    <h1 className="products-title">TODAS LAS FIGURAS</h1> 
                    <div className="product-grid-container">
                        <span className="products-grid">
                            {
                                this.state.figuras.map((f)=>{
                                    return <Card vol={f} key={f.id} />
                                })
                            }
                        </span>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getFiguras
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Figuras);