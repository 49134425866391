import React from "react";
import Card from "../../../components/card/card";

import './autores.css';

class Autores extends React.Component{

    componentDidMount(){
        console.log(this.props.recos)
    }

    render(){
        return(
            <div className='reco-autores'>
                {this.props.recos.map((e)=>{
                    return (
                        <span className='reco-container' key={e.autor}>
                            <h1 className='autor-title'>{e.autor}</h1>
                            <span className='reco-grid'>
                                {
                                    e.series.map((m)=>{
                                        return <Card serie={true} vol={m} key={m.id} />
                                    })
                                }
                            </span>
                        </span>
                    )
                })}
            </div>
        )
    }
}

export default Autores;