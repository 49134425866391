import React from "react";
import Loader from "../loader";
import { auth } from '../../firebase';

import queryString from 'query-string'

import { connect } from 'react-redux';
import { getUser, getFavs, getCompras } from "../../redux/actions";

import Card from '../../components/card/card';

import './userPage.css';
import Building from "../../components/pageBuilding/pageBuilding";

class User extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            user: {},
            id: "",
            favs: [],
            compras: [],
            get: false
        }
    }

    componentDidMount(){
        /*
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        let id = params.id; // "some_value"
        */

        const obj = queryString.parse(window.location.hash);
        let id;

        for(let key in obj){
            id = obj[key];
        }

        this.setState({
            id: id
        }, ()=>{
            if(!this.state.get){
                this.setState({
                    get: true
                }, ()=>{
                    this.props.getUser(id);
                })
            }
        })
    }

    componentDidUpdate(prevProps){
        auth.onAuthStateChanged(()=>{
            if(auth.currentUser){
                if(auth.currentUser.uid !== this.state.id){
                    window.location.href = '/';
                }
            }else{
                window.location.href = '/';
            }
        })

        if(prevProps.state.user !== this.props.state.user){
            this.setState({
                user: this.props.state.user
            }, ()=>{
                this.props.getFavs(this.state.user.id);
            })
        }

        if(prevProps.state.favs !== this.props.state.favs){
            this.setState({
                favs: this.props.state.favs
            }, ()=>{
                this.props.getCompras(this.state.id);
            })
        }

        if(prevProps.state.compras !== this.props.state.compras){
            this.setState({
                compras: this.props.state.compras
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    getFavs = () => {
        const favs = this.state.user.favs;

        if(favs.length < 0){
            return(
                <div className="favs-container">
                    <h2 className="favs-title">
                        Aún no tienes favoritos guardados!
                    </h2>
                </div>
            )
        }else{
            return(
                <div className="favs-container">
                    <h2 className="favs-title">
                        {`Tus favoritos (${favs.length}):`}
                    </h2>
                    <span className="grid-favs-container">
                        {this.state.favs.map((fav)=>{
                            return <Card vol={fav} />
                        })}
                    </span>
                </div>
            )
        }
    }

    getTitle = (item) => {
        if (item.figura) {
            return `${item.title} x${item.cant} - $${item.precio}`
        }

        if (item.serie) {
            if (item.libro) {
                return `${item.serie} ${item.numero}: ${item.subtitulo} x${item.cant} - $${item.precio}`
            } else {
                if (item.special) {
                    return `${item.serie} ${item.numero} - ${item.comment} x${item.cant} - $${item.precio}`
                } else {
                    return `${item.serie} ${item.numero} x${item.cant} - $${item.precio}`
                }
            }
        }

        if (item.coleccion) {
            return `${item.nombre} - ${item.coleccion} x${item.cant} - $${item.precio}`
        }

        return `${item.title} x${item.cant} - $${item.precio}`
    }

    getDispatch = (compra) => {
        if(compra.shipping.seguimiento){
            return(
                <p className="purchase-sp-data" id="seguimiento">
                    Despachado
                </p>
            )
        }
    }

    getCompras = () => {
        const compras = this.state.compras;
        
        if(compras.length < 0){
            return(
                <div className="favs-container">
                    <h2 className="favs-title">
                        Aún no tienes compras por la web!
                    </h2>
                </div>
            )
        }else{

            const retirar = [];
            const viaje = [];
            const preparacion = [];

            for(let i = 0; i < compras.length; i++){
                console.log(compras[i])
                if(!compras[i].purchase.completed){
                    if(compras[i].retira){
                        retirar.push(compras[i])
                    }else{
                        if(compras[i].shipping.seguimiento === ""){
                            preparacion.push(compras[i])
                        }else{
                            viaje.push(compras[i]);
                        }
                    }
                }
            }

            return(
                <div className="favs-container">
                    <h2 className="favs-title">
                        {`Compras por retirar (${retirar.length}):`}
                    </h2>
                    <span className="grid-favs-container" id="compras-grid">
                        {retirar.map((compra)=>{
                            return(
                                <div className="purchase-card" key={Math.random()}>
                                    <p className="purchase-date">
                                        {compra.date}
                                    </p>
                                    {compra.purchase.items.map((item)=>{
                                        return(
                                            <span className="purchase-item" key={item.id}>
                                                {this.getTitle(item)}
                                                <br />
                                            </span>
                                        )
                                    })}
                                    <p className="purchase-total">
                                        Total ${compra.purchase.total}.-
                                    </p>
                                    <br />
                                    <br />
                                    <br />
                                    <p className="purchase-sp-data" id="compra-id">
                                        Compra: {compra.id}
                                    </p>
                                </div>
                            )
                        })}
                    </span>
                    <h2 className="favs-title">
                        {`Compras despachadas (${viaje.length}):`}
                    </h2>
                    <span className="grid-favs-container" id="compras-grid">
                        {viaje.map((compra)=>{
                            return(
                                <div className="purchase-card" key={Math.random()}>
                                    <p className="purchase-date">
                                        {compra.date}
                                    </p>
                                    {compra.purchase.items.map((item)=>{
                                        return(
                                            <span className="purchase-item" key={item.id}>
                                                {this.getTitle(item)}
                                                <br />
                                            </span>
                                        )
                                    })}
                                    <p className="purchase-total">
                                        ${compra.purchase.total}
                                    </p>
                                    <span className="purchase-shipping">
                                        <p className="purchase-sp-title">Envío a domicilio</p>
                                        <p className="purchase-sp-data">
                                            Destino: {compra.shipping.destino.calle} {compra.shipping.destino.numero}, {compra.shipping.destino.localidad}
                                        </p>
                                        <p className="purchase-sp-data">
                                            Recibe: {compra.shipping.cliente.nombre}
                                        </p>
                                        {this.getDispatch(compra)}
                                        <p className="purchase-sp-data">
                                            Seguimiento: {compra.shipping.seguimiento}
                                        </p>
                                        <br />
                                        <br />
                                        <br />
                                    </span>
                                    <p className="purchase-sp-data" id="compra-id">
                                        Compra: {compra.id}
                                    </p>
                                </div>
                            )
                        })}
                    </span>
                    <h2 className="favs-title">
                        {`Compras pendientes (${preparacion.length}):`}
                    </h2>
                    <span className="grid-favs-container" id="compras-grid">
                        {preparacion.map((compra)=>{
                            return(
                                <div className="purchase-card" key={Math.random()}>
                                    <p className="purchase-date">
                                        {compra.date}
                                    </p>
                                    {compra.purchase.items.map((item)=>{
                                        return(
                                            <span className="purchase-item" key={item.id}>
                                                {this.getTitle(item)}
                                                <br />
                                            </span>
                                        )
                                    })}
                                    <p className="purchase-total">
                                        ${compra.purchase.total}
                                    </p>
                                    <span className="purchase-shipping">
                                        <p className="purchase-sp-title">Envío pendiente</p>
                                        <p className="purchase-sp-data">
                                            Destino: {compra.shipping.destino.calle} {compra.shipping.destino.numero}, {compra.shipping.destino.localidad}
                                        </p>
                                        <p className="purchase-sp-data">
                                            Recibe: {compra.shipping.cliente.nombre}
                                        </p>
                                        <br />
                                        <br />
                                        <br />
                                    </span>
                                    <p className="purchase-sp-data" id="compra-id">
                                        Compra: {compra.id}
                                    </p>
                                </div>
                            )
                        })}
                    </span>
                </div>
            )
        }
    }

    render(){
        /*
        if(true){
            return (
                <div className="user-main-container">
                    <Building/>
                    <p className="building-text">Esta página volverá a estar disponible pronto! Disculpe las molestias.</p>
                </div>
            )
        }else
        */
        if(this.state.loading){
            return(
                <div>
                    <Loader />
                </div>
            )
        }else{
            return(
                <div className="user-main-container">
                    <h1 className="user-title">
                        Bienvenido/a, {this.state.user.user}!
                    </h1>
                    <span className="user-info-container">
                        {this.getFavs()}
                        {this.getCompras()}
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getUser,
    getFavs,
    getCompras
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(User);