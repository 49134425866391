import React from "react";

import { connect } from "react-redux";

import { reportBug } from "../../redux/actions";

import './reportBug.css';

import Swal from 'sweetalert2';


class ReportBug extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.report_state !== this.props.state.report_state){
            if(this.props.state.report_state === "success"){
                Swal.fire({
                    icon: "success",
                    title: "Reporte envíado!",
                    text: "Gracias por envíar su feedback hacia la página. El mensaje será leído y analizado pronto"
                })
                .then(()=>{
                    window.location.href = "/";
                })
            }else{
                Swal.fire({
                    icon: "error",
                    title: "No se pudo envíar el reporte. Por favor, vuelva a intentarlo"
                })
                .then(()=>{
                    window.location.reload()
                })
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const message = document.getElementById("rep-message").value;

        const data = {
            message: message
        }

        this.props.reportBug(data);

        Swal.fire({
            title: "Enviando reporte...",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false
        })
    }

    render(){
        return(
            <div className="rep-form-container">
                <h1 className="rep-title">
                    REPORTAR ERRORES
                </h1>
                <p className="email-report">En caso de ser un problema que necesite respuesta, por favor dejá tu mail en el mensaje.</p>
                <form className="rep-main-container" onSubmit={this.handleSubmit}>
                    <textarea className="rep-input" id="rep-message" />
                    <input type="submit" className="rep-button" />
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    reportBug
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(ReportBug);