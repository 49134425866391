import React from 'react';
import { auth } from '../../firebase';
import tokens from '../../variables';
import { Link } from 'react-router-dom';

import { getEditoriales, getSeriesAll, getComics, getSpecial, getFiguras } from '../../redux/actions'
import { connect } from 'react-redux'

import Loader from '../loader';
import './dashboard.css';
import PriceChange from '../../components/priceChange/priceChange';
import Swal from 'sweetalert2';
import UpdateStock from '../../components/updateStock/updateStock';
import UpdateSerie from '../../components/updateSerie/updateSerie';
import Discounts from '../../components/discounts/discounts';

import FigurasPriceChange from './figuras/figurasPrecio'
import FigurasStock from './figuras/figurasStock';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            isLogged: false,
            series: {},
            comics: [],
            special: [],
            figuras: []
        }
    }

    redirect = () => {
        window.location.href = '/';
    }

    componentDidMount() {
        this.props.getEditoriales()
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.state.editoriales !== this.props.state.editoriales) {
            this.setState({
                editorial: ""
            }, () => {
                this.props.getSpecial()
            })
        }

        if (prevProps.state.special !== this.props.state.special) {
            this.setState({
                special: this.props.state.special
            }, () => {
                this.props.getSeriesAll()
            })
        }

        if (prevProps.state.allSeries !== this.props.state.allSeries) {
            this.setState({
                series: this.props.state.allSeries
            }, () => {
                this.props.getComics()
            })
        }

        if (prevProps.state.comics !== this.props.state.comics) {
            const filtered = []
            this.props.state.comics.map((c) => {
                if (!c.state) {
                    filtered.push(c)
                }
            })

            this.setState({
                comics: filtered
            }, () => {
                this.props.getFiguras()
            })
        }

        if (prevProps.state.figuras !== this.props.state.figuras) {
            this.setState({
                figuras: this.props.state.figuras
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        auth.onAuthStateChanged(() => {
            if (auth.currentUser && !this.state.isLogged) {
                if (auth.currentUser.uid === tokens.admin) {
                    this.setState({
                        isLogged: true
                    })
                } else {
                    window.location.href = '/';
                }
            } else if (!auth.currentUser && this.state.isLogged) {
                this.setState({
                    isLogged: false
                })
            } else if (!auth.currentUser && !this.state.isLogged) {
                window.location.href = '/';
            }
        })

        if (prevState.isLogged !== this.state.isLogged) {
            if (!this.state.isLogged) {
                window.location.href = '/';
            }
        }
    }

    handleEditorial = (e) => {
        let botones = document.getElementsByClassName("price-button");
        for (let i = 0; i < botones.length; i++) {
            botones[i].style.backgroundColor = "white"
            botones[i].style.color = "black"
        }

        e.target.style.backgroundColor = "black";
        e.target.style.color = "white"

        return this.setState({
            editorial: e.target.id
        })

    }

    changesInfo = () => {
        Swal.fire({
            title: "Cómo modificar los precios",
            text: "Hay 3(tres) formas de actualizar precios:"
        })
            .then(() => {
                Swal.fire({
                    title: "1) GLOBAL",
                    text: "Para modificar los precios de forma global, se debe seleccionar una editorial, colocar el precio actual y el nuevo. Se actualizarán TODOS los tomos que estén a ese monto de la editorial seleccionada."
                })
                    .then(() => {
                        Swal.fire({
                            title: "2) POR SERIE/COLECCIÓN",
                            text: "Para modificar los precios por serie/colección, se debe envíar el nombre de dicha serie/colección, colocar el precio actual y el nuevo. Se actualizarán TODOS los tomos que estén a ese monto de la serie/colección seleccionada."
                        })
                            .then(() => {
                                Swal.fire({
                                    title: "3) POR TOMO ESPECÍFICO",
                                    text: "Para modificar los precios por tomo específico, se necesita el nombre de la serie y el número de tomo o, en caso de ser un tomo sin número, el nombre específico de dicho tomo, colocar el precio actual y el nuevo. Se actualizará únicamente ese tomo."
                                })
                            })
                    })
            })
    }

    render() {
        if (this.state.loading || !this.state.isLogged) {
            return <Loader />
        } else {
            return (
                <div className='dashboard-main-container'>
                    <h1 className='dashboard-title'>
                        PANEL DE ADMINISTRADOR
                    </h1>
                    <span className='dashboard-tools-container'>
                        <span className='panel-container'>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    AÑADIR EDITORIAL
                                </h1>
                                <Link to="/posteditorial" className='panel-button-container' reloadDocument={true}>
                                    <button className='panel-button'>
                                        Editorial
                                    </button>
                                </Link>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    AÑADIR MANGAS
                                </h1>
                                <span className='panel-buttons-container'>
                                    <Link to="/postserie" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Serie
                                        </button>
                                    </Link>
                                    <Link to="/postmanga" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Tomo
                                        </button>
                                    </Link>
                                </span>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    AÑADIR CÓMICS
                                </h1>
                                <span className='panel-buttons-container'>
                                    <Link to="/postseriecomic" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Serie
                                        </button>
                                    </Link>
                                    <Link to="/postcoleccion" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Colección
                                        </button>
                                    </Link>
                                    <Link to="/postcomic-serie" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Tomo (serie)
                                        </button>
                                    </Link>
                                    <Link to="/postcomic-coleccion" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Tomo (colección)
                                        </button>
                                    </Link>
                                </span>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    AÑADIR LIBROS
                                </h1>
                                <span className='panel-buttons-container'>
                                    <Link to="/postserielibro" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Serie
                                        </button>
                                    </Link>
                                    <Link to="/postlibro" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Tomo
                                        </button>
                                    </Link>
                                </span>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    AÑADIR FIGURAS
                                </h1>
                                <span className='panel-buttons-container'>
                                    <Link to="/postfigura" className='panel-button-container' reloadDocument={true}>
                                        <button className='panel-button'>
                                            Figura
                                        </button>
                                    </Link>
                                    <FigurasPriceChange />
                                    <FigurasStock figuras={this.state.figuras} />
                                </span>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    ACTUALIZAR SERIE ABIERTA
                                </h1>
                                <UpdateSerie series={this.state.series} />
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    DESCUENTOS
                                </h1>
                                <Discounts editoriales={this.props.state.editoriales} />
                            </span>
                        </span>
                        <span className='panel-container'>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    VENTAS
                                </h1>
                                <p className='ventas-info'>
                                    En esta sección podrás ver todas las ventas abiertas y cerradas y sus estadísticas.
                                </p>
                                <Link to="/adminventas" className='panel-button-container' reloadDocument={true}>
                                    <button className='panel-button'>
                                        Administrar ventas
                                    </button>
                                </Link>
                                <h1 className='panel-title'>
                                    STOCK
                                </h1>
                                <Link to="/stock" className='panel-button-container' reloadDocument={true}>
                                    <button className='panel-button'>
                                        Administrar stock
                                    </button>
                                </Link>
                            </span>
                            <span className='add-panel'>
                                <h1 className='panel-title'>
                                    MODIFICAR PRECIOS
                                </h1>
                                <span className='price-modify-container'>
                                    <span className='price-button-container'>
                                        {
                                            this.props.state.editoriales.map((e) => {
                                                return (
                                                    <button id={e.nombre} className='price-button' key={e.id} onClick={this.handleEditorial}>
                                                        {e.nombre}
                                                    </button>
                                                )
                                            })
                                        }
                                    </span>
                                    <p className='p-changes-info' onClick={this.changesInfo}>Cómo modificar los precios</p>
                                    <PriceChange editorial={this.state.editorial} series={this.state.series} comics={this.state.comics} />
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getEditoriales,
    getSeriesAll,
    getComics,
    getSpecial,
    getFiguras
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(Dashboard);