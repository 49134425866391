import React from "react";
import Card from "../../components/card/card";

import { connect } from "react-redux";
import { sameSerie } from "../../redux/actions";

import './serie.css';

class Series extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            allVols: [],
            vols: [],
            loading: true,
            autores: {
                guion: "",
                dibujo: ""
            },
            serie: "",
            get: false
        }
    }

    componentDidMount(){
        const params = new Proxy(new URLSearchParams(window.location.href), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let name = params.name;
        
        console.log(name)
        this.setState({
            serie: name
        }, ()=>{
            if(!this.state.get){
                this.setState({
                    get: true
                }, ()=>{
                    this.props.sameSerie({
                        serie: name
                    });
                })
            }
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.vols !== this.props.state.vols){
            console.log(this.props.state.vols)
            this.setState({
                allVols: this.props.state.vols,
                vols: this.props.state.vols
            }, ()=>{
                this.setState({
                    loading: false
                })
            })

        }
    }

    filter = (e) => {
        if(e.target.style.backgroundColor !== "rgb(55, 130, 175)"){
            e.target.style.backgroundColor = "rgb(55, 130, 175)"
            let vols = this.state.allVols;
            let filtered = [];
            for(let i = 0; i < vols.length; i++){
                if(parseInt(vols[i].stock) > 0){
                    filtered.push(vols[i])
                }
            }

            this.setState({
                vols: filtered
            })
        }else{
            e.target.style.backgroundColor = "white";

            this.setState({
                vols: this.state.allVols
            })
        }
    }

    render(){
        if(this.state.loading){
            return(
                <div className="search-main-grid">BUSCANDO</div>
            )
        }else{
            return(
                <div className="search-main-container">
                    <div className="filter-container">
                        <span className="filters">
                            <button id="max-price" className="input-filter" onClick={this.filter}>
                                Solo tomos en stock
                            </button>
                        </span>
                        <span className="cant-search">
                            <p className="p-cant">
                                {
                                    `Todos los tomos de: ${this.state.serie}`
                                }
                            </p>
                        </span>
                    </div>
                    <span className="search-grid">
                        {
                            this.state.vols.map((vol)=>{
                                return(
                                    <Card vol={vol} key={`${vol.id}`} />
                                )
                            })
                        }
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    sameSerie
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Series);