import React from "react";

import './adminVentas.css';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import { getMangas, getComics, getLibros, simPurchase, getVentas, updateVentas } from "../../redux/actions";
import { auth } from '../../firebase';
import Loader from "../loader";
import tokens from "../../variables";

import { AiOutlinePlusCircle } from 'react-icons/ai';

class AdminVentas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            series: [],
            colecciones: [],
            special: [],
            isLogged: false,
            items: 1,
            ventas: {
                local: [],
                envio: [],
                preparacion: []
            },
            to_view: [],
            admin: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        auth.onAuthStateChanged(() => {
            if (auth.currentUser && !this.state.isLogged) {
                if (auth.currentUser.uid === tokens.admin) {
                    this.setState({
                        isLogged: true,
                        loading: true
                    }, () => {
                        this.props.getMangas()
                    })
                } else {
                    window.location.href = '/';
                }
            } else if (!auth.currentUser && this.state.isLogged) {
                this.setState({
                    isLogged: false
                })
            } else if (!auth.currentUser && !this.state.isLogged) {
                window.location.href = '/';
            }
        })

        if (prevProps.state.mangas !== this.props.state.mangas) {
            let mangas = this.props.state.mangas;
            let series = [];
            let special = [];
            mangas.map((e) => {
                if (e.special) {
                    special.push(`${e.serie} ${e.numero} ${e.comment}`)
                } else {
                    let repeat = false;
                    for (let i = 0; i < series.length; i++) {
                        if (series[i] === e.serie) {
                            repeat = true;
                        }
                    }

                    if (!repeat) {
                        series.push(e.serie)
                    }
                }
            })

            this.setState({
                series: series,
                special: special
            }, () => {
                this.props.getComics();
            })

        }

        if (prevProps.state.comics !== this.props.state.comics) {
            let comics = this.props.state.comics;
            let series = [];
            let colecciones = [];

            comics.map((e) => {
                let repeat = false;

                if (e.serie) {
                    for (let i = 0; i < series.length; i++) {
                        if (series[i] === e.serie) {
                            repeat = true;
                        }
                    }

                    if (!repeat) {
                        series.push(e.serie)
                    }
                } else {
                    for (let i = 0; i < colecciones.length; i++) {
                        if (colecciones[i] === e.nombre) {
                            repeat = true;
                        }
                    }

                    if (!repeat) {
                        colecciones.push(e.nombre)
                    }
                }
            })

            let total = this.state.series;

            for (let i = 0; i < total.length; i++) {
                series.push(total[i]);
            }

            this.setState({
                series: series,
                colecciones: colecciones
            }, () => {
                this.props.getLibros()
            })
        }

        if (prevProps.state.libros !== this.props.state.libros) {
            let libros = this.props.state.libros;
            let series = this.state.series;
            libros.map((e) => {
                let repeat = false;
                for (let i = 0; i < series.length; i++) {
                    if (series[i] === e.serie) {
                        repeat = true;
                    }
                }

                if (!repeat) {
                    series.push(e.serie)
                }
            })

            this.setState({
                series: series
            }, () => {
                let series_filtered = this.state.series.sort()
                let col_filtered = this.state.colecciones.sort()
                this.setState({
                    series: series_filtered,
                    colecciones: col_filtered
                }, () => {
                    this.props.getVentas();
                })
            })
        }

        if (prevProps.state.ventas !== this.props.state.ventas) {
            this.setState({
                ventas: this.props.state.ventas
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }

        if (prevProps.state.status !== this.props.state.status) {
            let text = "";
            let icon = "error";

            for (let i = 0; i < this.props.state.status.changes.length; i++) {
                if (this.props.state.status.changes[i].message === "actualizado correctamente!") {
                    icon = "success"
                } else if (this.props.state.status.changes[i].message !== "actualizado correctamente!" && icon === "success") {
                    icon = "warning"
                }
                text += `${this.props.state.status.changes[i].serie} ${this.props.state.status.changes[i].n} ${this.props.state.status.changes[i].message}\n\n\n`
            }

            Swal.fire({
                title: "Nueva compra confirmada",
                html: this.props.state.status.msg,
                showConfirmButton: true
            })
                .then(() => {
                    Swal.fire({
                        icon: icon,
                        title: text
                    })
                        .then(() => {
                            window.location.reload()
                        })
                })

        }

        if (prevProps.state.venta_status !== this.props.state.venta_status) {
            if (this.props.state.venta_status === "success") {
                Swal.fire({
                    icon: "success",
                    title: "Venta actualizada con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }

    }

    createElement = () => {

        const container = document.getElementById("admin-form");

        const lastContainer = document.getElementById(`container-${this.state.items - 1}`);

        let span = document.createElement("span");
        span.className = `admin-input-container`;
        span.id = `container-${this.state.items}`
        container.insertBefore(span, lastContainer);

        let p = document.createElement("p");
        p.className = "admin-p";
        p.innerHTML = "Serie";
        span.appendChild(p);

        let select = document.createElement("select");
        select.className = "admin-select";
        span.appendChild(select);

        this.state.series.map((e) => {
            let option = document.createElement("option");
            option.className = `admin-option-${this.state.items}`;
            option.id = e;
            option.innerHTML = e;
            option.key = e;

            return select.appendChild(option);
        })

        let option = document.createElement("option");
        option.className = `admin-option`;
        option.id = "n/a";
        option.innerHTML = "N/A";
        option.selected = "selected"
        select.appendChild(option);

        let pc = document.createElement("p");
        pc.innerHTML = "Número";
        pc.htmlFor = `input-${this.state.items}`;
        pc.className = "admin-p";
        span.appendChild(pc);

        let input = document.createElement("input");
        input.className = "admin-input";
        input.id = `input-${this.state.items}`;
        input.type = "number";
        input.min = "1"
        span.appendChild(input);

        let p_c = document.createElement("p");
        p_c.className = "admin-p";
        p_c.innerHTML = "Tomo (colección)";
        span.appendChild(p_c);

        let select_c = document.createElement("select");
        select_c.className = "admin-select";
        span.appendChild(select_c);

        this.state.colecciones.map((e) => {
            let option_c = document.createElement("option");
            option_c.className = `admin-option-${this.state.items}`;
            option_c.id = e;
            option_c.innerHTML = e;
            option_c.key = e;

            return select_c.appendChild(option_c);
        })

        let option_c = document.createElement("option");
        option_c.className = `admin-option`;
        option_c.id = "n/a-c";
        option_c.innerHTML = "N/A";
        option_c.selected = "selected"
        select_c.appendChild(option_c);

        let p_s = document.createElement("p");
        p_s.className = "admin-p";
        p_s.innerHTML = "Edición especial";
        span.appendChild(p_s);

        let select_s = document.createElement("select");
        select_s.className = "admin-select";
        span.appendChild(select_s);

        this.state.special.map((e) => {
            let option_s = document.createElement("option");
            option_s.className = `admin-option-${this.state.items}`;
            option_s.id = e;
            option_s.innerHTML = e;
            option_s.key = e;

            return select_s.appendChild(option_s);
        })

        let option_s = document.createElement("option");
        option_s.className = `admin-option`;
        option_s.id = "n/a-c";
        option_s.innerHTML = "N/A";
        option_s.selected = "selected"
        select_s.appendChild(option_s);

        return this.setState({
            items: this.state.items + 1
        })

    }

    handleBuy = (e) => {

        e.preventDefault();

        let inputs = document.getElementsByTagName("input");
        let items = [];

        for (let i = 0; i < this.state.items; i++) {
            for (let j = 0; j < inputs.length; j++) {
                if (inputs[j].id === `input-${i}`) {
                    let options = document.getElementsByClassName(`admin-option-${i}`);
                    for (let k = 0; k < options.length; k++) {
                        if (options[k].selected) {
                            if (options[k].innerHTML !== "N/A") {
                                let item = {
                                    serie: options[k].id,
                                    n: inputs[j].value
                                }

                                items.push(item);
                            }
                        }
                    }
                }
            }
        }

        this.props.simPurchase(items);

        Swal.fire({
            title: "Simulando venta",
            allowEscapeKey: false,
            allowOutsideClick: false,
            allowEnterKey: false,
            showConfirmButton: false
        })

    }

    getEnvio = (compra) => {
        if (compra.shipping) {
            if (compra.shipping.seguimiento === "") {
                return (
                    <span className="purchase-shipping">
                        <p className="purchase-sp-title">Envío pendiente</p>
                        <p className="purchase-sp-data">
                            Envíar a:
                        </p>
                        <p className="purchase-sp-data">
                            Calle: {compra.shipping.destino.calle} {compra.shipping.destino.numero}
                        </p>
                        <p className="purchase-sp-data">
                            Piso/Dpto: {compra.shipping.destino.piso_dpto}
                        </p>
                        <p className="purchase-sp-data">
                            Localidad: {compra.shipping.destino.localidad}
                        </p>
                        <p className="purchase-sp-data">
                            CP: {compra.shipping.destino.cp}
                        </p>
                        <p className="purchase-sp-data">
                            Provincia: {compra.shipping.destino.provincia}
                        </p>
                        <br />
                        <p className="purchase-sp-data">
                            Recibe: {compra.shipping.cliente.nombre}, DNI: {compra.shipping.cliente.dni}
                        </p>
                        <p className="purchase-sp-data">
                            Email: {compra.payer.email}
                        </p>
                        <p className="purchase-sp-data">
                            Tel.: {compra.payer.phone}
                        </p>
                    </span>
                )
            } else {
                return (
                    <span className="purchase-shipping">
                        <p className="purchase-sp-title">Envío despachado</p>
                        <p className="purchase-sp-data">
                            Envíar a:
                        </p>
                        <p className="purchase-sp-data">
                            Calle: {compra.shipping.destino.calle} {compra.shipping.destino.numero}
                        </p>
                        <p className="purchase-sp-data">
                            Piso/Dpto: {compra.shipping.destino.piso_dpto}
                        </p>
                        <p className="purchase-sp-data">
                            Localidad: {compra.shipping.destino.localidad}
                        </p>
                        <p className="purchase-sp-data">
                            CP: {compra.shipping.destino.cp}
                        </p>
                        <p className="purchase-sp-data">
                            Provincia: {compra.shipping.destino.provincia}
                        </p>
                        <br />
                        <p className="purchase-sp-data">
                            Recibe: {compra.shipping.cliente.nombre}, DNI: {compra.shipping.cliente.dni}
                        </p>
                        <p className="purchase-sp-data">
                            Email: {compra.payer.email}
                        </p>
                        <p className="purchase-sp-data">
                            Tel.: {compra.payer.phone}
                        </p>
                    </span>
                )
            }
        } else {
            return (
                <span className="purchase-shipping">
                    <p className="purchase-sp-data">
                        {this.getUser(compra)}
                    </p>
                    <p className="purchase-sp-data">
                        Email: {compra.payer.email}
                    </p>
                    <p className="purchase-sp-data">
                        Tel.: {compra.payer.phone}
                    </p>
                </span>
            )
        }
    }

    getTitle = (item) => {
        if (item.figura) {
            return `${item.title} x${item.cant} - $${item.precio}`
        }

        if (item.serie) {
            if (item.libro) {
                return `${item.serie} ${item.numero}: ${item.subtitulo} x${item.cant} - $${item.precio}`
            } else {
                if (item.special) {
                    return `${item.serie} ${item.numero} - ${item.comment} x${item.cant} - $${item.precio}`
                } else {
                    return `${item.serie} ${item.numero} x${item.cant} - $${item.precio}`
                }
            }
        }

        if (item.coleccion) {
            return `${item.nombre} - ${item.coleccion} x${item.cant} - $${item.precio}`
        }

        return `${item.title} x${item.cant} - $${item.precio}`
    }

    handleRetirada = (e) => {
        const id = e.target.id;
        const ventas = this.state.to_view;

        for (let i = 0; i < ventas.length; i++) {
            if (ventas[i].id === id) {
                const data = {
                    type: "retirada",
                    compraID: id,
                    user_email: ventas[i].user
                }

                this.props.updateVentas(data);
                Swal.fire({
                    title: "Actualizando...",
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                })
            }
        }
    }

    handleDespachada = (e) => {
        const id = e.target.id;
        const ventas = this.state.to_view;

        for (let i = 0; i < ventas.length; i++) {
            if (ventas[i].id === id) {
                let seguimiento = document.getElementById("input-seguimiento").value;

                if (seguimiento === "") {
                    seguimiento = "-"
                }

                const data = {
                    type: "seguimiento",
                    compraID: id,
                    user_email: ventas[i].user,
                    seguimiento: seguimiento
                }

                this.props.updateVentas(data);
                Swal.fire({
                    title: "Actualizando...",
                    allowEnterKey: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false
                })
            }
        }
    }

    getActions = (compra) => {
        if (compra.retira && !compra.retira.completed) {
            return (
                <span className="card-actions">
                    <button className="actions-button" onClick={this.handleRetirada} id={compra.id}>
                        Retirada
                    </button>
                </span>
            )
        } else if (compra.shipping && compra.shipping.seguimiento === "") {
            return (
                <span className="card-actions">
                    <input type="text" id="input-seguimiento" placeholder="Código de seguimiento..." className="input-actions" />
                    <button className="actions-button" onClick={this.handleDespachada} id={compra.id}>
                        Despachada
                    </button>
                </span>
            )
        }
    }

    getUser = (compra) => {
        return `Retira: ${compra.retira.nombre}, DNI: ${compra.retira.dni}`;
    }

    getVentas = () => {
        const ventas = this.state.to_view;
        let type = "";

        if (ventas[0]) {
            if (!ventas[0].shipping) {
                type = "local"
            } else {
                if (ventas[0].shipping.seguimiento === "") {
                    type = "preparacion"
                } else {
                    type = "envio"
                }
            }
        }

        return (
            <span className="ventas-grid">
                {
                    ventas.map((compra) => {
                        return (
                            <div className="purchase-card" key={compra.id} id="venta-card">
                                <p className="purchase-date">
                                    {compra.date}
                                </p>
                                {
                                    ()=>{
                                        if(compra.qr)
                                            return <p>Pago con QR: {compra.qr}</p>
                                    }
                                }
                                {compra.purchase.items.map((item) => {
                                    return (
                                        <span className="purchase-item" key={item.id}>
                                            {this.getTitle(item)}
                                            <br />
                                        </span>
                                    )
                                })}
                                <p className="purchase-total">
                                    ${compra.purchase.total}
                                </p>
                                {this.getEnvio(compra)}
                                <p className="purchase-sp-data" id="compra-id">
                                    Compra: {compra.id}
                                </p>
                                {this.getActions(compra)}
                            </div>
                        )
                    })
                }
            </span>
        )
    }

    handleVentas = (e) => {
        if (e.target.id === "local") {
            this.setState({
                to_view: this.state.ventas.local
            })
        } else if (e.target.id === "envio") {
            this.setState({
                to_view: this.state.ventas.envio
            })
        } else {
            this.setState({
                to_view: this.state.ventas.preparacion
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const search = document.getElementById('search-input').value
        const ventas = []
        this.state.ventas.local.map((v) => {
            ventas.push(v)
        })
        this.state.ventas.envio.map((v) => {
            ventas.push(v)
        })
        this.state.ventas.preparacion.map((v) => {
            ventas.push(v)
        })
        const to_view = []
        ventas.map((v) => {
            if (v.retira) {
                const name = `${v.id} ${v.retira.nombre} ${v.retira.dni} ${v.user}`
                if (name.toLowerCase().includes(search.toLowerCase())) {
                    to_view.push(v)
                }
            } else {
                const name = `${v.id} ${v.shipping.cliente.nombre} ${v.user}`
                if (name.toLowerCase().includes(search.toLowerCase())) {
                    to_view.push(v)
                }
            }
        })

        this.setState({
            to_view: to_view
        })

    }

    getStyle = (e) => {
        if (e === 'simul') {
            if (this.state.admin) {
                return { "display": "none" }
            } else {
                return { "display": "flex" }
            }
        } else {
            if (this.state.admin) {
                return { "display": "flex" }
            } else {
                return { "display": "none" }
            }
        }
    }

    handleStyle = (e) => {
        if (e.target.id === "admin") {
            this.setState({
                admin: true
            })
        } else {
            this.setState({
                admin: false
            })
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="adminventas-main-container">
                    <h1 className="admin-main-title">
                        ADMINISTRAR VENTAS
                    </h1>
                    <button className="button-crear" id="simul" onClick={this.handleStyle}>Simular venta</button>
                    <button className="button-crear" id="admin" onClick={this.handleStyle}>Administrar ventas</button>
                    <div className="adminventas-panel-container">
                        <span className="adminventas-panel" style={this.getStyle('simul')}>
                            <button className="button-crear" id="crear" type="submit" onClick={this.handleBuy}>Crear compra</button>
                            <form className="adminventas-form" id="admin-form" onSubmit={this.handleBuy}>
                                <button type="button" className="admin-add-button" onClick={this.createElement} id="add-item">
                                    <AiOutlinePlusCircle className="admin-icon" />
                                </button>
                                <span className="admin-input-container" id="container-0">
                                    <p className="admin-p">Serie</p>
                                    <select className="admin-select" id="select-serie">
                                        <option className="admin-option-0" id="n/a" defaultValue={true}>N/A</option>
                                        {
                                            this.state.series.map((e) => {
                                                return <option className="admin-option-0" id={e} key={e}>
                                                    {e}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <p className="admin-p" htmlFor="input-0">Número</p>
                                    <input type="number" min="1" className="admin-input" id="input-0" />
                                    <p className="admin-p">{"Tomo (colección)"}</p>
                                    <select className="admin-select" id="select-tomo">
                                        <option className="admin-option-0" id="n/a" defaultValue={true}>N/A</option>
                                        {
                                            this.state.colecciones.map((e) => {
                                                return <option className="admin-option-0" id={e} key={e}>
                                                    {e}
                                                </option>
                                            })
                                        }
                                    </select>
                                    <p className="admin-p">{"Ediciones especiales"}</p>
                                    <select className="admin-select" id="select-special">
                                        <option className="admin-option-0" id="n/a" defaultValue={true}>N/A</option>
                                        {
                                            this.state.special.map((e) => {
                                                return <option className="admin-option-0" id={e} key={e}>
                                                    {e}
                                                </option>
                                            })
                                        }
                                    </select>
                                </span>
                            </form>
                        </span>
                        <span className="adminventas-panel" id="button-container" style={this.getStyle('admin')}>
                            <button className="button-crear" id="local" onClick={this.handleVentas}>
                                Ventas por retirar
                            </button>
                            <button className="button-crear" id="preparacion" onClick={this.handleVentas}>
                                Ventas por despachar
                            </button>
                            <button className="button-crear" id="envio" onClick={this.handleVentas}>
                                Ventas despachadas
                            </button>
                        </span>
                    </div>
                    <form className="search-ventas-form" onSubmit={this.handleSubmit} style={this.getStyle('admin')}>
                        <label htmlFor="search-input" className="search-ventas-label">Buscar por número de compra o comprador</label>
                        <input className="search-ventas-input" id="search-input" type="text" />
                        <button type="submit" className="search-ventas-button">Buscar</button>
                    </form>
                    <div className="adminventas-panel-container" style={this.getStyle('admin')}>
                        {
                            this.getVentas()
                        }
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getMangas,
    getComics,
    simPurchase,
    getVentas,
    getLibros,
    updateVentas
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(AdminVentas);