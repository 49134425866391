import React from "react";
import "./card.css";
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { addToCart } from "../../../../redux/actions";

import { auth } from "../../../../firebase";
import Swal from "sweetalert2";

class Card extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            vol: {},
            url: "",
            loading: true
        }
    }

    componentDidMount(){
        this.setState({
            vol: this.props.vol
        }, ()=>{

            let type;

            if(this.state.vol.coleccion){
                type = "coleccion"
            }else{
                type = "serie"
            }

            let link;


            if(this.state.vol.libro){
                link = "/libro/" + this.state.vol.id;
            }else{
                if(this.state.vol.autores || this.state.vol.coleccion){
                    link = "/comic/" + this.state.vol.id;
                }else{
                    link = "/manga/" + this.state.vol.id;
                }
            }

            this.setState({
                url: link,
                type: type
            }, this.setState({
                loading: false
            }))
        })
    }

    addToCart = async(e) => {
        e.preventDefault();

        e.target.diabled = true;

        if(auth.currentUser){
            const data = {
                id: auth.currentUser.uid,
                itemId: this.state.vol.id
            }

            this.props.addToCart(data);
            e.target.disabled = true;
        }else{

            const prevStorage = await JSON.parse(localStorage.getItem('cart'));

            if(prevStorage){
                const cart = {
                    items: [...prevStorage.items, this.state.vol.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }else{
                const cart = {
                    items: [this.state.vol.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart))
            }

            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }
    }

    getAuthors = () => {
        if(this.state.vol.guion === this.state.vol.dibujo){
            return `${this.state.vol.guion}`
        }else{
            return `${this.state.vol.guion} & ${this.state.vol.dibujo}`
        }
    }

    getState = () => {
        if(this.state.vol.descuento){
            return "OFERTA"
        }else{
            if(this.state.vol.special){
                return "Edición especial"
            }else{
                if(this.state.vol.completa && parseInt(this.state.vol.numero) > 1){
                    return "Último tomo!"
                }else if(!this.state.vol.completa && parseInt(this.state.vol.numero) > 1){
                    return "Serie en curso"
                }else if(!this.state.vol.completa && parseInt(this.state.vol.numero) === 1){
                    return "Nueva serie!"
                }else if(this.state.vol.completa && parseInt(this.state.vol.numero) === 1){
                    return "Tomo único"
                }
            }
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.status !== this.props.state.status && this.props.state.status !== ""){
            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }
    }

    getTitle = () => {
        if(this.state.vol.special){
            return `${this.state.vol.serie} #${this.state.vol.numero} - ${this.state.vol.comment}`
        }else{
            if(this.state.vol.subtitulo){
                return `${this.state.vol.serie} ${this.state.vol.numero}: ${this.state.vol.subtitulo}`
            }else{
                return `${this.state.vol.serie} #${this.state.vol.numero}`
            }
        }
    }

    getStock = () => {
        if(this.state.vol.stock > 0){
            return(
                <button className="news-card-buy" id="cart-button" onClick={this.addToCart}>
                    AGREGAR AL CARRITO
                </button>
            )
        }else{
            return(
                <button className="news-card-buy" id="no-stock">
                    SIN STOCK
                </button>
            )
        }
    }

    getPrice = () => {
        if(this.state.vol.descuento){
            const precio = parseInt(this.state.vol.precio)
            const descuento = parseInt(this.state.vol.descuento)
            const oldPrecio = Math.floor(100 * precio / (100 - descuento))
            return(
                <div className="news-card-price-container" id="descuento">
                    <p className="news-card-old">
                        {`$${oldPrecio}`}
                    </p>
                    <p className="news-card-price">
                        {`$${precio}`}
                    </p>
                </div>
            )
        }else{
            return(
                <div className="news-card-price-container">
                    <p className="news-card-price">
                        {`$${this.state.vol.precio}`}
                    </p>
                </div>
            )
        }
    }

    getStateID = () => {
        if(this.state.vol.descuento){
            return "id-descuento"
        }
    }

    getOferta = () => {
        if(this.state.vol.descuento){
            return "OFERTA"
        }else{
            return `${this.state.vol.coleccion}`
        }
    }

    render(){
        if(this.state.loading){
            return(
                <div className="loader-container">
                    <span className="loader" />
                </div>
            )
        }else{
            if(this.state.type === "serie"){
                return(
                    <div className="news-card-container">
                        <Link to={this.state.url} reloaddocument={true}>
                            <img src={this.state.vol.imgURL} alt={`${this.state.vol.serie}${this.state.vol.numero}`} className="news-card-img" />
                        </Link>
                        <span className="news-card-info-container">
                            <span className="news-card-title-container">
                                <h1 className="news-card-title">
                                    {this.getTitle()}
                                </h1>
                            </span>
                            <p className="news-card-editorial">
                                {this.state.vol.editorial}
                            </p>
                            <p className="news-card-state" id={this.getStateID()}>
                                {this.getState()}
                            </p>
                            {this.getPrice()}
                            {this.getStock()}
                        </span>
                    </div>
                )
            }else{
                return(
                    <div className="news-card-container">
                        <Link to={this.state.url} reloaddocument={true}>
                            <img src={this.state.vol.imgURL} alt={`${this.state.vol.coleccion}${this.state.vol.nombre}`} className="news-card-img" />
                        </Link>
                        <span className="news-card-info-container">
                            <span className="news-card-title-container">
                                <h1 className="news-card-title">
                                    {`${this.state.vol.nombre}`}
                                </h1>
                            </span>
                            <p className="news-card-editorial">
                                {this.state.vol.editorial}
                            </p>
                            <p className="news-card-state" id={this.getStateID()}>
                                {this.getOferta()}
                            </p>
                            {this.getPrice()}
                            {this.getStock()}
                        </span>
                    </div>
                )
            }
        }
    }
            
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    addToCart
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Card);