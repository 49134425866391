import React from "react";
import Loader from "../../pages/loader";

import Card from "../card/card";

import { connect } from "react-redux";
import { getMangas, getComics, getLibros } from "../../redux/actions";

import "../same.css"

class SameAuthors extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            vols: [],
            n: 0
        }
    }

    componentDidMount(){
        
        if(window.screen.width < 450){
            this.setState({
                n: 2
            })
        }else if(window.screen.width < 850){
            this.setState({
                n: 3
            })
        }else{
            this.setState({
                n: 5
            })
        }

        if(this.props.type === "manga"){
            this.props.getMangas()
        }else if(this.props.type === "comic"){
            this.props.getComics()
        }else{
            this.props.getLibros()
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.state.mangas !== prevProps.state.mangas){
            let mangas = this.props.state.mangas;
            let filtered = [];

            for(let i = 0; i < mangas.length; i++){
                if(mangas[i].guion === this.props.guion && mangas[i].dibujo === this.props.dibujo && filtered.length < this.state.n){
                    filtered.push(mangas[i])
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
        
        if(this.props.state.comics !== prevProps.state.comics){
            let comics = this.props.state.comics;
            let filtered = [];

            
            for(let i = 0; i < comics.length; i++){
                if(comics[i].autores.guion === this.props.guion || comics[i].autores.guion.includes(this.props.guion)
                && comics[i].autores.dibujo === this.props.dibujo || comics[i].autores.dibujo.includes(this.props.dibujo)
                && filtered.length < this.state.n){
                    filtered.push(comics[i])
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        if(prevProps.state.libros !== this.props.state.libros){

            let libros = this.props.state.libros;

            let filtered = [];
            for(let i = 0; i < libros.length; i++){
                if(libros[i].autor === this.props.autor){
                    filtered.push(libros[i])
                }
            }

            this.setState({
                vols: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    handleRedirect = () => {

        let url;
        if(this.props.autor){
            url = `/autores?has=true&name=${this.props.autor}`
        }else{
            if(this.props.dibujo !== this.props.guion){
                url = `/autores?has=true&name=${this.props.guion}~${this.props.dibujo}`
            }else{
                url = `/autores?has=true&name=${this.props.guion}`
            }
        }
        
        window.location.hash = url;
    }

    render(){
        if(this.state.loading){
            return(
                <Loader />
            )
        }else{
            return(
                <div className="same-main-container">
                <span className="same-grid">
                    {this.state.vols.map((e)=>{
                        return <Card vol={e} key={e.id}/>
                    })}
                </span>
                <button className="editorial-ver-mas" id="nuevas" onClick={this.handleRedirect}>VER MÁS</button> 
            </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getMangas,
    getComics,
    getLibros
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(SameAuthors);