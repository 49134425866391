import React from "react";

import { connect } from "react-redux";
import Card from "../../components/card/card";
import { getCatalogo } from "../../redux/actions";

import Loader from "../loader";

import './catalogo.css'

class Catalogo extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            series: [],
            loading: true
        }
    }

    componentDidMount(){
        this.props.getCatalogo()
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.catalogo !== this.props.state.catalogo){
            const series = []

            this.props.state.catalogo.series_manga.map((m)=>{
                if(parseInt(m.numero) === 1){
                    series.push(m)
                }
            })

            this.props.state.catalogo.series_comic.map((m)=>{
                if(parseInt(m.numero) === 1){
                    series.push(m)
                }
            })

            this.props.state.catalogo.series_libro.map((m)=>{
                if(parseInt(m.numero) === 1){
                    series.push(m)
                }
            })

            this.props.state.catalogo.colecciones.map((m)=>{
                series.push(m)
            })

            const filtered = series.sort((a, b)=>{
                if(a.serie && b.serie){
                    return a.serie.localeCompare(b.serie)
                }else if(a.serie && b.coleccion){
                    return a.nombre.localeCompare(b.serie)
                }else if(a.coleccion && b.serie){
                    return a.nombre.localeCompare(b.serie)
                }else if(a.coleccion && b.coleccion){
                    return a.nombre.localeCompare(b.nombre)
                }
            })

            this.setState({
                series: filtered
            }, ()=>{
                this.setState({
                    loading: false
                })
            })

        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="catalogo-main-container">
                    <span className="cant-search">
                        <p className="p-cant">
                            CATÁLOGO
                        </p>
                    </span>
                    <span className="catalogo-grid-container">
                        {
                            this.state.series.map((t)=>{
                                return <Card serie={true} vol={t} key={t.id} />
                            })
                        }
                    </span>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getCatalogo
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Catalogo);