import React from 'react';
import { connect } from "react-redux";
import Loader from '../loader';

import Card from '../../components/card/card'

import { getMangas, getComics, getEditoriales, getLibros } from '../../redux/actions';

import './editorialPage.css';
import Autores from './autores/autores';
import Especiales from './especiales/especiales';

class EditorialPage extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            type: "",
            vols: [],
            loading: true,
            ed: {},
            id: "",
            mangas: {
                vols: [],
                checked: false
            },
            comics: {
                vols: [],
                checked: false
            },
            libros: {
                vols: [],
                checked: false
            },
            n: 5,
            n_reco: 0,
            getEditoriales: false,
            getMangas: false,
            getComics: false,
            getLibros: false
        }
    }

    componentDidMount(){

        if(window.screen.width > 950){
            this.setState({
                n_reco: 2
            })
        }else if(window.screen.width <= 950 && window.screen.width > 945){
            this.setState({
                n_reco: 4
            })
        }else if(window.screen.width <= 945 && window.screen.width > 600){
            this.setState({
                n_reco: 3
            })
        }else{
            this.setState({
                n_reco: 2
            })
        }

        let url = window.location.href;
        let data = url.split("?")[1]
        let id = data.split("=")[1]
        this.setState({
            id: id
        }, ()=>{
            if(!this.state.getEditoriales){
                this.setState({
                    getEditoriales: true
                }, ()=>{
                    this.props.getEditoriales()
                })
            }
        })

        if(window.screen.width < 850){
            if(window.screen.width < 450){
                this.setState({
                    n: 2
                })
            }else{
                this.setState({
                    n: 3
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.state.editoriales !== this.props.state.editoriales){
            let editorial = {};
            for(let i = 0; i < this.props.state.editoriales.length; i++){
                if(this.props.state.editoriales[i].id === this.state.id){
                    editorial = this.props.state.editoriales[i]
                }
            }

            this.setState({
                ed: editorial
            })
        }

        if(prevState.ed !== this.state.ed){
            if(!this.state.getMangas){
                this.setState({
                    getMangas: true
                }, ()=>{
                    if(this.state.ed.mangas > 0){
                        this.props.getMangas()
                    }else{
                        this.setState({
                            mangas: {
                                ...this.state.mangas,
                                checked: true
                            }
                        })
                    }
                })
            }
        }

        if(prevProps.state.mangas !== this.props.state.mangas){
            let mangas = [];

            for(let i = 0; i < this.props.state.mangas.length; i++){
                if(this.props.state.mangas[i].editorial === this.state.ed.nombre){
                    mangas.push(this.props.state.mangas[i])
                }
            }

            this.setState({
                mangas: {
                    ...this.state.mangas,
                    vols: mangas
                }
            }, ()=>{
                this.setState({
                    mangas: {
                        ...this.state.mangas,
                        checked: true
                    }
                })
            })
        }

        if(prevState.mangas.checked !== this.state.mangas.checked){
            if(!this.state.getComics){
                this.setState({
                    getComics: true
                }, ()=>{
                    if(this.state.ed.comics > 0){
                        this.props.getComics()
                    }else{
                        this.setState({
                            comics: {
                                ...this.state.comics,
                                checked: true
                            }
                        })
                    }
                })
            }
        }

        if(prevProps.state.comics !== this.props.state.comics){
            let comics = [];
            for(let i = 0; i < this.props.state.comics.length; i++){
                if(this.props.state.comics[i].editorial === this.state.ed.nombre){
                    comics.push(this.props.state.comics[i])
                }
            }
            
            this.setState({
                comics: {
                    ...this.state.comics,
                    vols: comics
                }
            }, ()=>{
                this.setState({
                    comics: {
                        ...this.state.comics,
                        checked: true
                    }
                })
            })
        }

        if(prevState.comics.checked !== this.state.comics.checked){
            if(!this.state.getLibros){
                this.setState({
                    getLibros: true
                }, ()=>{
                    if(this.state.ed.libros > 0){
                        this.props.getLibros()
                    }else{
                        this.setState({
                            libros: {
                                ...this.state.libros,
                                checked: true
                            }
                        })
                    }
                })
            }
        }

        if(prevProps.state.libros !== this.props.state.libros){
            let libros = [];
            for(let i = 0; i < this.props.state.libros.length; i++){
                if(this.props.state.libros[i].editorial === this.state.ed.nombre){
                    libros.push(this.props.state.libros[i])
                }
            }

            this.setState({
                libros: {
                    ...this.state.libros,
                    vols: libros
                }
            }, ()=>{
                this.setState({
                    libros: {
                        ...this.state.libros,
                        checked: true
                    }
                })
            })
        }

        if(this.state.mangas.checked && this.state.comics.checked && this.state.libros.checked && this.state.loading){
            this.setState({
                loading: false
            }, ()=>{

                if(this.state.mangas.vols.length === 0){
                    document.getElementById("edbutton-menu-mangas").disabled = true
                }else{
                    document.getElementById("edbutton-menu-mangas").style.backgroundColor = "rgb(55, 130, 175)";
                    this.setState({
                        type: "mangas"
                    })
                }

                if(this.state.comics.vols.length === 0){
                    document.getElementById("edbutton-menu-comics").disabled = true
                }else if(this.state.mangas.vols.length === 0){
                    document.getElementById("edbutton-menu-comics").style.backgroundColor = "rgb(55, 130, 175)";
                    this.setState({
                        type: "comics"
                    })
                }

                if(this.state.libros.vols.length === 0){
                    document.getElementById("edbutton-menu-libros").disabled = true
                }else if(this.state.mangas.vols.length === 0 && this.state.comics.vols.length === 0){
                    document.getElementById("edbutton-menu-libros").style.backgroundColor = "rgb(55, 130, 175)";
                    this.setState({
                        type: "libros"
                    })
                }
            })
        }
    }

    hasMangas = () => {
        if(this.state.mangas.vols.length > 0){
            return (
                <div className='mangas-sec-container'>
                    <span className='edpage-recomendaciones'>
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">Autores destacados</h1>
                        </span>
                        {this.getAutores("mangas")}
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">Ediciones especiales</h1>
                        </span>
                        {this.getEspeciales("especiales")}
                    </span>
                </div>
            )
        }else{
            return null
        }
    }

    hasComics = () => {
        if(this.state.comics.vols.length > 0){
            return (
                <div className='mangas-sec-container'>
                    <span className='edpage-recomendaciones'>
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">Autores destacados</h1>
                        </span>
                        {this.getAutores("comics")}
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">Hardbacks</h1>
                        </span>
                        {this.getEspeciales("hardback")}
                    </span>
                </div>
            )
        }else{
            return null
        }
    }

    hasLibros = () => {
        if(this.state.libros.vols.length > 0){
            return (
                <div className='mangas-sec-container'>
                    <span className='edpage-recomendaciones'>
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">Autores destacados</h1>
                        </span>
                        {this.getAutores("libros")}
                    </span>
                </div>
            )
        }else{
            return null
        }
    }

    getEspeciales = (getType) => {
        let especiales_total = [];
        let especiales = [];
        let type = getType;

        if(type === "especiales"){

            for(let i = 0; i < this.state.mangas.vols.length; i++){
                if(this.state.mangas.vols[i].special){
                    especiales_total.push(this.state.mangas.vols[i])
                }
            }
    
            if(especiales_total.length > 0){
                especiales_total.sort((a, b)=>{
                    return b.ventas - a.ventas
                })
            }
    
            for(let i = 0; i < especiales_total.length; i++){
                if(i < 4){
                    especiales.push(especiales_total[i]);
                }
            }
        }else if(type === "hardback"){
            for(let i = 0; i < this.state.comics.vols.length; i++){
                if(this.state.comics.vols[i].hardcover){
                    console.log('mandamos un hardback')
                    especiales_total.push(this.state.comics.vols[i])
                    console.log('así queda especiales_total: ', especiales_total)
                }
            }
    
            if(especiales_total.length > 0){
                console.log('hay especiales')
                especiales_total.sort((a, b)=>{
                    return b.ventas - a.ventas
                })
            }
    
            for(let i = 0; i < especiales_total.length; i++){
                if(i < 4){
                    especiales.push(especiales_total[i]);
                }
            }

            
        }
        
        console.log('especiales', especiales)
        return <Especiales type={type} vols={especiales} editorial={this.state.ed.nombre} />
    }

    getAutores = (type) => {
        if(type === "mangas"){
            let autores = [];
            let mangas = this.state.mangas.vols;

            console.log(this.state.mangas.vols)

            let series = [];
    
            for(let i = 0; i < mangas.length; i++){

                let exist = false;

                for(let j = 0; j < series.length; j++){
                    if(mangas[i].serie === series[j]){
                        exist = true
                    }
                }

                if(!exist){
                    series.push(mangas[i].serie)
                    if(mangas[i].guion !== mangas[i].dibujo){
                        autores.push(mangas[i].guion)
                        autores.push(mangas[i].dibujo)
                    }else{
                        autores.push(mangas[i].guion)
                    }
                }
            }

            let not_repeat = autores.filter((item,index)=>{
                return autores.indexOf(item) === index;
            })

            
            let nombres = [];
            
            for(let i = 0; i < not_repeat.length; i++){
                nombres.push({
                    nombre: not_repeat[i],
                    cant: 0
                })
            }

            for(let i = 0; i < nombres.length; i++){
                for(let j = 0; j < autores.length; j++){
                    if(nombres[i].nombre === autores[j]){
                        nombres[i].cant++;
                    }
                }
            }

            nombres.sort((a, b)=>{
                return b.cant - a.cant
            })

            let recos = [];

            for(let i = 0; i < nombres.length; i++){
                if(i < 3){
                    recos.push({
                        autor: nombres[i].nombre,
                        series: []
                    })
                }
            }

            for(let i = 0; i < mangas.length; i++){
                for(let j = 0; j < recos.length; j++){
                    if(mangas[i].guion === recos[j].autor || mangas[i].dibujo === recos[j].autor){
                        if(mangas[i].numero === 1){
                            if(recos[j].series.length < this.state.n_reco){
                                recos[j].series.push(mangas[i])
                            }
                        }
                    }
                }
            }

            for(let i = 0; i < recos.length; i++){
                if(recos[i].series.length <= 0){
                    for(let j = 0; j < mangas.length; j++){
                        if(mangas[j].guion === recos[i].autor || mangas[j].dibujo === recos[i].autor){
                            if(recos[i].series.length <= 0){
                                recos[i].series.push(mangas[j])
                            }
                        }
                    }
                }
            }

            return <Autores recos={recos} />

        }else if(type === "comics"){
            let autores = [];
            let comics = this.state.comics.vols;

            let series = [];
    
            for(let i = 0; i < comics.length; i++){

                let exist = false;

                if(comics[i].isSerie){
                    for(let j = 0; j < series.length; j++){
                        if(comics[i].serie === series[j]){
                            exist = true
                        }
                    }
    
                    if(!exist){
                        series.push(comics[i].serie)
                        for(let key in comics[i].autores){
                            let self = [];
                            if(comics[i].autores[key] !== "-" && comics[i].autores[key] !== " " && comics[i].autores[key] !== "")
                            self.push(comics[i].autores[key]);
                            self.filter((item, index)=>{
                                return self.indexOf(item) === index;
                            })
                            for(let i = 0; i < self.length; i++){
                                autores.push(self[i]);
                            }
                        }
                    }
                }else{
                    for(let j = 0; j < series.length; j++){
                        if(comics[i].coleccion === series[j]){
                            exist = true
                        }
                    }
    
                    if(!exist){
                        series.push(comics[i].coleccion)
                        for(let key in comics[i].autores){
                            let self = [];
                            if(comics[i].autores[key] !== "-" && comics[i].autores[key] !== " " && comics[i].autores[key] !== "")
                            self.push(comics[i].autores[key]);
                            self.filter((item, index)=>{
                                return self.indexOf(item) === index;
                            })
                            for(let i = 0; i < self.length; i++){
                                autores.push(self[i]);
                            }
                        }
                    }
                }

            }

            let not_repeat = autores.filter((item,index)=>{
                return autores.indexOf(item) === index;
            })

            let nombres = [];

            for(let i = 0; i < not_repeat.length; i++){
                nombres.push({
                    nombre: not_repeat[i],
                    cant: 0
                })
            }

            for(let i = 0; i < nombres.length; i++){
                for(let j = 0; j < autores.length; j++){
                    if(nombres[i].nombre === autores[j]){
                        nombres[i].cant++;
                    }
                }
            }

            nombres.sort((a, b)=>{
                return b.cant - a.cant
            })

            let recos = [];

            for(let i = 0; i < nombres.length; i++){
                if(i < 3){
                    recos.push({
                        autor: nombres[i].nombre,
                        series: []
                    })
                }
            }

            for(let i = 0; i < comics.length; i++){
                for(let j = 0; j < recos.length; j++){
                    for(let key in comics[i].autores){
                        if(comics[i].autores[key] === recos[j].autor){
                            if(recos[j].series.length < this.state.n_reco){
                                if(recos[j].series.length > 0){
                                    for(let k = 0; k < recos[j].series.length; k++){
                                        if(recos[j].series[k].id !== comics[i].id){
                                            recos[j].series.push(comics[i])
                                        }
                                    }
                                }else{
                                    recos[j].series.push(comics[i])
                                }
                            }
                        }
                    }
                }
            }

            return <Autores recos={recos} />
        }else{
            let autores = [];
            this.state.libros.vols.map((e)=>{
                return autores.push(e.autor)
            })

            let libros = this.state.libros.vols;
            const data = [];

            let autores_filtered = autores.filter((item,index)=>{
                return autores.indexOf(item) === index;
            })

            for(let i = 0; i < autores_filtered.length; i++){
                let autor = {
                    autor: autores_filtered[i],
                    series: []
                }

                for(let j = 0; j < libros.length; j++){
                    if(libros[j].autor === autores_filtered[i] && parseInt(libros[j].numero) === 1){
                        autor.series.push(libros[j]);
                    }
                }

                data.push(autor)
            }

            data.sort((a, b)=>{
                return b.vols - a.vols
            })

            return <Autores recos={data} />

        }
    }

    handleType = (e) => {
        const botones = document.getElementsByClassName('edmenu-button');
        for(let i = 0; i < botones.length; i++){
            if(!botones[i].disabled){
                botones[i].style.backgroundColor = 'white'
            }
        }

        e.target.style.backgroundColor = 'rgb(55, 130, 175)'

        if(e.target.id === 'edbutton-menu-mangas'){
            this.setState({
                type: 'mangas'
            })
        }else if(e.target.id === 'edbutton-menu-comics'){
            this.setState({
                type: 'comics'
            })
        }else{
            this.setState({
                type: 'libros'
            })
        }
    }

    renderRecos = () => {
        if(this.state.type === 'mangas'){
            return(
                <div className='recomendaciones'>
                    {
                        this.hasMangas()
                    }
                </div>
            )
        }else if(this.state.type === 'comics'){
            return(
                <div className='recomendaciones'>
                    {
                        this.hasComics()
                    }
                </div>
            )
        }else{
            return(
                <div className='recomendaciones'>
                    {
                        this.hasLibros()
                    }
                </div>
            )
        }
    }

    getAll = () => {
        let allSeries = [];
        for(let i = 0; i < this.state.mangas.vols.length; i++){
            if(parseInt(this.state.mangas.vols[i].numero) === 1){
                allSeries.push(this.state.mangas.vols[i])
            }
        }

        for(let i = 0; i < this.state.comics.vols.length; i++){
            if(this.state.comics.vols[i].serie){
                if(parseInt(this.state.comics.vols[i].numero) === 1){
                    allSeries.push(this.state.comics.vols[i])
                }
            }else{
                allSeries.push(this.state.comics.vols[i])
            }
        }

        for(let i = 0; i < this.state.libros.vols.length; i++){
            if(parseInt(this.state.libros.vols[i].numero) === 1){
                allSeries.push(this.state.libros.vols[i])
            }
        }

        if(allSeries.length <= 0){
            for(let i = 0; i < this.state.mangas.vols.length; i++){
                let exist = false;
                for(let j = 0; j < allSeries.length; j++){
                    if(this.state.mangas.vols[i].serie === allSeries[j].serie){
                        exist = true;
                    }
                }
                if(!exist){
                    allSeries.push(this.state.mangas.vols[i])
                }
            }
        }

        return(
            <div className='all-series-grid'>
                {
                    allSeries.map((e)=>{
                        return <Card serie={true} vol={e} key={e.id} />
                    })
                }
            </div>
        )
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className='edpage-main-container'>
                    <span className='edpage-title-container'>
                        <img src={this.state.ed.imgURL} alt={this.state.ed.nombre} className="edpage-img" />
                        <h1 className='edpage-title'>Editorial {this.state.ed.nombre}</h1>
                    </span>
                    <span className='edmenu-container'>
                        <button className='edmenu-button' id='edbutton-menu-mangas' onClick={this.handleType}>MANGAS</button>
                        <button className='edmenu-button' id='edbutton-menu-comics' onClick={this.handleType}>CÓMICS</button>
                        <button className='edmenu-button' id='edbutton-menu-libros' onClick={this.handleType}>LIBROS</button>
                    </span>
                    {this.renderRecos()}
                    <span className='all-series'>
                        <span className='reco-title-container'>
                            <h1 className="recomendaciones-title">
                                {`Todas las series de Editorial ${this.state.ed.nombre}`}
                            </h1>
                        </span>
                        {this.getAll()}
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getComics,
    getMangas,
    getEditoriales,
    getLibros
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(EditorialPage);