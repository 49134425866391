import React from "react";
import SearchResults from '../searchResults/searchResults'

import './advancedSearch.css';

class AdvancedSearchComics extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            comics: [],
            allComics: [],
            pagina: -1,
            last: true,
            search: []
        }
    }

    componentDidMount(){
        this.setState({
            allComics: this.props.comics
        }, ()=>{
            document.getElementById("back").disabled = true;
            document.getElementById("next").disabled = true;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const nombre = document.getElementById("input-nombre").value.toLowerCase()
        const guion = document.getElementById("input-guion").value.toLowerCase()
        const dibujo = document.getElementById("input-dibujo").value.toLowerCase()
        const editorial = document.getElementById("input-editorial").value.toLowerCase()
        const min_precio = document.getElementById("input-precio-min").value
        const max_precio = document.getElementById("input-precio-max").value
        const min_tomos = document.getElementById("input-min").value
        const max_tomos = document.getElementById("input-max").value
        const especial = document.getElementById("input-especial").checked
        const completa = document.getElementById("input-completa").checked

        let comics = this.state.allComics;

        if(nombre !== ""){
            comics = comics.filter((comic)=>{
                if(comic.serie.toLowerCase().includes(nombre)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(guion !== ""){
            comics = comics.filter((comic)=>{
                if(comic.autores.guion.toLowerCase().includes(guion)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(dibujo !== ""){
            comics = comics.filter((comic)=>{
                if(comic.autores.dibujo.toLowerCase().includes(dibujo) || comic.autores.tintas.toLowerCase().includes(dibujo) || comic.autores.color.toLowerCase().includes(dibujo)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(editorial !== ""){
            comics = comics.filter((comic)=>{
                if(comic.editorial.toLowerCase().includes(editorial)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(min_precio !== ""){
            comics = comics.filter((comic)=>{
                if(parseInt(comic.precio) >= parseInt(min_precio)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(max_precio !== ""){
            comics = comics.filter((comic)=>{
                if(parseInt(comic.precio) <= parseInt(max_precio)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(min_tomos !== ""){
            comics = comics.filter((comic)=>{
                if(parseInt(comic.vol_amount) >= parseInt(min_tomos)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(max_tomos !== ""){
            comics = comics.filter((comic)=>{
                if(parseInt(comic.vol_amount) <= parseInt(max_tomos)){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(completa){
            comics = comics.filter((comic)=>{
                if(comic.completa){
                    return comic
                }else{
                    return null
                }
            })
        }

        if(especial){
            comics = comics.filter((comic)=>{
                if(comic.especial){
                    return comic
                }else{
                    return null
                }
            })
        }

        comics = comics.filter((comic)=>{
            if(comic.isSerie){
                if(parseInt(comic.numero) === 1){
                    return comic
                }else{
                    return null
                }
            }else{
                return comic
            }
        })

        this.setState({
            search: comics
        }, ()=>{
            if(window.screen.width <= 450){
                setTimeout(() => {
                    window.scrollTo({
                        top: 2925,
                        behavior: "smooth"
                    })
                }, 50);

            }else{
                window.scrollTo({
                    top: 2850,
                    behavior: "smooth"
                })
            }
            this.setState({
                pagina: 0
            })
        })
        


    }

    setPages = (array, isLast) => {
        this.setState({
            comics: array
        }, ()=>{
            if(this.state.pagina <= 0){
                document.getElementById("back").disabled = true;
            }else{
                document.getElementById("back").disabled = false;
            }

            if(isLast){
                document.getElementById("next").disabled = true;
            }else{
                document.getElementById("next").disabled = false;
            }

            if(array.length <= 6){
                document.getElementById("next").disabled = true;
            }
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.pagina !== this.state.pagina || prevState.search !== this.state.search){
            const paginado = []

            let isLast;

            if(window.screen.width <= 450){
                setTimeout(() => {
                    window.scrollTo({
                        top: 2925,
                        behavior: "smooth"
                    })
                }, 50);

            }else{
                window.scrollTo({
                    top: 2850,
                    behavior: "smooth"
                })
            }

            if(this.state.search.length === 0){
                return this.setPages([], true);
            }else{
                for(let i = 0; i < this.state.search.length; i++){
                    if(this.state.search[i + (6 * this.state.pagina)]  && paginado.length < 6){
                        paginado.push(this.state.search[i + (6 * this.state.pagina)])
                    }else{
                        isLast = true;
                        
                    }
    
                    if(isLast){
                        if(paginado.length <= 6){
                            this.setPages(paginado, true)
                        }
                    }else{
                        if(!this.state.search[6 * this.state.pagina + 1]){
                            this.setPages(paginado, true)
                        }else{
                            this.setPages(paginado, false)
                        }
                    }
        
                }
            }
    
            if(prevState.allComics !== this.state.allComics){
                document.getElementById("back").disabled = true;
                document.getElementById("next").disabled = true;
            }
        }
    

    }

    handlePage = (e) => {
        if(e.target.id === "back"){
            this.setState({
                pagina: this.state.pagina - 1,
                last: false
            })
        }else{
            this.setState({
                pagina: this.state.pagina + 1
            })
        }
    }
    
    render(){
        return(
            <div className="advanced-search-main-container">
                <span className="asearch-title-container">
                    <h1 className="asearch-title">
                        Búsqueda avanzada
                    </h1>
                </span>
                <div className="all-search-container">
                    <span className="asearch-form-container">
                        <form className="asearch-form" onSubmit={this.handleSubmit}>
                            <span className="asearch-input-container">
                                <span className="asearch-column">
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Nombre"
                                        id="input-nombre"
                                    />
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Guión"
                                        id="input-guion"
                                    />
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Dibujo/Tintas/Color"
                                        id="input-dibujo"
                                    />
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Editorial"
                                        id="input-editorial"
                                    />
                                </span>
                                <span className="asearch-column">
                                    <label className="asearch-label">
                                        Cantidad de tomos
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-min"
                                            min="1"
                                            placeholder="Mínimo"
                                        />
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-max"
                                            placeholder="Máximo"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Precio
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-precio-min"
                                            min="1"
                                            placeholder="Mínimo"
                                        />
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-precio-max"
                                            placeholder="Máximo"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Serie completa
                                        <input
                                            className="asearch-input"
                                            type="checkbox"
                                            id="input-completa"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Edición especial
                                        <input
                                            className="asearch-input"
                                            type="checkbox"
                                            id="input-especial"
                                        />
                                    </label>
                                </span>
                            </span>
                            <button type="submit" className="asearch-button">
                                <p className="asearch-p">
                                    Buscar
                                </p>
                            </button>
                        </form>
                    </span>
                    <span className="asearch-results-container">
                        <SearchResults vols={this.state.comics} />
                        <span className="results-page-container">
                            <button id="back" className="button-page" onClick={this.handlePage}>Anterior</button>
                            <button id="next" className="button-page" onClick={this.handlePage}>Siguiente</button>
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}

export default AdvancedSearchComics;