import React from "react";
import { connect } from "react-redux";
import { getComic, addToCart, addtofavs, subscribe } from "../../redux/actions";
import Swal from 'sweetalert2';
import { auth } from "../../firebase";
import { Link } from 'react-router-dom';

import '../vol.css'

import Loader from "../loader";
import SameSerie from "../../components/sameSerie/sameSerie";
import SameAuthors from "../../components/sameAuthors/sameAuthors";


class Comic extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            comic: {},
            url: "",
            firstUpdate: false,
            n: 0,
            get: false
        }
    }

    componentDidMount(){
        
        let url = window.location.href;
        url = url.split("/")[5];
        const id = url.split('&')[0]
        let name = url.split('&')[1]

        if(name.length > 1){
            name = name.split('%20')
            name = name.join(' ')
        }

        name = decodeURI(name)

        
        this.setState({
            url: {
                id: id,
                name: name
            }
        }, ()=>{
            if(!this.state.get){
                this.setState({
                    get: true
                }, ()=>{
                    this.props.getComic(this.state.url);
                })
            }
        })
        
    }
    
    componentDidUpdate(prevProps){

        if(this.state.comic !== prevProps.state.comic){
            if(this.props.state.comic.autores){
                console.log(this.props.state.comic)
                this.setState({
                    comic: this.props.state.comic
                }, ()=>{
                    this.setState({
                        loading: false
                    })
                })
            }
        }

        if(prevProps.state.status !== this.props.state.status){
            document.getElementById("shop").disabled = false;
            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }

        if(prevProps.state.post_state !== this.props.state.post_state){
            if(this.props.state.post_state === "success"){
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "success",
                    title: "Añadido al favoritos!"
                })
                this.props.state.post_state = ""
            }else{
                document.getElementById("favs").disabled = false;
                Swal.fire({
                    icon: "error",
                    title: "Este tomo ya está en favoritos!"
                })
                this.props.state.post_state = ""
            }
        }

        if(prevProps.state.subscribe !== this.props.state.subscribe){
            if(this.props.state.subscribe === "success"){
                Swal.fire({
                    icon: "success",
                    title: `Susripto correctamente!`
                })
                .then(()=>{
                    window.location.reload();
                })
            }else{
                Swal.fire({
                    icon: "warning",
                    title: this.props.state.subscribe
                })
                this.props.state.subscribe = ""
            }
        }
    }

    getAutores = (e) => {
        if(e === "col"){
            if(this.state.comic.autores.guion === this.state.comic.autores.dibujo){
                return `${this.state.comic.autores.guion}`
            }else{
                return `${this.state.comic.autores.guion}, ${this.state.comic.autores.dibujo}`
            }
        }else{
            if(this.state.comic.autores.guion === this.state.comic.autores.dibujo && this.state.comic.autores.guion === this.state.comic.autores.tintas && this.state.comic.autores.guion === this.state.comic.autores.color){
                return(
                    <span className="autores-container">
                        <p className="vol-detail">
                            {`Autor: ${this.state.comic.autores.guion}`}
                        </p>
                        <p className="vol-detail">
                            {`Otros: ${this.state.comic.autores.otros}`}
                        </p>
                    </span>
                )
            }else if(this.state.comic.autores.guion === this.state.comic.autores.dibujo && this.state.comic.autores.guion === this.state.comic.autores.color){
                return(
                    <span className="autores-container">
                        <p className="vol-detail">
                            {`Autor: ${this.state.comic.autores.guion}`}
                        </p>
                        <p className="vol-detail">
                            {`Tintas: ${this.state.comic.autores.tintas}`}
                        </p>
                        <p className="vol-detail">
                            {`Otros: ${this.state.comic.autores.otros}`}
                        </p>
                    </span>
                )
            }else if(this.state.comic.autores.guion === this.state.comic.autores.dibujo){
                return(
                    <span className="autores-container">
                        <p className="vol-detail">
                            {`Autor: ${this.state.comic.autores.guion}`}
                        </p>
                        <p className="vol-detail">
                            {`Coloreado: ${this.state.comic.autores.color}`}
                        </p>
                        <p className="vol-detail">
                            {`Tintas: ${this.state.comic.autores.tintas}`}
                        </p>
                        <p className="vol-detail">
                            {`Otros: ${this.state.comic.autores.otros}`}
                        </p>
                    </span>
                )
            }else{
                return(
                    <span className="autores-container">
                        <p className="vol-detail">
                            {`Autor: ${this.state.comic.autores.guion}`}
                        </p>
                        <p className="vol-detail">
                            {`Dibujo: ${this.state.comic.autores.dibujo}`}
                        </p>
                        <p className="vol-detail">
                            {`Coloreado: ${this.state.comic.autores.color}`}
                        </p>
                        <p className="vol-detail">
                            {`Tintas: ${this.state.comic.autores.tintas}`}
                        </p>
                        <p className="vol-detail">
                            {`Otros: ${this.state.comic.autores.otros}`}
                        </p>
                    </span>
                )
            }
        }
    }

    getState = () => {
        if(this.state.comic.isSerie){
            if(this.state.comic.completa){
                return `Estado: Completa`
            }else{
                return `Estado: En curso`
            }
        }else{
            return null
        }
    }

    buy = (e) => {
        e.preventDefault();
        let url = `#/itstimetopay?cart=false&item=${this.state.comic.id}`

        window.location.href = url;
    }

    cart = (e) => {
        e.preventDefault();
        if(auth.currentUser){
            const data = {
                id: auth.currentUser.uid,
                itemId: this.state.comic.id
            }

            this.props.addToCart(data);
            e.target.disabled = true;
        }else{

            const prevStorage = JSON.parse(localStorage.getItem('cart'));

            if(prevStorage.items){
                const cart = {
                    items: [...prevStorage.items, this.state.comic.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }else{
                const cart = {
                    items: [this.state.comic.id]
                }
                
                localStorage.setItem('cart', JSON.stringify(cart));
            }

            Swal.fire({
                icon: "success",
                title: "Añadido al carrito!"
            })
            .then(()=>{
                window.location.reload()
            })
        }
    }

    favs = (e) => {
        e.preventDefault();
        if(auth.currentUser){
            e.target.disabled = true;
            this.props.addtofavs({
                user_id: auth.currentUser.uid,
                id: this.state.comic.id
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "Necesitas estar registrado para usar los favoritos!"
            })
        }
    }

    isSerie = (e) => {
        if(e === "title"){
            if(this.state.comic.serie){
                return `${this.state.comic.serie} #${this.state.comic.numero}`
            }else{
                return `${this.state.comic.nombre}`
            }
        }else{
            if(this.state.comic.serie){
                return `Más tomos de  ${this.state.comic.serie}`
            }else{
                return `Más tomos de ${this.state.comic.coleccion}`
            }
        }
    }

    getPrice = () => {
        if(this.state.comic.descuento){
            const precio = this.state.comic.precio
            const descuento = this.state.comic.descuento
            const oldPrecio = Math.floor(100 * precio / (100 - descuento))
            return(
                <span className="precio-container">
                    <h3 className="old-precio">
                        {`$${oldPrecio}`}
                    </h3>
                    <h1 className="vol-price">
                        {`$${precio}`}
                    </h1>
                </span>
            )
        }else{
            return(
                <h1 className="vol-price">
                    {`$${this.state.comic.precio}`}
                </h1>
            )
        }
    }

    isInStock = (e) => {
        if(this.state.comic.stock > 0){
            return(
                <span className={`vol-options-container${e}`}>
                    {this.getPrice()}
                    <button className="vol-buy-button" onClick={this.buy}>
                        <p id="buy" className="vol-p">
                            Comprar
                        </p>
                    </button>
                    <button className="vol-cart-button" onClick={this.cart}>
                        <p className="vol-p">
                            Agregar al carrito
                        </p>
                    </button>
                    <button className="vol-favs-button" onClick={this.favs}>
                        <p className="vol-p">
                            Agregar a favoritos
                        </p>
                    </button>
                </span>
            )
        }else{
            return(
                <span className={`vol-options-container${e}`}>
                    {this.getPrice()}
                    <span className="vol-buy-button" id="stock">
                        <p id="buy" className="vol-p">
                            SIN STOCK
                        </p>
                    </span>
                    <button className="vol-favs-button" onClick={this.favs}>
                        <p className="vol-p">
                            Agregar a favoritos
                        </p>
                    </button>
                </span>
            )
        }
    }

    handleSubscribe = (e) => {
        if(auth.currentUser){
            if(this.state.comic.serie){
                this.props.subscribe({
                    id: auth.currentUser.uid,
                    serie: this.state.comic.serie
                })
            }else{
                this.props.subscribe({
                    id: auth.currentUser.uid,
                    serie: this.state.comic.coleccion
                })
            }
            e.target.disabled = true
        }else{
            Swal.fire({
                icon: "error",
                title: "Debes estar registrado para suscribirte!"
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="vol-main-container">
                    <span className="this-container">
                        <span className="vol-info-container">
                            <span className="vol-img-container">
                                <img className="vol-img" alt={this.state.comic.serie} src={this.state.comic.imgURL} />
                            </span>
                            <span className="vol-info">
                                <h3 className="vol-title">
                                    {this.isSerie("title")}
                                </h3>
                                <span className="vol-details">
                                    {this.getAutores()}
                                    <p className="vol-detail">
                                        Editorial: {this.state.comic.editorial}
                                    </p>
                                    <p className="vol-detail">
                                        {this.getState()}
                                    </p>
                                    {this.isInStock("-p")}
                                    <button className="suscribe-button">
                                        <p className="suscribe" onClick={this.handleSubscribe}>
                                            Suscribirse a {this.state.comic.serie || this.state.comic.coleccion}
                                        </p>
                                    </button>
                                    <p className="suscribe-info">
                                        Al suscribirse a esta serie, se le enviarán notificaciones cada vez que salga un tomo nuevo u otro vuelva a estar en stock.
                                    </p>
                                </span>
                            </span>
                        </span>
                        {this.isInStock("")}
                    </span>
                    <span className="sinposis-container">
                        <h1 className="sinopsis">
                            SINOPSIS
                        </h1>
                        {this.state.comic.sinopsis}
                    </span>
                    <span className="vol-related">
                        <span className="vol-same">
                            <h1 className="same-title">
                                {this.isSerie()}
                            </h1>
                            <SameSerie type="comic" serie={this.state.comic.serie} coleccion={this.state.comic.coleccion} />
                        </span>
                        <span className="vol-same">
                            <h1 className="same-title">
                                Más tomos de {this.getAutores("col")}
                            </h1>
                            <SameAuthors type="comic" guion={this.state.comic.autores.guion} dibujo={this.state.comic.autores.dibujo} />
                        </span>
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getComic,
    addToCart,
    addtofavs,
    subscribe
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Comic);