import React from "react";

import './priceChange.css';

import { connect } from "react-redux";
import { changePrice, getColecciones } from "../../redux/actions";

import Swal from 'sweetalert2'
import Loader from "../../pages/loader";

class PriceChange extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            global: {"display": "flex"},
            serie: {"display": "none"},
            tomo: {"display": "none"},
            series: [],
            tooltip: false,
            nombre: "",
            type: "mangas"
        }
    }

    componentDidMount(){
        this.setState({
            series: this.props.series
        }, ()=>{
            this.props.getColecciones()
        })
    }

    getSeriesTooltip = () => {

        let tooltip = [];
        for(let i = 0; i < this.state.series.length; i++){
                let nombre = this.state.series[i].nombre;
    
                if(nombre.toLowerCase().includes(this.state.nombre.toLocaleLowerCase())){
                    tooltip.push(nombre)
                }
        }

        let filtered = tooltip.filter((item,index)=>{
            return tooltip.indexOf(item) === index;
        })

        return(
            <span className="tooltip-container" id="tooltip-price">
                {
                    filtered.map((n)=>{
                        return(
                            <p className="tooltip-element" id={n} key={n} onClick={this.handleClick}>
                                {n}
                            </p>
                        )
                    })
                }
            </span>
        )


    }

    tooltipVisible = () => {
        if(this.state.tooltip){
            return {"display": "flex"}
        }else{
            return {"display": "none"}
        }
    }

    handleChange = (e) => {
        if(e.target.value.length > 2){
            this.setState({
                tooltip: true,
                nombre: e.target.value
            })
        }else{
            this.setState({
                tooltip: false
            })
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            tooltip: false
        }, ()=>{
            document.getElementById("input-serie").value = e.target.id;
            document.getElementById("input-serieT").value = e.target.id;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if(e.target.id === "global"){
            this.props.changePrice({
                type: "global",
                editorial: this.props.editorial,
                oldPrice: document.getElementById("input-old").value,
                newPrice: document.getElementById("input-new").value,
                apply: this.state.type
            })
        }else if(e.target.id === "serie"){
            this.props.changePrice({
                type: "serie",
                oldPrice: document.getElementById("input-oldS").value,
                newPrice: document.getElementById("input-newS").value,
                serie: document.getElementById("input-serie").value
            })
        }else if(e.target.id === "tomo"){
            this.props.changePrice({
                type: "tomo",
                oldPrice: document.getElementById("input-oldT").value,
                newPrice: document.getElementById("input-newT").value,
                serie: document.getElementById("input-serie").value,
                numero: document.getElementById("input-numero").value,
                nombre: document.getElementById("input-nombre").value
            })
        }


        Swal.fire({
            title: "CAMBIANDO PRECIOS...",
            allowOutsideClick: false
        })

    }

    componentDidUpdate(prevProps){
        if(prevProps.state.status !== this.props.state.status){
            if(this.props.state.status.status === "success"){
                Swal.fire({
                    icon: "success",
                    title: "Precios actualizados con éxito!",
                    text: this.props.state.status.message
                })
                .then(()=>{
                    this.props.state.status.status = ""
                })
            }else if(this.props.state.status.status === "error"){
                Swal.fire({
                    icon: "error",
                    title: "Error al actualizar los precios",
                    text: this.props.state.status.message
                })
                .then(()=>{
                    this.props.state.status.status = ""
                })
            }
        }

        if(prevProps.state.colecciones !== this.props.state.colecciones){
            let newSeries = this.state.series;
            for(let i = 0; i < this.props.state.colecciones.length; i++){
                newSeries.push(this.props.state.colecciones[i])
            }
            this.setState({
                series: newSeries
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    handleSelect = () => {
        const global = document.getElementById("oGlobal").selected
        const serie = document.getElementById("oSerie").selected
        const tomo = document.getElementById("oTomo").selected

        if(global){
            return this.setState({
                global: {"display": "flex"},
                serie: {"display": "none"},
                tomo: {"display": "none"}
            })
        }else if(serie){
            return this.setState({
                global: {"display": "none"},
                serie: {"display": "flex"},
                tomo: {"display": "none"}
            })
        }else if(tomo){
            return this.setState({
                global: {"display": "none"},
                serie: {"display": "none"},
                tomo: {"display": "flex"}
            })
        }
    }

    handleType = () => {
        let mangas = document.getElementById("check-mangas").selected
        let comics = document.getElementById("check-comics").selected
        let libros = document.getElementById("check-libros").selected

        if(mangas){
            return this.setState({
                type: "mangas"
            })
        }else if(comics){
            return this.setState({
                type: "comics"
            })
        }else if(libros){
            return this.setState({
                type: "libros"
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="change-main-container">
                    <select className="change-input" id="select" onChange={this.handleSelect}>
                        <option className="change-option" id="oGlobal">Global</option>
                        <option className="change-option" id="oSerie">Serie</option>
                        <option className="change-option" id="oTomo">Volumen</option>
                    </select>
                    <form id="global" className="change-form" onSubmit={this.handleSubmit} style={this.state.global}>
                        <select className="change-input" id="select-type" onChange={this.handleType}>
                            <option className="change-option" id="check-mangas">
                                Mangas
                            </option>
                            <option className="change-option" id="check-comics">
                                Cómics
                            </option>
                            <option className="change-option" id="check-libros">
                                Libros
                            </option>
                        </select>
                        <label htmlFor="input-old" className="change-label">Precio actual</label>
                        <input type="number" min="1" className="change-input" id="input-old" />
                        <label htmlFor="input-new" className="change-label">Precio nuevo</label>
                        <input type="number" min="1" className="change-input" id="input-new" />
                        <button className="change-button" type="submit">
                            Actualizar
                        </button>
                    </form>
                    <form id="serie" className="change-form" onSubmit={this.handleSubmit} style={this.state.serie}>
                        <label htmlFor="input-serie" className="change-label">Serie</label>
                        <input className="change-input" type="text" id="input-serie" onChange={this.handleChange} />
                        <span className="series-tooltip" style={this.tooltipVisible()}>
                            {
                                this.getSeriesTooltip()
                            }
                        </span>
                        <label htmlFor="input-old" className="change-label">Precio actual</label>
                        <input type="number" min="1" className="change-input" id="input-oldS" />
                        <label htmlFor="input-new" className="change-label">Precio nuevo</label>
                        <input type="number" min="1" className="change-input" id="input-newS" />
                        <button className="change-button" type="submit">
                            Actualizar
                        </button>
                    </form>
                    <form id="tomo" className="change-form" onSubmit={this.handleSubmit} style={this.state.tomo}>
                        <p className="change-p">En caso de ser un tomo sin número, escribir solo el nombre...</p>
                        <label htmlFor="input-serie" className="change-label">Serie</label>
                        <input className="change-input" type="text" id="input-serieT" onChange={this.handleChange} />
                        <span className="series-tooltip" style={this.tooltipVisible()}>
                            {
                                this.getSeriesTooltip()
                            }
                        </span>
                        <label htmlFor="input-numero" className="change-label">Número</label>
                        <input type="number" min="0" className="change-input" id="input-numero" />
                        <label htmlFor="input-nombre" className="change-label">Nombre</label>
                        <select type="text" className="change-input" id="input-nombre">
                            <option key="0001"></option>
                            {this.props.comics.map((c)=>{
                                return <option key={c.id}>{c.nombre}</option>
                            })}
                        </select>
                        <label htmlFor="input-old" className="change-label">Precio actual</label>
                        <input type="number" min="1" className="change-input" id="input-oldT" />
                        <label htmlFor="input-new" className="change-label">Precio nuevo</label>
                        <input type="number" min="1" className="change-input" id="input-newT" />
                        <button className="change-button" type="submit">
                            Actualizar
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    changePrice,
    getColecciones
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(PriceChange);