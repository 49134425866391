import React from "react";

import { register } from "../../redux/actions";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { connect } from "react-redux";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Swal from "sweetalert2";
import Loader from "../loader";

class Register extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            isLogged: false
        }
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1500);

    }

    onSubmit = (e) => {
        e.preventDefault();

        const email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const pass_regex = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        const email = document.getElementById("input-email").value;
        const pass = document.getElementById("input-pass").value;
        const user = document.getElementById("input-user").value;

        if(user.length > 2){
            if(email.match(email_regex)){
                if(pass.match(pass_regex)){

                    document.getElementById("input-email").disabled = true;
                    document.getElementById("input-pass").disabled = true;
                    document.getElementById("input-user").disabled = true;
                    document.getElementById("post-button").disabled = true;

                    createUserWithEmailAndPassword(auth, email, pass)
                    .then(()=>{

                        const user_data = {
                            email: email,
                            user: user,
                            id: auth.currentUser.uid
                        }

                        this.props.register(user_data)
                    })
                    .catch((error)=>{

                        document.getElementById("input-email").disabled = false;
                        document.getElementById("input-pass").disabled = false;
                        document.getElementById("input-user").disabled = false;
                        document.getElementById("post-button").disabled = false;

                        Swal.fire({
                            icon: "error",
                            title: error.message
                        })
                    })
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "La contraseña debe contener entre 6 y 16 caracteres y mínimo un número"
                    })
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Ingrese un email válido"
                })
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Ingrese un usuario válido"
            })
        }


    }

    componentDidUpdate(prevProps){

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                this.setState({
                    isLogged: true
                })
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }
        })

        if(prevProps.state.status !== this.props.state.status){
            if(this.props.state.status === "success"){
                Swal.fire({
                    icon: "success",
                    title: "Usuario registrado con éxito!"
                })
                .then(()=>{
                    window.location.reload()
                })
            }
        }
    }

    render(){  
        if(this.state.loading){
            return <Loader />
        }else{
            if(this.state.isLogged){
                return(
                    <Link to={`/user?id=${auth.currentUser.uid}`} className='back-button-container'>
                        <button className='back-button'>
                            Ir a Mi perfil
                        </button>
                    </Link>
                )
            }else{
                return(
                    <div className="add-form-container">
                        <h1 className="title">Registrarse</h1>
                        <form className="form-container" onSubmit={this.onSubmit}>
                            <span className="input-add">
                                <p className="p-input">Usuario</p>
                                <input className="input-" id="input-user" type="text" />
                            </span>
                            <br />
                            <span className="input-add">
                                <p className="p-input">Correo electrónico</p>
                                <input className="input-" id="input-email" type="email" />
                            </span>
                            <br />
                            <span className="input-add">
                                <p className="p-input">Contraseña</p>
                                <input className="input-" id="input-pass" type="password" />
                            </span>
                            <br />
                            <button className="add-button" onClick={this.onSubmit} id="post-button">Crear</button>
                        </form>
                    </div>
                );
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    register
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Register);