import React from "react";

import './figuras.css';

import { connect } from "react-redux";
import { changePriceFigura, getFiguras } from "../../../redux/actions";

import Swal from 'sweetalert2'
import Loader from "../../loader";

class FigurasPriceChange extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            figuras: [],
            title: "",
            type: "mangas",
            old: null
        }
    }

    componentDidMount() {
        this.props.getFiguras()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const value = document.getElementById('get-figura-select').value
        const figuras = this.state.figuras
        let id = null
        let new_price = document.getElementById('input-new-price-figuras').value
        new_price = parseInt(new_price)
        for (let i = 0; i < figuras.length; i++) {
            if (figuras[i].title === value) {
                id = figuras[i].id
            }
        }

        const data = {
            id: id,
            new_price: new_price
        }

        this.props.changePriceFigura(data)
        document.getElementById('figuras-submit').disabled = true
        Swal.fire({
            title: "Actualizando precios...",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.status_price_f !== this.props.state.status_price_f) {
            if (this.props.state.status_price_f === "success") {
                Swal.fire({
                    icon: "success",
                    title: "Precio actualizados con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else if (this.props.state.status_price_f === "error") {
                Swal.fire({
                    icon: "error",
                    title: "Error al actualizar los precios",
                    text: this.props.state.status_price_f.msg
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }

        if (prevProps.state.figuras !== this.props.state.figuras) {
            this.setState({
                figuras: this.props.state.figuras
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    handleOld = () => {
        const value = document.getElementById('get-figura-select').value
        const figuras = this.state.figuras
        for (let i = 0; i < figuras.length; i++) {
            if (figuras[i].title === value) {
                this.setState({
                    old: figuras[i].precio
                })
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="change-main-container">
                    <form id="global" className="change-form" onSubmit={this.handleSubmit} style={this.state.global}>
                        <select id="get-figura-select" className="change-input-figura" onChange={this.handleOld}>
                            <option selected={true} disabled={true} key="123" className="change-option">
                                -
                            </option>
                            {
                                this.state.figuras.map((f) => {
                                    return (
                                        <option key={f.id} className="change-option" id={f.id}>
                                            {f.title}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="input-old" className="change-label">Precio actual</label>
                        <p className="change-input" id="input-old-figura">${this.state.old}</p>
                        <label htmlFor="input-new" className="change-label">Precio nuevo</label>
                        <input type="number" min="1" className="change-input" id="input-new-price-figuras" />
                        <button className="change-button" id="figuras-submit" type="submit">
                            Actualizar
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    changePriceFigura,
    getFiguras
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(FigurasPriceChange);