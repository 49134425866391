import React from "react";
import SearchResults from '../../components/searchResults/searchResults'

import './advancedSearch.css';

class AdvancedSearch extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            mangas: [],
            allMangas: [],
            pagina: -1,
            last: true,
            search: []
        }
    }

    componentDidMount(){
        this.setState({
            allMangas: this.props.mangas
        }, ()=>{
            document.getElementById("back").disabled = true;
            document.getElementById("next").disabled = true;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const nombre = document.getElementById("input-nombre").value.toLowerCase()
        const autor = document.getElementById("input-autor").value.toLowerCase()
        const editorial = document.getElementById("input-editorial").value.toLowerCase()
        const min_precio = document.getElementById("input-precio-min").value
        const max_precio = document.getElementById("input-precio-max").value
        const min_tomos = document.getElementById("input-min").value
        const max_tomos = document.getElementById("input-max").value
        const especial = document.getElementById("input-especial").checked
        const completa = document.getElementById("input-completa").checked

        let mangas = this.state.allMangas;

        if(nombre !== ""){
            mangas = mangas.filter((manga)=>{
                if(manga.serie.toLowerCase().includes(nombre)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(autor !== ""){
            mangas = mangas.filter((manga)=>{
                if(manga.guion.toLowerCase().includes(autor) || manga.dibujo.toLowerCase().includes(autor)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(editorial !== ""){
            mangas = mangas.filter((manga)=>{
                if(manga.editorial.toLowerCase().includes(editorial)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(min_precio !== ""){
            mangas = mangas.filter((manga)=>{
                if(parseInt(manga.precio) >= parseInt(min_precio)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(max_precio !== ""){
            mangas = mangas.filter((manga)=>{
                if(parseInt(manga.precio) <= parseInt(max_precio)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(min_tomos !== ""){
            mangas = mangas.filter((manga)=>{
                if(parseInt(manga.vol_amount) >= parseInt(min_tomos)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(max_tomos !== ""){
            mangas = mangas.filter((manga)=>{
                if(parseInt(manga.vol_amount) <= parseInt(max_tomos)){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(completa){
            mangas = mangas.filter((manga)=>{
                if(manga.completa){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(especial){
            mangas = mangas.filter((manga)=>{
                if(manga.special){
                    return manga
                }else{
                    return null
                }
            })
        }

        if(!especial){
            mangas = mangas.filter((manga)=>{
                if(manga.numero === 1){
                    return manga
                }else{
                    return null
                }
            })
        }

        this.setState({
            search: mangas
        }, ()=>{
            if(window.screen.width <= 450){
                setTimeout(() => {
                    window.scrollTo({
                        top: 2925,
                        behavior: "smooth"
                    })
                }, 50);

            }else{
                window.scrollTo({
                    top: 2850,
                    behavior: "smooth"
                })
            }
            this.setState({
                pagina: 0
            })
        })
        


    }

    setPages = (array, isLast) => {
        this.setState({
            mangas: array
        }, ()=>{

            if(this.state.pagina <= 0){
                document.getElementById("back").disabled = true;
            }else{
                document.getElementById("back").disabled = false;
            }

            if(isLast){
                document.getElementById("next").disabled = true;
            }else{
                document.getElementById("next").disabled = false;
            }

            if(array.length <= 5){
                document.getElementById("next").disabled = true;
            }
        })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.pagina !== this.state.pagina || prevState.search !== this.state.search){
            const paginado = []

            let isLast;

            if(window.screen.width <= 450){
                setTimeout(() => {
                    window.scrollTo({
                        top: 2925,
                        behavior: "smooth"
                    })
                }, 50);

            }else{
                window.scrollTo({
                    top: 2850,
                    behavior: "smooth"
                })
            }

            if(this.state.search.length === 0){
                return this.setPages([], true);
            }else if(this.state.search.length === 6){
                return this.setPages(this.state.search, true)
            }else{
                for(let i = 0; i < this.state.search.length; i++){
                    if(this.state.search[i + (6 * this.state.pagina)]  && paginado.length < 6){
                        paginado.push(this.state.search[i + (6 * this.state.pagina)])
                    }else{
                        isLast = true;
                        
                    }
    
                    if(isLast){
                        if(paginado.length <= 5){
                            this.setPages(paginado, true)
                        }
                    }else{
                        if(!this.state.search[6 * this.state.pagina + 1]){
                            this.setPages(paginado, true)
                        }else{
                            this.setPages(paginado, false)
                        }
                    }
        
                }
            }
    
            if(prevState.allMangas !== this.state.allMangas){
                document.getElementById("back").disabled = true;
                document.getElementById("next").disabled = true;
            }
        }
    

    }

    handlePage = (e) => {
        if(e.target.id === "back"){
            this.setState({
                pagina: this.state.pagina - 1,
                last: false
            })
        }else{
            this.setState({
                pagina: this.state.pagina + 1
            })
        }
    }
    
    render(){
        return(
            <div className="advanced-search-main-container">
                <span className="asearch-title-container">
                    <h1 className="asearch-title">
                        Búsqueda avanzada
                    </h1>
                </span>
                <div className="all-search-container">
                    <span className="asearch-form-container">
                        <form className="asearch-form" onSubmit={this.handleSubmit}>
                            <span className="asearch-input-container">
                                <span className="asearch-column">
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Nombre"
                                        id="input-nombre"
                                    />
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Autor"
                                        id="input-autor"
                                    />
                                    <input
                                        className="asearch-input"
                                        type="text"
                                        placeholder="Editorial"
                                        id="input-editorial"
                                    />
                                </span>
                                <span className="asearch-column">
                                    <label className="asearch-label">
                                        Cantidad de tomos
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-min"
                                            min="1"
                                            placeholder="Mínimo"
                                        />
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-max"
                                            placeholder="Máximo"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Precio
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-precio-min"
                                            min="1"
                                            placeholder="Mínimo"
                                        />
                                        <input
                                            className="asearch-input"
                                            type="number"
                                            id="input-precio-max"
                                            placeholder="Máximo"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Serie completa
                                        <input
                                            className="asearch-input"
                                            type="checkbox"
                                            id="input-completa"
                                        />
                                    </label>
                                    <label className="asearch-label">
                                        Edición especial
                                        <input
                                            className="asearch-input"
                                            type="checkbox"
                                            id="input-especial"
                                        />
                                    </label>
                                </span>
                            </span>
                            <button type="submit" className="asearch-button">
                                <p className="asearch-p">
                                    Buscar
                                </p>
                            </button>
                        </form>
                    </span>
                    <span className="asearch-results-container">
                        <SearchResults vols={this.state.mangas} />
                        <span className="results-page-container">
                            <button id="back" className="button-page" onClick={this.handlePage}>Anterior</button>
                            <button id="next" className="button-page" onClick={this.handlePage}>Siguiente</button>
                        </span>
                    </span>
                </div>
            </div>
        )
    }
}

export default AdvancedSearch;