const municipios = [
     "Tigre",
     "San Fernando",
     "San Isidro",
     "Vicente López",
     "Vicente Lopez",
     "San Martín",
     "San Martin",
     "Escobar",
     "Malvinas Argentinas",
     "José C Paz",
     "Jose C paz",
     "San Miguel",
     "Moreno",
     "Hurlingham",
     "Ituzaingó",
     "Ituzaingo",
     "Morón",
     "Moron",
     "Tres de Febrero",
     "Merlo",
     "La Matanza",
     "Ezeiza",
     "Esteban Echeverría",
     "Esteban Echeberria",
     "Lomas de Zamora",
     "Lanús",
     "Lanus",
     "Almirante Brown",
     "Avellaneda",
     "Quilmes",
     "Florencio Varela",
     "Berazategui"
]

export default municipios;