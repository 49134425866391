import React from "react";
import { connect } from 'react-redux';
import { postEditorial } from "../../redux/actions";
import { auth, storage } from '../../firebase';
import Loader from "../loader"
import { ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import BackDash from '../../components/backDash/backDash';

import '../add.css';
import Swal from "sweetalert2";

import tokens from "../../variables";

class AddEditorial extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            buttonUpload: true,
            progress: 0,
            imgURL: "",
            status: "",
            hasError: false,
            error: "",
            loading: true,
            isLogged: false,
            checked: false,
            editorial_info: {}
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);
    }

    uploadImage = (e)=>{

        e.preventDefault();

        const image = document.getElementById("input-cover").files[0];
        if(!image){
            Swal.fire({
                title: "Cargue una imágen válida!",
                icon: "warning",
                button: "Cerrar"
            })
            return 0;
        }
        const storageRef = ref(storage, `/covers/${image.name}`);
        const task = uploadBytesResumable(storageRef, image);

        task.on(
            "state_changed",
            (snapshot) => {
              const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    ...this.state,
                    progress: percentage
                })
            },
            (error) => {
              console.log(error.message);
            },
            () => {
                getDownloadURL(task.snapshot.ref).then((downloadURL)=>{
                    this.setState({
                        buttonUpload: false,
                        imgURL: downloadURL
                    })
                })
            }
          );
    }

    reset = ()=>{
        this.setState({
            hasError: false,
            error: ""
        });
    }

    controlInfo = ()=>{
        let nombre = document.getElementById("input-name");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        nombre.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(nombre.value === "" || !nombre.value){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${vacio}`,
                checked: true
            });
        }

        //CONTROLAR ESPACIOS
        if(nombre.value[0] === " "){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${espacio}`,
                checked: true
            })
        }

        return this.setState({
            checked: true
        })

    }

    onSubmit = (e)=>{
        e.preventDefault();

        e.target.disabled = true;

        const editorial_info = {
            nombre: document.getElementById("input-name").value,
            imgURL: this.state.imgURL
        }

        this.setState({
            editorial_info: editorial_info
        }, ()=>{
            this.controlInfo(editorial_info)
        })
    }

    componentDidUpdate(prevProps, prevState){

        if(auth.currentUser && !this.state.isLogged){
            if(auth.currentUser.uid === tokens.admin){
                this.setState({
                    isLogged: true
                })
            }else{
                window.location.href = '/';
            }
        }else if(!auth.currentUser && this.state.isLogged){
            this.setState({
                isLogged: false
            })
        }else if(!auth.currentUser && !this.state.isLogged){
            window.location.href = '/';
        }

        if(this.state.status !== ""){
            alert(this.state.status);

            document.getElementById("input-name").value = "";
            this.setState({
                ...this.state,
                buttonUpload: true,
                progress: 0
            }, ()=>{
                document.getElementsByClassName("add-button").disabled = false;
            })
        }

        if(prevState.loading !== this.state.loading){
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                buttons[i].disabled = false;
            }
        }

        if(prevState.checked !== this.state.checked & this.state.checked){
            if(this.state.hasError){
                Swal.fire({
                        title: "Error!",
                        text: this.state.error,
                        icon: "error",
                        button: "Volver"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
            }else{  
                this.props.postEditorial(this.state.editorial_info)
            }
        }

        if(prevProps.state !== this.props.state){
            if(this.props.state.post_state !== "success" && this.props.state.post_state !== ""){
                Swal.fire({
                    title: this.props.state.post_state,
                    icon: "warning",
                    button: "Cerrar"
                }).then(()=>{
                    window.location.reload()
                })
            }else if(this.props.state.post_state === "success"){
                Swal.fire({
                    title: "Agregada!",
                    text: `Editorial ${this.state.editorial_info.nombre} agregada exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                })
                .then(()=>{
                    window.location.reload()
                })
            }else if(this.props.state.post_state.error){
                Swal.fire({
                    title: this.props.state.post_state.error.detail,
                    text: this.props.state.post_state.error.code,
                    icon: "error"
                })
                .then(()=>{
                    window.location.reload()
                })
            }
        }

    }

    render(){
        if(this.state.loading || !this.state.isLogged){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir editorial</h1>
                    <span className="form-container">
                        <span className="input-add">
                            <p className="p-input">Nombre</p>
                            <input className="input-" id="input-name" type="text" />
                        </span>
                        <br />
                        <br />
                        <span className="input-add">
                            <p className="p-input">Logo</p>
                            <input id="input-cover" type="file" accept="image/*" />
                        </span>
                        <br />
                            <progress className="input-add" value={this.state.progress} />
                            <br />
                            <button className="input-add" id="upload" onClick={this.uploadImage}>Cargar imagen</button>
                            <br />
                            <button className="add-button" onClick={this.onSubmit} disabled={this.state.buttonUpload} id="add-button">Crear</button>
                    </span>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postEditorial
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(AddEditorial);