import React from 'react'

import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import { auth } from '../../firebase';
import Swal from 'sweetalert2'

import { Link } from 'react-router-dom';
import Loader from '../loader';

class Login extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isLogged: false,
            loading: true
        }
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);

    }

    onSubmit = (e) => {
        e.preventDefault();

        const email = document.getElementById("input-mail").value;
        const pass = document.getElementById("input-pass").value;

        setPersistence(auth, browserLocalPersistence)
        .then(()=>{

            document.getElementById("input-mail").disabled = true;
            document.getElementById("input-pass").disabled = true;
            document.getElementById("post-button").disabled = true;

            return signInWithEmailAndPassword(auth, email, pass)
            .then(()=>{
                this.setState({
                    isLogged: true
                }, ()=>{
                    Swal.fire({
                        icon: "success",
                        title: "Sesión iniciada correctamente"
                    })
                })
            })
            .catch((error)=>{
                if(error.message === "Firebase: Error (auth/user-not-found)."){
                    Swal.fire({
                        title: "Error",
                        text: "Usuario no encontrado!",
                        icon: "error"
                    })
                }else if(error.message === "Firebase: Error (auth/invalid-email)."){
                    Swal.fire({
                        title: "Error",
                        text: "Ingrese un mail válido!",
                        icon: "error"
                    })
                }else if(error.message === "Firebase: Error (auth/wrong-password)."){
                    Swal.fire({
                        title: "Error",
                        text: "Contraseña incorrecta!",
                        icon: "error"
                    })
                }else{
                    Swal.fire({
                        title: "Error",
                        text: error.message,
                        icon: "error"
                    })
                }
                
                document.getElementById("input-mail").disabled = false;
                document.getElementById("input-pass").disabled = false;
                document.getElementById("post-button").disabled = false;
                
            })
        })
    }

    componentDidUpdate(prevProps, prevState){
        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                this.setState({
                    isLogged: true
                })
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }
        })
    }

    redirect = () => {
        if(auth.currentUser){
            window.location.hash = `/user?id=${auth.currentUser.uid}`
        }else{
            window.location.hash = "/"
        }
    }

    
    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            if(!this.state.isLogged){
                return(
                    <form className="add-form-container" onSubmit={this.onSubmit}>
                        <h1 className="title">Iniciar sesión</h1>
                        <span className="form-container">
                            <span className="input-add">
                                <p className="p-input">Correo electrónico</p>
                                <input className="input-" id="input-mail" type="email" />
                            </span>
                            <br />
                            <span className="input-add">
                                <p className="p-input">Contraseña</p>
                                <input className="input-" id="input-pass" type="password" />
                            </span>
                            <br />
                            <button className="add-button" onClick={this.onSubmit} id="post-button">Entrar</button>
                        </span>
                    </form>
                );
            }else{
                return this.redirect()
            }    
        }
    }
}


export default Login;