import React from "react";

import { connect } from "react-redux";
import Card from "../../components/card/card";

import { getNews } from "../../redux/actions";
import Loader from "../loader";

import "./news.css"

class News extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            news: [],
            allNews: [],
            editoriales: [],
            get: false
        }
    }

    componentDidMount(){
        if(!this.state.get){
            this.setState({
                get: true
            }, ()=>{
                this.props.getNews();
            })
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.setState({
                news: this.props.state.news,
                allNews: this.props.state.news
            }, ()=>{
                this.state.news.forEach((e)=>{
                    if(this.state.editoriales.length === 0){
                        this.state.editoriales.push(e.editorial)
                    }else{
                        let exist = false;
                        for(let i = 0; i < this.state.editoriales.length; i++){
                            if(e.editorial === this.state.editoriales[i]){
                                exist = true
                            }
                        }
                        if(!exist){
                            this.state.editoriales.push(e.editorial)
                        }
                    }
                })
                this.setState({
                    loading: false
                })
            })
        }
    }

    mangaFilter = (param) => {
        let filtered = [];

        for(let i = 0; i < this.state.allNews.length; i++){
            if(this.state.allNews[i].editorial === param){
                filtered.push(this.state.allNews[i]);
            }
        }

        this.setState({
            news: filtered
        })
    }

    filter = (e) => {
        e.preventDefault();

        let active = false;

        if(e.target.style.backgroundColor === "rgb(55, 130, 175)"){
            active = true;
        }

        let buttons = document.getElementsByClassName("input-filter");
        for(let i = 0; i < buttons.length; i++){
            buttons[i].style.backgroundColor = "white";
        }

        if(!active){
            e.target.style.backgroundColor = "rgb(55, 130, 175)"
            this.mangaFilter(`${e.target.id}`)
        }else{
            this.setState({
                news: this.state.allNews
            })
        }


    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="search-main-container">
                    <div className="filter-container">
                        <span className="filters">
                            {
                                this.state.editoriales.map((ed)=>{
                                    return(
                                        <button id={ed} className="input-filter" onClick={this.filter}>
                                            {ed}
                                        </button>
                                    )
                                })
                            }
                        </span>
                        <span className="cant-search">
                            <p className="p-cant">
                                {
                                    `NOVEDADES DE LA SEMANA: ${this.state.news.length}`
                                }
                            </p>
                        </span>
                    </div>
                    <span className="search-grid">
                        {
                            this.state.news.map((vol)=>{
                                return(
                                    <Card vol={vol} key={`${vol.id}`} />
                                )
                            })
                        }
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getNews
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(News);