import React from "react";
import { connect } from 'react-redux';
import { postCollection, getEditoriales } from "../../redux/actions";
import Swal from "sweetalert2";
import Loader from "../loader"
import BackDash from '../../components/backDash/backDash';

import '../add.css';
import tokens from "../../variables";
import { auth } from "../../firebase";

class AddCollection extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            hasError: false,
            error: "",
            serie: {},
            loading: true,
            checked: false,
            isLogged: false
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);
    }

    reset = ()=>{
        this.setState({
            hasError: false,
            error: "",
            checked: false
        })
    }

    controlInfo = ()=>{
        let nombre = document.getElementById("input-name");
        let editorial = document.getElementById("input-editorial");
        let editorial_origen = document.getElementById("input-origen");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        nombre.style = "border-color: none";
        editorial.style = "border-color: none";
        editorial_origen.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(nombre.value === "" || !nombre.value){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${vacio}`,
                checked: true
            });
        }


        if(editorial.value === "" || !editorial.value){
            editorial.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Editorial" ${vacio}`,
                checked: true
            });
        }

        if(editorial_origen.value === "" || !editorial_origen.value){
            editorial_origen.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Editorial Origen" ${vacio}`,
                checked: true
            });
        }

        //CONTROLAR ESPACIOS
        if(nombre.value[0] === " "){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${espacio}`,
                checked: true
            })
        }

        if(editorial_origen.value[0] === " "){
            editorial_origen.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Editorial origen" ${espacio}`,
                checked: true
            })
        }

        return this.setState({
            checked: true
        })

    }

    onSubmit = (e)=>{
        e.preventDefault();
        e.target.disabled = true;

        const serie_info = {
            nombre: document.getElementById("input-name").value,
            editorial: document.getElementById("input-editorial").value,
            editorial_origen: document.getElementById("input-origen").value
        }

        this.setState({
            serie: serie_info
        }, ()=>{
            this.controlInfo();
        })
    }
    
    componentDidUpdate(prevProps, prevState){

        if(prevProps.state.editoriales !== this.props.state.editoriales){
            this.setState({
                editoriales: this.props.state.editoriales
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                if(auth.currentUser.uid === tokens.admin){
                    this.setState({
                        isLogged: true,
                        loading: true
                    }, ()=>{
                        this.props.getEditoriales()
                    })
                }else{
                    window.location.href = '/';
                }
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }else if(!auth.currentUser && !this.state.isLogged){
                window.location.href = '/';
            }
        })

        if(prevState.isLogged !== this.state.isLogged){
            if(!this.state.isLogged){
                window.location.href = '/';
            }
        }

        if(prevState.checked !== this.state.checked && this.state.checked){
            if(this.state.hasError){
                Swal.fire({
                        title: "Error!",
                        text: this.state.error,
                        icon: "error",
                        button: "Volver"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
    
                    document.getElementById("post_button").disabled = false;
    
            }else if(this.state.serie){  
                this.props.postCollection(this.state.serie);
            }
        }

        if(prevProps.state.post_state !== this.props.state.post_state){

            if(this.props.state.post_state !== "success"){
                if(this.props.state.post_state.error){
                    Swal.fire({
                        title: this.props.state.post_state.error.detail,
                        text: this.props.state.post_state.error.code,
                        icon: "error"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: this.props.state.post_state,
                        icon: "warning",
                        button: "Cerrar"
                    }).then(()=>{
                        window.location.reload()
                    })
                }

            }else{

                if(document.getElementById("input-editorial")){
                    document.getElementById("input-name").value = "";
                    document.getElementById("input-origen").value = "";
                    document.getElementById("input-editorial").value = "";
                }

                Swal.fire({
                    title: "Agregada!",
                    text: `${this.state.serie.nombre} agregada exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                }).then(()=>{
                    window.location.reload()
                })         

            }
        }
    }

    render(){
        if(this.state.loading || !this.state.isLogged){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir colección (cómic)</h1>
                    <span className="form-container">
                        <span className="input-add">
                            <p className="p-input">Nombre</p>
                            <input className="input-" id="input-name" type="text" autoComplete="false" />
                        </span>
                        <span className="input-add">
                            <p className="p-input">Editorial</p>
                            <select className="input-" id="input-editorial">
                                {
                                    this.props.state.editoriales.map((editorial)=>{
                                        return(
                                            <option key={editorial.nombre}>
                                                {editorial.nombre}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </span>
                        <span className="input-add">
                            <p className="p-input">Editorial origen</p>
                            <input className="input-" id="input-origen" type="text" autoComplete="false" />
                        </span>
                        <br />
                        <button className="add-button" onClick={this.onSubmit} id="post_button">Crear</button>
                    </span>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postCollection,
    getEditoriales
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(AddCollection);