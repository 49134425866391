import React from "react";
import './navbar.css';
import logo from '../../imgs/logo.jpg';
import { Link } from 'react-router-dom';

import { RiShoppingCart2Fill } from "react-icons/ri";
import { CgMoreO, CgCloseO } from "react-icons/cg";

import { auth } from "../../firebase";

import { connect } from "react-redux";
import { getUser } from "../../redux/actions";
import { signOut } from "firebase/auth";
import tokens from "../../variables";
import Swal from "sweetalert2";
import Loader from "../../pages/loader";

import Reporter from '../../pages/home/reporter/reporter'

class Navbar extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            profileInfo: "none",
            loading: true,
            closed: true,
            user: {},
            isLogged: false,
            url: "",
            changed: false
        }
    }

    componentDidMount(){
        setTimeout(() => {
            if(this.state.loading){
                this.setState({
                    loading: false
                })
            }

            const url = window.location.href

            this.setState({
                url: url
            })
    
            if(window.screen.width > 850){
                this.setState({
                    profileInfo: "flex",
                    loading: false
                }, ()=>{
                    window.scroll({
                        top: 0,
                        left: 0
                        });
                })
            }else{
                this.setState({
                    loading: false
                }, ()=>{
                    window.scroll({
                        top: 0,
                        left: 0
                        });
                })
            }
        }, 1500);
    }

    showButton = () => {
        if(window.screen.width > 850){
            return {"display": "none"}
        }else{
            if(this.state.closed){
                return {"display": "flex"}
            }else{
                return {"display": "none"}
            }
        }
    }

    showMenu = () => {
        if(window.screen.width > 615){
            return {"display": "none"}
        }else{
            if(this.state.closed){
                return {"display": "grid"}
            }else{
                return {"display": "none"}
            }
        }
    }

    closeButton = () => {
        if(window.screen.width > 850){
            return {"display": "none"}
        }else{
            if(this.state.closed){
                return {"display": "none"}
            }else{
                return {"display": "flex"}
            }
        }
    }

    changeInfo = (e) => {
        e.preventDefault();

        if(this.state.profileInfo === "none"){
            this.setState({
                ...this.state,
                profileInfo: "flex",
                closed: !this.state.closed
            })
        }else{
            this.setState({
                ...this.state,
                profileInfo: "none",
                closed: !this.state.closed
            })
        }
    }

    getProfileInfo = () => {
        return {"display": this.state.profileInfo}
    }

    handleSearch = (e) => {
        e.preventDefault()
        let search = document.getElementById("input-search").value.toLowerCase();

        window.location.hash = `/search/bytitle?name=${search}`;
    }

    logout = (e) => {
        e.preventDefault();

        signOut(auth)
        .then(()=>{
            Swal.fire({
                title: "Sesión cerrada correctamente"
            })
        })
    }

    resize = () => {
        if(window.screen < 850){
            window.location.reload()
        }
    }

    componentDidUpdate(prevProps){
        window.addEventListener("resize", this.resize);

        if(prevProps.state.user !== this.props.state.user){
            this.setState({
                user: this.props.state.user
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    getCart = () => {
        if(auth.currentUser){
            if(this.state.user.cart){
                return `${this.state.user.cart.length}`
            }
        }else{
            const storage = JSON.parse(localStorage.getItem('cart'));
            let cart;
            if(storage){
                cart = storage.items.length;
            }else{
                cart = 0;
            }
            return `${cart}`
        }
    }

    isAdmin = () => {
        if(auth.currentUser.uid === tokens.admin){
            return (
                <Link className="nav-b-c" to="/dashboard">
                    <button className="goto-profile-button">
                        Dashboard
                    </button>
                </Link>
            )
        }
    }

    render(){

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                this.setState({
                    loading: true,
                    isLogged: true
                }, ()=>{
                    this.props.getUser(auth.currentUser.uid)
                })
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }
        })

        if(this.state.loading){
            return <Loader />
        }else{
            if(auth.currentUser){
                return(
                    <div className="main-container">
                        <div className="promo-container">
                            <p className="promo">10% DE DESCUENTO CON CUALQUIER MÉTODO DE PAGO</p>
                        </div>
                        <div className="nav-container">
                            <span className="nav-img-container">
                                <Link to="/">
                                    <img src={logo} alt="logo.jpg" className="nav-img" />
                                </Link>
                            </span>
                            <span className="search-container">
                                <form className="search" onSubmit={this.handleSearch}>
                                    <input className="input-search" id="input-search" type="text" placeholder="Buscar por título o autor..." />
                                    <button type="submit" className="search-button">
                                        Buscar
                                    </button>
                                </form>
                                <span className="nav-button-container">
                                    <Link to="/search/mangas">
                                        <button className="nav-button" id="mangas-nav-button">
                                            Mangas
                                        </button>
                                    </Link>
                                    <Link to="/search/comics">
                                        <button className="nav-button" id="comics-nav-button">
                                            Cómics
                                        </button>
                                    </Link>
                                    <Link to="/search/libros">
                                        <button className="nav-button" id="libros-nav-button">Libros</button>
                                    </Link>
                                    <Link to="/search/figuras">
                                        <button className="nav-button" id="contacto-nav-button">Figuras</button>
                                    </Link>
                                </span>
                            </span>
                            <div className="login-main-container">
                                <span className="phone-buttons">
                                    <CgMoreO className="show-button" style={this.showButton()} onClick={this.changeInfo} />
                                    <CgCloseO className="show-button" id="closed" style={this.closeButton()} onClick={this.changeInfo} />
                                </span>
                                <span className="login-container" style={this.getProfileInfo()}>
                                    <Link className="nav-b-c" to={`/user?id=${auth.currentUser.uid}`}>
                                        <button className="goto-profile-button">Mi perfil</button>
                                    </Link>
                                    {this.isAdmin()}
                                    <span className="nav-cart-container">
                                        <span className="cant-cart-container">
                                            <p className="cant-cart">
                                                {this.getCart()}
                                            </p>
                                        </span>
                                        <button onClick={()=>{
                                            window.location.href = "/#/micarrito"
                                        }} className="user-icon">
                                            <RiShoppingCart2Fill/>
                                        </button>
                                    </span>
                                    <button onClick={this.logout} className="logout-nav">Cerrar sesión</button>
                                </span>
                            </div>
                            <span className="phone-menu" style={this.showMenu()}>
                                <Link className="pl" to="/search/mangas">
                                    <button className="nav-button-phone" id="mangas-nav-button-p">
                                        Mangas
                                    </button>
                                </Link>
                                <Link className="pl" to="/search/comics">
                                    <button className="nav-button-phone" id="comics-nav-button-p">
                                        Cómics
                                    </button>
                                </Link>
                                <Link className="pl" to="/search/libros">
                                    <button className="nav-button-phone" id="libros-nav-button">Libros</button>
                                </Link>
                                <Link className="pl" to="/search/figuras">
                                    <button className="nav-button-phone" id="contacto-nav-button-p">Figuras</button>
                                </Link>
                            </span>
                        </div>
                        <Reporter />
                        <Link to="/nos/contacto" className="contact-float-button">Contacto</Link>
                    </div>
                )
            }else{
                return(
                    <div className="main-container">
                        <div className="promo-container">
                            <p className="promo">10% DE DESCUENTO CON CUALQUIER MÉTODO DE PAGO</p>
                        </div>
                        <div className="nav-container">
                            <span className="nav-img-container">
                                <Link to="/">
                                    <img src={logo} alt="logo.jpg" className="nav-img" />
                                </Link>
                            </span>
                            <span className="search-container">
                                <form className="search" onSubmit={this.handleSearch}>
                                    <input className="input-search" id="input-search" type="text" placeholder="Buscar por título o autor..." />
                                    <button type="submit" className="search-button">
                                        Buscar
                                    </button>
                                </form>
                                <span className="nav-button-container">
                                    <Link to="/search/mangas"><button className="nav-button" id="mangas-nav-button">Mangas</button></Link>
                                    <Link to="/search/comics">
                                        <button className="nav-button" id="comics-nav-button">Cómics</button>
                                    </Link>
                                    <Link to="/search/libros">
                                        <button className="nav-button" id="libros-nav-button">Libros</button>
                                    </Link>
                                    <Link to="/search/figuras">
                                        <button className="nav-button" id="contacto-nav-button">Figuras</button>
                                    </Link>
                                </span>
                            </span>
                            <div className="login-main-container">
                                <span className="phone-buttons">
                                    <CgMoreO className="show-button" style={this.showButton()} onClick={this.changeInfo} />
                                    <CgCloseO className="show-button" id="closed" style={this.closeButton()} onClick={this.changeInfo} />
                                </span>
                                <span className="login-container" style={this.getProfileInfo()}>
                                    <Link to="/user/login" className="button-link">
                                        <button className="to-login" id="login">Iniciar sesión</button>
                                    </Link>
                                    <Link to="/user/register" className="button-link">
                                        <button className="to-register" id="register">Registrarse</button>
                                    </Link>
                                    <span className="nav-cart-container" id="no-login">
                                        <span className="cant-cart-container">
                                            <p className="cant-cart">
                                                {this.getCart()}
                                            </p>
                                        </span>
                                        <Link to={`/micarrito`} className="user-icon">
                                            <RiShoppingCart2Fill/>
                                        </Link>
                                    </span>
                                </span>
                                <span className="phone-menu" style={this.showMenu()}>
                                    <Link className="pl" to="/search/mangas">
                                        <button className="nav-button-phone" id="mangas-nav-button-p">
                                            Mangas
                                        </button>
                                    </Link>
                                    <Link className="pl" to="/search/comics">
                                        <button className="nav-button-phone" id="comics-nav-button-p">
                                            Cómics
                                        </button>
                                    </Link>
                                    <Link className="pl" to="/search/libros">
                                        <button className="nav-button-phone" id="libros-nav-button-p">Libros</button>
                                    </Link>
                                    <Link className="pl" to="/search/figuras">
                                        <button className="nav-button-phone" id="contacto-nav-button-p">Figuras</button>
                                    </Link>
                                </span>
                            </div>
                        </div>
                        <Reporter />
                        <Link to="/nos/contacto" className="contact-float-button">Contacto</Link>
                    </div>
                );
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getUser
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Navbar);