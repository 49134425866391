function vacio(p){
    for(let i = 0; i < p.length; i++){
        if(p[i] !== " "){
            return false
        }
    }

    return true;
}

export function controlShipping(prov, loc, cp, calle, n, dpto, nombre, email, dni){

    let obj = {
        error: {
            has: false,
            messeage: ""
        },
        shipping: {}
    }

    //CONTROLAR CP
    if(cp.length === 0 || vacio(cp)){
        obj.error.has = true;
        obj.error.messeage = "El CP no puede estar en blanco!"
        return obj;
    }


    //CONTROLAR CALLE
    if(calle.length === 0 || vacio(calle)){
        obj.error.has = true;
        obj.error.messeage = "La calle no puede estar en blanco!"
        return obj;
    }

    //CONTROLAR N
    if(n.length === 0 || vacio(n)){
        obj.error.has = true;
        obj.error.messeage = "El número no puede estar en blanco!"
        return obj;
    }

    //CONTROLAR DNI
    if(dni.length === 0 || vacio(dni)){
        obj.error.has = true;
        obj.error.messeage = "El DNI del destinatario no puede estar en blanco!"
        return obj;
    }else if(dni.length < 8 || dni.length > 10){
        obj.error.has = true;
        obj.error.messeage = "Ingrese un DNI válido!"
        return obj;
    }

    //CONTROLAR EMAIL
    if(email.length === 0 || vacio(email)){
        obj.error.has = true;
        obj.error.messeage = "El email del destinatario no puede estar en blanco!"
        return obj;
    }

    //CONTROLAR NOMBRE
    if(nombre.length === 0 || vacio(nombre)){
        obj.error.has = true;
        obj.error.messeage = "El nombre del destinatario no puede estar en blanco!"
        return obj;
    }

    obj.shipping = {
        prov: prov,
        loc: loc,
        cp: cp,
        calle: calle,
        n: n,
        dpto: dpto,
        nombre: nombre,
        email: email,
        dni: dni
    }

    return obj;
}

export function controlPayment(tarjeta, venc, titular, email, cod, dni){
    
}