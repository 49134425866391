import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getNews } from "../../../redux/actions";

import Card from "./card/card";

import "./news.css";

class News extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            news: [],
            new_n: 0,
            get: false
        }
    }

    componentDidMount(){
        if(!this.state.get){
            this.setState({
                get: true
            }, ()=>{
                this.props.getNews()
            })
        }
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){

            const news = this.props.state.news;

            console.log("NOVEDADES: ", news)

            news.sort((a, b) => b.ventas - a.ventas);

            this.setState({
                news: news
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    getNewN = (n) => {
        if(n === 1){
            return this.state.new_n;
        }else if(n === 2){
            if(this.state.new_n >= 0 && this.state.new_n < this.state.news.length - 2){
                return this.state.new_n + 1;
            }else if(this.state.new_n === this.state.news.length - 2){
                return this.state.news.length - 1;
            }else if(this.state.new_n === this.state.news.length - 1){
                return 0;
            }
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        if(e.target.id === "izq"){
            if(window.screen.width < 450){
                e.target.disabled = true;
    
                document.getElementsByClassName("card-container-a")[0].animate([
                    { transform: 'translatex(-100%)'}
                ], {
                    duration: 350,
                    iterations: 1
                })
    
                document.getElementsByClassName("card-container-b")[0].animate([
                    { transform: 'translatex(-100%) scale(0)'}
                ], {
                    duration: 350,
                    iterations: 1
                })
    
                setTimeout(() => {
                    if(this.state.new_n >= 0 && this.state.new_n < this.state.news.length - 1){
                        this.setState({
                            new_n: this.state.new_n + 1
                        }, () => {
                            document.getElementsByClassName("card-container-a")[0].animate([
                                { transform: 'scale(0.5)'},
                                { transform: 'scale(1)'}
                            ], {
                                duration: 250,
                                iterations: 1
                            })
                        })
                    }else if(this.state.new_n === this.state.news.length - 1){
                        this.setState({
                            new_n: 0
                        }, () => {
                            document.getElementsByClassName("card-container-a")[0].animate([
                                { transform: 'scale(0.5)'},
                                { transform: 'scale(1)'}
                            ], {
                                duration: 250,
                                iterations: 1
                            })
                        })
                    }
                }, 350);
    
                setTimeout(() => {
                    e.target.disabled = false;
                }, 600);
            }else{
                e.target.disabled = true;
    
                document.getElementsByClassName("card-container-b")[0].animate([
                    { transform: 'translatex(-100%)'}
                ], {
                    duration: 350,
                    iterations: 1
                })
    
                document.getElementsByClassName("card-container-a")[0].animate([
                    { transform: 'translatex(-100%) scale(0)'}
                ], {
                    duration: 350,
                    iterations: 1
                })
    
                setTimeout(() => {
                    if(this.state.new_n >= 0 && this.state.new_n < this.state.news.length - 1){
                        this.setState({
                            new_n: this.state.new_n + 1
                        }, () => {
                            document.getElementsByClassName("card-container-b")[0].animate([
                                { transform: 'scale(0.5)'},
                                { transform: 'scale(1)'}
                            ], {
                                duration: 250,
                                iterations: 1
                            })
                        })
                    }else if(this.state.new_n === this.state.news.length - 1){
                        this.setState({
                            new_n: 0
                        }, () => {
                            document.getElementsByClassName("card-container-b")[0].animate([
                                { transform: 'scale(0.5)'},
                                { transform: 'scale(1)'}
                            ], {
                                duration: 250,
                                iterations: 1
                            })
                        })
                    }
                }, 350);
    
                setTimeout(() => {
                    e.target.disabled = false;
                }, 600);
            }


        }else{

            e.target.disabled = true;

            document.getElementsByClassName("card-container-a")[0].animate([
                { transform: 'translatex(100%)'}
            ], {
                duration: 350,
                iterations: 1
            })

            document.getElementsByClassName("card-container-b")[0].animate([
                { transform: 'translatex(100%) scale(0)'}
            ], {
                duration: 350,
                iterations: 1
            })

            setTimeout(() => {
                if(this.state.new_n === 0){
                    this.setState({
                        new_n: this.state.news.length - 1
                    }, ()=>{
                        document.getElementsByClassName("card-container-a")[0].animate([
                            { transform: 'scale(0.5)'},
                            { transform: 'scale(1)'}
                        ], {
                            duration: 250,
                            iterations: 1
                        })
                    })
                }else if(this.state.new_n <= this.state.news.length - 1){
                    this.setState({
                        new_n: this.state.new_n - 1
                    }, ()=>{
                        document.getElementsByClassName("card-container-a")[0].animate([
                            { transform: 'scale(0.5)'},
                            { transform: 'scale(1)'}
                        ], {
                            duration: 250,
                            iterations: 1
                        })
                    })                    
                }
            }, 350);

            setTimeout(() => {
                e.target.disabled = false;
            }, 600);

        }

    }

    render(){
        if(this.state.loading){
            return(
                <div className="loader-container">
                    <span className="loader" />
                </div>
            )
        }else{
            if(this.state.news.length === 0){
                return(
                    <div>
                        No hay novedades
                    </div>
                )
            }else if(this.state.news.length === 1){
                return(
                    <div className="news-main-container">
                        <div className="news-title-container">
                            <span className="news-title-bar" id="bar-i" />
                            <span className="news-title">
                                <h1 className="news-title-h">
                                    NOVEDADES
                                </h1>
                            </span>
                            <span className="news-title-bar" id="bar-d" />
                        </div>
                        <span className="news-card-grid">
                            <span className="card-container-a">
                                <Card vol={this.state.news[this.getNewN(1)]}  key={`${this.state.news[this.getNewN(1)].serie}-${this.state.news[this.getNewN(1)].numero}`} />
                            </span>                        
                        </span>
                        <span className="more-news-container">
                            <Link to="/novedades" className="more-news-link">
                                <button className="more-news-button">
                                    <p className="more-news-p-button">
                                        Ver todas
                                    </p>
                                </button>
                            </Link>
                        </span>
                    </div>
                )
            }else if(this.state.news.length === 2){
                if(window.screen.width < 450){
                    return(
                        <div className="news-main-container">
                            <div className="news-title-container">
                                <span className="news-title-bar" id="bar-i" />
                                <span className="news-title">
                                    <h1 className="news-title-h">
                                        NOVEDADES
                                    </h1>
                                </span>
                                <span className="news-title-bar" id="bar-d" />
                            </div>
                            <span className="news-card-grid">
                                <button className="news-grid-button" id="izq" onClick={this.handleChange}>{"<"}</button>
                                <span className="card-container-a">
                                    <Card vol={this.state.news[this.getNewN(1)]}  key={`${this.state.news[this.getNewN(1)].serie}-${this.state.news[this.getNewN(1)].numero}`} />
                                </span>
                                <span className="card-container-b">
                                    <Card vol={this.state.news[this.getNewN(2)]}  key={`${this.state.news[this.getNewN(2)].serie}-${this.state.news[this.getNewN(2)].numero}`} />
                                </span>
                                <button className="news-grid-button" id="der" onClick={this.handleChange}>{">"}</button>
                            </span>
                            <span className="more-news-container">
                                <Link to="/novedades" className="more-news-link">
                                    <button className="more-news-button">
                                        <p className="more-news-p-button">
                                            Ver todas
                                        </p>
                                    </button>
                                </Link>
                            </span>
                        </div>
                    )
                }else{
                    return(
                        <div className="news-main-container">
                            <div className="news-title-container">
                                <span className="news-title-bar" id="bar-i" />
                                <span className="news-title">
                                    <h1 className="news-title-h">
                                        NOVEDADES
                                    </h1>
                                </span>
                                <span className="news-title-bar" id="bar-d" />
                            </div>
                            <span className="news-card-grid">
                                <span className="card-container-a">
                                    <Card vol={this.state.news[this.getNewN(1)]}  key={`${this.state.news[this.getNewN(1)].serie}-${this.state.news[this.getNewN(1)].numero}`} />
                                </span>
                                <span className="card-container-b">
                                    <Card vol={this.state.news[this.getNewN(2)]}  key={`${this.state.news[this.getNewN(2)].serie}-${this.state.news[this.getNewN(2)].numero}`} />
                                </span>
                            </span>
                            <span className="more-news-container">
                                <Link to="/novedades" className="more-news-link">
                                    <button className="more-news-button">
                                        <p className="more-news-p-button">
                                            Ver todas
                                        </p>
                                    </button>
                                </Link>
                            </span>
                        </div>
                    )
                }
            }else{
                return(
                    <div className="news-main-container">
                        <div className="news-title-container">
                            <span className="news-title-bar" id="bar-i" />
                            <span className="news-title">
                                <h1 className="news-title-h">
                                    NOVEDADES
                                </h1>
                            </span>
                            <span className="news-title-bar" id="bar-d" />
                        </div>
                        <span className="news-card-grid">
                            <button className="news-grid-button" id="izq" onClick={this.handleChange}>{"<"}</button>
                            <span className="card-container-a">
                                <Card vol={this.state.news[this.getNewN(1)]}  key={`${this.state.news[this.getNewN(1)].serie}-${this.state.news[this.getNewN(1)].numero}`} />
                            </span>
                            <span className="card-container-b">
                                <Card vol={this.state.news[this.getNewN(2)]}  key={`${this.state.news[this.getNewN(2)].serie}-${this.state.news[this.getNewN(2)].numero}`} />
                            </span>
                            <button className="news-grid-button" id="der" onClick={this.handleChange}>{">"}</button>
                        </span>
                        <span className="more-news-container">
                            <Link to="/novedades" className="more-news-link">
                                <button className="more-news-button">
                                    <p className="more-news-p-button">
                                        Ver todas
                                    </p>
                                </button>
                            </Link>
                        </span>
                    </div>
                )
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getNews
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(News);