import React from "react";

import './figuras.css';

import { connect } from "react-redux";
import { changeStockFigura, getFiguras } from "../../../redux/actions";

import Swal from 'sweetalert2'
import Loader from "../../loader";

class FigurasStockChange extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            figuras: [],
            title: "",
            type: "mangas"
        }
    }

    componentDidMount() {
        this.setState({
            figuras: this.props.figuras
        }, ()=>{
            this.setState({
                loading: false
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const value = document.getElementById('get-figura-select-stock').value
        const figuras = this.state.figuras
        let id = null
        let new_stock = document.getElementById('input-new-stock-figuras').value
        new_stock = parseInt(new_stock)
        for (let i = 0; i < figuras.length; i++) {
            if (figuras[i].title === value) {
                id = figuras[i].id
            }
        }

        const data = {
            id: id,
            new_stock: new_stock
        }

        this.props.changeStockFigura(data)
        document.getElementById('figuras-submit').disabled = true
        Swal.fire({
            title: "Actualizando stock...",
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps.state.status_stock_f !== this.props.state.status_stock_f) {
            if (this.props.state.status_stock_f === "success") {
                Swal.fire({
                    icon: "success",
                    title: "Stock actualizados con éxito!"
                })
                    .then(() => {
                        window.location.reload()
                    })
            } else if (this.props.state.status_stock_f === "error") {
                Swal.fire({
                    icon: "error",
                    title: "Error al actualizar los stock",
                    text: this.props.state.status.msg
                })
                    .then(() => {
                        window.location.reload()
                    })
            }
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader />
        } else {
            return (
                <div className="change-main-container">
                    <form id="global" className="change-form" onSubmit={this.handleSubmit} style={this.state.global}>
                        <select id="get-figura-select-stock" className="change-input-figura">
                            <option selected={true} disabled={true} key="123" className="change-option">
                                -
                            </option>
                            {
                                this.state.figuras.map((f) => {
                                    return (
                                        <option key={f.id} className="change-option" id={f.id}>
                                            {f.title}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        <label htmlFor="input-new" className="change-label">Nuevo stock</label>
                        <input type="number" className="change-input" id="input-new-stock-figuras" />
                        <button className="change-button" id="figuras-submit" type="submit">
                            Actualizar
                        </button>
                    </form>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    changeStockFigura,
    getFiguras
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default conexion(FigurasStockChange);