import React from 'react'

import './discounts.css'

import Swal from 'sweetalert2';

import { connect } from "react-redux";
import { setDiscount, deleteDiscount } from "../../redux/actions";

class Discounts extends React.Component{
    constructor(props){
        super(props);
    }

    setDiscount = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        const data = {
            editorial: document.getElementById('select-ed-discount').value,
            descuento: document.getElementById('descuento').value
        }

        this.props.setDiscount(data)
    }

    deleteDiscount = (e) => {
        e.preventDefault()
        e.target.disabled = true;
        const data = {
            editorial: document.getElementById('select-ed-discount').value
        }

        this.props.deleteDiscount(data)
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.d_status !== this.props.state.d_status){
            if(this.props.state.d_status === "success"){
                Swal.fire({
                    icon: 'success',
                    title: "Descuento actualizado con éxito!"
                })
                .then(()=>{
                    window.location.reload()
                })
            }
        }
    }

    render(){
        return(
            <div className='discounts-main-container'>
                <select className='update-select' id="select-ed-discount">
                    {this.props.editoriales.map((e)=>{
                        return(
                            <option key={e.id}>
                                {e.nombre}
                            </option>
                        )
                    })}
                </select>
                <input type="number" className='change-input' id="descuento" />
                <button className='update-button' id="agregar-descuetno" onClick={this.setDiscount}>Agregar descuento</button>
                <button className='update-button' id="delete-discount" onClick={this.deleteDiscount}>Borrar descuento</button>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    setDiscount,
    deleteDiscount
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Discounts);