import React from "react";

import { connect } from "react-redux";
import Loader from "../../pages/loader";
import { updateSerie } from "../../redux/actions";

import Swal from 'sweetalert2'

import './updateSerie.css';

class UpdateSerie extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            series: [],
        }
    }

    componentDidMount(){
        let filtered = [];

        for(let i = 0; i < this.props.series.length; i++){
            if(this.props.series[i].state === "Abierta"){
                filtered.push(this.props.series[i])
            }
        }

        this.setState({
            series: filtered
        }, ()=>{
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.update_status !== this.props.state.update_status){
            if(this.props.state.update_status === "success"){
                Swal.fire({
                    icon: "success",
                    text: "Serie actualizada con éxito!"
                })
                .then(()=>{
                    window.location.reload()
                })
            }else if(this.props.state.update_state){
                Swal.fire({
                    icon: "error",
                    text: "No se ha encontrado la serie a actualizar"
                })
                .then(()=>{
                    this.props.state.update_state = ""
                })
            }
        }
    }

    handleClose = () => {
        const serie = document.getElementById("update-serie-").value;
        const data = {
            serie: serie,
            update: "close"
        }
        this.props.updateSerie(data)
    }

    handlePlus = () => {
        const serie = document.getElementById("update-serie-").value;
        const data = {
            serie: serie,
            update: "plus"
        }
        this.props.updateSerie(data)
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="update-main-container">
                    <label className="update-l" htmlFor="update-serie-">
                        Series
                    </label>
                    <select className="update-select" id="update-serie-">
                        {
                            this.state.series.map((e)=>{
                                return <option className="update-option" key={Math.random()} id={e.nombre}>
                                    {e.nombre}
                                </option>
                            })
                        }
                    </select>
                    <button className="update-button" onClick={this.handlePlus}>
                        Nuevo tomo
                    </button>
                    <button className="update-button" onClick={this.handleClose}>
                        Cerrar serie
                    </button>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    updateSerie
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(UpdateSerie);