import React from "react"

import { connect } from "react-redux";
import { figsByTag } from "../../redux/actions";

import Card from "../../components/card/card";
import Loader from "../loader";

import './pageFiguras.css'

class PageFiguras extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            figuras: [],
            tag: null
        }
    }

    componentDidMount(){
        let tag = window.location.hash.split('/')[3]
        tag = decodeURI(tag)
        this.setState({
            tag: tag
        }, ()=>{
            this.props.figsByTag(tag)
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.figuras !== this.props.state.figuras){
            this.setState({
                figuras: this.props.state.figuras
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="figs-tag-main-container">
                    <section className="figs-tag-title-container">
                        <h2 className="figs-tag-title">
                            {`Figuras con la etiqueta "${this.state.tag}"`}
                        </h2>
                        <p className="figs-tag-results">
                            {`${this.state.figuras.length} resultados`}
                        </p>
                    </section>
                    <section className="figs-tag-grid">
                        {this.state.figuras.map((f)=>{
                            return <Card vol={f} key={f.id} />
                        })}
                    </section>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    figsByTag
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(PageFiguras);