import React from "react";
import Loader from "../loader";

import { connect } from 'react-redux';
import { getStock } from "../../redux/actions";

import UpdateStock from "../../components/updateStock/updateStock";

import './stock.css'
import BackDash from "../../components/backDash/backDash";

class Stock extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            isLogged: false,
            loading: true,
            stock: [],
            filtered: {},
            data: {}
        }
    }

    componentDidMount(){
        this.props.getStock()
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.stock !== this.props.state.stock){
            let stock = this.props.state.stock
            
            this.setState({
                stock: stock,
                filtered: stock
            }, ()=>{
                const stock = this.state.stock;
                const series = []
                const colecciones = []
                const special = []
                stock.mangas.map((v)=>{
                    if(v.special){
                        if(v.serie === "BANANA FISH - BOX SET (COLECCIÓN COMPLETA + POSTALES)")
                            series.push(v.serie.trim())
                        special.push(v)
                    }else{
                        series.push(v.serie.trim())
                    }
                })

                stock.libros.map((v)=>{
                    series.push(v.serie)
                })

                stock.comics.map((v)=>{
                    if(v.serie){
                        series.push(v.serie)
                    }else{
                        colecciones.push(`${v.coleccion}: ${v.nombre}`)
                    }
                })

                let series_filtered = series.filter((item,index)=>{
                    return series.indexOf(item) === index;
                })

                series_filtered.sort()
                colecciones.sort()
                special.sort()

                const data = {
                    series: series_filtered,
                    special: special,
                    colecciones: colecciones
                }

                this.setState({
                    loading: false,
                    data: data
                }, ()=>{
                    console.log(this.state.data)
                })
            })
        }
    }

    getTables = (e) => {
        if(e === 'mangas'){
            return (
                <table className="stock-table">
                    <tr className="stock-row-header">
                        <th className="stock-header">SERIE</th>
                        <th className="stock-header">NÚMERO</th>
                        <th className="stock-header">STOCK</th>
                    </tr>
                    {
                        this.state.filtered.mangas.map((m)=>{
                            if(parseInt(m.stock) > 0){
                                return(
                                    <tr className="stock-row" key={m.id}>
                                        <td className="stock-data" id="serie">
                                            {m.serie} {m.comment}
                                        </td>
                                        <td className="stock-data">
                                            {m.numero}
                                        </td>
                                        <td className="stock-data" id="has-stock">
                                            {m.stock}
                                        </td>
                                    </tr>
                                )
                            }else{
                                return(
                                    <tr className="stock-row" id="sin-stock" key={m.id}>
                                        <td className="stock-data" id="serie">
                                            {m.serie}
                                        </td>
                                        <td className="stock-data">
                                            {m.numero}
                                        </td>
                                        <td className="stock-data">
                                            {m.stock}
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    }
                </table>
            )
        }else if(e === 'libros'){
            return (
                <table className="stock-table">
                    <tr className="stock-row-header">
                        <th className="stock-header">SERIE</th>
                        <th className="stock-header">NÚMERO</th>
                        <th className="stock-header">STOCK</th>
                    </tr>
                    {
                        this.state.filtered.libros.map((m)=>{
                            if(parseInt(m.stock) > 0){
                                return(
                                    <tr className="stock-row" key={m.id}>
                                        <td className="stock-data" id="serie">
                                            {m.serie} {m.comment}
                                        </td>
                                        <td className="stock-data">
                                            {m.numero}
                                        </td>
                                        <td className="stock-data" id="has-stock">
                                            {m.stock}
                                        </td>
                                    </tr>
                                )
                            }else{
                                return(
                                    <tr className="stock-row" id="sin-stock" key={m.id}>
                                        <td className="stock-data" id="serie">
                                            {m.serie}
                                        </td>
                                        <td className="stock-data">
                                            {m.numero}
                                        </td>
                                        <td className="stock-data">
                                            {m.stock}
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    }
                </table>
            )
        }else if(e === 'comics'){
            return (
                <table className="stock-table">
                    <tr className="stock-row-header">
                        <th className="stock-header">SERIE/COLECCIÓN</th>
                        <th className="stock-header">NÚMERO/NOMBRE</th>
                        <th className="stock-header">STOCK</th>
                    </tr>
                    {
                        this.state.filtered.comics.map((m)=>{
                            if(m.serie){
                                if(parseInt(m.stock) > 0){
                                    return(
                                        <tr className="stock-row" key={m.id}>
                                            <td className="stock-data" id="serie">
                                                {m.serie} {m.comment}
                                            </td>
                                            <td className="stock-data">
                                                {m.numero}
                                            </td>
                                            <td className="stock-data" id="has-stock">
                                                {m.stock}
                                            </td>
                                        </tr>
                                    )
                                }else{
                                    return(
                                        <tr className="stock-row" id="sin-stock" key={m.id}>
                                            <td className="stock-data" id="serie">
                                                {m.serie}
                                            </td>
                                            <td className="stock-data">
                                                {m.numero}
                                            </td>
                                            <td className="stock-data">
                                                {m.stock}
                                            </td>
                                        </tr>
                                    )
                                }
                            }else{
                                if(parseInt(m.stock) > 0){
                                    return(
                                        <tr className="stock-row" key={m.id}>
                                            <td className="stock-data" id="serie">
                                                {m.coleccion}
                                            </td>
                                            <td className="stock-data">
                                                {m.nombre}
                                            </td>
                                            <td className="stock-data" id="has-stock">
                                                {m.stock}
                                            </td>
                                        </tr>
                                    )
                                }else{
                                    return(
                                        <tr className="stock-row" id="sin-stock" key={m.id}>
                                            <td className="stock-data" id="serie">
                                                {m.coleccion}
                                            </td>
                                            <td className="stock-data">
                                                {m.nombre}
                                            </td>
                                            <td className="stock-data">
                                                {m.stock}
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                        })
                    }
                </table>
            )
        }
    }

    filterMangas = (e) => {
        e.preventDefault()
        const mangas = this.state.stock.mangas
        const search = document.getElementById('search-mangas').value

        let filtered = []
        let new_filtered = []

        mangas.map((m)=>{
            const name = `${m.serie} ${m.numero} ${m.editorial}`
            if(name.toLowerCase().includes(search.toLowerCase())){
                filtered.push(m)
            }
        })

        new_filtered = filtered.sort((a, b)=>{
            return parseInt(a.numero) - parseInt(b.numero)
        })

        this.setState({
            filtered: {
                ...this.state.filtered,
                mangas: []
            }
        }, ()=>{
            this.setState({
                filtered: {
                    ...this.state.filtered,
                    mangas: new_filtered
                }
            })
        })
    }

    filterLibros = (e) => {
        e.preventDefault()
        const libros = this.state.stock.libros
        const search = document.getElementById('search-libros').value

        let filtered = []
        let new_filtered = []

        libros.map((m)=>{
            const name = `${m.serie} ${m.numero} ${m.editorial}`
            if(name.toLowerCase().includes(search.toLowerCase())){
                filtered.push(m)
            }
        })

        new_filtered = filtered.sort((a, b)=>{
            return parseInt(a.numero) - parseInt(b.numero)
        })

        this.setState({
            filtered: {
                ...this.state.filtered,
                libros: []
            }
        }, ()=>{
            this.setState({
                filtered: {
                    ...this.state.filtered,
                    libros: new_filtered
                }
            })
        })
    }

    filterComics = (e) => {
        e.preventDefault()
        const comics = this.state.stock.comics
        const search = document.getElementById('search-comics').value

        let filtered = []
        let new_filtered = []

        comics.map((m)=>{
            if(m.serie){
                const name = `${m.serie} ${m.numero} ${m.editorial}`
                if(name.toLowerCase().includes(search.toLowerCase())){
                    filtered.push(m)
                }
            }else{
                const name = `${m.nombre} ${m.coleccion} ${m.editorial}`
                if(name.toLowerCase().includes(search.toLowerCase())){
                    filtered.push(m)
                }
            }
        })

        new_filtered = filtered.sort((a, b)=>{
            return parseInt(a.numero) - parseInt(b.numero)
        })

        this.setState({
            filtered: {
                ...this.state.filtered,
                comics: []
            }
        }, ()=>{
            this.setState({
                filtered: {
                    ...this.state.filtered,
                    comics: new_filtered
                }
            })
        })
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="stock-main-container">
                    <BackDash />
                    <UpdateStock
                        series={this.state.data.series}
                        special={this.state.data.special}
                        comics={this.state.data.colecciones}
                    />
                    <div className="stock-container">
                        <h1 className="stock-title">
                            MANGAS
                        </h1>
                        <div className="stock-form-container">
                            <form className="stock-form" onSubmit={this.filterMangas}>
                                <input
                                className="stock-input"
                                type="text"
                                id="search-mangas"
                                placeholder="Buscar..."
                                />
                                <button type="submit" className="stock-button">
                                    Buscar
                                </button>
                            </form>
                        </div>
                        <div className="stock-table-container">
                            {this.getTables('mangas')}
                        </div>
                    </div>
                    <div className="stock-container">
                        <h1 className="stock-title">
                            COMICS
                        </h1>
                        <div className="stock-form-container">
                            <form className="stock-form" onSubmit={this.filterComics}>
                                <input
                                className="stock-input"
                                type="text"
                                id="search-comics"
                                placeholder="Buscar..."
                                />
                                <button type="submit" className="stock-button">
                                    Buscar
                                </button>
                            </form>
                        </div>
                        <div className="stock-table-container">
                            {this.getTables('comics')}
                        </div>
                    </div>
                    <div className="stock-container">
                        <h1 className="stock-title">
                            LIBROS
                        </h1>
                        <div className="stock-form-container">
                            <form className="stock-form" onSubmit={this.filterLibros}>
                                <input
                                className="stock-input"
                                type="text"
                                id="search-libros"
                                placeholder="Buscar..."
                                />
                                <button type="submit" className="stock-button">
                                    Buscar
                                </button>
                            </form>
                        </div>
                        <div className="stock-table-container">
                            {this.getTables('libros')}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getStock
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Stock);