import React from "react";
import Card from "../../components/card/card";

import { connect } from "react-redux";
import { getAuthors } from "../../redux/actions";

import './author.css';
import Swal from "sweetalert2";
import Loader from "../loader";

class Authors extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            allVols: [],
            vols: [],
            loading: true,
            autores: {
                guion: "",
                dibujo: ""
            },
            type: "",
            get: false
        }
    }

    componentDidMount(){
        
        const params = new Proxy(new URLSearchParams(window.location.href), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let name = params.name;

        let different = false;

        for(let i = 0; i < name.length; i++){
            if(name[i] === "~"){
                different = true;
            }
        }

        if(different){
            let guion = name.split('~')[0];
            let dibujo = name.split('~')[1];
            this.setState({
                autores: {
                    guion: guion,
                    dibujo: dibujo
                }
            }, ()=>{
                if(!this.state.get){
                    this.setState({
                        get: true
                    }, ()=>{
                        console.log('mandamos el get')
                        this.props.getAuthors(this.state.autores)
                    })
                }
            })
        }else{
            let guion = name.split('-')[0];
            this.setState({
                autores: {
                    guion: guion,
                    dibujo: guion
                }
            }, ()=>{
                if(!this.state.get){
                    this.setState({
                        get: true
                    }, ()=>{
                        console.log('mandamos el get')
                        this.props.getAuthors(this.state.autores)
                    })
                }
            })
        }
        

    }

    componentDidUpdate(prevProps){
        if(prevProps.state.authors !== this.props.state.authors){
            if(this.props.state.authors.error){
                console.log(this.props.state.authors)
                Swal.fire({
                    icon: 'error',
                    text: `Error code: ${this.props.state.authors.code}`,
                    title: `${this.props.state.authors.detail}`
                })
                .then(()=>{
                    window.location.reload()
                })
            }else{
                this.setState({
                    allVols: this.props.state.authors,
                    vols: this.props.state.authors
                }, ()=>{
                    this.setState({
                        loading: false
                    })
                })
            }
        }
    }

    getAuthors = () => {
        if(this.state.autores.dibujo === this.state.autores.guion){
            return `${this.state.autores.dibujo}`
        }else{
            return `${this.state.autores.guion} y ${this.state.autores.dibujo}`
        }
    }

    filter = (e) => {
        if(e.target.style.backgroundColor !== "rgb(55, 130, 175)"){
            e.target.style.backgroundColor = "rgb(55, 130, 175)"
            let vols = this.state.allVols;
            let filtered = [];
            for(let i = 0; i < vols.length; i++){
                if(parseInt(vols[i].numero) === 1){
                    filtered.push(vols[i])
                }
            }

            this.setState({
                vols: filtered
            })
        }else{
            e.target.style.backgroundColor = "white";

            this.setState({
                vols: this.state.allVols
            })
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="search-main-container">
                    <div className="filter-container">
                        <span className="filters">
                            <button id="max-price" className="input-filter" onClick={this.filter}>
                                Solo tomos 1
                            </button>
                        </span>
                        <span className="cant-search">
                            <p className="p-cant">
                                {
                                    `Todos los tomos de: ${this.getAuthors()}`
                                }
                            </p>
                        </span>
                    </div>
                    <span className="search-grid">
                        {
                            this.state.vols.map((vol)=>{
                                return(
                                    <Card vol={vol} key={`${vol.id}`} />
                                )
                            })
                        }
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getAuthors
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Authors);