import React from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { getCartItems, guestCartItems, addToCart, deleteItem } from "../../redux/actions";
import Loader from "../loader";

import "./cartPage.css";

class CartPage extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            loading: true,
            cart: [],
            cant_items: 0,
            total: 0,
            get: false
        }
    }

    componentDidMount(){
        
        setTimeout(() => {
            if(auth.currentUser){
                if(!this.state.get){
                    this.setState({
                        get: true
                    }, ()=>{
                        this.props.getCartItems(auth.currentUser.uid)
                    })
                }
            }else{
                const storage = JSON.parse(localStorage.getItem('cart'));
                if(storage){
                    if(!this.state.get){
                        this.setState({
                            get: true
                        }, ()=>{
                            this.props.guestCartItems(storage.items)
                        })
                    }
                }else{
                    this.setState({
                        cart: []
                    }, ()=>{
                        this.setState({
                            loading: false
                        })
                    })
                }
            }
        }, 2000);
    }

    componentDidUpdate(prevProps){
        if(prevProps.state.status !== this.props.state.status){
            window.location.reload()
        }

        if(prevProps.state.cart !== this.props.state.cart){

            console.log(this.props.state.cart)

            let cant_items = 0;
            let total = 0;
            
            for(let i = 0; i < this.props.state.cart.length; i++){
                cant_items += this.props.state.cart[i].cant;
                total += (this.props.state.cart[i].precio * this.props.state.cart[i].cant);
            }

            this.setState({
                cart: this.props.state.cart,
                cant_items: cant_items,
                total: total
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    addItem = (e) => {
        e.preventDefault();
        e.target.disabled = true;
        if(auth.currentUser){
            const data = {
                id: auth.currentUser.uid,
                itemId: e.target.id
            }
            this.props.addToCart(data)
        }else{
            const storage = JSON.parse(localStorage.getItem('cart'));
            let items = storage.items;

            const cart = {
                items: [
                    ...items,
                    e.target.id
                ]
            }

            localStorage.setItem('cart', JSON.stringify(cart));

            window.location.reload();

        }
    }

    deleteItem = (e) => {
        e.preventDefault();
        e.target.disabled = true;

        if(auth.currentUser){

            const data = {
                id: auth.currentUser.uid,
                itemId: e.target.id
            }

            this.props.deleteItem(data)
        }else{
            const storage = JSON.parse(localStorage.getItem('cart'));
            const items = storage.items;

            let deleted = false;

            for(let i = 0; i < items.length; i++){
                if(!deleted){
                    if(e.target.id === items[i]){
                        items.splice(i, 1);
                        deleted = true;
                    }
                }
            }

            const cart = {
                items: items
            }

            localStorage.setItem('cart', JSON.stringify(cart));

            window.location.reload();

        }
    }

    getTitle = (item) => {
        if(item.figura){
            return `${item.title}`
        }
        if(item.libro){
            if(item.subtitulo){
                return `${item.serie} ${item.numero}: ${item.subtitulo}`
            }else{
                return `${item.serie} ${item.numero}`
            }
        }else if(item.coleccion){
            return `${item.nombre}`
        }else{
            if(item.special){
                return `${item.serie} #${item.numero} ${item.comment}`

            }else{
                return `${item.serie} #${item.numero}`
            }
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            if(this.state.cart.length > 0){
                return(
                    <div className="cart-main-container">
                        <h1 className="cart-main-title">
                            Mi carrito - items: {this.state.cant_items}
                        </h1>
                        {
                            this.state.cart.map((item)=>{
                                return(
                                    <span key={item.id} className="cart-card-container">
                                        <span className="cart-img-container">
                                            <img src={item.imgURL} alt={`${item.colecion}-${item.nombre}`} className="cart-img" />
                                        </span>
                                        <span className="cart-info-container">
                                            <h1 className="cart-title">
                                                {this.getTitle(item)}
                                            </h1>
                                            <p className="cart-price">
                                                ${item.precio} - Cant. x{item.cant}
                                            </p>
                                        </span>
                                        <span className="cart-buttons-container">
                                            <button className="delete-button" onClick={this.addItem} id={item.id}>
                                                Agregar
                                            </button>
                                            <button className="delete-button" onClick={this.deleteItem} id={item.id}>
                                                Borrar
                                            </button>
                                        </span>
                                    </span>  
                                )
                            })
                        }
                        <span className="buy-space-container">
                            <p className="buy-space-total">
                                Total: ${this.state.total}.-
                            </p>
                            <Link to="/itstimetopay?cart=true">
                                <button className="buy-space-button">
                                    <p className="buy-space-button-p">
                                        Comprar
                                    </p>
                                </button>
                            </Link>
                        </span>
                    </div>
                )
            }else{
                return(
                    <div className="cart-main-container">
                        <h1 className="cart-main-title">
                            Tu carrito está vacío!
                        </h1>
                    </div>
                )
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getCartItems,
    guestCartItems,
    addToCart,
    deleteItem
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(CartPage);