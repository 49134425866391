import React from "react";
import { AiOutlineDoubleLeft } from 'react-icons/ai'

import './backDash.css';

class BackDash extends React.Component{
    
    render(){
        return(
            <div className="backdash-main-container">
                <AiOutlineDoubleLeft className="backdash-button" id="icon" onClick={()=>{
                    window.location.href = '/#/dashboard'
                }}/>
                <h1 className="backdash-button">
                    Volver al Dashboard
                </h1>
            </div>
        )
    }

}

export default BackDash;