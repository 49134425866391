import React from "react";
import { connect } from 'react-redux';
import { postComic, getColecciones } from "../../redux/actions";
import { auth, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL  } from "firebase/storage";
import Loader from "../loader"
import BackDash from '../../components/backDash/backDash';

import '../add.css';
import Swal from "sweetalert2";
import tokens from "../../variables";

class PostComicCol extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            buttonUpload: true,
            progress: 0,
            imgURL: "",
            hasError: false,
            error: "",
            comic_info: {},
            loading: true,
            series: [],
            nombre: "",
            tooltip: false,
            checked: false,
            isLogged: false
        }
    }

    redirect = () => {
        window.location.href = "/";
    }

    componentDidMount(){

        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 1000);

        setTimeout(()=>{
            let buttons = document.getElementsByClassName("nav-button");
            for(let i = 0; i < buttons.length; i++){
                buttons[i].disabled = false;
            }
        }, 500)
    }

    uploadImage = (e)=>{

        e.preventDefault();

        const image = document.getElementById("input-cover").files[0];
        if(!image){
            Swal.fire({
                title: "Cargue una imágen válida!",
                icon: "warning",
                button: "Cerrar"
            })
            return 0;
        }
        const storageRef = ref(storage, `/covers/${image.name}`);
        const task = uploadBytesResumable(storageRef, image);

        task.on(
            "state_changed",
            (snapshot) => {
              const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setState({
                    ...this.state,
                    progress: percentage
                })
            },
            (error) => {
              console.log(error.message);
            },
            () => {
                getDownloadURL(task.snapshot.ref).then((downloadURL)=>{
                    this.setState({
                        buttonUpload: false,
                        imgURL: downloadURL
                    })
                })
            }
          );
    };

    reset = ()=>{
        this.setState({
            hasError: false,
            error: "",
            nombre: "",
            tooltip: false
        });
    }

    controlInfo = ()=>{
        let nombre = document.getElementById("input-name");
        let guion = document.getElementById("input-guion");
        let dibujo = document.getElementById("input-dibujo");
        let tintas = document.getElementById("input-tintas");
        let color = document.getElementById("input-color");
        let otros = document.getElementById("input-otros");
        let cantidad = document.getElementById("input-cant");
        let coleccion = document.getElementById("input-col");
        let precio = document.getElementById("input-price");

        //ERRORES
        let vacio = "no puede estar vacío";
        let espacio = "no puede empezar con un espacio";

        //RESETEAR ESTADO
        this.reset();
        coleccion.style = "border-color: none";
        nombre.style = "border-color: none";
        cantidad.style = "border-color: none";
        precio.style = "border-color: none";

        //CONTROLAR VACÍOS
        if(coleccion.value === "" || !coleccion.value){
            coleccion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Colección" ${vacio}`,
                checked: true
            });
        }

        if(nombre.value === "" || !nombre.value){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${vacio}`,
                checked: true
            });
        }

        if(cantidad.value === "" || !cantidad.value){
            cantidad.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Cant. stock" ${vacio}`,
                checked: true
            });
        }

        if(precio.value === "" || !precio.value){
            precio.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Precio" ${vacio}`,
                checked: true
            });
        }

        if(guion.value === "" || !guion.value){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${vacio}`,
                checked: true
            });
        }

        if(dibujo.value === "" || !dibujo.value){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${vacio}`,
                checked: true
            });
        }

        if(tintas.value === "" || !tintas.value){
            tintas.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tintas" ${vacio}`,
                checked: true
            });
        }

        if(color.value === "" || !color.value){
            color.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Color" ${vacio}`,
                checked: true
            });
        }

        //CONTROLAR ESPACIOS
        if(coleccion.value[0] === " "){
            coleccion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Colección" ${espacio}`,
                checked: true
            })
        }

        if(nombre.value[0] === " "){
            nombre.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Nombre" ${espacio}`,
                checked: true
            })
        }

        if(dibujo.value[0] === " "){
            dibujo.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Dibujo" ${espacio}`,
                checked: true
            })
        }

        if(guion.value[0] === " "){
            guion.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Guión" ${espacio}`,
                checked: true
            })
        }

        if(tintas.value[0] === " "){
            tintas.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Tintas" ${espacio}`,
                checked: true
            })
        }

        if(color.value[0] === " "){
            color.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Color" ${espacio}`,
                checked: true
            })
        }

        if(otros.value[0] === " "){
            otros.style = "border-color: red";
            return this.setState({
                ...this.state,
                hasError: true,
                error: `"Otros" ${espacio}`,
                checked: true
            })
        }

        return this.setState({
            checked: true
        })

    }

    onSubmit = (e)=>{
        e.preventDefault();
        e.target.disabled = true;

        const comic_info = {
            coleccion: document.getElementById("input-col").value,
            nombre: document.getElementById("input-name").value,
            autores: {
                guion: document.getElementById("input-guion").value,
                dibujo: document.getElementById("input-dibujo").value,
                color: document.getElementById("input-color").value,
                tintas: document.getElementById("input-tintas").value,
                otros: document.getElementById("input-otros").value,
            },
            stock: document.getElementById("input-cant").value,
            precio: document.getElementById("input-price").value,
            imgURL: this.state.imgURL,
            isColeccion: true,
            hardcover: document.getElementById("input-hardcover").checked,
            sinopsis: document.getElementById("sinopsis").value
        }

        this.setState({
            comic_info: comic_info
        }, ()=>{
            this.controlInfo();
        })

    }

    componentDidUpdate(prevProps, prevState){

        auth.onAuthStateChanged(()=>{
            if(auth.currentUser && !this.state.isLogged){
                if(auth.currentUser.uid === tokens.admin){
                    this.setState({
                        isLogged: true,
                        loading: true
                    }, ()=>{
                        this.props.getColecciones()
                    })
                }else{
                    window.location.href = '/';
                }
            }else if(!auth.currentUser && this.state.isLogged){
                this.setState({
                    isLogged: false
                })
            }else if(!auth.currentUser && !this.state.isLogged){
                window.location.href = '/';
            }
        })

        if(prevState.checked !== this.state.checked && this.state.checked){
            if(this.state.hasError){
                Swal.fire({
                    title: "Error!",
                    text: this.state.error,
                    icon: "error",
                    button: "Volver"
                })
                .then(()=>{
                    window.location.reload()
                })
            }else{  
                this.props.postComic(this.state.comic_info);
            }
        }

        if(prevProps.state.post_state !== this.props.state.post_state){
            if(this.props.state.post_state !== "success"){
                if(this.props.state.post_state.error){
                    Swal.fire({
                        title: this.props.state.post_state.error.detail,
                        text: this.props.state.post_state.error.code,
                        icon: "error"
                    })
                    .then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: this.props.state.post_state,
                        icon: "warning",
                        button: "Cerrar"
                    }).then(()=>{
                        window.location.reload()
                    })
                }

            }else{
                Swal.fire({
                    title: "Agregada!",
                    text: `${this.state.comic_info.nombre} agregado exitosamente`,
                    icon: "success",
                    button: "Cerrar"
                })
                .then(()=>{
                    window.location.reload();
                })
            }
        }

        if(prevProps.state.colecciones !== this.props.state.colecciones){
            this.setState({
                series: this.props.state.colecciones
            }, ()=>{
                this.setState({
                    loading: false
                })
            })
        }
    }

    getSeriesTooltip = () => {

        let tooltip = [];
        for(let i = 0; i < this.state.series.length; i++){
            let nombre = this.state.series[i].nombre;

            if(nombre.toLowerCase().includes(this.state.nombre)){
                tooltip.push(nombre)
            }
        }

        return(
            <span className="tooltip-container">
                {
                    tooltip.map((n)=>{
                        return(
                            <p className="tooltip-element" id={n} key={n} onClick={this.handleClick}>
                                {n}
                            </p>
                        )
                    })
                }
            </span>
        )


    }

    handleClick = (e) => {
        e.preventDefault();
        this.setState({
            tooltip: false
        }, ()=>{
            document.getElementById("input-col").value = e.target.id;
        })
    }

    tooltipVisible = () => {
        if(this.state.tooltip){
            return {"display": "flex"}
        }else{
            return {"display": "none"}
        }
    }

    handleChange = () => {

        if(document.getElementById("input-col").value.length > 1){
            this.setState({
                nombre: document.getElementById("input-col").value.toLowerCase(),
                tooltip: true
            })
        }else{
            this.setState({
                tooltip: false
            })
        }
    }

    render(){

        if(this.state.loading || !this.state.isLogged){
            return <Loader />
        }else{
            return(
                <div className="add-form-container">
                    <BackDash />
                    <h1 className="add-title">Añadir cómic (colección)</h1>
                    <span className="form-container" id="comic-width">
                        <span id="comic-form">
                            <span className="adds-container">
                                <span className="input-add">
                                    <p className="p-input">Nombre</p>
                                    <input className="input-" id="input-name" type="text" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Colección</p>
                                    <input className="input-" id="input-col" type="text" onChange={this.handleChange} />
                                    <span className="series-tooltip" style={this.tooltipVisible()}>
                                        {
                                            this.getSeriesTooltip()
                                        }
                                    </span>
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Sinopsis</p>
                                    <textarea className="input-" id="sinopsis" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Cant. stock</p>
                                    <input className="input-" id="input-cant" type="number" min="1" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Precio</p>
                                    <input className="input-" id="input-price" type="number" min="0" />
                                </span>
                                <span className="input-add">
                                    <label for="input-hardcover" className="p-input">Hardcover</label>
                                    <input className="input-" id="input-hardcover" type="checkbox" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Cover</p>
                                    <input className="input-" id="input-cover" type="file" accept="image/*" />
                                </span>
                                <br />
                                <br />
                                <progress className="input-" value={this.state.progress} />
                                <br />
                                <button className="input-add" id="upload" onClick={this.uploadImage}>Cargar imagen</button> 
                            </span>
                            <span className="adds-container">
                                <span className="input-add">
                                        <p className="p-input">Guión</p>
                                        <input className="input-" id="input-guion" type="text" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Dibujo</p>
                                    <input className="input-" id="input-dibujo" type="text" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Color</p>
                                    <input className="input-" id="input-color" type="text" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Tintas</p>
                                    <input className="input-" id="input-tintas" type="text" />
                                </span>
                                <span className="input-add">
                                    <p className="p-input">Otros autores</p>
                                    <input className="input-" id="input-otros" type="text" />
                                </span>
                            </span>   
                        </span>
                        <br />
                        <button className="add-button" onClick={this.onSubmit} disabled={this.state.buttonUpload} id="post-button">Crear</button>
                    </span>
                </div>
            );
        }

    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    postComic,
    getColecciones
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(PostComicCol);