import React from "react";
import Card from "../card/card";

import './searchResults.css';

class SearchResults extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            vols: [],
            loading: true
        }
    }

    componentDidMount(){
        console.log(this.props.vols)
        this.setState({
            vols: this.props.vols
        }, ()=>{
            this.setState({
                loading: false
            })
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps !== this.props){
            this.setState({
                loading: true
            }, ()=>{
                this.setState({
                    vols: this.props.vols
                }, ()=>{
                    this.setState({
                        loading: false
                    })
                })
            })
        }
    }

    render(){
        if(this.state.loading){
            return(
                <div className="search-main-grid">BUSCANDO</div>
            )
        }else{
            return(
                <div id="searching" className="advance-main-grid">
                    {
                        this.state.vols.map((vol)=>{
                            return <Card serie={true} key={vol.id} vol={vol} />
                        })
                    }
                </div>
            )
        }
    }
}

export default SearchResults;