import React from "react";

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getLess } from "../../../redux/actions";

import Card from "../../../components/card/card";
import Loader from "../../loader";

import './products.css'

class Products extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            products: [],
            cant: 0
        }
    }

    componentDidMount(){

        let cant = 0;

        if(window.screen.width > 850){
            cant = 14
        }else if(window.screen.width <= 850 && window.screen.width > 650){
            cant = 24
        }else{
            cant = 10
        }

        this.setState({
            cant: cant
        }, ()=>{
            this.props.getLess()
        })

    }

    componentDidUpdate(prevProps){
        if(prevProps.state.products !== this.props.state.products){
            let products = this.props.state.products

            products = products.sort((a, b)=>{
                const n = Math.random()
                if(n > 0.5){
                    return a
                }else{
                    return b
                }
            })

            this.setState({
                products: products
            }, ()=>{
                console.log(products)
                this.setState({
                    loading: false
                })
            })
        }
    }

    getCards = () => {
        
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="products-main-container">
                    <h1 className="products-title">OTROS PRODUCTOS</h1> 
                    <div className="product-grid-container">
                        <span className="products-grid">
                            {
                                this.state.products.map((p)=>{
                                    return <Card serie={true} vol={p} key={p.id} />
                                })
                            }
                        </span>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    getLess
}

const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Products);