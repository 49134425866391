import React from "react";
import Card from "../../../components/card/card";

import './especiales.css';

class Especiales extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            has: false
        }
    }

    componentDidMount(){
        console.log('entramos al especiales', this.props.vols)
        if(this.props.vols.length > 0){
            this.setState({
                has: true
            })
        }
    }

    componentDidUpdate(){
        console.log('se actualizó especiales', this.props.vols)
        if(this.props.vols.length > 0 && !this.state.has){
            this.setState({
                has: true
            })
        }
    }

    getType = () => {
        if(this.props.type === "hardback"){
            return `Editorial ${this.props.editorial} no tiene ediciones hardback por el momento`
        }else if(this.props.type === "especiales"){
            return `Editorial ${this.props.editorial} no tiene ediciones especiales por el momento`
        }
    }

    render(){
        if(this.state.has){
            return(
                <div className="especiales-container">
                    <span className="especiales-grid">
                        {
                            this.props.vols.map((e)=>{
                                return <Card vol={e} key={e.id} />
                            })
                        }
                    </span>
                </div>
            )
        }else{
            return(
                <div className="especiales-container">
                    <p className="hasnt">
                        {this.getType()}
                    </p>
                </div>
            )
        }
    }
}

export default Especiales;