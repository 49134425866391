import React from "react";

import { connect } from "react-redux";
import Card from "../../components/card/card";

import queryString from 'query-string'

import { searchByTitle } from "../../redux/actions";
import Loader from "../loader";

import "./search.css"

class Search extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            vols: [],
            allVols: [],
            name: "",
            editoriales: [],
            get: false,
            pageVols: [],
            page: null,
            limit: 20,
            lastPage: 0
        }
    }

    componentDidMount(){
        /*
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let name = params.name;
        */

        //console.log(this.state.page)

        const obj = queryString.parse(window.location.hash);
        let name;

        for(let key in obj){
            name = obj[key];
        }

        this.setState({
            name: name
        }, ()=>{
            if(!this.state.get){
                this.setState({
                    get: true
                }, ()=>{
                    this.props.searchByTitle(name);
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState){

        if(prevState.page !== this.state.page){
            const pageVols = []

            for(let i = 0; i < this.state.vols.length; i++){
                if(pageVols.length < this.state.limit){
                    if(this.state.vols[i + (this.state.limit * this.state.page)]){
                        pageVols.push(this.state.vols[i + (this.state.limit * this.state.page)])
                    }
                }
            }

            this.setState({
                pageVols: pageVols
            }, () => {

                if(this.state.loading){
                    this.setState({
                        loading: false
                    })
                }

                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                })
            })

        }

        if(prevProps.state.vols !== this.props.state.vols){
            //console.log(this.props.state.vols.length, this.state.limit)
            let last = Math.floor(this.props.state.vols.length / this.state.limit)
            //console.log(last)

            if((this.props.state.vols.length / this.state.limit) === 1){
                last = 0;
            }

            this.setState({
                vols: this.props.state.vols,
                allVols: this.props.state.vols,
                lastPage: last,
                page: 0
            }, ()=>{
                this.state.vols.forEach((e)=>{
                    if(this.state.editoriales.length === 0){
                        this.state.editoriales.push(e.editorial)
                    }else{
                        let exist = false;
                        for(let i = 0; i < this.state.editoriales.length; i++){
                            if(e.editorial === this.state.editoriales[i]){
                                exist = true
                            }
                        }
                        if(!exist){
                            this.state.editoriales.push(e.editorial)
                        }
                    }
                })

                //console.log(this.props.state.vols, this.state.allVols)
            })
        }
    }

    filter = (e) => {
        e.preventDefault();

        let active = false;

        if(e.target.style.backgroundColor === "rgb(55, 130, 175)"){
            active = true;
        }

        let buttons = document.getElementsByClassName("input-filter");
        for(let i = 0; i < buttons.length; i++){
            buttons[i].style.backgroundColor = "white";
        }

        if(!active){
            e.target.style.backgroundColor = "rgb(55, 130, 175)";
            const vols = this.state.vols;
            if(e.target.id === "max-price"){
                const volsSorted = [...vols].sort((a,b)=>{
                    return b.precio - a.precio
                })
                this.setState({
                    vols: volsSorted
                }, () => {
                    const pageVols = []

                    for(let i = 0; i < this.state.vols.length; i++){
                        if(pageVols.length < this.state.limit){
                            if(this.state.vols[i + (this.state.limit * this.state.page)]){
                                pageVols.push(this.state.vols[i + (this.state.limit * this.state.page)])
                            }
                        }
                    }

                    this.setState({
                        pageVols: pageVols
                    }, () => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        })
                    })
                })
            }else if(e.target.id === "min-price"){
                const volsSorted = [...vols].sort((a,b)=>{
                    return a.precio - b.precio
                })
                this.setState({
                    vols: volsSorted
                }, () => {
                    const pageVols = []

                    for(let i = 0; i < this.state.vols.length; i++){
                        if(pageVols.length < this.state.limit){
                            if(this.state.vols[i + (this.state.limit * this.state.page)]){
                                pageVols.push(this.state.vols[i + (this.state.limit * this.state.page)])
                            }
                        }
                    }

                    this.setState({
                        pageVols: pageVols,
                        page: 0
                    }, () => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        })
                    })
                })
            }else if(e.target.id === "oldest"){
                const volsSorted = []

                this.state.vols.map((v)=>{
                    if(v.stock > 0){
                        volsSorted.push(v)
                    }

                    return null
                })

                this.setState({
                    vols: volsSorted,
                    page: 0
                }, () => {
                    const pageVols = []

                    for(let i = 0; i < this.state.vols.length; i++){
                        if(pageVols.length < this.state.limit){
                            if(this.state.vols[i + (this.state.limit * this.state.page)]){
                                pageVols.push(this.state.vols[i + (this.state.limit * this.state.page)])
                            }
                        }
                    }

                    this.setState({
                        pageVols: pageVols,
                        page: 0
                    }, () => {
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        })
                    })
                })
            }
        }else{
            this.setState({
                vols: this.state.allVols
            }, () => {
                const pageVols = []

                for(let i = 0; i < this.state.vols.length; i++){
                    if(pageVols.length < this.state.limit){
                        if(this.state.vols[i + (this.state.limit * this.state.page)]){
                            pageVols.push(this.state.vols[i + (this.state.limit * this.state.page)])
                        }
                    }
                }

                this.setState({
                    pageVols: pageVols,
                    page: 0
                }, () => {
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    })
                })
            })
        }

    }

    back = () => {
        if(this.state.page > 0){
            this.setState({
                page: this.state.page - 1
            })
        }
    }

    next = () => {
        if(this.state.page < this.state.lastPage){
            this.setState({
                page: this.state.page + 1
            })
        }
    }

    isAvailable = (e) => {
        if(e === "back"){
            if(this.state.page === 0){
                return true
            }else{
                return false
            }
        }else{
            //console.log("IS AVAILABLE NEXT: ", this.state.page, this.state.lastPage)
            if(this.state.page === this.state.lastPage){
                return true
            }else{
                return false
            }
        }
    }

    render(){
        if(this.state.loading){
            return <Loader />
        }else{
            return(
                <div className="search-main-container">
                    <div className="filter-container">
                        <span className="filters">
                            <button id="max-price" className="input-filter" onClick={this.filter}>
                                Mayor precio
                            </button>
                            <button id="min-price" className="input-filter" onClick={this.filter}>
                                Menor precio
                            </button>
                            <button id="oldest" className="input-filter" onClick={this.filter}>
                                En stock
                            </button>
                        </span>
                        <span className="cant-search">
                            <p className="p-cant">
                                {
                                    `${this.state.vols.length} resultados para: "${this.state.name}"`
                                }
                            </p>
                        </span>
                    </div>
                    <span className="search-grid">
                        {
                            this.state.pageVols.map((vol)=>{
                                return(
                                    <Card vol={vol} key={`${vol.id}`} />
                                )
                            })
                        }
                    </span>
                    <span className="nav-result-container">
                        <span className="button-result-nav-container">
                            <button onClick={this.back}
                            disabled={this.isAvailable("back")}
                            className="button-page">
                                ANTERIOR
                            </button>
                            <button onClick={this.next}
                            disabled={this.isAvailable("next")}
                            className="button-page">
                                SIGUIENTE
                            </button>
                        </span>
                        <p className="page-number">
                            Página {this.state.page + 1} de {this.state.lastPage + 1}
                        </p>
                    </span>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        state: state
    }
}

const mapDispatchToProps = {
    searchByTitle
}
const conexion = connect(mapStateToProps, mapDispatchToProps)
export default  conexion(Search);