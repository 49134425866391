import React from "react";

import "./loader.css";

class Loader extends React.Component{

    componentDidMount(){
        window.scrollTo({
            y: 0,
            x: 0,
            behavior: "smooth"
        })
    }

    render(){
        return(
            <div className="loader-container">
                <span className="loader" />
            </div>
        )
    }
}

export default Loader;